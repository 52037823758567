import React from "react";

import brandOne from "@home/assets/images/brand/brand-tech.svg";
import brandTwo from "@home/assets/images/brand/brand-uab.svg";
import brandThree from "@home/assets/images/brand/brand-scaleway.svg";
import brandFour from "@home/assets/images/brand/brand-impelia.svg";

import styles from '../index.module.css'

const BrandTwo = () => {
  return (
    <>
      <div className={`${styles.row}`} >
        <div className={`${styles["col-lg-12"]} ${styles["mt--10"]}`} >
          <ul className={`${styles["brand-list"]} ${styles["brand-style-2"]}`} >
            <li>
              <button href="#">
                <img
                  src={brandOne}
                  width={180}
                  height={60}
                  alt="Brand Image"
                />
              </button>
            </li>
            <li>
              <button href="#">
                <img
                  src={brandTwo}
                  width={155}
                  height={34}
                  alt="Brand Image"
                />
              </button>
            </li>
            <li>
              <button href="#">
                <img
                  src={brandThree}
                  width={183}
                  height={34}
                  alt="Brand Image"
                />
              </button>
            </li>
            <li>
              <button href="#">
                <img
                  src={brandFour}
                  width={197}
                  height={34}
                  alt="Brand Image"
                />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default BrandTwo;
