import React from "react"
import { useSelector } from 'react-redux'
import styles from "./ModalLimit.module.css"

const ModalLimit = () => {

  const { user } = useSelector(state => state.iam)

  return (
    <div className={styles.modal}>
      <div className={styles.content}>
        <svg fill="none" viewBox="0 0 24 24">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M7.757 12h8.486M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>

        <b>No hay tokens disponibles</b>
        <p>Sube el límite de tu cuenta</p>
      </div>
      <div className={styles.buttons}>
        <span>Tu limite es de 10.02€</span>
        {user.plan >= 102 ? (
          <button>Incrementar aquí</button>
        ) : (
          <button>Plan Business</button>
        )}
      </div>
    </div>
  )
}

export default ModalLimit
