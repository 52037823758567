import apiBackend from '@src/apiBackend'
import { createAsyncThunk } from '@reduxjs/toolkit';


export const fetchsSession =
  createAsyncThunk('session/fetchsSession',
    async ({user, component, query}, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          '/session', {
          user,
          component,
          token,
          query
        }
        );

        return {
          type: query.type,
          session: resp.data
        };
      } catch (error) {
        throw error;
      }
    }
  );


export const fetchSession =
  createAsyncThunk('session/fetchSession',
    async (id, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.get(
          `/session/${id}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );




export const addSession =
  createAsyncThunk('session/addSession',
    async (session, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          '/session/add',
          { session },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return {
          type: session.data.map.type,
          session: resp.data
        };
      } catch (error) {
        throw error;
      }
    }
  );




export const updateSession =
  createAsyncThunk('session/update',
    async (session, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.put(
          '/session',
          { session },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );


export const deleteSession =
  createAsyncThunk('session/delete',
    async (sessionId, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.delete(
          `/session`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          data: { id: sessionId },  
        });

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );






