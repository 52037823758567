const form = {
    editor: {
        step0: {
            text0: `Il tuo progetto avrà monetizzazione?`,
            t0: `Sì`,
            t1: `No`,
        },
        step1: {
            title: `Descrizione del tuo progetto`,
            text: `Descrivi il progetto per preparare i primi passi`,
            t0: `Nome`,
            t1: `Descrizione`,
        },
        step2: {
            title: `Quanti utenti prevedi di avere?`,
            text: `In base al numero di utenti, offriamo misure più critiche`,
            t0: `0-1.000`,
            t1: `1.000-10k`,
            t2: `10k-100k`,
            t3: `100k-250k`,
            t4: `250k-500k`,
            t5: `+500.001`
        },
        step3: {
            title: `Seleziona il tipo e lo stile`,
            text0: `Il tipo serve per scegliere una delle nostre categorie`,
            text1: `Lo stile definisce l'aspetto`,
            t0: `Ciao mondo`,
            t1: `Minimalista`,
            t2: `Moderno`,
            t3: `Classico`,
            x0: `Complesso`,
            x1: `Semplice`,
        },
        step4: {
            title: `Desideri la modalità editor avanzata o di base?`,
            t0: `Avanzata`,
            t1: `Di base`,
        },
    }, 
    flags: {
        en: `Inglese`,
        es: `Spagnolo`,
        fr: `Francese`,
        de: `Tedesco`,
        it: `Italiano`,
        ru: `Russo`,
        pt: `Portoghese`,
        nl: `Olandese`,
        sv: `Svedese`,
    },
    title: {
        text0: 'Passo',
        text1: 'di',
        step0: 'Lingua',
        step1: 'Profilo',
        step2: 'Obiettivo',
        step3: 'Contesto',
        step4: 'Bonus',
        step5: 'Azienda',
        step6: 'Dimensione',
        step7: 'Tipo di Software',
        step8: 'Assunzione',
    },
    footer: {
        text: 'Iscriviti alle nostre e-mail',
        button0: 'Precedente',
        button1: 'Avanti',
    },
    step0: {
        t0: `Scegli la lingua`,
        t1: `Scegliendo, il sito web cambierà`,
        t2: `Stiamo attualmente integrando più lingue`,
    },
    step1: {
        t0: `Qual è il tuo nome?`,
        t1: `Piacere di conoscerti`,
        t2: `Nome`,
        t3: `Cognome`,
        t4: `Telefono`,
        t5: `E-mail`,
    },
    step2: {
        t0: `Per chi ti serve?`,
        t1: `Seleziona l'opzione del tuo scopo`,
        t2: `La mia azienda`,
        t3: `Per me stesso`,
        t4: `Clienti`,
    },
    step3: {
        t0: `Come ti descriveresti? `,
        t1: `Startup`,
        t2: `Piccola impresa`,
        t3: `Medio impresa`,
        t4: `Grande impresa`,
    },
    step4:{
        t0: `Sei uno studente?`,
        t1: `Sì`,
        t2: `No`,
    },
    step5:{
        t0: `Cosa ti descrive meglio?`,
        t1: `Libero professionista`,
        t2: `Agenzia`,
    },
    step6:{
        t0: `Qual è la dimensione del tuo team di lavoro?`,
    },
    step7:{
        t0: `Che tipo di sito stai cercando di costruire oggi?`,
        t1: `Sito aziendale`,
        t2: `Negozio di e-commerce`,
        t3: `Portfolio`,
        t4: `Blog`,
        t5: `Analitica`,
        t6: `Altro`,
    },
    step8:{
        t0: `Sei interessato a utilizzare Aythen?`,
        t1: `Sì`,
        t2: `No`,
        t3: `Non ne sono sicuro ancora`,
    },
}

export default form
