import React, { useEffect, useState, useRef } from 'react';
import styles from './ReactEditor.module.css';

const ReactEditor = ({ index, children, loadContent, content, handleChange, handleKey }) => {
    useEffect(() => {
        const editControls = document.querySelector(`.${styles.editControls}`);

        const handleButtonClick = (e) => {
            e.preventDefault();
            const role = e.target.dataset.role;

            const editor = findEditor(e.target);

            switch (role) {
                case 'clean':
                    btnCleanHtml(editor);
                    break;
                case 'a':
                    btnLink();
                    break;
                case 'pasteTextClean':
                    btnPasteCleanHtml();
                    break;
                case 'sourceCode':
                    btnSourceCode(editor);
                    break;
                default:
                    if (['h1', 'h2', 'h3', 'h4', 'h5', 'p'].includes(role)) {
                        btnFormatBlock(role);
                    } else {
                        btnFormatOther(role);
                    }
            }
        };

        const findEditor = (target) => {
            let parent = target.parentElement;
            while (parent && !parent.classList.contains(`${styles.editControls}`)) {
                parent = parent.parentElement;
            }
            return parent ? parent.nextElementSibling : null;
        };

        const btnFormatBlock = (role) => {
            document.execCommand('formatBlock', false, role);
        };

        const btnFormatOther = (role) => {
            document.execCommand(role, false, null);
        };

        const btnCleanHtml = (editor) => {
            const selection = window.getSelection();
            if (selection.rangeCount > 0 && selection.toString().length) {
                const purify = stripHtmlTags(selection.toString());
                const range = selection.getRangeAt(0);
                range.deleteContents();
                range.insertNode(document.createTextNode(purify));
            } else {
                editor.innerHTML = stripHtmlTags(editor.innerHTML);
            }
        };

        const btnLink = () => {
            const linkUrl = prompt('Enter the URL of the link:');
            if (linkUrl) {
                document.execCommand('createLink', false, linkUrl);
            }
        };

        const btnPasteCleanHtml = () => {
            navigator.clipboard.readText()
                .then(clipboardText => {
                    document.execCommand('insertHTML', false, stripHtmlTags(clipboardText));
                })
                .catch(err => {
                    console.error('Error pasting:', err);
                });
        };

        const btnSourceCode = (editor) => {
            const editorSource = editor.innerHTML;
            if (!editor.classList.contains('editor--source-code')) {
                editor.classList.add('editor--source-code');
                editor.innerHTML = escapeHtml(editorSource);
            } else {
                editor.classList.remove('editor--source-code');
                editor.innerHTML = unescapeHtml(editorSource);
            }
        };

        const stripHtmlTags = (str) => {
            const parser = new DOMParser();
            const parsed = parser.parseFromString(str, 'text/html');
            return parsed.body.textContent || '';
        };

        const escapeHtml = (str) => {
            return str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        };

        const unescapeHtml = (str) => {
            return str.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
        };

        editControls.addEventListener('click', handleButtonClick);

        return () => {
            editControls.removeEventListener('click', handleButtonClick);
        };
    }, []);



    const editorRef = useRef(null);
    const [refreshContent, setRefreshContent] = useState(null)
    
    useEffect(() => {
        setRefreshContent(loadContent)

        if(loadContent !== refreshContent){
            const editor = editorRef.current;
        
            if (editor) {
              editor.innerHTML = content; 
            }
        }
      }, [loadContent, content]);

   

    const handlePaste = (e) => {
        e.preventDefault(); 

        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedText = clipboardData?.getData('text/plain'); 
        const pastedHTML = clipboardData?.getData('text/html'); 


        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = pastedText
        if (tempDiv.childNodes.length > 0) {
            insertHtmlAtCursor(pastedText); 
        } else {
            document.execCommand('insertText', false, pastedHTML);
        }

    };


    const insertHtmlAtCursor = (html) => {
        const selection = window.getSelection();
    
        if (!selection.rangeCount) return;
    
        const range = selection.getRangeAt(0);
        range.deleteContents(); 
    
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html; 
    
        const frag = document.createDocumentFragment();
        let child;
        while ((child = tempDiv.firstChild)) {
            frag.appendChild(child); 
        }
    
        if (frag.childNodes.length > 0) {
            range.insertNode(frag); 
    
            const lastChild = frag.lastChild; 
    
            if (lastChild) {
                range.setStartAfter(lastChild); 
                range.collapse(true); 
                selection.removeAllRanges(); 
                selection.addRange(range); 
            }
        } else {
        }
    };



    return (
        <>
            <div className={styles['editControls']}>
                {children}
                <div className={styles['btn-group']}>
                    <a className={styles['btn']} data-role='undo' href='#' title="undo">Undo</a>
                    <a className={styles['btn']} data-role='redo' href='#' title="redo">Redo</a>
                </div>
                <div className={styles['btn-group']}>
                    <a className={styles['btn']} data-role='bold' href='#' title="bold">B</a>
                    <a className={styles['btn']} data-role='italic' href='#' title="italic">I</a>
                    <a className={styles['btn']} data-role='underline' href='#' title="underline">U</a>
                    <a className={styles['btn']} data-role='strikethrough' href='#' title="strikethrough">S</a>
                </div>
                <div className={styles['btn-group']}>
                    <a className={styles['btn']} data-role='justifyLeft' href='#' title="justify left">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={styles["bi bi-text-left"]} viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </a>
                    <a className={styles['btn']} data-role='justifyCenter' href='#' title="justify center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={styles["bi bi-text-center"]} viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </a>
                    <a className={styles['btn']} data-role='justifyRight' href='#' title="justify right">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={styles["bi bi-text-right"]} viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm4-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </a>
                    <a className={styles['btn']} data-role='justifyFull' href='#' title="justify full">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={styles["bi bi-justify"]} viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </a>
                </div>
                <div className={styles['btn-group']}>
                    <a className={styles['btn']} data-role='indent' href='#' title="indent">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={styles["bi bi-text-indent-left"]} viewBox="0 0 16 16">
                            <path d="M2 3.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm.646 2.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L4.293 8 2.646 6.354a.5.5 0 0 1 0-.708zM7 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </a>
                    <a className={styles['btn']} data-role='outdent' href='#' title="outdent">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={styles["bi bi-text-indent-right"]} viewBox="0 0 16 16">
                            <path d="M2 3.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm10.646 2.146a.5.5 0 0 1 .708.708L11.707 8l1.647 1.646a.5.5 0 0 1-.708.708l-2-2a.5.5 0 0 1 0-.708l2-2zM2 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </a>
                </div>
                <div className={styles['btn-group']}>
                    <a className={styles['btn']} data-role='insertUnorderedList' href='#' title="insert unordered list">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={styles["bi bi-list-ul"]} viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                        </svg>
                    </a>
                    <a className={styles['btn']} data-role='insertOrderedList' href='#' title="insert ordered list">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={styles["bi bi-list-ol"]} viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z" />
                            <path d="M1.713 11.865v-.474H2c.217 0 .363-.137.363-.317 0-.185-.158-.31-.361-.31-.223 0-.367.152-.373.31h-.59c.016-.467.373-.787.986-.787.588-.002.954.291.957.703a.595.595 0 0 1-.492.594v.033a.615.615 0 0 1 .569.631c.003.533-.502.8-1.051.8-.656 0-1-.37-1.008-.794h.582c.008.178.186.306.422.309.254 0 .424-.145.422-.35-.002-.195-.155-.348-.414-.348h-.3zm-.004-4.699h-.604v-.035c0-.408.295-.844.958-.844.583 0 .96.326.96.756 0 .389-.257.617-.476.848l-.537.572v.03h1.054V9H1.143v-.395l.957-.99c.138-.142.293-.304.293-.508 0-.18-.147-.32-.342-.32a.33.33 0 0 0-.342.338v.041zM2.564 5h-.635V2.924h-.031l-.598.42v-.567l.629-.443h.635V5z" />
                        </svg>
                    </a>
                </div>
                <div className={styles['btn-group']}>
                    <a className={styles['btn']} data-role='h1' href='#' title="heading 1">H1</a>
                    <a className={styles['btn']} data-role='h2' href='#' title="heading 2">H2</a>
                    <a className={styles['btn']} data-role='h3' href='#' title="heading 3">H3</a>
                    <a className={styles['btn']} data-role='p' href='#' title="paragraph">p</a>
                </div>
                <div className={styles['btn-group']}>
                    <a className={styles['btn']} data-role='subscript' href='#' title="subscript">x<sub>2</sub></a>
                    <a className={styles['btn']} data-role='superscript' href='#' title="superscript">x<sup>2</sup></a>
                </div>
                <div className={`${styles['btn-group']} ${styles['btn-group--experimental']}`}>
                    <a className={styles['btn']} data-role='a' href='#' title="link">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={styles["bi bi-link"]} viewBox="0 0 16 16">
                            <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                            <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                        </svg>
                    </a>
                    <a className={styles['btn']} data-role='clean' href='#' title="clean html">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <text x="3" y="14" font-size="16" font-style="italic" font-weight="bold" fill="currentColor">T</text>
                            <line x1="1" y1="4" x2="15" y2="11" stroke="white" strokeWidth="2" />
                            <line x1="1" y1="5" x2="15" y2="12" stroke="currentColor" strokeWidth="1" />
                        </svg>
                    </a>
                    <a className={styles['btn']} data-role='pasteTextClean' href='#' title="paste plain text">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 107.07 122.88" >
                            <g>
                                <path d="M31.54,86.95c-1.74,0-3.16-1.43-3.16-3.19c0-1.76,1.41-3.19,3.16-3.19h20.5c1.74,0,3.16,1.43,3.16,3.19 c0,1.76-1.41,3.19-3.16,3.19H31.54L31.54,86.95z M31.54,42.27c-1.74,0-3.15-1.41-3.15-3.15c0-1.74,1.41-3.15,3.15-3.15h41.61 c1.74,0,3.15,1.41,3.15,3.15c0,1.74-1.41,3.15-3.15,3.15H31.54L31.54,42.27z M56.85,116.58c1.74,0,3.15,1.41,3.15,3.15 c0,1.74-1.41,3.15-3.15,3.15H7.33c-2.02,0-3.85-0.82-5.18-2.15C0.82,119.4,0,117.57,0,115.55V7.33c0-2.02,0.82-3.85,2.15-5.18 C3.48,0.82,5.31,0,7.33,0h90.02c2.02,0,3.85,0.82,5.18,2.15c1.33,1.33,2.15,3.16,2.15,5.18V72.6c0,1.74-1.41,3.15-3.15,3.15 s-3.15-1.41-3.15-3.15V7.33c0-0.28-0.12-0.54-0.3-0.73c-0.19-0.19-0.45-0.3-0.73-0.3H7.33c-0.28,0-0.54,0.12-0.73,0.3 C6.42,6.8,6.3,7.05,6.3,7.33v108.21c0,0.28,0.12,0.54,0.3,0.73c0.19,0.19,0.45,0.3,0.73,0.3H56.85L56.85,116.58z M83.35,83.7 c0-1.73,1.41-3.14,3.14-3.14c1.73,0,3.14,1.41,3.14,3.14l-0.04,14.36l14.34,0.04c1.73,0,3.14,1.41,3.14,3.14s-1.41,3.14-3.14,3.14 l-14.35-0.04l-0.04,14.34c0,1.73-1.41,3.14-3.14,3.14c-1.73,0-3.14-1.41-3.14-3.14l0.04-14.35l-14.34-0.04 c-1.73,0-3.14-1.41-3.14-3.14c0-1.73,1.41-3.14,3.14-3.14l14.36,0.04L83.35,83.7L83.35,83.7z M31.54,64.59 c-1.74,0-3.15-1.41-3.15-3.15c0-1.74,1.41-3.15,3.15-3.15h41.61c1.74,0,3.15,1.41,3.15,3.15c0,1.74-1.41,3.15-3.15,3.15H31.54 L31.54,64.59z" />
                            </g>
                        </svg>
                    </a>
                    <a className={styles['btn']} data-role='sourceCode' href='#' title="edit source code">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <text x="0" y="14" font-size="12" fill="currentColor">&lt;/&gt;
                            </text>
                        </svg>
                    </a>
                </div>
            </div>
            <div
                data-index={index}
                ref={editorRef}
                contentEditable
                className={styles.editor}
                onInput={(e) => handleChange(e)} 
                onKeyDown={handleKey}
                suppressContentEditableWarning={true} 
            >
            </div>
               
        </>
    );
};

export default ReactEditor;
