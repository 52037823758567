import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// import Sal from "sal.js";

import Context from "@home/context/Context";
import HeaderTop from "@home/Header/HeaderTop/HeaderTop";
import Header from "@home/Header/Header";
import PopupMobileMenu from "@home/Header/PopUpMobileMenu";
import Footer from "@home/Footers/Footer";
import Copyright from "@home/Footers/Copyright";
import Breadcrumb from "@home/Common/Breadcrumb";
import BackToTop from "../backToTop";

import Privacy from "./Privacy";


import styles from '../index.module.css'

const PrivacyPage = () => {

  const { t } = useTranslation()


  return (
    <>
      <main className={styles["page-wrapper"]} >
        <Context>
          <HeaderTop />
          <Header
            headerTransparent="header-transparent"
            headerSticky="header-sticky"
            btnClass="rainbow-gradient-btn"
          />
          <PopupMobileMenu />
          <Breadcrumb 
          title={t("privacy.breadcrumb.text1")} 
          text={t("privacy.breadcrumb.text2")}
          />



          <div className={`${styles["rainbow-testimonial-area"]} ${styles["rainbow-section-gap"]}`} >
            <div className={`${styles.container}`} >
              <Privacy />
            </div>
          </div>


          <BackToTop />
          <Footer />
          <Copyright />
        </Context>
      </main>
    </>
  );
};

export default PrivacyPage;
