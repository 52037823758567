import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import coinSound from "./coin-2.mp3";
import addSound from "./coin-1.mp3";
import chunkSound from "./coin-3.mp3";
import collisionSound from "./collision.mp3";
import trashSound from "./trash.mp3";
import { usePosition } from "./PositionContext";
import styles from "./Map.module.css";

import { ReactComponent as IconDrive } from "./assets/images/graph-column-1.svg"


import {
    readSemantic,
    readEmbedding,
    readChunk,
    readWebsite,
    readUrl,
    readWeb,
    readContact,
} from '../../views/app/v1-1/actions/game';

import {
    isInsideCircle,
    updatePointerInventory,
    playAudio,
    createWallsAroundCircle,
    handleTeleport
} from "./utils";

const Map = () => {
    const dispatch = useDispatch();

    const { chunks } = useSelector(state => state.game);
    const { position, setPosition, circles, setCircles, pointerInventory, setPointerInventory, walls, setWalls } = usePosition();

    const mapContainerRef = useRef(null);
    const [pointerValue, setPointerValue] = useState(0);
    const [shake, setShake] = useState(false);
    const [hoveredCircleIndex, setHoveredCircleIndex] = useState(null);
    const [animations, setAnimations] = useState([]);
    const [pointerStack, setPointerStack] = useState([]);
    const [collectedData, setCollectedData] = useState([]);

    const audioRef = useRef(null);
    const addAudioRef = useRef(null);
    const chunkAudioRef = useRef(null);
    const collisionAudioRef = useRef(null);
    const trashAudioRef = useRef(null);
    const [circleTexts, setCircleTexts] = useState({});

    useEffect(() => {
        if (mapContainerRef.current) {
            const mapWidth = 4000;
            const mapHeight = 4000;
            const viewportWidth = mapContainerRef.current.clientWidth;
            const viewportHeight = mapContainerRef.current.clientHeight;

            const maxScrollLeft = mapWidth - viewportWidth;
            const maxScrollTop = mapHeight - viewportHeight;

            const scrollLeft = Math.min(Math.max(position.left - viewportWidth / 2, 0), maxScrollLeft);
            const scrollTop = Math.min(Math.max(position.top - viewportHeight / 2, 0), maxScrollTop);

            mapContainerRef.current.scrollLeft = scrollLeft;
            mapContainerRef.current.scrollTop = scrollTop;
        }
    }, [position]);

    const [stateInterval, setStateInterval] = useState(null)

    useEffect(() => {
        const interval = setInterval(() => {
            let shakeScreen = false;
            let playCollectSound = false;
            let playAddSound = false;
            let playTrashSound = false;
            let playCollisionSound = false;
            let newAnimations = [];
            let newCircles = [];
            let newCollectedData = [...collectedData];
            let newPointerStack = [];
            let updatedPointerValue = pointerValue;

            if (stateInterval) {
                const circleTypes = [
                    { type: 'collect', name: 'Manzana', value: Math.floor(Math.random() * 10), top: Math.floor(Math.random() * 3000), left: Math.floor(Math.random() * 3000) },
                    { type: 'add', name: 'Manzana', targetValue: 5, top: Math.floor(Math.random() * 3000), left: Math.floor(Math.random() * 3000) },
                    { type: 'trash', top: Math.floor(Math.random() * 3000), left: Math.floor(Math.random() * 3000) },
                    { type: 'coin', coinCount: 1, maxCoins: 10, top: Math.floor(Math.random() * 3000), left: Math.floor(Math.random() * 3000) },
                    { type: 'popup', popup: Math.floor(Math.random() * 3) + 1, top: Math.floor(Math.random() * 3000), left: Math.floor(Math.random() * 3000) }
                ];

                let randomCircle = circleTypes[Math.floor(Math.random() * circleTypes.length)];

                if (stateInterval == 'add') {
                    randomCircle.top = position.top
                    randomCircle.left = position.left
                } else if (stateInterval == 'random') {

                }

                newCircles.push({
                    id: `circle_${Date.now()}`,
                    ...randomCircle
                });

                setStateInterval(null)
            }

            circles.forEach((circle, index) => {
                let updatedCircle = { ...circle };
                let newValue = circle.value;


                if (circle.type === 'data') {
                    if (isInsideCircle(circle, position)) {
                        newCollectedData.push({ id: circle.id, data: circle.data });
                    }
                } else if (circle.type === 'gpt') {
                    if (isInsideCircle(circle, position)) {
                        if (newCollectedData.length > 0) {
                            handleWeb(circle.id)
                            newCollectedData = []; 
                        }
                    }
                } else if (circle.type === 'teleport') {
                    if (isInsideCircle(circle, position)) {
                        handleTeleport(circle, circles, setPosition, mapContainerRef);
                    }
                } else if (circle.type === 'coin') {
                    let updatedCoinCount = circle.coinCount;

                    if (updatedCoinCount < circle.maxCoins) {
                        updatedCoinCount += 1;
                        updatedCircle.coinCount = updatedCoinCount;
                    }

                    if (isInsideCircle(circle, position) && !circle.completed) {
                        updatedPointerValue += circle.coinCount;
                        playCollectSound = true;
                        updatedCircle.coinCount = 0;
                        newPointerStack.push({
                            key: `collect_${circle.id}`,
                            startX: circle.left,
                            startY: circle.top,
                            endX: position.left,
                            endY: position.top,
                            type: 'collect',
                        });

                        updatePointerInventory(setPointerInventory, 'coins', circle.coinCount);
                    }
                } else if (circle.type === 'add') {
                    if (isInsideCircle(circle, position) && pointerInventory[circle.name] > 0 && !circle.completed) {
                        newValue = circle.value + 1;
                        updatedPointerValue -= 1;
                        updatePointerInventory(setPointerInventory, circle.name, -1);
                        shakeScreen = true;
                        playAddSound = true;
                        const numBalls = Math.min(pointerInventory[circle.name], 10);
                        newAnimations.push(...Array(numBalls).fill(null).map((_, i) => ({
                            key: `add_${circle.id}_${i}`,
                            startX: position.left,
                            startY: position.top,
                            endX: circle.left,
                            endY: circle.top,
                            type: 'add',
                            index: i
                        })));
                        newPointerStack.pop();
                    }
                } else if (circle.type === 'collect') {
                    if (isInsideCircle(circle, position) && circle.value > 0 && !circle.completed) {
                        newValue = circle.value - 1;
                        updatedPointerValue += 1;
                        updatePointerInventory(setPointerInventory, circle.name, 1);
                        shakeScreen = true;
                        playCollectSound = true;
                        newPointerStack.push({
                            key: `collect_${circle.id}`,
                            startX: circle.left,
                            startY: circle.top,
                            endX: position.left,
                            endY: position.top,
                            type: 'collect',
                        });
                    }
                } else if (circle.type === 'trash') {
                    if (isInsideCircle(circle, position)) {
                        const itemToRemove = Object.keys(pointerInventory).find(item => pointerInventory[item] > 0);
                        if (itemToRemove) {
                            updatePointerInventory(setPointerInventory, itemToRemove, -1);
                            playTrashSound = true;
                        }
                    }
                } else if (circle.type === 'popup') {
                    if (isInsideCircle(circle, position)) {
                        const circleTypes = [
                            { type: 'collect', name: 'Manzana', value: Math.floor(Math.random() * 10), top: Math.floor(Math.random() * 3000), left: Math.floor(Math.random() * 3000) },
                            { type: 'add', name: 'Manzana', targetValue: 5, top: Math.floor(Math.random() * 3000), left: Math.floor(Math.random() * 3000) },
                            { type: 'trash', top: Math.floor(Math.random() * 3000), left: Math.floor(Math.random() * 3000) },
                            { type: 'coin', coinCount: 1, maxCoins: 10, top: Math.floor(Math.random() * 3000), left: Math.floor(Math.random() * 3000) },
                            { type: 'popup', popup: Math.floor(Math.random() * 3) + 1, top: Math.floor(Math.random() * 3000), left: Math.floor(Math.random() * 3000) }
                        ];
                        const randomCircle = circleTypes[Math.floor(Math.random() * circleTypes.length)];
                        const newCircle = {
                            id: `circle_${Date.now()}`,
                            ...randomCircle
                        };
                        newCircles.push(newCircle);


                        const entrancePosition = 'top';
                        const circleRadius = 50;
                        const wallWidth = 10;
                        const wallsAroundCircle = createWallsAroundCircle(newCircle, circleRadius, wallWidth, entrancePosition);
                        setWalls(prevWalls => [...prevWalls, ...wallsAroundCircle]);

                    }
                }

                newCircles.push({
                    ...updatedCircle,
                    value: newValue,
                    completed: circle.completed,
                });

            });

            if (shakeScreen) {
                setShake(true);
                setTimeout(() => setShake(false), 500);
            }

            if (playCollectSound) {
                playAudio(audioRef);
            }

            if (playAddSound) {
                playAudio(addAudioRef);
            }

            if (playTrashSound) {
                playAudio(trashAudioRef);
            }

            if (playCollisionSound) {
                playAudio(collisionAudioRef);
            }

            setCircles(newCircles);
            setAnimations(prev => [...prev, ...newAnimations]);
            setPointerStack(newPointerStack);
            setPointerValue(updatedPointerValue);
            setCollectedData(newCollectedData);
        }, 1000);

        return () => clearInterval(interval);
    }, [position, circles, collectedData, pointerInventory, pointerStack, stateInterval]);

    useEffect(() => {
        const circleIndex = circles.findIndex(circle => isInsideCircle(circle, position));
        setHoveredCircleIndex(circleIndex >= 0 ? circleIndex : null);
    }, [position, circles]);

   

    const handleAnimationEnd = (key) => {
        setAnimations((prev) => prev.filter((anim) => anim.key !== key));
    };

    const handleDeleteCircle = (index) => {
        setCircles((prevCircles) => prevCircles.filter((_, i) => i !== index));
    };

    const handleChunk = async (circleId) => {
        dispatch(readChunk({
            text: 'hello world',
            id: circleId
        }));
        playAudio(chunkAudioRef);
    };

    const handleEmbedding = async (circleId) => {
        dispatch(readEmbedding({
            text: 'hello world',
            id: circleId
        }));
    };

    const handleSemantic = async (circleId) => {
        dispatch(readSemantic({
            text: 'hello world',
            id: circleId
        }));
    };

    const handleWebsite = async (circleId) => {
        dispatch(readWebsite({
            text: 'hello world',
            id: circleId
        }));
    };


    const handleUrl = async (circleId) => {
        dispatch(readUrl({
            text: 'hello world',
            id: circleId
        }));
    };


    const handleWeb = async (circleId) => {
        dispatch(readWeb({
            text: 'hello world',
            id: circleId
        }));
    };


    const handleContact = async (circleId) => {
        dispatch(readContact({
            text: 'hello world',
            id: circleId
        }));
    };


    const handleMapDoubleClick = (e) => {
        const rect = mapContainerRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left + mapContainerRef.current.scrollLeft;
        const y = e.clientY - rect.top + mapContainerRef.current.scrollTop;

        setPosition({ left: x, top: y });
    };

    useEffect(() => {
        setCircleTexts(prevTexts => {
            const updatedTexts = { ...prevTexts };

            chunks.forEach(chunk => {
                if (chunk.id) {
                    if (chunk.type == 'website') {
                        if (!updatedTexts[chunk.id]) {
                            updatedTexts[chunk.id] = { html: '', css: '' };
                        }

                        updatedTexts[chunk.id] = {
                            html: chunk.html,
                            css: chunk.css,
                        }
                    } else if (chunk.type == 'website-spider') {
                        if (!updatedTexts[chunk.id]) {
                            updatedTexts[chunk.id] = { linkMap: '', allUrls: '' };
                        }

                        updatedTexts[chunk.id] = {
                            linkMap: chunk.linkMap,
                            allUrls: chunk.allUrls,
                        }

                    } else if (chunk.type == 'website-embed') {
                        if (!updatedTexts[chunk.id]) {
                            updatedTexts[chunk.id] = { texts: '' };
                        }

                        updatedTexts[chunk.id] = {
                            texts: chunk.texts,
                        }


                    } else if (chunk.type == 'website-contact') {
                        if (!updatedTexts[chunk.id]) {
                            updatedTexts[chunk.id] = { contact: '' };
                        }

                        updatedTexts[chunk.id] = {
                            texts: chunk.texts,
                        }


                    } else {
                        if (!updatedTexts[chunk.id]) {
                            updatedTexts[chunk.id] = { text: '', coin: 0, token: 0 };
                        }

                        const tokenInput = chunk.input.token;
                        const tokenOutput = chunk.output.token;

                        const tokenInputPrice = chunk.input.price;
                        const tokenOutputPrice = chunk.output.price;

                        updatedTexts[chunk.id].text += chunk.text || '';

                        if (!updatedTexts[chunk.id].coin) updatedTexts[chunk.id].coin = tokenInputPrice;
                        updatedTexts[chunk.id].coin += tokenOutputPrice || 0;

                        if (!updatedTexts[chunk.id].token) updatedTexts[chunk.id].token = tokenInput;
                        updatedTexts[chunk.id].token += tokenOutput || 0;
                    }
                }
            });

            return updatedTexts;
        });
    }, [chunks]);



    return (
        <div
            className={`${styles.mapContainer}`}
            ref={mapContainerRef}
            onDoubleClick={handleMapDoubleClick}
        >
            <div className={styles.buttons}>
                <button
                    className={styles.buttonChunk}
                    onClick={() => handleChunk('circleId')}
                >
                    chunk button
                </button>
                {hoveredCircleIndex !== null && (
                    <button
                        className={styles.deleteButton}
                        onClick={() => handleDeleteCircle(hoveredCircleIndex)}
                    >
                        Borrar Círculo
                    </button>
                )}
                {pointerInventory.coins >= 10 && (
                    <button
                        className={styles.buttonCoin}
                        onClick={() => {
                            setStateInterval('add')
                            updatePointerInventory(setPointerInventory, 'coins', -10);
                        }}
                    >
                        Usar 10 Coins
                    </button>
                )}
                {pointerInventory.coins >= 20 && (
                    <button
                        className={styles.buttonCoin}
                        onClick={() => {
                            setStateInterval('random')
                            updatePointerInventory(setPointerInventory, 'coins', -20);
                        }}
                    >
                        20 Coins - Random
                    </button>
                )}
            </div>
            <div className={styles.inventoryBox}>
                <h3>Inventario</h3>
                <ul>
                    {Object.entries(pointerInventory).map(([itemName, amount]) => (
                        <li key={itemName}>{itemName}: {amount}</li>
                    ))}
                </ul>
            </div>

            <div className={styles.coordinatesBox}>
                <p>X: {Math.round(position.left)}</p>
                <p>Y: {Math.round(position.top)}</p>
            </div>

            <div className={`${styles.map} ${styles.transform3D} ${shake ? styles.shake : ''}`}>
                <div
                    className={styles.redDot}
                    style={{ top: position.top - 25 + "px", left: position.left - 25 + "px" }}
                >
                    <span className={styles.pointerValue}>{pointerValue}</span>

                    {collectedData.length > 0 && (
                        <div className={styles.collectedData}>
                            {collectedData.map((dataItem, index) => (
                                <div key={index}>
                                    {dataItem.id}: {JSON.stringify(dataItem.data)}
                                </div>
                            ))}
                        </div>
                    )}

                    {pointerStack.map(({ key, startX, startY, type }, index) => (
                        <div
                            key={key}
                            className={`${styles.pointerBall} ${type === 'collect' ? styles.collectBall : styles.addBall}`}
                            style={{
                                position: 'absolute',
                                top: `${index * 10}px`,
                                left: '50%',
                                transform: 'translateX(-50%)'
                            }}
                        />
                    ))}
                </div>

                {circles.map((circle, index) => {
                    const targetValue = circle.targetValue || Infinity;
                    const fillPercentage = circle.value / targetValue * 100;
                    return (
                        <div
                            key={circle.id}
                            className={`${styles.circle} ${circle.completed ? styles.completed : ''} ${index === hoveredCircleIndex ? styles.hovered : ''}`}
                            style={{
                                top: circle.top - 50 + "px",
                                left: circle.left - 50 + "px",
                                borderColor: circle.type === 'coin'
                                    ? 'yellow'
                                    : circle.type === 'trash'
                                        ? 'red'
                                        : circle.type === 'add'
                                            ? "pink"
                                            : circle.type === 'popup'
                                                ? 'purple'
                                                : circle.type === 'gpt'
                                                    ? 'transparent'
                                                    : circle.type === 'data'
                                                        ? 'green' : 'blue',
                                backgroundColor: circle.type === 'data'
                                    ? 'transparent'
                                    : circle.type === 'gpt'
                                        ? 'dark'
                                        : circle.type === 'coin'
                                            ? 'transparent'
                                            : circle.type === 'add'
                                                ? `rgba(255, 105, 180, ${circle.completed ? 0.7 : fillPercentage / 100})`
                                                : "transparent",
                            }}
                            onMouseEnter={() => setHoveredCircleIndex(index)}
                            onMouseLeave={() => setHoveredCircleIndex(null)}
                        >
                            {circle.type === 'data' ? (
                                <div>Data</div>
                            ) : circle.type === 'gpt' ? (
                                <div className={styles.bar}>

                                    <IconDrive  />
                                </div>
                            ) : circle.type === 'trash' ? (
                                <div>Trash</div>
                            ) : circle.type === 'add' ? (
                                <>
                                    <div>{circle.name}</div>
                                    <div>{circle.value} / {circle.targetValue}</div>
                                </>
                            ) : circle.type === 'coin' ? (
                                <div>{circle.coinCount} / {circle.maxCoins} Coins</div>
                            ) : circle.type === 'popup' ? (
                                <div>Popup {circle.popup}</div>
                            ) : (
                                <div>{circle.name} ({circle.value})</div>
                            )}
                            {circle.type === 'add' && !circle.completed && (
                                <div className={styles.progressBar} style={{ width: `${fillPercentage}%` }}></div>
                            )}
                            {(circleTexts[circle.id] && circleTexts[circle.id].coin) && (
                                <div className={styles.circleText}>
                                    {circleTexts[circle.id].text}
                                    <div>Coins: {circleTexts[circle.id].coin}</div>
                                    <div>Tokens: {circleTexts[circle.id].token}</div>
                                </div>
                            )}
                            {(circleTexts[circle.id] && circleTexts[circle.id].html) && (
                                <div className={styles.circleText}>
                                    <div dangerouslySetInnerHTML={{
                                        __html: `
                                        ${circleTexts[circle.id].html}
                                        ${circleTexts[circle.id].css}
                                    `}} />
                                </div>
                            )}
                            {(circleTexts[circle.id] && circleTexts[circle.id].allUrls) && (
                                <div className={styles.circleText}>
                                    <ul>
                                        {circleTexts[circle.id].allUrls.map((url, index) => (
                                            <li key={index}>
                                                {url}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    );
                })}

                {walls.map((wall, index) => (
                    <div
                        key={index}
                        className={styles.wall}
                        style={{
                            top: wall.top + "px",
                            left: wall.left + "px",
                            width: wall.width + "px",
                            height: wall.height + "px",
                        }}
                    />
                ))}

                {animations.map(({ key, startX, startY, endX, endY, type, index }) => (
                    <div
                        key={key}
                        className={`${styles.animation} ${type === 'collect' ? styles.collectBall : styles.addBall}`}
                        style={{
                            left: startX + "px",
                            top: startY + "px",
                            transform: `translate(${endX - startX}px, ${endY - startY}px)`,
                            animation: `move-${type} 1s linear`,
                            zIndex: 10 + index,
                            '--start-x': startX,
                            '--start-y': startY,
                            '--end-x': endX,
                            '--end-y': endY,
                        }}
                        onAnimationEnd={() => handleAnimationEnd(key)}
                    />
                ))}


            </div>
            <audio ref={audioRef} src={coinSound} />
            <audio ref={addAudioRef} src={addSound} />
            <audio ref={chunkAudioRef} src={chunkSound} />
            <audio ref={collisionAudioRef} src={collisionSound} />
            <audio ref={trashAudioRef} src={trashSound} />


        </div>
    );
};

export default Map;



const Grid = () => {
    const gridSize = 4000; 
    const cellSize = 50; 
    const numCells = gridSize / cellSize; 

    const cells = [];
    for (let i = 0; i < numCells * numCells; i++) {
        cells.push(<div key={i} className={styles.gridCell} />);
    }

    return (
        <div className={styles.gridContainer}>
            {cells}
        </div>
    );
};

