import { createSlice } from '@reduxjs/toolkit'

import {
	addContact
} from '../actions/contact'

const contactSlice = createSlice({
	name: 'contact',
	initialState: {
		loading: false,
		contacts: [],
		groups: [],
	},
	reducers: {
	
	},
	extraReducers: (builder) => {
		builder
		
			.addCase(addContact.fulfilled, (state, action) => {
				const index = state.contacts.findIndex(
					(contact) => contact.id === action.payload
				)

				if (index > -1) {
					state.contacts[index] = action.payload
				} else {
					state.contacts.push(action.payload)
				}
			})
			
	},
})

export const {
} = contactSlice.actions

export default contactSlice.reducer
