import { configureStore } from '@reduxjs/toolkit';

const socketReducer = (state = null, action) => {
    switch (action.type) {
      case 'SET_USER_SOCKET_ID':
        return {
          ...state,
          socketId: action.payload,
        };
      default:
        return state;
    }
  };

export const store = configureStore({
  reducer: {
    socket: socketReducer,
  },
});