import React, { useState } from 'react'
import DripImage from './assets/drip.png'
import BrushImage from './assets/brush.png'
import ShotIcon from './assets/shot-icon.png'
import ToolsIcons from './assets/tools-icons.png'
import Card1Image from './assets/Base.png'
import Card2Image from './assets/bucket.png'
import Card3Image from './assets/net.png'
import ToolsNavImage from './assets/tools-nav.png'
import TabletImage from './assets/tablet.png'
import Gapper1Image from './assets/gapper-1.png'

import Video from './components/video'

import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

const Editor = () => {
  const { t } = useTranslation()
  const { lng, version } = useParams()

  const navigate = useNavigate()

  const [video, setVideo] = useState(false)


  return (
    <div className="relative w-full sm:h-full " id="editor">
      <div className="container mx-auto">
        <section className="home-section-1">
          <div className="editor-gradient"></div>
          <div className="flex lg:flex ">
            <div className="relative z-[1] lg:pt-[100px]">
              <div className="mb-5 flex items-center ml-[-8px] absolute left-0 top-[20px] w-[300px]">
                <div className="bg-primary-500 w-[40px] h-[40px] p-3 flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                  >
                    <path
                      d="M12.4418 0.21934C12.5648 0.0788894 12.7316 0 12.9056 0C13.0795 0 13.2463 0.0788894 13.3693 0.21934L19.0568 6.71934C19.1797 6.85997 19.2487 7.05059 19.2487 7.24934C19.2487 7.44809 19.1797 7.63871 19.0568 7.77934L13.3693 14.2793C13.2891 14.3781 13.1871 14.4502 13.074 14.4883C12.9609 14.5263 12.8409 14.5287 12.7267 14.4953C12.6125 14.462 12.5083 14.394 12.425 14.2986C12.3418 14.2032 12.2826 14.084 12.2537 13.9533C12.2244 13.823 12.2265 13.686 12.2596 13.5568C12.2927 13.4277 12.3556 13.3111 12.4418 13.2193L17.6656 7.24934L12.4418 1.27934C12.3189 1.13871 12.2499 0.948091 12.2499 0.74934C12.2499 0.550589 12.3189 0.359965 12.4418 0.21934ZM6.8068 0.21934C6.92969 0.359965 6.99872 0.550589 6.99872 0.74934C6.99872 0.948091 6.92969 1.13871 6.8068 1.27934L1.58305 7.24934L6.8068 13.2193C6.89318 13.311 6.95633 13.4276 6.9896 13.5569C7.02286 13.6861 7.025 13.8232 6.9958 13.9537C6.9666 14.0843 6.90713 14.2034 6.82367 14.2985C6.74021 14.3937 6.63583 14.4613 6.52155 14.4943C6.40747 14.5278 6.28762 14.5254 6.1746 14.4876C6.06158 14.4498 5.95957 14.3778 5.8793 14.2793L0.1918 7.77934C0.0689063 7.63871 -0.00012207 7.44809 -0.00012207 7.24934C-0.00012207 7.05059 0.0689063 6.85997 0.1918 6.71934L5.8793 0.21934C6.00235 0.0788894 6.16914 0 6.34305 0C6.51696 0 6.68375 0.0788894 6.8068 0.21934Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="ml-4">
                  <h4 className="text-primary-500 font-extrabold uppercase text-[20px]">
                    {t('Entra gratis')}
                  </h4>
                </div>
              </div>
              <div className="editor-left-border"></div>
            </div>
            <div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="col-span-2 relative order-2 md:order-1 lg:order-1">
                  <div className="md:mt-[100px]">
                    <div className="absolute top-[-130px] w-[120px] lg:w-[200px] md:top-[50px] md:absolute right-0 ">
                      <div className="flex justify-end">
                        <img src={BrushImage} alt="brush" />
                      </div>
                    </div>
                    <div className="text-gray-600 dark:text-white pl-3 lg:pl-0">
                      <div className="md:pl-10 mt-[10px]">
                        <h5 className="text-[20px] lg:text-[42px] mb-0 leading-none">
                          {t('Aythen es una sistema operativo')}
                        </h5>
                        <h2 className="leading-none ">
                          <span className="text-[50px] lg:text-[80px]">
                            {t('nube')}
                          </span>
                          <span className="text-[50px] font-extrabold lg:text-[80px] lg:ml-3 ml-2">
                            {t('colaborativa')}
                          </span>
                        </h2>
                        <p className="text-[20px] lg:text-[25px] leading-normal drop-shadow-xl text-left">
                          {t(`donde agendar eventos, guardar y compartir documentos, realizar reuniones, mensajeria
                          instantanea, guardar millones de datos, crear bocetos y aplicaciones virtuales 
                            llamadas "addons" y "vectores".`)}
                        </p>
                        <Video video={video} />
                        <div className="mt-3 lg:mt-5 flex items-center">
                          <button
                            onClick={() => navigate(`/${lng}/login`)}
                            className="bg-[#0000FF] text-white font-bold px-6 lg:px-8 py-2 lg:py-4 rounded h-[45px] mr-1 flex items-center whitespace-nowrap"
                          >
                            {t('Iniciar sesión')}
                          </button>
                          <button
                            onClick={() => setVideo('home')}
                            className="bg-[#0000FF] text-white font-bold px-4 py-2 lg:py-4 rounded lg:ml-5 h-[45px] mr-1 flex items-center"
                          >
                            <img src={ShotIcon} />
                          </button>
                          <button
                            onClick={() => navigate(`/${lng}/home`)}
                            className="bg-[#3F3F3F] text-white font-bold px-6 lg:px-8 py-2 lg:py-4 rounded lg:ml-5 h-[45px] mr-1 flex items-center whitespace-nowrap"
                          >
                            {t('Negocios')}
                          </button>
                        </div>
                        <p className="mt-4">
                          {t('Integrable a Internet y sus aplicaciones de terceros')}
                        </p>
                        <div className="mt-5">
                          <img src={ToolsIcons} className="lg:w-[60%]" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-end relative order-1 md:order-2 lg:order-2">
                  <div className="w-full initial lg:absolute lg:right-[-30px] lg:top-[15px]">
                    <img src={DripImage} className="w-full" />
                    <div className="w-full initial mt-3">
                      <div className="rounded-lg bg-white dark:bg-gray-900 flex justify-between items-center ">
                        <input
                          type="text"
                          className="bg-transparent w-full focus:outline-none py-2 px-3 text-[20px] text-[#444]"
                          placeholder="Registra tu dominio y empieza"
                        />
                        <button
                          className="bg-[blue] px-3 py-2 lg:px-5 lg:py-3"
                        >
                          <svg
                            fill="none"
                            style={{ stroke: '#fff' }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5 lg:mt-[50px]">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                  <div className="w-full z-[10] relative">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 p-5 h-[400px] mb-4 lg:mb-0">
                      <div className="w-full text-gris-300 dark:text-gray-400">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          {t('PRIMER BENEFICIO')}
                        </p>
                        <h5 className="text-[20px] lg:text-[30px] my-1 lg:my-2 font-bold lg:font-light ">
                          {t('Escala tus datos')}
                        </h5>
                        <p className="lg:text-[15px]">
                          {t(`Desde una pequeño mvp como producto hasta empresas con multiples departamentos,
                          el objetivo de vectorizar y controlar desde una pequeña variable hasta millones de parametros
                          para calcular un dataset.`)}
                        </p>
                      </div>
                      <img
                        className="ml-[100px] mt-[-30px]"
                        src={Card1Image}
                      />
                    </div>
                  </div>
                  <div className="z-[10]">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 p-5 h-[400px] lg:h-[400px] mb-4 lg:mb-0 relative z-[5]">
                      <div className="md:w-[60%] text-gris-300 dark:text-gray-400">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          {t('SEGUNDO BENEFICIO')}
                        </p>
                        <h5 className="text-[20px] lg:text-[30px] my-1 lg:my-2 font-bold lg:font-light">
                          {t('En la nube')}
                        </h5>
                        <p className="lg:text-[15px] w-[80%] lg:w-full">
                          {t(`Crea salas de reuniones con tu equipo y comparte archivos en tu escritorio en la nube, abre
                          multiples proyectos desde cualquier lugar y gestiona tus documentos con las ventajas del cloud.`)}
                        </p>
                      </div>
                      <img
                        src={Card2Image}
                        className="absolute  md:top-[50px] lg:top-[200px] md:right-[-20px] -right:left-10 z-20 w-[230px] lg:w-[300px]"
                      />
                      <img
                        src={ToolsNavImage}
                        className="absolute bottom-0 right-[-20px] w-[200px] md:top-[-150px] z-[5]"
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 p-5 h-[400px] lg:h-[400px] mb-8 lg:mb-0 relative z-[5]">
                      <div className="md:w-[90%] text-gris-300 dark:text-gray-400">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          {t('TERCER BENEFICIO')}
                        </p>
                        <h5 className="text-[20px] lg:text-[30px] my-1 lg:my-2 font-bold lg:font-light">
                          {t('Addons y Vectores')}
                        </h5>
                        <p className="lg:text-[15px]">
                          {t(`La dualidad del frontend y backend, diseño y lógica, en el sistema 
                          operativo la comunidad crea su matrix de datos organizandolos y creando
                          sus aplicaciones personalizadas gracias a su estructura modular.`)}
                        </p>
                      </div>
                      <img
                        src={Card3Image}
                        className="absolute w-[75%] bottom-[0px] right-0"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5  mb-8 lg:mb-0 relative z-[5]">
                      <div className="md:w-[90%] t">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          {t('CUARTO BENEFICIO')}
                        </p>
                        <h5 className="text-[20px] lg:text-[30px] my-1 lg:my-2 font-bold lg:font-light">
                          {t('Organización')}
                        </h5>
                        <p className="lg:text-[15px]">
                          {t(`Crea todos los escritorios que necesites para gestionar tus documentos
                          y usa nuestras herramientas de AI para analizar los datos y simular la experiencia del usuario 
                          para que los agentes puedan interactuar en tus archivos y módulos antes de la
                          implementación.`)}
                        </p>
                        <p className="mt-10 lg:text-[15px]">
                          {t(`Tenemos incorporados varios motores.`)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="md:col-span-2 lg:col-span-1 relative">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 p-5 lg:h-[400px] mb-8 lg:mb-0 relative z-[5]">
                      <div className="md:w-[80%] lg:w-[90%] text-gris-300 dark:text-gray-400">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          {t('QUINTO BENEFICIO')}
                        </p>
                        <h5 className="text-[20px] lg:text-[30px] my-1 lg:my-2 font-bold lg:font-light">
                          {t('Prototipado Interactivo')}
                        </h5>
                        <p className="lg:text-[15px]">
                          {t(`Además de la edición visual, ofrecemos un editor de
                          código integrado que permite a los usuarios acceder y
                          modificar el código subyacente de los módulos. Esto es
                          útil para aquellos que prefieren trabajar con código
                          directamente.`)}
                        </p>


                      </div>
                      <img
                        src={TabletImage}
                        className="absolute bottom-[0px] lg:bottom-[-50px] md:right-[0px] lg:right-[-100px] z-20 h-[200px] lg:h-[250px]"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-span-2 grid grid-cols-2 relative z-[10]">
                  <div className="relative ">
                    <div className="h-[80px]">
                      <div className="bg-light-200 dark:bg-[#0f131b] card-shadow rounded-[8px] md:w-[450px] py-2 px-5">
                        <div className="py-3">
                          <div className="flex justify-between w-full ">
                            <div className="flex w-full">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="22"
                                viewBox="0 0 23 22"
                                fill="none"
                              >
                                <path
                                  d="M0.155762 11C0.155762 4.925 5.08076 0 11.1558 0C17.2308 0 22.1558 4.925 22.1558 11C22.1558 17.075 17.2308 22 11.1558 22C5.08076 22 0.155762 17.075 0.155762 11ZM16.4358 8.28C16.5682 8.13774 16.6403 7.94969 16.6369 7.75537C16.6336 7.56105 16.555 7.3756 16.4178 7.238C16.2802 7.10076 16.0947 7.0222 15.9004 7.01884C15.7061 7.01549 15.518 7.08759 15.3758 7.22L9.40576 13.19L6.93576 10.72C6.7935 10.5876 6.60545 10.5155 6.41113 10.5188C6.21681 10.5222 6.03136 10.6008 5.89376 10.738C5.75652 10.8756 5.67796 11.0611 5.67461 11.2554C5.67125 11.4497 5.74335 11.6377 5.87576 11.78L8.87576 14.78C9.01639 14.9205 9.20701 14.9993 9.40576 14.9993C9.60451 14.9993 9.79514 14.9205 9.93576 14.78L16.4358 8.28Z"
                                  fill="#3FB950"
                                />
                              </svg>
                              <h5 className="ml-3 dark:text-light-500">
                                Build
                              </h5>
                            </div>
                            <span className="whitespace-nowrap text-light-100 dark:text-light-400">
                              1m 42s
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" absolute top-[100px] lg:left-[100px] z-[5]">
                      <div>
                        <div className="bg-light-200 dark:bg-[#0f131b] card-shadow rounded-[8px] lg:w-[450px] py-4 px-5">
                          <div className="py-3">
                            <div className="flex justify-between w-full ">
                              <div className="flex w-full">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="23"
                                  height="22"
                                  viewBox="0 0 23 22"
                                  fill="none"
                                >
                                  <path
                                    d="M0.155762 11C0.155762 4.925 5.08076 0 11.1558 0C17.2308 0 22.1558 4.925 22.1558 11C22.1558 17.075 17.2308 22 11.1558 22C5.08076 22 0.155762 17.075 0.155762 11ZM16.4358 8.28C16.5682 8.13774 16.6403 7.94969 16.6369 7.75537C16.6336 7.56105 16.555 7.3756 16.4178 7.238C16.2802 7.10076 16.0947 7.0222 15.9004 7.01884C15.7061 7.01549 15.518 7.08759 15.3758 7.22L9.40576 13.19L6.93576 10.72C6.7935 10.5876 6.60545 10.5155 6.41113 10.5188C6.21681 10.5222 6.03136 10.6008 5.89376 10.738C5.75652 10.8756 5.67796 11.0611 5.67461 11.2554C5.67125 11.4497 5.74335 11.6377 5.87576 11.78L8.87576 14.78C9.01639 14.9205 9.20701 14.9993 9.40576 14.9993C9.60451 14.9993 9.79514 14.9205 9.93576 14.78L16.4358 8.28Z"
                                    fill="#3FB950"
                                  />
                                </svg>
                                <h5 className="ml-3 dark:text-light-500">
                                  Initialize CodeQL
                                </h5>
                              </div>
                              <span className="whitespace-nowrap text-light-100 dark:text-light-400">
                                1m 42s
                              </span>
                            </div>
                          </div>
                          <div className="py-3">
                            <div className="flex justify-between w-full ">
                              <div className="flex w-full">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="23"
                                  height="22"
                                  viewBox="0 0 23 22"
                                  fill="none"
                                >
                                  <path
                                    d="M0.155762 11C0.155762 4.925 5.08076 0 11.1558 0C17.2308 0 22.1558 4.925 22.1558 11C22.1558 17.075 17.2308 22 11.1558 22C5.08076 22 0.155762 17.075 0.155762 11ZM16.4358 8.28C16.5682 8.13774 16.6403 7.94969 16.6369 7.75537C16.6336 7.56105 16.555 7.3756 16.4178 7.238C16.2802 7.10076 16.0947 7.0222 15.9004 7.01884C15.7061 7.01549 15.518 7.08759 15.3758 7.22L9.40576 13.19L6.93576 10.72C6.7935 10.5876 6.60545 10.5155 6.41113 10.5188C6.21681 10.5222 6.03136 10.6008 5.89376 10.738C5.75652 10.8756 5.67796 11.0611 5.67461 11.2554C5.67125 11.4497 5.74335 11.6377 5.87576 11.78L8.87576 14.78C9.01639 14.9205 9.20701 14.9993 9.40576 14.9993C9.60451 14.9993 9.79514 14.9205 9.93576 14.78L16.4358 8.28Z"
                                    fill="#3FB950"
                                  />
                                </svg>
                                <h5 className="ml-3 dark:text-light-500">
                                  Autobuild
                                </h5>
                              </div>
                              <span className="whitespace-nowrap text-light-100 dark:text-light-400">
                                1m 24s
                              </span>
                            </div>
                          </div>
                          <div className="py-3">
                            <div className="flex justify-between w-full ">
                              <div className="flex w-full">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="23"
                                  height="22"
                                  viewBox="0 0 23 22"
                                  fill="none"
                                >
                                  <path
                                    d="M0.155762 11C0.155762 4.925 5.08076 0 11.1558 0C17.2308 0 22.1558 4.925 22.1558 11C22.1558 17.075 17.2308 22 11.1558 22C5.08076 22 0.155762 17.075 0.155762 11ZM16.4358 8.28C16.5682 8.13774 16.6403 7.94969 16.6369 7.75537C16.6336 7.56105 16.555 7.3756 16.4178 7.238C16.2802 7.10076 16.0947 7.0222 15.9004 7.01884C15.7061 7.01549 15.518 7.08759 15.3758 7.22L9.40576 13.19L6.93576 10.72C6.7935 10.5876 6.60545 10.5155 6.41113 10.5188C6.21681 10.5222 6.03136 10.6008 5.89376 10.738C5.75652 10.8756 5.67796 11.0611 5.67461 11.2554C5.67125 11.4497 5.74335 11.6377 5.87576 11.78L8.87576 14.78C9.01639 14.9205 9.20701 14.9993 9.40576 14.9993C9.60451 14.9993 9.79514 14.9205 9.93576 14.78L16.4358 8.28Z"
                                    fill="#3FB950"
                                  />
                                </svg>
                                <h5 className="ml-3 dark:text-light-500">
                                  Perform CodeQL Analyses
                                </h5>
                              </div>
                              <span className="whitespace-nowrap text-light-100 dark:text-light-400">
                                1m 36s
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="absolute right-[30px] top-[100px]">
                      <img
                        src={Gapper1Image}
                        className="z-20 m-end h-[70px]"
                      />
                    </div>
                  </div>

                  <div className="mt-[50px] mb-[50px] relative z-[4]">
                    <div>
                      <div className="border dark:border-gray-800 h-[40px] w-[80%] mx-auto bg-light-200 dark:bg-[#0f131b] rounded-[8px]"></div>
                      <div className="border dark:border-gray-800 h-[40px] w-[90%] mx-auto bg-light-200 dark:bg-[#0f131b] rounded-[8px] mt-[-10px]"></div>
                      <div className="border dark:border-gray-800 bg-light-200 dark:bg-[#0f131b] card-shadow rounded-[8px]  mx-auto mt-[-10px] lg:w-[100%]">
                        <div className="border-b dark:border-gray-800 py-3 px-5">
                          <h5 className="font-bold text-[#252C32] dark:text-light-400">
                            {t('Developer panel - Cloud Code')}
                          </h5>
                        </div>
                        <div className="py-5 px-5 text-[#252C32] dark:text-light-400">
                          <p className="font-semibold leading-none line-0 my-1">
                            <span className="text-[#de24ac]"> {'import'}</span>
                            <span className="text-[#0c66f2]"> {' *'}</span>
                            <span className="text-[#de24ac]"> {' as'}</span>
                            <span className="text-[#0c66f2]"> {' React'}</span>
                            <span className="text-[#de24ac]"> {' from'}</span>
                            <span className="text-[#ec6c1f]">
                              {' '}
                              {' "react"'}
                            </span>
                          </p>
                          <p className="font-semibold mb-10 mt-0">
                            <span className="text-[#de24ac]"> {'import'}</span>
                            <span className="text-[#0c66f2]">
                              {' '}
                              {' {Frame}'}
                            </span>
                            <span className="text-[#de24ac]"> {' from'}</span>
                            <span className="text-[#ec6c1f]">
                              {' '}
                              {' "framerReact"'}
                            </span>
                          </p>
                          <p className="font-semibold my-1">
                            <span className="text-[#de24ac]"> {'export'}</span>
                            <span className="text-[#2a20ae]">
                              {' '}
                              {' function'}
                            </span>
                            <span className="text-[#e6b628]">
                              {' '}
                              {' MyComponent'}
                            </span>
                            <span className="text-[#ffca00]"> {'(){'}</span>
                          </p>
                          <p className="font-semibold ml-4 my-1">
                            <span className="text-[#de24ac]"> {'return'}</span>
                            <span className="text-[#de24ac]"> {' ('}</span>
                          </p>
                          <p className="font-semibold ml-8 my-1">
                            <span className="text-[#cccccc]"> {'<'}</span>
                            <span className="text-[#3dd295]"> {'Frame'}</span>
                          </p>
                          <p className="font-semibold ml-12 my-1">
                            <span className="text-[#00aaff]"> {'animate'}</span>
                            <span className="text-[#c2c2c2]"> {'='}</span>
                            <span className="text-[#0c66f2]"> {'{'}</span>
                            <span className="text-[#ffca00]"> {'{'}</span>
                            <span className="text-[#00aaff]">
                              {' '}
                              {' rotate:'}
                            </span>
                            <span className="text-[#9fe66b]"> {' 360'}</span>
                            <span className="text-[#ffca00]"> {' }'}</span>
                            <span className="text-[#0c66f2]"> {'}'}</span>
                          </p>
                          <p className="font-semibold  ml-12 my-1">
                            <span className="text-[#00aaff]">
                              {' '}
                              {'transition'}
                            </span>
                            <span className="text-[#c2c2c2]"> {'='}</span>
                            <span className="text-[#0c66f2]"> {'{'}</span>
                            <span className="text-[#ffca00]"> {'{'}</span>
                            <span className="text-[#00aaff]">
                              {' '}
                              {' duration:'}
                            </span>
                            <span className="text-[#9fe66b]"> {' 2'}</span>
                            <span className="text-[#ffca00]"> {' }'}</span>
                            <span className="text-[#0c66f2]"> {'}'}</span>
                          </p>
                          <p className="font-semibold  ml-8 my-1">
                            <span className="text-[#cccccc]"> {'/>'}</span>
                          </p>
                          <p className="font-semibold ml-4 my-1">
                            <span className="text-[#de24ac]"> {')'}</span>
                          </p>
                          <p className="font-semibold ml-2 my-1">
                            <span className="text-[#ffca00]"> {'}'}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Editor
