const home = {
    banner: {
        banner: 'Profilbild bearbeiten',
        edit: 'Profil bearbeiten',
        addUser: 'Benutzer hinzufügen',
        title: 'Geteilte Benutzer',
        viewTeam: 'Team verwalten',
        team: {
            title: `Leute, die du vielleicht kennst`,
            button: `Alle anzeigen`,
            addContact: `Zu Kontakten hinzufügen`
        },
    },
    t1: `Organisation`,
    t2: `Bank`,
    t3: `Identitäts- und Zugriffsmanagement (IAM)`,
    t4: `Zugriff (IAM)`,
    t5: `Arbeite und verstehe deine Daten wie Vektoren`,
    t6: `Entdecke die neue Dimension der Daten. Eliminiere die Latenz, um x2000 
        mal effizienter zu arbeiten, perfekt für das Segmentieren, Analysieren und 
        Verstehen großer Datenmengen gleichzeitig.`,
    t60: `Füge Daten deinem Vektor hinzu, damit sie für dich arbeiten`,
    t61: `Zu einem Vektor gehen`,
    t7: `Neues erstellen`,
    t8: `Neues hinzufügen`,
    t9: `Neu`,
    t10: `Erstelle deine Addons, um vektorielle Komponenten zu erstellen, 
        in denen du darstellen kannst`,
    t11: `Es gibt kein Addon, möchtest du es erstellen`,
    t12: `Neues Addon`,
    t13: `Premium-Addon`,
    t14: `Datenverbrauch`,
    t15: `Merkmale`,
    t16: `Mehr sehen`,
    t17: `Notizen`,
    t18: `Neue hinzufügen`,
    t19: `Sichere Daten`,
    t20: " DSGVO - ISO 27001",
    t21: `Du kannst mit benutzerdefinierten Vektoren arbeiten.`,
    steps: {
        addCampaign: `Kampagne erstellen`,
        addAdvice: `Anzeigen erstellen`,
        t1: `Überprüfung der Politikkonformität`,
        t2: `Impressionen`,
        t3: `Konversionen`,
        title: `Alle Diagnosen der Kampagne`,
        s1: `Kontostatus`,
        s2: `Rechnungsstatus`,
        s3: `Kampagnenpolitiken`,
        s4: `Konversionsverfolgung`,
        s5: `Kampagnenbudget`,
        s6: `Bietstrategie`,
        s7: `Kampagnenstatus`,
        s8: `Anzeigeneffizienz`,
        text1: `Diese Kampagne hat keine Anzeigen`,
        text2: `Füge Anzeigen zu Anzeigengruppen hinzu, damit sie veröffentlicht werden`,
        text3: `Lösung finden`,
    },
    t22: {
        t0: `In`,
        t1: `wir verwenden`,
    },
    t23: {
        t0: `Bald verfügbar`,
        t1: `Bald wird es verfügbar sein, wir können dir eine Information 
            für das Pro`,
    },
    t24: {
        t0: `Keine Internetverbindung`,
        t1: `Überprüfe deine Wi-Fi- oder Mobilfunkverbindung, oder erhalte 
            eine Benachrichtigung, wenn die Verbindung wiederhergestellt ist`,
        t2: `Benachrichtigen`,
        t3: `Erneut versuchen`,
    },
    t25: {
        t0: `Nicht gefunden`,
        t1: `🚀 Nicht gefunden`,
        t2: `Änderungen anzeigen`,
    }
  }

export default home