import React from 'react'

import SectionImage from './assets/section-image.png'
import DragNavImage from './assets/drag-nav.png'

import Card1Image from './assets/img-1.png'
import Card2Image from './assets/img-2.png'
import Card3Image from './assets/img-3.png'
import Card4Image from './assets/img-4.png'
import Card5Image from './assets/img-5.png'
import Card6Image from './assets/img-6.png'

const DocsAi = () => {
  return (
    <div className="relative w-full sm:h-full lg:mb-[100px]" id="supportai">
      <div className="container mx-auto">
        <section className="home-section-1 ">
          <div className="flex">
            <div className="relative lg:pt-[10px] z-[0]">
              <div className="mb-5 flex items-center ml-[-8px] absolute left-0 top-[10px] w-[300px]">
                <div className="bg-[#FFA28B] z-[-1]  w-[50px] h-[50px] p-3 flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="24"
                    viewBox="0 0 18 24"
                    fill="none"
                  >
                    <path
                      d="M14.9993 10H9.99927V8L12.9993 7.992C13.7937 7.99253 14.5559 7.67773 15.1183 7.11674C15.6808 6.55574 15.9977 5.79443 15.9993 5V3C15.9982 2.20468 15.6818 1.44223 15.1194 0.879849C14.557 0.317469 13.7946 0.00105832 12.9993 0H4.99927C4.20394 0.00105832 3.4415 0.317469 2.87912 0.879849C2.31674 1.44223 2.00033 2.20468 1.99927 3V5C2.00033 5.79532 2.31674 6.55777 2.87912 7.12015C3.4415 7.68253 4.20394 7.99894 4.99927 8H7.99927V10H2.99927C2.20402 10.0013 1.44173 10.3178 0.879408 10.8801C0.317085 11.4425 0.000589681 12.2048 -0.000732422 13V21C0.000325896 21.7953 0.316736 22.5578 0.879116 23.1202C1.4415 23.6825 2.20394 23.9989 2.99927 24H14.9993C15.7946 23.9989 16.557 23.6825 17.1194 23.1202C17.6818 22.5578 17.9982 21.7953 17.9993 21V13C17.9979 12.2048 17.6814 11.4425 17.1191 10.8801C16.5568 10.3178 15.7945 10.0013 14.9993 10ZM4.99927 6C4.73413 5.99974 4.47993 5.89429 4.29245 5.70681C4.10497 5.51934 3.99953 5.26514 3.99927 5V3C3.99953 2.73486 4.10497 2.48066 4.29245 2.29319C4.47993 2.10571 4.73413 2.00026 4.99927 2L12.9913 2.002C13.257 2.00146 13.5122 2.10612 13.7011 2.29311C13.89 2.4801 13.9972 2.73423 13.9993 3V5C13.9972 5.26595 13.89 5.52029 13.7012 5.7076C13.5124 5.89491 13.2572 6.00001 12.9913 6H4.99927ZM15.9993 21C15.999 21.2651 15.8936 21.5193 15.7061 21.7068C15.5186 21.8943 15.2644 21.9997 14.9993 22H2.99927C2.73413 21.9997 2.47993 21.8943 2.29245 21.7068C2.10497 21.5193 1.99953 21.2651 1.99927 21V13C1.9998 12.7349 2.10532 12.4809 2.29274 12.2935C2.48017 12.1061 2.73421 12.0005 2.99927 12H14.9993C15.2644 12.0003 15.5186 12.1057 15.7061 12.2932C15.8936 12.4807 15.999 12.7349 15.9993 13V21Z"
                      fill="white"
                      fillOpacity="0.48"
                    />
                  </svg>
                </div>
                <div className="ml-4">
                  <h4 className="text-[#FFA28B] font-extrabold uppercase text-[25px]">
                    SUPPORT AI
                  </h4>
                </div>
              </div>
              <div className="support-left-border"></div>
            </div>
            <div>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                <div className="col-span-2 relative">
                  <div className="w-full mt-[100px]">
                    <div className="lg:pl-10 ">
                      <p className="text-[24px] text-[#554D56] lg:text-[48px] leading-tight drop-shadow-xl text-left pl-7 lg:pl-0">
                        <span className="text-[#FFA28B]">
                          Asesor inteligente para tu proyecto.
                        </span>
                        Un asesor con todo el background de tu proyecto, para
                        garantizar el soporte perfecto y poder crear nuevas
                        funcionalidades adaptadas que mejoraran la integración
                        de nuevas tecnologías.
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-end  ">
                    <img
                      src={DragNavImage}
                      className="lg:w-[200px] w-[80px]"
                    />
                  </div>
                </div>
                <div className="text-end relative">
                  <div className="w-full lg:absolute lg:left-[0px] lg:top-[0px] ">
                    <img src={SectionImage} className="w-full" />
                  </div>
                </div>
              </div>

              <div className="lg:mt-[50px]">
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3">
                  <div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto]  lg:h-[450px] relative mb-8 lg:mb-0">
                      <p className="mt-0 lg:text-[13px] uppercase">
                        PRIMER BENEFICIO
                      </p>
                      <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                        Disponibilidad 24/7{' '}
                      </h5>
                      <div className="lg:w-[90%]">
                        <p className="lg:text-[15px] mt-5">
                          La IA no tiene limitaciones de tiempo y puede ofrecer
                          soporte en cualquier momento, incluso fuera del
                          horario laboral o en días festivos. Esto garantiza que
                          los usuarios puedan obtener ayuda cuando la necesiten,
                          mejorando la satisfacción del cliente.
                        </p>
                        <img
                          src={Card1Image}
                          className="lg:absolute bottom-[0px] left-[0px] w-[200px] lg:w-[50%] z-20"
                        />
                      </div>
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mt-5 mb-8 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          CUARTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Acceso a Información Compleja
                        </h5>
                      </div>
                      <div className="lg:w-[90%]">
                        <p className="lg:text-[15px]">
                          La IA puede acceder y procesar grandes cantidades de
                          datos y documentos en cuestión de segundos,
                          proporcionando información detallada y precisa a los
                          usuarios en función de sus consultas.
                        </p>
                      </div>
                      <img
                        src={Card4Image}
                        className="lg:absolute bottom-[50px] left-[20%] w-[200px] lg:w-[50%] z-20"
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mb-8 lg:mb-0">
                      <p className="mt-0 lg:text-[13px] uppercase">
                        SEGUNDO BENEFICIO
                      </p>
                      <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                        Respuestas Rápidas e Instantáneas
                      </h5>
                      <div className="lg:w-[90%]">
                        <p className="lg:text-[15px]">
                          La IA puede proporcionar respuestas instantáneas a las
                          consultas de los usuarios, eliminando la necesidad de
                          esperar en colas telefónicas o a respuestas por correo
                          electrónico. Esto agiliza la resolución de problemas y
                          mejora la experiencia del usuario.
                        </p>
                      </div>
                      <img
                        src={Card2Image}
                        className="lg:absolute bottom-[0px] left-[20%] z-20 w-[200px] lg:w-[50%]"
                      />
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mt-5 mb-8 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          QUINTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Personalización
                        </h5>
                      </div>
                      <div className="lg:w-[80%]">
                        <p className="lg:text-[15px]">
                          La IA puede aprender y adaptarse a las preferencias y
                          necesidades individuales de los usuarios a lo largo
                          del tiempo. Esto permite proporcionar respuestas y
                          recomendaciones personalizadas, mejorando la
                          relevancia y utilidad del soporte.
                        </p>
                      </div>
                      <img
                        src={Card5Image}
                        className="z-20 lg:absolute bottom-[-80px] left-[20%] w-[200px] lg:w-[50%]"
                      />
                    </div>
                  </div>
                  <div className="relative md:col-span-2 lg:col-span-1 grid md:grid-cols-2 lg:block gap-4">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 relative h-[auto] lg:h-[450px] mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          TERCER BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Escala sin Problemas
                        </h5>
                        <p className="lg:text-[15px] mt-3">
                          La IA puede manejar múltiples consultas y solicitudes
                          simultáneamente, lo que permite que una organización
                          brinde soporte a un gran número de usuarios sin
                          aumentar significativamente la carga de trabajo del
                          personal humano.
                        </p>
                      </div>
                      <img
                        src={Card3Image}
                        className="lg:absolute bottom-[-30px] right-[0px] z-20 w-[200px] lg:w-[50%]"
                      />
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative  mt-5 mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          SEXTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Análisis y Tendencias
                        </h5>
                        <p className="lg:text-[15px] mt-3">
                          La IA puede analizar datos y patrones a gran escala
                          para identificar tendencias y problemas comunes en las
                          consultas de los usuarios. Esto ayuda a la
                          organización a tomar decisiones informadas para
                          mejorar productos y servicios.
                        </p>
                      </div>
                      <img
                        src={Card6Image}
                        className="z-20 lg:absolute bottom-[0px] left-[20%] w-[200px] lg:w-[50%]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default DocsAi
