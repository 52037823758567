import { configureStore } from '@reduxjs/toolkit'

import gameReducer from '../slices/gameSlice'
import ticketReducer from '../slices/ticketSlice'
import dashboardReducer from '../slices/dashboardSlice'
import addonReducer from '../slices/addonSlice'
import iamReducer from '../slices/iamSlice'
import assetsReducer from '../slices/assetsSlice'
import vectorReducer from '../slices/vectorSlice'
import kanbanReducer from '../slices/kanbanSlice'

import datatableReducer from '../slices/datatableSlices'
import sessionReducer from '../slices/sessionSlice'
import formReducer from '../slices/formSlice'
import chatReducer from '../slices/chatSlice'
import storeReducer from '../slices/storeSlice'
import erpReducer from '../slices/erpSlice'
import meetReducer from '../slices/meetSlices'
import prototypeReducer from '../slices/prototypeSlice'
import stripeReducer from '../slices/stripeSlice'
import calendarReducer from '../slices/calendarSlices'
import noteReducer from '../slices/noteSlice'
import utilizeReducer from '../slices/utilizeSlice'
import friendsReducer from '../slices/friendsSlices'
import desktopReducer from '../slices/desktopSlices'
import notificationsReducer from '../slices/notificationsSlices'
import tokensReducer from '../slices/tokensSlices'
import domainReducer from '../slices/domainSlices'
import contactReducer from '../slices/contactSlice'

const store = configureStore({
	reducer: {
		ticket: ticketReducer,
		dashboard: dashboardReducer,
		addon: addonReducer,
		iam: iamReducer,
		assets: assetsReducer,
		vector: vectorReducer,
		kanban: kanbanReducer,
		addon: addonReducer,
		datatable: datatableReducer,
		session: sessionReducer,
		form: formReducer,
		chats: chatReducer,
		store: storeReducer,
		erp: erpReducer,
		meet: meetReducer,
		game: gameReducer,
		prototype: prototypeReducer,
		stripe: stripeReducer,
		calendar: calendarReducer,
		desktop: desktopReducer,
		note: noteReducer,
		utilize: utilizeReducer,
		friends: friendsReducer,
		notifications: notificationsReducer,
		gptTokens: tokensReducer,
		domain: domainReducer,
		contact: contactReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
})

export default store
