
import React from 'react'


const Icon = () => {
    return (
        <svg width="147" height="147" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M105.691 117.32H40.3088C36.8246 117.32 34 114.496 34 111.011V35.3086C34 31.8244 36.8246 29 40.3088 29H82.75L112 58.2488V111.011C112 114.496 109.175 117.32 105.691 117.32Z" fill="url(#paint0_linear_633_2667)"/>
<g filter="url(#filter0_f_633_2667)">
<rect x="47.4779" y="70.293" width="51.0441" height="24.0873" fill="var(--color-primary-3)" fillOpacity="0.33" />
</g>
<path opacity="0.12" d="M81.5594 97.426L77.4573 81.0188C77.35 80.5897 77.1024 80.2087 76.7538 79.9365C76.4051 79.6643 75.9755 79.5164 75.5331 79.5164H73.5497V73.5663H79.5V69.5995H73.5497V65.6327H79.5V61.666H73.5497V59.6826H69.5827V65.6327H63.6324V69.5995H69.5827V73.5663H63.6324V77.533H69.5827V79.5164H67.5993C67.1569 79.5164 66.7273 79.6643 66.3787 79.9365C66.03 80.2087 65.7824 80.5897 65.6751 81.0188L61.5731 97.426C61.2806 98.5957 61.2587 99.8165 61.5088 100.996C61.7589 102.175 62.2745 103.282 63.0165 104.232C63.7585 105.183 64.7073 105.951 65.7908 106.48C66.8744 107.009 68.0642 107.284 69.2699 107.284H73.8626C75.0682 107.284 76.258 107.009 77.3416 106.48C78.4251 105.951 79.3739 105.183 80.1159 104.232C80.8579 103.282 81.3735 102.175 81.6236 100.996C81.8738 99.8165 81.8518 98.5957 81.5594 97.426ZM71.5662 101.334C70.7816 101.334 70.0147 101.101 69.3623 100.665C68.71 100.229 68.2015 99.6096 67.9013 98.8848C67.601 98.16 67.5225 97.3624 67.6755 96.5929C67.8286 95.8234 68.2064 95.1166 68.7612 94.5619C69.316 94.0071 70.0228 93.6293 70.7923 93.4762C71.5618 93.3232 72.3594 93.4017 73.0843 93.702C73.8091 94.0022 74.4287 94.5106 74.8646 95.163C75.3005 95.8153 75.5331 96.5822 75.5331 97.3668C75.5331 98.4188 75.1152 99.4278 74.3712 100.172C73.6273 100.916 72.6183 101.334 71.5662 101.334Z" fill="white"/>
<path d="M50.7587 90V75.4545H56.4974C57.5959 75.4545 58.5334 75.651 59.3099 76.044C60.0911 76.4323 60.6853 76.9839 61.0925 77.6989C61.5045 78.4091 61.7104 79.2448 61.7104 80.206C61.7104 81.1719 61.5021 82.0028 61.0854 82.6989C60.6688 83.3902 60.0651 83.9205 59.2744 84.2898C58.4884 84.6591 57.5367 84.8438 56.4192 84.8438H52.5769V82.3722H55.9221C56.5092 82.3722 56.9969 82.2917 57.3852 82.1307C57.7734 81.9697 58.0622 81.7282 58.2516 81.4062C58.4458 81.0843 58.5428 80.6842 58.5428 80.206C58.5428 79.723 58.4458 79.3158 58.2516 78.9844C58.0622 78.6529 57.771 78.402 57.3781 78.2315C56.9898 78.0563 56.4997 77.9688 55.9079 77.9688H53.834V90H50.7587ZM58.6139 83.3807L62.2289 90H58.834L55.2971 83.3807H58.6139ZM67.7908 90H64.4954L69.5167 75.4545H73.4798L78.494 90H75.1985L71.555 78.7784H71.4414L67.7908 90ZM67.5849 84.2827H75.369V86.6832H67.5849V84.2827ZM81.6447 90V75.4545H87.3833C88.4818 75.4545 89.4193 75.651 90.1958 76.044C90.9771 76.4323 91.5713 76.9839 91.9785 77.6989C92.3904 78.4091 92.5964 79.2448 92.5964 80.206C92.5964 81.1719 92.388 82.0028 91.9714 82.6989C91.5547 83.3902 90.951 83.9205 90.1603 84.2898C89.3743 84.6591 88.4226 84.8438 87.3052 84.8438H83.4629V82.3722H86.808C87.3951 82.3722 87.8828 82.2917 88.2711 82.1307C88.6594 81.9697 88.9482 81.7282 89.1376 81.4062C89.3317 81.0843 89.4288 80.6842 89.4288 80.206C89.4288 79.723 89.3317 79.3158 89.1376 78.9844C88.9482 78.6529 88.657 78.402 88.264 78.2315C87.8757 78.0563 87.3857 77.9688 86.7938 77.9688H84.72V90H81.6447ZM89.4998 83.3807L93.1148 90H89.72L86.183 83.3807H89.4998Z" fill="white"/>
<mask id="mask0_633_2667" maskUnits="userSpaceOnUse" x="34" y="29" width="78" height="89">
<path d="M105.691 117.32H40.3088C36.8246 117.32 34 114.496 34 111.011V35.3086C34 31.8244 36.8246 29 40.3088 29H82.75L112 58.2488V111.011C112 114.496 109.175 117.32 105.691 117.32Z" fill="url(#paint1_linear_633_2667)"/>
</mask>
<g mask="url(#mask0_633_2667)">
<ellipse opacity="0.05" cx="32.7096" cy="25.989" rx="64.0919" ry="61.5086" fill="url(#paint2_linear_633_2667)"/>
<ellipse opacity="0.05" cx="32.7096" cy="25.9892" rx="40.2904" ry="38.5683" fill="url(#paint3_linear_633_2667)"/>
</g>
<mask id="mask1_633_2667" maskUnits="userSpaceOnUse" x="34" y="29" width="78" height="89">
<path d="M105.691 117.32H40.3088C36.8246 117.32 34 114.496 34 111.011V35.3086C34 31.8244 36.8246 29 40.3088 29H82.75L112 58.2488V111.011C112 114.496 109.175 117.32 105.691 117.32Z" fill="url(#paint4_linear_633_2667)"/>
</mask>
<g mask="url(#mask1_633_2667)">
</g>
<g filter="url(#filter1_d_633_2667)">
<path d="M112 58.2488H104.75C92.5997 58.2488 82.75 48.3991 82.75 36.2488V29L112 58.2488Z" fill="url(#paint5_linear_633_2667)"/>
</g>
<defs>
<filter id="filter0_f_633_2667" x="8.47794" y="31.293" width="129.044" height="102.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="19.5" result="effect1_foregroundBlur_633_2667"/>
</filter>
<filter id="filter1_d_633_2667" x="59.75" y="5" width="79.25" height="79.249" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="1"/>
<feGaussianBlur stdDeviation="12.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_633_2667"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_633_2667" result="shape"/>
</filter>
<linearGradient id="paint0_linear_633_2667" x1="73" y1="29" x2="73" y2="117.32" gradientUnits="userSpaceOnUse">
<stop stopColor="var(--color-primary-3)"/>
<stop offset="1" stopColor="var(--color-primary-3)"/>
</linearGradient>
<linearGradient id="paint1_linear_633_2667" x1="73" y1="29" x2="73" y2="117.32" gradientUnits="userSpaceOnUse">
<stop stopColor="var(--color-primary-3)"/>
<stop offset="1" stopColor="var(--color-primary-3)"/>
</linearGradient>
<linearGradient id="paint2_linear_633_2667" x1="37.3631" y1="28.1027" x2="56.7984" y2="79.6863" gradientUnits="userSpaceOnUse">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="white"/>
</linearGradient>
<linearGradient id="paint3_linear_633_2667" x1="35.6349" y1="27.3146" x2="47.7984" y2="59.6799" gradientUnits="userSpaceOnUse">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="white"/>
</linearGradient>
<linearGradient id="paint4_linear_633_2667" x1="73" y1="29" x2="73" y2="117.32" gradientUnits="userSpaceOnUse">
<stop stopColor="var(--color-primary-3)"/>
<stop offset="1" stopColor="var(--color-primary-3)"/>
</linearGradient>
<linearGradient id="paint5_linear_633_2667" x1="97.375" y1="29" x2="97.375" y2="58.2488" gradientUnits="userSpaceOnUse">
<stop stopColor="var(--color-primary-3)"/>
<stop offset="1" stopColor="var(--color-primary-3)"/>
</linearGradient>
</defs>
</svg>

    )
}

export default Icon
