const settings = {
    t1: "Hem",
    t2: "Aythen DB",
    t3: "Kanban",
    t4: "Mina dokument",
    t5: "Kontrakt",
    t6: "Fakturering",
    info: {
        t0: `Lägg till tagg`,
        t1: `Nyckel`,
        t2: `Värde`,
        info: {
          t0: `exkl. skatter med rabatt`,
          t1: `Uppdaterad för 27 minuter sedan`
        }
    },
    invoice: {
        send: {
            "t0": "Faktura",
            "t1": "Kopiera faktura",
            "t2": "Ändra organisation om det inte stämmer",
            "t3": "Det saknas några uppgifter att fylla i",
            "t4": "Skatteidentifikationsnummer",
            "t5": "Personnummer (officiella dokument)",
            "t6": "Välj betalningsmetod",
            "t7": "Mer info",
            "t8": "Betalning",
            "t9": "Hur mycket pengar vill du få",
            "t10": "Kontakter",
            "t11": "Välj en kontakt",
            "t12": "Organisation",
            "t13": "Vi kommer att skicka en process till ditt konto",
            "t14": "Intakt läge",
            "t15": "Jag vill få pengar så snart som möjligt",
            "t16": "1",
            "t17": "Schemalägg avsändning påminnelse",
            "t18": "2",
            "t19": "Påminn om betalning imorgon om det inte har betalats",
            "t20": "3",
            "t21": "Påminn varje dag i 14 dagar",
            "t22": "Bankläge",
            "t23": "Factoring snabbare för dina kunder",
            "t24": "1",
            "t25": "Omedelbar betalning och säker betalning",
            "t26": "2",
            "t27": "Banken förskotter pengar",
            "t28": "3",
            "t29": "Bättre betalningsvillkor",
            "t30": "Schemalagt läge",
            "t31": "Berätta oss en dag att begära betalning",
            "t32": "1",
            "t33": "Schemalägg avsändning av dagen",
            "t34": "2",
            "t35": "Påminn under 14 dagar",
            "t36": "3",
            "t37": "Bättre betalningsvillkor",
            "t38": "Skicka faktura",
            "t39": "Betalning lyckades",
            "t40": "Gör överföring",
            "t41": "Du kommer att få",
            "t42": "2.000$",
            "t43": "Aktuell kreditkort",
            "t44": "**** 4543",
            "t45": "Vald process",
            "t46": "Omedelbar betalning",
            "t47": "Betal faktura",
            "t48": "Avbryt"
        }
    },
    status: {
        "t0": "Aythen",
        "t1": "status",
        "t2": "Kontaktstöd",
        "t3": "Live-status",
        "t4": "Historik",
        "t5": "Prenumerera",
        "t6": "Aythen API",
        "t7": "99,999% SLA uptime",
        "t8": "Jan 15",
        "t9": "100% uptime",
        "t10": "Jan 1",
        "t11": "Idag",
        "t12": "Legenda:",
        "t13": "Lyckades",
        "t14": "Väntar",
        "t15": "Fel",
        "t16": "Systemstatus",
        "t17": "Planerat underhåll för Bancontact",
        "t18": "Aythen",
        "t19": "© 2024 Aythen, Inc",
        "t20": "Besök vår hemsida",
        "t21": "Villkor och sekretess",
        "t22": "E-post",
        "t23": "Textmeddelande",
        "t24": "Få e-postnotiser när Stripe skapar, uppdaterar eller löser en incident.",
        "t25": "E-postadress",
        "t26": "Prenumerera via e-post",
        "t27": "För att uppdatera befintliga e-postpreferenser, prenumerera igen.",
        "t28": "För mer information om prenumeration, besök vår support.",
        "t29": "Denna sida skyddas av reCAPTCHA och Googles sekretesspolicy och användarvillkor gäller.",
        "t30": "Få textmeddelanden när Stripe skapar eller löser en incident.",
        "t31": "Landskod",
        "t32": "Telefonnummer",
        "t33": "Prenumerera via textmeddelande",
        "t34": "För att uppdatera befintliga preferenser, prenumerera igen.",
        "t35": "För mer information om prenumeration, besök vår support.",
        "t36": "Meddelanden och dataavgifter kan tillämpas. Genom att prenumerera godkänner du Atlassians användarvillkor och Atlassians sekretesspolicy. Denna sida skyddas av reCAPTCHA och Googles sekretesspolicy och användarvillkor gäller."
    },
    billing: {
        t1: `Fyll i uppgifterna…`,
        t2: `Valuta`,
        t3: `Redigera`,
        t4: `Här visas dina registrerings- och faktureringsuppgifter. Du kan ändra eller modifiera dem för att verifiera din åtkomst till olika funktioner som Aythen Lite erbjuder.`,
        t5: `Ändra uppgifter`,
        t6: `Dataförbrukning`,
        t7: `Kontakt-e-post`,
        t8: `Som en sammanställning kommer du varje månad att ha din elektroniska faktura automatiskt förberedd på din registrerade e-post.`,
        t9: `Fakturerings-e-post`,
        t10: `Spara`,
        t12: `GPT-token`,
        t13: `Du kan skapa en GPT-token för att unikt identifiera och autentisera dina API-förfrågningar.`,
        t14: `Token`,
        t15: `Spara`,
        t16: `Förbrukningsgränser`,
        t17: `På din licens kan du behöva fler interaktioner eller GB lagring. För dessa ytterligare användningar kan du konfigurera varningar enligt ett totalt.`,
        t177: `Läs mer`,
        t18: `Lägg till varning från `,
        t19: `Månadskostnader`,
        t20: `Spara varning`,
        t21: `Skatteidentifikation/VAT`,
        t222: `VAT`,
        t23: `Spara`,
        t24: `Betalningsmetoder`,
        t25: `Välj bland följande betalningsmetoder för ditt konto.`,
        t26: `Hur vill du hantera din licens:`,
        t266: `Kreditkort`,
        t267: `SEPA Direkt Debit`,
        t268: `saknas tabell`,
        t27: `Notifikationstyper`,
        t28: `Välj och konfigurera dina notifikationspreferenser. När ska notifikationer skickas?`,
        t29: `Notifikationstyper`,
        t30: `E-postnotifikationer`,
        t31: `Dagliga påminnelser`,
        t32: `Särskilda händelse-notifikationer:`,
        t33: `Privata meddelandenotifikationer:`,
        t34: `Fakturor mottagna`,
        t340: `Aythen Lite Premium`,
        t341: `Uppgradera till Premium`,
        t342: `Lorem ipsum dolor et epsum holend.`,
        t343: `Lorem ipsum dolor et epsum holend och erem super tupper dolem herem superssupostom hellom super start.`,
        t344: `Lorem ipsum dolor et teresum`,
        t345: `Lorem ipsum dolor et teresum`,
        t346: `Gratis`,
        t347: `Forever`,
        t350: `Lägg till`,
        t35: `Här hittar du din historik mellan mottagna och skickade.`,
        t36: `Fakturor skickade`,
        t360: `Fakturor mottagna`,
        t361: `av`,
        t37: `Månad`,
        t38: `År`,
        t39: `Nummer`,
        t40: `Betalningsmetod`,
        t41: `Status`,
        t42: `Totalt (inkl. moms)`,
        t43: `Ladda ner`,
        t44: `Radera konto`,
        t45: `Är du säker på att du vill radera ditt konto? Observera att denna åtgärd inte kan ångras och ditt konto kommer att avaktiveras om 48 timmar om du bekräftar.`,
        t46: `Radera`,

        x1: `Redigera kontonamn`,
        x2: `Detta är ett företagskonto`,
        x3: `Företagsnamn`,
        x4: `Gatuadress`,
        x5: `Gatuadress 2`,
        x6: `Postnummer`,
        x7: `Stad`,
        x8: `Land`,
        x9: `Region`,
        x10: `Bekräfta ändringar`,
    },
    contracts: {
        t1: `Organisation`,
        t2: `Kontakter`,
        t3: `Namn`,
        t4: `Status`,
        t5: `Slutar vid`,
        t6: `Ladda ner`,
        t7: `Organisera dina företagsavtal här.`,
        t8: `Vilka fördelar har det?`,
        t0: 'Lägg till kontrakt',
        popup: {
            t0: `Öppnad på`,
            t1: `Status`,
            t2: `Skicka med påminnelse`,
            t3: `Radera påminnelse`,
            t4: `Skicka en gång`,
            t5: `Prioriterad avsändning`,
            t6: `Markera signerad`,
            t7: `För att signera`,
            t8: `För att radera måste du lägga till Radera`,
            t9: `Signera`,
            t10: `Avbryt`,
            t11: `Radera`
        }
    }
}

export default settings