

import React from 'react'


const Icon = () => {
    return (
        <svg width="147" height="147" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M104.691 116.026H39.3088C35.8246 116.026 33 113.202 33 109.717V34.0146C33 30.5305 35.8246 27.7061 39.3088 27.7061H81.75L111 56.9549V109.717C111 113.202 108.175 116.026 104.691 116.026Z" fill="url(#paint0_linear_633_2808)"/>
<g filter="url(#filter0_f_633_2808)">
<rect x="46.7647" y="70.4316" width="51.0441" height="24.0873" fill="var(--color-primary-3)" fillOpacity="0.16" />
</g>
<path opacity="0.09" fillRule="evenodd" clipRule="evenodd" d="M89.5362 61.2468C90.0239 61.66 90.3052 62.2668 90.3052 62.906V91.1751C90.3052 95.979 86.4107 99.8733 81.6066 99.8733C76.8026 99.8733 72.9081 95.979 72.9081 91.1751C72.9081 86.3712 76.8026 82.4769 81.6066 82.4769C83.191 82.4769 84.6764 82.9005 85.9559 83.6406V65.473L64.2096 69.0972V95.5242L64.2096 95.5276C64.2077 100.33 60.314 104.222 55.511 104.222C50.707 104.222 46.8125 100.328 46.8125 95.5242C46.8125 90.7203 50.707 86.826 55.511 86.826C57.0954 86.826 58.5809 87.2496 59.8603 87.9897V67.2551C59.8603 66.1921 60.6288 65.2849 61.6774 65.1101L87.773 60.761C88.4036 60.656 89.0484 60.8336 89.5362 61.2468ZM85.9559 91.1751C85.9559 88.7731 84.0087 86.826 81.6066 86.826C79.2046 86.826 77.2574 88.7731 77.2574 91.1751C77.2574 93.577 79.2046 95.5242 81.6066 95.5242C84.0087 95.5242 85.9559 93.577 85.9559 91.1751ZM55.511 91.1751C57.9131 91.1751 59.8603 93.1222 59.8603 95.5242C59.8603 97.9261 57.9131 99.8733 55.511 99.8733C53.109 99.8733 51.1618 97.9261 51.1618 95.5242C51.1618 93.1222 53.109 91.1751 55.511 91.1751Z" fill="white"/>
<path d="M49.1462 88L44.9842 73.4545H48.3436L50.7513 83.5611H50.872L53.5283 73.4545H56.4047L59.0538 83.5824H59.1817L61.5893 73.4545H64.9487L60.7868 88H57.7896L55.0197 78.4901H54.9061L52.1433 88H49.1462ZM67.9947 73.4545H71.7873L75.793 83.2273H75.9634L79.9691 73.4545H83.7617V88H80.7788V78.5327H80.658L76.8938 87.929H74.8626L71.0984 78.4972H70.9776V88H67.9947V73.4545ZM90.2026 88H86.9071L91.9284 73.4545H95.8915L100.906 88H97.6102L93.9668 76.7784H93.8531L90.2026 88ZM89.9966 82.2827H97.7807V84.6832H89.9966V82.2827Z" fill="white"/>
<mask id="mask0_633_2808" maskUnits="userSpaceOnUse" x="33" y="27" width="78" height="90">
<path d="M104.691 116.026H39.3088C35.8246 116.026 33 113.202 33 109.717V34.0146C33 30.5305 35.8246 27.7061 39.3088 27.7061H81.75L111 56.9549V109.717C111 113.202 108.175 116.026 104.691 116.026Z" fill="url(#paint1_linear_633_2808)"/>
</mask>
<g mask="url(#mask0_633_2808)">
<ellipse opacity="0.05" cx="28.8419" cy="24.1219" rx="64.0919" ry="61.5086" fill="url(#paint2_linear_633_2808)"/>
<ellipse opacity="0.05" cx="28.8419" cy="24.122" rx="40.2904" ry="38.5683" fill="url(#paint3_linear_633_2808)"/>
</g>
<g filter="url(#filter1_d_633_2808)">
<path d="M111 56.9549H103.75C91.5997 56.9549 81.75 47.1052 81.75 34.9549V27.7061L111 56.9549Z" fill="url(#paint4_linear_633_2808)"/>
</g>
<defs>
<filter id="filter0_f_633_2808" x="7.76471" y="31.4316" width="129.044" height="102.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="19.5" result="effect1_foregroundBlur_633_2808"/>
</filter>
<filter id="filter1_d_633_2808" x="58.75" y="3.70605" width="79.25" height="79.249" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="1"/>
<feGaussianBlur stdDeviation="12.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_633_2808"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_633_2808" result="shape"/>
</filter>
<linearGradient id="paint0_linear_633_2808" x1="72" y1="27.7061" x2="72" y2="116.026" gradientUnits="userSpaceOnUse">
<stop stopColor="var(--color-primary-3)"/>
<stop offset="1" stopColor="var(--color-primary-3)"/>
</linearGradient>
<linearGradient id="paint1_linear_633_2808" x1="72" y1="27.7061" x2="72" y2="116.026" gradientUnits="userSpaceOnUse">
<stop stopColor="var(--color-primary-3)"/>
<stop offset="1" stopColor="var(--color-primary-3)"/>
</linearGradient>
<linearGradient id="paint2_linear_633_2808" x1="33.4954" y1="26.2355" x2="52.9308" y2="77.8191" gradientUnits="userSpaceOnUse">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="white"/>
</linearGradient>
<linearGradient id="paint3_linear_633_2808" x1="31.7673" y1="25.4474" x2="43.9307" y2="57.8127" gradientUnits="userSpaceOnUse">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="white"/>
</linearGradient>
<linearGradient id="paint4_linear_633_2808" x1="96.375" y1="27.7061" x2="96.375" y2="56.9549" gradientUnits="userSpaceOnUse">
<stop stopColor="var(--color-primary-3)"/>
<stop offset="1" stopColor="var(--color-primary-3)"/>
</linearGradient>
</defs>
</svg>

    )
}

export default Icon
