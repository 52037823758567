const funnel = {
    t1: {
        '01': `Aythen ayuda a automatizar con inteligencia artificial todo tipo de documentación`,
        '02': `Pide una demo gratis`,
        '03': `"¡Verdaderamente!"`,
        '04': `Hemos automatizado más de 20,344 documentos ahorrando un 200% a nuestros clientes.`,
        '05': `Dos planes simples, cada uno con una`,
        '06': `Prueba gratuita de 30 días`,
        '07': `. No se requiere tarjeta de crédito.`,
    },
    t2: {
        '01': `49.9/licencia por mes`,
        '010': `Si no te gusta te devolvemos el dinero.`,
        '011': `Invite clients, contractors and guests for free.`,
        '02': `Prueba gratis`,
        '03': `100 escaneres al mes incluidos`,
        '04': `10 GB almacenamiento de archivos y documentos`,
        '05': `Gestion documental`,
        '06': `Acceso a herramientas inteligentes para la gestión documental de una empresa`,
        '07': `Licencias`,
        '08': `Perfecto para empresas en crecimiento, grupos más grandes
              y empresas que quieren lo mejor.`,
        '09': `Todas las funciones que ofrecemos, más……`,
        '10': `100x &amp; más almacenamiento  (1 TB)`,
        '11': `Soporte prioritario las 24 horas, los 7 días de la semana y los 365 días del año`,
        '12': `Visita de incorporación individual con nuestro equipo`,
        '13': `Opción de pago anual con cheque`,
        '14': `Facturación anual para una contabilidad simplificada`,
        '15': `Agencias`,
        '16': `A partir de 3 licencias`,
        '17': `Licencias`,
        '18': `justo`,
        '19': `149/mes, facturado anualmente`,
        '20': `Con cargos por usuario. Toda su organización por un precio variable.`,
        '21': `Si prefieres pagar mes a mes, el precio es $199 al mes.`,
        '22': `Compra el acceso partner`,
        '23': `Aythen Partner`,
    },
    t3: {
        '01': `¿Qué incluye? Absolutamente todo.`,
        '02': `Cualquier documento`,
        '03': `Súbelo a la plataforma`,
        '04': `Crea carpetas inteligentes`,
        '05': `Reconoce y observa datos`,
        '06': `Analiza el resultado`,
        '07': `Conecta y Automatiza`,
        '08': `En módo automatico`
    },
    t4: {
        '01': `Preguntamos a nuestros clientes`,
        '02': `“¿Qué ha cambiado para mejor desde que te cambiaste a Aythen?”`,
        '03': `Miles de personas respondieron, esto es algo de lo que dijeron…`,
        '04': `¿Cómo se sienten los clientes después de comprar Aythen?`,
        '05': `Con Aythen, he automatizado y centrado mis objetivos a las ventas,
ahora puedo gestionar todo el volumen o incluso cancelar en cualquier momento, sin contratos a largo plazo que te encierren. Simple, directo y justo, tal como debe ser.`,
        '06': `Sin riesgos, cancelación en cualquier momento, sin compromisos a largo plazo`,
        '07': `¿Descuentos para organizaciones sin fines de lucro o educativas?`,
        '08': `Sí, ofrecemos descuentos por usuario para organizaciones sin fines de lucro y cuentas completamente
gratuitas para profesores y estudiantes (incluidos
los que educan en casa y los programas de educación alternativa). Revise
la`,
        '09': `página de descuentos`,
        '10': `Para más detalles e indicaciones.`,
        '11': `Un gran impulso para emprendedores y pequeñas empresas`,
        '12': `Comenzamos nuestro negocio sin financiación externa, y probablemente tú también lo hiciste. Sabemos que tu dinero es valioso y queremos ayudarte.
Por eso, Aythen es intencionadamente asequible para empresas emergentes, autónomos y pequeñas empresas que recién comienzan.`,
        '13': `Cuando te cambias a Aythen, no necesitas pagar por cosas como
Slack, Trello, Asana o Google Docs. Aythen reemplaza todo eso. Es fácil aligerar tu carga, simplificar tu flujo de trabajo y
ahorrar dinero real ahora mismo con Aythen.`,
        '14': `Las agencias adoran Aythen`
    },
    t5: {
        '01': `Estamos aquí para ayudar.`,
        '02': `Siempre humanos, nunca bots. Para consultas previas a la venta, clientes existentes que necesitan ayuda u otras consultas,`,
        '03': `Contacta con nosotros`,
        '04': `y`,
        '05': `Lo contactaremos dentro de una hora`,
        '06': `Si te encanta Aythen, te encantará`,
        '07': `— nuestra opinión sobre el correo electrónico`,
        '01': ``,
    },
    t6: {
        '01': `está diseñado, construido y respaldado por`,
        '02': `Aythen`,
        '03': `Disfruta el resto de tu`
    },
    t7: {
        '01': `“ Ofrecemos AI y Big Data .”`,
        '02': `“ Somos tu mejor apoyo .” `,
        '03': `“ Con Aythen .”`,
        '04': `“ Haz menos reuniones .”`,
        '05': `“ Mejora la comunicación .”`,
        '06': `“ Gestioné tu caos .”`,
        '07': `“ Dejé de buscar el dato en varios lugares .”`,
        '08': `“ Las empresa ha mejorado .”`,
        '09': `“ Mucha más productividad .”`,
        '10': `“ Mejor alineación de equipos .”`,
        '11': `“ Realizar proyectos en tiempo récord .”`,
        '12': `“ Capacidad para tomar voz .”`,
        '13': `“ Todo en una misma plataforma .”`,
        '14': `“ Es muy fluido .”`,
        '15': `“ Fácil y rápido .”`,
        '16': `“ Automatiza todo .”`,
        '17': `“ Carpetas inteligentes .”`,
        '18': `“ Mucho más organizado .”`,
        '19': `“ Sincroniza rapido .”`,
        '20': `“ Más responsabilidad .”`,
        '21': `“ Más transparencia .”`,
        '22': `“ Eleva tu gestión de datos .”`,
        '23': `“ La información fluye bien .”`,
    },
    t8: {
        '01': `Laura Martínez`,
        '02': `Cliente Satisfecha`,
        '03': `La automatización del proceso documental nos ha ahorrado horas de trabajo y ha incrementado nuestra eficiencia.`,
        '04': `“Trabajé en gestión de documentos durante muchos años antes de descubrir este producto. Estaba buscando una solución que pudiera simplificar y organizar nuestro flujo de trabajo. Probé la versión de prueba gratuita y quedé impresionada. Poco después adquirimos la versión completa. Las primeras semanas fueron transformadoras. Desde entonces, hemos automatizado completamente nuestros procesos documentales, y ahora todo está ordenado con carpetas inteligentes. Nuestro equipo ha experimentado una mejora significativa en la productividad.”`,
        '11': `Carlos López`,
        '12': `Cliente Satisfecho`,
        '13': `El equipo de soporte fue increíble y siempre estuvo ahí para ayudar.`,
        '14': `“Comencé sin mucha experiencia en la gestión de documentos automatizada. Ahora, gracias a este producto, nuestro proceso documental es eficiente y está perfectamente organizado. Esta ha sido una de las mejores inversiones para nuestra empresa.”`,
        '21': `Ana Gómez`,
        '22': `Cliente Satisfecha`,
        '23': `Una experiencia excepcional y beneficiosa en general`,
        '24': `“Gracias por esta maravillosa solución. Ahora tengo control total sobre la documentación de mi empresa y todo está automatizado y bien organizado.”`,
        '31': `Miguel Torres`,
        '32': `Cliente Satisfecho`,
        '33': `El soporte técnico fue excelente y nos ayudó a implementar el sistema de manera efectiva.`,
        '34': `“El equipo realmente se preocupa por nuestro progreso, lo cual es refrescante. Este producto me dio la confianza para automatizar nuestros procesos documentales y presentar a nuestra empresa como moderna y eficiente. El estándar es superior al resto. Recibirás la atención personalizada que necesitas de una comunidad increíble de personas inteligentes y profesionales.”`,
    }
    
}

export default funnel