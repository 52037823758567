// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formTime_mBHSP {
	display: flex;
	align-items: center;
	border-radius: 5px;
	padding: 3px 5px;
	margin-left: -4px;
	cursor: pointer;
	-webkit-user-select: none;
	        user-select: none;
	gap: 5px;
	transition: background 0.2s ease-in-out;
	&:hover {
		background-color: var(--color-primary-6);
	}
	span {
		font-size: 12px;
		color: var(--text-secondary);
	}
}
.normalIcon_6Yzgg {
	color: var(--text-color);
}
.innerContainer_LiBqs {
	display: flex;
	flex-direction: column;
	margin-left: 5px;
}

.expandedDateHourWrapper__1ORx {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.allDayWrapper_OUZBU {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	gap: 40px;
	align-items: center;
}

.allDayContainer_iHOMo {
	display: flex;
	align-items: center;
	flex-direction: row;
	cursor: pointer;
	-webkit-user-select: none;
	        user-select: none;
	gap: 12px;
	svg {
		margin-left: -3px;
	}
	span {
		line-height: 1;
		font-size: 14px;
		width: max-content;
		color: var(--text-color);
	}
}
.expandedContainer_KyPn7 {
	display: flex;
	flex-direction: row;
	gap: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/views/app/v1-1/pages/Calendar/components/DateHourInfo.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,kBAAkB;CAClB,gBAAgB;CAChB,iBAAiB;CACjB,eAAe;CACf,yBAAiB;SAAjB,iBAAiB;CACjB,QAAQ;CACR,uCAAuC;CACvC;EACC,wCAAwC;CACzC;CACA;EACC,eAAe;EACf,4BAA4B;CAC7B;AACD;AACA;CACC,wBAAwB;AACzB;AACA;CACC,aAAa;CACb,sBAAsB;CACtB,gBAAgB;AACjB;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,SAAS;AACV;AACA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;CAC9B,WAAW;CACX,SAAS;CACT,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,mBAAmB;CACnB,eAAe;CACf,yBAAiB;SAAjB,iBAAiB;CACjB,SAAS;CACT;EACC,iBAAiB;CAClB;CACA;EACC,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,wBAAwB;CACzB;AACD;AACA;CACC,aAAa;CACb,mBAAmB;CACnB,SAAS;AACV","sourcesContent":[".formTime {\n\tdisplay: flex;\n\talign-items: center;\n\tborder-radius: 5px;\n\tpadding: 3px 5px;\n\tmargin-left: -4px;\n\tcursor: pointer;\n\tuser-select: none;\n\tgap: 5px;\n\ttransition: background 0.2s ease-in-out;\n\t&:hover {\n\t\tbackground-color: var(--color-primary-6);\n\t}\n\tspan {\n\t\tfont-size: 12px;\n\t\tcolor: var(--text-secondary);\n\t}\n}\n.normalIcon {\n\tcolor: var(--text-color);\n}\n.innerContainer {\n\tdisplay: flex;\n\tflex-direction: column;\n\tmargin-left: 5px;\n}\n\n.expandedDateHourWrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 10px;\n}\n.allDayWrapper {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: space-between;\n\twidth: 100%;\n\tgap: 40px;\n\talign-items: center;\n}\n\n.allDayContainer {\n\tdisplay: flex;\n\talign-items: center;\n\tflex-direction: row;\n\tcursor: pointer;\n\tuser-select: none;\n\tgap: 12px;\n\tsvg {\n\t\tmargin-left: -3px;\n\t}\n\tspan {\n\t\tline-height: 1;\n\t\tfont-size: 14px;\n\t\twidth: max-content;\n\t\tcolor: var(--text-color);\n\t}\n}\n.expandedContainer {\n\tdisplay: flex;\n\tflex-direction: row;\n\tgap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formTime": `formTime_mBHSP`,
	"normalIcon": `normalIcon_6Yzgg`,
	"innerContainer": `innerContainer_LiBqs`,
	"expandedDateHourWrapper": `expandedDateHourWrapper__1ORx`,
	"allDayWrapper": `allDayWrapper_OUZBU`,
	"allDayContainer": `allDayContainer_iHOMo`,
	"expandedContainer": `expandedContainer_KyPn7`
};
export default ___CSS_LOADER_EXPORT___;
