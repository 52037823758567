import apiBackend from '@src/apiBackend.js'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getUserFriends = createAsyncThunk(
	'friends/getUserFriends',
	async ({ userId }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.get(`/friends/getUserFriends/${userId}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			return res.data
		} catch (error) {
			console.log('Error getUserFriends:', error)
		}
	}
)

export const sendFriendRequest = createAsyncThunk(
	'friends/sendFriendRequest',
	async ({ userId, receiverId, message }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				'/friends/sendFriendRequest',
				{ userId, receiverId, message },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			return res.data
		} catch (error) {
		}
	}
)

export const acceptFriendRequest = createAsyncThunk(
	'friends/acceptFriendRequest',
	async ({ userId, requestId }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				'/friends/acceptFriendRequest',
				{ userId, requestId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			return res.data
		} catch (error) {
			console.error('Error acceptFriendRequest:', error)
		}
	}
)

export const cancelFriendRequest = createAsyncThunk(
	'friends/cancelFriendRequest',
	async ({ userId, requestId }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				'/friends/cancelFriendRequest',
				{ userId, requestId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			return res.data
		} catch (error) {
			console.log('Error cancelFriendRequest:', error)
		}
	}
)
