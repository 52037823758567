import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'


import gsap from 'https://cdn.skypack.dev/gsap@3.12.0'
import ScrollTrigger from 'https://cdn.skypack.dev/gsap@3.12.0/ScrollTrigger'


import Image from './assets/home/background2.png'


import styles from './IntroSecond.module.css'

import { useAppContext } from "@home/context/Context";


const Test = () => {
  const dispatch = useDispatch()
  const [audios, setAudios] = useState([]);

  const heroRef = useRef(null);
  const imgRef = useRef(null);
  const titleRef = useRef(null);
  const containerRef = useRef(null);

  const {
    isLightTheme,
  } = useAppContext();


  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);



    gsap.timeline({
      scrollTrigger: {
        trigger: heroRef.current,
        start: 'top top',
        end: 'top center',
        // end: '-=100%',
        end: '-=300%',
        pin: true,
        scrub: true,
        markers: false,
      },
    })
      .to(imgRef.current, {
        scale: 600,
        // z: 150,
        y: "-8000px",
        x: "1800px",
        transformOrigin: 'center center',
        ease: 'power1.inOut',
      })
      .to(titleRef.current, {
        scale: 20,
        z: 350,
        transformOrigin: 'center center',
        ease: 'power1.inOut',
      })
      .to(heroRef.current, {
        scale: 1.4,
        transformOrigin: 'center center',
        ease: 'power1.inOut',
      }, "<")


    ScrollTrigger.create({
      trigger: heroRef.current,
      start: 'bottom bottom',
      onEnterBack: () => {
        gsap.set(containerRef.current, { display: 'block', opacity: 1 });
      },
      onLeave: () => {
        gsap.set(containerRef.current, { display: 'none', opacity: 0 });
      },
    });


  }, []);




  return (
    <div ref={containerRef} className={styles["wrapper"]}>
      <div className={styles["content"]}>
        <section className={`${styles["section"]} ${styles["hero"]}`} ref={heroRef}></section>
        <section className={`${styles["section"]} ${styles["gradient-purple"]}`}></section>
        <section className={`${styles["section"]} ${styles["gradient-blue"]}`}></section>
      </div>
      <div className={styles["image-container"]}>
        <div className={styles.titles} ref={titleRef}>

          <h2 className={styles.title2}>
            "La mejor manera de predecir el futuro es creándolo" - P.D
          </h2>
          {/* <h2 className={styles.title2}>
            Aythen
          </h2> */}
        </div>
        <img
          ref={imgRef}
          src={Image}
        />
      </div>
    </div>
  );
}


export default Test