import React, { useEffect } from "react";

import BlogData from "@home/assets/constants/blog.json";

import Categories from "../Blog/BlogItems/Categories";
import BlogPost from "../Blog/BlogItems/BlogPost";
import Archives from "../Blog/BlogItems/Archives";
import BlogTags from "../Blog/BlogItems/BlogTags";

import styles from '../index.module.css'

const SingleBlog = ({ getBlog }) => {
  const postId = parseInt(null);
  
  let blogPosts = JSON.parse(JSON.stringify(BlogData.blog));

  const matchedBlog = true



  return (
    <>
      <div className={`${styles["rainbow-blog-section"]} ${styles["rainbow-section-gap-big"]} ${styles["bg-color-1"]}`} >
        {matchedBlog && (
          <div className={`${styles.container}`} >
            <div className={`${styles.row} ${styles["row--30"]}`} >
              <div className={`${styles["col-lg-8"]}`} >
                <div className={`${styles["rainbow-blog-details-area"]}`} >
                  <div className={`${styles["post-page-banner"]}`} >
                    <div className={`${styles.container}`} >
                      <div className={`${styles.row}`} >
                        <div className={`${styles["col-lg-12"]}`} >
                          <div className={`${styles.content} ${styles["text-center"]}`} >
                            <div className={`${styles.thumbnail}`} >
                              <img
                                className={`${styles["w-100"]} ${styles["radius"]}`} 
                                src={matchedBlog.blogImg}
                                width={790}
                                height={445}
                                alt="Blog imgs"
                              />
                            </div>
                            <ul className={`${styles["rainbow-meta-list"]}`} >
                              <li>
                                <i className={`${styles.featherUser} ${styles.me2}`} ></i>
                                <a href="#">RainbowIT</a>
                              </li>
                              <li>
                                <i className={`${styles["feather-calendar"]} ${styles["me-2"]}`} ></i>
                                {matchedBlog.date}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles["blog-details-content"]} ${styles["pt--40"]}`} >
                    <div className={`${styles.container}`} >
                      <div className={`${styles.row}`} >
                        <div className={`${styles["col-lg-12"]}`} >
                          <div className={`${styles.content}`} >
                            <h2 className={styles.title} >{matchedBlog.title}</h2>

                            <p>
                              Integrating artificial intelligence (AI) into your
                              startup can indeed unlock exponential growth by
                              enhancing efficiency, improving decision-making,
                              and providing innovative solutions. Here are 10
                              ways to supercharge your startup with AI
                              integration:
                            </p>

                            <h6>1.Automated Customer Support:</h6>
                            <p>
                              Implement AI-powered chatbots and virtual
                              assistants to provide instant and personalized
                              customer support. This not only improves customer
                              satisfaction but also frees up human resources for
                              more complex tasks.
                            </p>

                            <h6>2.Data-driven Decision Making:</h6>
                            <p>
                              Implement AI-powered chatbots and virtual
                              assistants to provide instant and personalized
                              customer support. This not only improves customer
                              satisfaction but also frees up human resources for
                              more complex tasks.
                            </p>

                            <h6>3.AI-driven Innovation:</h6>
                            <p>
                              Encourage a culture of innovation by using AI to
                              generate insights and ideas. AI can assist in
                              identifying market gaps, predicting industry
                              trends, and proposing innovative solutions to
                              common challenges.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={`${styles["post-page-banner"]}`} >
                    <div className={styles.container} >
                      <div className={`${styles.row}`} >
                        <div className={`${styles["col-lg-12"]}`} >
                          <div className={`${styles.content} ${styles["text-center"]}`} >
                            <div className={styles.thumbnail} >
                              <img
                                className={`${styles["w-100"]} ${styles["radius"]}`} 
                                src={matchedBlog.blogImg2}
                                width={790}
                                height={445}
                                alt="Blog imgs"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles["blog-details-content"]} ${styles["pt--40"]} ${styles["rainbow-section-gapBottom"]}`} >
                    <div className={`${styles.container}`} >
                      <div className={`${styles.row}`} >
                        <div className={`${styles["col-lg-12"]}`} >
                          <div className={styles.content} >
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book.There are many variations of passages of
                              Lorem Ipsum available, but the majority have
                              suffered alteration in some form, by injected
                              humour, or randomised words which don't look even
                              slightly believable. If you are going to use a
                              passage of Lorem Ipsum, you need to be sure there
                              isn't anything embarrassing hidden in the middle
                              of text. All the Lorem Ipsum generators on the
                              Internet tend to repeat predefined chunks as
                              necessary, making this the first true generator on
                              the Internet. It uses a dictionary of over 200
                              Latin words, combined with a handful of model
                              sentence structures, to generate Lorem Ipsum which
                              looks reasonable.
                            </p>

                            <p>
                              It has survived not only five centuries, but also
                              the leap into electronic typesetting, remaining
                              essentially unchanged. It was popularised in the
                              1960s with the release of Letraset sheets
                              containing Lorem Ipsum passages, and more recently
                              with desktop publishing software like Aldus
                              PageMaker including versions of Lorem Ipsum.
                            </p>

                            <p>
                              Contrary to popular belief, Lorem Ipsum is not
                              simply random text. It has roots in a piece of
                              classical Latin literature from 45 BC, making it
                              over 2000 years old. Richard McClintock, a Latin
                              professor at Hampden-Sydney College in Virginia,
                              looked up one of the more obscure Latin words.
                            </p>

                            <p>
                              There are many variations of passages of Lorem
                              Ipsum available, but the majority have suffered
                              alteration in some form, by injected humour, or
                              randomised words which don't look even slightly
                              believable.
                            </p>

                            <p>
                              If you are going to use a passage of Lorem Ipsum,
                              you need to be sure there isn't anything
                              embarrassing hidden in the middle of text. All the
                              Lorem Ipsum generators on the Internet tend to
                              repeat predefined chunks as necessary, making this
                              the first true generator on the Internet.
                            </p>

                            <p>
                              There are many variations of passages of Lorem
                              Ipsum available, but the majority have suffered
                              alteration in some form, by injected humour, or
                              randomised words which don't look even slightly
                              believable. If you are going to use a passage of
                              Lorem Ipsum, you need to be sure there isn't
                              anything embarrassing hidden in the middle of
                              text. All the Lorem Ipsum generators on the
                              Internet tend to repeat predefined chunks as
                              necessary, making this the first true generator on
                              the Internet. It uses a dictionary of over 200
                              Latin words, combined with a handful of model
                              sentence structures, to generate Lorem Ipsum which
                              looks reasonable.
                            </p>

                            <div className={`${styles['category-meta']}`} >
                              <span className={`${styles.text}`} >Tags:</span>
                              <BlogTags tags={BlogData && BlogData.tags} />
                            </div>

                            <div className={`${styles["rainbow-comment-form"]} ${styles["pt--60"]}`} >
                              <div className={`${styles.inner}`} >
                                <div className={styles["section-title"]} >
                                  <span className={styles.subtitle} >
                                    Have a Comment?
                                  </span>
                                  <h2 className={styles.title} >Leave a Reply</h2>
                                </div>
                                <form className={`${styles["mt--40"]}`} >
                                  <div className={`${styles.row}`} >
                                    <div className={`${styles["col-lg-6"]} ${styles["col-md-12"]} ${styles["col-12"]}`} >
                                      <div className={`${styles["rnform-group"]}`} >
                                        <input type="text" placeholder="Name" />
                                      </div>
                                      <div className={`${styles["rnform-group"]}`} >
                                        <input
                                          type="email"
                                          placeholder="Email"
                                        />
                                      </div>
                                      <div className={`${styles["rnform-group"]}`} >
                                        <input
                                          type="text"
                                          placeholder="Website"
                                        />
                                      </div>
                                    </div>
                                    <div className={`${styles["col-lg-6"]} ${styles["col-md-12"]} ${styles["col-12"]}`} >
                                      <div className={`${styles["rnform-group"]}`} >
                                        <textarea placeholder="Comment"></textarea>
                                      </div>
                                    </div>
                                    <div className={`${styles["col-lg-12"]}`} >
                                      <div className={`${styles["blog-btn"]}`} >
                                        <a
                                          className={`${styles["btn-default"]}`} 
                                          href="blog-details.html"
                                        >
                                          <span>SEND MESSAGE</span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles['col-lg-4']} ${styles['mt_md--40']} ${styles['mt_sm--40']}`} >
                <aside className={`${styles["rainbow-sidebar"]}`} >
                  <div className={`${styles["rbt-single-widget"]} ${styles["widget_search"]} ${styles["mt--40"]}`} >
                    <div className={styles.inner} >
                      <form className={`${styles["blog-search"]}`}  action="#">
                        <input type="text" placeholder="Search ..." />
                        <button className={styles["search-button"]} >
                          <i className={`${styles["feather-search"]}`} ></i>
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className={`${styles["rbt-single-widget"]} ${styles["widget_categories"]} ${styles["mt--40"]}`} >
                    <h3 className={styles.title} >Categories</h3>
                    <Categories category={BlogData && BlogData.categories} />
                  </div>
                  <div className={`${styles["rbt-single-widget"]} ${styles["widget_recent_entries"]} ${styles["mt--40"]}`} >
                    <h3 className={styles.title} >Post</h3>
                    <BlogPost blogpost={BlogData && BlogData.blog} />
                  </div>
                  <div className={`${styles["rbt-single-widget"]} ${styles["widget_archive"]} ${styles["mt--40"]}`} >
                    <h3 className={styles.title} >Archives</h3>
                    <Archives blogarc={BlogData && BlogData.blog} />
                  </div>
                  <div className={`${styles["rbt-single-widget"]} ${styles["widget_tag_cloud"]} ${styles["mt--40"]}`} >
                    <h3 className={styles.title} >Tags</h3>
                    <BlogTags tags={BlogData && BlogData.tags} />
                  </div>
                </aside>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SingleBlog;
