import apiBackend from '@src/apiBackend'
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchsForm =
  createAsyncThunk('form/fetchsForm',
    async ({ query, offset = 0, limit = 10 }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.get(`/form/all`, {
          params: {
            query: query,
            offset: offset,
            limit: limit
          },
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        return {
          forms: resp.data.forms,
          total: resp.data.total
        }
      } catch (error) {
        throw error;
      }
    }
  );


export const fetchForm =
  createAsyncThunk('form/fetchForm',
    async (formId, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.get(
          `/form/${formId}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );

export const addForm =
  createAsyncThunk('form/addForm',
    async (form, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          '/form',
          { form },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );



export const updateForm =
  createAsyncThunk('form/updateForm',
    async (form, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.put(
          '/form',
          { form },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return 'user';
      } catch (error) {
        throw error;
      }
    }
  );


export const deleteForm =
  createAsyncThunk('form/deleteForm',
    async (formId, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.delete(
          `/form`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          data: { id: formId },
        });

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );



export const ayForm =
  createAsyncThunk('form/ayForm',
    async ({ prompt }, { dispatch }) => {
      try {
        const tokenGPT = localStorage.getItem('token-gpt')
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          '/form/ay',
          { prompt, tokenGPT },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );






export const addFormResponses =
  createAsyncThunk('form/addFormResponses',
    async ({ projectId, data }, { dispatch }) => {
      try {
        const resp = await apiBackend.post(
          `/api/${projectId}`,
          {
            data,
          },
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );

export const getFormResponses =
  createAsyncThunk('form/getFormResponses',
    async ({ projectId, filter }, { dispatch }) => {
      try {
        const resp = await apiBackend.get(
          `/api/${projectId}`,
          {
            params: {
              filter: JSON.stringify(filter),
              type: 'query'
            },
          },
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );



export const updateFormResponses =
  createAsyncThunk('form/updateFormResponses',
    async ({ projectId, data }, { dispatch }) => {
      try {
        const resp = await apiBackend.put(
          `/api/${projectId}`,
          {
            data,
          },
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );


export const deleteFormResponses =
  createAsyncThunk('form/deleteFormResponses',
    async ({ projectId, id }, { dispatch }) => {
      try {
        const resp = await apiBackend.delete(
          `/api/${projectId}/${id}`,
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );




export const sendBotForm =
  createAsyncThunk('form/sendBotForm',
    async ({ projectId, message, phoneNumber }, { dispatch }) => {
      try {
        const tokenGPT = localStorage.getItem('token-gpt')
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          '/form/whatsapp',
          { message, phoneNumber },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );



  export const activeBotGmail =
  createAsyncThunk('form/activeBotGmail',
    async ({ projectId, bot, form}, { dispatch }) => {
      try {
        const tokenGPT = localStorage.getItem('token-gpt')
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          '/form/gmail',
          { 
            projectId,
            bot,
            form
           },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );

  export const sendFormEmail =
  createAsyncThunk('form/sendFormEmail',
    async ({ id, email }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')

        const resp = await apiBackend.post(
          `/form/send/${id}`,
          { email },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );


export const readBotFTP =
createAsyncThunk('form/readBotFTP',
  async ({ }, { dispatch }) => {
    try {
      const token = localStorage.getItem('token')
      const resp = await apiBackend.post(
        '/form/ftp/read',
        { 
         },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
      );

      return resp.data;
    } catch (error) {
      throw error;
    }
  }
);


export const uploadBotFTP =
createAsyncThunk('form/uploadBotFTP',
  async ({ }, { dispatch }) => {
    try {
      const token = localStorage.getItem('token')
      const resp = await apiBackend.post(
        '/form/ftp/upload',
        { 
         },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
      );

      return resp.data;
    } catch (error) {
      throw error;
    }
  }
);
