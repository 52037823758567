const web = {
    t1: {
        '01': `Model your`,
        '02': `data`,
        '03': `advancedly,`,
        '04': `predict your`,
        '05': `matrix`,
        '06': `to any possible reality and analyze the`,
        '07': `big data`,
        '08': `with vectorized databases`,
    },
    t2: `START NOW`,
    t3: `On your new operating system`,
    t4: {
        '01': `Offline`, 
        '02': `Online`, 
        '03': `Public`, 
        '04': `Private`, 
    },
    tassistant: {
        '01': `Speed`,
        '02': `Texture`,
        '03': `Processing`,
    },
    t5: `A world of possibilities`,
    t6: {
        '01': `Thanks to the`,
        '02': `vectorized databases,`,
        '03': `you go from`,
        '04': `static data`,
        '05': `to`,
        '06': `dynamic data.`,
        '07': `Working with lots of data simultaneously is difficult, that's why`,
        '08': `zero latency in certain dimensions`,
        '09': `is necessary for fast and versatile access.`,
    },
    t7: `Enter now`,
    t8: `SAVE TIME . SAVE TIME . SAVE TIME`,
    t9: `Draw your own conclusions`,
    t10: `Information is power`,
    t11: {
        '01': `Now you can understand your data!`,
        '02': `Through segmentation, with the concept of`,
        '03': `K-Means,`,
        '04': `you have a tool to analyze any data to measure.`,
    },
    t12: `Start now`,
    t13: `Space available in the cloud. All scalable on private and secure instances.`,
    t14: `times faster than working with traditional databases.`,
    t15: `Possible representations to import your metrics and KPIs.`,
    t16: `Analyze data across dimensions`,
    t17: `Each dimension has agents.`,
    t18: {
        '01': `In each dimension, you can create`,
        '02': `flows of agents`,
        '03': `to create networks of interactions.`
    },
    t19: {
        '01': `In each agent, you will choose the`,
        '02': `strategy`,
        '03': `to relate`,
        '04': `each data.`,
        '05': `each data.`,
    },
    t20: `Start your FREE trial`,
    t21: `Dimensions`,
    t22: `Agents`,
    t23: `Data managing ideas`,
    t24: `Your data the first step`,
    t25: {
        '01': `They are your turnkey to build`,
        '02': `amazing applications. They're not just information,`,
        '03': `they are the raw material`,
        '04': `for the creation of`,
        '05': `innovative ideas and their analysis.`,
        '06': `How do we work with data?`,
    },
    t26: `Your workspace`,
    t27: `Store all the content you need in your private or public Cloud according to your needs.`,
    t28: `Heat map`,
    t29: `Representation that shows the distribution and concentration of certain values within a dataset.`,
    t30: `Dynamic questions`,
    t31: `Creation of interactive questionnaires, where questions can adapt to pre-existing data.`,
    t32: `Customized charts`,
    t33: `Allowing selection of chart type, data to visualize, and customization of aspects like colors and labels.`,
    t34: `Video calls`,
    t35: `Real-time communication via video and audio, facilitating collaboration and interaction.`,
    t36: `Contracts`,
    t37: `Creation, management, and electronic invoicing of contracts, streamlining legal and administrative processes.`,
    t38: `Roles and permissions`,
    t39: `Assign different roles and levels of access to users within your own team to create your organization.`,
    t40: `Team sync`,
    t41: `Real-time collaboration of work among several users, ensuring changes made are automatically secured.`,
    t42: `All your contacts`,
    t43: `Centralize and organize all contacts. Easily manage and access contact information.`,
    t44: `Learn to do it, it's very easy`,
    t45: `Change dimensions`,
    t46: {
        '01': `Make your data intelligent, connectable, consistent, secure, efficient, and accessible. Empower your analyses through`,
        '02': `our access.`
    },
    t47: {
        '01': `Start with `,
        '02': `the tutorial`,
        '03': `to understand the different parts of the platform.`,
    },
    t48: `Sign up NOW`,
    t49: `Value your time and automate your data`,
    t50: {
        '01': `And start switching dimensions in our cloud operating system. Analyze and understand patterns to`,
        '02': `work your data.`,
    },

    footer: {
        t00: "HOME",
        t01: "Your team",
        t02: "Updates",
        t03: "Digital Kit",
        t04: "Consult",
        t05: "Study",
    
        t10: "LINKS",
        t11: "Team",
        t12: "Freelancers",
        t13: "Terms",
        t14: "Features",
        t15: "Calculator",
        t16: "Plans",
    
        t20: "AI MODULE",
        t21: "Voice",
        t22: "Image & Video",
        t23: "Content Creation",
        t24: "Media Creation",
    
        t30: "AYTHEN SUPPORT",
        t31: "User Agreement",
        t32: "Privacy Policy",
        t33: "Data Collection and Use",
        t34: "Content Guidelines",
    
        t40: "TO DESIGN",
        t41: "Editor",
        t42: "Apps",
        t43: "Cloud",
        t44: "Plugins",
        t45: "RPA",
    
        t50: "TO FIND",
        t51: "Library",
        t52: "Presets",
        t53: "Devops",
        t54: "IDE",
        t55: "Sandbox",
    
        t60: "TO BUILD",
        t61: "UX/AI",
        t62: "Translate",
        t63: "AI Docs",
        t64: "AI Support",
        t65: "Collaborations",
    
        t70: "TO PROTECT",
        t71: "Blockchain",
        t72: "Data",
        t73: "Pivot",
        t74: "Academy",
        t75: "Freelancers",
    }
    
}

export default web
