
const requireModule = require.context('./', false, /\.js$/);

const steps = {};

requireModule.keys().forEach((fileName) => {
    if (fileName === './index.js') return; 

    const moduleName = fileName.replace(/(\.\/|\.js)/g, '');
  
    steps[moduleName] = requireModule(fileName).default;
});

export default steps ;