import React, { useState } from "react"

import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"


const flagDE = (await import(`@app/pages/shared/assets/flags/de.png`)).default;
const flagEN = (await import(`@app/pages/shared/assets/flags/en.png`)).default;
const flagES = (await import(`@app/pages/shared/assets/flags/es.png`)).default;
const flagFR = (await import(`@app/pages/shared/assets/flags/fr.png`)).default;
const flagIT = (await import(`@app/pages/shared/assets/flags/it.png`)).default;
const flagND = (await import(`@app/pages/shared/assets/flags/nd.png`)).default;
const flagPL = (await import(`@app/pages/shared/assets/flags/pl.png`)).default;
const flagPT = (await import(`@app/pages/shared/assets/flags/pt.png`)).default;
const flagRU = (await import(`@app/pages/shared/assets/flags/ru.png`)).default;
const flagSZ = (await import(`@app/pages/shared/assets/flags/sz.png`)).default;


import i18n from "@src/i18n"

import styles from "./flags.module.css"

const Flags = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const flags = [
    {
      value: "en",
      text: t("form.flags.en"), 
      flag: flagEN,
    },
    {
      value: "es",
      text: t("form.flags.es"),
      flag: flagES,
    },
    {
      value: "fr",
      text: t("form.flags.fr"),
      flag: flagFR,
    },
    {
      value: "de",
      text: t("form.flags.de"),
      flag: flagDE,
    },
    {
      value: "it",
      text: t("form.flags.it"),
      flag: flagIT,
    },
    {
      value: "ru",
      text: t("form.flags.ru"),
      flag: flagRU,
    },
    {
      value: "pt",
      text: t("form.flags.pt"),
      flag: flagPT,
    },
    {
      value: "nl",
      text: t("form.flags.nl"),
      flag: flagND,
    },
    {
      value: "sv",
      text: t("form.flags.sv"),
      flag: flagSZ,
    },
  ]

  const [state, setState] = useState({
    flag: "",
  })

  const selectFlag = (value) => {
    i18n.changeLanguage(value)

    navigate(`/${value}/login`)
    setState((prev) => ({ ...prev, flag: value }))
  }

  return (
    <div className={styles.container}>
      <h4>{t('iam.auth.t2')}</h4>
      <p>
      {t('iam.auth.t3')}
        
      </p>
      <ul className={styles.list}>
        <li>
          <div>
            <svg fill="none" viewBox="0 0 24 24">
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 11.917 9.724 16.5 19 7.5"
              />
            </svg>
          </div>
          <span>
          {t('iam.auth.t4')}
          </span>
        </li>
        <li>
          <div>
            <svg fill="none" viewBox="0 0 24 24">
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 11.917 9.724 16.5 19 7.5"
              />
            </svg>
          </div>
          <span>{t('iam.auth.t5')}</span>
        </li>
      </ul>
      <ul className={styles.flags}>
        {flags.map((flag, index) => (
          <li
            key={index}
            className={`${state.flag == flag.value ? styles.active : ""}`}
            onClick={() => selectFlag(flag.value)}
          >
            <img src={flag.flag} />
            {flag.text}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Flags
