import React from "react";
import Context from "@home/context/Context";


import HeaderTop from "@home/Header/HeaderTop/HeaderTop";
import Header from "@home/Header/Header";
import PopupMobileMenu from "@home/Header/PopUpMobileMenu";
import Footer from "@home/Footers/Footer";
import Copyright from "@home/Footers/Copyright";
import Breadcrumb from "@home/Common/Breadcrumb";
import BackToTop from "../backToTop";
import CtaTwo from "@home/CallToActions/Cta-Two";
import Blog from "@home/Blog/Blog";

import styles from '../index.module.css'

const BlogPage = () => {
  return (
    <>

      <main className={styles["page-wrapper"]}>
        <Context>
          <HeaderTop />
          <Header
            headerTransparent="header-transparent"
            headerSticky="header-sticky"
            btnClass={styles["rainbow-gradient-btn"]}
          />
          <PopupMobileMenu />
          <Breadcrumb title="Our Blog" text="Blog" />

          <Blog />

          <div className={`${styles["rainbow-cta-area"]} ${styles["rainbow-section-gap"]} ${styles["rainbow-section-gapBottom-big"]} ${styles["bg-color-1"]}`} >
            <div className={styles.container}>
              <CtaTwo />
            </div>
          </div>

          <BackToTop />
          <Footer />
          <Copyright />
        </Context>
      </main>
    </>
  );
};

export default BlogPage;
