import React, { useState, useEffect } from "react";

import JSZip from "jszip";
import { saveAs } from "file-saver";


import translationES from "./es/";

const Locales = () => {

  const handleDownload = async () => {
    const zip = new JSZip();

    for (const lan of Object.keys(i18)) {
      const lan18 = { ...i18[lan] };
      const folder = zip.folder(lan);
      for (const key of Object.keys(lan18)) {
        folder.file(`${key}.json`, JSON.stringify(lan18[key]));
      }
    }

    zip
      .generateAsync({ type: "blob" })
      .then((content) => {
        saveAs(content, "carpeta_con_archivos.zip");
      })
      .catch((err) => {
        console.error("Error al generar el archivo ZIP:", err);
      });
  };
  const [isLoadGPT, setIsLoadGPT] = useState(false);
  const [numLoadGPT, setNumLoadGPT] = useState(0);
  const [numTotalGPT, setTotalLoadGPT] = useState(0);

  const handleAllGPT = async ({ language, category }) => {
    if (isLoadGPT) return;
    const updatedI18 = { ...i18 };
    setTotalLoadGPT(Object.keys(updatedI18).length);
    setIsLoadGPT(true);

    let n = 0;
    setNumLoadGPT(n);
    for (const key of Object.keys(updatedI18)) {
      const texts = updatedI18[key];
      for (const subKey of Object.keys(texts)) {
        setNumLoadGPT(++n);
      }
    }

    setIsLoadGPT(false);
  };
  const originLan = "es";
  const handleAllCategoryGPT = async ({ language, category }) => {
    if (isLoadGPT) return;
    const updatedI18 = { ...i18 };
    setTotalLoadGPT(Object.keys(updatedI18).length);
    setIsLoadGPT(true);

    let n = 0;
    setNumLoadGPT(n);

    for (const key of Object.keys(updatedI18[selectedCategory])) {
      await handleGPT({ language, category, key });
      setNumLoadGPT(++n);
    }

    setIsLoadGPT(false);
  };

  const selectIsoLanguage = (iso) => {
    switch (iso) {
      case "en":
        return "Inglés";
      case "es":
        return "Español";
      case "fr":
        return "Francés";
      case "de":
        return "Alemán";
      case "it":
        return "Italiano";
      case "ru":
        return "Ruso";
      case "pt":
        return "Portugués";
      case "nl":
        return "Neerlandés";
      case "sv":
        return "Sueco";
      case "pl":
        return "Polaco";
    }
  };

  const handleGPT = async ({ language, category, key }) => {
    const text = data[originLan][category][key];

    const prompt = `Eres un traductor profesional de más de 20 idiomas, 
    tu única tarea es enviar al usuario el texto traducido al idioma ${selectIsoLanguage(
      language
    )}.
    Si no se puede traducir devuelve el mismo texto, te dejo el texto: \n\n
    ${text}.
    `;


    try {
      const messages = [
        {
          role: "user",
          content: prompt,
        },
      ];

      const openai = null;
      const stream = await openai.chat.completions.create({
        model: "gpt-3.5-turbo",
        messages,
        stream: true,
      });

      let accumulatedText = "";
      for await (const part of stream) {
        const text = part.choices[0]?.delta?.content || "";
        accumulatedText += text;

        const updatedData = { ...data };
        updatedData[language][category][key] = accumulatedText;
        setData(updatedData);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };

  const storeData = translationES;
  const storeLan = "es";

  const [data, setData] = useState({});
  const [i18, setI18] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");

  useEffect(() => {
    setSelectedLanguage(storeLan);
    setSelectedCategory(Object.keys(storeData)[0]);

    setData({ [storeLan]: storeData });
  }, []);

  useEffect(() => {
    if (data[selectedLanguage]) {
      const updatedData = { ...data[selectedLanguage] };
      setI18(updatedData);
    }
  }, [data, selectedLanguage]);

  useEffect(() => {
    if (i18 && selectedCategory) {
      filterAndSetResults();
    }
  }, [i18, selectedCategory]);

  const filterAndSetResults = () => {
    const filteredData = i18[selectedCategory];

    const filteredList = filterData(filteredData, searchTerm);
    setFilteredResults(filteredList);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const filterData = (dataObj, searchTerm) => {
    return Object.entries(dataObj)
      .filter(([key, value]) => {
        if(typeof value == 'object') return
        const normalizedKey = key.toLowerCase();
        const normalizedValue = value.toLowerCase();
        return (
          normalizedKey.includes(searchTerm) ||
          normalizedValue.includes(searchTerm)
        );
      })
      .map(([key, value]) => ({ key, text: value }));
  };

  const handleLanguageChange = (e) => {
    const lan = e.target.value;
    const updateData = { ...data };

    if (!updateData[lan]) {
      updateData[lan] = {};

      Object.keys(storeData).forEach((key) => {
        updateData[lan][key] = {};

        const filtered = { ...storeData[key] };

        Object.keys(filtered).forEach((subKey) => {
          updateData[lan][key][subKey] = "";
        });
      });
      setData(updateData);
    }

    setSelectedLanguage(lan);
  };


  const handleChange = (key, value) => {
    const updatedData = { ...data };
    updatedData[selectedLanguage][selectedCategory][key] = value;
    setData(updatedData);
  };

  return (
    <div>
      <div>
        <button onClick={() => handleDownload()}>Download</button>
        <button
          onClick={() =>
            handleAllCategoryGPT({
              language: selectedLanguage,
              category: selectedCategory,
            })
          }
        >
          All GPT
        </button>
        {isLoadGPT && (
          <div>
            {numLoadGPT}/{numTotalGPT}
          </div>
        )}

        <div>
          <div>
            <select
              id="languageSelect"
              value={selectedLanguage}
              onChange={(e) => handleLanguageChange(e)}
            >
              <option value="en">Inglés</option>
              <option value="es">Español</option>
              <option value="fr">Francés</option>
              <option value="de">Alemán</option>
              <option value="it">Italiano</option>
              <option value="ru">Ruso</option>
              <option value="pt">Portugués</option>
              <option value="nl">Neerlandés</option>
              <option value="sv">Sueco</option>
              <option value="pl">Polaco</option>
            </select>
          </div>
          <div>
            <select
              id="categorySelect"
              value={selectedCategory}
              onChange={(e) => handleCategoryChange(e)}
            >
              {Object.keys(storeData).map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
          <input
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <div>
          <ul>
            {filteredResults &&
              filteredResults.map((item) => (
                <li key={item.key}>
                  <div>
                    <input
                      value={item.key}
                      onChange={(e) => handleChange(item.key, e.target.value)}
                    />
                    <textarea
                      value={item.text}
                      onChange={(e) => handleChange(item.key, e.target.value)}
                    />
                  </div>
                  Words {item.text.split(" ").length}
                  <br />
                  <br />
                  <div
                    onClick={() =>
                      handleGPT({
                        language: selectedLanguage,
                        category: selectedCategory,
                        key: item.key,
                      })
                    }
                  >
                    chat gpt
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Locales;
