
import Context from "@home/context/Context";
import HeaderTop from "@home/Header/HeaderTop/HeaderTop";
import Header from "@home/Header/Header";

import PopupMobileMenu from "@home/Header/PopUpMobileMenu";
import LeftDashboardSidebar from "@home/Header/LeftDashboardSidebar";
import Release from "@home/ReleaseNotes/Release";
import BackToTop from "../backToTop";

import styles from '../index.module.css'

const ReleaseNotesPage = () => {
  return (
    <>
      <main className={`${styles["page-wrapper"]} ${styles["rbt-dashboard-page"]}`} >
        <Context>
          <div className={`${styles["rbt-panel-wrapper"]}`} >
            <HeaderTop />
            <Header
              headerTransparent="header-transparent"
              headerSticky="header-sticky"
              btnClass="rainbow-gradient-btn"
            />
   
            <Release />
          </div>
          <BackToTop />
        </Context>
      </main>
    </>
  );
};

export default ReleaseNotesPage;
