
export const isInsideCircle = (circle, position) => {
    const dx = circle.left - position.left;
    const dy = circle.top - position.top;
    const distance = Math.sqrt(dx * dx + dy * dy);
    return distance < 50;
};

export const updatePointerInventory = (setPointerInventory, itemName, change) => {
    setPointerInventory(prev => {
        const currentAmount = prev[itemName] || 0;
        return { ...prev, [itemName]: currentAmount + change };
    });
};

export const playAudio = (audioRef) => {
    if (audioRef.current) {
        audioRef.current.currentTime = 0;
        audioRef.current.play();
    }
};

export const createWallsAroundCircle = (circle, circleRadius, wallWidth, entrancePosition) => {
    const walls = [];
    const circleLeft = circle.left - circleRadius;
    const circleTop = circle.top - circleRadius;

    switch (entrancePosition) {
        case 'top':
            walls.push(
                { top: circleTop + circleRadius * 2, left: circleLeft, width: circleRadius * 2 + wallWidth, height: wallWidth },
                { top: circleTop, left: circleLeft - wallWidth, width: wallWidth, height: circleRadius * 2 },
                { top: circleTop, left: circleLeft + circleRadius * 2, width: wallWidth, height: circleRadius * 2 }
            );
            break;
        case 'bottom':
            walls.push(
                { top: circleTop - wallWidth, left: circleLeft, width: circleRadius * 2 + wallWidth, height: wallWidth },
                { top: circleTop, left: circleLeft - wallWidth, width: wallWidth, height: circleRadius * 2 },
                { top: circleTop, left: circleLeft + circleRadius * 2, width: wallWidth, height: circleRadius * 2 }
            );
            break;
        case 'left':
            walls.push(
                { top: circleTop - wallWidth, left: circleLeft, width: circleRadius * 2 + wallWidth, height: wallWidth },
                { top: circleTop + circleRadius * 2, left: circleLeft, width: circleRadius * 2 + wallWidth, height: wallWidth },
                { top: circleTop, left: circleLeft + circleRadius * 2, width: wallWidth, height: circleRadius * 2 }
            );
            break;
        case 'right':
            walls.push(
                { top: circleTop - wallWidth, left: circleLeft, width: circleRadius * 2 + wallWidth, height: wallWidth },
                { top: circleTop + circleRadius * 2, left: circleLeft, width: circleRadius * 2 + wallWidth, height: wallWidth },
                { top: circleTop, left: circleLeft - wallWidth, width: wallWidth, height: circleRadius * 2 }
            );
            break;
        default:
            console.error('Invalid entrance position');
    }

    return walls;
};

export const handleTeleport = (circle, circles, setPosition, mapContainerRef) => {
    if (circle.type === 'teleport') {
        let currentIndex = -1
        for (let i = 0; i < circle.route.length; i++) {
            currentIndex = circles.findIndex(c => c.id == circle.route[i])
            if (currentIndex !== -1) break
        }

        if (currentIndex !== -1) {
            const targetCircle = circles[currentIndex]

            setPosition(prevPosition => ({
                ...prevPosition,
                top: targetCircle.top,
                left: targetCircle.left
            }));

            if (mapContainerRef.current) {
                const mapWidth = 4000;
                const mapHeight = 4000;
                const viewportWidth = mapContainerRef.current.clientWidth;
                const viewportHeight = mapContainerRef.current.clientHeight;

                const maxScrollLeft = mapWidth - viewportWidth;
                const maxScrollTop = mapHeight - viewportHeight;

                const scrollLeft = Math.min(Math.max(targetCircle.left - viewportWidth / 2, 0), maxScrollLeft);
                const scrollTop = Math.min(Math.max(targetCircle.top - viewportHeight / 2, 0), maxScrollTop);

                mapContainerRef.current.scrollLeft = scrollLeft;
                mapContainerRef.current.scrollTop = scrollTop;
            }
        }
    }
};
