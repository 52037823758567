import "./funnel.css";
import { useTranslation } from "react-i18next"

const Funnel = ({ }) => {
  const { t } = useTranslation()

  return (
    <div className={"basecamp-pricing"}>
      <div className="header">
        <div className="notification">
          <div className="p">
            <div className="we-re-thrilled">
              {t("funnel.t1.01")}{" "}
            </div>
            <div className="just-let-me-do-my-job">{t("funnel.t1.02")} </div>
          </div>
        </div>
        <div className="header-container">
          <div className="logo3">
            <div className="div2">
              <div className="span">
                {/* prettier-ignore */}
                <svg className="tagline-2044" width="77" height="17" viewBox="0 0 77 17" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41611)"> <path fillRule="evenodd" clipRule="evenodd" d="M7.18431 2.87805L9.4984 7.11734L14.3125 7.6213L10.9843 11.1228L11.9922 15.83L7.62158 13.7652L3.43067 16.1739L4.05779 11.401L0.459495 8.17769L5.21765 7.28927L7.18431 2.87805ZM22.4955 2.26309L24.8096 6.50238L29.6237 7.00634L26.2955 10.5079L27.3033 15.215L22.9328 13.1503L18.7419 15.5589L19.369 10.7861L15.7707 7.56273L20.5289 6.67431L22.4955 2.26309ZM40.1208 5.88742L37.8067 1.64813L35.8401 6.05935L31.0819 6.94777L34.6802 10.1711L34.0531 14.9439L38.244 12.5353L42.6145 14.6001L41.6067 9.89292L44.9349 6.39138L40.1208 5.88742ZM53.1179 1.03317L55.432 5.27245L60.2461 5.77642L56.9179 9.27795L57.9257 13.9851L53.5552 11.9204L49.3643 14.329L49.9914 9.55615L46.3931 6.33281L51.1512 5.44439L53.1179 1.03317ZM70.7432 4.65749L68.4291 0.418207L66.4624 4.82942L61.7043 5.71785L65.3026 8.94119L64.6755 13.714L68.8664 11.3054L73.2369 13.3702L72.2291 8.66299L75.5573 5.16145L70.7432 4.65749Z" fill="#FFE200" /> </g> <defs> <clipPath id="clip0_1604_41611"> <rect width="75.888" height="13.1345" fill="white" transform="translate(0.257812 3.15625) rotate(-2.3)" /> </clipPath> </defs> </svg>
                <div className="truly-amazing">{t("funnel.t1.03")} </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="gradient"></div>

      <div className="headline">
        <div className="another-2-272-organizations-signed-up-last-week">
          {t("funnel.t1.04")}{" "}
        </div>
        <div className="two-simple-plans">
          <span className="two-simple-plans-each">
            {t("funnel.t1.05")} {" "}
          </span>
          <span className="two-simple-plans-span2">
            {t("funnel.t1.06")}
          </span>
          <span className="two-simple-plans-each">
            {t("funnel.t1.07")}
          </span>
        </div>
      </div>

      <div className="pricing-details">
        <div className="standard">
          <div className="price-breakdown">
            <div className="benefits">
              <div className="basecamp4">{t("funnel.t2.07")} </div>
              <div className="ideal-for-freelancers">
                {t("funnel.t2.06")}
                {" "}
              </div>
              <div className="benefit9">
                <div className="every-feature-we-offer-plus" style={{width: '100%'}}>
                  {t("funnel.t2.03")}{" "}
                </div>
                <div className="bullet"></div>
              </div>
              <div className="benefit9">
                <div className="every-feature-we-offer-plus" style={{width: '100%'}}>
                  {t("funnel.t2.04")}{" "}
                </div>
                <div className="bullet"></div>
              </div>
              <div className="benefit9">
                <div className="every-feature-we-offer-plus" style={{width: '100%'}}>
                  {t("funnel.t2.05")}{" "}
                </div>
                <div className="bullet"></div>
              </div>
            </div>
          </div>
          <div className="standard-features">
            <div className="we-only-bill">
              <span>
                <span className="we-only-bill-span">
                  {t("funnel.t2.010")}
                  <br />
                </span>
                <span className="we-only-bill-span2">
                  {" "}
                  {t("funnel.t2.011")}
                </span>
              </span>{" "}
            </div>
            <div className="cost">
              <div className="_15-user-per-month">{t("funnel.t2.01")} </div>
              <div className="div36">$ </div>
            </div>

            <div className="button">
              <div className="try-for-free">{t("funnel.t2.02")} </div>
            </div>
          </div>
        </div>
        <div className="pro-unlimited">
          <div className="pro-features">
            <div className="title">
              <div className="basecamp5">{t("funnel.t2.15")} </div>
              <div className="tag-1">
                <div className="pro-unlimited2">{t("funnel.t2.16")} </div>
              </div>
            </div>
            <div className="perfect-for-growing">
              {t("funnel.t2.08")}{" "}
            </div>
            <div className="benefits2">
              <div className="column-1">
                <div className="benefit10">
                  <div className="every-feature-we-offer-plus">
                    {t("funnel.t2.09")}{" "}
                  </div>
                  <div className="bullet"></div>
                </div>
                <div className="benefit10">
                  <div className="every-feature-we-offer-plus">
                    {t("funnel.t2.10")}{" "}
                  </div>
                  <div className="bullet2"></div>
                </div>
                <div className="benefit10">
                  <div className="every-feature-we-offer-plus">
                    {t("funnel.t2.11")}{" "}
                  </div>
                  <div className="bullet2"></div>
                </div>
              </div>
              <div className="column-2">
                <div className="benefit11">
                  <div className="every-feature-we-offer-plus">
                    {t("funnel.t2.12")}{" "}
                  </div>
                  <div className="bullet2"></div>
                </div>
                <div className="benefit11">
                  <div className="every-feature-we-offer-plus">
                    {t("funnel.t2.13")}{" "}
                  </div>
                  <div className="bullet2"></div>
                </div>
                <div className="benefit11">
                  <div className="every-feature-we-offer-plus">
                    {t("funnel.t2.14")}{" "}
                  </div>
                  <div className="bullet2"></div>
                </div>
              </div>
            </div>

          </div>
          <div className="price-breakdown2">
            <div className="cost2">
              <div className="unlimited-users">{t("funnel.t2.17")}</div>
              <div className="cost3">
                <div className="just">{t("funnel.t2.18")} </div>
                <div className="div37">$ </div>
                <div className="_299-month-billed-annually">
                  {t("funnel.t2.19")}{" "}
                </div>
              </div>
            </div>
            <div className="no-per-user-charges">
              <span>
                <span className="no-per-user-charges-span">
                  {t("funnel.t2.20")}

                  <br />
                </span>
                <span className="no-per-user-charges-span2">
                  {" "}
                  {t("funnel.t2.21")}

                </span>
              </span>{" "}
            </div>

            <div className="button">
              <div className="try-for-free">{t("funnel.t2.22")}</div>
            </div>
          </div>
          <div className="plan-sticker">
            <div className="best-value-for-larger-teams">
              {t("funnel.t2.23")}{" "}
            </div>
          </div>
        </div>
      </div>

      <div className="what-s-included">
        <div className="what-s-included-absolutely-everything">
          {t("funnel.t3.01")}
          {" "}
        </div>
        <div className="included">
          <div className="benefit">
            <div className="loads-of-storage-space">
              {t("funnel.t3.02")}
            </div>
            <div className="icon-check">
              {/* prettier-ignore */}
              <svg className="icon-check-9" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_42074)"> <path d="M6.13222 10.115L2.5357 6.6425C2.29454 6.40966 1.91387 6.40452 1.66651 6.63078L0.542244 7.65914C0.270513 7.9077 0.266676 8.33458 0.533896 8.58797L5.69684 13.4839C5.94115 13.7155 6.32384 13.7162 6.56886 13.4852L14.774 5.7516C15.0426 5.49849 15.0394 5.07051 14.7671 4.82144L13.641 3.79137C13.395 3.56638 13.0168 3.57002 12.7753 3.79968L6.13222 10.115Z" fill="#1D2D35" /> </g> <defs> <clipPath id="clip0_1604_42074"> <rect width="15.232" height="15.232" fill="white" transform="translate(0.0390625 0.853516)" /> </clipPath> </defs> </svg>
            </div>
          </div>
          <div className="benefit">
            <div className="loads-of-storage-space">
              {t("funnel.t3.03")}

              {" "}
            </div>
            <div className="icon-check">
              {/* prettier-ignore */}
              <svg className="icon-check-9" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_42079)"> <path d="M6.46816 10.115L2.87164 6.6425C2.63048 6.40966 2.24981 6.40452 2.00245 6.63078L0.878182 7.65914C0.606451 7.9077 0.602613 8.33458 0.869833 8.58797L6.03278 13.4839C6.27708 13.7155 6.65978 13.7162 6.90479 13.4852L15.1099 5.7516C15.3785 5.49849 15.3753 5.07051 15.1031 4.82144L13.9769 3.79137C13.7309 3.56638 13.3528 3.57002 13.1112 3.79968L6.46816 10.115Z" fill="#1D2D35" /> </g> <defs> <clipPath id="clip0_1604_42079"> <rect width="15.232" height="15.232" fill="white" transform="translate(0.375 0.853516)" /> </clipPath> </defs> </svg>
            </div>
          </div>
          <div className="benefit">
            <div className="loads-of-storage-space">
              {t("funnel.t3.04")}
              {" "}
            </div>
            <div className="icon-check">
              {/* prettier-ignore */}
              <svg className="icon-check-9" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_42084)"> <path d="M6.80409 10.115L3.20758 6.6425C2.96642 6.40966 2.58574 6.40452 2.33839 6.63078L1.21412 7.65914C0.942388 7.9077 0.938551 8.33458 1.20577 8.58797L6.36871 13.4839C6.61302 13.7155 6.99571 13.7162 7.24073 13.4852L15.4458 5.7516C15.7144 5.49849 15.7113 5.07051 15.439 4.82144L14.3128 3.79137C14.0668 3.56638 13.6887 3.57002 13.4472 3.79968L6.80409 10.115Z" fill="#1D2D35" /> </g> <defs> <clipPath id="clip0_1604_42084"> <rect width="15.232" height="15.232" fill="white" transform="translate(0.710938 0.853516)" /> </clipPath> </defs> </svg>
            </div>
          </div>
          <div className="benefit">
            <div className="loads-of-storage-space">
              {t("funnel.t3.05")}
            </div>
            <div className="icon-check">
              {/* prettier-ignore */}
              <svg className="icon-check-9" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_42095)"> <path d="M6.46816 9.28687L2.87164 5.81438C2.63048 5.58153 2.24981 5.57639 2.00245 5.80265L0.878182 6.83101C0.606451 7.07958 0.602613 7.50645 0.869833 7.75985L6.03278 12.6557C6.27708 12.8874 6.65978 12.888 6.90479 12.6571L15.1099 4.92347C15.3785 4.67037 15.3753 4.24239 15.1031 3.99332L13.9769 2.96324C13.7309 2.73825 13.3528 2.74189 13.1112 2.97156L6.46816 9.28687Z" fill="#1D2D35" /> </g> <defs> <clipPath id="clip0_1604_42095"> <rect width="15.232" height="15.232" fill="white" transform="translate(0.375 0.0253906)" /> </clipPath> </defs> </svg>
            </div>
          </div>
          <div className="benefit">
            <div className="loads-of-storage-space">
              {t("funnel.t3.06")}{" "}
            </div>
            <div className="icon-check">
              {/* prettier-ignore */}
              <svg className="icon-check-9" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_42100)"> <path d="M6.80409 9.28687L3.20758 5.81438C2.96642 5.58153 2.58574 5.57639 2.33839 5.80265L1.21412 6.83101C0.942388 7.07958 0.938551 7.50645 1.20577 7.75985L6.36871 12.6557C6.61302 12.8874 6.99571 12.888 7.24073 12.6571L15.4458 4.92347C15.7144 4.67037 15.7113 4.24239 15.439 3.99332L14.3128 2.96324C14.0668 2.73825 13.6887 2.74189 13.4472 2.97156L6.80409 9.28687Z" fill="#1D2D35" /> </g> <defs> <clipPath id="clip0_1604_42100"> <rect width="15.232" height="15.232" fill="white" transform="translate(0.710938 0.0253906)" /> </clipPath> </defs> </svg>
            </div>
          </div>
          <div className="benefit">
            <div className="loads-of-storage-space">
              {t("funnel.t3.07")} {" "}
            </div>
            <div className="icon-check">
              {/* prettier-ignore */}
              <svg className="icon-check-9" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_42105)"> <path d="M6.79628 9.45874L3.19977 5.98625C2.9586 5.75341 2.57793 5.74827 2.33058 5.97453L1.20631 7.00289C0.934576 7.25145 0.930738 7.67833 1.19796 7.93172L6.3609 12.8276C6.60521 13.0593 6.9879 13.0599 7.23292 12.8289L15.438 5.09535C15.7066 4.84224 15.7034 4.41426 15.4312 4.16519L14.305 3.13512C14.059 2.91013 13.6809 2.91377 13.4393 3.14343L6.79628 9.45874Z" fill="#1D2D35" /> </g> <defs> <clipPath id="clip0_1604_42105"> <rect width="15.232" height="15.232" fill="white" transform="translate(0.703125 0.197266)" /> </clipPath> </defs> </svg>
            </div>
          </div>
          <div className="benefit">
            <div className="loads-of-storage-space">
              {t("funnel.t3.08")} {" "}
            </div>
            <div className="icon-check">
              {/* prettier-ignore */}
              <svg className="icon-check-9" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_42110)"> <path d="M6.13222 9.45874L2.5357 5.98625C2.29454 5.75341 1.91387 5.74827 1.66651 5.97453L0.542244 7.00289C0.270513 7.25145 0.266676 7.67833 0.533896 7.93172L5.69684 12.8276C5.94115 13.0593 6.32384 13.0599 6.56886 12.8289L14.774 5.09535C15.0426 4.84224 15.0394 4.41426 14.7671 4.16519L13.641 3.13512C13.395 2.91013 13.0168 2.91377 12.7753 3.14343L6.13222 9.45874Z" fill="#1D2D35" /> </g> <defs> <clipPath id="clip0_1604_42110"> <rect width="15.232" height="15.232" fill="white" transform="translate(0.0390625 0.197266)" /> </clipPath> </defs> </svg>
            </div>
          </div>
        </div>

      </div>
      <div className="quotes">
        <div className="quote-row">
          <div className="filler"></div>
          <div className="blockquote">
            <div className="q">
              <div className="q-text">{t("funnel.t7.01")}</div>
              <div className="mark-wrapper">
                {/* prettier-ignore */}
                <svg className="mark-top-76" width="279" height="28" viewBox="0 0 279 28" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41623)"> <path d="M277.681 5.76444C268.498 6.51067 236.009 5.43819 226.72 5.57874C217.43 5.71929 178.377 4.99137 168.688 4.74182C158.998 4.49227 120.525 3.77272 110.956 3.25038C101.386 2.72805 11.3762 2.02698 1.74098 1.23828C0.903912 6.56546 0.738215 17.2677 0.648438 23.0664C29.2738 24.5539 64.1104 23.5607 108.875 25.1739C153.639 26.7872 157.79 26.2637 167.714 26.4068C177.637 26.5499 216.52 27.0009 226.394 26.6491C236.267 26.2974 254.032 27.5304 277.462 27.3856C277.988 27.1736 278.203 5.77217 277.681 5.76444Z" fill="#FFE200" fillOpacity="0.3" /> </g> <defs> <clipPath id="clip0_1604_41623"> <rect width="277.703" height="27.2485" fill="white" transform="translate(0.46875 0.671875)" /> </clipPath> </defs> </svg>
                <div className="mark">
                  {/* prettier-ignore */}
                  <svg className="mark-bottom-cb-8" width="281" height="25" viewBox="0 0 281 25" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41627)"> <path d="M0.591185 24.2906C9.83744 23.3432 42.609 23.7335 51.9723 23.3944C61.3356 23.0552 100.72 22.9587 110.493 23.0042C120.266 23.0497 159.065 22.9571 168.721 23.2802C178.377 23.6032 269.03 20.838 278.861 22.9864C280.734 23.3613 281.553 1.33546 279.622 0.960903C270.142 -1.07912 180.074 1.57153 170.477 1.13738C160.879 0.703236 121.144 1.07885 111.137 1.14556C101.13 1.21228 61.9208 1.58438 51.9723 2.14883C42.0238 2.71329 24.0938 1.84808 0.474143 2.49217C-0.0525422 2.71707 0.064499 24.2939 0.591185 24.2906Z" fill="#FFE200" fillOpacity="0.75" /> </g> <defs> <clipPath id="clip0_1604_41627"> <rect width="280.595" height="24.5521" fill="white" transform="translate(0.128906 0.121094)" /> </clipPath> </defs> </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="blockquote">
            <div className="q2">
              <div className="q-text">{t("funnel.t7.02")} </div>
              <div className="mark-wrapper2">
                {/* prettier-ignore */}
                <svg className="mark-top-76" width="327" height="33" viewBox="0 0 327 33" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41634)"> <path d="M326.282 6.65136C315.498 7.52771 277.344 6.26821 266.435 6.43327C255.525 6.59834 209.662 5.74348 198.283 5.45042C186.903 5.15735 141.722 4.31232 130.484 3.69891C119.246 3.08549 13.5399 2.26217 2.22446 1.33594C1.24143 7.59206 1.04684 20.1605 0.941406 26.9704C34.5584 28.7173 75.4697 27.5508 128.04 29.4454C180.611 31.34 185.485 30.7252 197.139 30.8933C208.793 31.0614 254.457 31.591 266.052 31.1779C277.646 30.7648 298.509 32.2129 326.025 32.0428C326.642 31.7938 326.895 6.66043 326.282 6.65136Z" fill="#FFE200" fillOpacity="0.3" /> </g> <defs> <clipPath id="clip0_1604_41634"> <rect width="326.128" height="32" fill="white" transform="translate(0.726562 0.671875)" /> </clipPath> </defs> </svg>
                <div className="mark2">
                  {/* prettier-ignore */}
                  <svg className="mark-bottom-cb-8" width="287" height="26" viewBox="0 0 287 26" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41638)"> <path d="M1.30974 25.281C10.7096 24.3179 44.0256 24.7147 53.5445 24.3699C63.0634 24.0251 103.102 23.927 113.037 23.9733C122.973 24.0196 162.417 23.9254 172.233 24.2538C182.049 24.5822 274.208 21.7711 284.203 23.9551C286.107 24.3363 286.94 1.94451 284.977 1.56373C275.339 -0.510188 183.774 2.1845 174.018 1.74314C164.261 1.30178 123.865 1.68363 113.692 1.75145C103.519 1.81928 63.6583 2.19756 53.5445 2.77139C43.4307 3.34523 25.2028 2.46564 1.19075 3.12043C0.655316 3.34907 0.774302 25.2844 1.30974 25.281Z" fill="#FFE200" fillOpacity="0.75" /> </g> <defs> <clipPath id="clip0_1604_41638"> <rect width="285.257" height="24.96" fill="white" transform="translate(0.839844 0.710938)" /> </clipPath> </defs> </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="filler"></div>
        </div>
        <div className="quote-row2">
          <div className="filler"></div>
          <div className="blockquote">
            <div className="q3">
              <div className="q-text">{t("funnel.t7.03")} </div>
              <div className="mark-wrapper3">
                {/* prettier-ignore */}
                <svg className="mark-top-76" width="190" height="20" viewBox="0 0 190 20" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41648)"> <path d="M188.81 4.42906C182.566 4.93645 160.476 4.20723 154.159 4.3028C147.843 4.39836 121.29 3.90342 114.701 3.73375C108.113 3.56407 81.9538 3.07482 75.4472 2.71966C68.9406 2.36451 7.73953 1.88783 1.18817 1.35156C0.619019 4.9737 0.506355 12.2506 0.445312 16.1933C19.9087 17.2047 43.5954 16.5294 74.0324 17.6263C104.469 18.7232 107.292 18.3673 114.039 18.4646C120.786 18.5619 147.225 18.8685 153.938 18.6293C160.651 18.3902 172.73 19.2286 188.661 19.1301C189.018 18.9859 189.165 4.43432 188.81 4.42906Z" fill="#FFE200" fillOpacity="0.3" /> </g> <defs> <clipPath id="clip0_1604_41648"> <rect width="188.82" height="18.5272" fill="white" transform="translate(0.320312 0.96875)" /> </clipPath> </defs> </svg>
                <div className="mark3">
                  {/* prettier-ignore */}
                  <svg className="mark-bottom-cb-8" width="192" height="17" viewBox="0 0 192 17" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41652)"> <path d="M1.17495 16.7064C7.46181 16.0622 29.7444 16.3276 36.1108 16.097C42.4773 15.8664 69.2561 15.8007 75.9011 15.8317C82.5461 15.8627 108.927 15.7997 115.492 16.0194C122.058 16.239 183.696 14.3588 190.381 15.8196C191.654 16.0745 192.211 1.09835 190.898 0.843677C184.452 -0.543407 123.212 1.25886 116.686 0.963672C110.161 0.66848 83.1429 0.92387 76.3388 0.969233C69.5347 1.0146 42.8752 1.2676 36.1108 1.65139C29.3465 2.03519 17.1552 1.4469 1.09536 1.88484C0.737252 2.03776 0.816832 16.7086 1.17495 16.7064Z" fill="#FFE200" fillOpacity="0.75" /> </g> <defs> <clipPath id="clip0_1604_41652"> <rect width="190.787" height="16.6939" fill="white" transform="translate(0.863281 0.273438)" /> </clipPath> </defs> </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="blockquote">
            <div className="q4">
              <div className="q-text">{t("funnel.t7.04")}</div>
              <div className="mark-wrapper4">
                {/* prettier-ignore */}
                <svg className="mark-top-76" width="189" height="20" viewBox="0 0 189 20" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41659)"> <path d="M188.542 4.42699C182.302 4.93403 160.227 4.2053 153.915 4.3008C147.603 4.39631 121.067 3.9017 114.483 3.73214C107.9 3.56257 81.7581 3.07365 75.2559 2.71874C68.7537 2.36383 7.59398 1.88747 1.04704 1.35156C0.478276 4.97126 0.365689 12.2432 0.304688 16.1833C19.755 17.194 43.4256 16.5191 73.8421 17.6153C104.259 18.7115 107.079 18.3558 113.822 18.453C120.564 18.5502 146.985 18.8567 153.693 18.6177C160.402 18.3786 172.473 19.2165 188.393 19.1181C188.75 18.974 188.897 4.43223 188.542 4.42699Z" fill="#FFE200" fillOpacity="0.3" /> </g> <defs> <clipPath id="clip0_1604_41659"> <rect width="188.693" height="18.5147" fill="white" transform="translate(0.179688 0.96875)" /> </clipPath> </defs> </svg>
                <div className="mark4">
                  {/* prettier-ignore */}
                  <svg className="mark-bottom-cb-8" width="192" height="17" viewBox="0 0 192 17" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41663)"> <path d="M1.22161 16.707C7.50423 16.0633 29.7717 16.3285 36.1339 16.0981C42.496 15.8676 69.2568 15.802 75.8973 15.833C82.5378 15.8639 108.901 15.801 115.462 16.0205C122.023 16.24 183.62 14.3611 190.3 15.8208C191.572 16.0756 192.129 1.10954 190.817 0.855037C184.375 -0.53111 123.176 1.26994 116.655 0.974951C110.134 0.679958 83.1343 0.935176 76.3347 0.980508C69.5352 1.02584 42.8937 1.27867 36.1339 1.66221C29.3741 2.04575 17.1911 1.45785 1.14208 1.8955C0.784212 2.04831 0.863739 16.7093 1.22161 16.707Z" fill="#FFE200" fillOpacity="0.75" /> </g> <defs> <clipPath id="clip0_1604_41663"> <rect width="190.658" height="16.6826" fill="white" transform="translate(0.910156 0.285156)" /> </clipPath> </defs> </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="blockquote">
            <div className="q5">
              <div className="q-text">{t("funnel.t7.05")}</div>
              <div className="mark-wrapper5">
                {/* prettier-ignore */}
                <svg className="mark-top-76" width="327" height="33" viewBox="0 0 327 33" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41670)"> <path d="M326.274 6.94824C315.49 7.82459 277.336 6.56509 266.427 6.73015C255.518 6.89521 209.654 6.04036 198.275 5.74729C186.896 5.45423 141.714 4.6092 130.476 3.99578C119.238 3.38236 13.5321 2.55905 2.21665 1.63281C1.23362 7.88893 1.03903 20.4574 0.933594 27.2672C34.5506 29.0142 75.4619 27.8477 128.032 29.7423C180.603 31.6369 185.477 31.0221 197.131 31.1902C208.785 31.3582 254.449 31.8878 266.044 31.4747C277.638 31.0617 298.502 32.5097 326.017 32.3397C326.635 32.0907 326.887 6.95731 326.274 6.94824Z" fill="#FFE200" fillOpacity="0.3" /> </g> <defs> <clipPath id="clip0_1604_41670"> <rect width="326.128" height="32" fill="white" transform="translate(0.71875 0.96875)" /> </clipPath> </defs> </svg>
              </div>
            </div>
          </div>
          <div className="filler"></div>
        </div>
        <div className="quote-row3">
          <div className="filler"></div>
          <div className="blockquote">
            <div className="q6">
              <div className="q-text">{t("funnel.t7.06")}</div>
              <div className="mark-wrapper6">
                {/* prettier-ignore */}
                <svg className="mark-top-76" width="232" height="23" viewBox="0 0 232 23" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41684)"> <path d="M230.796 4.48982C223.177 5.10898 196.221 4.21912 188.513 4.33573C180.806 4.45235 148.402 3.84838 140.363 3.64133C132.323 3.43427 100.401 2.83725 92.4615 2.40385C84.5216 1.97046 9.83855 1.38878 1.844 0.734375C1.14947 5.15443 1.01199 14.0343 0.9375 18.8456C24.6885 20.0798 53.5931 19.2557 90.735 20.5942C127.877 21.9328 131.321 21.4984 139.555 21.6172C147.788 21.7359 180.051 22.1101 188.243 21.8182C196.434 21.5264 211.175 22.5495 230.615 22.4293C231.051 22.2534 231.23 4.49623 230.796 4.48982Z" fill="#FFE200" fillOpacity="0.3" /> </g> <defs> <clipPath id="clip0_1604_41684"> <rect width="230.415" height="22.6086" fill="white" transform="translate(0.785156 0.265625)" /> </clipPath> </defs> </svg>
                <div className="mark6">
                  {/* prettier-ignore */}
                  <svg className="mark-bottom-cb-8" width="234" height="22" viewBox="0 0 234 22" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41688)"> <path d="M1.36469 20.9467C9.03649 20.1606 36.2277 20.4845 43.9966 20.2031C51.7655 19.9216 84.4434 19.8416 92.5522 19.8793C100.661 19.9171 132.853 19.8403 140.865 20.1083C148.877 20.3764 224.093 18.082 232.251 19.8645C233.805 20.1756 234.484 1.90038 232.882 1.5896C225.016 -0.103048 150.285 2.09624 142.322 1.73602C134.359 1.3758 101.389 1.68745 93.0863 1.74281C84.7833 1.79817 52.251 2.1069 43.9966 2.57524C35.7421 3.04359 20.8652 2.3257 1.26758 2.86012C0.830583 3.04672 0.927694 20.9494 1.36469 20.9467Z" fill="#FFE200" fillOpacity="0.75" /> </g> <defs> <clipPath id="clip0_1604_41688"> <rect width="232.815" height="20.3713" fill="white" transform="translate(0.980469 0.894531)" /> </clipPath> </defs> </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="blockquote">
            <div className="q7">
              <div className="q-text">{t("funnel.t7.07")}</div>
              <div className="mark-wrapper7">
                {/* prettier-ignore */}
                <svg className="mark-top-76" width="327" height="33" viewBox="0 0 327 33" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41695)"> <path d="M326.176 6.24511C315.392 7.12146 277.238 5.86196 266.329 6.02702C255.42 6.19209 209.557 5.33723 198.177 5.04417C186.798 4.7511 141.616 3.90607 130.378 3.29266C119.14 2.67924 13.4344 1.85592 2.11899 0.929688C1.13596 7.18581 0.94137 19.7543 0.835938 26.5641C34.4529 28.3111 75.3642 27.1446 127.935 29.0392C180.505 30.9338 185.38 30.319 197.034 30.4871C208.688 30.6551 254.351 31.1847 265.946 30.7716C277.541 30.3585 298.404 31.8066 325.92 31.6365C326.537 31.3875 326.79 6.25418 326.176 6.24511Z" fill="#FFE200" fillOpacity="0.3" /> </g> <defs> <clipPath id="clip0_1604_41695"> <rect width="326.128" height="32" fill="white" transform="translate(0.621094 0.265625)" /> </clipPath> </defs> </svg>
                <div className="mark7">
                  {/* prettier-ignore */}
                  <svg className="mark-bottom-cb-8" width="287" height="26" viewBox="0 0 287 26" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41699)"> <path d="M1.38005 24.8748C10.7799 23.9116 44.096 24.3084 53.6148 23.9636C63.1337 23.6188 103.172 23.5207 113.108 23.567C123.043 23.6133 162.487 23.5191 172.303 23.8476C182.119 24.176 274.279 21.3648 284.274 23.5489C286.177 23.9301 287.01 1.53826 285.047 1.15748C275.409 -0.916438 183.845 1.77825 174.088 1.33689C164.331 0.895528 123.935 1.27738 113.762 1.3452C103.589 1.41303 63.7286 1.79131 53.6148 2.36514C43.501 2.93898 25.2731 2.05939 1.26106 2.71418C0.725628 2.94282 0.844614 24.8781 1.38005 24.8748Z" fill="#FFE200" fillOpacity="0.75" /> </g> <defs> <clipPath id="clip0_1604_41699"> <rect width="285.257" height="24.96" fill="white" transform="translate(0.910156 0.304688)" /> </clipPath> </defs> </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="filler"></div>
        </div>
        <div className="quote-row4">
          <div className="filler"></div>
          <div className="blockquote">
            <div className="q8">
              <div className="q-text">{t("funnel.t7.08")}</div>
              <div className="mark-wrapper8">
                {/* prettier-ignore */}
                <svg className="mark-top-76" width="327" height="33" viewBox="0 0 327 33" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41709)"> <path d="M326.364 6.54199C315.58 7.41834 277.426 6.15884 266.517 6.3239C255.607 6.48896 209.744 5.63411 198.365 5.34104C186.986 5.04798 141.804 4.20295 130.566 3.58953C119.328 2.97611 13.6219 2.1528 2.30649 1.22656C1.32346 7.48268 1.12887 20.0512 1.02344 26.861C34.6404 28.608 75.5517 27.4415 128.122 29.3361C180.693 31.2307 185.567 30.6159 197.221 30.7839C208.875 30.952 254.539 31.4816 266.134 31.0685C277.728 30.6554 298.591 32.1035 326.107 31.9334C326.724 31.6844 326.977 6.55106 326.364 6.54199Z" fill="#FFE200" fillOpacity="0.3" /> </g> <defs> <clipPath id="clip0_1604_41709"> <rect width="326.128" height="32" fill="white" transform="translate(0.808594 0.5625)" /> </clipPath> </defs> </svg>
                <div className="mark8">
                  {/* prettier-ignore */}
                  <svg className="mark-bottom-cb-8" width="286" height="26" viewBox="0 0 286 26" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41713)"> <path d="M0.715988 25.1716C10.1159 24.2085 43.4319 24.6053 52.9508 24.2605C62.4696 23.9157 102.508 23.8176 112.444 23.8639C122.379 23.9102 161.823 23.816 171.639 24.1444C181.455 24.4729 273.615 21.6617 283.61 23.8458C285.513 24.2269 286.346 1.83514 284.383 1.45435C274.745 -0.619563 183.181 2.07512 173.424 1.63376C163.667 1.1924 123.271 1.57425 113.098 1.64208C102.925 1.7099 63.0645 2.08818 52.9508 2.66202C42.837 3.23586 24.6091 2.35626 0.597002 3.01106C0.0615657 3.2397 0.180552 25.175 0.715988 25.1716Z" fill="#FFE200" fillOpacity="0.75" /> </g> <defs> <clipPath id="clip0_1604_41713"> <rect width="285.257" height="24.96" fill="white" transform="translate(0.246094 0.601562)" /> </clipPath> </defs> </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="blockquote">
            <div className="q9">
              <div className="q-text">{t("funnel.t7.09")}</div>
              <div className="mark-wrapper9">
                {/* prettier-ignore */}
                <svg className="mark-top-76" width="327" height="33" viewBox="0 0 327 33" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41720)"> <path d="M325.762 6.54199C314.978 7.41834 276.824 6.15884 265.915 6.3239C255.006 6.48896 209.143 5.63411 197.763 5.34104C186.384 5.04798 141.202 4.20295 129.964 3.58953C118.726 2.97611 13.0204 2.1528 1.70493 1.22656C0.721898 7.48268 0.527308 20.0512 0.421875 26.861C34.0389 28.608 74.9502 27.4415 127.521 29.3361C180.091 31.2307 184.966 30.6159 196.62 30.7839C208.273 30.952 253.937 31.4816 265.532 31.0685C277.127 30.6554 297.99 32.1035 325.506 31.9334C326.123 31.6844 326.376 6.55106 325.762 6.54199Z" fill="#FFE200" fillOpacity="0.3" /> </g> <defs> <clipPath id="clip0_1604_41720"> <rect width="326.128" height="32" fill="white" transform="translate(0.207031 0.5625)" /> </clipPath> </defs> </svg>
                <div className="mark9">
                  {/* prettier-ignore */}
                  <svg className="mark-bottom-cb-8" width="287" height="26" viewBox="0 0 287 26" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41724)"> <path d="M1.39958 25.1716C10.7995 24.2085 44.1155 24.6053 53.6343 24.2605C63.1532 23.9157 103.192 23.8176 113.127 23.8639C123.063 23.9102 162.506 23.816 172.323 24.1444C182.139 24.4729 274.298 21.6617 284.293 23.8458C286.197 24.2269 287.03 1.83514 285.067 1.45435C275.429 -0.619563 183.864 2.07512 174.107 1.63376C164.351 1.1924 123.955 1.57425 113.782 1.64208C103.608 1.7099 63.7481 2.08818 53.6343 2.66202C43.5206 3.23586 25.2926 2.35626 1.2806 3.01106C0.745159 3.2397 0.864145 25.175 1.39958 25.1716Z" fill="#FFE200" fillOpacity="0.75" /> </g> <defs> <clipPath id="clip0_1604_41724"> <rect width="285.257" height="24.96" fill="white" transform="translate(0.929688 0.601562)" /> </clipPath> </defs> </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="filler"></div>
        </div>
        <div className="quote-row5">
          <div className="filler"></div>
          <div className="blockquote">
            <div className="q10">
              <div className="q-text">{t("funnel.t7.10")} </div>
              <div className="mark-wrapper10">
                {/* prettier-ignore */}
                <svg className="mark-top-76" width="200" height="21" viewBox="0 0 200 21" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41734)"> <path d="M199.239 4.50778C192.662 5.04232 169.389 4.27408 162.735 4.37476C156.081 4.47544 128.107 3.95402 121.166 3.77526C114.225 3.59651 86.6663 3.08108 79.8116 2.70692C72.9569 2.33277 8.48136 1.83058 1.57948 1.26562C0.979875 5.08156 0.861184 12.7477 0.796875 16.9014C21.3017 17.967 46.2556 17.2555 78.3211 18.4111C110.387 19.5667 113.36 19.1917 120.468 19.2942C127.577 19.3967 155.429 19.7198 162.502 19.4678C169.574 19.2158 182.299 20.0991 199.083 19.9954C199.459 19.8435 199.613 4.51332 199.239 4.50778Z" fill="#FFE200" fillOpacity="0.3" /> </g> <defs> <clipPath id="clip0_1604_41734"> <rect width="198.923" height="19.5185" fill="white" transform="translate(0.667969 0.859375)" /> </clipPath> </defs> </svg>
                <div className="mark10">
                  {/* prettier-ignore */}
                  <svg className="mark-bottom-cb-8" width="202" height="18" viewBox="0 0 202 18" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41738)"> <path d="M0.699433 17.5874C7.32267 16.9088 30.7974 17.1884 37.5045 16.9454C44.2116 16.7025 72.4232 16.6333 79.4237 16.666C86.4242 16.6986 114.217 16.6322 121.133 16.8636C128.05 17.095 192.986 15.1143 200.029 16.6532C201.37 16.9218 201.957 1.14431 200.574 0.876003C193.783 -0.585295 129.266 1.3134 122.391 1.00242C115.516 0.691432 87.053 0.960487 79.8848 1.00828C72.7166 1.05607 44.6308 1.32261 37.5045 1.72694C30.3782 2.13127 17.5347 1.5115 0.615594 1.97287C0.238321 2.13397 0.32216 17.5898 0.699433 17.5874Z" fill="#FFE200" fillOpacity="0.75" /> </g> <defs> <clipPath id="clip0_1604_41738"> <rect width="200.995" height="17.587" fill="white" transform="translate(0.371094 0.273438)" /> </clipPath> </defs> </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="blockquote">
            <div className="q11">
              <div className="q-text">{t("funnel.t7.11")}</div>
              <div className="mark-wrapper11">
                {/* prettier-ignore */}
                <svg className="mark-top-76" width="327" height="33" viewBox="0 0 327 33" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41745)"> <path d="M325.575 6.83886C314.791 7.71521 276.637 6.45571 265.728 6.62077C254.818 6.78584 208.955 5.93098 197.576 5.63792C186.196 5.34485 141.015 4.49982 129.777 3.88641C118.539 3.27299 12.8329 2.44967 1.51743 1.52344C0.534398 7.77956 0.339808 20.348 0.234375 27.1579C33.8514 28.9048 74.7627 27.7383 127.333 29.6329C179.904 31.5275 184.778 30.9127 196.432 31.0808C208.086 31.2489 253.75 31.7785 265.344 31.3654C276.939 30.9523 297.802 32.4004 325.318 32.2303C325.935 31.9813 326.188 6.84793 325.575 6.83886Z" fill="#FFE200" fillOpacity="0.3" /> </g> <defs> <clipPath id="clip0_1604_41745"> <rect width="326.128" height="32" fill="white" transform="translate(0.0195312 0.859375)" /> </clipPath> </defs> </svg>
                <div className="mark11">
                  {/* prettier-ignore */}
                  <svg className="mark-bottom-cb-8" width="286" height="26" viewBox="0 0 286 26" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41749)"> <path d="M1.11052 25.4685C10.5104 24.5054 43.8264 24.9022 53.3453 24.5574C62.8642 24.2126 102.903 24.1145 112.838 24.1608C122.774 24.2071 162.217 24.1129 172.034 24.4413C181.85 24.7697 274.009 21.9586 284.004 24.1426C285.908 24.5238 286.741 2.13201 284.777 1.75123C275.14 -0.322688 183.575 2.372 173.818 1.93064C164.062 1.48928 123.666 1.87113 113.493 1.93895C103.319 2.00678 63.4591 2.38506 53.3453 2.95889C43.2315 3.53273 25.0036 2.65314 0.991533 3.30793C0.456097 3.53657 0.575083 25.4719 1.11052 25.4685Z" fill="#FFE200" fillOpacity="0.75" /> </g> <defs> <clipPath id="clip0_1604_41749"> <rect width="285.257" height="24.96" fill="white" transform="translate(0.640625 0.898438)" /> </clipPath> </defs> </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="filler"></div>
        </div>
        <div className="quote-row6">
          <div className="filler"></div>
          <div className="blockquote">
            <div className="q12">
              <div className="q-text">{t("funnel.t7.12")}</div>
              <div className="mark-wrapper12">
                {/* prettier-ignore */}
                <svg className="mark-top-76" width="262" height="26" viewBox="0 0 262 26" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41759)"> <path d="M261.44 4.94009C252.812 5.64121 222.287 4.63355 213.559 4.76561C204.832 4.89767 168.139 4.21374 159.035 3.97928C149.931 3.74481 113.783 3.06875 104.792 2.57799C95.8014 2.08722 11.2317 1.42853 2.17885 0.6875C1.39238 5.69269 1.23669 15.7481 1.15234 21.1963C28.0475 22.5939 60.7785 21.6607 102.837 23.1764C144.896 24.6922 148.796 24.2003 158.12 24.3348C167.443 24.4693 203.977 24.8929 213.253 24.5625C222.529 24.232 239.221 25.3905 261.235 25.2544C261.729 25.0552 261.931 4.94735 261.44 4.94009Z" fill="#FFE200" fillOpacity="0.3" /> </g> <defs> <clipPath id="clip0_1604_41759"> <rect width="260.918" height="25.6015" fill="white" transform="translate(0.980469 0.15625)" /> </clipPath> </defs> </svg>
                <div className="mark12">
                  {/* prettier-ignore */}
                  <svg className="mark-bottom-cb-8" width="264" height="24" viewBox="0 0 264 24" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41763)"> <path d="M0.454104 22.7981C9.1415 21.908 39.9323 22.2747 48.7296 21.956C57.527 21.6374 94.5309 21.5467 103.713 21.5895C112.895 21.6323 149.349 21.5452 158.422 21.8488C167.494 22.1523 252.668 19.5542 261.905 21.5727C263.665 21.925 264.434 1.23045 262.62 0.878528C253.713 -1.03819 169.088 1.45225 160.071 1.04434C151.054 0.636435 113.72 0.989342 104.318 1.05203C94.9157 1.11471 58.0768 1.46432 48.7296 1.99466C39.3824 2.525 22.5361 1.71208 0.344137 2.31724C-0.150714 2.52855 -0.0407469 22.8012 0.454104 22.7981Z" fill="#FFE200" fillOpacity="0.75" /> </g> <defs> <clipPath id="clip0_1604_41763"> <rect width="263.635" height="23.0681" fill="white" transform="translate(0.0195312 0.0898438)" /> </clipPath> </defs> </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="blockquote">
            <div className="q13">
              <div className="q-text">{t("funnel.t7.13")}</div>
              <div className="mark-wrapper13">
                {/* prettier-ignore */}
                <svg className="mark-top-76" width="295" height="30" viewBox="0 0 295 30" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41770)"> <path d="M294.37 5.55083C284.638 6.3417 250.206 5.20506 240.361 5.35401C230.516 5.50297 189.126 4.73151 178.857 4.46703C168.588 4.20255 127.813 3.43996 117.672 2.88638C107.53 2.33279 12.1352 1.58979 1.92352 0.753906C1.03638 6.39976 0.860773 17.7422 0.765625 23.8878C31.1034 25.4643 68.0239 24.4116 115.466 26.1214C162.909 27.8312 167.308 27.2764 177.825 27.4281C188.342 27.5797 229.551 28.0577 240.015 27.6849C250.479 27.3121 269.307 28.6189 294.138 28.4654C294.696 28.2407 294.924 5.55901 294.37 5.55083Z" fill="#FFE200" fillOpacity="0.3" /> </g> <defs> <clipPath id="clip0_1604_41770"> <rect width="294.315" height="28.8785" fill="white" transform="translate(0.574219 0.15625)" /> </clipPath> </defs> </svg>
                <div className="mark13">
                  {/* prettier-ignore */}
                  <svg className="mark-bottom-cb-8" width="286" height="26" viewBox="0 0 286 26" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41774)"> <path d="M1.11833 24.7654C10.5182 23.8022 43.8342 24.1991 53.3531 23.8543C62.872 23.5095 102.911 23.4113 112.846 23.4576C122.781 23.5039 162.225 23.4098 172.041 23.7382C181.858 24.0666 274.017 21.2554 284.012 23.4395C285.916 23.8207 286.749 1.42889 284.785 1.0481C275.147 -1.02581 183.583 1.66887 173.826 1.22751C164.069 0.786153 123.674 1.168 113.5 1.23583C103.327 1.30365 63.4669 1.68193 53.3531 2.25577C43.2393 2.82961 25.0114 1.95001 0.999346 2.60481C0.463909 2.83345 0.582895 24.7688 1.11833 24.7654Z" fill="#FFE200" fillOpacity="0.75" /> </g> <defs> <clipPath id="clip0_1604_41774"> <rect width="285.257" height="24.96" fill="white" transform="translate(0.648438 0.195312)" /> </clipPath> </defs> </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="filler"></div>
        </div>
        <div className="quote-row7">
          <div className="filler"></div>
          <div className="blockquote">
            <div className="q14">
              <div className="q-text">{t("funnel.t7.14")}</div>
              <div className="mark-wrapper14">
                {/* prettier-ignore */}
                <svg className="mark-top-76" width="114" height="12" viewBox="0 0 114 12" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41784)"> <path d="M113.487 2.53283C109.735 2.83772 96.4614 2.39954 92.666 2.45696C88.8707 2.51438 72.9149 2.21698 68.956 2.11502C64.9971 2.01307 49.2784 1.71908 45.3686 1.50567C41.4589 1.29226 4.6838 1.00583 0.747156 0.683594C0.40516 2.8601 0.337461 7.23268 0.300781 9.60183C11.9962 10.2096 26.2292 9.80378 44.5185 10.4629C62.8078 11.122 64.5036 10.9081 68.5581 10.9666C72.6125 11.0251 88.4989 11.2093 92.5327 11.0656C96.5666 10.9219 103.825 11.4257 113.398 11.3665C113.612 11.2799 113.7 2.53599 113.487 2.53283Z" fill="#FFE200" fillOpacity="0.3" /> </g> <defs> <clipPath id="clip0_1604_41784"> <rect width="113.46" height="11.1328" fill="white" transform="translate(0.226562 0.453125)" /> </clipPath> </defs> </svg>
                <div className="mark14">
                  {/* prettier-ignore */}
                  <svg className="mark-bottom-cb-8" width="115" height="11" viewBox="0 0 115 11" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41788)"> <path d="M0.452901 10.2962C4.23061 9.90914 17.62 10.0686 21.4455 9.93005C25.271 9.79148 41.3622 9.75204 45.3551 9.77065C49.348 9.78926 65.2 9.75141 69.1451 9.8834C73.0902 10.0154 110.128 8.88561 114.145 9.76336C114.91 9.91656 115.245 0.917524 114.456 0.764491C110.582 -0.0689933 73.7836 1.01397 69.8624 0.836595C65.9412 0.659217 49.7066 0.812679 45.6181 0.839936C41.5296 0.867194 25.5101 1.01922 21.4455 1.24984C17.3809 1.48046 10.0553 1.12696 0.405081 1.39012C0.189895 1.482 0.237714 10.2976 0.452901 10.2962Z" fill="#FFE200" fillOpacity="0.75" /> </g> <defs> <clipPath id="clip0_1604_41788"> <rect width="114.642" height="10.0312" fill="white" transform="translate(0.265625 0.421875)" /> </clipPath> </defs> </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="blockquote">
            <div className="q15">
              <div className="q-text">{t("funnel.t7.15")}</div>
              <div className="mark-wrapper15">
                {/* prettier-ignore */}
                <svg className="mark-top-76" width="189" height="19" viewBox="0 0 189 19" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41795)"> <path d="M188.527 3.90366C182.304 4.40943 160.284 3.68253 153.987 3.77779C147.691 3.87306 121.222 3.37969 114.655 3.21055C108.087 3.04141 82.0112 2.55372 75.5253 2.19969C69.0394 1.84567 8.03276 1.3705 1.50221 0.835938C0.934873 4.44657 0.822568 11.7003 0.761719 15.6305C20.1633 16.6387 43.7747 15.9655 74.115 17.0589C104.455 18.1524 107.269 17.7976 113.994 17.8946C120.72 17.9916 147.075 18.2972 153.766 18.0588C160.458 17.8204 172.499 18.6561 188.379 18.558C188.736 18.4143 188.881 3.9089 188.527 3.90366Z" fill="#FFE200" fillOpacity="0.3" /> </g> <defs> <clipPath id="clip0_1604_41795"> <rect width="188.22" height="18.4684" fill="white" transform="translate(0.640625 0.453125)" /> </clipPath> </defs> </svg>
                <div className="mark15">
                  {/* prettier-ignore */}
                  <svg className="mark-bottom-cb-8" width="191" height="18" viewBox="0 0 191 18" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41799)"> <path d="M0.728642 17.1933C6.99553 16.5512 29.2073 16.8158 35.5535 16.5859C41.8997 16.356 68.5935 16.2906 75.2174 16.3215C81.8412 16.3523 108.138 16.2895 114.683 16.5085C121.227 16.7275 182.67 14.8533 189.333 16.3094C190.603 16.5635 191.158 1.63492 189.849 1.38105C183.424 -0.00162494 122.378 1.79492 115.873 1.50067C109.368 1.20641 82.4362 1.46099 75.6537 1.50621C68.8711 1.55143 42.2964 1.80363 35.5535 2.1862C28.8106 2.56878 16.6581 1.98236 0.649315 2.41891C0.29234 2.57134 0.371668 17.1956 0.728642 17.1933Z" fill="#FFE200" fillOpacity="0.75" /> </g> <defs> <clipPath id="clip0_1604_41799"> <rect width="190.181" height="16.6408" fill="white" transform="translate(0.417969 0.8125)" /> </clipPath> </defs> </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="blockquote">
            <div className="q16">
              <div className="q-text">{t("funnel.t7.16")}</div>
              <div className="mark-wrapper16">
                {/* prettier-ignore */}
                <svg className="mark-top-76" width="327" height="33" viewBox="0 0 327 33" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41806)"> <path d="M326.243 6.43261C315.459 7.30896 277.305 6.04946 266.396 6.21452C255.486 6.37959 209.623 5.52473 198.244 5.23167C186.864 4.9386 141.683 4.09357 130.445 3.48016C119.207 2.86674 13.5008 2.04342 2.1854 1.11719C1.20237 7.37331 1.00778 19.9418 0.902344 26.7516C34.5194 28.4986 75.4306 27.3321 128.001 29.2267C180.571 31.1213 185.446 30.5065 197.1 30.6746C208.754 30.8426 254.418 31.3722 266.012 30.9591C277.607 30.546 298.47 31.9941 325.986 31.824C326.603 31.575 326.856 6.44168 326.243 6.43261Z" fill="#FFE200" fillOpacity="0.3" /> </g> <defs> <clipPath id="clip0_1604_41806"> <rect width="326.128" height="32" fill="white" transform="translate(0.6875 0.453125)" /> </clipPath> </defs> </svg>
                <div className="mark16">
                  {/* prettier-ignore */}
                  <svg className="mark-bottom-cb-8" width="286" height="26" viewBox="0 0 286 26" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41810)"> <path d="M0.883956 25.0623C10.2838 24.0991 43.5999 24.4959 53.1187 24.1511C62.6376 23.8063 102.676 23.7082 112.612 23.7545C122.547 23.8008 161.991 23.7066 171.807 24.0351C181.623 24.3635 273.783 21.5523 283.778 23.7364C285.681 24.1176 286.514 1.72576 284.551 1.34498C274.913 -0.728938 183.349 1.96575 173.592 1.52439C163.835 1.08303 123.439 1.46488 113.266 1.5327C103.093 1.60053 63.2325 1.97881 53.1187 2.55264C43.0049 3.12648 24.777 2.24689 0.764971 2.90168C0.229534 3.13032 0.34852 25.0656 0.883956 25.0623Z" fill="#FFE200" fillOpacity="0.75" /> </g> <defs> <clipPath id="clip0_1604_41810"> <rect width="285.257" height="24.96" fill="white" transform="translate(0.414062 0.492188)" /> </clipPath> </defs> </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="filler"></div>
        </div>
        <div className="quote-row8">
          <div className="filler"></div>
          <div className="blockquote">
            <div className="q17">
              <div className="q-text">{t("funnel.t7.17")}</div>
              <div className="mark-wrapper17">
                {/* prettier-ignore */}
                <svg className="mark-top-76" width="327" height="33" viewBox="0 0 327 33" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41820)"> <path d="M325.571 6.72949C314.787 7.60584 276.633 6.34634 265.724 6.5114C254.814 6.67646 208.951 5.82161 197.572 5.52854C186.193 5.23548 141.011 4.39045 129.773 3.77703C118.535 3.16361 12.829 2.3403 1.51352 1.41406C0.530492 7.67018 0.335901 20.2387 0.230469 27.0485C33.8475 28.7955 74.7588 27.629 127.329 29.5236C179.9 31.4182 184.774 30.8034 196.428 30.9714C208.082 31.1395 253.746 31.6691 265.341 31.256C276.935 30.8429 297.798 32.291 325.314 32.1209C325.932 31.8719 326.184 6.73856 325.571 6.72949Z" fill="#FFE200" fillOpacity="0.3" /> </g> <defs> <clipPath id="clip0_1604_41820"> <rect width="326.128" height="32" fill="white" transform="translate(0.015625 0.75)" /> </clipPath> </defs> </svg>
                <div className="mark17">
                  {/* prettier-ignore */}
                  <svg className="mark-bottom-cb-8" width="287" height="26" viewBox="0 0 287 26" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41824)"> <path d="M1.28239 25.3591C10.6823 24.396 43.9983 24.7928 53.5172 24.448C63.036 24.1032 103.075 24.0051 113.01 24.0514C122.945 24.0977 162.389 24.0035 172.205 24.3319C182.022 24.6604 274.181 21.8492 284.176 24.0333C286.08 24.4144 286.913 2.02264 284.949 1.64185C275.312 -0.432063 183.747 2.26262 173.99 1.82126C164.233 1.3799 123.838 1.76175 113.664 1.82958C103.491 1.8974 63.631 2.27568 53.5172 2.84952C43.4034 3.42336 25.1755 2.54376 1.16341 3.19856C0.627972 3.4272 0.746958 25.3625 1.28239 25.3591Z" fill="#FFE200" fillOpacity="0.75" /> </g> <defs> <clipPath id="clip0_1604_41824"> <rect width="285.257" height="24.96" fill="white" transform="translate(0.8125 0.789062)" /> </clipPath> </defs> </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="blockquote">
            <div className="q18">
              <div className="q-text">{t("funnel.t7.18")}</div>
              <div className="mark-wrapper18">
                {/* prettier-ignore */}
                <svg className="mark-top-76" width="261" height="27" viewBox="0 0 261 27" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41831)"> <path d="M260.458 5.51844C251.862 6.21702 221.447 5.21301 212.751 5.34459C204.055 5.47617 167.495 4.79472 158.424 4.5611C149.353 4.32749 113.336 3.65388 104.378 3.16489C95.4195 2.6759 11.1562 2.0196 2.13607 1.28125C1.35244 6.26831 1.19733 16.2873 1.11328 21.7157C27.9111 23.1083 60.5235 22.1785 102.43 23.6887C144.336 25.199 148.222 24.7089 157.512 24.8429C166.802 24.9769 203.203 25.399 212.446 25.0697C221.688 24.7404 238.319 25.8948 260.254 25.7592C260.746 25.5607 260.947 5.52567 260.458 5.51844Z" fill="#FFE200" fillOpacity="0.3" /> </g> <defs> <clipPath id="clip0_1604_41831"> <rect width="259.973" height="25.5088" fill="white" transform="translate(0.945312 0.75)" /> </clipPath> </defs> </svg>
                <div className="mark18">
                  {/* prettier-ignore */}
                  <svg className="mark-bottom-cb-8" width="264" height="24" viewBox="0 0 264 24" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41835)"> <path d="M1.31583 23.3918C9.97175 22.5049 40.651 22.8703 49.4165 22.5528C58.182 22.2353 95.0518 22.145 104.201 22.1876C113.35 22.2302 149.672 22.1435 158.711 22.4459C167.751 22.7484 252.616 20.1597 261.82 22.1709C263.573 22.5219 264.34 1.9023 262.532 1.55165C253.657 -0.358124 169.339 2.12329 160.355 1.71686C151.37 1.31043 114.172 1.66206 104.803 1.72452C95.4353 1.78698 58.7298 2.13532 49.4165 2.66374C40.1031 3.19216 23.3179 2.38218 1.20626 2.98515C0.713198 3.1957 0.822767 23.3949 1.31583 23.3918Z" fill="#FFE200" fillOpacity="0.75" /> </g> <defs> <clipPath id="clip0_1604_41835"> <rect width="262.681" height="22.9845" fill="white" transform="translate(0.882812 0.765625)" /> </clipPath> </defs> </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="filler"></div>
        </div>
        <div className="quote-row9">
          <div className="filler"></div>
          <div className="blockquote">
            <div className="q19">
              <div className="q-text">{t("funnel.t7.19")}</div>
              <div className="mark-wrapper19">
                {/* prettier-ignore */}
                <svg className="mark-top-76" width="231" height="23" viewBox="0 0 231 23" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41845)"> <path d="M230.327 4.27412C222.702 4.89379 195.723 4.0032 188.01 4.11991C180.296 4.23663 147.866 3.63217 139.82 3.42494C131.774 3.21772 99.826 2.62021 91.8797 2.18646C83.9333 1.75272 9.18955 1.17056 1.18849 0.515625C0.493394 4.93928 0.355801 13.8264 0.28125 18.6415C24.0516 19.8768 52.9797 19.052 90.1518 20.3916C127.324 21.7313 130.771 21.2966 139.011 21.4154C147.252 21.5343 179.54 21.9087 187.739 21.6166C195.937 21.3246 210.689 22.3485 230.146 22.2282C230.582 22.0521 230.761 4.28054 230.327 4.27412Z" fill="#FFE200" fillOpacity="0.3" /> </g> <defs> <clipPath id="clip0_1604_41845"> <rect width="230.602" height="22.627" fill="white" transform="translate(0.128906 0.046875)" /> </clipPath> </defs> </svg>
                <div className="mark19">
                  {/* prettier-ignore */}
                  <svg className="mark-bottom-cb-8" width="234" height="22" viewBox="0 0 234 22" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41849)"> <path d="M0.708754 20.7286C8.38679 19.9419 35.6001 20.266 43.3753 19.9844C51.1505 19.7027 83.8551 19.6226 91.9705 19.6604C100.086 19.6982 132.304 19.6213 140.323 19.8895C148.341 20.1578 223.619 17.8616 231.783 19.6456C233.338 19.9569 234.018 1.66678 232.414 1.35575C224.542 -0.338273 149.75 1.86281 141.781 1.5023C133.811 1.14178 100.815 1.45369 92.505 1.50909C84.1953 1.56449 51.6365 1.87348 43.3753 2.3422C35.1141 2.81092 20.2252 2.09245 0.611564 2.6273C0.174208 2.81406 0.271398 20.7313 0.708754 20.7286Z" fill="#FFE200" fillOpacity="0.75" /> </g> <defs> <clipPath id="clip0_1604_41849"> <rect width="233.005" height="20.3879" fill="white" transform="translate(0.324219 0.660156)" /> </clipPath> </defs> </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="blockquote">
            <div className="q20">
              <div className="q-text">{t("funnel.t7.20")}</div>
              <div className="mark-wrapper20">
                {/* prettier-ignore */}
                <svg className="mark-top-76" width="293" height="29" viewBox="0 0 293 29" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41856)"> <path d="M292.065 5.40002C282.41 6.1847 248.247 5.05695 238.479 5.20475C228.711 5.35254 187.645 4.58711 177.456 4.3247C167.267 4.06229 126.812 3.30566 116.749 2.75641C106.687 2.20716 12.0384 1.46997 1.90665 0.640625C1.02645 6.24231 0.852216 17.496 0.757812 23.5935C30.8583 25.1577 67.4899 24.1133 114.561 25.8097C161.632 27.5061 165.997 26.9556 176.432 27.1061C186.867 27.2566 227.754 27.7308 238.136 27.3609C248.518 26.991 267.198 28.2876 291.836 28.1353C292.388 27.9124 292.615 5.40814 292.065 5.40002Z" fill="#FFE200" fillOpacity="0.3" /> </g> <defs> <clipPath id="clip0_1604_41856"> <rect width="292.013" height="28.6526" fill="white" transform="translate(0.566406 0.046875)" /> </clipPath> </defs> </svg>
                <div className="mark20">
                  {/* prettier-ignore */}
                  <svg className="mark-bottom-cb-8" width="287" height="26" viewBox="0 0 287 26" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41860)"> <path d="M1.33318 24.656C10.7331 23.6929 44.0491 24.0897 53.5679 23.7449C63.0868 23.4001 103.126 23.302 113.061 23.3483C122.996 23.3946 162.44 23.3004 172.256 23.6288C182.073 23.9572 274.232 21.1461 284.227 23.3301C286.13 23.7113 286.963 1.31951 285 0.938726C275.362 -1.13519 183.798 1.5595 174.041 1.11814C164.284 0.676778 123.889 1.05863 113.715 1.12645C103.542 1.19428 63.6817 1.57256 53.5679 2.14639C43.4541 2.72023 25.2262 1.84064 1.21419 2.49543C0.678753 2.72407 0.797739 24.6594 1.33318 24.656Z" fill="#FFE200" fillOpacity="0.75" /> </g> <defs> <clipPath id="clip0_1604_41860"> <rect width="285.257" height="24.96" fill="white" transform="translate(0.863281 0.0859375)" /> </clipPath> </defs> </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="blockquote">
            <div className="q21">
              <div className="q-text">{t("funnel.t7.21")}</div>
              <div className="mark-wrapper21">
                {/* prettier-ignore */}
                <svg className="mark-top-76" width="287" height="29" viewBox="0 0 287 29" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41867)"> <path d="M286.447 5.29772C276.975 6.06747 243.462 4.96118 233.88 5.10616C224.298 5.25115 184.014 4.50028 174.019 4.24287C164.024 3.98545 124.338 3.24322 114.467 2.70443C104.596 2.16563 11.7495 1.44247 1.81057 0.628906C0.94712 6.12398 0.776201 17.1635 0.683594 23.145C30.2112 24.6794 66.1457 23.6548 112.321 25.319C158.496 26.9831 162.778 26.4431 173.014 26.5907C183.251 26.7383 223.359 27.2035 233.544 26.8406C243.728 26.4778 262.053 27.7497 286.222 27.6003C286.764 27.3816 286.986 5.30569 286.447 5.29772Z" fill="#FFE200" fillOpacity="0.3" /> </g> <defs> <clipPath id="clip0_1604_41867"> <rect width="286.455" height="28.1073" fill="white" transform="translate(0.496094 0.046875)" /> </clipPath> </defs> </svg>
                <div className="mark21">
                  {/* prettier-ignore */}
                  <svg className="mark-bottom-cb-8" width="286" height="26" viewBox="0 0 286 26" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41871)"> <path d="M0.891769 24.656C10.2916 23.6929 43.6077 24.0897 53.1265 23.7449C62.6454 23.4001 102.684 23.302 112.619 23.3483C122.555 23.3946 161.999 23.3004 171.815 23.6288C181.631 23.9572 273.791 21.1461 283.785 23.3301C285.689 23.7113 286.522 1.31951 284.559 0.938726C274.921 -1.13519 183.356 1.5595 173.6 1.11814C163.843 0.676778 123.447 1.05863 113.274 1.12645C103.101 1.19428 63.2403 1.57256 53.1265 2.14639C43.0127 2.72023 24.7848 1.84064 0.772783 2.49543C0.237347 2.72407 0.356333 24.6594 0.891769 24.656Z" fill="#FFE200" fillOpacity="0.75" /> </g> <defs> <clipPath id="clip0_1604_41871"> <rect width="285.257" height="24.96" fill="white" transform="translate(0.421875 0.0859375)" /> </clipPath> </defs> </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="filler"></div>
        </div>
        <div className="quote-row10">
          <div className="filler"></div>
          <div className="blockquote">
            <div className="q22">
              <div className="q-text">{t("funnel.t7.22")}</div>
              <div className="mark-wrapper22">
                {/* prettier-ignore */}
                <svg className="mark-top-76" width="327" height="33" viewBox="0 0 327 33" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41881)"> <path d="M326.36 6.32324C315.576 7.19959 277.422 5.94009 266.513 6.10515C255.603 6.27021 209.74 5.41536 198.361 5.12229C186.982 4.82923 141.8 3.9842 130.562 3.37078C119.324 2.75736 13.618 1.93405 2.30258 1.00781C1.31955 7.26393 1.12496 19.8324 1.01953 26.6422C34.6365 28.3892 75.5478 27.2227 128.118 29.1173C180.689 31.0119 185.563 30.3971 197.217 30.5652C208.871 30.7332 254.535 31.2628 266.13 30.8497C277.724 30.4367 298.588 31.8847 326.103 31.7147C326.721 31.4657 326.973 6.33231 326.36 6.32324Z" fill="#FFE200" fillOpacity="0.3" /> </g> <defs> <clipPath id="clip0_1604_41881"> <rect width="326.128" height="32" fill="white" transform="translate(0.804688 0.34375)" /> </clipPath> </defs> </svg>
                <div className="mark22">
                  {/* prettier-ignore */}
                  <svg className="mark-bottom-cb-8" width="287" height="26" viewBox="0 0 287 26" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41885)"> <path d="M1.25896 24.9529C10.6588 23.9897 43.9749 24.3866 53.4937 24.0418C63.0126 23.697 103.051 23.5988 112.987 23.6451C122.922 23.6914 162.366 23.5973 172.182 23.9257C181.998 24.2541 274.158 21.4429 284.153 23.627C286.056 24.0082 286.889 1.61639 284.926 1.2356C275.288 -0.838313 183.724 1.85637 173.967 1.41501C164.21 0.973653 123.814 1.3555 113.641 1.42333C103.468 1.49115 63.6075 1.86943 53.4937 2.44327C43.3799 3.01711 25.152 2.13751 1.13997 2.79231C0.604534 3.02095 0.72352 24.9563 1.25896 24.9529Z" fill="#FFE200" fillOpacity="0.75" /> </g> <defs> <clipPath id="clip0_1604_41885"> <rect width="285.257" height="24.96" fill="white" transform="translate(0.789062 0.382812)" /> </clipPath> </defs> </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="blockquote">
            <div className="q23">
              <div className="q-text">{t("funnel.t7.23")}</div>
              <div className="mark-wrapper23">
                {/* prettier-ignore */}
                <svg className="mark-top-76" width="182" height="19" viewBox="0 0 182 19" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41892)"> <path d="M180.706 3.65055C174.742 4.13521 153.641 3.43866 147.608 3.52994C141.575 3.62123 116.211 3.14846 109.918 2.98639C103.625 2.82431 78.6377 2.35698 72.4227 2.01774C66.2077 1.6785 7.74865 1.22318 1.49082 0.710938C0.947173 4.17079 0.839558 11.1216 0.78125 14.8877C19.3726 15.8538 41.998 15.2087 71.0713 16.2565C100.145 17.3043 102.84 16.9642 109.285 17.0572C115.73 17.1501 140.984 17.443 147.396 17.2146C153.809 16.9861 165.347 17.787 180.564 17.6929C180.905 17.5552 181.045 3.65557 180.706 3.65055Z" fill="#FFE200" fillOpacity="0.3" /> </g> <defs> <clipPath id="clip0_1604_41892"> <rect width="180.36" height="17.6971" fill="white" transform="translate(0.664062 0.34375)" /> </clipPath> </defs> </svg>
                <div className="mark23">
                  {/* prettier-ignore */}
                  <svg className="mark-bottom-cb-8" width="183" height="17" viewBox="0 0 183 17" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41896)"> <path d="M0.364106 16.0969C6.36929 15.4815 27.6535 15.7351 33.7347 15.5148C39.8159 15.2945 65.3949 15.2318 71.7422 15.2614C78.0894 15.291 103.288 15.2308 109.56 15.4406C115.831 15.6504 174.708 13.8545 181.093 15.2498C182.309 15.4933 182.841 1.18814 181.587 0.944877C175.43 -0.380059 116.933 1.34146 110.7 1.0595C104.467 0.77753 78.6596 1.02148 72.1603 1.06481C65.661 1.10814 40.196 1.34981 33.7347 1.71641C27.2734 2.08301 15.6284 1.52107 0.288091 1.93939C-0.0539764 2.08546 0.0220386 16.099 0.364106 16.0969Z" fill="#FFE200" fillOpacity="0.75" /> </g> <defs> <clipPath id="clip0_1604_41896"> <rect width="182.239" height="15.9459" fill="white" transform="translate(0.0664062 0.398438)" /> </clipPath> </defs> </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="filler"></div>
        </div>


      </div>
      <div className="hightlights">
        <div className="highlights">
          <div className="we-asked-our">
            <span>
              <span className="we-asked-our-span">
                {t("funnel.t4.01")}{" "}
              </span>
              <span className="we-asked-our-span2">
                {t("funnel.t4.02")}
              </span>
              <span className="we-asked-our-span3">
                {" "}
                {t("funnel.t4.03")}
              </span>
            </span>{" "}
          </div>
          <div className="non-profit-title">
            {t("funnel.t4.04")}{" "}
          </div>
          <div className="divider"></div>
        </div>
        <div className="highlights2">
          <div className="with-basecamp-text">
            {t("funnel.t4.05")}{" "}
          </div>
          <div className="non-profit-title">
            {t("funnel.t4.06")}{" "}
          </div>
          <div className="divider"></div>
          <div className="highlights5">
          <div className="when-you-switch">
            {t("funnel.t4.13")}
            {" "}
          </div>
          <div className="non-profit-title">{t("funnel.t4.14")} </div>
        </div>
        </div>
        <div className="highlights3">
          <div className="non-profit-title">
            {t("funnel.t4.07")}{" "}
          </div>
          <div className="divider"></div>
          <div className="yes-we-offer">
            <span>
              <span className="with-basecamp-text">
                {t("funnel.t4.08")} {" "}
              </span>
              <span className="yes-we-offer-href">
                {t("funnel.t4.09")}
              </span>
              <span className="with-basecamp-text">
                {" "}
                {t("funnel.t4.10")}
              </span>
            </span>{" "}
          </div>

        </div>
        <div className="highlights4">
          <div className="non-profit-title">
            {t("funnel.t4.11")}{" "}
          </div>
          <div className="divider"></div>
          <div className="with-basecamp-text">
            {t("funnel.t4.12")}{" "}
          </div>
        </div>
        
      </div>



        <div id="testimonials">
          <figure className="testimonial daniel">
            <figcaption>
              <img src="https://github.com/annafkt/frontend-mentor-challenges/blob/main/challenges/testimonials-grid-section/images/image-daniel.jpg?raw=true" alt="" width="35" />
              <p className="name">{t('funnel.t8.01')}</p>
              <p className="title">{t('funnel.t8.02')}</p>
            </figcaption>
            <blockquote>
              <h3 className="quote-part-1">{t('funnel.t8.03')}</h3>
              <p className="quote-part-2">{t('funnel.t8.04')}</p>
            </blockquote>
          </figure>
          <figure className="testimonial jonathan">
            <figcaption>
              <img src="https://github.com/annafkt/frontend-mentor-challenges/blob/main/challenges/testimonials-grid-section/images/image-jonathan.jpg?raw=true" alt="" width="35" />
              <p className="name">{t('funnel.t8.11')}</p>
              <p className="title">{t('funnel.t8.12')}</p>
            </figcaption>
            <blockquote>
              <h3 className="quote-part-1">{t('funnel.t8.13')}</h3>
              <p className="quote-part-2">{t('funnel.t8.14')}</p>
            </blockquote>
          </figure>
          <figure className="testimonial jeanette">
            <figcaption>
              <img src="https://github.com/annafkt/frontend-mentor-challenges/blob/main/challenges/testimonials-grid-section/images/image-jeanette.jpg?raw=true" alt="" width="35" />
              <p className="name">{t('funnel.t8.21')}</p>
              <p className="title">{t('funnel.t8.22')}</p>
            </figcaption>
            <blockquote>
              <h3 className="quote-part-1">{t('funnel.t8.23')}</h3>
              <p className="quote-part-2">{t('funnel.t8.24')}</p>
            </blockquote>
          </figure>
          <figure className="testimonial patrick">
            <figcaption>
              <img src="https://github.com/annafkt/frontend-mentor-challenges/blob/main/challenges/testimonials-grid-section/images/image-patrick.jpg?raw=true" alt="" width="35" />
              <p className="name">{t('funnel.t8.31')}</p>
              <p className="title">{t('funnel.t8.32')}</p>
            </figcaption>
            <blockquote>
              <h3 className="quote-part-1">{t('funnel.t8.33')}</h3>
              <p className="quote-part-2">{t('funnel.t8.34')}</p>
            </blockquote>
          </figure>
        </div>

      <div className="footer">
     
        <div className="site-map">
          <div className="links">
            <div className="footer-item">
              <div className="footer-title">Product </div>
              <div className="footer-label">Pricing </div>
              <div className="footer-label">Features </div>
              <div className="footer-label">Apps + Desktop </div>
              <div className="footer-label">Integrations </div>
              <div className="footer-label">Updates </div>
              <div className="footer-label">Status </div>
            </div>
            <div className="footer-item">
              <div className="footer-title">Company </div>
              <div className="footer-label">About </div>
              <div className="footer-label">Customers </div>
              <div className="footer-label">Newsletter </div>
              <div className="footer-label">Handbook </div>
              <div className="footer-label">Podcast </div>
              <div className="footer-label">Jobs </div>
            </div>
            <div className="footer-item">
              <div className="footer-title">Resources </div>
              <div className="footer-label">Support </div>
              <div className="footer-label">Learn </div>
              <div className="footer-label">Articles </div>
              <div className="footer-label">Remote Resources </div>
              <div className="footer-label">Customer Rights </div>
              <div className="footer-label">Policies &amp; Terms </div>
              <div className="footer-label">Books </div>
            </div>
          </div>
          <div className="contact-us">
            <div className="contact">
              <div className="we-re-here-to-help">{t("funnel.t5.01")} </div>
              <div className="always-humans">
                <span>
                  <span className="always-humans-span">
                    {t("funnel.t5.02")}{" "}
                  </span>
                  <span className="always-humans-span2">
                    {t("funnel.t5.03")}
                  </span>
                  <span className="always-humans-span3">
                    {" "}
                    {t("funnel.t5.04")} {" "}
                  </span>
                  <span className="always-humans-span4">
                    {t("funnel.t5.05")}
                  </span>
                </span>{" "}
              </div>
            </div>
            <div className="hey-email-advert">
              <div className="if-you-love-basecamp-you-ll-love">
                {t("funnel.t5.06")}{" "}
              </div>
              <div className="logo2">
                <div className="hey">HEY </div>
              </div>
              <div className="our-take-on-email">{t("funnel.t5.07")} </div>
            </div>
          </div>
        </div>
      </div>
      <div className="legal">
          <div className="a">
            {/* prettier-ignore */}
            <svg className="icon-basecamp-453" width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg" > <g clipPath="url(#clip0_1604_41529)"> <path d="M15.8562 28.9727C10.9803 29.037 6.31656 26.9812 3.07474 23.3384C2.72849 22.9538 2.62451 22.4087 2.80488 21.9237C3.67988 19.5685 6.0759 14.0896 9.06888 14.0733C10.5899 14.0733 11.7266 15.2835 12.6425 16.2484C12.9461 16.5852 13.2712 16.902 13.6156 17.1971C14.9008 15.5713 15.9726 13.7877 16.8048 11.8898C17.1499 11.2343 17.9485 10.9644 18.6204 11.2764C19.2924 11.5884 19.6016 12.3725 19.3235 13.0592C16.0525 20.0674 14.3025 20.0674 13.7301 20.0674C12.4544 20.0674 11.5303 19.0861 10.6389 18.1456C10.2383 17.7204 9.40414 16.8373 9.08522 16.8373C8.37381 16.9599 6.82821 19.405 5.71609 22.0872C8.39583 24.7779 12.0596 26.2552 15.8562 26.176C21.3434 26.176 25.4403 24.6795 27.2066 22.0872C26.6015 15.1608 22.7581 5.66672 15.8562 5.66672C10.0093 5.66672 5.60978 9.75552 2.77217 17.7695C2.48565 18.4461 1.72202 18.783 1.02914 18.5382C0.336257 18.2936 -0.0465482 17.552 0.155361 16.8454C3.42637 7.58841 8.70907 2.89453 15.8562 2.89453C25.1868 2.89453 29.5127 14.8174 30.0116 22.3407C30.0325 22.625 29.97 22.9092 29.8316 23.1585C27.6564 26.8956 22.6927 28.9727 15.8562 28.9727Z" fill="white" /> </g> <defs> <clipPath id="clip0_1604_41529"> <rect width="30.3552" height="30.3552" fill="white" transform="translate(0.101562 0.957031)" /> </clipPath> </defs> </svg>
            <div className="basecamp">Basecamp </div>
          </div>
          <div className="is-designed-built">
            <span>
              <span className="is-designed-built-span">
                {t("funnel.t6.01")}{" "}
              </span>
              <span className="is-designed-built-span2">
                {t("funnel.t6.02")}
              </span>
              <span className="is-designed-built-span3">
                {t("funnel.t6.03")}.
              </span>
            </span>{" "}
          </div>
      </div>
    </div>
  );
};


export default Funnel;