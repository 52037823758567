import { createSlice } from '@reduxjs/toolkit'
import {
	deleteInstanceDatabase,
	getAllInstanceDatabases,
	getEditorSheetData,
	getPremiumSheetData,
	getScalewayInstance,
} from '../actions/data.js'


const {selectedSheet} = (await import(`@app/components/constants/harcodedDataStuff.js`));

const datatableSlices = createSlice({
	name: 'datatable',
	initialState: {
		currentSheet: null,
		scalewayInstance: [],
		instanceDatabases: [],
		selectedPremiumSheet: null,
		selectedPremiumSheetData: selectedSheet,
		selectedEditorSheetData: null,
		selectedPremiumSheetPage: {},
		selectedCell: { col: 'A', row: 1 },
		selectedCells: [],
		items: [],
		renderedSheet: 0,
		exportedFunctions: null,
		focusedCell: [null, null],
		selectedColumn: { id: null, titleColumn: null, readOnly: true },
		selectedRow: null,
		renderTable: true,
		renderSideBarIcon: true,
		versions: [],
		userBooks: [],
		reRenderBody: false,
		lastModified: null,
		fileSize: null,

		zoomScale: 1,
		fullScreen: false,
		renderFormulaBar: false,
		freezeRowUpTo: null,
		freezeColumnUpTo: null,
		showProtectedRanges: false,
		popUpList: [],

		loading: false,
		error: null,
		sheets: [],
		sheet: {},
		selectedSpreadsheet: {},
		openedMenu: null,
		spreadSheetScale: null,
		showGridlines: true,
		showStatusMenu: false,
		showPriorityMenu: false,
		showDueDateMenu: false,
		showPersonMenu: false,
		showVersionsMenu: false,
		isFullScreen: false,
		showTimelineMenu: false,
		rows: null,
		showAddNote: false,
		selectedFontSize: 12,
		selectedDueDate: null,
		sheetData: null,
		hoveredCell: { col: null, row: null },
		columnToRenameData: null,
		showColumnRenamePopUp: false,
		selectedTimelineStartDate: null,
		selectedTimelineEndDate: null,
		rightPanelLoader: false,
		showExamplePopUp: false,
	},
	reducers: {
		setCurrentSheet: (state, action) => {
			state.currentSheet = action.payload
		},
		setShowExamplePopUp: (state, action) => {
			state.showExamplePopUp = action.payload
		},
		setSheetData: (state, action) => {
			state.sheetData = action.payload
		},
		setSelectedTimelineEndDate: (state, action) => {
			state.selectedTimelineEndDate = action.payload
		},
		setSelectedTimelineStartDate: (state, action) => {
			state.selectedTimelineStartDate = action.payload
		},
		setShowColumnRenamePopUp: (state, action) => {
			state.showColumnRenamePopUp = action.payload
		},
		setColumnToRenameData: (state, action) => {
			state.columnToRenameData = action.payload
		},
		setHoveredCell: (state, action) => {
			state.hoveredCell = action.payload
		},
		setSelectedDueDate: (state, action) => {
			state.selectedDueDate = action.payload
		},
		setSelectedFontSize: (state, action) => {
			state.selectedFontSize = action.payload
		},
		setShowAddNote: (state, action) => {
			state.showAddNote = action.payload
		},
		setRows: (state, action) => {
			state.rows = action.payload
		},
		setShowTimelineMenu: (state, action) => {
			state.showTimelineMenu = action.payload
		},
		setIsFullScreen: (state, action) => {
			state.isFullScreen = action.payload
		},
		setShowDueDateMenu: (state, action) => {
			state.showDueDateMenu = action.payload
		},
		setShowPersonMenu: (state, action) => {
			state.showPersonMenu = action.payload
		},
		setShowVersionsMenu: (state, action) => {
			state.showVersionsMenu = action.payload
		},
		setShowPriorityMenu: (state, action) => {
			state.showPriorityMenu = action.payload
		},
		setShowStatusMenu: (state, action) => {
			state.showStatusMenu = action.payload
		},
		setShowGridlines: (state, action) => {
			state.showGridlines = action.payload
		},
		setSpreadSheetScale: (state, action) => {
			state.spreadSheetScale = action.payload
		},
		setOpenedMenu: (state, action) => {
			state.openedMenu = action.payload
		},
		setSelectedCell: (state, action) => {
			state.selectedCell = action.payload
		},
		setSelectedPremiumSheet: (state, action) => {
			state.selectedPremiumSheet = action.payload
		},
		setSelectedCells: (state, action) => {
			state.selectedCells = action.payload
		},
		setSelectedPremiumSheetData: (state, action) => {
			if (!action.payload) {
				state.selectedPremiumSheetData = action.payload
				return
			}
			const data = action.payload
			const sortedArrays = data.pages.sort((a, b) => a.id - b.id)
			const newData = { pages: sortedArrays }
			state.selectedPremiumSheetData = newData
		},
		addPageToSelectedPremiumSheetData: (state, action) => {
			const oldPages = state.selectedPremiumSheetData.pages
			const allPages = [...oldPages, action.payload]
			const finalPages = allPages.sort((a, b) => a.id - b.id)
			state.selectedPremiumSheetData = { pages: finalPages }
			state.selectedPremiumSheetPage = finalPages.length - 1
		},
		setSelectedPremiumSheetPage: (state, action) => {
			state.selectedPremiumSheetPage = action.payload
		},
	},

	extraReducers: (builder) => {
		builder
			.addCase(getScalewayInstance.pending, (state) => {
				state.loading = true
			})
			.addCase(getScalewayInstance.fulfilled, (state, action) => {
				state.loading = false
				state.scalewayInstance = action.payload
			})
			.addCase(getScalewayInstance.rejected, (state, action) => {
				state.loading = false
				state.error = action.error.message
			})

			.addCase(getAllInstanceDatabases.pending, (state) => {
				state.loading = true
			})
			.addCase(getAllInstanceDatabases.fulfilled, (state, action) => {
				state.loading = false
				state.instanceDatabases = action.payload.data.dbs
			})
			.addCase(getAllInstanceDatabases.rejected, (state, action) => {
				state.loading = false
				state.error = action.error.message
			})
			.addCase(getPremiumSheetData.pending, (state) => {
				state.loading = true
			})
			.addCase(getPremiumSheetData.fulfilled, (state, action) => {
				const data = action.payload
				const sortedArrays = data.pages.sort((a, b) => a.id - b.id)
				const newData = { pages: sortedArrays }
				state.loading = false
				state.selectedPremiumSheetData = newData
			})
			.addCase(getPremiumSheetData.rejected, (state, action) => {
				state.loading = false
				state.error = action.error.message
			})
			.addCase(getEditorSheetData.pending, (state) => {
				state.rightPanelLoader = true
			})
			.addCase(getEditorSheetData.fulfilled, (state, action) => {
				const data = action.payload
				const sortedArrays = data.pages.sort((a, b) => a.id - b.id)
				const newData = { pages: sortedArrays }
				state.rightPanelLoader = false
				state.selectedEditorSheetData = newData
			})
			.addCase(getEditorSheetData.rejected, (state, action) => {
				state.rightPanelLoader = false
				state.error = action.error.message
			})
			.addCase(deleteInstanceDatabase.pending, (state) => {
				state.loading = true
			})
			.addCase(deleteInstanceDatabase.fulfilled, (state, action) => {
				const sheetName = action.payload
				const oldData = [...state.instanceDatabases]
				state.instanceDatabases = oldData.filter(
					(database) => database.name !== sheetName
				)
			})
			.addCase(deleteInstanceDatabase.rejected, (state, action) => {
				state.loading = false
				state.error = action.error.message
			})
	},
})

export const {
	setSelectedColumn,
	setSelectedPremiumSheet,
	setSelectedPremiumSheetData,
	setSelectedPremiumSheetPage,
	addPageToSelectedPremiumSheetData,
	setSelectedCell,
	setOpenedMenu,
	setShowPriorityMenu,
	setShowDueDateMenu,
	setShowPersonMenu,
	setShowVersionsMenu,
	setShowStatusMenu,
	setSpreadSheetScale,
	setShowAddNote,
	setSelectedFontSize,
	setShowGridlines,
	setRows,
	setSheetData,
	setSelectedDueDate,
	setColumnToRenameData,
	setCurrentSheet,
	setShowColumnRenamePopUp,
	setHoveredCell,
	setSelectedTimelineEndDate,
	setShowTimelineMenu,
	setIsFullScreen,
	setSelectedTimelineStartDate,
	setSelectedCells,
	setShowExamplePopUp,
} = datatableSlices.actions

export default datatableSlices.reducer
