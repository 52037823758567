
import React from 'react'


const Icon = () => {
    return (
        <svg width="147" height="147" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M105.691 117.32H40.3088C36.8246 117.32 34 114.496 34 111.011V35.3086C34 31.8244 36.8246 29 40.3088 29H82.75L112 58.2488V111.011C112 114.496 109.175 117.32 105.691 117.32Z" fill="url(#paint0_linear_633_2916)" />
            <g opacity="0.51" filter="url(#filter0_f_633_2916)">
                <rect x="47.7648" y="71.7266" width="51.0441" height="24.0873" fill="var(--color-primary-3)" />
            </g>
            <mask id="mask0_633_2916" maskUnits="userSpaceOnUse" x="34" y="29" width="78" height="89">
                <path d="M105.691 117.32H40.3088C36.8246 117.32 34 114.496 34 111.011V35.3086C34 31.8244 36.8246 29 40.3088 29H82.75L112 58.2488V111.011C112 114.496 109.175 117.32 105.691 117.32Z" fill="url(#paint1_linear_633_2916)" />
            </mask>
            <g mask="url(#mask0_633_2916)">
                <path d="M73.0873 104.159C72.2652 104.159 71.4768 103.832 70.8954 103.251C70.3141 102.67 69.9874 101.881 69.9874 101.059V96.2939C69.9874 95.8871 70.0677 95.4844 70.2235 95.1087C70.3794 94.733 70.6079 94.3917 70.8959 94.1044C71.1838 93.8171 71.5256 93.5896 71.9016 93.4346C72.2777 93.2796 72.6806 93.2002 73.0873 93.2012C73.9076 93.2012 74.6942 93.527 75.2741 94.107C75.8541 94.687 76.18 95.4736 76.18 96.2939V101.059C76.18 101.88 75.8544 102.667 75.2746 103.248C74.6948 103.83 73.9082 104.157 73.0873 104.159V104.159Z" fill="#E984ED" />
                <path d="M73.0873 65.7435C72.2652 65.7435 71.4768 65.4168 70.8954 64.8355C70.3141 64.2542 69.9874 63.4657 69.9874 62.6436V57.0999C69.9874 56.2778 70.3141 55.4892 70.8954 54.9079C71.4768 54.3266 72.2652 54 73.0873 54C73.9082 54.0019 74.6948 54.3293 75.2746 54.9105C75.8544 55.4916 76.18 56.279 76.18 57.0999V62.6436C76.18 63.4645 75.8544 64.2518 75.2746 64.8329C74.6948 65.4141 73.9082 65.7416 73.0873 65.7435V65.7435Z" fill="#E984ED" />
                <path d="M84.081 101.218C83.5362 101.218 83.0007 101.076 82.5286 100.803C82.0565 100.531 81.6645 100.14 81.3921 99.6677L79.0852 95.6595C78.6751 94.9492 78.564 94.1052 78.7763 93.3129C78.9885 92.5207 79.5068 91.8452 80.217 91.4351C80.569 91.2309 80.9579 91.0981 81.3613 91.0445C81.7647 90.9909 82.1747 91.0174 82.5678 91.1226C82.9609 91.2277 83.3294 91.4095 83.6521 91.6573C83.9749 91.9051 84.2456 92.2142 84.4487 92.5669L86.7555 96.5679C87.166 97.2793 87.2773 98.1244 87.0651 98.9179C86.8529 99.7113 86.3345 100.388 85.6238 100.8C85.1548 101.071 84.6229 101.215 84.081 101.218Z" fill="#E984ED" />
                <path d="M64.9411 68.0569C64.3962 68.0577 63.8607 67.9148 63.3886 67.6427C62.9166 67.3706 62.5246 66.9789 62.2521 66.507L59.4118 61.5977C59.002 60.8854 58.8917 60.0396 59.1053 59.2461C59.3189 58.4525 59.8388 57.7763 60.5508 57.366C61.2622 56.9556 62.1074 56.8442 62.9008 57.0564C63.6942 57.2686 64.371 57.787 64.7825 58.4978L67.6157 63.4143C68.0261 64.1257 68.1374 64.9709 67.9252 65.7643C67.713 66.5577 67.1945 67.2345 66.4838 67.6461C66.0144 67.9157 65.4824 68.0573 64.9411 68.0569V68.0569Z" fill="#E984ED" />
                <path d="M92.1191 93.1649C91.5778 93.1653 91.0458 93.0237 90.5764 92.754L86.5682 90.44C85.8574 90.0285 85.3391 89.3517 85.1269 88.5583C84.9147 87.7649 85.026 86.9197 85.4364 86.2083C85.8467 85.4963 86.5229 84.9764 87.3164 84.7628C88.1099 84.5492 88.9558 84.6594 89.6681 85.0692L93.6763 87.3833C94.3871 87.7948 94.9054 88.4716 95.1176 89.265C95.3298 90.0584 95.2185 90.9037 94.8081 91.615C94.5356 92.0869 94.1436 92.4786 93.6716 92.7507C93.1995 93.0228 92.664 93.1657 92.1191 93.1649V93.1649Z" fill="#E984ED" />
                <path d="M58.9578 74.0264C58.4157 74.0249 57.8836 73.8807 57.415 73.6082L52.4985 70.7751C51.7877 70.3636 51.2694 69.6868 51.0572 68.8934C50.845 68.1 50.9563 67.2548 51.3667 66.5434C51.5698 66.1908 51.8405 65.8817 52.1633 65.6338C52.486 65.386 52.8545 65.2043 53.2476 65.0992C53.6407 64.994 54.0507 64.9675 54.4541 65.0211C54.8575 65.0747 55.2463 65.2074 55.5984 65.4116L60.5149 68.2447C61.2242 68.6576 61.7413 69.3343 61.9534 70.1271C62.1655 70.92 62.0552 71.7646 61.6467 72.4764C61.3743 72.9483 60.9823 73.34 60.5102 73.6121C60.0381 73.8843 59.5027 74.0271 58.9578 74.0264V74.0264Z" fill="#E984ED" />
                <path d="M95.0748 82.1808H90.3024C89.4803 82.1808 88.6918 81.8542 88.1105 81.2729C87.5292 80.6915 87.2025 79.9031 87.2025 79.081C87.2044 78.2601 87.5319 77.4734 88.1131 76.8936C88.6942 76.3139 89.4815 75.9883 90.3024 75.9883H95.0748C95.8957 75.9883 96.683 76.3139 97.2641 76.8936C97.8453 77.4734 98.1728 78.2601 98.1747 79.081C98.1747 79.9031 97.848 80.6915 97.2667 81.2729C96.6854 81.8542 95.8969 82.1808 95.0748 82.1808V82.1808Z" fill="#E984ED" />
                <path d="M56.6365 82.1808H51.0999C50.2778 82.1808 49.4893 81.8542 48.908 81.2729C48.3267 80.6916 48 79.9031 48 79.081C48.0019 78.2601 48.3294 77.4734 48.9105 76.8936C49.4917 76.3139 50.279 75.9883 51.0999 75.9883H56.6292C57.036 75.9873 57.4389 76.0667 57.815 76.2217C58.191 76.3767 58.5328 76.6043 58.8207 76.8915C59.1087 77.1788 59.3371 77.5201 59.493 77.8958C59.6488 78.2715 59.7291 78.6742 59.7291 79.081C59.73 79.4877 59.6508 79.8906 59.4958 80.2667C59.3408 80.6427 59.1131 80.9845 58.8258 81.2725C58.5385 81.5604 58.1972 81.7888 57.8216 81.9447C57.4459 82.1006 57.0432 82.1808 56.6365 82.1808Z" fill="#E984ED" />
                <path d="M87.7792 73.694C87.2352 73.6948 86.7005 73.5527 86.2286 73.282C85.7567 73.0112 85.3642 72.6214 85.0903 72.1514C84.6805 71.4391 84.5702 70.5932 84.7838 69.7997C84.9974 69.0062 85.5173 68.3299 86.2293 67.9196L90.5547 65.4109C91.2661 65.0005 92.1112 64.8892 92.9046 65.1014C93.698 65.3136 94.3748 65.832 94.7864 66.5427C95.1968 67.2541 95.3081 68.0993 95.0959 68.8927C94.8837 69.6861 94.3653 70.3629 93.6546 70.7744L89.3292 73.2831C88.8579 73.5546 88.3231 73.6964 87.7792 73.694V73.694Z" fill="#E984ED" />
                <path d="M54.0484 93.1656C53.5044 93.1666 52.9699 93.0238 52.499 92.7516C52.028 92.4794 51.6374 92.0876 51.3667 91.6158C50.9563 90.9044 50.845 90.0591 51.0572 89.2657C51.2694 88.4723 51.7877 87.7955 52.4985 87.384L57.0835 84.7384C57.7953 84.3299 58.6398 84.2196 59.4326 84.4317C60.2255 84.6437 60.9023 85.1608 61.3151 85.8701C61.7249 86.5824 61.8351 87.4283 61.6216 88.2218C61.408 89.0153 60.888 89.6915 60.1761 90.1018L55.5984 92.7476C55.1286 93.0235 54.5932 93.1679 54.0484 93.1656V93.1656Z" fill="#E984ED" />
                <path d="M73.4478 99.4022H72.9504C69.4073 99.3193 65.9515 98.286 62.9443 96.4104C59.9725 94.5679 57.5364 91.9778 55.8795 88.8987C54.3308 85.9993 53.5187 82.7636 53.5149 79.4765C53.5149 79.3107 53.5149 79.1449 53.5149 78.9791C53.6002 75.5915 54.548 72.2815 56.2688 69.3622C57.9873 66.4469 60.4182 64.016 63.3335 62.2975C66.265 60.5754 69.5875 59.63 72.9864 59.5508H73.4406C76.7317 59.5473 79.9721 60.3624 82.8699 61.9226C85.9466 63.5738 88.5347 66.0053 90.3745 68.973C92.2524 71.9946 93.2832 75.4655 93.359 79.0223C93.359 79.1449 93.359 79.3107 93.359 79.4765C93.3597 82.9181 92.4681 86.301 90.771 89.2951C88.997 92.4376 86.4 95.0371 83.2592 96.8142C80.267 98.5094 76.8869 99.401 73.4478 99.4022V99.4022ZM73.3685 65.7362H73.1738C70.8187 65.7873 68.5157 66.44 66.4839 67.6321C64.4748 68.8169 62.7984 70.4909 61.6106 72.4982C60.4346 74.5166 59.7944 76.8021 59.7507 79.1377C59.7507 79.2674 59.7507 79.3611 59.7507 79.4548C59.7492 81.7189 60.309 83.9481 61.3799 85.9429C62.5207 88.0708 64.2014 89.861 66.2532 91.1335C68.3349 92.4249 70.7247 93.1343 73.1738 93.188H73.4911C75.8636 93.1908 78.1961 92.5772 80.2603 91.4074C82.4278 90.1807 84.2199 88.3861 85.4436 86.2169C86.6122 84.1549 87.2257 81.8249 87.2242 79.4548V79.181C87.1708 76.7173 86.4589 74.3127 85.1624 72.2171C83.8941 70.1707 82.1087 68.4948 79.9863 67.3582C77.9576 66.2749 75.6899 65.7172 73.3902 65.7362H73.3685Z" fill="#E984ED" />
                <path d="M81.421 67.7333C80.8794 67.7354 80.347 67.5935 79.8783 67.3223C79.5256 67.1192 79.2165 66.8486 78.9687 66.5258C78.7209 66.203 78.5391 65.8345 78.434 65.4414C78.3288 65.0483 78.3023 64.6383 78.3559 64.2349C78.4095 63.8315 78.5422 63.4426 78.7464 63.0906L81.3921 58.4985C81.5952 58.1459 81.8659 57.8368 82.1886 57.5889C82.5114 57.3411 82.8799 57.1594 83.2731 57.0542C83.6662 56.9491 84.0761 56.9226 84.4795 56.9762C84.8829 57.0298 85.2718 57.1625 85.6238 57.3667C85.9764 57.5698 86.2856 57.8404 86.5334 58.1632C86.7812 58.486 86.9628 58.8545 87.068 59.2476C87.1731 59.6408 87.1997 60.0507 87.1461 60.4541C87.0925 60.8575 86.9598 61.2464 86.7556 61.5984L84.1099 66.1833C83.8375 66.6552 83.4455 67.0469 82.9734 67.319C82.5013 67.5912 81.9659 67.734 81.421 67.7333V67.7333Z" fill="#E984ED" />
                <path d="M62.0935 101.219C61.5514 101.219 61.019 101.074 60.5508 100.801C59.8388 100.391 59.3189 99.7144 59.1053 98.9209C58.8917 98.1273 59.002 97.2816 59.4118 96.5693L61.9205 92.2439C62.3321 91.5332 63.0088 91.0147 63.8022 90.8025C64.5957 90.5903 65.4408 90.7016 66.1522 91.112C66.863 91.5236 67.3814 92.2004 67.5936 92.9938C67.8058 93.7872 67.6944 94.6323 67.284 95.3437L64.7825 99.6691C64.51 100.141 64.118 100.533 63.646 100.805C63.1739 101.077 62.6384 101.22 62.0935 101.219V101.219Z" fill="#E984ED" />
                <ellipse opacity="0.05" cx="29.8419" cy="23.1219" rx="64.0919" ry="61.5086" fill="url(#paint2_linear_633_2916)" />
                <ellipse opacity="0.05" cx="29.842" cy="23.122" rx="40.2904" ry="38.5683" fill="url(#paint3_linear_633_2916)" />
            </g>
            <path d="M58.4205 90H53.2642V75.4545H58.4631C59.9261 75.4545 61.1856 75.7457 62.2415 76.3281C63.2973 76.9058 64.1094 77.7367 64.6776 78.821C65.2505 79.9053 65.5369 81.2027 65.5369 82.7131C65.5369 84.2282 65.2505 85.5303 64.6776 86.6193C64.1094 87.7083 63.2926 88.544 62.2273 89.1264C61.1667 89.7088 59.8977 90 58.4205 90ZM56.3395 87.3651H58.2926C59.2017 87.3651 59.9664 87.2041 60.5866 86.8821C61.2116 86.5554 61.6804 86.0511 61.9929 85.3693C62.3101 84.6828 62.4688 83.7973 62.4688 82.7131C62.4688 81.6383 62.3101 80.7599 61.9929 80.0781C61.6804 79.3963 61.214 78.8944 60.5938 78.5724C59.9735 78.2505 59.2088 78.0895 58.2997 78.0895H56.3395V87.3651ZM69.215 90V75.4545H72.2903V87.4645H78.5261V90H69.215ZM81.9626 90V75.4545H85.0379V87.4645H91.2737V90H81.9626Z" fill="white" />
            <g filter="url(#filter1_d_633_2916)">
                <path d="M112 58.2488H104.75C92.5997 58.2488 82.75 48.3991 82.75 36.2488V29L112 58.2488Z" fill="url(#paint4_linear_633_2916)" />
            </g>
            <defs>
                <filter id="filter0_f_633_2916" x="8.76477" y="32.7266" width="129.044" height="102.088" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="19.5" result="effect1_foregroundBlur_633_2916" />
                </filter>
                <filter id="filter1_d_633_2916" x="59.75" y="5" width="79.25" height="79.248" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="2" dy="1" />
                    <feGaussianBlur stdDeviation="12.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_633_2916" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_633_2916" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_633_2916" x1="73" y1="29" x2="73" y2="117.32" gradientUnits="userSpaceOnUse">
                    <stop stopColor="var(--color-primary-3)" />
                    <stop offset="1" stopColor="var(--color-primary-3)" />
                </linearGradient>
                <linearGradient id="paint1_linear_633_2916" x1="73" y1="29" x2="73" y2="117.32" gradientUnits="userSpaceOnUse">
                    <stop stopColor="var(--color-primary-3)" />
                    <stop offset="1" stopColor="var(--color-primary-3)" />
                </linearGradient>
                <linearGradient id="paint2_linear_633_2916" x1="34.4954" y1="25.2355" x2="53.9308" y2="76.8191" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient id="paint3_linear_633_2916" x1="32.7673" y1="24.4474" x2="44.9308" y2="56.8128" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient id="paint4_linear_633_2916" x1="89.2022" y1="19.8239" x2="97.3744" y2="58.249" gradientUnits="userSpaceOnUse">
                    <stop stopColor="var(--color-primary-3)" />
                    <stop offset="1" stopColor="var(--color-primary-3)" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export default Icon