const support = {
  t1: `Stöd för Aythen Lite`,
  t2: `Guider och hjälp`,
  t33: `Öppna biljetter`,
  t3: `Service Level Agreement (SLA) är att fastställa tillgängligheten för programvaruplattformen`,
  t4: `Kontakta oss`,
  t5: `Skapa en supportbiljett`,
  t6: `Få direkt hjälp från vårt team.`,
  t7: `Hantera incidenter`,
  t8: `Kontrollera din licens samt dina öppna och stängda biljetter.`,
  t9: `Vill du veta mer?`,
  t10: `Känner du till Aythen PRO?`,
  t11: `Gå in här för att förstå det och registrera dig på vår väntelista`,
  t12: `Övervakning och rapportering`,
  t13: `Skicka oss dina rekommendationer för att veta med vad mer du vill arbeta med dina data.`,
  topics: {
    1: {
      title: "Cookies och Integritet",
      data: {
        1: {
          title: `Är mina data säkra?`,
          text: `Vi arbetar med ISO 27001-certifiering. Det är en internationellt erkänd standard för informationshantering. Denna certifiering innebär att ett system för informationshantering (SGSI) har implementerats som uppfyller de nödvändiga kontrollerna och processerna för att skydda data på ett effektivt sätt.`,
        },
        2: {
          title: `Användarupplevelse`,
          text: `Genom att använda en molntjänstleverantör med certifieringar som ISO 27001 och som opererar i Europeiska unionen, ger vi större förtroende för säkerheten och skyddet av personuppgifter som hanteras. Detta hjälper till att uppfylla säkerhetskraven i GDPR och att minimera riskerna förknippade med behandlingen av personuppgifter.`,
        },
      },
    },
    2: {
      title: `Vektorbaserade databaser`,
      data: {
        1: {
          title: `Vad är de?`,
          text: `De representerar en betydande innovation inom datalagring och databehandling. De används i en mängd olika applikationer, 
                    inklusive data science, maskininlärning och artificiell intelligens. <br /><br />
                    I motsats till traditionella databaser, som lagrar information i form av tabeller med rader och kolumner, 
                    använder vektorbaserade databaser vektorstrukturer för att lagra och manipulera information på ett mer effektivt sätt. 
                    Organiserar sedan data i multidimensionella vektorer. Varje element i vektorn representerar en informationsenhet, och operationerna utförs 
                    på ett effektivt sätt genom att skapa flöden mellan informationsuppsättningar i stället för individuella rader och kolumner.`,
        },
        2: {
          title: `Vad används de för?`,
          text: `Denna struktur möjliggör snabb åtkomst till stora datamängder och underlättar komplexa matematiska och logiska operationer på samma gång. 
                    Det eliminerar datalagringens latens utan att behöva bearbeta all information, bara den som är av intresse, vilken kan segmenteras och organiseras i kluster 
                    mellan dimensioner och agenter. 
                    <br /><br />
                    - Varje dimension representerar en koordinat i ett multidimensionellt utrymme där data är organiserade. Det betyder att en vektorbaserad databas 
                    kan ha flera dimensioner, vilket möjliggör en mer fullständig och detaljerad representation av informationen.
                    <br /><br />
                    - Agenterna är enheter som utför operationer på vektorbaserade data. Dessa agenter kan vara program, processer eller frågor som interagerar med 
                    databasen för att hämta, bearbeta eller manipulera information. De kan komma åt olika dimensioner av data och utföra komplexa operationer beroende på 
                    kraven för att representera grafiskt eller svara på frågan.`,
        },
      },
    },
    3: {
      title: `Serverless Back-office`,
      data: {
        1: {
          title: `Hur gynnar det mig?`,
          text: `I en serverless miljö behöver du inte hantera fysiska eller virtuella servrar. Infrastrukturen hanteras så att applikationen kan skalas automatiskt 
                    enligt efterfrågan. 
                    <br /><br />
                    Det betyder att det inte finns något behov av att förutse trafiktoppar eller justera serverkapaciteten manuellt. Plattformen skalas automatiskt 
                    de resurser som behövs för att hantera arbetsbelastningen.`,
        },
        2: {
          title: `Operativ flexibilitet`,
          text: `Därför betalar du bara för de resurser som används, vilket kan spara pengar jämfört med traditionella servrar där du betalar för reserverad kapacitet, 
                    oavsett om den används eller inte.`,
        },
      },
    },
    4: {
      title: `On-demand-tjänster`,
      data: {
        1: {
          title: `Kan jag anpassa det till mitt företag?`,
          text: `Du kan skapa dina behov baserat på din egen företagsstruktur.`,
        },
        2: {
          title: `Företagslogotyp`,
          text: `Det är grundläggande att kunna lägga till din företagslogotyp i registreringen för att upprätthålla varumärkeskonsekvens i alla interaktioner med kunder, 
                    leverantörer och andra aktörer.`,
        },
        3: {
          title: `Användare och roller`,
          text: `Du kan lägga till och hantera olika typer av användare och roller i IAM, om de är interna arbetare eller externa kunder, leverantörer och andra 
                    samarbetspartners i våra dynamiska tabeller. Så varje typ av användare kan ha olika åtkomstnivåer och behörigheter för att säkerställa datasäkerhet och 
                    integritet.`,
        },
        4: {
          title: `Företagsfärger`,
          text: `Det skulle vara viktigt att anpassa CRM:s utseende med företagets färger för att stärka varumärkesidentiteten. 
                    Detta skulle inkludera möjligheten att välja de dominerande färgerna i CRM:s gränssnitt, som bakgrunder, knappar och navigeringsfält.`,
        },
        5: {
          title: `Länkning av bankkonton`,
          text: `För en effektiv finansiell hantering kan du länka serverless Aythen-kort till IAM (åtkomst och roller) så att varje företagsanställd kan följa transaktioner, 
                    betalningar och andra finansiella operationer direkt från plattformen, vilket förenklar hanteringsprocessen.`,
        },
      },
    },
    5: {
      title: `Måttanpassade interaktioner`,
      data: {
        1: {
          title: `Djupgående analys`,
          text: `Vektorrepresentationen möjliggör en djupare analys av data.`,
        },
        2: {
          title: `Strategiska insikter`,
          text: `Vi erbjuder värdefulla insikter för strategiska beslut genom vektorbaserade dimensioner.`,
        },
      },
    },
    6: {
      title: `Essentiella verktyg`,
      data: {
        1: {
          title: `Egna dokument`,
          text: `Du kan ladda upp nya textdokument, format och samarbete i realtid. Stöder alla typer av filformat, inklusive textdokument, kalkylblad, 
                    presentationer, formulär, ritningar och mer.`,
        },
        2: {
          title: `Lagring`,
          text: `Dokumenten kan uppta utrymme i din konto. Därför kan du frysa dem för att ha åtkomst till dem när du vill eller ta bort dem om de inte är av intresse.`,
        },
        2: {
          title: `Dela och samarbeta`,
          text: `Du kan dela dina dokument med andra användare och arbeta tillsammans i realtid. Samarbetarna kan se ändringar i dokumentet när de görs och 
                    lägga till kommentarer för att diskutera idéer.`,
        },
        3: {
          title: `Elektronisk faktura`,
          text: `Spara tid och resurser. Automatisera processen för att skapa, skicka och ta emot fakturor, vilket minskar administrativt arbete och kostnader 
                    förknippade med papper. Minskar mänskliga fel och förbättrar precisionen i registreringen och spårningen av finansiella transaktioner. Allt som behövs för att uppfylla 
                    lagstiftningens krav för alla enskilda näringsidkare och företag för skatte- och lagstiftning.`,
        },
        3: {
          title: `Kontrakt`,
          text: `Centralisering av information relaterad till kunder och kommersiella avtal, vilket underlättar hanteringen och spårningen av kontrakt under hela deras livscykel.`,
        },
        4: {
          title: `Dina favoritdiagram`,
          text: `Skapa dina anpassade instrumentpaneler baserat på dina interaktioner för att visualisera nyckeldata och viktiga mått på ett klart och koncist sätt, 
                    vilket underlättar identifieringen av trender, mönster och möjligheter för att fatta informerade beslut. `,
        },
        5: {
          title: `Dynamiska tabeller`,
          text: `Dynamiska tabeller erbjuder en flexibel metod för att summera dina data baserat på dina projekt. Räkna upp personernas kort, status, datum, data från 
                    olika perspektiv och detaljnivåer. Du kan filtrera, gruppera och summera data interaktivt för att få detaljerad information och fatta informerade beslut.`,
        },
      },
    },
  },
}

export default support