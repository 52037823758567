const settings = {
    t1: "Дом",
    t2: "Aythen DB",
    t3: "Канбан",
    t4: "Мои документы",
    t5: "Контракты",
    t6: "Фактурирование",
    info: {
        t0: `Добавить тег`,
        t1: `Ключ`,
        t2: `Значение`,
        info: {
          t0: `исключая налоги с скидкой`,
          t1: `Обновлено 27 минут назад`
        }
    },
    invoice: {
        send: {
            "t0": "Счет",
            "t1": "Копировать счет",
            "t2": "Изменить организацию, если она не соответствует",
            "t3": "Не хватает некоторых данных для заполнения",
            "t4": "Номер фискальной идентификации",
            "t5": "ДНИ (официальные документы)",
            "t6": "Выберите способ оплаты, который вы хотите выполнить",
            "t7": "Больше информации",
            "t8": "Оплата",
            "t9": "Сколько денег вы хотите получить",
            "t10": "Контакты",
            "t11": "Выберите контакт",
            "t12": "Организация",
            "t13": "Мы отправим процесс на ваш счет",
            "t14": "Неповрежденный режим",
            "t15": "Я хочу получить деньги как можно скорее",
            "t16": "1",
            "t17": "Программировать уведомление о отправке",
            "t18": "2",
            "t19": "Напомнить завтра, если не оплачено",
            "t20": "3",
            "t21": "Напомнить каждый день в течение 14 дней",
            "t22": "Банковский режим",
            "t23": "Быстрейшая факторинг для ваших клиентов",
            "t24": "1",
            "t25": "Немедленная и безопасная оплата",
            "t26": "2",
            "t27": "Банк предоплачивает деньги",
            "t28": "3",
            "t29": "Лучшие условия оплаты",
            "t30": "Программированный режим",
            "t31": "Скажите день для запроса оплаты",
            "t32": "1",
            "t33": "Программировать отправку дня",
            "t34": "2",
            "t35": "Уведомлять в течение 14 дней",
            "t36": "3",
            "t37": "Лучшие условия оплаты",
            "t38": "Отправить счет",
            "t39": "Успешная оплата",
            "t40": "Сделать перевод",
            "t41": "Вы получите",
            "t42": "2.000$",
            "t43": "Текущая кредитная карта",
            "t44": "**** 4543",
            "t45": "Выбранный процесс",
            "t46": "Немедленная оплата",
            "t47": "Оплатить счет",
            "t48": "Отменить"
        }
    },
    status: {
        "t0": "Aythen",
        "t1": "статус",
        "t2": "Поддержка контакта",
        "t3": "Состояние в реальном времени",
        "t4": "История",
        "t5": "ПОДПИСАТЬСЯ",
        "t6": "Aythen API",
        "t7": "99,999% SLA время активности",
        "t8": "15 янв",
        "t9": "100% uptime",
        "t10": "1 янв",
        "t11": "Сегодня",
        "t12": "Легенда:",
        "t13": "Успех",
        "t14": "В ожидании",
        "t15": "Ошибка",
        "t16": "Состояние системы",
        "t17": "Плановое обслуживание для Bancontact",
        "t18": "Aythen",
        "t19": "© 2024 Aythen, Inc",
        "t20": "Посетите наш сайт",
        "t21": "Условия и приватность",
        "t22": "Электронная почта",
        "t23": "Текстовое сообщение",
        "t24": "Получать электронные уведомления, когда Stripe создает, обновляет или решает инцидент.",
        "t25": "Адрес электронной почты",
        "t26": "Подписаться по электронной почте",
        "t27": "Чтобы обновить существующие предпочтения электронной почты, подпишитесь снова.",
        "t28": "Для получения дополнительной информации о подписке, посетите наши инструкции в Поддержке.",
        "t29": "Сайт защищен reCAPTCHA и применяются Политика приватности Google и Условия обслуживания.",
        "t30": "Получать текстовые уведомления, когда Stripe создает или решает инцидент.",
        "t31": "Код страны",
        "t32": "Номер телефона",
        "t33": "Подписаться по текстовому сообщению",
        "t34": "Чтобы обновить существующие предпочтения, подпишитесь снова.",
        "t35": "Для получения дополнительной информации о подписке, посетите наши инструкции в Поддержке.",
        "t36": "Стоимость сообщений и данных может применяться. Подписываясь, вы соглашаетесь с Условиями обслуживания Atlassian и Политикой приватности Atlassian. Сайт защищен reCAPTCHA и применяются Политика приватности Google и Условия обслуживания."
    },
    billing: {
        t1: `Заполните данные…`,
        t2: `Валюта`,
        t3: `Редактировать`,
        t4: `Здесь отображаются ваши данные регистрации и фактурирования. Вы можете изменить или
модифицировать их, чтобы проверить ваш доступ к различным функциям, которые предлагает Aythen Lite.`,
        t5: `Изменить данные`,
        t6: `Потребление данных`,
        t7: `Электронная почта для контакта`,
        t8: `В виде сбора, каждый месяц вы будет получать электронный счет на ваш электронный адрес регистрации.`,
        t9: `Электронная почта для фактурирования`,
        t10: `Сохранить`,
        t12: `Токен GPT`,
        t13: `Вы можете создать токен GPT для уникальной идентификации и аутентификации ваших запросов API.`,
        t14: `Токен`,
        t15: `Сохранить`,
        t16: `Лимиты потребления`,
        t17: `В вашей лицензии может потребоваться больше интеракций или ГБ хранения. 
Для этих дополнительных использований вы можете настроить уведомления по общему количеству.`,
        t177: `Узнать больше`,
        t18: `Добавить уведомление с `,
        t19: `Ежемесячные затраты`,
        t20: `Сохранить уведомление`,
        t21: `Идентификация НДС/НДС`,
        t22: `Войти НДС`,
        t23: `Сохранить`,
        t24: `Методы оплаты`,
        t25: `Выберите из следующих методов оплаты для вашей учетной записи.`,
        t26: `Как вы хотите управлять вашей лицензией:`,
        t266: `Кредитная карта`,
        t267: `SEPA Direct Debit`,
        t268: `отсутствует таблица`,
        t27: `Типы уведомлений`,
        t28: `Выберите и настройте ваши предпочтения уведомлений. Когда будут отправлены уведомления?`,
        t29: `Типы уведомлений`,
        t30: `Электронные уведомления`,
        t31: `Ежедневные напоминания`,
        t32: `Специальные уведомления событий:`,
        t33: `Приватные уведомления:`,
        t34: `Счета получена`,
        t340: `Aythen Lite Premium`,
        t341: `Обновить до Premium`,
        t342: `Lorem ipsum dolor et epsum holend.`,
        t343: `Lorem ipsum dolor et epsum holend et erem super tupper dolem herem
    superssupostom hellom super start.`,
        t344: `Lorem ipsum dolor et teresum`,
        t345: `Lorem ipsum dolor et teresum`,
        t346: `Бесплатно`,
        t347: `Навсегда`,
        t350: `Добавить`,
        t35: `Здесь вы найдете ваш историю между полученными и отправленными.`,
        t36: `Счета отправлена`,
        t360: `Счета получена`,
        t361: `от`,
        t37: `Месяц`,
        t38: `Год`,
        t39: `Номер`,
        t40: `Метод оплаты`,
        t41: `Статус`,
        t42: `Общий (НДС вкл.)`,
        t43: `Скачать`,
        t44: `Удалить учетную запись`,
        t45: `Вы уверены, что хотите удалить ваш учетную запись? Учтите, что эта действие не может быть отменено, и ваш учетная запись будет деактивирована в течение 48 часов, если вы подтвердите.`,
        t46: `Удалить`,

        x1: `Редактировать имя учетной записи`,
        x2: `Это корпоративная учетная запись`,
        x3: `Имя компании`,
        x4: `Улица`,
        x5: `Улица 2`,
        x6: `Почтовый код`,
        x7: `Город`,
        x8: `Страна`,
        x9: `Регион`,
        x10: `Подтвердите изменения`
    },
    contracts: {
        t1: `Организация`,
        t2: `Договоры учетные контракты`,
        t3: `Имя`,
        t4: `Статус`,
        t5: `Оконец`,
        t6: `Скачать`,
        t7: `Организуйте здесь свои корпоративные договоры.`,
        t8: `Что преимущества?`,
        t0: 'Добавить договор',
        popup: {
            t0: `Открыто в`,
            t1: `Статус`,
            t2: `Отправить с уведомлением`,
            t3: `Удалить уведомление`,
            t4: `Отправить один раз`,
            t5: `Приоритетный отправки`,
            t6: `Маркировать подписать`,
            t7: `Для подписать`,
            t8: `Для удалить, вам должны добавить Удалить`,
            t9: `Подписать`,
            t10: `Отменить`,
            t11: `Удалить`
        }
    }
}

export default settings