import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import Cookies from './Cookies'

import styles from '../index.module.css'

const Copyright = () => {
	const navigate = useNavigate()
	const { lng } = useParams()
	const { t } = useTranslation()

	const [isActive, setIsActive] = useState(false)

	const handleContact = () => {
		navigate(`/${lng}/contact`)
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	const handlePrivacy = () => {
		navigate(`/${lng}/privacy`)
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	return (
		<>
			<div
				className={`${styles['copyright-area']} ${styles['copyright-style-one']}`}
			>
				<div className={styles['container']}>
					<div className={`${styles['row']} ${styles['align-items-center']}`}>
						<div
							className={`${styles['col-lg-6']} ${styles['col-md-8']} ${styles['col-sm-12']} ${styles['col-12']}`}
						>
							<div className={styles['copyright-left']}>
								<ul className={`${styles['ft-menu']} ${styles['link-hover']}`}>
									<li>
										<button onClick={() => handlePrivacy()}>
											{t('home1.footer.text5')}
										</button>
									</li>
									<li>
										<button onClick={() => setIsActive(true)}>
											{t('home1.footer.text6')}
										</button>
									</li>
									<li>
										<button onClick={() => handleContact()}>
											{t('home1.footer.text7')}
										</button>
									</li>
								</ul>
							</div>
						</div>
						<div
							className={`${styles['col-lg-6']} ${styles['col-md-4']} ${styles['col-sm-12']} ${styles['col-12']}`}
						>
							<div
								className={`${styles['copyright-right']} ${styles['text-center']} ${styles['text-lg-end']}`}
							>
								<p className={styles['copyright-text']}>
									Copyright © 2024{' '}
									<button
										className={styles['btn-read-more']}
										onClick={() => {
											navigate(`/${lng}`)
										}}
									>
										<span>Aythen.</span>
									</button>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Cookies isActive={isActive} setIsActive={setIsActive} />
		</>
	)
}

export default Copyright
