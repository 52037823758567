
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Sal from "sal.js";

import TeamImg from "@home/assets/images/cta-img/team-01.png";
import ShapeImg from "@home/assets/images/cta-img/bg-shape-01.png";

import styles from '../index.module.css'


import Form from '../Form'


const CtaOne = () => {
  const { t } = useTranslation()

  useEffect(() => {
    Sal();
  }, []);




  const [isShowModal, setIsShowModal] = useState(false)


  const handleOpen = () => {
    setIsShowModal(true)

  }

  useEffect(() => {
    const form = localStorage.getItem("form")
    if (!form) {
      localStorage.setItem("form", true)
      setIsShowModal(true)
    }
  }, [])


  return (
    <>
      <div className={`${styles.row} ${styles['row--0']} ${styles['align-items-center']} ${styles['content-wrapper']}`} >
     
      {isShowModal && (
        <Form isShowModal={isShowModal} setIsShowModal={setIsShowModal} />
      )}
        <div className={`${styles.className} ${styles["col-lg-8"]}`} >
          <div className={styles.inner} >
            <div className={`${styles.content} ${styles["text-left"]}`} >
              <h2
                className={`${styles.title} ${styles["sal-animate"]}`} 
                data-sal="slide-up"
                data-sal-duration="400"
                data-sal-delay="200"
              >
                {t("home1.web.component44.text1")}
              </h2>
              <p
                className={styles["sal-animate"]} 
                data-sal="slide-up"
                data-sal-duration="400"
                data-sal-delay="300"
              >
                
                {t("home1.web.component44.text2")}
              </p>
            </div>
          </div>
        </div>
        <div className={styles["col-lg-4"]} >
          <div className={`${styles["right-content"]}`} >
            <div
              className={`${styles["call-to-btn"]} ${styles["text-start"]} ${styles["text-lg-end"]} ${styles["sal-animate"]}`} 
              data-sal="slide-up"
              data-sal-duration="400"
              data-sal-delay="400"
            >
              <div className={`${styles["team-image"]}`} >
                <img
                  src={TeamImg}
                  width={149}
                  height={49}
                  alt="Group Image"
                />
              </div>
              <button 
              onClick={() => handleOpen()}
              className={styles["btn-default"]}  
              >
              {t("home1.web.component44.text3")}
                
              </button>
            </div>
          </div>
        </div>
        <div className={`${styles["bg-shape"]}`} >
          <img src={ShapeImg} width={353} height={203} alt="BG Shape" />
        </div>
      </div>
    </>
  );
};

export default CtaOne;
