const iam = {
    login: {
        t1: `Accedi`,
        t2: `e accedi al tuo spazio personale`,
        t3: `oppure`,
        t4: `Ricorda me`,
        t5: `Hai dimenticato la password?`,
        t6: `ACCEDI`,
        t7: `Non hai un account?`,
        t8: `Iscrivi`
    },
    table: {
        t1: `Non abbiamo trovato dati nella tua base vettoriale`,
        t2: `Crea`,
        t3: `Documentazione di avvio rapido`,
    },
    header: {
        t1: `Torna indietro`,
        t2: `Gestione dell'identità e dell'accesso (IAM)`,
        t3: `Utenti`,
        t4: `Applicazioni`,
        t5: `Politiche`,
        t6: `Chiavi API`,
        t7: `Log`,
    },
    users: {
        t1: `Di seguito è mostrata una lista di utenti di questa organizzazione. 
            Puoi vedere più informazioni su ogni utente.`,
        t2: `Cos'è un utente`,
        t3: `Aggiungi utente`,
        t4: `Invita utente(i)`,
        t5: `Inserisci l'indirizzo e-mail di ogni utente da invitare, separato da virgole.`,
        t6: `Inserisci etichette di valore chiave`,
        t7: `Le etichette di valore chiave ti aiutano a organizzare i tuoi utenti. Puoi assegnare fino a 10 etichette per utente.`,
        t8: `Aggiungi a un gruppo esistente (opzionale)`,
        t9: `Invita`,
        t10: `Informazioni utente`,
        t11: `Stato`,
        t12: `Attivato`,
        t13: `Tipo`,
        t14: `Ospite`,
        t15: `Si è unito`,
        t16: `Ultimo accesso`,
        t17: `Elimina utente`,
        t18: `Eliminando un utente da questa organizzazione, verranno eliminate automaticamente le sue chiavi API e qualsiasi politica direttamente associata a loro rimarrà orfana.`,
        t19: `Elimina utente`,
    },
    apps: {
        t1: `Di seguito è mostrata una lista di applicazioni in questa Organizzazione. Puoi vedere più informazioni su ogni applicazione.`,
        t2: `Cos'è un'applicazione?`,
        t3: `Crea App`,
        t4: `Applicazioni`,
        t5: `Crea un'applicazione`,
        t6: `Inserisci un nome e una descrizione opzionale`,
        t7: `Nome`,
        t8: `Il nome della tua applicazione può contenere solo caratteri alfanumerici, punti e trattini.`,
        t9: `Descrizione`,
        t10: `Inserisci etichette di valore chiave (opzionale)`,
        t11: `Le etichette di valore chiave ti aiutano a organizzare le tue applicazioni. Puoi assegnare fino a 10 etichette per applicazione.`,
        t12: `Inserisci etichette di valore chiave (opzionale)`,
        t13: `Crea applicazione`,
    },
    polices: {
        t1: `Questa è una lista delle politiche di questa Organizzazione. Puoi vedere più informazioni su ogni politica.`,
        t2: `Cos'è una politica?`,
        t3: `Crea politica`,
        t4: `Politiche`,
        t5: `Regole`,
        t6: `Una regola consiste in un ambito (ad esempio, livello di progetto o livello di organizzazione) e uno o più insiemi di permessi (ad esempio, "elencare tutte le istanze").`,
        t7: `Ottieni più informazioni sulle regole.`,
        t8: `Regola #1`,
        t9: `Fai`,
        t10: `Organizzazione`,
        t11: `Gli insiemi di permessi di IAM e fatturazione possono essere selezionati solo a livello di organizzazione.`,
        t12: `Accesso a risorse`,
        t13: `Concede accesso a risorse (istanze, storage di oggetti, database, ecc.) nei tuoi progetti.`,
        t14: `Accesso alle funzionalità dell'organizzazione`,
        t15: `Concede accesso a IAM, fatturazione, supporto e ticket di abuso e gestione dei progetti, tutto gestito a livello di organizzazione.`,
        t16: `Convalida`,
        t17: `Insiemi di permessi`,
        t18: `Gestore di organizzazione`,
        t19: `Aggiungi regola`,
    },
    apis: {
        t1: `Di seguito è mostrata una lista delle chiavi API di questa organizzazione.`,
        t2: `Come creare chiavi API`,
        t3: `Genera chiave API`,
        t4: `API`,
        t5: `Genera una chiave API`,
        t6: `Copia e archivia la tua chiave segreta. Verrà mostrata solo una volta.`,
        t7: `Gestisci i tuoi vettori attraverso l'accesso IAM aggiungendo le tue
            Chiavi API per utilizzarle`,
        t8: `ID chiave di accesso`,
        t9: `Chiave segreta`,
        t10: `Chiudi Genera chiave`,
        t11: `Genera una chiave API`,
        t12: `Seleziona portatore di chiave API`,
        t13: `Io stesso (utente di IAM)`,
        t14: `Un'applicazione`,
        t15: `Descrizione`,
        t16: `Scadenza`,
        t17: `Verrà utilizzata questa chiave API per lo storage di oggetti?`,
        t18: `Io stesso (utente di IAM)`,
        t19: `Io stesso (utente di IAM)`,
        t20: `Genera chiave API`,
        t21: `Chiudi`,
    },
    logs: {
        t1: `Di seguito è mostrata la lista dei tuoi registri di risorse di IAM. Le risorse di IAM possono essere utenti, applicazioni, gruppi, chiavi API e politiche di IAM. Puoi ottenere più dettagli su ognuno di essi.`,
        t2: `Come capire i miei registri?`,
        t3: `Filtra per ID di risorsa di IAM esatto`,
        t4: `Data`,
        t5: `Data di IAM`,
        t6: `Categoria`,
        t7: `categoria`,
        t8: `Azione`,
        t9: `Azione`,
        t10: `Aggiungi`,
        t11: `Reimposta`,
        t12: `Filtra`,
        t13: `Log`,
        t14: `Informazioni log`,
        t15: `Stato`,
        t16: `Attivato`,
        t17: `Tipo`,
        t18: `Ospite`,
        t19: `Si è unito`,
        t20: `Ultimo accesso`,
        t21: `Elimina registro`,
        t22: `Eliminando un registro di questa organizzazione, verranno eliminate automaticamente le sue chiavi API e qualsiasi politica direttamente associata a loro rimarrà orfana.`,
        t23: `Elimina registro`,
    },
}

export default iam