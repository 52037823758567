const form = {
    editor: {
        step0: {
            text0: `Votre projet sera-t-il monétisé ?`,
            t0: `Oui`,
            t1: `Non`,
        },
        step1: {
            title: `Description de votre projet`,
            text: `Décrivez le projet pour préparer les premières étapes`,
            t0: `Nom`,
            t1: `Description`,
        },
        step2: {
            title: `Combien d'utilisateurs prévoyez-vous d'avoir ?`,
            text: `Selon le nombre d'utilisateurs, nous proposons des mesures plus critiques`,
            t0: `0-1.000`,
            t1: `1.000-10k`,
            t2: `10k-100k`,
            t3: `100k-250k`,
            t4: `250k-500k`,
            t5: `+500.001`
        },
        step3: {
            title: `Sélectionnez le type et le style`,
            text0: `Le type sert à choisir l'une de nos catégories`,
            text1: `Le style définit son apparence`,
            t0: `Bonjour le monde`,
            t1: `Minimaliste`,
            t2: `Moderne`,
            t3: `Classique`,
            x0: `Complexe`,
            x1: `Simple`,
        },
        step4: {
            title: `Voulez-vous le mode éditeur avancé ou de base ?`,
            t0: `Avancé`,
            t1: `De base`,
        },
    }, 
    flags: {
        en: `Anglais`,
        es: `Espagnol`,
        fr: `Français`,
        de: `Allemand`,
        it: `Italien`,
        ru: `Russe`,
        pt: `Portugais`,
        nl: `Néerlandais`,
        sv: `Suédois`,
    },
    title: {
        text0: 'Étape',
        text1: 'de',
        step0: 'Langue',
        step1: 'Profil',
        step2: 'Objectif',
        step3: 'Contexte',
        step4: 'Bonus',
        step5: 'Entreprise',
        step6: 'Taille',
        step7: 'Type de Logiciel',
        step8: 'Recrutement',
    },
    footer: {
        text: 'Abonnez-vous à nos e-mails',
        button0: 'Précédent',
        button1: 'Suivant',
    },
    step0: {
        t0: `Choisissez la langue`,
        t1: `En choisissant, le site web changera`,
        t2: `Nous intégrons actuellement plus de langues`,
    },
    step1: {
        t0: `Quel est votre nom ?`,
        t1: `Ravi de vous rencontrer`,
        t2: `Prénom`,
        t3: `Nom de famille`,
        t4: `Téléphone`,
        t5: `E-mail`,
    },
    step2: {
        t0: `Pour qui en avez-vous besoin ?`,
        t1: `Sélectionnez l'option de votre choix`,
        t2: `Mon entreprise`,
        t3: `Pour moi-même`,
        t4: `Clients`,
    },
    step3: {
        t0: `Comment vous décririez-vous ? `,
        t1: `Startup`,
        t2: `Petite entreprise`,
        t3: `Entreprise de taille moyenne`,
        t4: `Grande entreprise`,
    },
    step4:{
        t0: `Êtes-vous étudiant ?`,
        t1: `Oui`,
        t2: `Non`,
    },
    step5:{
        t0: `Qu'est-ce qui vous décrit le mieux ?`,
        t1: `Travailleur indépendant`,
        t2: `Agence`,
    },
    step6:{
        t0: `Quelle est la taille de votre équipe de travail ?`,
    },
    step7:{
        t0: `Quel type de site souhaitez-vous construire aujourd'hui ?`,
        t1: `Site d'entreprise`,
        t2: `Boutique de commerce électronique`,
        t3: `Portfolio`,
        t4: `Blog`,
        t5: `Analytique`,
        t6: `Autre`,
    },
    step8:{
        t0: `Êtes-vous intéressé par l'utilisation d'Aythen ?`,
        t1: `Oui`,
        t2: `Non`,
        t3: `Je ne suis pas sûr`,
    },
}

export default form
