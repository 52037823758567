// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay_c2g8o {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    background: #ffffff6b;
    z-index: 9999;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    .modal_X6X0u {
      max-height: 80vh;
      background: #fff;
      padding: 8px;
      border-radius: 8px;
      video{
        width: 300px;
      }
    }
}




`, "",{"version":3,"sources":["webpack://./src/views/web/components/Cloud/video.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,aAAa;IACb,YAAY;IACZ,qBAAqB;IACrB,aAAa;IACb,oBAAoB;IACpB,mBAAmB;IACnB,uBAAuB;IACvB;MACE,gBAAgB;MAChB,gBAAgB;MAChB,YAAY;MACZ,kBAAkB;MAClB;QACE,YAAY;MACd;IACF;AACJ","sourcesContent":[".overlay {\n    position: fixed;\n    top: 0px;\n    left: 0px;\n    height: 100vh;\n    width: 100vw;\n    background: #ffffff6b;\n    z-index: 9999;\n    display: inline-flex;\n    align-items: center;\n    justify-content: center;\n    .modal {\n      max-height: 80vh;\n      background: #fff;\n      padding: 8px;\n      border-radius: 8px;\n      video{\n        width: 300px;\n      }\n    }\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `overlay_c2g8o`,
	"modal": `modal_X6X0u`
};
export default ___CSS_LOADER_EXPORT___;
