import React from 'react'

import SectionImage from './assets/section-img.png'
import DragNavImage from './assets/drag-nav.png'
import Card1Image from './assets/img-1.svg'
import Card2Image from './assets/img-2.png'
import Card3Image from './assets/img-3.png'
import Card4Image from './assets/img-4.png'

const Plugins = () => {
  return (
    <div
      className="relative w-full sm:h-full lg:mt-[-90px]  lg:pb-[230px] lg:mb-8"
      id="library"
    >
      <div className="container mx-auto">
        <section className="home-section-1 ">
          <div className="flex">
            <div className="relative lg:pt-[10px] z-[0]">
              <div className="mb-5 flex items-center ml-[-8px] absolute left-0 top-[10px] w-[300px]">
                <div className="bg-[#32D1C8] z-[-1]  w-[40px] h-[40px] p-3 flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="26"
                    viewBox="0 0 28 26"
                    fill="none"
                  >
                    <path
                      d="M3.84662 3.76562H2.00047C1.49067 3.76562 1.07739 4.1789 1.07739 4.6887V24.0733C1.07739 24.5831 1.49067 24.9964 2.00047 24.9964H3.84662C4.35642 24.9964 4.7697 24.5831 4.7697 24.0733V4.6887C4.7697 4.1789 4.35642 3.76562 3.84662 3.76562Z"
                      stroke="white"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.69458 11.1562H13.0792M5.69458 21.3101H13.0792"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.1561 7.46094H6.61766C6.10786 7.46094 5.69458 7.87421 5.69458 8.38401V24.0763C5.69458 24.5861 6.10786 24.9994 6.61766 24.9994H12.1561C12.6659 24.9994 13.0792 24.5861 13.0792 24.0763V8.38401C13.0792 7.87421 12.6659 7.46094 12.1561 7.46094Z"
                      stroke="white"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18.6147 1H14.9223C14.4125 1 13.9993 1.41328 13.9993 1.92308V24.0769C13.9993 24.5867 14.4125 25 14.9223 25H18.6147C19.1245 25 19.5377 24.5867 19.5377 24.0769V1.92308C19.5377 1.41328 19.1245 1 18.6147 1Z"
                      stroke="white"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M23.6023 3.77185L21.2715 4.01704C20.63 4.08454 20.165 4.68454 20.2371 5.34973L22.2517 23.903C22.3244 24.5682 22.9077 25.0569 23.5492 24.9899L25.88 24.7447C26.5215 24.6772 26.9865 24.0772 26.9144 23.412L24.9027 4.86166C24.8271 4.19358 24.2438 3.70435 23.6023 3.77185Z"
                      stroke="white"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="ml-4">
                  <h4 className="text-[#32D1C8] font-extrabold uppercase text-[20px]">
                    BIBLIOTECA
                  </h4>
                </div>
              </div>
              <div className="bibli-left-border"></div>
            </div>
            <div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="md:col-span-1 lg:col-span-2 relative">
                  <div className="pt-[100px]">
                    <div className="pl-5 lg:pl-10">
                      <p className="text-[24px] text-[#554D56] lg:text-[48px] leading-tight drop-shadow-xl text-left">
                        <span className="text-[#32D1C8] me-1">
                          Componentes creados por la comunidad.
                        </span>
                        Ten a mano miles de recursos que han sido compartidos
                        para que tu proyecto tenga todas las funcionalidades
                        necesarias.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="text-end relative">
                  <div className="w-full initial absolute top-[280px] right-[0px] lg:left-[50px] lg:top-[-200px] z-20 flex justify-end lg:block">
                    <img
                      src={DragNavImage}
                      className="w-[100px] lg:w-[200px] z-20"
                    />
                  </div>
                  <div className="w-full md:w-full">
                    <img
                      src={SectionImage}
                      className="lg:absolute lg:left-[0px] lg:top-[-40px] z-20"
                    />
                  </div>
                </div>
              </div>

              <div className="lg:mt-[120px]">
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3">
                  <div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 lg:h-[450px] relative lg:mt-[50px] mb-8 lg:mb-0">
                      <div className="lg:w-[50%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          PRIMER BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Consistencia visual
                        </h5>
                        <p className="lg:text-[15px] mt-5">
                          Una librería de componentes establece un conjunto de
                          elementos visuales y estilos predefinidos que se
                          utilizan de manera coherente en todo el diseño. Esto
                          garantiza una apariencia uniforme y profesional en
                          todas las partes del proyecto.
                        </p>
                        <img
                          src={Card1Image}
                          className="lg:absolute bottom-[0px] right-[-60px] lg:w-[80%] z-20"
                        />
                      </div>
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 relative lg:mt-5 mb-5 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          SEXTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Adaptabilidad y flexibilidad
                        </h5>
                      </div>
                      <div className="lg:w-[90%]">
                        <p className="lg:text-[15px]">
                          Una librería de componentes bien diseñada permite una
                          mayor adaptabilidad a cambios en los requisitos o en
                          el enfoque del proyecto, lo que mejora la flexibilidad
                          y agilidad del equipo.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="relative">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[430px] relative mb-8 lg:mb-0">
                      <p className="mt-0 lg:text-[13px] uppercase">
                        SEGUNDO BENEFICIO
                      </p>
                      <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                        Eficiencia en el desarrollo
                      </h5>
                      <div className="lg:w-[40%]">
                        <p className="lg:text-[15px]">
                          Al tener componentes predefinidos y listos para usar,
                          los diseñadores y desarrolladores pueden ahorrar
                          tiempo y esfuerzo al no tener que crear los elementos
                          desde cero en cada proyecto.
                        </p>
                      </div>
                      <img
                        src={Card2Image}
                        className="w-[200px] absolute lg:top-[70px] lg:right-[0px] z-20 lg:w-[60%]"
                      />
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 lg:h-[450px] relative lg:mt-5 mb-5 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          QUINTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Mejora de la calidad
                        </h5>
                      </div>
                      <div className="lg:w-[80%]">
                        <p className="lg:text-[15px]">
                          Al estandarizar y reutilizar componentes, se reducen
                          los errores y se garantiza una mayor calidad en el
                          diseño y desarrollo del proyecto.
                        </p>
                      </div>
                      <img
                        src={Card3Image}
                        className="z-20 lg:absolute bottom-[-80px] right-[-140px] lg:w-[80%]"
                      />
                    </div>
                  </div>
                  <div className="relative md:col-span-2 lg:col-span-1 grid md:grid-cols-2 lg:block gap-4">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 relative lg:mt-[150px] mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          TERCER BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Reutilización de diseño
                        </h5>
                        <p className="lg:text-[15px] mt-3">
                          La librería de componentes facilita la reutilización
                          de elementos en diferentes proyectos. Los componentes
                          pueden ser extraídos y utilizados en varias
                          aplicaciones o diseños, lo que aumenta la eficiencia
                          en el desarrollo.
                        </p>
                      </div>
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 lg:h-[500px] relative lg:mt-3 mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          CUARTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Facilidad de aprendizaje
                        </h5>
                        <p className="lg:text-[15px] mt-3">
                          Para nuevos miembros del equipo o colaboradores, una
                          librería de componentes proporciona una referencia
                          visual y funcional clara, lo que facilita la
                          familiarización con el diseño y acelera su
                          incorporación al proyecto.
                        </p>
                      </div>
                      <img
                        src={Card4Image}
                        className="z-20 lg:absolute bottom-[-100px] right-[-40px] lg:w-[70%]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Plugins
