const support = {
  t1: `Hulp van Aythen Lite`,
  t2: `Hulp en gidsen`,
  t33: `Openstaande tickets`,
  t3: `Service Level Agreement (SLA) is het beschikbaar stellen van de software platform`,
  t4: `Neem contact met ons op`,
  t5: `Maak een ondersteunings ticket aan`,
  t6: `Krijg directe hulp van ons team.`,
  t7: `Incidenten beheren`,
  t8: `Controleer uw licentie en uw openstaande en gesloten tickets.`,
  t9: `Wilt u meer weten?`,
  t10: `Kent u Aythen PRO?`,
  t11: `Kom hier om het te begrijpen en schrijf u in op onze wachtlijst`,
  t12: `Monitoring en rapportage`,
  t13: `Stuur ons uw aanbevelingen om te weten met welke gegevens u nog meer zou willen werken.`,
  topics: {
    1: {
      title: "Cookies en privacy",
      data: {
        1: {
          title: `Zijn mijn gegevens veilig?`,
          text: `Wij werken met de ISO 27001-certificering. Dit is een internationaal erkende standaard voor het beheren van de informatiebeveiliging. Deze certificering impliceert dat een informatiebeveiligingssysteem (IBS) is geïmplementeerd dat voldoet aan de noodzakelijke controles en processen om gegevens effectief te beschermen.`,
        },
        2: {
          title: `Gebruikerservaring`,
          text: `Door gebruik te maken van een cloud-dienstverlener met certificeringen zoals ISO 27001 en die in de Europese Unie opereert, geven wij meer vertrouwen in de beveiliging en bescherming van persoonlijke gegevens die wij verwerken. Dit helpt bij het voldoen aan de beveiligingsvereisten van de AVG en het mitigeren van risico's verbonden aan de verwerking van persoonlijke gegevens.`,
        },
      },
    },
    2: {
      title: `Vectoriële databases`,
      data: {
        1: {
          title: `Wat zijn het?`,
          text: `Zij vertegenwoordigen een significante innovatie op het gebied van gegevensopslag en -verwerking. Zij worden gebruikt in diverse toepassingen, 
                        inclusief datawetenschap, automatische leer en kunstmatige intelligentie. <br /><br />
                        In tegenstelling tot traditionele databases, die informatie opslaan in de vorm van tabellen met rijen en kolommen, 
                        gebruiken vectoriële databases vectoriële gegevensstructuren om informatie efficiënter op te slaan en te verwerken. 
                        Organiserend zo de gegevens in multidimensionale vectoren. Deze vectoren kunnen gegevens in diverse bestandsformaten bevatten, 
                        zoals nummers, tekst, afbeeldingen, code... Elk element van de vector vertegenwoordigt een eenheid van informatie, en de bewerkingen worden 
                        efficiënt uitgevoerd door stromen tussen informatie-sets in plaats van individuele rijen en kolommen.`,
        },
        2: {
          title: `Waarvoor dienen zij?`,
          text: `Deze structuur biedt snel toegang tot grote hoeveelheden gegevens en vergemakkelijkt complexe wiskundige en logische bewerkingen simultaan. 
                        Het elimineert de latentie van de gegevens zonder de noodzaak om alle informatie te verwerken, alleen de relevante informatie, die kan worden gesegmenteerd en georganiseerd in clusters 
                        tussen dimensies en agenten. 
                        <br /><br />
                        - Elke dimensie vertegenwoordigt een coördinaat in een multidimensionale ruimte waar de gegevens zijn georganiseerd. Dit betekent dat een verzameling vectoriële gegevens 
                        meerdere dimensies kan hebben, wat een meer complete en gedetailleerde weergave van de informatie mogelijk maakt.
                        <br /><br />
                        - De agenten zijn entiteiten die bewerkingen uitvoeren op de vectoriële gegevens. Deze agenten kunnen programma's, processen of queries zijn die interacteren met 
                        de database om informatie op te halen, te verwerken of te manipuleren. Zij kunnen toegang krijgen tot verschillende dimensies van de gegevens en complexe bewerkingen uitvoeren 
                        afhankelijk van de vereisten om grafisch weer te geven of om een vraag te beantwoorden. `,
        },
      },
    },
    3: {
      title: `Serverless backoffice`,
      data: {
        1: {
          title: `Hoe ben ik gebaat?`,
          text: `In een serverless omgeving is het niet nodig om fysieke of virtuele servers te beheren. De infrastructuur wordt beheerd zodat de applicatie automatisch kan schalen 
                        naar de vraag. 
                        <br /><br />
                        Dit betekent dat er geen noodzaak is om verkeerspieken te voorzien noch om de servercapaciteit handmatig aan te passen. Het platform zal automatisch de noodzakelijke resources 
                        schalen om het werklast te beheren.`,
        },
        2: {
          title: `Operationele flexibiliteit`,
          text: `Dus worden alleen de gebruikte resources betaald, wat kan besparen in vergelijking met traditionele servers waarvoor capaciteit wordt betaald, 
                        ongeacht of deze wordt gebruikt of niet.`,
        },
      },
    },
    4: {
      title: `On-demand diensten`,
      data: {
        1: {
          title: `Kan ik het aanpassen aan mijn bedrijf?`,
          text: `U kunt uw behoeften creëren op basis van uw eigen bedrijfsstructuur.`,
        },
        2: {
          title: `Bedrijfslogo`,
          text: `Fundamenteel om uw bedrijfslogo toe te voegen aan het register om de merkconsistentie te handhaven in alle interacties met klanten, 
                        leveranciers en andere actoren.`,
        },
        3: {
          title: `Gebruikers en rollen`,
          text: `U kunt verschillende soorten gebruikers en rollen toevoegen en beheren in het IAM, of het nu interne werknemers, externe klanten, leveranciers en andere 
                        samenwerkers in onze dynamische tabellen zijn. Zo kan elk type gebruiker verschillende toegangs- en machtigingsniveaus hebben om de beveiliging en privacy van de gegevens te garanderen.`,
        },
        4: {
          title: `Bedrijfskleuren`,
          text: `Het zou belangrijk zijn om het uiterlijk van het CRM aan te passen aan de bedrijfskleuren van het bedrijf om de visuele identiteit van het merk te versterken. 
                        Dit zou de mogelijkheid omvatten om de overheersende kleuren in de CRM-interface te kiezen, zoals achtergronden, knoppen en navigatiebalken.`,
        },
        5: {
          title: `Koppeling van bankrekeningen`,
          text: `Voor een efficiënte financiële beheer, kunt u de serverless Aythen-kaarten koppelen aan het IAM (toegangen en rollen) zodat elk personeelslid van het 
                        bedrijf het volgen van transacties, betalingen en andere financiële operaties rechtstreeks vanuit de platform kan uitvoeren, het proces van beheer vereenvoudigend.`,
        },
      },
    },
    5: {
      title: `Maatwerk interacties`,
      data: {
        1: {
          title: `Diepgaande analyse`,
          text: `De vectoriële weergave biedt een diepgaande analyse van de gegevens.`,
        },
        2: {
          title: `Strategische inzichten`,
          text: `Wij bieden waardevolle inzichten voor strategische besluitvorming via vectoriële dimensies.`,
        },
      },
    },
    6: {
      title: `Essentiële tools`,
      data: {
        1: {
          title: `Eigen documenten`,
          text: `U kunt nieuwe documenten uploaden in tekst, formaat en real-time samenwerking. Het accepteert elke variëteit van bestandsformaten, 
                        inclusief tekstbestanden, spreadsheets, presentaties, formulieren, tekeningen en meer.`,
        },
        2: {
          title: `Opslag`,
          text: `De documenten kunnen ruimte in uw account innemen. U kunt ze dus bevriezen om toegang te hebben wanneer u wilt of verwijderen als ze niet van belang zijn.`,
        },
        2: {
          title: `Delen en samenwerken`,
          text: `U kunt uw documenten delen met andere gebruikers en samenwerken in real-time. De samenwerkers kunnen de wijzigingen in het document zien 
                        terwijl deze worden uitgevoerd en commentaren toevoegen om ideeën te bespreken.`,
        },
        3: {
          title: `Elektronische factuur`,
          text: `Bespaart tijd en middelen. Automatiseert het proces van factuuruitgifte, -verzending en -ontvangst, wat de administratieve last en kosten 
                        verbonden aan papier reduceert. Reduceert menselijke fouten en verbetert de precisie in het registreren en volgen van financiële transacties. Alles wat nodig is om te voldoen aan de wettelijke vereisten voor elke zelfstandige 
                        en onderneming voor fiscale en juridische regelgeving.`,
        },
        3: {
          title: `Contracten`,
          text: `Centralisatie van informatie over klanten en commerciële overeenkomsten, wat het beheer en volgen van contracten over hun levenscyclus vergemakkelijkt.`,
        },
        4: {
          title: `Dashboards van uw favoriete grafieken`,
          text: `Creërend uw dashboards op maat op basis van uw interacties biedt u de mogelijkheid om belangrijke gegevens en metrieken duidelijk en beknopt weer te geven, 
                        wat het identificeren van trends, patronen en kansen voor besluitvorming vergemakkelijkt. `,
        },
        5: {
          title: `Dynamische tabellen`,
          text: `De dynamische tabellen bieden een flexibele manier om uw gegevens samen te vatten op basis van uw projecten. U kunt de fiches van 
                        de betrokken personen, de status, data vanuit verschillende perspectieven en detailniveaus opsommen. U kunt filteren, groeperen en samenvatten gegevens interactief om gedetailleerde informatie te verkrijgen en beslissingen te nemen.`,
        },
      },
    },
  },
}

export default support