const home = {
    banner: {
        banner: 'Редактировать фото обложки',
        edit: 'Редактировать профиль',
        addUser: 'Добавить пользователя',
        title: 'пользователи, разделенные',
        viewTeam: 'Управлять командой',
        team: {
            title: `Люди, которых вы, возможно, знаете`,
            button: `Просмотреть всех`,
            addContact: `Добавить в контакты`
        },
    },
    t1: `Организация`,
    t2: `Банк`,
    t3: `Идентификация и доступ менеджера (IAM)`,
    t4: `Доступ (IAM)`,
    t5: `Работайте и понимайте свои данные как векторы`,
    t6: `Войдите в новое измерение данных. Удалите задержку, чтобы работать в x2000 
        раз более эффективно, идеально для сегментации, анализа и понимания больших объемов 
        данных одновременно.`,
    t60: `Добавьте данные в свой вектор, чтобы они работали за вас`,
    t61: `Перейти к вектору`,
    t7: `Создать новый`,
    t8: `Добавить новый`,
    t9: `Новый`,
    t10: `Создайте свои Addons, чтобы создавать векторные компоненты, в которых представлять`,
    t11: `Нет addon, вы хотите создать`,
    t12: `Новый Addon`,
    t13: `Премиальный Addon`,
    t14: `Потребление данных`,
    t15: `Характеристики`,
    t16: `Просмотреть больше`,
    t17: `Заметки`,
    t18: `Добавить новый`,
    t19: `Безопасные данные`,
    t20: " RGPD - ISO 27001",
    t21: `вы можете работать с настраиваемыми векторами.`,
    steps: {
        addCampaign: `Создать кампанию`,
        addAdvice: `Создать объявления`,
        t1: `Проверка соблюдения политик`,
        t2: `Импрессии`,
        t3: `Конверсии`,
        title: `Все диагностические результаты кампании`,
        s1: `Состояние счета`,
        s2: `Состояние выставления счетов`,
        s3: `Политики кампании`,
        s4: `Отслеживание конверсий`,
        s5: `Бюджет кампании`,
        s6: `Стратегия ставок`,
        s7: `Состояние кампании`,
        s8: `Эффективность объявления`,
        text1: `Эта кампания не имеет объявлений`,
        text2: `Добавьте объявления в группы объявлений, чтобы они были опубликованы`,
        text3: `Решить`,
    },
    t22: {
        t0: `В`,
        t1: `мы используем`,
    },
    t23: {
        t0: `Скоро будет доступно`,
        t1: `Скоро будет доступно, мы можем отправить вам информацию для
        pro`,
    },
    t24: {
        t0: `Нет подключения к Интернету`,
        t1: `Проверьте свое подключение Wi-Fi или мобильных данных, или получите уведомление, когда подключение будет восстановлено`,
        t2: `Уведомить меня`,
        t3: `Попробовать снова`,
    },
    t25: {
        t0: `Не найдено`,
        t1: `🚀 Не найдено`,
        t2: `Просмотреть изменения`,
    }
}

export default home;