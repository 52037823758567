
const requireModule = require.context('./', false, /\.js$/);

const translation = {};

requireModule.keys().forEach((fileName) => {
    if (fileName === './index.js') return;

    const moduleName = fileName.replace(/(\.\/|\.js)/g, '');
  
    translation[moduleName] = requireModule(fileName).default;
});

export default translation ;