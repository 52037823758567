import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux'

import styles from '../index.module.css'


import {
  support
} from '../../../../views/app/v1-1/actions/iam'

const ContactForm = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [state, setState] = useState({})

  const handleSend = async () => {

    await dispatch(support({
      company: state.company || 'not found',
      name: state.name || 'not found',
      email: state.email || 'not found',
      web: state.web || 'not found',
      text: state.text || 'not found',
    }))

    setState({})

  }



  return (
    <>
      <div className={`${styles["rbt-profile-row"]} ${styles["rbt-default-form"]} ${styles["row"]} ${styles["row--15"]}`} >
        <div className={`${styles["col-lg-6"]} ${styles["col-md-6"]} ${styles["col-sm-6"]} ${styles["col-12"]}`} >
          <div className={`${styles["form-group"]}`} >
            <label htmlFor="firstname1">{t("home1.contact.form.text1")}</label>
            <input 
            id="firstname1" 
            type="text" 
            placeholder="" 
            spellCheck="false"
            value={state.company}
            onChange={(e) => setState({ ...state, company: e.target.value })} />
          </div>
        </div>
        <div className={`${styles["col-lg-6"]} ${styles["col-md-6"]} ${styles["col-sm-6"]} ${styles["col-12"]}`} >
          <div className={`${styles["form-group"]}`} >
            <label htmlFor="lastname1">{t("home1.contact.form.text2")}</label>
            <input 
            id="lastname1" 
            type="text" 
            placeholder=""
            spellCheck="false"
            value={state.name}
            onChange={(e) => setState({ ...state, name: e.target.value })}
             />
          </div>
        </div>
        <div className={`${styles["col-lg-6"]} ${styles["col-md-6"]} ${styles["col-sm-6"]} ${styles["col-12"]}`} >
          <div className={`${styles["form-group"]}`} >
            <label htmlFor="username1">{t("home1.contact.form.text3")}</label>
            <input 
            id="username1" 
            type="text" 
            placeholder="" 
            spellCheck="false"
            value={state.email}
            onChange={(e) => setState({ ...state, email: e.target.value })}
            />
          </div>
        </div>
        <div className={`${styles["col-lg-6"]} ${styles["col-md-6"]} ${styles["col-sm-6"]} ${styles["col-12"]}`} >
          <div className={`${styles["form-group"]}`} >
            <label htmlFor="phonenumber1">{t("home1.contact.form.text4")}</label>
            <input 
            id="phonenumber1" 
            type="tel" 
            placeholder="" 
            spellCheck="false"
            value={state.web}
            onChange={(e) => setState({ ...state, web: e.target.value })}
            />
          </div>
        </div>
        <div className={styles["col-12"]} >
          <div className={`${styles["form-group"]}`} >
            <label htmlFor="bio1">{t("home1.contact.form.text5")}</label>
            <textarea
              id="bio1"
              cols="20"
              rows="5"
              spellCheck="false"
              value={state.text}
              onChange={(e) => setState({ ...state, text: e.target.value })}
            ></textarea>
          </div>
        </div>
        <div className={`${styles["col-12"]} ${styles["mt--20"]}`} >
          <div className={`${styles["form-group"]} ${styles["mb--0"]}`} >
            <button 
            className={styles["btn-default"]} 
            onClick={() => handleSend()}
            >
              {t("home1.contact.form.text6")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
