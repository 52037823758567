const form = {
    editor: {
        step0: {
            text0: `¿Tu proyecto va a tener monetización?`,
            t0: `Sí`,
            t1: `No`,
        },
        step1: {
            title: `Descripción de tu proyecto`,
            text: `Describe el proyecto para preparar los primeros pasos que hay`,
            t0: `Nombre`,
            t1: `Descripción`,
        },
        step2: {
            title: `¿Cuántos usuarios esperas tener?`,
            text: `Dependiendo de la cantidad de usuarios, ofrecemos medidas más críticas`,
            t0: `0-1.000`,
            t1: `1.000-10k`,
            t2: `10k-100k`,
            t3: `100k-250k`,
            t4: `250k-500k`,
            t5: `+500.001`
        },
        step3: {
            title: `Selecciona el tipo y estilo`,
            text0: `El tipo sirve para escoger una de nuestras categorías`,
            text1: `El estilo define la forma en que se verá`,
            t0: `Hello world`,
            t1: `Minimalista`,
            t2: `Moderno`,
            t3: `Clásico`,
            x0: `Complejo`,
            x1: `Simple`,
        },
        step4: {
            title: `¿Quieres el modo editor avanzado o básico?`,
            t0: `Avanzado`,
            t1: `Básico`,
        },
    }, 
    flags: {
        en: `Ingles`,
        es: `Español`,
        fr: `Francés`,
        de: `Alemán`,
        it: `Italiano`,
        ru: `Ruso`,
        pt: `Portugués`,
        nl: `Neerlandés`,
        sv: `Sueco`,
    },
    title: {
        text0: 'Paso',
        text1: 'de',
        step0: 'Lenguaje',
        step1: 'Perfil',
        step2: 'Objetivo',
        step3: 'Contexto',
        step4: 'Bono',
        step5: 'Compañia',
        step6: 'Tamaño',
        step7: 'Tipo de software',
        step8: 'Contratación',
    },
    footer: {
        text: 'Suscribete al newsletter',
        button0: 'Anterior',
        button1: 'Siguiente',
    },
    step0: {
        t0: `Elige el idioma`,
        t1: `Al elegirlo, la web cambiará`,
        t2: `Actualmente estamos integrando más idiomas`,
    },
    step1: {
        t2: `Nombre`,
        t3: `Apellido`,
        t4: `Teléfono`,
        t5: `Email`,
    },
    step2: {
        t0: `¿Para quién lo necesitas?`,
        t1: `Selecciona la opción de tu propósito`,
        t2: `Mi empresa`,
        t3: `Para mí`,
        t4: `Clientes`,
    },
    step3: {
        t0: `¿Cómo te describes? `,
        t1: `Startup`,
        t2: `Pequeña empresa`,
        t3: `Empresa mediana`,
        t4: `Empresa grande`,
    },
    step4:{
        t0: `¿Eres estudiante?`,
        t1: `Sí`,
        t2: `No`,
    },
    step5:{
        t0: `¿Qué te describe mejor?`,
        t1: `Freelancer`,
        t2: `Agencia`,
    },
    step6:{
        t0: `¿Qué tamaño tiene tu equipo de trabajo?`,
    },
    step7:{
        t0: `¿Qué tipo de sitio estás buscando construir hoy?`,
        t1: `Sitio de negocios`,
        t2: `Tienda de ecommerce`,
        t3: `Portafolio`,
        t4: `Blog`,
        t5: `Análisis`,
        t6: `Otro`,
    },
    step8:{
        t0: `¿Estás interesado en utilizar Aythen?`,
        t1: `Sí`,
        t2: `No`,
        t3: `Aún no lo sé`,
    },
}

export default form
