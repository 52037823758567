const Icon1 = require(`@app/assets/steps/1.svg`).default;
const Icon2 = require(`@app/assets/steps/2.svg`).default;
const Icon3 = require(`@app/assets/steps/3.svg`).default;
const Icon4 = require(`@app/assets/steps/4.svg`).default;
const Icon5 = require(`@app/assets/steps/5.svg`).default;
const Icon6 = require(`@app/assets/steps/6.svg`).default;
const Icon7 = require(`@app/assets/steps/7.svg`).default;
const Icon8 = require(`@app/assets/steps/8.svg`).default;
const Icon9 = require(`@app/assets/steps/9.svg`).default;
const Icon10 = require(`@app/assets/steps/10.svg`).default;
const Icon11 = require(`@app/assets/steps/11.svg`).default;
const Icon12 = require(`@app/assets/steps/12.svg`).default;
const Icon13 = require(`@app/assets/steps/13.svg`).default;
const Icon14 = require(`@app/assets/steps/14.svg`).default;
const Icon15 = require(`@app/assets/steps/15.svg`).default;
const Icon16 = require(`@app/assets/steps/16.svg`).default;
const Icon17 = require(`@app/assets/steps/17.svg`).default;
const Icon18 = require(`@app/assets/steps/18.svg`).default;
const Icon19 = require(`@app/assets/steps/19.svg`).default;
const Icon20 = require(`@app/assets/steps/20.svg`).default;
const Icon21 = require(`@app/assets/steps/21.svg`).default;
const Icon22 = require(`@app/assets/steps/22.svg`).default;
const Icon23 = require(`@app/assets/steps/23.svg`).default;
const Icon24 = require(`@app/assets/steps/24.svg`).default;
const Icon25 = require(`@app/assets/steps/25.svg`).default;
const Icon26 = require(`@app/assets/steps/26.svg`).default;
const Icon28 = require(`@app/assets/steps/28.svg`).default;
const Icon29 = require(`@app/assets/steps/29.svg`).default;
const Icon30 = require(`@app/assets/steps/30.svg`).default;
const Icon31 = require(`@app/assets/steps/31.svg`).default;
const Icon32 = require(`@app/assets/steps/32.svg`).default;
const Icon33 = require(`@app/assets/steps/33.svg`).default;

const renderHelp = ({title, text, icon}) => {
    return (`<div class="steps-intro">
<img src=${icon} />
<div class="info">
<b>
${title} 
</b>
<p>
${text} 
</p>
</div>
</div>`)
}





export const home = [{
    hintPosition: 'top-right',
    element: `[data-step="0-1"]`,
    position: 'bottom',
    intro: renderHelp({
        icon: Icon1,
        title: `Tu Escitorio Cloud`,
        text: `El escritorio en el sistema operativo Cloud de Aythen actúa como la interfaz principal tras iniciar sesión.`
    })
},
{
    hintPosition: 'top-right',
    tooltipClass: 'introjs-bottom',
    position: "bottom",
    element: `[data-step="0-2"]`,
    intro: renderHelp({
        icon: Icon2,
        title: `Escitorio > Personalización`,
        text: ` Personaliza el fondo de pantalla gracias a la generación de imágenes inteligente. Escribe qué lienzo quieres pintar y plasma tu idea.`
    }),
},
{
    tooltipClass: 'introjs-top',
    hintPosition: 'top-right',
    position: "bottom",
    element: `[data-step="0-3"]`,
    intro: renderHelp({
        icon: Icon3,
        title: `Escitorio > Estado del sistema`,
        text: `Comprueba el estado de tu sistema para saber si todo el procesamiento está siendo el óptimo para seguir trabajando con tus datos.`
    })
},
{
    tooltipClass: 'introjs-top',
    hintPosition: 'top-right',
    position: "bottom",
    element: `[data-step="0-4"]`,
    intro: renderHelp({
        icon: Icon4,
        title: `Multii-escritorio`,
        text: `Dinamiza tus proyectos gracias a un acceso segmentado de tus datos. De esta manera podrás trabajar muchas ideas.`
    })
},
{
    hintPosition: 'top-right',
    element: `[data-step="1-1"]`,
    position: 'bottom',
    intro: renderHelp({
        icon: Icon5,
        title: `Tu resumen > Diseño `,
        text: `De tus proyectos y dominios, necesitarás diseños.En los Add-ons puedes crear lo que quieras. Así observarás el consumo de recursos que implica.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-2"]`,
    intro: renderHelp({
        icon: Icon6,
        title: `Tu resumen > Datos`,
        text: `De tus proyectos y diseños, tendrás datos.Accede a tus bases de datos vectorizados y guarda tus gráficas de más interés.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-3"]`,
    intro: renderHelp({
        icon: Icon7,
        title: `Tu resumen > Status`,
        text: `Refresca tu memoria con las diferentes funcionalidades que tienes al alcance, el estado de tu conexión y acceso a soporte.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="2-0"]`,
    intro: renderHelp({
        icon: Icon8,
        title: `Aythen DB > tus análisis`,
        text: `Crea y sincronza todos los datos para crear dimensiones y estrategias. Así vas a poder tomar decisiones informadas gracias a las interacciones. `
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="2-0"]`,
    intro: renderHelp({
        icon: Icon9,
        title: `Tu documentación`,
        text: `Aythen admite cualquier formato de archivo. Arrastra y suelta tus archivos deseados. Compártelos y categoriza su relevancia.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="3-0"]`,
    intro: renderHelp({
        icon: Icon10,
        title: `Kan Ban > Tus tareas`,
        text: `Deberás organizar tus tareas y tu equipo. Agrega tickets, listas de tareas, prioridades, estados y comprueba el estado general de los avances.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="4-0"]`,
    intro: renderHelp({
        icon: Icon11,
        title: `Facturación electrónica (OCR)`,
        text: `Rellena tus datos empresariales y gestiona tu balance, programa recordatorios de cobro y configura tus límites de licencia.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icon: Icon12,
        title: `Firma electrónica (Audit trail)`,
        text: `Gestiona todos tus acuerdos gracias a la recopilación de tus contratos. Firma, descarga o elimina aquellos documentos más sensibles.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icon: Icon13,
        title: `Tu primer proyecto`,
        text: `Con todo el repaso a la plataforma. Crea un nuevo add-on (público o privado). Selecciona ajustes y vincula tu dominio. Guarda y abrélo:`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icon: Icon14,
        title: `Tu primer proyecto`,
        text: `Con todo el repaso a la plataforma. Crea un nuevo add-on (público o privado). Selecciona ajustes y vincula tu dominio. Guarda y abrélo:`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-1"]`,
    intro: renderHelp({
        icon: Icon15,
        title: `Tu espacio creativo`,
        text: `Aquí vas a poder construir tus flujos de diseños. Crea y conecta para crear pantallas correlativas. ¿Quieres saber cómo empezar?`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-2"]`,
    intro: renderHelp({
        icon: Icon16,
        title: `Crea y guarda`,
        text: `Todo tu flujo se basa en nodos inteligentes. Conectarán tanto tus pantallas como sincronizarán tus datos. Crea el primero y guarda tu sesión.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-3"]`,
    intro: renderHelp({
        icon: Icon17,
        title: `Organiza tu vista`,
        text: `¿Eres de cuarículas? ¿De puntos? ¿De tenerlo todo ordenado? ¿Quieres saber donde está cada nodo en todo momento? Estos 3 botones te ayudarán.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-4"]`,
    intro: renderHelp({
        icon: Icon18,
        title: `Controla tu vista`,
        text: `Haz zoom para pulir detalles o para ganar perspectiva de toda tu creación. Ésta, va consumir unos recursos u otros en función de su uso.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-5"]`,
    intro: renderHelp({
        icon: Icon19,
        title: `Controla tu creación`,
        text: `Exporta o importa tus diseños y analiza dónde los usuarios son más activos gracias a los mapas de calor. Así vas a poder correlacionar con criterio.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-6"]`,
    intro: renderHelp({
        icon: Icon20,
        title: `Añade y sincroniza`,
        text: `Agrega contenido a tu diseño, súbelo a GitHub de forma automática o compártelo con terceros. Todo lo que necesites para crear sin fin.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-7"]`,
    intro: renderHelp({
        icon: Icon21,
        title: `¿Tienes tus primer flujo?`,
        text: `Es posible que no esté todo a gusto del consumidor. Por ello, puedes dar doble click o “Alt” + click al nodo que necesites perfilar.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-8"]`,
    intro: renderHelp({
        icon: Icon22,
        title: `Tu espacio creativo individual`,
        text: `Aquí vas a poder hacer foco a tu construcción de diseño. Crea con código y conecta nuevos o datos existentes para crear pantallas correlativas. `
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-9"]`,
    intro: renderHelp({
        icon: Icon23,
        title: `Accede a tu código`,
        text: `Modifica y guarda en tiempo real. Con un efecto espejo vas a poder editar y ver el diseño para crear cualquier modificación a tu gusto.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-10"]`,
    intro: renderHelp({
        icon: Icon24,
        title: `Sincroniza tus datos`,
        text: `Encontrarás diferentes métodos para guardar y relacionar tu contenido a tus bases de datos. Si tienes dudas, comprueba nuestros comandos.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-11"]`,
    intro: renderHelp({
        icon: Icon25,
        title: `Accesos rápidos`,
        text: `Vuelve atrás con todos los nodos, haz zoom en este espacio individual o bien, haz click a la derecha para comprobar su vista desde el navegador.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-12"]`,
    intro: renderHelp({
        icon: Icon26,
        title: `Trabaja de forma eficiente`,
        text: `Crea todas tus modificaciones a la vez con la doble pantalla interna para tener acceso rápido a todas las funcionaldiades disponibles. `
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-0"]`,
    intro: renderHelp({
        icon: Icon28,
        title: `Tu primer análisis`,
        text: `Aquí vas a poder construir tus flujos de datos. Tanto externos como tus sincronizados con el diseño. Crea interacciones y toma decisiones informadas.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-1"]`,
    intro: renderHelp({
        icon: Icon29,
        title: `Localiza tus datos`,
        text: `Busca, selecciona tus nodos con las pestañas, guarda tu versión, descarga y ordena tu flujo a través de las diferentes funcionalidades.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-2"]`,
    intro: renderHelp({
        icon: Icon30,
        title: `Interacciona con tus datos`,
        text: `Para tomar decisiones informadas vas a necesitar hacer preguntas, gráficos o relaciones de matrices a través de Python / Javascript. `
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-3"]`,
    intro: renderHelp({
        icon: Icon31,
        title: `Gestiona tu nodo`,
        text: `Cada nodo inteligente se configura a través de diferentes maneras. Su flexibilidad de cualquier dato y formato es para cualquier propósito.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-4"]`,
    intro: renderHelp({
        icon: Icon32,
        title: `Atento a todos los detalles`,
        text: `Vas a poder ver y modificar el nombre. Así siempre vas a poder buscarlo para encontrar tus datos. A su derecha tendrás cuando se subió y lo que ocupa.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-5"]`,
    intro: renderHelp({
        icon: Icon33,
        title: `Configura tus datos`,
        text: `Haz copias de seguridad con las versiones, incorpora datos de terceros, genera una pregunta o bien expande el nodo para ver todos los datos.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-6"]`,
    intro: renderHelp({
        icon: Icon1,
        title: `Previsualiza tus datos`,
        text: `En la ventana del nodo vas a poder ver todos los datos que has a tu espacio para poder entender de forma rápida qué es y con qué se puede relacionar.`
    })
}]


export default home