const iam = {
    login: {
        t1: `Inloggen`,
        t2: `en toegang tot uw persoonlijke ruimte`,
        t3: `OF`,
        t4: `Onthoud mij`,
        t5: `Wachtwoord vergeten?`,
        t6: `INLOGGEN`,
        t7: `Hebt u geen account?`,
        t8: `Registreer`
    },
    table: {
        t1: `We hebben geen gegevens gevonden in uw vectoriële database`,
        t2: `Creëer`,
        t3: `Snelstartdocumentatie`,
    },
    header: {
        t1: `Terug naar voren`,
        t2: `Identiteits- en toegangsbeheer (IAM)`,
        t3: `Gebruikers`,
        t4: `Toepassingen`,
        t5: `Beleidsregels`,
        t6: `API-sleutels`,
        t7: `Logboeken`,
    },
    users: {
        t1: `Hieronder wordt een lijst van gebruikers in deze organisatie weergegeven. 
            U kunt meer informatie over elke gebruiker bekijken.`,
        t2: `Wat zijn gebruikers`,
        t3: `Gebruiker toevoegen`,
        t4: `Nodig gebruiker(s) uit`,
        t5: `Voer het e-mailadres van elke gebruiker in die u wilt uitnodigen, gescheiden door komma's.`,
        t6: `Voer sleutelwaardenlabels in`,
        t7: `Sleutelwaardenlabels helpen u om uw gebruikers te organiseren. U kunt maximaal 10 labels per gebruiker toewijzen.`,
        t8: `Toevoegen aan een bestaande groep (optioneel)`,
        t9: `Uitnodigen`,
        t10: `Gebruikersinformatie`,
        t11: `Status`,
        t12: `Geactiveerd`,
        t13: `Type`,
        t14: `Gast`,
        t15: `Heeft zich aangesloten`,
        t16: `Laatste toegang`,
        t17: `Gebruiker verwijderen`,
        t18: `Wanneer u een gebruiker uit deze organisatie verwijdert, worden automatisch zijn API-sleutels verwijderd en elke rechtstreeks aan hen gekoppelde beleidsregel wordt wees.`,
        t19: `Gebruiker verwijderen`,
    },
    apps: {
        t1: `Hieronder wordt een lijst van toepassingen in deze organisatie weergegeven. U kunt meer informatie over elke toepassing bekijken.`,
        t2: `Wat zijn toepassingen?`,
        t3: `App maken`,
        t4: `Toepassingen`,
        t5: `Een toepassing maken`,
        t6: `Voer een naam en een optionele beschrijving in`,
        t7: `Naam`,
        t8: `De naam van uw toepassing mag alleen alfanumerieke tekens, punten en streepjes bevatten.`,
        t9: `Beschrijving`,
        t10: `Voer sleutelwaardenlabels in (optioneel)`,
        t11: `Sleutelwaardenlabels helpen u om uw toepassingen te organiseren. U kunt maximaal 10 labels per toepassing toewijzen.`,
        t12: `Voer sleutelwaardenlabels in (optioneel)`,
        t13: `Toepassing maken`,
    },
    polices: {
        t1: `Dit is een lijst van beleidsregels in deze organisatie. U kunt meer informatie over elke beleidsregel bekijken.`,
        t2: `Wat zijn beleidsregels?`,
        t3: `Beleidsregel maken`,
        t4: `Beleidsregels`,
        t5: `Regels`,
        t6: `Een regel bestaat uit een bereik (bijvoorbeeld projectniveau of organisatieniveau) en een of meer machtigingssets (bijvoorbeeld "alle instanties opnoemen").`,
        t7: `Krijg meer informatie over de regels.`,
        t8: `Regel #1`,
        t9: `Doe`,
        t10: `Organisatie`,
        t11: `De machtigingssets van IAM en facturering kunnen alleen op organisatieniveau worden geselecteerd.`,
        t12: `Toegang tot resources`,
        t13: `Geeft toegang tot resources (instanties, objectopslag, databases, etc.) in uw projecten.`,
        t14: `Toegang tot organisatiefuncties`,
        t15: `Geeft toegang tot IAM, facturering, ondersteuning en misbruiktickets en projectbeheer, alles beheerd op organisatieniveau.`,
        t16: `Valideren`,
        t17: `Machtigingssets`,
        t18: `Organisatiebeheerder`,
        t19: `Regel toevoegen`,
    },
    apis: {
        t1: `Hieronder wordt een lijst van API-sleutels in deze organisatie weergegeven.`,
        t2: `Hoe maak ik API-sleutels?`,
        t3: `API-sleutel genereren`,
        t4: `API's`,
        t5: `Een API-sleutel genereren`,
        t6: `Kopieer en sla uw geheime sleutel op. Deze wordt slechts één keer weergegeven.`,
        t7: `Beheer uw vectoren via IAM-toegang door uw
            API-sleutels toe te voegen om ze te gebruiken`,
        t8: `Toegangs-ID`,
        t9: `Geheime sleutel`,
        t10: `Sluit API-sleutel`,
        t11: `Een API-sleutel genereren`,
        t12: `Selecteer API-sleuteldrager`,
        t13: `Ik zelf (IAM-gebruiker)`,
        t14: `Een toepassing`,
        t15: `Beschrijving`,
        t16: `Verval`,
        t17: `Zal deze API-sleutel worden gebruikt voor objectopslag?`,
        t18: `Ik zelf (IAM-gebruiker)`,
        t19: `Ik zelf (IAM-gebruiker)`,
        t20: `API-sleutel genereren`,
        t21: `Sluit`,
    },
    logs: {
        t1: `Hieronder wordt een lijst van uw IAM-bronlogs weergegeven. IAM-bronnen kunnen gebruikers, toepassingen, groepen, API-sleutels en IAM-beleidsregels zijn. U kunt meer details over elk van hen bekijken.`,
        t2: `Hoe begrijp ik mijn logs?`,
        t3: `Filteren op exacte IAM-bron-ID`,
        t4: `Datum`,
        t5: `IAM-datum`,
        t6: `Categorie`,
        t7: `categorie`,
        t8: `Actie`,
        t9: `Actie`,
        t10: `Toevoegen`,
        t11: `Reset`,
        t12: `Filteren`,
        t13: `Logboeken`,
        t14: `Logboekinformatie`,
        t15: `Status`,
        t16: `Geactiveerd`,
        t17: `Type`,
        t18: `Gast`,
        t19: `Heeft zich aangesloten`,
        t20: `Laatste toegang`,
        t21: `Logboek verwijderen`,
        t22: `Wanneer u een logboek uit deze organisatie verwijdert, worden automatisch zijn API-sleutels verwijderd en elke rechtstreeks aan hen gekoppelde beleidsregel wordt wees.`,
        t23: `Logboek verwijderen`,
    },
}

export default iam