import React, { createContext, useEffect, useRef, useState } from 'react'
import socket from './socket'
import { useDispatch, useSelector } from 'react-redux'
import { setJoinedVideoChat, setSelectedMeet } from '../../slices/meetSlices'
import Peer from 'peerjs'
import './ScreenShake.css'

import { apiUrl } from '@src/apiBackend'

import { Steps, Hints } from 'intro.js-react'
import stepsDefault from '@src/locales/steps/'
import 'intro.js/introjs.css'

import { useParams } from 'react-router-dom'

import { fetchsStep, updateStep } from '../../actions/iam'
import { sendMessage, sendMessageToGptChat } from '../../actions/meet'
import axios from 'axios'
import {
	addFolderLocal,
	createNewFolder,
	getBucketDirectories,
	uploadFile,
} from '../../actions/assets'
import GhostVideo from './components/GhostVideo'
import { promptMessages } from './utils/promptMessages'
import { setNotification } from '../../slices/iamSlice'
import { getUserDesktops } from '../../actions/desktop'
import { setSelectedDesktop } from '../../slices/desktopSlices'

export const ChatContext = createContext()

export const ChatContextProvider = ({ children }) => {
	const [isLeftVisible, setIsLeftVisible] = useState(true)
	const [isRightVisible, setIsRightVisible] = useState(true)
	const [filesToResponse, setFilesToResponse] = useState()
	const [imageToResponse, setImageToResponse] = useState()
	const [popUpToastMessage, setPopUpToastMessage] = useState(
		'Link copiado con exito!'
	)
	const [showDrivePopup, setShowDrivePopup] = useState(false)
	const [expandedVideos, setExpandedVideos] = useState(false)
	const [selectedContext, setSelectedContext] = useState()
	const [onBotCall, setOnBotCall] = useState(false)
	const [isOn, setIsOn] = useState(false)
	const [meetBotTriggered, setMeetBotTriggered] = useState(false)
	const [botPrompt, setBotPrompt] = useState('')
	const [showCalendarPopup, setShowCalendarPopup] = useState(false)
	const [showToast, setShowToast] = useState(false)
	const [toastMessage, setToastMessage] = useState('Configuración guardada!')
	const dispatch = useDispatch()
	const { selectedMeet, joinedVideoChat, allUsers } = useSelector(
		(state) => state.meet
	)
	const { desktops, selectedDesktop } = useSelector((state) => state.desktop)
	const { user } = useSelector((state) => state.iam)

	const [newChatStarted, setNewChatStarted] = useState(false)


	const [isCreateMeetModalOpen, setIsCreateMeetModalOpen] = useState(false)
	const [showCreateMeetingModal, setShowCreateMeetingModal] = useState(false)
	const [onMeetSection, setOnMeetSection] = useState(false)
	const [bubbleChatAsMain, setBubbleChatAsMain] = useState(false) // testing
	const [topIndicatorLocked, setTopIndicatorLocked] = useState(false)
	const [bubbleBottomBarLocked, setBubbleBottomBarLocked] = useState(false)
	const [showRightBar, setShowRightBar] = useState(false)
	const [lockRightBar, setLockRightBar] = useState(false)
	const [clonedStream, setClonedStream] = useState([])
	const [showMediumVideoCards, setShowMediumVideoCards] = useState(false)
	const [showAcceptInvitationModal, setShowAcceptInvitationModal] = useState()
	const [layoutRendered, setLayoutRendered] = useState(false)
	const [renameMeet, setRenameMeet] = useState()
	const [peers, setPeers] = useState([])
	const [focusedVideoCard, setFocusedVideoCard] = useState()
	const [screenShare, setScreenShare] = useState(false)
	const [expandedVideo, setExpandedVideo] = useState()
	const [view, setView] = useState('grid')
	const [userVideoAudio, setUserVideoAudio] = useState({
		localUser: { video: true, audio: true },
	})
	const [hostId, setHostId] = useState(null)

	const [toggleCollabRecording, setToggleCollabRecording] = useState(false)
	const mediaRecorderRef = useRef(null)
	const recordedChunksRef = useRef([])
	const animationFrameIdRef = useRef(null)
	const isFirstRender = useRef(true)
	const audioContextRef = useRef(null)
	const participantsRef = useRef([])
	const audioDestinationRef = useRef(null)
	const audioSourcesRef = useRef([])
	const previousParticipantsCountRef = useRef(0)


	const [responseToMessage, setResponseToMessage] = useState()
	const myVideoRef = useRef(null)
	const myVideoRef1 = useRef(null)
	const myVideoRef2 = useRef(null)
	const peerRef = useRef(null)
	const peersRef = useRef({})
	const secondaryPeersRef = useRef({})
	const screenStreamRef = useRef(null)
	const webcamStreamRef = useRef(null)

	const [mediaRecorder, setMediaRecorder] = useState(null)
	const [isRecording, setIsRecording] = useState(false)
	const [mediaStream, setMediaStream] = useState(null)


	
	const [loadNewContent, setLoadNewContent] = useState(false)

	const [steps, setSteps] = useState([])
	const [stepsEnabled, setStepsEnabled] = useState(true)
	const [initialStep, setInitialStep] = useState(0)
	const [seenSteps, setSeenSteps] = useState(false)

	const { lng, version } = useParams()
	
	
	const handleShowToast = (newToastMessage) => {
		if (newToastMessage) {
			setPopUpToastMessage(newToastMessage)
		}
		setShowToast(true)
	}

	const getParticipants = () => {
		const participants = []

		const localVideoElement = document.getElementById('userMainVideoComponent')
		if (localVideoElement && webcamStreamRef.current) {
			const localUserName = user.user.split('@')[0] || 'You'
			participants.push({
				videoElement: localVideoElement,
				mediaStream: webcamStreamRef.current,
				name: localUserName,
				audioEnabled: userVideoAudio.localUser.audio,
			})
		}

		const peerIds = Object.keys(peersRef.current)
		peerIds.forEach((peerId) => {
			const peerData = peersRef.current[peerId]
			if (peerData) {
				const peerVideoElement =
					document.getElementById(`remoteVideo_${peerId}`) ||
					document.getElementById(`bubbleRemoteVideo_${peerId}`)

				const peerMediaStream = peerData.userVideoStream

				const peerUser = allUsers.find((user) => user.id === peerId)
				const peerName = peerUser
					? peerUser.user.split('@')[0]
					: `Peer ${peerId}`

				if (peerVideoElement && peerMediaStream) {
					participants.push({
						videoElement: peerVideoElement,
						mediaStream: peerMediaStream,
						name: peerName,
						audioEnabled: userVideoAudio[peerId]?.audio,
					})
				}
			}
		})

		return participants
	}

	const updateAudioConnections = (participants) => {
		audioSourcesRef.current.forEach((source) => {
			source.disconnect()
		})
		audioSourcesRef.current = []

		participants.forEach((participant) => {
			const { mediaStream, audioEnabled } = participant
			if (mediaStream && audioEnabled !== false) {
				const audioTracks = mediaStream.getAudioTracks()
				if (audioTracks.length > 0) {
					const stream = new MediaStream()
					audioTracks.forEach((track) => stream.addTrack(track))
					const source = audioContextRef.current.createMediaStreamSource(stream)
					source.connect(audioDestinationRef.current)
					audioSourcesRef.current.push(source)
				}
			}
		})
	}

	const startCollabRecording = async () => {
		const canvas = document.createElement('canvas')
		const ctx = canvas.getContext('2d')
		canvas.width = 1280
		canvas.height = 720

		audioContextRef.current = new (window.AudioContext ||
			window.webkitAudioContext)()
		audioDestinationRef.current =
			audioContextRef.current.createMediaStreamDestination()

		const canvasStream = canvas.captureStream(30)
		const combinedStream = new MediaStream()

		canvasStream.getVideoTracks().forEach((track) => {
			combinedStream.addTrack(track)
		})

		if (audioDestinationRef.current.stream.getAudioTracks().length > 0) {
			audioDestinationRef.current.stream.getAudioTracks().forEach((track) => {
				combinedStream.addTrack(track)
			})
		}

		const mediaRecorder = new MediaRecorder(combinedStream, {
			mimeType: 'video/webm; codecs=vp9,opus',
		})

		const recordedChunks = []
		mediaRecorder.ondataavailable = (e) => {
			if (e.data && e.data.size > 0) {
				recordedChunks.push(e.data)
			}
		}

		let transcriptionData1 = null

		mediaRecorder.onstop = async () => {
			try {
				if (recordedChunks.length > 0) {
					const date = new Date()
					const ddmmyyyy = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`
					const dateTime = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}_${date.getHours().toString().padStart(2, '0')}-${date.getMinutes().toString().padStart(2, '0')}-${date.getSeconds().toString().padStart(2, '0')}`

					const blob = new Blob(recordedChunks, { type: 'video/webm' })
					recordedChunksRef.current = []

					const videoFile = new File([blob], `collab-${dateTime}.webm`, {
						type: 'video/webm',
					})

					const videoUrl = URL.createObjectURL(videoFile)
					const videoLink = document.createElement('a')
					videoLink.href = videoUrl
					videoLink.download = videoFile.name
					videoLink.click()

					URL.revokeObjectURL(videoUrl)

					try {
						const audioBlob = await extractAudioFromVideoBlob(blob)
						transcriptionData1 = await sendAudioToServer(audioBlob)

						if (transcriptionData1) {
							const transcription = transcriptionData1
							const transcriptionFile = new File(
								[transcription],
								`transcription-${dateTime}.json`,
								{ type: 'application/json' }
							)

							const transcriptionUrl = URL.createObjectURL(transcriptionFile)
							const transcriptionLink = document.createElement('a')
							transcriptionLink.href = transcriptionUrl
							transcriptionLink.download = transcriptionFile.name
							transcriptionLink.click()

							const peersToSend = selectedMeet.participants

							const uploadPromises = []

							peersToSend.forEach((peerId) => {
								const folderPromise = dispatch(
									createNewFolder({ name: 'Meet', driveId: user.id })
								)
								const folderPromise1 = dispatch(
									createNewFolder({
										name: `Meet/${selectedMeet.meetName.split(' ').join('_')}`,
										driveId: user.id,
									})
								)
								const folderPromise2 = dispatch(
									createNewFolder({
										name: `Meet/${selectedMeet.meetName.split(' ').join('_')}/files`,
										driveId: user.id,
									})
								)
								const folderPromise3 = dispatch(
									createNewFolder({
										name: `Meet/${selectedMeet.meetName.split(' ').join('_')}/files/${ddmmyyyy}`,
										driveId: user.id,
									})
								)

								uploadPromises.push(folderPromise)
								uploadPromises.push(folderPromise1)
								uploadPromises.push(folderPromise2)
								uploadPromises.push(folderPromise3)

								const videoUploadPromise = dispatch(
									uploadFile({
										file: videoFile,
										pathDepured: `Meet/${selectedMeet.meetName.split(' ').join('_')}/files/${ddmmyyyy}`,
										driveId: peerId,
									})
								)
								uploadPromises.push(videoUploadPromise)

								const transcriptionUploadPromise = dispatch(
									uploadFile({
										file: transcriptionFile,
										pathDepured: `Meet/${selectedMeet.meetName.split(' ').join('_')}/files/${ddmmyyyy}`,
										driveId: peerId,
									})
								)
								uploadPromises.push(transcriptionUploadPromise)
							})

							const videoUrl = URL.createObjectURL(videoFile)
							const videoLink = document.createElement('a')
							videoLink.href = videoUrl
							videoLink.download = videoFile.name
							videoLink.click()

							const audioBlob = await extractAudioFromVideoBlob(blob)
							const transcriptionData = await sendAudioToServer(audioBlob)

							if (transcriptionData) {
								const transcriptionFile = new File(
									[JSON.stringify(transcriptionData, null, 2)],
									`transcription-${dateTime}.json`,
									{ type: 'application/json' }
								)

								const transcriptionUrl = URL.createObjectURL(transcriptionFile)
								const transcriptionLink = document.createElement('a')
								transcriptionLink.href = transcriptionUrl
								transcriptionLink.download = transcriptionFile.name
								transcriptionLink.click()

								const peersToSend = selectedMeet.participants

								const uploadPromises = []

								peersToSend.forEach((peerId) => {
									const folderPromise = handleCreateNewFolder({
										mainFolder: 'Meet',
										subFolder: `${selectedMeet.meetName.split(' ').join('_')}/files/${ddmmyyyy}`,
										userId: peerId,
									})

									uploadPromises.push(folderPromise)

									const videoUploadPromise = dispatch(
										uploadFile({
											file: videoFile,
											pathDepured: `Meet/${selectedMeet.meetName.split(' ').join('_')}/files/${ddmmyyyy}`,
											driveId: peerId,
										})
									)
									uploadPromises.push(videoUploadPromise)

									const transcriptionUploadPromise = dispatch(
										uploadFile({
											file: transcriptionFile,
											pathDepured: `Meet/${selectedMeet.meetName.split(' ').join('_')}/files/${ddmmyyyy}`,
											driveId: peerId,
										})
									)
									uploadPromises.push(transcriptionUploadPromise)
								})

								Promise.all(uploadPromises)
									.then(() => {
										dispatch(
											setNotification({
												status: 300,
												text: 'Se ha subido el video resumen del meet junto con la conversacion a texto a su drive!',
											})
										)
										dispatch(
											getBucketDirectories({ driveId: user.id, type: 'all' })
										)
									})
									.catch((error) => {
										console.error('Error uploading files:', error)
										dispatch(
											setNotification({
												status: 500,
												text: 'Hubo un error al subir los archivos al drive.',
											})
										)
									})
							}
						}

						if (audioContextRef.current) {
							audioContextRef.current.close()
							audioContextRef.current = null
						}
					} catch (error) {
						console.error('Error processing transcription:', error)
						dispatch(
							setNotification({
								status: 500,
								text: 'Error al procesar la transcripción del video.',
							})
						)
					}
				}
			} catch (err) {
				console.error('Error stopping media recorder:', err)
			}
		}

		mediaRecorder.start()
		mediaRecorderRef.current = mediaRecorder
		recordedChunksRef.current = recordedChunks

		drawVideos(canvas, ctx)
	}

	const drawVideos = (canvas, ctx) => {
		const participants = getParticipants()

		if (participants.length !== previousParticipantsCountRef.current) {
			updateAudioConnections(participants)
			previousParticipantsCountRef.current = participants.length
		}

		const positions = calculateVideoPositions(
			participants.length,
			canvas.width,
			canvas.height
		)

		ctx.clearRect(0, 0, canvas.width, canvas.height)

		participants.forEach((participant, index) => {
			const { videoElement, name } = participant
			if (videoElement && videoElement.readyState >= 2) {
				const { x, y, width, height } = positions[index]

				ctx.save()
				ctx.beginPath()
				ctx.moveTo(x + 20, y)
				ctx.arcTo(x + width, y, x + width, y + height, 20)
				ctx.arcTo(x + width, y + height, x, y + height, 20)
				ctx.arcTo(x, y + height, x, y, 20)
				ctx.arcTo(x, y, x + width, y, 20)
				ctx.closePath()
				ctx.clip()

				ctx.drawImage(videoElement, x, y, width, height)
				ctx.restore()

				ctx.fillStyle = 'rgba(128, 128, 128, 0.7)'
				ctx.strokeStyle = 'transparent'
				ctx.lineWidth = 0
				ctx.textAlign = 'left'
				ctx.font = '16px Arial'

				const labelWidth = ctx.measureText(name).width + 20
				const labelHeight = 30
				const labelX = x + 10
				const labelY = y + height - labelHeight - 10

				ctx.save()
				ctx.beginPath()
				ctx.moveTo(labelX + 10, labelY)
				ctx.arcTo(
					labelX + labelWidth,
					labelY,
					labelX + labelWidth,
					labelY + labelHeight,
					10
				)
				ctx.arcTo(
					labelX + labelWidth,
					labelY + labelHeight,
					labelX,
					labelY + labelHeight,
					10
				)
				ctx.arcTo(labelX, labelY + labelHeight, labelX, labelY, 10)
				ctx.arcTo(labelX, labelY, labelX + labelWidth, labelY, 10)
				ctx.closePath()
				ctx.fill()
				ctx.restore()

				ctx.fillStyle = 'white'
				ctx.fillText(name, labelX + 10, labelY + 20)
			}
		})

		animationFrameIdRef.current = requestAnimationFrame(() =>
			drawVideos(canvas, ctx)
		)
	}

	const stopCollabRecording = () => {
		if (mediaRecorderRef.current) {
			mediaRecorderRef.current.stop()
			mediaRecorderRef.current = null
		}

		if (animationFrameIdRef.current) {
			cancelAnimationFrame(animationFrameIdRef.current)
			animationFrameIdRef.current = null
		}

		if (audioContextRef.current) {
			audioContextRef.current.close()
			audioContextRef.current = null
		}
	}

	const downloadFile = (url, filename) => {
		const a = document.createElement('a')
		document.body.appendChild(a)
		a.style = 'display: none'
		a.href = url
		a.download = filename
		a.click()
		window.URL.revokeObjectURL(url)
		document.body.removeChild(a)
	}

	const extractAudioFromVideoBlob = async (videoBlob) => {
		return new Promise((resolve, reject) => {
			const videoUrl = URL.createObjectURL(videoBlob)

			const audioContext = new (window.AudioContext ||
				window.webkitAudioContext)()

			const video = document.createElement('video')
			video.src = videoUrl
			video.crossOrigin = 'anonymous'

			video.oncanplaythrough = () => {
				const source = audioContext.createMediaElementSource(video)
				const destination = audioContext.createMediaStreamDestination()
				source.connect(destination)

				const recorder = new MediaRecorder(destination.stream)
				const audioChunks = []

				recorder.ondataavailable = (e) => {
					if (e.data.size > 0) {
						audioChunks.push(e.data)
					}
				}

				recorder.onstop = () => {
					const audioBlob = new Blob(audioChunks, { type: 'audio/webm' })
					resolve(audioBlob)
					audioContext.close()
					URL.revokeObjectURL(videoUrl)
				}

				video.play().then(() => {
					recorder.start()
					video.onended = () => {
						recorder.stop()
					}
				})
			}

			video.onerror = (error) => {
				reject('Error loading video for audio extraction')
			}
		})
	}

	const sendAudioToServer = async (audioBlob) => {
		try {
			const formData = new FormData()
			formData.append('audio_file', audioBlob, 'audio.webm')
			const response = await axios.post(
				`${apiUrl}/api/v1/transcribe`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			)

			const transcriptionData = JSON.stringify(response.data, null, 2)
			return transcriptionData
		} catch (error) {
			console.error('Error sending audio to server:', error)
			return null
		}
	}

	const calculateVideoPositions = (numVideos, canvasWidth, canvasHeight) => {
		const positions = []
		const padding = 20

		if (numVideos === 1) {
			positions.push({
				x: padding,
				y: padding,
				width: canvasWidth - 2 * padding,
				height: canvasHeight - 2 * padding,
			})
		} else {
			const cols = Math.ceil(Math.sqrt(numVideos))
			const rows = Math.ceil(numVideos / cols)
			const totalPaddingX = (cols + 1) * padding
			const totalPaddingY = (rows + 1) * padding
			const videoWidth = (canvasWidth - totalPaddingX) / cols
			const videoHeight = (canvasHeight - totalPaddingY) / rows

			for (let i = 0; i < numVideos; i++) {
				const col = i % cols
				const row = Math.floor(i / cols)
				const x = padding + col * (videoWidth + padding)
				const y = padding + row * (videoHeight + padding)
				positions.push({ x, y, width: videoWidth, height: videoHeight })
			}
		}

		return positions
	}






	const cloneMediaStream = (stream) => {
		const clonedStream = new MediaStream()
		stream.getTracks().forEach((track) => {
			clonedStream.addTrack(track.clone())
		})
		return clonedStream
	}

	


	const connectToNewUser = (userId, stream) => {
		if (!peersRef.current[userId]) {
			const call = peerRef.current.call(userId, stream)

			call.on('stream', (userVideoStream) => {
				peersRef.current[userId] = { call, userVideoStream }

				setUserVideoAudio((prevList) => ({
					...prevList,
					[userId]: { video: true, audio: true },
				}))
			})

			call.on('close', () => {
				if (peersRef.current[userId]) {
					delete peersRef.current[userId]
				}
				setUserVideoAudio((prevList) => {
					const updatedList = { ...prevList }
					delete updatedList[userId]
					return updatedList
				})
			})
		}
	}

	const stopVideoChat = (fromBubble) => {
		try {
			if (myVideoRef.current && myVideoRef.current.srcObject) {
				myVideoRef.current.srcObject
					.getTracks()
					.forEach((track) => track.stop())
			}

			Object.keys(peersRef.current).forEach((peerId) => {
				if (peersRef.current[peerId].call) {
					peersRef.current[peerId].call.close() // Close the call
				}
			})

			if (peerRef.current) {
				peerRef.current.destroy() 
			}

			if (toggleCollabRecording) {
				setToggleCollabRecording(false)
			}

			socket.emit('leave-room', { roomId: selectedMeet.id, userId: user.id })

			socket.off('user-connected')
			socket.off('user-disconnected')
			socket.off('audio-toggle')
			socket.off('video-toggle')

			setUserVideoAudio({
				localUser: { video: true, audio: true },
			})

			dispatch(setJoinedVideoChat(false))
			if (fromBubble) {
				dispatch(setSelectedMeet())
			}
		} catch (error) {
			console.error('Error while stopping video chat:', error)
		}
	}

	const expandedVideoHandler = (user) => {
		if (!user) {
			return
		}
		if (user === 'user') {
			if (expandedVideo === 'user') {
				setExpandedVideo()
				return
			}
			setExpandedVideo('user')
			return
		}
		if (expandedVideo === user) {
			setExpandedVideo()
			return
		}
		setExpandedVideo(user)
	}

	const expandPeerVideo = (peerID) => {
		const elem = document.getElementById(`remoteVideo_${peerID}`)
		if (elem.requestFullscreen) {
			elem.requestFullscreen()
		} else if (elem.mozRequestFullScreen) {
			elem.mozRequestFullScreen()
		} else if (elem.webkitRequestFullscreen) {
			elem.webkitRequestFullscreen()
		} else if (elem.msRequestFullscreen) {
			elem.msRequestFullscreen()
		}
	}

	const toggleAudio = () => {
		if (myVideoRef.current && myVideoRef.current.srcObject) {
			const audioTrack = myVideoRef.current.srcObject.getAudioTracks()[0]
			if (audioTrack) {
				audioTrack.enabled = !audioTrack.enabled

				socket.emit('audio-toggle', {
					userId: user.id,
					audio: audioTrack.enabled,
				})

				setUserVideoAudio((prevState) => ({
					...prevState,
					localUser: {
						...prevState.localUser,
						audio: audioTrack.enabled,
					},
				}))
			}
		}
	}

	const toggleVideo = () => {

		if (myVideoRef.current && myVideoRef.current.srcObject) {
			const videoTrack = myVideoRef.current.srcObject.getVideoTracks()[0]
			if (videoTrack) {
				videoTrack.enabled = !videoTrack.enabled


				socket.emit('video-toggle', {
					userId: user.id,
					video: videoTrack.enabled,
				})

				setUserVideoAudio((prevState) => ({
					...prevState,
					localUser: {
						...prevState.localUser,
						video: videoTrack.enabled,
					},
				}))
			}
		}
	}

	const toggleMuteLocally = (userId) => {
		const remoteVideo = document.getElementById(`remoteVideo_${userId}`)
		if (remoteVideo) {
			remoteVideo.muted = !remoteVideo.muted
		}
	}

	const toggleScreenShare = async () => {
		if (!screenShare) {
			try {
				const screenStream = await navigator.mediaDevices.getDisplayMedia({
					video: true,
				})

				if (myVideoRef.current) {
					myVideoRef.current.srcObject = screenStream
					myVideoRef.current.onloadedmetadata = () => {
						myVideoRef.current.play().catch((error) => {
							console.error('Error playing screen share video:', error)
						})
					}
				}

				for (let peerId in peersRef.current) {
					const sender = peersRef.current[peerId].call.peerConnection
						.getSenders()
						.find((s) => s.track.kind === 'video')
					if (sender) {
						sender.replaceTrack(screenStream.getVideoTracks()[0])
					}
				}

				screenStreamRef.current = screenStream
				screenStreamRef.current.getVideoTracks()[0].onended = () => {
					stopScreenShare() // Stop screen sharing when user stops it manually
				}

				setScreenShare(true)
			} catch (error) {
				console.error('Error starting screen share:', error)
			}
		} else {
			stopScreenShare()
		}
	}

	const stopScreenShare = () => {
		if (screenStreamRef.current) {
			screenStreamRef.current.getTracks().forEach((track) => track.stop())
		}

		if (webcamStreamRef.current) {
			if (myVideoRef.current) {
				myVideoRef.current.srcObject = webcamStreamRef.current
				myVideoRef.current.onloadedmetadata = () => {
					myVideoRef.current.play().catch((error) => {
						console.error('Error playing webcam video:', error)
					})
				}
			}

			const videoTrack = webcamStreamRef.current
				.getVideoTracks()
				.find((track) => track.kind === 'video')

			if (videoTrack) {
				for (let peerId in peersRef.current) {
					const sender = peersRef.current[peerId].call.peerConnection
						.getSenders()
						.find((s) => s.track.kind === 'video')
					if (sender) {
						sender.replaceTrack(videoTrack)
					}
				}
			}
		}

		setScreenShare(false)
	}

	const handleShake = () => {
		document.body.classList.add('shake')
		setTimeout(() => {
			document.body.classList.remove('shake')
		}, 500) 
	}

	const triggerShake = () => {
		if (selectedMeet) {
			const roomId = selectedMeet.id
			socket.emit('shake-screen', roomId)
		}
	}

	const startRecording = async () => {
		try {
			const audioStream = await navigator.mediaDevices.getUserMedia({
				audio: true,
			})
			const screenStream = await navigator.mediaDevices.getDisplayMedia({
				video: true,
			})

			const combinedStream = new MediaStream()
			screenStream
				.getTracks()
				.forEach((track) => combinedStream.addTrack(track))
			audioStream.getTracks().forEach((track) => combinedStream.addTrack(track))

			if (myVideoRef.current) {
				myVideoRef.current.srcObject = combinedStream
				myVideoRef.current.onloadedmetadata = () => {
					myVideoRef.current.play().catch((error) => {
						console.error('Error playing combined video:', error)
					})
				}
			}

			const screenTrack = combinedStream.getVideoTracks()[0]
			for (let peerId in peersRef.current) {
				const sender = peersRef.current[peerId].call.peerConnection
					.getSenders()
					.find((s) => s.track.kind === 'video')
				if (sender) {
					sender.replaceTrack(screenTrack)
				}
			}

			const recorder = new MediaRecorder(combinedStream, {
				mimeType: 'video/webm',
			})
			const recordedChunks = []

			recorder.ondataavailable = (e) => {
				if (e.data.size > 0) {
					recordedChunks.push(e.data)
				}
			}

			recorder.onstop = async () => {
				const blob = new Blob(recordedChunks, { type: 'video/webm' })
				const url = URL.createObjectURL(blob)
				const a = document.createElement('a')
				a.href = url
				a.download = 'recordedScreen.mp4'
				document.body.appendChild(a)
				a.click()
				window.URL.revokeObjectURL(url)

				setMediaStream(null)
				for (let peerId in peersRef.current) {
					const sender = peersRef.current[peerId].call.peerConnection
						.getSenders()
						.find((s) => s.track.kind === 'video')
					if (sender) {
						sender.replaceTrack(webcamStreamRef.current.getVideoTracks()[0])
					}
				}
				if (myVideoRef.current) {
					myVideoRef.current.srcObject = webcamStreamRef.current
				}
			}

			recorder.start()
			setIsRecording(true)
			setMediaRecorder(recorder)
			setMediaStream(combinedStream)
		} catch (error) {
			console.error('Error starting recording:', error)
		}
	}

	const stopRecording = () => {
		if (mediaRecorder && mediaRecorder.state !== 'inactive') {
			mediaRecorder.stop()
			setIsRecording(false)
			if (mediaStream) {
				mediaStream.getTracks().forEach((track) => track.stop())
			}
		}
	}
	
	const handleStepChange = async (nextStepIndex) => {
		const seenStep = steps[nextStepIndex]
		const step = seenStep.element.replace(/.*data-step="([^"]+)".*/, '$1')

		const resp = await dispatch(
			updateStep({
				element: step,
			})
		)
	}

	const onExit = () => {
	}

	const handleToolClick = async (tool) => {
		if (!selectedMeet) {
			console.error('No chat selected.')
			return
		}

		const promptMessage =
			promptMessages[tool]?.prompt ||
			'Hola, estoy aquí para ayudarte con cualquier pregunta que tengas.'

		dispatch(
			sendMessage({
				meetId: selectedMeet.id,
				type: 'bot',
				senderId: `bot-textToText-${selectedMeet.id}`,
				senderName: 'Aythen BOT',
				senderPicture: '',
				message: promptMessage,
				selectedTool: tool || 'text2text',
				hasFiles: false,
				files: [],
			})
		)
	}


	const handleCreateNewFolder = async ({ mainFolder, subFolder, userId }) => {
		if (!mainFolder.trim() && !subFolder.trim()) {
			return
		}

		const folderPath = !mainFolder
			? `${subFolder}`
			: `${mainFolder}/${subFolder}`
		const pathDepured = folderPath.replace(/\/\/+/g, '/')
		await dispatch(createNewFolder({ name: pathDepured, driveId: userId }))
		dispatch(addFolderLocal(pathDepured + '/', new Date().getTime()))
	}


	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false
			return
		}

		if (toggleCollabRecording) {
			startCollabRecording()
		} else {
			stopCollabRecording()
		}
	}, [toggleCollabRecording])

	
	useEffect(() => {
		if (!toggleCollabRecording && user && hostId === user.id) {
			setToggleCollabRecording(true)
		}
	}, [hostId])

	useEffect(() => {
		if (joinedVideoChat) {
			navigator.mediaDevices
				.getUserMedia({
					video: true,
					audio: true,
				})
				.then((stream) => {
					webcamStreamRef.current = stream
					if (myVideoRef.current) {
						myVideoRef.current.srcObject = stream
						myVideoRef.current.onloadedmetadata = () => {
							myVideoRef.current.play().catch((error) => {
								console.error('Error playing your video:', error)
							})
						}
					}

					peerRef.current = new Peer(user.id)

					peerRef.current.on('open', (id) => {
						socket.emit('join-room', { roomId: selectedMeet.id, userId: id })
					})

					peerRef.current.on('call', (call) => {
						call.answer(stream)
						call.on('stream', (userVideoStream) => {
							peersRef.current[call.peer] = { call, userVideoStream }
							setUserVideoAudio((prevList) => ({
								...prevList,
								[call.peer]: { video: true, audio: true },
							}))
						})
					})

					socket.on('host-assigned', (newHostId) => {
						setHostId(newHostId)
					})

					socket.on('user-connected', (userId) => {
						connectToNewUser(userId, stream)
					})

					socket.on('user-disconnected', (userId) => {
						if (peersRef.current[userId]) {
							peersRef.current[userId].call.close()
							delete peersRef.current[userId]
						}
						setUserVideoAudio((prevList) => {
							const updatedList = { ...prevList }
							delete updatedList[userId]
							return updatedList
						})
					})

					socket.on('audio-toggle', ({ userId, audio }) => {
						setUserVideoAudio((prevList) => ({
							...prevList,
							[userId]: { ...prevList[userId], audio },
						}))
					})

					socket.on('video-toggle', ({ userId, video }) => {
						setUserVideoAudio((prevList) => ({
							...prevList,
							[userId]: { ...prevList[userId], video },
						}))
					})
				})
				.catch((error) => {
					console.error('Error accessing media devices:', error)
				})

			return () => {
				socket.emit('leave-room', { roomId: selectedMeet.id, userId: user.id })
				if (peerRef.current) {
					peerRef.current.destroy()
				}
				socket.off('host-assigned')
				socket.off('user-connected')
				socket.off('user-disconnected')
				socket.off('audio-toggle')
				socket.off('video-toggle')
			}
		}
	}, [joinedVideoChat])

	useEffect(() => {
		socket.on('shake-screen', () => {
			handleShake()
		})

		return () => {
			socket.off('shake-screen')
		}
	}, [])

	useEffect(() => {
		if (seenSteps) {
			let data = []
			stepsDefault[lng]
				.filter((step, index) => {
					const match = step.element.match(/data-step="([^"]+)"/)
					const stepValue = match ? match[1] : null // Get the value inside the quotes or null if no match

					if (stepValue) {
						return !seenSteps.includes(stepValue)
					}

					return false 
				})
				.forEach((step) => {
					const el = document.querySelector(step.element) // Query the DOM for the element
					if (el) {
						data.push(step) 
					}
				})

			setSteps(data)
		}
	}, [loadNewContent, seenSteps])

	useEffect(() => {
		console.log('getting user desktops')
		if(user) {
			dispatch(getUserDesktops({ userId: user.id }))
		}
	}, [user])

	// useEffect(() => {
	// 	if (desktops.length > 0 && !selectedDesktop) {
	// 		const defaultDesktop = desktops.filter((desktop) =>
	// 			desktop.id.includes('default')
	// 		)
	// 		dispatch(setSelectedDesktop(defaultDesktop))
	// 	}
	// }, [desktops])

	useEffect(() => {
		const fetchsItemStep = async () => {
			const resp = await dispatch(fetchsStep())

			if (resp && resp.payload) {
				setSeenSteps(resp.payload)
			}
		}

		fetchsItemStep()
	}, [])

	const [hoveredItem, setHoveredItem] = useState()

	return (
		<ChatContext.Provider
			value={{
				hoveredItem,
				setHoveredItem,
				toggleCollabRecording,
				myVideoRef1,
				myVideoRef2,
				setToggleCollabRecording,
				setLoadNewContent,
				handleCreateNewFolder,
				handleToolClick,
				loadNewContent, 
				newChatStarted,
				setNewChatStarted,
				bubbleChatAsMain,
				bubbleBottomBarLocked,
				setBubbleBottomBarLocked,
				setBubbleChatAsMain,
				onBotCall,
				setOnBotCall,
				focusedVideoCard,
				showRightBar,
				toggleMuteLocally,
				setShowRightBar,
				lockRightBar,
				setLockRightBar,
				setFocusedVideoCard,
				screenShare,
				setScreenShare,
				handleShake,
				expandedVideo,
				toastMessage,
				setToastMessage,
				setExpandedVideo,
				cloneMediaStream,
				showToast,
				setShowToast,
				showCalendarPopup,
				setShowCalendarPopup,
				view,
				setView,
				userVideoAudio,
				responseToMessage,
				setResponseToMessage,
				setUserVideoAudio,
				expandedVideoHandler,
				layoutRendered,
				setLayoutRendered,
				expandPeerVideo,
				meetBotTriggered,
				setMeetBotTriggered,
				topIndicatorLocked,
				setTopIndicatorLocked,
				myVideoRef,
				isCreateMeetModalOpen,
				setIsCreateMeetModalOpen,
				secondaryPeersRef,
				clonedStream,
				setClonedStream,
				showMediumVideoCards,
				isOn,
				expandedVideos,
				setExpandedVideos,
				setIsOn,
				toggleScreenShare,
				setShowMediumVideoCards,
				showAcceptInvitationModal,
				setShowAcceptInvitationModal,
				showDrivePopup,
				setShowDrivePopup,
				showCreateMeetingModal,
				botPrompt,
				setBotPrompt,
				setShowCreateMeetingModal,
				peers,
				setPeers,
				peerRef,
				popUpToastMessage,
				setPopUpToastMessage,
				peersRef,
				connectToNewUser,
				stopVideoChat,
				triggerShake,
				toggleAudio,
				toggleVideo,
				startRecording,
				stopRecording,
				isRecording,
				handleShowToast,
				onMeetSection,
				setOnMeetSection,
				renameMeet,
				setRenameMeet,
				selectedContext,
				setSelectedContext,
				filesToResponse,
				setFilesToResponse,
				isLeftVisible,
				setIsLeftVisible,
				isRightVisible,
				setIsRightVisible,
				imageToResponse,
				setImageToResponse,
			}}
		>
			<video
				ref={myVideoRef}
				muted
				autoPlay
				playsInline
				style={{
					display: 'none',
				}}
			></video>
			{Object.keys(peersRef.current).map((userId) => (
				<GhostVideo
					key={userId}
					stream={peersRef.current[userId].userVideoStream}
				/>
			))}
			{children}

			<Steps
				enabled={stepsEnabled}
				steps={steps}
				initialStep={initialStep}
				onExit={onExit}
				showProgress={false}
				onBeforeChange={handleStepChange}
			/>
			<Hints
				enabled={stepsEnabled}
				hints={steps.map((step) => ({
					element: step.element,
					hint: step.intro,
				}))}
			/>
		</ChatContext.Provider>
	)
}
