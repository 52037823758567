import React from "react";

const Background = () => {
  return (
    <div className="background-lambda">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4604.63 1040.63">
        <style>
          {`
.cls-111{
  fill: var(--color-primary-6);
  opacity: 0.5;
  animation: changeBackgroundAndColor 20s infinite alternate;
}      
@keyframes changeBackgroundAndColor {
  0% {
    fill: var(--color-primary-7);
  }
  25% {
    fill: var(--color-primary-6);
  }
  50% {
    fill: var(--color-primary-7);
  }
  75% {
    fill: var(--color-primary-6);
  }
  100% {
    fill: var(--color-primary-7);
  }
}
      `}
        </style>
        <g id="Capa_1-2" data-name="Capa 1">
          <g className="cls-111">
            <g id="items">
              <g
                id="_Repetición_de_cuadrícula_"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m13.72.2L0,14.97h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27C51.78,28.7,43,10.02,40.72,5.33c-.47-.97-1.31-2.34-2.77-3.45C35.18-.23,31.94-.05,28.65.06c-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-2"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m147.01.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-3"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m280.3.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-4"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m413.59.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-5"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m546.88.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-6"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m680.17.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-7"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m813.46.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-8"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m946.75.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-9"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1080.04.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-10"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1213.33.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-11"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1346.62.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-12"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1479.91.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-13"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1613.2.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-14"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1746.49.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-15"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1879.78.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-16"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2013.07.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-17"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2146.36.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-18"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2279.65.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-19"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2412.94.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-20"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2546.23.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-21"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2679.52.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-22"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2812.81.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-23"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2946.1.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-24"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3079.39.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-25"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3212.68.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-26"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3345.97.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-27"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3479.26.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-28"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3612.55.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-29"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3745.84.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-30"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3879.13.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-31"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4012.42.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-32"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4145.71.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-33"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4279,.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-34"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4412.29.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-35"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4545.58.2l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-36"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m13.72,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-37"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m147.01,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-38"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m280.3,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-39"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m413.59,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-40"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m546.88,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-41"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m680.17,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-42"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m813.46,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-43"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m946.75,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-44"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1080.04,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-45"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1213.33,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-46"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1346.62,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-47"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1479.91,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-48"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1613.2,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-49"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1746.49,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-50"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1879.78,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-51"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2013.07,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-52"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2146.36,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-53"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2279.65,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-54"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2412.94,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-55"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2546.23,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-56"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2679.52,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-57"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2812.81,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-58"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2946.1,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-59"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3079.39,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-60"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3212.68,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-61"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3345.97,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-62"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3479.26,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-63"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3612.55,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-64"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3745.84,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-65"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3879.13,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-66"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4012.42,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-67"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4145.71,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-68"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4279,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-69"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4412.29,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-70"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4545.58,114.12l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-71"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m13.72,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-72"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m147.01,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-73"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m280.3,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-74"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m413.59,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-75"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m546.88,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-76"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m680.17,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-77"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m813.46,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-78"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m946.75,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-79"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1080.04,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-80"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1213.33,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-81"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1346.62,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-82"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1479.91,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-83"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1613.2,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-84"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1746.49,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-85"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1879.78,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-86"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2013.07,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-87"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2146.36,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-88"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2279.65,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-89"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2412.94,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-90"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2546.23,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-91"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2679.52,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-92"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2812.81,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-93"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2946.1,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-94"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3079.39,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-95"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3212.68,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-96"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3345.97,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-97"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3479.26,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-98"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3612.55,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-99"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3745.84,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-100"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3879.13,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-101"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4012.42,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-102"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4145.71,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-103"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4279,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-104"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4412.29,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-105"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4545.58,228.04l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-106"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m13.72,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-107"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m147.01,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-108"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m280.3,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-109"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m413.59,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-110"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m546.88,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-111"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m680.17,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-112"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m813.46,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-113"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m946.75,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-114"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1080.04,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-115"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1213.33,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-116"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1346.62,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-117"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1479.91,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-118"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1613.2,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-119"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1746.49,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-120"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1879.78,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-121"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2013.07,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-122"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2146.36,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-123"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2279.65,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-124"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2412.94,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-125"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2546.23,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-126"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2679.52,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-127"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2812.81,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-128"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2946.1,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-129"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3079.39,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-130"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3212.68,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-131"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3345.97,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-132"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3479.26,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-133"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3612.55,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-134"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3745.84,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-135"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3879.13,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-136"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4012.42,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-137"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4145.71,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-138"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4279,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-139"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4412.29,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-140"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4545.58,341.96l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-141"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m13.72,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-142"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m147.01,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-143"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m280.3,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-144"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m413.59,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-145"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m546.88,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-146"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m680.17,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-147"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m813.46,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-148"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m946.75,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-149"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1080.04,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-150"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1213.33,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-151"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1346.62,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-152"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1479.91,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-153"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1613.2,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-154"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1746.49,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-155"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1879.78,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-156"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2013.07,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-157"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2146.36,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-158"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2279.65,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-159"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2412.94,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-160"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2546.23,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-161"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2679.52,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-162"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2812.81,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-163"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2946.1,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-164"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3079.39,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-165"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3212.68,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-166"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3345.97,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-167"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3479.26,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-168"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3612.55,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-169"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3745.84,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-170"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3879.13,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-171"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4012.42,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-172"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4145.71,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-173"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4279,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-174"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4412.29,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-175"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4545.58,455.88l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-176"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m13.72,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-177"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m147.01,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-178"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m280.3,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-179"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m413.59,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-180"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m546.88,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-181"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m680.17,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-182"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m813.46,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-183"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m946.75,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-184"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1080.04,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-185"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1213.33,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-186"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1346.62,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-187"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1479.91,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-188"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1613.2,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-189"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1746.49,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-190"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1879.78,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-191"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2013.07,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-192"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2146.36,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-193"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2279.65,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-194"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2412.94,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-195"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2546.23,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-196"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2679.52,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-197"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2812.81,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-198"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2946.1,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-199"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3079.39,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-200"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3212.68,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-201"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3345.97,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-202"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3479.26,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-203"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3612.55,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-204"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3745.84,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-205"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3879.13,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-206"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4012.42,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-207"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4145.71,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-208"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4279,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-209"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4412.29,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-210"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4545.58,569.8l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-211"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m13.72,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-212"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m147.01,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-213"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m280.3,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-214"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m413.59,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-215"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m546.88,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-216"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m680.17,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-217"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m813.46,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-218"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m946.75,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-219"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1080.04,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-220"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1213.33,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-221"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1346.62,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-222"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1479.91,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-223"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1613.2,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-224"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1746.49,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-225"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1879.78,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-226"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2013.07,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-227"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2146.36,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-228"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2279.65,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-229"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2412.94,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-230"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2546.23,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-231"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2679.52,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-232"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2812.81,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-233"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2946.1,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-234"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3079.39,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-235"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3212.68,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-236"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3345.97,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-237"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3479.26,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-238"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3612.55,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-239"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3745.84,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-240"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3879.13,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-241"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4012.42,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-242"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4145.71,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-243"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4279,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-244"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4412.29,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-245"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4545.58,683.72l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-246"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m13.72,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-247"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m147.01,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-248"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m280.3,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-249"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m413.59,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-250"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m546.88,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-251"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m680.17,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-252"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m813.46,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-253"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m946.75,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-254"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1080.04,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-255"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1213.33,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-256"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1346.62,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-257"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1479.91,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-258"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1613.2,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-259"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1746.49,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-260"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1879.78,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-261"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2013.07,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-262"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2146.36,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-263"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2279.65,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-264"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2412.94,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-265"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2546.23,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-266"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2679.52,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-267"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2812.81,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-268"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2946.1,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-269"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3079.39,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-270"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3212.68,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-271"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3345.97,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-272"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3479.26,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-273"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3612.55,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-274"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3745.84,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-275"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3879.13,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-276"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4012.42,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-277"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4145.71,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-278"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4279,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-279"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4412.29,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-280"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4545.58,797.64l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-281"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m13.72,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-282"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m147.01,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-283"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m280.3,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-284"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m413.59,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-285"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m546.88,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-286"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m680.17,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-287"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m813.46,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-288"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m946.75,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-289"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1080.04,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-290"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1213.33,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-291"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1346.62,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-292"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1479.91,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-293"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1613.2,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-294"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1746.49,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-295"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1879.78,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-296"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2013.07,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-297"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2146.36,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-298"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2279.65,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-299"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2412.94,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-300"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2546.23,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-301"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2679.52,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-302"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2812.81,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-303"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2946.1,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-304"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3079.39,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-305"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3212.68,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-306"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3345.97,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-307"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3479.26,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-308"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3612.55,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-309"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3745.84,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-310"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3879.13,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-311"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4012.42,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-312"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4145.71,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-313"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4279,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-314"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4412.29,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-315"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4545.58,911.56l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-316"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m13.72,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-317"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m147.01,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-318"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m280.3,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-319"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m413.59,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-320"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m546.88,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-321"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m680.17,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-322"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m813.46,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-323"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m946.75,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-324"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1080.04,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-325"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1213.33,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-326"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1346.62,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-327"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1479.91,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-328"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1613.2,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-329"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1746.49,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-330"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m1879.78,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-331"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2013.07,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-332"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2146.36,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-333"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2279.65,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-334"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2412.94,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-335"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2546.23,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-336"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2679.52,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-337"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2812.81,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-338"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m2946.1,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-339"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3079.39,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-340"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3212.68,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-341"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3345.97,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-342"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3479.26,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-343"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3612.55,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-344"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3745.84,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-345"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m3879.13,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-346"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4012.42,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-347"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4145.71,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-348"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4279,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-349"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4412.29,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
              <g
                id="_Repetición_de_cuadrícula_-350"
                data-name="&amp;lt;Repetición de cuadrícula&amp;gt;"
              >
                <path d="m4545.58,1025.48l-13.72,14.77h23.28c2.09.03,3.52.67,4.27,1.08,2.21,1.22,3.29,3.12,4.29,4.87.88,1.54,1.37,2.92,1.66,3.88-5.8,12.65-11.6,25.3-17.4,37.95h16.24l9.32-20.39,13.71,29.53c.34.72,1.31,2.54,3.44,3.84,2.27,1.38,4.48,1.34,5.26,1.29h17.69l9.58-14.77h-18.6c-1.52.02-2.64-.33-3.32-.61-2.93-1.21-4.34-3.84-5.01-5.09-.48-.9-.78-1.7-.97-2.27-11.66-25.57-20.44-44.25-22.73-48.94-.47-.97-1.31-2.34-2.77-3.45-2.77-2.11-6.01-1.93-9.3-1.82-3.37.12-8.31.23-14.92.14Z" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Background;
