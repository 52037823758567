import React, { memo } from "react";
import { Handle, Position } from "reactflow";

function CustomNode({ data }) {
  return (
    <div
      className={`bg-${data.color}-500 text-white px-4 py-2 shadow-md rounded-md border-2 border-slate-400`}
    >
      <div className="flex">
        <div className="rounded-full w-12 h-12 flex justify-center items-center bg-gray-100">
          {data.emoji}
        </div>
        <div className="ml-2">
          <div className="text-xl font-bold">{data.name}</div>
        </div>
      </div>

      <Handle
        type="target"
        position={Position.Top}
        className="w-16 !bg-slate-500"
      />
      <Handle
        type="source"
        position={Position.Bottom}
        className="w-16 !bg-slate-500"
      />
    </div>
  );
}

export default memo(CustomNode);
