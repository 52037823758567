const form = {
    editor: {
        step0: {
            text0: `Will your project have monetization?`,
            t0: `Yes`,
            t1: `No`,
        },
        step1: {
            title: `Description of your project`,
            text: `Describe the project to prepare the initial steps`,
            t0: `Name`,
            t1: `Description`,
        },
        step2: {
            title: `How many users do you expect to have?`,
            text: `Depending on the number of users, we offer more critical measures`,
            t0: `0-1,000`,
            t1: `1,000-10k`,
            t2: `10k-100k`,
            t3: `100k-250k`,
            t4: `250k-500k`,
            t5: `+500,001`
        },
        step3: {
            title: `Select the type and style`,
            text0: `The type serves to choose one of our categories`,
            text1: `The style defines how it will look`,
            t0: `Hello world`,
            t1: `Minimalist`,
            t2: `Modern`,
            t3: `Classic`,
            x0: `Complex`,
            x1: `Simple`,
        },
        step4: {
            title: `Do you want the advanced or basic editor mode?`,
            t0: `Advanced`,
            t1: `Basic`,
        },
    }, 
    flags: {
        en: `English`,
        es: `Spanish`,
        fr: `French`,
        de: `German`,
        it: `Italian`,
        ru: `Russian`,
        pt: `Portuguese`,
        nl: `Dutch`,
        sv: `Swedish`,
    },
    title: {
        text0: 'Step',
        text1: 'of',
        step0: 'Language',
        step1: 'Profile',
        step2: 'Objective',
        step3: 'Context',
        step4: 'Bonus',
        step5: 'Company',
        step6: 'Size',
        step7: 'Type of Software',
        step8: 'Hiring',
    },
    footer: {
        text: 'Subscribe to our emails',
        button0: 'Previous',
        button1: 'Next',
    },
    step0: {
        t0: `Choose the language`,
        t1: `By choosing, the website will change`,
        t2: `We are currently integrating more languages`,
    },
    step1: {
        t0: `What is your name?`,
        t1: `Nice to meet you`,
        t2: `First Name`,
        t3: `Last Name`,
        t4: `Phone`,
        t5: `Email`,
    },
    step2: {
        t0: `Who do you need it for?`,
        t1: `Select the option of your purpose`,
        t2: `My company`,
        t3: `For myself`,
        t4: `Clients`,
    },
    step3: {
        t0: `How do you describe yourself? `,
        t1: `Startup`,
        t2: `Small business`,
        t3: `Medium-sized company`,
        t4: `Large company`,
    },
    step4:{
        t0: `Are you a student?`,
        t1: `Yes`,
        t2: `No`,
    },
    step5:{
        t0: `What describes you best?`,
        t1: `Freelancer`,
        t2: `Agency`,
    },
    step6:{
        t0: `What is the size of your work team?`,
    },
    step7:{
        t0: `What type of site are you looking to build today?`,
        t1: `Business site`,
        t2: `E-commerce store`,
        t3: `Portfolio`,
        t4: `Blog`,
        t5: `Analytics`,
        t6: `Other`,
    },
    step8:{
        t0: `Are you interested in using Aythen?`,
        t1: `Yes`,
        t2: `No`,
        t3: `I'm not sure yet`,
    },
}

export default form
