const VERSION = process.env.REACT_APP_VERSION;

const Icon1 = require(`@app/assets/steps/1.svg`).default;
const Icon2 = require(`@app/assets/steps/2.svg`).default;
const Icon3 = require(`@app/assets/steps/3.svg`).default;
const Icon4 = require(`@app/assets/steps/4.svg`).default;
const Icon5 = require(`@app/assets/steps/5.svg`).default;
const Icon6 = require(`@app/assets/steps/6.svg`).default;
const Icon7 = require(`@app/assets/steps/7.svg`).default;
const Icon8 = require(`@app/assets/steps/8.svg`).default;
const Icon9 = require(`@app/assets/steps/9.svg`).default;
const Icon10 = require(`@app/assets/steps/10.svg`).default;
const Icon11 = require(`@app/assets/steps/11.svg`).default;
const Icon12 = require(`@app/assets/steps/12.svg`).default;
const Icon13 = require(`@app/assets/steps/13.svg`).default;
const Icon14 = require(`@app/assets/steps/14.svg`).default;
const Icon15 = require(`@app/assets/steps/15.svg`).default;
const Icon16 = require(`@app/assets/steps/16.svg`).default;
const Icon17 = require(`@app/assets/steps/17.svg`).default;
const Icon18 = require(`@app/assets/steps/18.svg`).default;
const Icon19 = require(`@app/assets/steps/19.svg`).default;
const Icon20 = require(`@app/assets/steps/20.svg`).default;
const Icon21 = require(`@app/assets/steps/21.svg`).default;
const Icon22 = require(`@app/assets/steps/22.svg`).default;
const Icon23 = require(`@app/assets/steps/23.svg`).default;
const Icon24 = require(`@app/assets/steps/24.svg`).default;
const Icon25 = require(`@app/assets/steps/25.svg`).default;
const Icon26 = require(`@app/assets/steps/26.svg`).default;
const Icon28 = require(`@app/assets/steps/28.svg`).default;
const Icon29 = require(`@app/assets/steps/29.svg`).default;
const Icon30 = require(`@app/assets/steps/30.svg`).default;
const Icon31 = require(`@app/assets/steps/31.svg`).default;
const Icon32 = require(`@app/assets/steps/32.svg`).default;
const Icon33 = require(`@app/assets/steps/33.svg`).default;

const renderHelp = ({ title, text, icon }) => {
    return (`<div class="steps-intro">
<img src=${icon} />
<div class="info">
<b>
${title} 
</b>
<p>
${text} 
</p>
</div>
<div class="buttons">
    <button onclick="introJs().nextStep()">
        Anterior
    </button>
    <button class="next" onclick="introJs().previousStep()">
        Siguiente
    </button >
</div>
</div>`)
}












export const home = [{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="0-1"]`,
    intro: renderHelp({
        icoon: Icon1,
        titel: `Jouw eerste toegang`,
        tekst: `Hier kun je je accountgegevens beheren en toegang krijgen tot je licentie, je visuele voorkeur en uitloggen.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="0-2"]`,
    intro: renderHelp({
        icoon: Icon2,
        titel: `Jouw eerste gegevens`,
        tekst: `Laten we beginnen met iets eenvoudigs! In "Account bewerken" ga je je persoonlijke gegevens invullen en opslaan, evenals het wijzigen van het thema.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="0-3"]`,
    intro: renderHelp({
        icoon: Icon3,
        titel: `Jouw beheer`,
        tekst: `Je hebt alles wat je nodig hebt om je project vanuit elk perspectief te laten groeien. Je start, je gegevens, documenten, taken, facturen en contracten.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-0"]`,
    intro: renderHelp({
        icoon: Icon4,
        titel: `Jouw start > Cover`,
        tekst: `Je vindt hier een algemeen beeld. Eerst van je profiel, de organisaties die je beheert, toegang tot je balans, je toegangen en je domeinen.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-1"]`,
    intro: renderHelp({
        icoon: Icon5,
        titel: `Jouw samenvatting > Ontwerp `,
        tekst: `Voor je projecten en domeinen, heb je ontwerpen nodig. In de Add-ons kun je creëren wat je maar wilt. Zo kun je het resourceverbruik observeren dat ermee gepaard gaat.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-2"]`,
    intro: renderHelp({
        icoon: Icon6,
        titel: `Jouw samenvatting > Gegevens`,
        tekst: `Van je projecten en ontwerpen, zul je gegevens hebben. Toegang tot je gevectoriseerde databases en bewaar je meest interessante grafieken.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-3"]`,
    intro: renderHelp({
        icoon: Icon7,
        titel: `Jouw samenvatting > Status`,
        tekst: `Vernieuw je geheugen met de verschillende functionaliteiten die je tot je beschikking hebt, de status van je verbinding en toegang tot ondersteuning.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="2-0"]`,
    intro: renderHelp({
        icoon: Icon8,
        titel: `Aythen DB > jouw analyses`,
        tekst: `Maak en synchroniseer alle gegevens om dimensies en strategieën te creëren. Zo kun je geïnformeerde beslissingen nemen dankzij de interacties.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="2-0"]`,
    intro: renderHelp({
        icoon: Icon9,
        titel: `Jouw documentatie`,
        tekst: `Aythen ondersteunt elk bestandsformaat. Sleep en plaats je gewenste bestanden. Deel ze en categoriseer hun relevantie.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="3-0"]`,
    intro: renderHelp({
        icoon: Icon10,
        titel: `Kan Ban > Jouw taken`,
        tekst: `Je moet je taken en je team organiseren. Voeg tickets toe, takenlijsten, prioriteiten, statussen toe en controleer de algehele voortgangsstatus.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="4-0"]`,
    intro: renderHelp({
        icoon: Icon11,
        titel: `Elektronische facturatie (OCR)`,
        tekst: `Vul je bedrijfsgegevens in en beheer je balans, stel herinneringen voor betalingen in en configureer je licentielimieten.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icoon: Icon12,
        titel: `Elektronische handtekening (Audit trail)`,
        tekst: `Beheer al je overeenkomsten door je contracten te verzamelen. Onderteken, download of verwijder de meest gevoelige documenten.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icoon: Icon13,
        titel: `Jouw eerste project`,
        tekst: `Na al het overzicht van het platform. Maak een nieuwe add-on (openbaar of privé). Selecteer instellingen en koppel je domein. Sla op en open:`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icoon: Icon14,
        titel: `Jouw eerste project`,
        tekst: `Met een overzicht van het platform. Maak een nieuwe add-on (openbaar of privé). Selecteer de instellingen en koppel je domein. Sla op en open het:`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-1"]`,
    intro: renderHelp({
        icoon: Icon15,
        titel: `Jouw creatieve ruimte`,
        tekst: `Hier kun je je ontwerpstroom opbouwen. Maak en verbind om samenhangende schermen te maken. Wil je weten hoe te beginnen?`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-2"]`,
    intro: renderHelp({
        icoon: Icon16,
        titel: `Maak en bewaar`,
        tekst: `Je hele stroom is gebaseerd op slimme knooppunten. Ze zullen zowel je schermen verbinden als je gegevens synchroniseren. Maak de eerste en sla je sessie op.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-3"]`,
    intro: renderHelp({
        icoon: Icon17,
        titel: `Organiseer je weergave`,
        tekst: `Ben je van raster? Van stippen? Van alles netjes geordend hebben? Wil je weten waar elk knooppunt op elk moment is? Deze 3 knoppen zullen je helpen.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-4"]`,
    intro: renderHelp({
        icoon: Icon18,
        titel: `Beheer je weergave`,
        tekst: `Zoom in om details bij te werken of om een overzicht te krijgen van je hele creatie. Dit zal verschillende bronnen verbruiken, afhankelijk van het gebruik.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-5"]`,
    intro: renderHelp({
        icoon: Icon19,
        titel: `Beheer je creatie`,
        tekst: `Exporteer of importeer je ontwerpen en analyseer waar gebruikers het meest actief zijn dankzij warmtekaarten. Zo kun je weloverwogen correlaties maken.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-6"]`,
    intro: renderHelp({
        icoon: Icon20,
        titel: `Toevoegen en synchroniseren`,
        tekst: `Voeg inhoud toe aan je ontwerp, upload automatisch naar GitHub of deel het met derden. Alles wat je nodig hebt om eindeloos te creëren.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-7"]`,
    intro: renderHelp({
        icoon: Icon21,
        titel: `Heb je je eerste stroom?`,
        tekst: `Het kan zijn dat niet alles aan de smaak van de consument voldoet. Daarom kun je dubbelklikken of "Alt" + klikken op het knooppunt dat je wilt verfijnen.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-8"]`,
    intro: renderHelp({
        icoon: Icon22,
        titel: `Jouw individuele creatieve ruimte`,
        tekst: `Hier kun je je concentreren op het bouwen van je ontwerp. Maak met code en verbind nieuwe of bestaande gegevens om samenhangende schermen te maken.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-9"]`,
    intro: renderHelp({
        icoon: Icon23,
        titel: `Toegang tot je code`,
        tekst: `Bewerk en bewaar in realtime. Met een spiegeleffect kun je bewerken en het ontwerp bekijken om elke gewenste wijziging aan te brengen.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-10"]`,
    intro: renderHelp({
        icoon: Icon24,
        titel: `Synchroniseer je gegevens`,
        tekst: `Je zult verschillende methoden vinden om je inhoud op te slaan en te relateren aan je databases. Als je vragen hebt, controleer dan onze commando's.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-11"]`,
    intro: renderHelp({
        icoon: Icon25,
        titel: `Snelkoppelingen`,
        tekst: `Ga terug met alle knooppunten, zoom in op deze individuele ruimte of klik rechts om de weergave vanuit de browser te controleren.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-12"]`,
    intro: renderHelp({
        icoon: Icon26,
        titel: `Efficiënt werken`,
        tekst: `Maak al je wijzigingen tegelijk met het dubbele interne scherm om snel toegang te krijgen tot alle beschikbare functionaliteiten.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-0"]`,
    intro: renderHelp({
        icoon: Icon28,
        titel: `Jouw eerste analyse`,
        tekst: `Hier kun je je gegevensstromen opbouwen. Zowel extern als gesynchroniseerd met het ontwerp. Maak interacties en neem geïnformeerde beslissingen.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-1"]`,
    intro: renderHelp({
        icoon: Icon29,
        titel: `Vind je gegevens`,
        tekst: `Zoek, selecteer je knooppunten met de tabbladen, sla je versie op, download en orden je stroom via de verschillende functies.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
            element: `[data-step="6-2"]`,
    intro: renderHelp({
        icoon: Icon30,
        titel: `Interactie met je gegevens`,
        tekst: `Om geïnformeerde beslissingen te nemen, moet je vragen stellen, grafieken maken of matrixrelaties opbouwen via Python / JavaScript.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-3"]`,
    intro: renderHelp({
        icoon: Icon31,
        titel: `Beheer je knooppunt`,
        tekst: `Elk slim knooppunt kan op verschillende manieren worden geconfigureerd. De flexibiliteit van elk gegeven en formaat is voor elk doel.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-4"]`,
    intro: renderHelp({
        icoon: Icon32,
        titel: `Let op alle details`,
        tekst: `Je kunt de naam zien en wijzigen. Zo kun je het altijd vinden om je gegevens te vinden. Aan de rechterkant zie je wanneer het is geüpload en hoeveel ruimte het inneemt.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-5"]`,
    intro: renderHelp({
        icoon: Icon33,
        titel: `Configureer je gegevens`,
        tekst: `Maak back-ups met versies, voeg gegevens van derden toe, stel een vraag of breid het knooppunt uit om alle gegevens te zien.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-6"]`,
    intro: renderHelp({
        icoon: Icon1,
        titel: `Bekijk je gegevens vooraf`,
        tekst: `In het knooppuntsvenster kun je alle gegevens zien die je hebt, zodat je snel kunt begrijpen wat het is en waar het mee kan worden gerelateerd.`
    })
}]


export default home


