const support = {
  t1: `Помощь Aythen Lite`,
  t2: `Помощь и руководства`,
  t33: `Открытые тикеты`,
  t3: `Соглашение о уровне обслуживания (SLA) - это обеспечение доступности платформы программного обеспечения`,
  t4: `Свяжитесь с нами`,
  t5: `Создайте тикет поддержки`,
  t6: `Получите помощь напрямую от нашей команды.`,
  t7: `Управление инцидентами`,
  t8: `Проверьте свою лицензию, а также открытые и закрытые тикеты.`,
  t9: `Хотите узнать больше?`,
  t10: `Знаете ли вы Aythen PRO?`,
  t11: `Войдите сюда, чтобы понять и зарегистрироваться в нашем списке ожидания`,
  t12: `Мониторинг и отчетность`,
  t13: `Отправьте нам свои рекомендации, чтобы узнать, с какими данными вы хотели бы работать.`,
  topics: {
    1: {
      title: "Куки и конфиденциальность",
      data: {
        1: {
          title: `Мои данные в безопасности?`,
          text: `Мы работаем с сертификацией ISO 27001. Это международный стандарт для управления безопасностью информации. Эта сертификация подразумевает, что система управления безопасностью информации (SGSI) была реализована, которая соответствует необходимым контролям и процессам для эффективной защиты данных.`,
        },
        2: {
          title: `Пользовательский опыт`,
          text: `Используя провайдера облачных услуг с сертификациями, такими как ISO 27001, и работающего в Европейском союзе, 
                        мы обеспечиваем большую уверенность в безопасности и защите персональных данных, которые мы обрабатываем. Это помогает выполнить требования безопасности RGPD и снизить риски, связанные с обработкой персональных данных.`,
        },
      },
    },
    2: {
      title: `Векторизованные базы данных`,
      data: {
        1: {
          title: `Что это?`,
          text: `Они представляют собой значительное новшество в области хранения и обработки данных. Они используются в различных приложениях, 
                        включая науку о данных, автоматическое обучение и искусственный интеллект. <br /><br />
                        В отличие от традиционных баз данных, которые хранят информацию в виде таблиц с строками и столбцами, 
                        векторизованные базы данных используют векторные структуры данных для хранения и манипуляции информацией более эффективно. 
                        Организуя таким образом данные в многомерных векторах. Эти векторы могут содержать данные в различных форматах файлов, 
                        таких как числа, текст, изображения, код... Каждый элемент вектора представляет собой единицу информации, и операции выполняются 
                        эффективно, создавая потоки между наборами информации вместо отдельных строк и столбцов.`,
        },
        2: {
          title: `Для чего они нужны?`,
          text: `Эта структура позволяет быстро доступ к большим объемам данных и облегчает сложные математические и логические операции одновременно. 
                        Это позволяет устранить задержку данных без необходимости обрабатывать всю информацию, только интересующую, которая может быть сегментирована и организована в кластерах 
                        между измерениями и агентами. 
                        <br /><br />
                        - Каждое измерение представляет собой координату в многомерном пространстве, где данные организованы. Это означает, что векторизованная база данных 
                        может иметь несколько измерений, что позволяет более полно и подробно представить информацию.
                        <br /><br />
                        - Агенты - это сущности, которые выполняют операции над векторизованными данными. Эти агенты могут быть программами, процессами или запросами, которые взаимодействуют с 
                        базой данных для извлечения, обработки или манипуляции информацией. Они могут доступ к разным измерениям данных и выполнять сложные операции в зависимости 
                        от требований для графического представления или ответа на вопрос.`,
        },
      },
    },
    3: {
      title: `Безсерверное back-office`,
      data: {
        1: {
          title: `Как я выигрываю?`,
          text: `В безсерверной среде не нужно управлять физическими или виртуальными серверами. Инфраструктура управляется для того, чтобы приложение могло масштабироваться автоматически 
                        в соответствии с спросом. 
                        <br /><br />
                        Это означает, что нет необходимости предвидеть пиков трафика или регулировать серверную мощность вручную. Платформа автоматически масштабирует необходимые ресурсы 
                        для управления нагрузкой.`,
        },
        2: {
          title: `Оперативная гибкость`,
          text: `Таким образом, платятся только используемые ресурсы, что может сэкономить в сравнении с традиционными серверами, где платится за зарезервированную мощность, 
                        независимо от того, используется ли она или нет.`,
        },
      },
    },
    4: {
      title: `Сервисы по требованию`,
      data: {
        1: {
          title: `Могу ли я адаптировать к моей компании?`,
          text: `Вы можете создавать свои потребности на основе своей собственной корпоративной структуры.`,
        },
        2: {
          title: `Логотип компании`,
          text: `Фундаментально добавить логотип компании в регистр, чтобы mantener брендинг везде в всех взаимодействиях с клиентами, 
                        поставщиками и другими акторами.`,
        },
        3: {
          title: `Пользователи и роли`,
          text: `Вы можете добавлять и управлять разными типами пользователей и ролями в IAM, если они внутренние работники, или ваши внешние клиенты, поставщики и другие 
                        коллабораторы в наших динамических таблицах. Таким образом каждый тип пользователя может иметь различные уровни доступа и разрешений для обеспечения безопасности и конфиденциальности данных.`,
        },
        4: {
          title: `Корпоративные цвета`,
          text: `Было бы важно персонализировать внешний вид CRM с корпоративными цветами компании, чтобы укрепить визуальную идентичность бренда. 
                        Это включало бы возможность выбора преобладающих цветов в интерфейсе CRM, таких как фоны, кнопки и навигационные панели.`,
        },
        5: {
          title: `Связь банковских счетов`,
          text: `Для эффективного финансового управления, вы можете связать серверные карты Aythen с IAM (доступы и ролями) для того, чтобы каждый сотрудник компании мог отслеживать транзакции, платежи и другие 
                        финансовые операции напрямую из платформы, упрощая процесс управления.`,
        },
      },
    },
    5: {
      title: `Индивидуальные интеракции`,
      data: {
        1: {
          title: `Глубокий анализ`,
          text: `Векторное представление позволяет более глубокий анализ данных.`,
        },
        2: {
          title: `Стратегические инсайты`,
          text: `Мы предлагаем ценную инсайт для стратегического принятия решений с помощью векторных измерений.`,
        },
      },
    },
    6: {
      title: `Необходимые инструменты`,
      data: {
        1: {
          title: `Собственные документы`,
          text: `Вы можете загружать новые документы текста, формата и сотрудничества в реальном времени. Принимает любые форматы файлов, 
                        включая документы текста, электронные таблицы, презентации, формы, рисунки и больше.`,
        },
        2: {
          title: `Хранение`,
          text: `Документы могут занимать место в вашей учетной записи. Вы можете заморозить их, чтобы иметь доступ к ним, когда 
                        вам нужно, или удалить, если они не интересны.`,
        },
        2: {
          title: `Делиться и сотрудничество`,
          text: `Вы можете делиться своими документами с другими пользователями и работать вместе в реальном времени. Сотрудники могут видеть изменения в документе, 
                        как они происходят, и добавлять комментарии для обсуждения идей.`,
        },
        3: {
          title: `Электронный счет`,
          text: `Сэкономьте время и ресурсы. Автоматизируйте процесс выставления, отправки и получения счетов, что уменьшает административную нагрузку и затраты, связанные с бумагой. 
                        Уменьшает ошибки и улучшает точность в учете и отслеживании финансовых транзакций. Все, что необходимо для выполнения обязательных требований для любого автономного 
                        и компании для фискального и правового регулирования.`,
        },
        3: {
          title: `Договоры`,
          text: `Централизация информации, связанной с клиентами и коммерческими соглашениями, что облегчает управление и отслеживание договоров на протяжении всего их жизненного цикла.`,
        },
        4: {
          title: `Дашборды ваших график`,
          text: `Создавая дашборды на основе ваших интеракций, что позволяет визуально представлять ключевые данные и метрики в明ной и сжатой форме, 
                        что облегчает идентификацию тенденций, паттернов и областей для принятия информированных решений. `,
        },
        5: {
          title: `Динамические таблицы`,
          text: `Динамические таблицы предлагают гибкий способ резюмировать ваши данные на основе ваших проектов. Вы можете фильтровать, группировать и суммировать данные интерактивно, чтобы получить детализированную информацию и принять информированные решения.`,
        },
      },
    },
  },
}

export default support