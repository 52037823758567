const form = {
    editor: {
        step0: {
            text0: `Kommer ditt projekt att monetariseras?`,
            t0: `Ja`,
            t1: `Nej`,
        },
        step1: {
            title: `Beskrivning av ditt projekt`,
            text: `Beskriv projektet för att förbereda de första stegen`,
            t0: `Namn`,
            t1: `Beskrivning`,
        },
        step2: {
            title: `Hur många användare förväntar du dig att ha?`,
            text: `Beroende på antalet användare erbjuder vi mer kritiska åtgärder`,
            t0: `0-1.000`,
            t1: `1.000-10k`,
            t2: `10k-100k`,
            t3: `100k-250k`,
            t4: `250k-500k`,
            t5: `+500.001`
        },
        step3: {
            title: `Välj typ och stil`,
            text0: `Typen används för att välja en av våra kategorier`,
            text1: `Stilen definierar hur det kommer att se ut`,
            t0: `Hej världen`,
            t1: `Minimalistisk`,
            t2: `Modern`,
            t3: `Klassisk`,
            x0: `Komplex`,
            x1: `Enkel`,
        },
        step4: {
            title: `Vill du ha avancerat eller grundläggande redigeringsläge?`,
            t0: `Avancerat`,
            t1: `Grundläggande`,
        },
    }, 
    flags: {
        en: `Engelska`,
        es: `Spanska`,
        fr: `Franska`,
        de: `Tyska`,
        it: `Italienska`,
        ru: `Ryska`,
        pt: `Portugisiska`,
        nl: `Holländska`,
        sv: `Svenska`,
    },
    title: {
        text0: 'Steg',
        text1: 'av',
        step0: 'Språk',
        step1: 'Profil',
        step2: 'Mål',
        step3: 'Kontext',
        step4: 'Bonus',
        step5: 'Företag',
        step6: 'Storlek',
        step7: 'Typ av mjukvara',
        step8: 'Anställning',
    },
    footer: {
        text: 'Prenumerera på våra e-postmeddelanden',
        button0: 'Föregående',
        button1: 'Nästa',
    },
    step0: {
        t0: `Välj språk`,
        t1: `Genom att välja kommer webbplatsen att ändras`,
        t2: `Vi integrerar för närvarande fler språk`,
    },
    step1: {
        t0: `Vad är ditt namn?`,
        t1: `Trevligt att träffas`,
        t2: `Förnamn`,
        t3: `Efternamn`,
        t4: `Telefon`,
        t5: `E-post`,
    },
    step2: {
        t0: `För vem behöver du det?`,
        t1: `Välj alternativet för ditt syfte`,
        t2: `Mitt företag`,
        t3: `För mig själv`,
        t4: `Kunder`,
    },
    step3: {
        t0: `Hur beskriver du dig själv? `,
        t1: `Startup`,
        t2: `Litet företag`,
        t3: `Medium företag`,
        t4: `Stort företag`,
    },
    step4:{
        t0: `Är du student?`,
        t1: `Ja`,
        t2: `Nej`,
    },
    step5:{
        t0: `Vad beskriver dig bäst?`,
        t1: `Frilansare`,
        t2: `Byrå`,
    },
    step6:{
        t0: `Hur stor är din arbetsgrupp?`,
    },
    step7:{
        t0: `Vilken typ av webbplats försöker du bygga idag?`,
        t1: `Företagswebbplats`,
        t2: `E-handelsbutik`,
        t3: `Portfölj`,
        t4: `Blogg`,
        t5: `Analys`,
        t6: `Annat`,
    },
    step8:{
        t0: `Är du intresserad av att använda Aythen?`,
        t1: `Ja`,
        t2: `Nej`,
        t3: `Jag är inte säker ännu`,
    },
}

export default form
