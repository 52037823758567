import { createSlice } from '@reduxjs/toolkit'
import {
	createNewDesktop,
	deleteUserDesktop,
	getUserDesktops,
	updateDesktopSettings,
	updateUserDesktop,
} from '../actions/desktop'

const desktopSlices = createSlice({
	name: 'desktop',
	initialState: {
		selectedDesktop: null,
		desktops: [],
		desktopSettings: {},
		error: null,
		selectedItem: null,
	},
	reducers: {
		setUserDesktops: (state, action) => {
			state.desktops = action.payload
		},
		setSelectedDesktop: (state, action) => {
			if (action.payload) {
				console.log('Setting selectedDesktop to', action.payload)
				state.selectedDesktop = { ...action.payload }
			}
		},
		setSelectedItem: (state, action) => {
			// console.log('Selecting', action.payload)
			state.selectedItem = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			// ====================== GET USER DESKTOPS DATA ==========================
			.addCase(getUserDesktops.pending, (state) => {
				state.loading = true
			})
			.addCase(getUserDesktops.fulfilled, (state, action) => {
				console.log('DATA from getUserDesktops', action.payload)
				state.loading = false
				state.desktops = action.payload.desktops
				console.log('state.selectedDesktop', state.selectedDesktop)
				state.desktopSettings = action.payload.settings
				console.log(
					'DEFAULT',
					action.payload.desktops.filter((desktop) =>
						desktop.id.includes('default')
					)
				)
				if (!state.selectedDesktop) {
					const defaultDesktop = action.payload.desktops.filter((desktop) =>
						desktop.id.includes('default')
					)
					state.selectedDesktop = defaultDesktop[0]
				}
			})
			.addCase(getUserDesktops.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			// =======================================================================
			// ===================== UDPATE DESKTOP SETTINGS =========================
			.addCase(updateDesktopSettings.pending, (state) => {
				state.loading = true
			})
			.addCase(updateDesktopSettings.fulfilled, (state, action) => {
				state.loading = false
				if (action.payload) {
					console.log('Updating settings to..', action.payload)
					state.desktopSettings = action.payload
				}
			})

			.addCase(updateDesktopSettings.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			// =======================================================================
			// ======================= CREATE DESKTOP REQUEST ===========================
			.addCase(createNewDesktop.pending, (state) => {
				state.loading = true
			})
			.addCase(createNewDesktop.fulfilled, (state, action) => {
				state.loading = false
				if (action.payload) {
					console.log('Pushing to desktops..', action.payload)
					state.desktops = [...state.desktops, action.payload]
				}
			})

			.addCase(createNewDesktop.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			// =======================================================================
			// ====================== UPDATE USER DESKTOP  ==========================
			// .addCase(updateUserDesktop.pending, (state) => {
			// 	state.loading = true
			// })
			// .addCase(updateUserDesktop.fulfilled, (state, action) => {
			// 	state.loading = false
			// 	const index = state.desktops.findIndex(
			// 		(d) => d.id === action.payload.id
			// 	)
			// 	if (index >= 0) {
			// 		console.log('Updating desktop to..', action.payload)
			// 		state.desktops = [
			// 			...state.desktops.slice(0, index),
			// 			action.payload,
			// 			...state.desktops.slice(index + 1),
			// 		]
			// 	}
			// })
			// .addCase(updateUserDesktop.rejected, (state, action) => {
			// 	state.error = action.payload
			// 	state.loading = false
			// })
			// =======================================================================
			// ====================== DELETE USER DESKTOP ==========================
			.addCase(deleteUserDesktop.pending, (state) => {
				state.loading = true
			})
			.addCase(deleteUserDesktop.fulfilled, (state, action) => {
				state.loading = false
				console.log('Removing desktop', action.payload.desktopId)
				state.desktops = [...state.desktops].filter(
					(desktop) => desktop.id !== action.payload.desktopId
				)
			})
			.addCase(deleteUserDesktop.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
		// =======================================================================
	},
})

export const { setUserDesktops, setSelectedDesktop, setSelectedItem } =
	desktopSlices.actions

export default desktopSlices.reducer
