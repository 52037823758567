
import React from 'react'


const Icon = () => {
    return (
        <svg width="147" height="147" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_647_2689"  maskUnits="userSpaceOnUse" x="34" y="29" width="78" height="89">
                <path d="M105.691 117.32H40.3088C36.8246 117.32 34 114.496 34 111.011V35.3086C34 31.8244 36.8246 29 40.3088 29H82.75L112 58.2488V111.011C112 114.496 109.175 117.32 105.691 117.32Z" fill="url(#paint0_linear_647_2689)" />
            </mask>
            <g mask="url(#mask0_647_2689)">
                <path d="M105.691 117.32H40.3088C36.8246 117.32 34 114.496 34 111.011V35.3086C34 31.8244 36.8246 29 40.3088 29H82.75L112 58.2488V111.011C112 114.496 109.175 117.32 105.691 117.32Z" fill="url(#paint1_linear_647_2689)" />
                <path d="M41.419 73.8565C41.4083 73.5228 41.5094 73.1951 41.7062 72.9254C41.9416 72.623 42.2245 72.3609 42.5441 72.1494C43.0282 71.8989 43.5286 71.6811 44.0419 71.4976L45.5394 70.8769L51.5298 68.355C52.3626 67.967 53.9145 67.3204 56.1855 66.4151L59.0332 65.2512L60.0575 64.8865L60.236 64.8089C60.2846 64.7986 60.3348 64.7986 60.3834 64.8089C60.4983 64.758 60.6226 64.7316 60.7482 64.7313C60.9886 64.718 61.229 64.753 61.4556 64.8343C61.6822 64.9156 61.8903 65.0415 62.0673 65.2046C62.2175 65.346 62.3369 65.5168 62.4184 65.7064C62.4998 65.8959 62.5414 66.1002 62.5406 66.3065V68.2774C62.5332 68.6431 62.42 68.9989 62.2147 69.3017C61.9988 69.6396 61.6768 69.8962 61.2991 70.0311L49.0623 75.036L61.2991 80.0409C61.6635 80.1914 61.9799 80.4382 62.2147 80.7548C62.428 81.0448 62.5422 81.3958 62.5406 81.7558V83.7267C62.5408 83.9352 62.4989 84.1416 62.4176 84.3336C62.3363 84.5256 62.2172 84.6992 62.0673 84.8441C61.8931 85.0122 61.6859 85.1424 61.4588 85.2266C61.2318 85.3107 60.99 85.3469 60.7482 85.333C60.6235 85.3316 60.4998 85.3079 60.3834 85.2631L60.0575 85.1545L59.0332 84.7898L56.2088 83.6413L51.5531 81.7092L45.5627 79.1874L44.0652 78.5666C43.5841 78.3726 43.0796 78.1553 42.5674 77.907C42.2496 77.7589 41.9775 77.528 41.7795 77.2386C41.5816 76.9492 41.4652 76.6119 41.4423 76.262L41.419 73.8565Z" fill="var(--color-primary-3)" />
                <path d="M77.9744 87.4447C77.5861 87.4542 77.2021 87.3608 76.8615 87.1742C76.5208 86.9875 76.2355 86.714 76.0345 86.3816C75.8678 86.1422 75.7347 85.8811 75.6388 85.6056L75.3827 84.915L69.7958 72.5695L69.3923 71.6539C68.9267 70.6607 68.0266 68.6898 66.692 65.7411C64.959 61.9907 64.0924 59.9344 64.0924 59.5723C64.0927 59.3541 64.1425 59.1388 64.2379 58.9425C64.3334 58.7463 64.472 58.5743 64.6435 58.4394C65.0307 58.112 65.5257 57.9405 66.0323 57.9583H67.8481C68.2307 57.9517 68.6082 58.0466 68.9422 58.2333C69.2761 58.42 69.5545 58.692 69.7492 59.0213L70.1527 59.7973L70.4088 60.4879L71.8676 63.7004L75.9957 72.8334L76.5388 73.9973L79.0995 79.623C80.0812 81.6411 80.9465 83.7137 81.6913 85.8307C81.6899 86.0475 81.6397 86.2613 81.5443 86.4561C81.4489 86.6509 81.3107 86.8217 81.1402 86.9558C80.7534 87.2838 80.2582 87.4554 79.7514 87.4369L77.9744 87.4447Z" fill="var(--color-primary-3)" />
                <path d="M84.733 85.3498C84.4852 85.366 84.2367 85.3309 84.003 85.2468C83.7694 85.1627 83.5557 85.0313 83.375 84.861C83.0765 84.5652 82.9064 84.1638 82.9016 83.7436V81.7727C82.8981 81.4148 83.0067 81.0648 83.2121 80.7717C83.4442 80.4461 83.7685 80.1974 84.1432 80.0578L96.4189 75.0529L84.1432 70.0557C83.7705 69.8926 83.4546 69.6225 83.2354 69.2798C83.0239 68.9854 82.9073 68.6334 82.9016 68.271V66.2691C82.899 66.0644 82.9391 65.8614 83.0193 65.673C83.0994 65.4846 83.2179 65.315 83.3673 65.1749C83.732 64.8468 84.2119 64.6767 84.7019 64.7016C84.8153 64.6997 84.9273 64.7264 85.0277 64.7792C85.0945 64.7675 85.1627 64.7675 85.2295 64.7792L85.3926 64.8568C85.346 64.8568 85.6718 64.9499 86.3779 65.2215L89.2646 66.3854C89.1146 66.3182 90.6665 66.9648 93.9204 68.3253C95.6947 69.0547 97.7276 69.9186 100.019 70.917L100.601 71.1343L101.439 71.468C101.933 71.6434 102.411 71.8615 102.867 72.1198C103.193 72.2685 103.471 72.5045 103.671 72.8017C103.871 73.0989 103.985 73.4457 104 73.8036V76.2091C103.993 76.5389 103.885 76.8586 103.69 77.1247C103.473 77.4259 103.192 77.675 102.867 77.8541C102.425 78.0714 101.905 78.3041 101.315 78.5524L99.7631 79.1732L86.246 84.8299C85.8581 85.0239 85.6253 85.1481 85.5555 85.1946L85.4079 85.3033L84.733 85.3498Z" fill="var(--color-primary-3)" />
                <g opacity="0.2" filter="url(#filter0_f_647_2689)">
                    <rect x="48" y="62" width="51" height="34" fill="var(--color-primary-3)" />
                </g>
                <g filter="url(#filter1_d_647_2689)">
                    <path d="M112 58.2488H104.75C92.5997 58.2488 82.75 48.3991 82.75 36.2488V29L112 58.2488Z" fill="url(#paint2_linear_647_2689)" />
                </g>
                <path d="M51.2185 72.4545H54.2583V82.5966C54.2583 83.5341 54.0476 84.3485 53.6262 85.0398C53.2095 85.7311 52.6295 86.2637 51.8862 86.6378C51.1428 87.0118 50.2787 87.1989 49.2938 87.1989C48.4179 87.1989 47.6224 87.045 46.9075 86.7372C46.1972 86.4247 45.6338 85.9512 45.2171 85.3168C44.8004 84.6776 44.5945 83.875 44.5992 82.9091H47.6603C47.6698 83.2926 47.7479 83.6217 47.8947 83.8963C48.0462 84.1662 48.2522 84.3745 48.5126 84.5213C48.7777 84.6634 49.0902 84.7344 49.4501 84.7344C49.8289 84.7344 50.1485 84.6539 50.4089 84.4929C50.674 84.3272 50.8753 84.0857 51.0126 83.7685C51.1499 83.4512 51.2185 83.0606 51.2185 82.5966V72.4545ZM60.708 87H57.4126L62.4339 72.4545H66.3969L71.4112 87H68.1157L64.4722 75.7784H64.3586L60.708 87ZM60.5021 81.2827H68.2862V83.6832H60.5021V81.2827ZM75.3733 72.4545L78.8889 83.5057H79.0239L82.5466 72.4545H85.9557L80.9415 87H76.9784L71.9571 72.4545H75.3733ZM89.6799 87H86.3844L91.4058 72.4545H95.3688L100.383 87H97.0876L93.4441 75.7784H93.3305L89.6799 87ZM89.4739 81.2827H97.258V83.6832H89.4739V81.2827Z" fill="white" />
            </g>
            <defs>
                <filter id="filter0_f_647_2689" x="9" y="23" width="129" height="112" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="19.5" result="effect1_foregroundBlur_647_2689" />
                </filter>
                <filter id="filter1_d_647_2689" x="59.75" y="5" width="79.25" height="79.248" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="2" dy="1" />
                    <feGaussianBlur stdDeviation="12.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_647_2689" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_647_2689" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_647_2689" x1="73" y1="29" x2="73" y2="117.32" gradientUnits="userSpaceOnUse">
                    <stop stopColor="var(--color-primary-3)" />
                    <stop offset="1" stopColor="var(--color-primary-3)" />
                </linearGradient>
                <linearGradient id="paint1_linear_647_2689" x1="73" y1="29" x2="73" y2="117.32" gradientUnits="userSpaceOnUse">
                    <stop stopColor="var(--color-primary-3)" />
                    <stop offset="0.942892" stopColor="var(--color-primary-3)" />
                </linearGradient>
                <linearGradient id="paint2_linear_647_2689" x1="97.375" y1="29" x2="97.375" y2="58.2488" gradientUnits="userSpaceOnUse">
                    <stop stopColor="var(--color-primary-3)" />
                    <stop offset="1" stopColor="var(--color-primary-3)" />
                </linearGradient>
            </defs>
        </svg>

    )
}
export default Icon