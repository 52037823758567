import React from 'react'

import SectionImage from './assets/section-image.png'
import DragNavImage from './assets/drag-nav.png'

import Card1Image from './assets/img-1.png'
import Card2Image from './assets/img-2.png'
import Card3Image from './assets/img-3.png'
import Card4Image from './assets/img-4.png'
import Card5Image from './assets/img-5.png'

const Sandbox = () => {
  return (
    <div className="relative w-full sm:h-full  mb-20" id="sandbox">
      <div className="container mx-auto">
        <section className="home-section-1 ">
          <div className="flex ">
            <div className="relative lg:pt-[10px] z-[0]">
              <div className="mt-2 mb-5 flex items-center ml-[-8px] absolute left-0 top-[10px] w-[300px]">
                <div className="bg-[#939AFF] z-[-1]  w-[40px] h-[40px] p-3 flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="22"
                    viewBox="0 0 20 22"
                    fill="none"
                  >
                    <path
                      d="M18.9993 14.9979V6.99795C18.9989 6.64722 18.9063 6.30276 18.7308 5.99911C18.5553 5.69546 18.303 5.44331 17.9993 5.26795L10.9993 1.26795C10.6952 1.09241 10.3503 1 9.99927 1C9.64819 1 9.30331 1.09241 8.99927 1.26795L1.99927 5.26795C1.69553 5.44331 1.44325 5.69546 1.26773 5.99911C1.09221 6.30276 0.999627 6.64722 0.999268 6.99795V14.9979C0.999627 15.3487 1.09221 15.6931 1.26773 15.9968C1.44325 16.3004 1.69553 16.5526 1.99927 16.7279L8.99927 20.7279C9.30331 20.9035 9.64819 20.9959 9.99927 20.9959C10.3503 20.9959 10.6952 20.9035 10.9993 20.7279L17.9993 16.7279C18.303 16.5526 18.5553 16.3004 18.7308 15.9968C18.9063 15.6931 18.9989 15.3487 18.9993 14.9979Z"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.49927 3.20801L9.99927 5.80801L14.4993 3.20801M5.49927 18.788V13.598L0.999268 10.998M18.9993 10.998L14.4993 13.598V18.788M1.26927 5.95801L9.99927 11.008L18.7293 5.95801M9.99927 21.078V10.998"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="ml-4">
                  <h4 className="text-[#939AFF] font-extrabold uppercase text-[20px]">
                    SANDBOX
                  </h4>
                </div>
              </div>
              <div className="sandbox-left-border "></div>
            </div>
            <div>
              <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="relative col-span-1 lg:col-span-2">
                  <div className="mt-[100px]">
                    <div className="lg:pl-10">
                      <p className="text-[20px] text-[#554D56] lg:text-[48px] leading-tight drop-shadow-xl text-left pl-[30px] lg:pl-0">
                        <span className="text-[#939AFF]">
                          Escala todos tus proyectos.
                        </span>
                        Crea un usuario gratis y administra las licencias de tus
                        proyectos en una sola plataforma. Lanza tu idea desde
                        plantillas predeterminadas y realiza ajustes generales,
                        privacidad, integraciones y mucho más.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="text-end relative">
                  <div className="w-full lg:mt-[-100px]">
                    <img src={SectionImage} className="" />
                  </div>
                  <div className="absolute lg:left-[10%] lg:bottom-[-80px] lg:right-[0px] top-[-280px] right-[0px] z-20">
                    <img
                      src={DragNavImage}
                      className="lg:w-[120px] z-20 w-[50px]"
                    />
                  </div>
                </div>
              </div>

              <div className="lg:mt-[50px]">
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3">
                  <div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto]  lg:h-[450px] relative lg:mt-[0px] mb-8 lg:mb-0">
                      <p className="mt-0 lg:text-[13px] uppercase">
                        PRIMER BENEFICIO
                      </p>
                      <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                        Eficiencia en el Inicio
                      </h5>
                      <div className="lg:w-[50%]">
                        <p className="lg:text-[15px] mt-5">
                          Un workspace personalizado ofrece un punto de partida
                          eficiente para comenzar nuevos proyectos. Los usuarios
                          pueden acceder a plantillas, configuraciones
                          predeterminadas y recursos esenciales sin tener que
                          buscarlos manualmente.
                        </p>
                        <img
                          src={Card1Image}
                          className="lg:absolute bottom-[0px] right-[-40px] w-[200px] lg:w-[80%] z-20"
                        />
                      </div>
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mt-5 mb-8 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          CUARTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Consistencia en la Configuración{' '}
                        </h5>
                      </div>
                      <div className="lg:w-[90%]">
                        <p className="lg:text-[15px]">
                          Utilizar un workspace personalizado garantiza que los
                          usuarios sigan configuraciones y prácticas
                          consistentes en cada proyecto. Esto puede mejorar la
                          calidad del trabajo y reducir errores debido a
                          configuraciones incorrectas.
                        </p>
                      </div>
                      <img
                        src={Card4Image}
                        className="lg:absolute bottom-[-50px] right-[80px] w-[200px] lg:w-[300px] z-20"
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mb-8 lg:mb-0">
                      <p className="mt-0 lg:text-[13px] uppercase">
                        SEGUNDO BENEFICIO
                      </p>
                      <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                        Adaptación a las Necesidades
                      </h5>
                      <div className="lg:w-[100%]">
                        <p className="lg:text-[15px]">
                          Cada usuario puede configurar su workspace según sus
                          preferencias y necesidades específicas. Esto permite
                          una experiencia de usuario personalizada que se ajusta
                          a los flujos de trabajo individuales.
                        </p>
                      </div>
                      <img
                        src={Card2Image}
                        className="lg:absolute bottom-[-80px] right-[-40px] z-20 w-[200px] lg:w-[70%]"
                      />
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mt-5 mb-8 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          QUINTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Acceso a Plantillas
                        </h5>
                      </div>
                      <div className="lg:w-[100%]">
                        <p className="lg:text-[15px]">
                          Los usuarios pueden acceder a plantillas predefinidas
                          para proyectos comunes, lo que acelera la creación y
                          configuración inicial. Esto es especialmente útil para
                          tareas repetitivas o proyectos similares.
                        </p>
                      </div>
                      <img
                        src={Card5Image}
                        className="z-20 lg:absolute bottom-[-60px] left-[20%] w-[200px] lg:w-[60%]"
                      />
                    </div>
                  </div>
                  <div className="relative md:col-span-2 lg:col-span-1 grid grid-cols-2 lg:block gap-4">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 relative h-[auto] lg:h-[450px] mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          TERCER BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Productividad Mejorada
                        </h5>
                        <p className="lg:text-[15px] mt-3">
                          Al proporcionar acceso rápido a herramientas y
                          recursos necesarios, un workspace personalizado reduce
                          el tiempo necesario para preparar y configurar un
                          nuevo proyecto. Esto permite a los usuarios
                          concentrarse en el trabajo real de manera más rápida.
                        </p>
                      </div>
                      <img
                        src={Card3Image}
                        className="lg:absolute bottom-[0px] left-[25%] z-20 w-[200px] lg:w-[50%]"
                      />
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 relative  mt-5 mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          SEXTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Seguimiento y Organización de Proyectos
                        </h5>
                        <p className="lg:text-[15px] mt-3">
                          Los usuarios pueden utilizar el workspace para llevar
                          un registro y realizar un seguimiento de los proyectos
                          en curso, tareas pendientes y fechas importantes.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Sandbox
