import apiBackend from '@src/apiBackend.js'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const addGptTokens = createAsyncThunk(
	'tokens/addGptTokens',
	async ({ userId, tokens }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/gptTokens/addGptTokens',
				{ userId, tokens },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			return res.data
		} catch (error) {
			console.log('Error in addGptTokens action:', error)
		}
	}
)

export const getUserGptTokens = createAsyncThunk(
	'tokens/getUserGptTokens',
	async (userId) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.get(
				`/gptTokens/getUserGptTokens/${userId}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			return res.data
		} catch (error) {
			console.log('Error in getUserGptTokens action:', error)
		}
	}
)
