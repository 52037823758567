import React from 'react'

import DragNavImage from './assets/drag-nav.png'
import Card1Image from './assets/img-1.png'
import Card2Image from './assets/img-2.png'
import Card3Image from './assets/img-3.png'
import Card4Image from './assets/img-4.png'
import PraImage from './assets/8.png'
import WebHeader from './assets/web-header.png'

const Plugins = () => {
  return (
    <div
      className="relative w-full sm:h-full lg:mt-[-90px] mt-[-50px] "
      id="rpa"
    >
      
      <div className="container mx-auto">
        <section className="home-section-1 ">
          <div className="flex relative">
          <div className="rpa-left-border"></div>
            <div>
              <div className="grid grid-cols-4 lg:grid-cols-4 gap-4">                
                <div className="col-span-3 relative">
                  <div className="">
                    <div className="pl-5 md:pl-10">
                      <p className="text-[24px] text-[#554D56] lg:text-[48px] leading-tight drop-shadow-xl text-left mt-[80px]">
                        <span className="text-[#00EE51]">
                          Procesos automatizados de robots.
                        </span>
                        Crea bots para tu proyecto y permite conectar,
                        implementar y administrar acciones automatizadas en
                        cualquier tecnología para digitalizar acciones, fácil e
                        intuitivas.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="relative">
                  <div className="w-full initial absolute lg:left-[0px] lg:top-[10px] z-20 flex lg:initial justify-end">
                    <img
                      src={DragNavImage}
                      className="w-[60px] lg:w-[120px] z-20"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-[30px] -lg:mt-[120px]">
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3 items-start">
                  <div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[450px] relative mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          PRIMER BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Eficiencia operativa
                        </h5>
                        <p className="lg:text-[15px]">
                          RPA automatiza tareas repetitivas y tediosas que
                          normalmente realizarían los empleados, lo que permite
                          un aumento significativo en la eficiencia y
                          productividad en las operaciones diarias.
                        </p>
                        <img
                          src={Card1Image}
                          className="lg:absolute bottom-[-150px] right-[-50px] lg:w-[90%] z-20"
                        />
                      </div>
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[500px] relative mt-5 mb-8 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          TERCER BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Escalabilidad
                        </h5>
                      </div>
                      <div className="lg:w-[90%]">
                        <p className="lg:text-[15px]">
                          Los bots de RPA pueden escalarse fácilmente según las
                          necesidades, lo que permite adaptarse a cambios en la
                          demanda y aumentar la capacidad de procesamiento sin
                          grandes inversiones adicionales.
                        </p>
                      </div>
                      <div className="lg:flex items-end justify-center">
                        <img src={Card3Image} className="lg:w-[350px]" />
                      </div>
                    </div>
                  </div>
                  <div className="relative">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[400px] relative mb-8 lg:mb-0">
                      <p className="mt-0 lg:text-[13px] uppercase">
                        SEGUNDO BENEFICIO
                      </p>
                      <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                        Reducción de errores
                      </h5>
                      <div className="lg:w-[50%]">
                        <p className="lg:text-[15px]">
                          Al eliminar la intervención humana en tareas
                          rutinarias, se reducen las posibilidades de errores
                          humanos, lo que mejora la precisión y calidad de los
                          procesos. Pueden operar de forma continua y sin
                          interrupciones, lo que permite la ejecución de tareas
                          durante todo el día, incluso fuera del horario
                          laboral.
                        </p>
                      </div>
                      <img
                        src={Card2Image}
                        className="absolute bottom-[-50px] right-[-100px] w-[200px] lg:w-[auto] lg:absolute lg:top-[80px]  z-20"
                      />
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[500px] relative mt-5 mb-8 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          CUARTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Integración sin problemas
                        </h5>
                      </div>
                      <div className="lg:w-[80%]">
                        <p className="lg:text-[15px]">
                          La RPA puede integrarse con sistemas existentes sin la
                          necesidad de modificar la infraestructura tecnológica,
                          lo que facilita su adopción en empresas con diferentes
                          sistemas.
                        </p>
                      </div>
                      <img
                        src={Card4Image}
                        className="z-20 lg:absolute bottom-[-30px] left-[50px]"
                      />
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 relative -mt-5 -mb-8 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          SEXTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Ahorro de tiempo y costos
                        </h5>
                      </div>
                      <div className="lg:w-[80%]">
                        <p className="lg:text-[15px]">
                          La automatización de tareas permite realizarlas más
                          rápidamente, lo que lleva a una reducción del tiempo
                          dedicado a procesos y ahorros en costos laborales.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="relative md:col-span-2 lg:col-span-1 grid lg:block md:grid-cols-2">
                    <div className="lg:mt-[-150px] lg:mb-[100px] relative">
                      <img src={PraImage} className="w-full relative z-[5]" />
                      <div className="paraImage-bg"></div>
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[500px] relative mt-10 mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          QUINTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Mejora de la experiencia del cliente
                        </h5>
                        <p className="lg:text-[15px] mt-3">
                          Al mejorar la eficiencia de los procesos internos, se
                          pueden ofrecer servicios más rápidos y precisos a los
                          clientes, lo que mejora la satisfacción del cliente.
                        </p>
                      </div>
                      <img src={WebHeader} className="z-20" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Plugins
