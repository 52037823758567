import { useLocation } from 'react-router-dom';

const menuData = null

import styles from '../index.module.css'

const SmallNav = () => {

  const location = useLocation();

  const isActive = (href) => location.pathname.startsWith(href);
  return (
    <>
      <nav className={`${styles["mainmenu-nav"]}`} >
        <ul className={`${styles["dashboard-mainmenu"]} ${styles["rbt-default-sidebar-list"]}`} >
          <li>
            <button href="/dashboard">
              <i className={`${styles.featherMonitor}`} ></i>
              <span>Welcome</span>
            </button>
          </li>
          <li>
            <button href="/plans-billing">
              <i className={`${styles.feather} ${styles.briefcase}`} ></i>
              <span>Manage Subsription</span>
            </button>
          </li>
        </ul>
        <div className={styles["rbt-sm-separator"]} ></div>
        <ul className={`${styles["dashboard-mainmenu"]} ${styles["rbt-default-sidebar-list"]}`}>
          {menuData &&
            menuData.smallNavItem.slice(0, 7).map((data, index) => (
              <li key={index}>
                <button
                  className={
                    isActive(data.link)
                      ? styles.active
                      : "" || data.isDisable
                      ? styles.disabled
                      : ""
                  }
                  href={data.link}
                >
                  <img
                    src={data.img}
                    width={35}
                    height={35}
                    alt="AI Generator"
                  />
                  <span>{data.text}</span>
                  {data.badge !== "" ? (
                    <div className={`${styles["rainbow-badge-card"]} ${styles["badge-sm"]} ${styles["ml--10"]}`} >
                      {data.badge}
                    </div>
                  ) : (
                    ""
                  )}
                </button>
              </li>
            ))}
        </ul>
        <div className={`${styles["rbt-sm-separator"]}`} ></div>
      
      </nav>
    </>
  );
};

export default SmallNav;
