const bank = {
    t1: `Rendimentos Totais`,
    t2: `Resultado Total`,
    t3: `Gerencie seus acessos`,
    t4: `Buscar uma fatura`,
    t5: `Buscar uma data`,
    t6: `Meu Cartão`,
    t7: `Saldo do cartão`,
    t8: `Estado`,
    t9: `Ativa`,
    t10: `Administrar`,
    t11: `Transferir`,
    t12: `Compartilhe e consiga afiliar todos os seus contatos para
        obter sua recompensa`,
    t13: `Segurança`,
    t14: `Cartão ativo`,
    t15: `Acesso seguro`,
    t16: `Alterar`,
    t17: `Informação`,
    t18: `Localidade`,
    t19: `Endereço`,
    t20: `Carteira`,

    cardlist: {
        "t0": "Cartões",
        "t1": "Adicionar",
        "t2": "Adicione seu cartão de pagamento de forma segura",
        "t3": "Uma vez que você adicionou seu cartão, poderá administrar seus pagamentos e assinaturas ativas.",
        "t4": "Adicionar cartão",
        "t5": "Insira os detalhes do seu cartão de crédito para realizar pagamentos de forma segura.",
        "t6": "Ver mais",
        "t7": "Nome",
        "t8": "Nº do cartão",
        "t9": "CVC",
        "t10": "Vencimento",
        "t11": "Aceitar cartão",
        "t12": "Cancelar",
        "t13": "Peça um novo cartão",
        "t14": "Você receberá um novo cartão",
        "t15": "Enviado desde Aythen",
        "t16": "Espanha",
        "t17": "10903",
        "t18": "Santa Perpétua de Mogoda",
        "t19": "Este é o endereço que você receberá",
        "t20": "Isso terá um custo de 10* Isso terá um custo de Isso terá um custo de Isso terá um custo de",
        "t21": "Solicitar cartão"
    }
}

export default bank