import React, { createContext, useState, useContext } from "react";

const PositionContext = createContext();

export const PositionProvider = ({ children }) => {
  const [position, setPosition] = useState({ top: 1000, left: 1000 });
  const [direction, setDirection] = useState({ deltaX: 0, deltaY: 0 });
  const [circles, setCircles] = useState([
    { id: 'circle5', top: 1200, left: 1200, value: 5, type: 'collect', name: 'Pera' },
    { id: 'circle6', top: 1400, left: 1400, value: 0, type: 'add', name: 'Pera', targetValue: 5 },
    { id: 'trashCircle', top: 900, left: 900, type: 'trash' },  // Nuevo círculo trash
    { id: 'coin1', type: 'coin', left: 1300, top: 1300, coinCount: 0, maxCoins: 10, value: 0, completed: false, generated: false },

    { id: 'popup1', top: 1200, left: 1300, type: 'popup', popup: 1 },

    { id: 1, type: 'teleport', top: 1200, left: 1000, route: [2, 3] },
    { id: 2, type: 'teleport', top: 1200, left: 900, route: [3, 1] },


    {
      id: 'data1', type: 'data', top: 500, left: 1100, 
      data: 'Lorem ipsum et dolor etsum eterem dorote eptsum'
    },
    {
      id: 'data2', type: 'gpt', top: 500, left: 1400, data: {
        hello: 'world'
      }
    },
  ]);

  const [pointerInventory, setPointerInventory] = useState({});
  const [walls, setWalls] = useState([
    { top: 300, left: 300, width: 200, height: 50 }, // Ejemplo de pared
    { top: 700, left: 500, width: 50, height: 200 }, // Otro ejemplo de pared
  ]);



  return (
    <PositionContext.Provider
      value={{
        position,
        setPosition,
        direction,
        setDirection,
        circles,
        setCircles,
        pointerInventory,
        setPointerInventory,
        walls,
        setWalls
      }}>
      {children}
    </PositionContext.Provider>
  );
};

export const usePosition = () => useContext(PositionContext);
