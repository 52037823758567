import { createSlice } from '@reduxjs/toolkit'
import {
  createChat,
  getAllChatsByUserId,
  getAllMessagesByChat,
  getAllProjectIds,
  getAllUsers,
  getChatByParticipant,


  getAllInvitations,
  getAllUserInvitations
} from '../actions/chat'

export const chatSlice = createSlice({
  name: 'chats',
  initialState: {
    allInvitations: [],
    allUserInvitations: [],
    allUsers: [],
    allChats: [],
    allProjectUsersIds: [],
    newChatUserName: null,
    onChatSection: null,
    joinedVideoChat: null,
    newChatSelected: null,
    selectedChat: null,
    selectedChatAdmin: null,
    socketId: null,
    selectedChatUsername: null,
    allMessages: [],
    selectedContact: '',
    activeComponent: '',
    loading: false,
    error: null,
    userVideoAudio: {
      localUser: { video: true, audio: true }
    },
    userVideoRef: null,
    userStream: null,
    screenTrackRef: null,
    peers: [],
    peersRef: [],
    joinTime: null
  },
  reducers: {
    setAllUserInvitations(state, action) {
      state.allUserInvitations = action.payload
    },
    setAllInvitations(state, action) {
      state.allInvitations = action.payload
    },



    setJoinTime(state, action) {
      state.joinTime = action.payload
    },
    setUserVideoRef(state, action) {
      state.userVideoRef = action.payload
    },
    settingPeers(state, action) {
      state.peers = action.payload
    },
    setSelectedChat(state, action) {
      state.selectedChat = action.payload
    },
    setSocketId(state, action) {
      state.socketId = action.payload
    },
    setOutsideChatSection(state, action) {
      state.onChatSection = false
    },
    setOnChatSection(state, action) {
      state.onChatSection = true
    },
    setSelectedChatAdmin(state, action) {
      state.selectedChatAdmin = action.payload === true ? true : null
    },
    setSelectedChatUsername(state, action) {
      state.selectedChatUsername = action.payload
    },
    setNewChatUsername(state, action) {
      state.newChatUserName = action.payload
    },
    setJoinedVideoChat(state, action) {
      state.joinedVideoChat = action.payload
    },
    setNewChatSelected(state, action) {
      state.newChatSelected = action.payload
    },
    setSelectedContact(state, action) {
      state.selectedContact = action.payload
    },
    setAllMessages(state, action) {
      state.allMessages = action.payload
    },
    updateChat(state, action) {
      state.allMessages = [...state.allMessages, action.payload]
    },
    changeActiveComponent(state, action) {
      state.activeComponent = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUserInvitations.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllUserInvitations.fulfilled, (state, action) => {
        state.loading = false
        state.allUserInvitations = action.payload
      })
      .addCase(getAllUserInvitations.rejected, (state, action) => {
        state.error = action.payload
        state.loading = false
      })
      .addCase(getAllInvitations.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllInvitations.fulfilled, (state, action) => {
        state.loading = false
        state.allInvitations = action.payload
      })
      .addCase(getAllInvitations.rejected, (state, action) => {
        state.error = action.payload
        state.loading = false
      })
      .addCase(getAllChatsByUserId.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllChatsByUserId.fulfilled, (state, action) => {
        state.loading = false
        if(action.payload){
          state.allChats = action.payload
        }
      })
      .addCase(getAllChatsByUserId.rejected, (state, action) => {
        state.error = action.payload
        state.loading = false
      })
      .addCase(getAllMessagesByChat.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllMessagesByChat.fulfilled, (state, action) => {
        state.loading = false
        state.allMessages = action.payload
      })
      .addCase(getAllMessagesByChat.rejected, (state, action) => {
        state.error = action.payload
        state.loading = false
      })
      .addCase(getChatByParticipant.pending, (state) => {
        state.loading = true
      })
      .addCase(getChatByParticipant.fulfilled, (state, action) => {
        state.loading = false
        state.selectedChat = action.payload
      })
      .addCase(getChatByParticipant.rejected, (state, action) => {
        state.error = action.payload
        state.loading = false
      })
      .addCase(createChat.pending, (state) => {
        state.loading = true
      })
      .addCase(createChat.fulfilled, (state, action) => {
        state.loading = false
        state.selectedChat = action.payload
      })
      .addCase(createChat.rejected, (state, action) => {
        state.error = action.payload
        state.loading = false
      })
      .addCase(getAllUsers.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.loading = false
        state.allUsers = action.payload
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.error = action.payload
        state.loading = false
      })
      .addCase(getAllProjectIds.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllProjectIds.fulfilled, (state, action) => {
        state.loading = false
        state.allProjectUsersIds = action.payload.map((item) => item.userToolId)
      })
      .addCase(getAllProjectIds.rejected, (state, action) => {
        state.error = action.payload
        state.loading = false
      })
  }
})

export const {
  setAllUserInvitations, 
  setAllInvitations,

  setUserVideoRef,
  setOutsideChatSection,
  setSelectedChat,
  setSelectedChatUsername,
  setSelectedContact,
  settingPeers,
  updateChat,
  setNewChatSelected,
  setJoinedVideoChat,
  setOnChatSection,
  setJoinTime,
  setSocketId,
  setSelectedChatAdmin,
  setAllMessages,
  setNewChatUsername,
  changeActiveComponent
} = chatSlice.actions

export default chatSlice.reducer
