import React, { useEffect } from "react";
import Slider from "react-slick";

import "venobox/dist/venobox.min.css";

import darkBg from "@home/assets/images/light/service/bg-testimonial.png";
import darkBgHover from "@home/assets/images/light/service/bg-testimonial-hover.png";

import TestimonialData from "@home/assets/constants/testimonial.json";
import { useAppContext } from "@home/context/Context";

import styles from '../index.module.css'


const Testimonial = () => {
  const { isLightTheme } = useAppContext();
  var settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 2,
    dots: true,
    arrows: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 581,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    import("venobox/dist/venobox.min.js").then((venobox) => {
      new venobox.default({
        selector: ".popup-video",
        maxWidth: window.innerWidth >= 992 ? "70%" : "100%",
      });
    });
  }, []);
  return (
    <div className={`${styles.container}`} >
      <div className={`${styles.row}`} >
        <div className={`${styles["col-md-12"]}`} >
          <Slider
            {...settings}
            className={`${styles["service-wrapper"]} ${styles["rainbow-service-slider-actvation"]} ${styles["slick-grid-15"]} ${styles["rainbow-slick-dot"]} ${styles["rainbow-gradient-arrows"]}`}
          >
            {TestimonialData &&
              TestimonialData.testimonial.map((data, index) => (
                <div className={`${styles["slide-single-layout"]}`} key={index}>
                  <div
                    className={`${styles["rainbow-box-card"]} ${data.isActive ? styles.active : ""
                      } ${styles["card-style-default"]} ${styles["testimonial-style-defalt"]} ${styles["has-bg-shaped"]}`}
                  >
                    <div className={`${styles.inner}`} >
                      <div className={`${styles.rating}`} >
                        <a href="#rating">
                          <svg fill="currentColor" viewBox="0 0 24 24">
                            <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                          </svg>

                        </a>
                        <a href="#rating">
                          <svg fill="currentColor" viewBox="0 0 24 24">
                            <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                          </svg>

                        </a>
                        <a href="#rating">
                          <svg fill="currentColor" viewBox="0 0 24 24">
                            <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                          </svg>

                        </a>
                        <a href="#rating">
                          <svg fill="currentColor" viewBox="0 0 24 24">
                            <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                          </svg>

                        </a>
                        <a href="#rating">
                          <svg fill="currentColor" viewBox="0 0 24 24">
                            <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                          </svg>

                        </a>
                      </div>
                      <div className={`${styles.content}`} >
                        <p className={styles.description} >{data.desc}</p>
                        <div className={`${styles["bottom-content"]}`} >
                          <div className={`${styles["meta-info-section"]}`} >
                            <p className={styles["title-text"]} >{data.name}</p>
                            <p className={styles.desc} >{data.post}</p>
   
                          </div>
                          <div className={`${styles["meta-img-section"]}`} >

                            <a className={styles.image} href="#">
                              <img
                                src={data.userImg}
                                width={43}
                                height={43}
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles["bg-shape"]}`} >
                      <img
                        className={`${styles.bg}`}
                        src={!isLightTheme ? data.img : darkBg}
                        width={415}
                        height={287}
                        alt=""
                      />
                      <img
                        className={`${styles["bg-hover"]}`}
                        src={!isLightTheme ? data.imgHover : darkBgHover}
                        width={415}
                        height={287}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
