const form = {
    editor: {
        step0: {
            text0: `Seu projeto terá monetização?`,
            t0: `Sim`,
            t1: `Não`,
        },
        step1: {
            title: `Descrição do seu projeto`,
            text: `Descreva o projeto para preparar os primeiros passos`,
            t0: `Nome`,
            t1: `Descrição`,
        },
        step2: {
            title: `Quantos usuários você espera ter?`,
            text: `Dependendo do número de usuários, oferecemos medidas mais críticas`,
            t0: `0-1.000`,
            t1: `1.000-10k`,
            t2: `10k-100k`,
            t3: `100k-250k`,
            t4: `250k-500k`,
            t5: `+500.001`
        },
        step3: {
            title: `Selecione o tipo e o estilo`,
            text0: `O tipo serve para escolher uma das nossas categorias`,
            text1: `O estilo define como ficará`,
            t0: `Olá mundo`,
            t1: `Minimalista`,
            t2: `Moderno`,
            t3: `Clássico`,
            x0: `Complexo`,
            x1: `Simples`,
        },
        step4: {
            title: `Deseja o modo editor avançado ou básico?`,
            t0: `Avançado`,
            t1: `Básico`,
        },
    }, 
    flags: {
        en: `Inglês`,
        es: `Espanhol`,
        fr: `Francês`,
        de: `Alemão`,
        it: `Italiano`,
        ru: `Russo`,
        pt: `Português`,
        nl: `Holandês`,
        sv: `Sueco`,
    },
    title: {
        text0: 'Passo',
        text1: 'de',
        step0: 'Idioma',
        step1: 'Perfil',
        step2: 'Objetivo',
        step3: 'Contexto',
        step4: 'Bônus',
        step5: 'Empresa',
        step6: 'Tamanho',
        step7: 'Tipo de Software',
        step8: 'Contratação',
    },
    footer: {
        text: 'Inscreva-se em nossos e-mails',
        button0: 'Anterior',
        button1: 'Próximo',
    },
    step0: {
        t0: `Escolha o idioma`,
        t1: `Ao escolher, o site mudará`,
        t2: `Estamos integrando mais idiomas atualmente`,
    },
    step1: {
        t0: `Qual é o seu nome?`,
        t1: `Prazer em conhecê-lo`,
        t2: `Nome`,
        t3: `Sobrenome`,
        t4: `Telefone`,
        t5: `E-mail`,
    },
    step2: {
        t0: `Para quem você precisa disso?`,
        t1: `Selecione a opção do seu propósito`,
        t2: `Minha empresa`,
        t3: `Para mim`,
        t4: `Clientes`,
    },
    step3: {
        t0: `Como você se descreve? `,
        t1: `Startup`,
        t2: `Pequena empresa`,
        t3: `Empresa média`,
        t4: `Grande empresa`,
    },
    step4:{
        t0: `Você é estudante?`,
        t1: `Sim`,
        t2: `Não`,
    },
    step5:{
        t0: `O que melhor te descreve?`,
        t1: `Freelancer`,
        t2: `Agência`,
    },
    step6:{
        t0: `Qual é o tamanho da sua equipe de trabalho?`,
    },
    step7:{
        t0: `Que tipo de site você está buscando construir hoje?`,
        t1: `Site de negócios`,
        t2: `Loja de e-commerce`,
        t3: `Portfólio`,
        t4: `Blog`,
        t5: `Análise`,
        t6: `Outro`,
    },
    step8:{
        t0: `Você está interessado em usar o Aythen?`,
        t1: `Sim`,
        t2: `Não`,
        t3: `Ainda não tenho certeza`,
    },
}

export default form
