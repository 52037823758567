const web = {
    t1: {
        '01': `Моделируйте свои`,
        '02': `данные`,
        '03': `продвинуто,`,
        '04': `предсказывайте вашу`,
        '05': `матрицу`,
        '06': `в любой возможной реальности и анализируйте`,
        '07': `большие данные`,
        '08': `с векторизованными базами данных`,
    },
    t2: `НАЧНИТЕ СЕЙЧАС`,
    t3: `На вашей новой операционной системе`,
    t4: {
        '01': `Оффлайн`,
        '02': `Онлайн`,
        '03': `Публичный`,
        '04': `Приватный`,
    },
    tassistant: {
        '01': `Скорость`,
        '02': `Текстура`,
        '03': `Обработка`,
    },
    t5: `Мир возможностей`,
    t6: {
        '01': `Благодаря`,
        '02': `векторизованным базам данных,`,
        '03': `вы переходите от`,
        '04': `статических данных`,
        '05': `к`,
        '06': `динамическим данным.`,
        '07': `Работа с большим количеством данных одновременно трудна, поэтому`,
        '08': `нулевая задержка в определенных измерениях`,
        '09': `необходима для быстрого и универсального доступа.`,
    },
    t7: `Войдите сейчас`,
    t8: `СЭКОНОМЬТЕ ВРЕМЯ . СЭКОНОМЬТЕ ВРЕМЯ . СЭКОНОМЬТЕ ВРЕМЯ`,
    t9: `Сделайте свои выводы`,
    t10: `Информация - сила`,
    t11: {
        '01': `Теперь вы можете понять ваши данные!`,
        '02': `Через сегментацию, с концепцией`,
        '03': `K-Means,`,
        '04': `у вас есть инструмент для анализа любых данных для измерения.`,
    },
    t12: `Начните сейчас`,
    t13: `Место доступно в облаке. Все масштабируется на частных и безопасных инстансах.`,
    t14: `раз быстрее, чем работа с традиционными базами данных.`,
    t15: `Возможные представления для импорта ваших метрик и KPI.`,
    t16: `Анализ данных по измерениям`,
    t17: `Каждое измерение имеет агентов.`,
    t18: {
        '01': `В каждом измерении вы можете создать`,
        '02': `потоки агентов`,
        '03': `для создания сетей взаимодействий.`
    },
    t19: {
        '01': `В каждом агенте вы выберете`,
        '02': `стратегию`,
        '03': `для связи`,
        '04': `каждого данных.`,
        '05': `каждого данных.`,
    },
    t20: `Начните ваш бесплатный период`,
    t21: `Измерения`,
    t22: `Агенты`,
    t23: `Идеи по управлению данными`,
    t24: `Ваши данные - первый шаг`,
    t25: {
        '01': `Они - ваш ключ для создания`,
        '02': `потрясающих приложений. Это не просто информация,`,
        '03': `это сырье`,
        '04': `для создания`,
        '05': `инновационных идей и их анализа.`,
        '06': `Как мы работаем с данными?`,
    },
    t26: `Ваше рабочее пространство`,
    t27: `Храните всю нужную вам информацию в вашем частном или общедоступном облаке в соответствии с вашими потребностями.`,
    t28: `Тепловая карта`,
    t29: `Представление, показывающее распределение и концентрацию определенных значений в наборе данных.`,
    t30: `Динамические вопросы`,
    t31: `Создание интерактивных опросов, где вопросы могут адаптироваться к предварительно существующим данным.`,
    t32: `Настраиваемые диаграммы`,
    t33: `Позволяющие выбирать тип диаграммы, данные для визуализации и настройку аспектов, таких как цвета и метки.`,
    t34: `Видеовызовы`,
    t35: `Реальное время общения через видео и аудио, облегчающее сотрудничество и взаимодействие.`,
    t36: `Контракты`,
    t37: `Создание, управление и электронное выставление счетов по контрактам, оптимизация юридических и административных процессов.`,
    t38: `Роли и разрешения`,
    t39: `Назначайте различные роли и уровни доступа пользователям в вашей собственной команде для создания вашей организации.`,
    t40: `Синхронизация команды`,
    t41: `Реальное сотрудничество над работой между несколькими пользователями, обеспечивая автоматическое сохранение внесенных изменений.`,
    t42: `Все ваши контакты`,
    t43: `Централизуйте и организуйте все контакты. Легко управляйте и получайте доступ к контактной информации.`,
    t44: `Научитесь делать это, это очень просто`,
    t45: `Изменить измерения`,
    t46: {
        '01': `Сделайте свои данные интеллектуальными, подключаемыми, последовательными, безопасными, эффективными и доступными. Усилите свои анализы через`,
        '02': `наш доступ.`
    },
    t47: {
        '01': `Начните с `,
        '02': `руководства`,
        '03': `для понимания различных частей платформы.`,
    },
    t48: `Зарегистрируйтесь СЕЙЧАС`,
    t49: `Оцените свое время и автоматизируйте свои данные`,
    t50: {
        '01': `И начните переключать измерения в нашей облачной операционной системе. Анализируйте и понимайте шаблоны для`,
        '02': `работы с вашими данными.`,
    },


    footer: {
        t00: "ГЛАВНАЯ",
        t01: "Ваша команда",
        t02: "Обновления",
        t03: "Цифровой набор",
        t04: "Консультации",
        t05: "Учеба",
    
        t10: "ССЫЛКИ",
        t11: "Команда",
        t12: "Фрилансеры",
        t13: "Условия",
        t14: "Характеристики",
        t15: "Калькулятор",
        t16: "Планы",
    
        t20: "МОДУЛЬ ИИ",
        t21: "Голос",
        t22: "Изображение и Видео",
        t23: "Создание контента",
        t24: "Создание медиа",
    
        t30: "ПОДДЕРЖКА AYTHEN",
        t31: "Пользовательское соглашение",
        t32: "Политика конфиденциальности",
        t33: "Сбор и использование данных",
        t34: "Руководство по контенту",
    
        t40: "ДЛЯ ПРОЕКТИРОВАНИЯ",
        t41: "Редактор",
        t42: "Приложения",
        t43: "Облако",
        t44: "Плагины",
        t45: "RPA",
    
        t50: "ДЛЯ ПОИСКА",
        t51: "Библиотека",
        t52: "Пресеты",
        t53: "Devops",
        t54: "IDE",
        t55: "Песочница",
    
        t60: "ДЛЯ СТРОИТЕЛЬСТВА",
        t61: "UX/AI",
        t62: "Перевод",
        t63: "Документы ИИ",
        t64: "Поддержка ИИ",
        t65: "Сотрудничество",
    
        t70: "ДЛЯ ЗАЩИТЫ",
        t71: "Блокчейн",
        t72: "Данные",
        t73: "Пивот",
        t74: "Академия",
        t75: "Фрилансеры",
    }
    
}

export default web


