import React from 'react'

import SectionImage from './assets/section-image.png'
import DragNavImage from './assets/drag-nav.png'

import Card1Image from './assets/img-1.png'
import Card2Image from './assets/img-2.png'
import Card3Image from './assets/img-3.png'
import Card4Image from './assets/img-4.png'
import Card5Image from './assets/img-5.png'

const translate = () => {
  return (
    <div className="relative w-full sm:h-full  lg:mb-[140px]" id="translate">
      <div className="container mx-auto">
        <section className="home-section-1 ">
          <div className="translate-gradient"></div>
          <div className="flex">
            <div className="relative lg:pt-[10px] z-[0]">
              <div className="mb-5 flex items-center ml-[-8px] absolute left-0 top-[10px] w-[300px]">
                <div className="bg-[#32D1C8] z-[-1]  w-[40px] h-[40px] p-3 flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M19.285 10.1429V3.28571C19.285 2.67951 19.0442 2.09812 18.6155 1.66947C18.1869 1.24082 17.6055 1 16.9993 1H10.1422C9.53595 1 8.95457 1.24082 8.52592 1.66947C8.09726 2.09812 7.85645 2.67951 7.85645 3.28571V10.1429C7.85645 10.7491 8.09726 11.3304 8.52592 11.7591C8.95457 12.1878 9.53595 12.4286 10.1422 12.4286H16.9993C17.6055 12.4286 18.1869 12.1878 18.6155 11.7591C19.0442 11.3304 19.285 10.7491 19.285 10.1429Z"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.5707 7.86035H3.28498C2.67877 7.86035 2.09739 8.10117 1.66874 8.52982C1.24008 8.95848 0.999268 9.53986 0.999268 10.1461V17.0032C0.999268 17.6094 1.24008 18.1908 1.66874 18.6195C2.09739 19.0481 2.67877 19.2889 3.28498 19.2889H3.28841L10.1456 19.2775C10.7512 19.2766 11.3317 19.0354 11.7596 18.6068C12.1875 18.1783 12.4278 17.5974 12.4278 16.9918V14.7141M6.71355 12.4318H3.28498"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.42786 14.1431L7.285 15.2859C6.90329 15.6665 5.95243 16.2379 4.42786 17.0002"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.42786 12.4319C4.80957 13.7645 5.381 14.7176 6.14214 15.2879C6.90328 15.8582 7.85643 16.4285 8.99928 16.9999M10.1421 10.1428L13.5707 3.28564L16.9993 10.1428M15.8564 7.85707H11.285"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="ml-4">
                  <h4 className="text-[#32D1C8] font-extrabold uppercase text-[20px]">
                    TRANSLATE
                  </h4>
                </div>
              </div>
              <div className="translate-left-border mt-[70px]"></div>
            </div>
            <div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="col-span-2 relative">
                  <div className="mt-[100px]">
                    <div className="lg:pl-10 ">
                      <p className="text-[24px] text-[#554D56] lg:text-[48px] leading-tight drop-shadow-xl text-left pl-7 lg:pl-0">
                        <span className="text-[#32D1C8]">
                          Crea tu proyecto multiplataforma.
                        </span>
                        Importa y exporta tu archivo a cualquier lenguaje de
                        programación, benefíciate de adaptar cualquier creación
                        a contextos de escalabilidad y modularización para
                        expandir sin límites tu proyecto.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="text-end relative">
                  <div className="absolute lg:right-[0px] lg:top-[-50px] top-[-250px] right-[0px]  z-20">
                    <img
                      src={DragNavImage}
                      className="lg:w-[180px] z-20 w-[70px]"
                    />
                  </div>
                  <div className="w-full lg:mt-[50px] ">
                    <img src={SectionImage} className="" />
                  </div>
                </div>
              </div>

              <div className="">
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3">
                  <div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto]  lg:h-[450px] relative lg:mt-[0px] mb-8 lg:mb-0">
                      <p className="mt-0 lg:text-[13px] uppercase">
                        PRIMER BENEFICIO
                      </p>
                      <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                        Portabilidad del código
                      </h5>
                      <div className="lg:w-[90%]">
                        <p className="lg:text-[15px] mt-5">
                          Con un traductor de código, es posible desarrollar una
                          aplicación o proyecto en un lenguaje específico y
                          luego traducirlo a otros lenguajes sin tener que
                          reescribir todo el código desde cero. Esto aumenta la
                          portabilidad y la flexibilidad del código.
                        </p>
                        <img
                          src={Card1Image}
                          className="lg:absolute bottom-[0px] mx-auto w-[200px] lg:w-[80%] z-20"
                        />
                      </div>
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mt-5 mb-8 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          CUARTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Acceso a habilidades y conocimientos{' '}
                        </h5>
                      </div>
                      <div className="lg:w-[50%]">
                        <p className="lg:text-[15px]">
                          La colaboración en un IDE permite que los
                          desarrolladores compartan y aprovechen las habilidades
                          y conocimientos complementarios de otros miembros del
                          equipo, lo que enriquece el resultado final del
                          proyecto.
                        </p>
                      </div>
                      <img
                        src={Card3Image}
                        className="lg:absolute bottom-[0px] right-[-50px] w-[200px] lg:w-[350px] z-20"
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mb-8 lg:mb-0">
                      <p className="mt-0 lg:text-[13px] uppercase">
                        SEGUNDO BENEFICIO
                      </p>
                      <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                        Aprovechamiento de habilidades existentes
                      </h5>
                      <div className="lg:w-[90%]">
                        <p className="lg:text-[15px]">
                          Los desarrolladores pueden utilizar sus habilidades y
                          conocimientos en un lenguaje específico para crear el
                          código original y luego traducirlo a otros lenguajes
                          donde se pueda ejecutar sin necesidad de aprender un
                          nuevo lenguaje desde cero.
                        </p>
                      </div>
                      <img
                        src={Card2Image}
                        className="lg:absolute bottom-[-50px] right-[0px] z-20 w-[200px] lg:w-[50%]"
                      />
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mt-5 mb-8 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          QUINTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Consistencia del código
                        </h5>
                      </div>
                      <div className="lg:w-[100%]">
                        <p className="lg:text-[15px]">
                          Al traducir el código a diferentes lenguajes, se
                          mantiene una mayor consistencia en la estructura y
                          estilo del código, lo que facilita la colaboración
                          entre diferentes equipos de desarrollo.
                        </p>
                      </div>
                      <img
                        src={Card4Image}
                        className="z-20 lg:absolute bottom-[-50px] left-[20%] w-[200px] lg:w-[70%]"
                      />
                    </div>
                  </div>
                  <div className="relative md:col-span-2 lg:col-span-1 md:grid grid-cols-2 lg:block gap-4">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 relative lg:mt-[250px] mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          TERCER BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Adaptabilidad a plataformas
                        </h5>
                        <p className="lg:text-[15px] mt-3">
                          Un traductor de código permite adaptar rápidamente una
                          aplicación para que funcione en diferentes plataformas
                          o entornos, lo que ahorra tiempo y esfuerzo en el
                          proceso de desarrollo.
                        </p>
                      </div>
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 relative h-[auto] lg:h-[450px]  mt-5 mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          SEXTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Migración y actualización tecnológica
                        </h5>
                        <p className="lg:text-[15px] mt-3">
                          Un traductor de código puede ser útil para migrar
                          aplicaciones legadas a tecnologías más modernas y
                          mantener la funcionalidad existente.
                        </p>
                      </div>
                      <img
                        src={Card5Image}
                        className="lg:absolute bottom-[0px] z-20 "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default translate
