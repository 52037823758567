const iam = {
    login: {
        t1: `Войти в систему`,
        t2: `И получить доступ к личному кабинету`,
        t3: `ИЛИ`,
        t4: `Запомнить меня`,
        t5: `Забыли пароль?`,
        t6: `ВОЙТИ`,
        t7: `У вас нет аккаунта?`,
        t8: `Зарегистрироваться`
    },
    table: {
        t1: `Мы не нашли данных в вашей векторной базе`,
        t2: `Создать`,
        t3: `Документация для быстрого старта`,
    },
    header: {
        t1: `Вернуться назад`,
        t2: `Управление идентичностью и доступом (IAM)`,
        t3: `Пользователи`,
        t4: `Приложения`,
        t5: `Политики`,
        t6: `Ключи API`,
        t7: `Логи`,
    },
    users: {
        t1: `Ниже отображается список пользователей этой организации. 
            Вы можете просмотреть дополнительную информацию о каждом пользователе.`,
        t2: `Что такое пользователи`,
        t3: `Добавить пользователя`,
        t4: `Пригласить пользователя(ей)`,
        t5: `Введите адрес электронной почты каждого пользователя, которого вы хотите пригласить, разделенный запятыми.`,
        t6: `Введите метки значимых ключей`,
        t7: `Метки значимых ключей помогают вам организовать своих пользователей. Вы можете присвоить до 10 меток каждому пользователю.`,
        t8: `Добавить к существующей группе (необязательно)`,
        t9: `Пригласить`,
        t10: `Информация о пользователе`,
        t11: `Состояние`,
        t12: `Активирован`,
        t13: `Тип`,
        t14: `Гость`,
        t15: `Присоединился`,
        t16: `Последний доступ`,
        t17: `Удалить пользователя`,
        t18: `Удаляя пользователя из этой организации, его ключи API и любая политика, непосредственно связанная с ними, станут сиротами.`,
        t19: `Удалить пользователя`,
    },
    apps: {
        t1: `Ниже отображается список приложений в этой организации. Вы можете просмотреть дополнительную информацию о каждом приложении.`,
        t2: `Что такое приложения?`,
        t3: `Создать приложение`,
        t4: `Приложения`,
        t5: `Создать приложение`,
        t6: `Введите имя и опциональное описание`,
        t7: `Имя`,
        t8: `Имя вашего приложения может содержать только алфавитно-цифровые символы, точки и тире.`,
        t9: `Описание`,
        t10: `Введите метки значимых ключей (необязательно)`,
        t11: `Метки значимых ключей помогают вам организовать свои приложения. Вы можете присвоить до 10 меток каждому приложению.`,
        t12: `Введите метки значимых ключей (необязательно)`,
        t13: `Создать приложение`,
    },
    polices: {
        t1: `Это список политик этой организации. Вы можете просмотреть дополнительную информацию о каждой политике.`,
        t2: `Что такое политики?`,
        t3: `Создать политику`,
        t4: `Политики`,
        t5: `Правила`,
        t6: `Правило состоит из области (например, уровня проекта или уровня организации) и одного или более наборов разрешений (например, "перечислить все экземпляры").`,
        t7: `Получите дополнительную информацию о правилах.`,
        t8: `Правило #1`,
        t9: `Сделать`,
        t10: `Организация`,
        t11: `Наборы разрешений IAM и выставления счетов могут быть выбраны только на уровне организации.`,
        t12: `Доступ к ресурсам`,
        t13: `Дает доступ к ресурсам (экземплярам, хранилищу объектов, базам данных и т.д.) в ваших проектах.`,
        t14: `Доступ к функциям организации`,
        t15: `Предоставьте доступ к IAM, выставлению счетов, поддержке и билетам на злоупотребление и управлению проектами, все управляемое на уровне организации.`,
        t16: `Валидировать`,
        t17: `Наборы разрешений`,
        t18: `Менеджер организации`,
        t19: `Добавить правило`,
    },
    apis: {
        t1: `Ниже отображается список ключей API этой организации.`,
        t2: `Как создать ключи API`,
        t3: `Сгенерировать ключ API`,
        t4: `API`,
        t5: `Сгенерировать ключ API`,
        t6: `Скопируйте и сохраните свой секретный ключ. Он будет отображаться только один раз.`,
        t7: `Управляйте своими векторами через доступ IAM, добавляя свои
            Ключи API для использования`,
        t8: `ID ключа доступа`,
        t9: `Секретный ключ`,
        t10: `Закрыть сгенерировать ключ`,
        t11: `Сгенерировать ключ API`,
        t12: `Выбрать носителя ключа API`,
        t13: `Я сам (пользователь IAM)`,
        t14: `Приложение`,
        t15: `Описание`,
        t16: `Срок действия`,
        t17: `Будет ли этот ключ API использоваться для хранения объектов?`,
        t18: `Я сам (пользователь IAM)`,
        t19: `Я сам (пользователь IAM)`,
        t20: `Сгенерировать ключ API`,
        t21: `Закрыть`,
    },
    logs: {
        t1: `Ниже отображается список ваших записей ресурсов IAM. Ресурсы IAM могут быть пользователями, приложениями, группами, ключами API и политиками IAM. Вы можете получить дополнительную информацию о каждом из них.`,
        t2: `Как понять мои записи?`,
        t3: `Фильтровать по точному ID ресурса IAM`,
        t4: `Дата`,
        t5: `Дата IAM`,
        t6: `Категория`,
        t7: `категория`,
        t8: `Действие`,
        t9: `Действие`,
        t10: `Добавить`,
        t11: `Сбросить`,
        t12: `Фильтровать`,
        t13: `Логи`,
        t14: `Информация о логах`,
        t15: `Состояние`,
        t16: `Активирован`,
        t17: `Тип`,
        t18: `Гость`,
        t19: `Присоединился`,
        t20: `Последний доступ`,
        t21: `Удалить запись`,
        t22: `Удаляя запись из этой организации, ее ключи API и любая политика, непосредственно связанная с ними, станут сиротами.`,
        t23: `Удалить запись`,
    },
}

export default iam