import { createSlice } from '@reduxjs/toolkit';

import {
  fetchsAddon,
  fetchAddon,
  addAddon,
  updateAddon,
  deleteAddon,

  fetchsTemplate,
  addTemplate,

  fetchsMapAddon,

  addI18Addon,
  fetchsI18Addon,

  codeAddon,
  codeFetch,
  codeDelete,
  codeInsertAddon
} from '../actions/addon'

export const initialComponent = {
  id: '',
  owner: '',
  version: '',
  title: '',
  description: '',
  data: '',
  updatedAt: '',
  createdAt: ''
}

const addonSlice = createSlice({
  name: 'addons',
  initialState: {
    status: null,
    loading: false,

    code: null,

    element: null,

    i18: {},
    map: {},

    addon: {},
    addons: [],
    templates: [], 
    connections: [], 
    components: [],

    infoResume: {
      template: 10,
      component: 10,
      management: 14,
      token: 20
    },

    codes: {
      type: 'web',
      total: 0,
      items: [],
      item: {}
    }
  },
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload
    },

    setAddon: (state, action) => {
      state.addon = action.payload
    },
    setTemplates: (state, action) => {
      state.templates = action.payload
    },
    setConnections: (state, action) => {
      state.connections = action.payload
    },

    setElement: (state, action) => {
      state.element = action.payload
    },

    setCode: (state, action) => {
      state.code = action.payload
    },

    setCodeType: (state, action) => {
      state.codes.type = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchsAddon.fulfilled, (state, action) => {
        state.addons = action.payload;
      })
      .addCase(fetchsAddon.rejected, (state, action) => {
        state.error = action.error.message;
      })


      .addCase(fetchAddon.fulfilled, (state, action) => {
        state.addon = action.payload.addon;
        state.templates = action.payload.templates;

        const { template, component, management, token } = resumeTemplate(action.payload.templates)

        state.infoResume = {
          template,
          component,
          management,
          token,
        }
      })
      .addCase(fetchAddon.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message;
      })


      .addCase(addAddon.fulfilled, (state, action) => {
        const newAddon = action.payload;
        const existingAddonIndex = state.addons.findIndex((existingAddon) => existingAddon.id === newAddon.id);

        if (existingAddonIndex !== -1) {
          state.addons[existingAddonIndex] = { ...state.addons[existingAddonIndex], ...newAddon };
        } else {
          state.addons.push(newAddon);
        }
      })

      .addCase(addAddon.rejected, (state, action) => {
        state.error = action.error.message;
      })


      .addCase(updateAddon.fulfilled, (state, action) => {
        state.addon = action.payload;
      })
      .addCase(updateAddon.rejected, (state, action) => {
        state.error = action.error.message;
      })


      .addCase(deleteAddon.fulfilled, (state, action) => {
        state.addons = state.addons.filter(addon => addon.id !== action.payload);
      })


      .addCase(fetchsTemplate.fulfilled, (state, action) => {
      })
      .addCase(addTemplate.fulfilled, (state, action) => {
      })

      .addCase(fetchsMapAddon.fulfilled, (state, action) => {
        state.map = action.payload
      })

      .addCase(fetchsI18Addon.fulfilled, (state, action) => {
        if (action.payload?.id) {
          if (!action.payload.text.store) {
            state.i18[action.payload.id] = action.payload.text
          }
        }
      })
      .addCase(addI18Addon.fulfilled, (state, action) => {
        if (action.payload?.id) {

          state.i18[action.payload.id] = action.payload.text
        }
      })
 
      .addCase(codeAddon.fulfilled, (state, action) => {
        state.codes.items.push(action.payload);
      })
      .addCase(codeFetch.fulfilled, (state, action) => {
        state.codes.items = action.payload;
      })
      .addCase(codeDelete.fulfilled, (state, action) => {
        state.codes.items = state.codes.items.filter(item => item.id !== action.payload.id);
      })
      .addCase(codeInsertAddon.fulfilled, (state, action) => {
      })


  },
});

export const {
  setStatus,

  setCodeType,
  
  setAddon,
  setTemplates,
  setConnections,
  setElement,

  setCode,
} = addonSlice.actions;

export default addonSlice.reducer;







const resumeTemplate = (templates) => {
  let component = 0
  let management = 0

  templates.map((template, index) => {
    const components = template.data.components
    component += components.length

    components.map((component, index) => {
      if (component.html?.data) {
        management += component.html.data.length
      }

      component.css?.map((css, index) => {
        management += css.data.length
      })
      component.script?.map((css, index) => {
        management += css.data.length
      })
      component.matrix?.map((matrix, index) => {
        management += matrix.data.length
      })
    })
  })


  return {
    template: templates.length,
    component: component,
    management: management,
    token: 0
  }
}



