export const languages = [
	'Inglés (US)',
	'Español',
	'Francés',
	'Alemán',
	'Italiano',
	'Portugués',
	'Chino',
	'Japonés',
	'Coreano',
	'Ruso',
	'Árabe',
	'Hindi',
	'Bengalí',
	'Punjabi',
	'Urdu',
	'Javanés',
	'Vietnamita',
	'Turco',
	'Tamil',
	'Telugú',
	'Maratí',
	'Tailandés',
	'Griego',
	'Hebreo',
	'Holandés',
	'Sueco',
	'Noruego',
	'Danés',
	'Finlandés',
	'Polaco',
	'Checo',
	'Húngaro',
	'Rumano',
	'Búlgaro',
	'Serbio',
	'Croata',
	'Eslovaco',
	'Esloveno',
	'Ucraniano',
	'Lituano',
	'Letón',
	'Estonio',
	'Mongol',
	'Amhárico',
	'Bielorruso',
	'Albanés',
	'Bosnio',
	'Macedonio',
	'Georgiano',
	'Armenio',
]

export const countries = [
	'Alemania',
	'Argentina',
	'Australia',
	'Austria',
	'Bélgica',
	'Bolivia',
	'Brasil',
	'Canadá',
	'Chile',
	'China',
	'Colombia',
	'Corea del Sur',
	'Costa Rica',
	'Cuba',
	'Dinamarca',
	'Ecuador',
	'Egipto',
	'El Salvador',
	'Emiratos Árabes Unidos',
	'España',
	'Estados Unidos',
	'Filipinas',
	'Finlandia',
	'Francia',
	'Grecia',
	'Guatemala',
	'Honduras',
	'India',
	'Indonesia',
	'Irlanda',
	'Italia',
	'Japón',
	'México',
	'Noruega',
	'Nueva Zelanda',
	'Panamá',
	'Países Bajos',
	'Perú',
	'Polonia',
	'Portugal',
	'Reino Unido',
	'República Checa',
	'Rusia',
	'Sudáfrica',
	'Suecia',
	'Suiza',
	'Turquía',
	'Ucrania',
	'Venezuela',
]

export const timezones = [
	'(GMT+00:00) Reino Unido - Londres',
	'(GMT+01:00) España - Madrid',
	'(GMT+01:00) Francia - París',
	'(GMT+01:00) Alemania - Berlín',
	'(GMT+01:00) Italia - Roma',
	'(GMT+01:00) Bélgica - Bruselas',
	'(GMT+01:00) Suiza - Ginebra',
	'(GMT+02:00) Grecia - Atenas',
	'(GMT+02:00) Sudáfrica - Ciudad del Cabo',
	'(GMT+02:00) Egipto - El Cairo',
	'(GMT+02:00) Turquía - Estambul',
	'(GMT+03:00) Rusia - Moscú',
	'(GMT+03:00) Arabia Saudita - Riad',
	'(GMT+03:00) Kenia - Nairobi',
	'(GMT+04:00) Emiratos Árabes Unidos - Dubái',
	'(GMT+04:00) Azerbaiyán - Bakú',
	'(GMT+05:30) India - Nueva Delhi',
	'(GMT+06:00) Bangladesh - Daca',
	'(GMT+07:00) Tailandia - Bangkok',
	'(GMT+07:00) Vietnam - Hanói',
	'(GMT+08:00) China - Pekín',
	'(GMT+08:00) Singapur',
	'(GMT+09:00) Japón - Tokio',
	'(GMT+09:30) Australia - Darwin',
	'(GMT+10:00) Australia - Sídney',
	'(GMT+11:00) Islas Salomón - Honiara',
	'(GMT+12:00) Nueva Zelanda - Auckland',
	'(GMT-01:00) Cabo Verde - Praia',
	'(GMT-02:00) Brasil - Islas del Atlántico',
	'(GMT-03:00) Argentina - Buenos Aires',
	'(GMT-03:00) Brasil - São Paulo',
	'(GMT-03:00) Uruguay - Montevideo',
	'(GMT-03:00) Chile - Santiago',
	'(GMT-04:00) Venezuela - Caracas',
	'(GMT-04:00) Paraguay - Asunción',
	'(GMT-04:00) Bolivia - La Paz',
	'(GMT-04:00) República Dominicana - Santo Domingo',
	'(GMT-05:00) Perú - Lima',
	'(GMT-05:00) Colombia - Bogotá',
	'(GMT-05:00) Ecuador - Quito',
	'(GMT-05:00) México - Ciudad de México',
	'(GMT-05:00) Estados Unidos - Nueva York (EST)',
	'(GMT-06:00) México - Cancún',
	'(GMT-06:00) Costa Rica - San José',
	'(GMT-06:00) El Salvador - San Salvador',
	'(GMT-07:00) Estados Unidos - Denver (MST)',
	'(GMT-08:00) Estados Unidos - Los Ángeles (PST)',
	'(GMT-09:00) Estados Unidos - Alaska (AKST)',
	'(GMT-10:00) Hawái - Honolulu',
	'(GMT-12:00) Islas Baker y Howland - Pacífico',
]

export const dateFormats = ['31/12/2024', '12/31/2024', '2024-12-31']

export const hourFormats = ['1:00pm', '13:00']

export const durations = [
	'15 minutos',
	'20 minutos',
	'30 minutos',
	'45 minutos',
	'60 minutos',
	'90 minutos',
	'120 minutos',
]

export const guestPermissions = [
	'Modificar evento',
	'Invitar a otros',
	'Ver lista de invitados',
]

export const invitationsToCalendar = [
	'De todos',
	'Solo si el remitente es conocido',
	'Cuando respondo a la invitación por correo electrónico',
]

export const notifications = [
	'Desactivadas',
	'Notificaciones de escritorio',
	'Alertas',
]

export const startWeek = ['Sabado', 'Domingo', 'Lunes']

export const customViews = [
	'2 días',
	'3 días',
	'4 días',
	'5 días',
	'6 días',
	'7 días',
	'2 semanas',
	'3 semanas',
	'4 semanas',
]

export const privacyOfEmailEvents = [
	'Calendario por defecto',
	'Privado',
	'Solo yo',
]
