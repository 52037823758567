const drive = {
    t1: `Sök dokumentation`,
    t2: `Skapa ny`,
    t3: `Ny mapp`,
    t4: `Nytt lanceDB`,
    t5: `Ladda upp fil`,
    t6: `Ladda upp mapp`,
    t7: `Använd`,
    t8: `Mina dokument`,
    t9: `Mina dokument`,
    t10: `Snart`,
    t11: `Prioriterade`,
    t12: `Delade`,
    t13: `Nyligen`,
    t14: `Utvalda`,
    t15: `Papperskorg`,
    t16: `Papperskorg`,
    t17: `Ett ögonblick...`,
    t18: `Avbryt`,
    t19: `Skapa`,

    x1: `Namn`,
    x2: `Senaste ändring`,
    x3: `Välj alla`,
    x4: `Ta bort alla`,
    x5: `Nyligen filer`,
    x6: `LanceDB`,
    x7: `Visa DB`,
    x8: `Visa drive`,
    x9: `Projekt`,
    x10: `Namn`,
    x11: `Namn`,
    x12: `Storlek`,
    x13: `Senaste ändring`,
    x14: `Valda element`,
    x15: `Delat med`,
}

export default drive