const vector = {
    t1: `Crie um novo vetor para o seu projeto`,
    t2: `Importe, salve, trabalhe, analise e tire conclusões de qualquer estrutura de dados. 
Armazene e manipule as informações com total liberdade através de espaços multidimensionais. `,
    t3: `Novo vetor`,
    t4: `Vetores`,
    t5: `Vetor`,
    t6: `Dimensão`,
    t7: `Tamanho`,
    t9: `Criado em`,
    t10: `Matriz`,
    t11: `As bases de dados vetoriais permitem criar experiências únicas impulsionadas pela busca vetorial.  
Aceleram o desenvolvimento de aplicações de IA.`,
    t12: `Nova Matriz`,
    t13: `Documentação de Início Rápido`,
    node: {
        data: {
            title: `Adicione dados ao seu relatório`,
            text: `Uma vez carregados, os seus dados aparecerão no`,
            label: `Campos`,
            excel: `Importar dados do Excel`,
            sql: `Importar dados do SQL`,
            blank: `Cole dados em branco`,
            export: `Exportar dados de exemplo`,
            info: `Obtenha dados de outra fonte`,
        }
    },
    dashboard: {
        t1: `Lembre-se de que sempre poderá modificar e reunir os gráficos que deseja destacar 
como favoritos a partir das informações que você decidiu analisar nos vetores.`,
        t2: `Saiba mais`,
    }
}

export default vector
