const VERSION = process.env.REACT_APP_VERSION;

const Icon1 = require(`@app/assets/steps/1.svg`).default;
const Icon2 = require(`@app/assets/steps/2.svg`).default;
const Icon3 = require(`@app/assets/steps/3.svg`).default;
const Icon4 = require(`@app/assets/steps/4.svg`).default;
const Icon5 = require(`@app/assets/steps/5.svg`).default;
const Icon6 = require(`@app/assets/steps/6.svg`).default;
const Icon7 = require(`@app/assets/steps/7.svg`).default;
const Icon8 = require(`@app/assets/steps/8.svg`).default;
const Icon9 = require(`@app/assets/steps/9.svg`).default;
const Icon10 = require(`@app/assets/steps/10.svg`).default;
const Icon11 = require(`@app/assets/steps/11.svg`).default;
const Icon12 = require(`@app/assets/steps/12.svg`).default;
const Icon13 = require(`@app/assets/steps/13.svg`).default;
const Icon14 = require(`@app/assets/steps/14.svg`).default;
const Icon15 = require(`@app/assets/steps/15.svg`).default;
const Icon16 = require(`@app/assets/steps/16.svg`).default;
const Icon17 = require(`@app/assets/steps/17.svg`).default;
const Icon18 = require(`@app/assets/steps/18.svg`).default;
const Icon19 = require(`@app/assets/steps/19.svg`).default;
const Icon20 = require(`@app/assets/steps/20.svg`).default;
const Icon21 = require(`@app/assets/steps/21.svg`).default;
const Icon22 = require(`@app/assets/steps/22.svg`).default;
const Icon23 = require(`@app/assets/steps/23.svg`).default;
const Icon24 = require(`@app/assets/steps/24.svg`).default;
const Icon25 = require(`@app/assets/steps/25.svg`).default;
const Icon26 = require(`@app/assets/steps/26.svg`).default;
const Icon28 = require(`@app/assets/steps/28.svg`).default;
const Icon29 = require(`@app/assets/steps/29.svg`).default;
const Icon30 = require(`@app/assets/steps/30.svg`).default;
const Icon31 = require(`@app/assets/steps/31.svg`).default;
const Icon32 = require(`@app/assets/steps/32.svg`).default;
const Icon33 = require(`@app/assets/steps/33.svg`).default;

const renderHelp = ({ title, text, icon }) => {
    return (`<div class="steps-intro">
<img src=${icon} />
<div class="info">
<b>
${title} 
</b>
<p>
${text} 
</p>
</div>
<div class="buttons">
    <button onclick="introJs().nextStep()">
        Anterior
    </button>
    <button class="next" onclick="introJs().previousStep()">
        Siguiente
    </button >
</div>
</div>`)
}



export const home = [{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="0-1"]`,
    intro: renderHelp({
        icon: Icon1,
        title: `Seu primeiro acesso`,
        text: `Aqui você poderá gerenciar os dados da sua conta, bem como acessar sua licença, suas preferências visuais e sair da sua sessão.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="0-2"]`,
    intro: renderHelp({
        icon: Icon2,
        title: `Seus primeiros dados`,
        text: `Vamos começar com algo simples! Em "Editar conta", você preencherá e salvará seus dados pessoais e também poderá alterar o tema.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="0-3"]`,
    intro: renderHelp({
        icon: Icon3,
        title: `Sua administração`,
        text: `Você tem tudo o que precisa para fazer seu projeto avançar em qualquer perspectiva. Seu início, seus dados, documentos, tarefas, faturas e contratos.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-0"]`,
    intro: renderHelp({
        icon: Icon4,
        title: `Seu início > Página inicial`,
        text: `Você encontrará uma visão geral. Primeiro, do seu perfil, organizações que você gerencia, acesso ao seu saldo, seus acessos e seus domínios.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-1"]`,
    intro: renderHelp({
        icon: Icon5,
        title: `Seu resumo > Design`,
        text: `Para seus projetos e domínios, você precisará de designs. Nos Add-ons, você pode criar o que quiser. Assim, você observará o consumo de recursos envolvido.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-2"]`,
    intro: renderHelp({
        icon: Icon6,
        title: `Seu resumo > Dados`,
        text: `De seus projetos e designs, você terá dados. Acesse seus bancos de dados vetorizados e salve seus gráficos mais interessantes.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-3"]`,
    intro: renderHelp({
        icon: Icon7,
        title: `Seu resumo > Status`,
        text: `Atualize sua memória com as diferentes funcionalidades disponíveis, o estado da sua conexão e acesso ao suporte.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="2-0"]`,
    intro: renderHelp({
        icon: Icon8,
        title: `Aythen DB > suas análises`,
        text: `Crie e sincronize todos os dados para criar dimensões e estratégias. Assim, você poderá tomar decisões informadas graças às interações.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="2-0"]`,
    intro: renderHelp({
        icon: Icon9,
        title: `Sua documentação`,
        text: `Aythen aceita qualquer formato de arquivo. Arraste e solte os arquivos desejados. Compartilhe-os e categorize sua relevância.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="3-0"]`,
    intro: renderHelp({
        icon: Icon10,
        title: `Kan Ban > Suas tarefas`,
        text: `Você precisará organizar suas tarefas e sua equipe. Adicione tickets, listas de tarefas, prioridades, estados e verifique o estado geral dos progressos.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="4-0"]`,
    intro: renderHelp({
        icon: Icon11,
        title: `Faturamento eletrônico (OCR)`,
        text: `Preencha seus dados empresariais e gerencie seu saldo, programe lembretes de pagamento e configure seus limites de licença.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icon: Icon12,
        title: `Assinatura eletrônica (Audit trail)`,
        text: `Gerencie todos os seus acordos por meio da compilação de seus contratos. Assine, faça download ou exclua os documentos mais sensíveis.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icon: Icon13,
        title: `Seu primeiro projeto`,
        text: `Com toda a revisão da plataforma. Crie um novo add-on (público ou privado). Selecione configurações e vincule seu domínio. Salve e abra:`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icon: Icon14,
        title: `Seu primeiro projeto`,
        text: `Com toda a revisão da plataforma. Crie um novo add-on (público ou privado). Selecione configurações e vincule seu domínio. Salve e abra:`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-1"]`,
    intro: renderHelp({
        icon: Icon15,
        title: `Seu espaço criativo`,
        text: `Aqui você poderá construir seus fluxos de designs. Crie e conecte para criar telas correlatas. Quer saber como começar?`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-2"]`,
    intro: renderHelp({
        icon: Icon16,
        title: `Crie e salve`,
        text: `Todo o seu fluxo é baseado em nós inteligentes. Eles conectarão suas telas e sincronizarão seus dados. Crie o primeiro e salve sua sessão.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-3"]`,
    intro: renderHelp({
        icon: Icon17,
        title: `Organize sua visualização`,
        text: `Você gosta de grades? De pontos? De manter tudo organizado? Quer saber onde está cada nó o tempo todo? Esses 3 botões ajudarão.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-4"]`,
    intro: renderHelp({
        icon: Icon18,
        title: `Controle sua visualização`,
        text: `Dê zoom para ajustar detalhes ou obter uma visão geral de sua criação. Isso consumirá mais ou menos recursos dependendo do uso.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-5"]`,
    intro: renderHelp({
        icon: Icon19,
        title: `Controle sua criação`,
        text: `Exporte ou importe seus designs e analise onde os usuários são mais ativos graças aos mapas de calor. Assim, você poderá correlacionar com critério.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-6"]`,
    intro: renderHelp({
        icon: Icon20,
        title: `Adicione e sincronize`,
        text: `Adicione conteúdo ao seu design, faça o upload automaticamente para o GitHub ou compartilhe com terceiros. Tudo o que você precisa para criar sem limites.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-7"]`,
    intro: renderHelp({
        icon: Icon21,
        title: `Tem o seu primeiro fluxo?`,
        text: `É possível que nem tudo esteja de acordo com o consumidor. Portanto, você pode dar um clique duplo ou "Alt" + clique no nó que precisa ser aprimorado.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-8"]`,
    intro: renderHelp({
        icon: Icon22,
        title: `Seu espaço criativo individual`,
        text: `Aqui você poderá focar na construção de seu design. Crie com código e conecte novos ou dados existentes para criar telas correlatas.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-9"]`,
    intro: renderHelp({
        icon: Icon23,
        title: `Acesse seu código`,
        text: `Edite e salve em tempo real. Com um efeito espelho, você poderá editar e visualizar o design para fazer qualquer modificação de acordo com sua preferência.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-10"]`,
    intro: renderHelp({
        icon: Icon24,
        title: `Sincronize seus dados`,
        text: `Você encontrará diferentes métodos para salvar e relacionar seu conteúdo com seus bancos de dados. Se tiver dúvidas, verifique nossos comandos.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-11"]`,
    intro: renderHelp({
        icon: Icon25,
        title: `Atalhos rápidos`,
        text: `Volte com todos os nós, dê zoom neste espaço individual ou clique à direita para verificar sua visualização no navegador.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-12"]`,
    intro: renderHelp({
        icon: Icon26,
        title: `Trabalhe de forma eficiente`,
        text: `Faça todas as suas modificações de uma vez com a tela dupla interna para ter acesso rápido a todas as funcionalidades disponíveis.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-0"]`,
    intro: renderHelp({
        icon: Icon28,
        title: `Sua primeira análise`,
        text: `Aqui você poderá construir seus fluxos de dados. Tanto externos quanto sincronizados com o design. Crie interações e tome decisões informadas.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-1"]`,
    intro: renderHelp({
        icon: Icon29,
        title: `Localize seus dados`,
        text: `Pesquise, selecione seus nós com as guias, salve sua versão, faça o download e organize seu fluxo por meio das diferentes funcionalidades.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-2"]`,
    intro: renderHelp({
        icon: Icon30,
        title: `Interaja com seus dados`,
        text: `Para tomar decisões informadas, você precisará fazer perguntas, gráficos ou relações de matrizes por meio de Python / Javascript.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-3"]`,
    intro: renderHelp({
        icon: Icon31,
        title: `Gerencie seu nó`,
        text: `Cada nó inteligente é configurado de diferentes maneiras. Sua flexibilidade com qualquer dado e formato é para qualquer finalidade.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-4"]`,
    intro: renderHelp({
        icon: Icon32,
        title: `Atento a todos os detalhes`,
        text: `Você poderá ver e modificar o nome. Assim, você sempre poderá pesquisá-lo para encontrar seus dados. À direita, você terá quando foi enviado e quanto espaço ocupa.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-5"]`,
    intro: renderHelp({
        icon: Icon33,
        title: `Configure seus dados`,
        text: `Faça backup com versões, incorpore dados de terceiros, gere uma pergunta ou expanda o nó para ver todos os dados.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-6"]`,
    intro: renderHelp({
        icon: Icon33,
        title: `Pré-visualize seus dados`,
        text: `Na janela do nó, você poderá ver todos os dados que tem no seu espaço para entender rapidamente o que é e com o que pode se relacionar.`
    })
}]


export default home
