import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Sal from "sal.js";

import viewImg from "@home/assets/images/cta-img/PC.png";

import playApp from "@home/assets/images/cta-img/play-app.png";
import appleApp from "@home/assets/images/cta-img/apple-app.png";
import bgShape from "@home/assets/images/cta-img/bg-shape.png";
import bgLight from "@home/assets/images/bg/bg-shape-tree.png";

import styles from '../index.module.css'

const CtaTwo = () => {
  const { t } = useTranslation()

  useEffect(() => {
    Sal();
  }, []);
  return (
    <>
      <div className={styles["row"]}>
        <div className={styles["col-lg-12"]}>
          <div className={styles["aiwave-cta"]}>
            <div className={styles["inner"]}>
              <div className={styles["content-left"]}>
                <div
                  className={`${styles["section-title"]} ${styles["text-left"]}`}
                  data-sal="slide-up"
                  data-sal-duration="400"
                  data-sal-delay="150"
                >
                  <h4 className={styles["subtitle"]}>
                    <span className={styles["theme-gradient"]}>
                      {t("home1.web.component66.text1")}
                    </span>
                  </h4>
                  <h2 className={`${styles["title"]} ${styles["w-600"]} ${styles["mb--20"]}`}>
                    {t("home1.web.component66.text2")}
                  </h2>
                  <p className={`${styles["description"]} ${styles["b1"]}`}>
                    {t("home1.web.component66.text3")}
                  </p>
                </div>
              
              </div>
              <div className={styles["content-right"]}>
                <div className={styles["img-right"]}>
                  <img
                    src={viewImg}
                    width={449}
                    height={499}
                    alt="Mobile View"
                  />
                </div>
              </div>
              <div className={styles["bg-shape-one"]}>
                <img src={bgShape} width={639} height={404} alt="Bg shape" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CtaTwo;
