
import React from 'react'


const Icon = () => {
    return (
<svg width="147" height="147" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M105.691 117.32H40.3088C36.8246 117.32 34 114.496 34 111.011V35.3086C34 31.8244 36.8246 29 40.3088 29H82.75L112 58.2488V111.011C112 114.496 109.175 117.32 105.691 117.32Z" fill="url(#paint0_linear_633_2705)"/>
<g opacity="0.51" filter="url(#filter0_f_633_2705)">
<rect x="47.7648" y="71.7266" width="51.0441" height="24.0873" fill="var(--color-primary-3)" />
</g>
<g opacity="0.12">
<path fillRule="evenodd" clipRule="evenodd" d="M56.6544 64.8449C55.3874 64.8449 54.3603 65.872 54.3603 67.1389V99.2553C54.3603 100.522 55.3874 101.549 56.6544 101.549H88.7721C90.0391 101.549 91.0662 100.522 91.0662 99.2553V67.1389C91.0662 65.872 90.0391 64.8449 88.7721 64.8449H56.6544ZM49.7721 67.1389C49.7721 63.338 52.8534 60.2568 56.6544 60.2568H88.7721C92.5731 60.2568 95.6545 63.338 95.6545 67.1389V99.2553C95.6545 103.056 92.5731 106.137 88.7721 106.137H56.6544C52.8534 106.137 49.7721 103.056 49.7721 99.2553V67.1389Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M80.2676 76.9869C81.1635 76.0911 82.616 76.0911 83.5119 76.9869L94.9825 88.4571C95.8784 89.3529 95.8784 90.8054 94.9825 91.7013C94.0866 92.5972 92.6341 92.5972 91.7382 91.7013L81.8897 81.8533L58.2766 105.465C57.3807 106.361 55.9282 106.361 55.0323 105.465C54.1364 104.57 54.1364 103.117 55.0323 102.221L80.2676 76.9869Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M64.6839 74.021C64.0504 74.021 63.5368 74.5345 63.5368 75.168C63.5368 75.8015 64.0504 76.315 64.6839 76.315C65.3174 76.315 65.8309 75.8015 65.8309 75.168C65.8309 74.5345 65.3174 74.021 64.6839 74.021ZM58.9486 75.168C58.9486 72.0006 61.5163 69.4329 64.6839 69.4329C67.8514 69.4329 70.4192 72.0006 70.4192 75.168C70.4192 78.3354 67.8514 80.9031 64.6839 80.9031C61.5163 80.9031 58.9486 78.3354 58.9486 75.168Z" fill="white"/>
</g>
<mask id="mask0_633_2705" maskUnits="userSpaceOnUse" x="34" y="29" width="78" height="89">
<path d="M105.691 117.32H40.3088C36.8246 117.32 34 114.496 34 111.011V35.3086C34 31.8244 36.8246 29 40.3088 29H82.75L112 58.2488V111.011C112 114.496 109.175 117.32 105.691 117.32Z" fill="url(#paint1_linear_633_2705)"/>
</mask>
<g mask="url(#mask0_633_2705)">
<ellipse opacity="0.05" cx="29.8419" cy="23.1219" rx="64.0919" ry="61.5086" fill="url(#paint2_linear_633_2705)"/>
<ellipse opacity="0.05" cx="29.842" cy="23.122" rx="40.2904" ry="38.5683" fill="url(#paint3_linear_633_2705)"/>
</g>
<path d="M50.2642 90V75.4545H56.0881C57.1581 75.4545 58.0507 75.6132 58.7656 75.9304C59.4806 76.2476 60.018 76.688 60.3778 77.2514C60.7377 77.8101 60.9176 78.4541 60.9176 79.1832C60.9176 79.7514 60.804 80.2509 60.5767 80.6818C60.3494 81.108 60.0369 81.4583 59.6392 81.733C59.2462 82.0028 58.7964 82.1946 58.2898 82.3082V82.4503C58.8438 82.474 59.3622 82.6302 59.8452 82.919C60.3329 83.2079 60.7282 83.6127 61.0312 84.1335C61.3343 84.6496 61.4858 85.2652 61.4858 85.9801C61.4858 86.7519 61.294 87.4408 60.9105 88.0469C60.5317 88.6482 59.9706 89.1241 59.2273 89.4744C58.4839 89.8248 57.5677 90 56.4787 90H50.2642ZM53.3395 87.4858H55.8466C56.7036 87.4858 57.3286 87.3224 57.7216 86.9957C58.1146 86.6643 58.3111 86.224 58.3111 85.6747C58.3111 85.2723 58.214 84.9171 58.0199 84.6094C57.8258 84.3016 57.5488 84.0601 57.1889 83.8849C56.8338 83.7098 56.41 83.6222 55.9176 83.6222H53.3395V87.4858ZM53.3395 81.5412H55.6193C56.0407 81.5412 56.4148 81.4678 56.7415 81.321C57.0729 81.1695 57.3333 80.9564 57.5227 80.6818C57.7169 80.4072 57.8139 80.0781 57.8139 79.6946C57.8139 79.169 57.6269 78.7453 57.2528 78.4233C56.8835 78.1013 56.358 77.9403 55.6761 77.9403H53.3395V81.5412ZM64.8869 75.4545H68.6795L72.6852 85.2273H72.8556L76.8613 75.4545H80.6539V90H77.671V80.5327H77.5502L73.786 89.929H71.7548L67.9906 80.4972H67.8698V90H64.8869V75.4545ZM84.5876 90V75.4545H90.3263C91.4295 75.4545 92.3694 75.6652 93.1459 76.0866C93.9224 76.5033 94.5143 77.0833 94.9214 77.8267C95.3334 78.5653 95.5393 79.4176 95.5393 80.3835C95.5393 81.3494 95.331 82.2017 94.9143 82.9403C94.4977 83.679 93.894 84.2543 93.1033 84.6662C92.3173 85.0781 91.3656 85.2841 90.2482 85.2841H86.5905V82.8196H89.751C90.3428 82.8196 90.8305 82.7178 91.2141 82.5142C91.6023 82.3059 91.8911 82.0194 92.0805 81.6548C92.2747 81.2855 92.3717 80.8617 92.3717 80.3835C92.3717 79.9006 92.2747 79.4792 92.0805 79.1193C91.8911 78.7547 91.6023 78.473 91.2141 78.2741C90.8258 78.0705 90.3334 77.9688 89.7368 77.9688H87.6629V90H84.5876Z" fill="white"/>
<g filter="url(#filter1_d_633_2705)">
<path d="M112 58.2488H104.75C92.5997 58.2488 82.75 48.3991 82.75 36.2488V29L112 58.2488Z" fill="url(#paint4_linear_633_2705)"/>
</g>
<defs>
<filter id="filter0_f_633_2705" x="8.76477" y="32.7266" width="129.044" height="102.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="19.5" result="effect1_foregroundBlur_633_2705"/>
</filter>
<filter id="filter1_d_633_2705" x="59.75" y="5" width="79.25" height="79.249" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="1"/>
<feGaussianBlur stdDeviation="12.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_633_2705"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_633_2705" result="shape"/>
</filter>
<linearGradient id="paint0_linear_633_2705" x1="73" y1="29" x2="73" y2="117.32" gradientUnits="userSpaceOnUse">
<stop stopColor="var(--color-primary-3)"/>
<stop offset="1" stopColor="var(--color-primary-3)"/>
</linearGradient>
<linearGradient id="paint1_linear_633_2705" x1="73" y1="29" x2="73" y2="117.32" gradientUnits="userSpaceOnUse">
<stop stopColor="var(--color-primary-3)"/>
<stop offset="1" stopColor="var(--color-primary-3)"/>
</linearGradient>
<linearGradient id="paint2_linear_633_2705" x1="34.4954" y1="25.2355" x2="53.9308" y2="76.8191" gradientUnits="userSpaceOnUse">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="white"/>
</linearGradient>
<linearGradient id="paint3_linear_633_2705" x1="32.7673" y1="24.4474" x2="44.9308" y2="56.8128" gradientUnits="userSpaceOnUse">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="white"/>
</linearGradient>
<linearGradient id="paint4_linear_633_2705" x1="89.2022" y1="19.8239" x2="97.3744" y2="58.249" gradientUnits="userSpaceOnUse">
<stop stopColor="var(--color-primary-3)"/>
<stop offset="1" stopColor="var(--color-primary-3)"/>
</linearGradient>
</defs>
</svg>
    )
}

export default Icon