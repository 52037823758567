const pricing = {
  "t0": "Analysez et explorez vos métriques",
  "t1": "Pas de surprises, voici nos prix",
  "t2": "Essai gratuit 7 jours",
  "t3": "PLUS POPULAIRE",
  "t4": "149€",
  "t5": "/mois",
  "t6": "Entreprise",
  "t7": "Entreprises qui ont besoin d'analyses continues selon différents acteurs.",
  "t8": "5 utilisateurs inclus",
  "t9": "10 Add-ons visuels",
  "t10": "Données vectorisées",
  "t11": "50 graphiques de données",
  "t12": "100 questions de données",
  "t13": "200 clics de HeatMap",
  "t14": "100GB de données",
  "t15": "Soutien prioritaire",
  "t16": "Appels vidéo",
  "t17": "Moteur de recherche",
  "t18": "Choisir un plan",
  "t19": "49€",
  "t20": "/mois",
  "t21": "Basique",
  "t22": "Destiné aux idées émergentes",
  "t23": "qui veulent analyser leurs KPI pour définir des stratégies.",
  "t24": "1 utilisateur inclus",
  "t25": "5 add-ons visuels",
  "t26": "Données vectorisées",
  "t27": "10 graphiques de données",
  "t28": "20 questions de données",
  "t29": "50 clics de HeatMap",
  "t30": "5GB de données",
  "t31": "Soutien par e-mail",
  "t32": "Appels vidéo",
  "t33": "Moteur de recherche",
  "t34": "Choisir un plan",
  "t35": "349€",
  "t36": "/mois",
  "t37": "Start Up",
  "t38": "Pour les équipes de Start Ups déterminées à créer leurs propres analyses à mesure sans limites.",
  "t39": "15 utilisateurs inclus",
  "t40": "Add-ons illimités",
  "t41": "Données vectorisées",
  "t42": "Graphiques illimités",
  "t43": "Questions illimitées",
  "t44": "500 clics de HeatMap",
  "t45": "500GB de données",
  "t46": "Soutien urgent",
  "t47": "Appels vidéo",
  "t48": "Moteur de recherche",
  "t49": "Choisir un plan",
  "t50": "GRATUIT",
  "t51": "*7 jours",
  "t52": "Exploration",
  "t53": "Parfait pour commencer avec des analyses ponctuelles, sans pouvoir créer à long terme.",
  "t54": "1 utilisateur inclus",
  "t55": "1 Add-on visual",
  "t56": "Données vectorisées",
  "t57": "1 graphique de données",
  "t58": "5 questions de données",
  "t59": "Pas de HeatMap",
  "t60": "500MB de données",
  "t61": "Pas de soutien",
  "t62": "Appels vidéo",
  "t63": "Moteur de recherche",
  "t64": "Choisir un plan",
  "t65": "Si vous avez besoin de plus d'utilisateurs et de plus d'options, contactez notre équipe de",
  "t66": "Ventes",
  "t67": "Comparez entre les plans de paiement",
  "t68": "Et découvrez lequel correspond à vos besoins",
  "t69": "Basique",
  "t70": "Commencez",
  "t71": "Entreprise",
  "t72": "Comprenez",
  "t73": "Start Up",
  "t74": "Analysez",
  "t75": "CAPACITÉS ET ACCÈS",
  "t76": "Utilisateurs",
  "t77": "1",
  "t78": "5",
  "t79": "15",
  "t80": "Add-ons",
  "t81": "Illimités",
  "t82": "5",
  "t83": "10",
  "t84": "Données vectorisées",
  "t85": "GB de données",
  "t86": "Jusqu'à 5 GB",
  "t87": "Jusqu'à 100 GB",
  "t88": "Jusqu'à 500 GB",
  "t89": "Représentation personnalisée",
  "t90": "Nombre de graphiques",
  "t91": "10",
  "t92": "50",
  "t93": "Illimités",
  "t94": "Nombre de questions",
  "t95": "20",
  "t96": "100",
  "t97": "Illimités",
  "t98": "Clics avec carte de chaleur",
  "t99": "50",
  "t100": "200",
  "t101": "500",
  "t102": "Appels vidéo et recherche",
  "t103": "PERSONNALISATION ET CODE",
  "t104": "Logo personnalisé",
  "t105": "bientôt",
  "t106": "Thèmes prédéfinis",
  "t107": "Thèmes personnalisés",
  "t108": "bientôt",
  "t109": "Partager avec sous-domaine",
  "t110": "Partager avec domaine",
  "t111": "bientôt",
  "t112": "Accès IAM et banque",
  "t113": "Utilisation des Add-ons",
  "t114": "bientôt",
  "t115": "Édition de prompts",
  "t116": "Accès au code source",
  "t117": "bientôt",
  "t118": "Édition en temps réel",
  "t119": "bientôt",
  "t120": "RAPPORTS",
  "t121": "Exporter PDF",
  "t122": "Envoi par e-mail",
  "t123": "Partager par lien",
  "t124": "SOUTIEN",
  "t125": "Soutien par e-mail",
  "t126": "Soutien prioritaire",
  "t127": "Aide one-to-one",
  "t128": "Et si j'ai besoin de plus pour analyser?",
  "t129": "Vous pouvez changer de plan. Mais si cela dépasse trop vos besoins, vous pouvez choisir en fonction de plus de graphiques, plus de questions, plus d'add-ons pour vos représentations d'interface, plus de clics, plus de GB de données... Vos extras seront ajoutés à votre quota existante. Vous pourrez annuler à tout moment. Et si vous avez besoin d'un plan personnalisé, contactez les ventes.",
  "t130": "Graphiques",
  "t131": "Pack 10",
  "t132": "15€",
  "t133": "Questions",
  "t134": "Pack 20",
  "t135": "10€",
  "t136": "Add-on",
  "t137": "Extra",
  "t138": "10€",
  "t139": "Carte de chaleur",
  "t140": "Clic",
  "t141": "0,5€",
  "t142": "GBs de Cloud",
  "t143": "GB",
  "t144": "1€"
}

export default pricing;