const bank = {
    t1: `Entrate Totali`,
    t2: `Risultato Totale`,
    t3: `Gestisci i tuoi accessi`,
    t4: `Cerca una fattura`,
    t5: `Cerca una data`,
    t6: `La mia carta`,
    t7: `Saldo della carta`,
    t8: `Stato`,
    t9: `Attiva`,
    t10: `Amministra`,
    t11: `Trasferisci`,
    t12: `Condividi e ottieni la tua ricompensa affiliando tutti i tuoi contatti`,
    t13: `Sicurezza`,
    t14: `Carta attiva`,
    t15: `Accesso sicuro`,
    t16: `Cambia`,
    t17: `Informazioni`,
    t18: `Località`,
    t19: `Indirizzo`,
    t20: `Portafoglio`,

    cardlist: {
        "t0": "Carte",
        "t1": "Aggiungi",
        "t2": "Aggiungi la tua carta di pagamento in modo sicuro",
        "t3": "Una volta aggiunta la tua carta, potrai gestire i tuoi pagamenti e le tue sottoscrizioni attive.",
        "t4": "Aggiungi carta",
        "t5": "Inserisci i dettagli della tua carta di credito per effettuare pagamenti in modo sicuro.",
        "t6": "Vedi più",
        "t7": "Nome",
        "t8": "PAN",
        "t9": "CVC",
        "t10": "EXP",
        "t11": "Accetta carta",
        "t12": "Annulla",
        "t13": "Richiedi una nuova carta",
        "t14": "Ti arriverà una nuova carta",
        "t15": "Inviato da Aythen",
        "t16": "Spagna",
        "t17": "10903",
        "t18": "Santa Perpetua de Mogoda",
        "t19": "Questo è l'indirizzo dove ti sarà spedita",
        "t20": "Ciò avrà un costo di 10* Ciò avrà un costo di Ciò avrà un costo di Ciò avrà un costo di",
        "t21": "Richiedi carta"
    }
}

export default bank