import React from "react";
import Context from "@home/context/Context";


import HeaderTop from "@home/Header/HeaderTop/HeaderTop";
import Header from "@home/Header/Header";
import PopupMobileMenu from "@home/Header/PopUpMobileMenu";
import Home from "@home/Home/Home";
import Footer from "@home/Footers/Footer";
import Copyright from "@home/Footers/Copyright";

import "react-tooltip/dist/react-tooltip.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



import styles from '../index.module.css'

const HomePage = () => {
  return (
    <>
      <main className={styles["page-wrapper"]} >
        <Context>
          <HeaderTop />
          <Header
            headerTransparent="header-transparent"
            headerSticky="header-sticky"
            btnClass="rainbow-gradient-btn"
          />
          <PopupMobileMenu />

          <Home />
          <Footer />
          <Copyright />
        </Context>
      </main>
    </>
  );
};

export default HomePage;











