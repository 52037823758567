// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.meetOptionsPopup_MVcM8 {
	position: absolute;
	top: -10px;
	left: 0px;
	width: max-content;
	padding: 10px 0px;
	overflow: hidden;
	background-color: var(--background) !important;
	border-radius: 8px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	z-index: 10000;
}

.popupOptionsList_u2AuF {
	list-style: none;
	margin: 0;
	padding: 0;
}

.popupOption_ymRwj {
	display: flex;
	align-items: center;
	padding: 10px;
	color: var(--text-color) !important;
	font-size: 16px;
	font-weight: 500;
	pointer-events: all;
	-webkit-user-select: none;
	        user-select: none;
	cursor: pointer;
	transition: background 0.2s ease;
}

.popupOption_ymRwj:hover {
	background-color: var(--background-secondary) !important;
}

.popupIcon_Hiykv {
	color: var(--text-color) !important;
	margin-right: 25px;
	font-size: 22px;
}
`, "",{"version":3,"sources":["webpack://./src/views/app/v1-1/pages/Meet/components/MeetOptionsPopup.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,UAAU;CACV,SAAS;CACT,kBAAkB;CAClB,iBAAiB;CACjB,gBAAgB;CAChB,8CAA8C;CAC9C,kBAAkB;CAClB,0CAA0C;CAC1C,cAAc;AACf;;AAEA;CACC,gBAAgB;CAChB,SAAS;CACT,UAAU;AACX;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,aAAa;CACb,mCAAmC;CACnC,eAAe;CACf,gBAAgB;CAChB,mBAAmB;CACnB,yBAAiB;SAAjB,iBAAiB;CACjB,eAAe;CACf,gCAAgC;AACjC;;AAEA;CACC,wDAAwD;AACzD;;AAEA;CACC,mCAAmC;CACnC,kBAAkB;CAClB,eAAe;AAChB","sourcesContent":[".meetOptionsPopup {\n\tposition: absolute;\n\ttop: -10px;\n\tleft: 0px;\n\twidth: max-content;\n\tpadding: 10px 0px;\n\toverflow: hidden;\n\tbackground-color: var(--background) !important;\n\tborder-radius: 8px;\n\tbox-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n\tz-index: 10000;\n}\n\n.popupOptionsList {\n\tlist-style: none;\n\tmargin: 0;\n\tpadding: 0;\n}\n\n.popupOption {\n\tdisplay: flex;\n\talign-items: center;\n\tpadding: 10px;\n\tcolor: var(--text-color) !important;\n\tfont-size: 16px;\n\tfont-weight: 500;\n\tpointer-events: all;\n\tuser-select: none;\n\tcursor: pointer;\n\ttransition: background 0.2s ease;\n}\n\n.popupOption:hover {\n\tbackground-color: var(--background-secondary) !important;\n}\n\n.popupIcon {\n\tcolor: var(--text-color) !important;\n\tmargin-right: 25px;\n\tfont-size: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"meetOptionsPopup": `meetOptionsPopup_MVcM8`,
	"popupOptionsList": `popupOptionsList_u2AuF`,
	"popupOption": `popupOption_ymRwj`,
	"popupIcon": `popupIcon_Hiykv`
};
export default ___CSS_LOADER_EXPORT___;
