

import styles from '../index.module.css'

const DarkSwitch = ({ isLight, switchTheme }) => {
  return (
    <div id="my_switcher" className={styles["my_switcher"]}>
      <ul onClick={switchTheme}>
        {isLight ? (
          <li>
            <button data-theme="light" className={`${styles["setColor"]} ${styles["light"]}`}>
              <svg fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M12 5V3m0 18v-2M7.05 7.05 5.636 5.636m12.728 12.728L16.95 16.95M5 12H3m18 0h-2M7.05 16.95l-1.414 1.414M18.364 5.636 16.95 7.05M16 12a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" />
              </svg>

            </button>
          </li>
        ) : (
          <li>
            <button data-theme="dark" className={`${styles["setColor"]} ${styles["dark"]}`}>
              <svg  fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M12 21a9 9 0 0 1-.5-17.986V3c-.354.966-.5 1.911-.5 3a9 9 0 0 0 9 9c.239 0 .254.018.488 0A9.004 9.004 0 0 1 12 21Z" />
              </svg>

            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default DarkSwitch;
