import React from 'react'

import DragNavImage from './assets/drag-nav.png'
import Card1Image from './assets/rocket.png'
import Card2Image from './assets/Error.png'
import Card3Image from './assets/round-cut.png'
import Card4Image from './assets/thorus.png'

const Cloud = () => {
  return (
    <div className="relative w-full sm:h-full pb-[70px]" id="cloud">
      <div className="container mx-auto">
        <section className="home-section-1 ">
          <div className="cloud-gradient"></div>
          <div className="flex">
            <div className="relative lg:pt-[10px]">
              <div className="mb-5 flex items-center ml-[-8px] absolute left-0 top-[10px] w-[300px] z-[5]">
                <div className="bg-[#7EE787] w-[40px] h-[40px] p-3 flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="21"
                    viewBox="0 0 27 21"
                    fill="none"
                  >
                    <path
                      d="M20.7592 18.375H6.57739C5.28939 18.375 4.18332 17.876 3.28546 16.9059C2.3888 15.9372 1.94103 14.7611 1.94103 13.4062C1.94103 12.0514 2.3888 10.8753 3.28546 9.90656C4.18332 8.93655 5.28939 8.4375 6.57739 8.4375H6.683C6.7834 6.76273 7.39837 5.30355 8.52918 4.08187C9.77236 2.73879 11.2972 2.05364 13.0771 2.05357C13.0772 2.05357 13.0773 2.05357 13.0774 2.05357V2.55357C14.7115 2.55272 16.1048 3.17494 17.2575 4.42023C18.4102 5.66552 18.9865 7.17128 18.9865 8.9375L20.7592 18.375ZM20.7592 18.375C21.7284 18.375 22.5598 18.0064 23.2239 17.289C23.8863 16.5733 24.2138 15.694 24.2138 14.683C24.2138 13.672 23.8863 12.7928 23.2239 12.0771C22.5598 11.3597 21.7284 10.9911 20.7592 10.9911H19.4865L20.7592 18.375ZM0.577393 13.5022V13.502C0.577393 11.9417 1.01038 10.5652 1.87311 9.3555C2.7317 8.15161 3.84197 7.39388 5.21757 7.06146L5.5113 6.99047L5.58502 6.69741C6.05289 4.83729 6.98224 3.34691 8.37195 2.20585C9.76189 1.06462 11.3246 0.5 13.0774 0.5C15.2386 0.5 17.062 1.30626 18.5758 2.94173C20.0911 4.57876 20.8509 6.56712 20.8501 8.93734L20.85 9.37876L21.288 9.43362C22.515 9.58732 23.5282 10.1532 24.3466 11.1539C25.1673 12.1574 25.5781 13.3263 25.5774 14.6828V14.683C25.5774 16.1597 25.1033 17.3934 24.1587 18.4138C23.2156 19.4327 22.0917 19.9293 20.7595 19.9286H20.7592H6.57739C4.92688 19.9286 3.52504 19.3181 2.34535 18.0736C1.16719 16.8308 0.576655 15.3174 0.577393 13.5022Z"
                      stroke="white"
                    />
                  </svg>
                </div>
                <div className="ml-4">
                  <h4 className="text-[#7EE787] font-extrabold uppercase text-[20px]">
                    CLOUD
                  </h4>
                </div>
              </div>
              <div className="cloud-left-border"></div>
            </div>
            <div className="cloud-bg-img">
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                <div className="col-span-2 relative mt-[70px]">
                  <div className="lg:mt-6">
                    <div className="pl-5 lg:pl-10">
                      <p className="text-[24px] text-[#554D56] lg:text-[48px] leading-tight drop-shadow-xl text-left">
                        <span className="text-[#7EE787] mr-1">
                          Instancias, ciberseguridad, dominios y servicios en la
                          nube.
                        </span>
                        Utiliza la infraestructura segura de Scaleway para
                        escalar tu proyecto con la seguridad de tener sus datos
                        en copias de seguridad en la nube sincronizadas en una
                        misma plataforma.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="text-end relative">
                  <div className="w-full initial absolute md:left-[50px] md:bottom-[0px] z-20 flex justify-end lg:block">
                    <img
                      src={DragNavImage}
                      className="w-[100px] lg:w-[200px] z-20"
                    />
                  </div>
                </div>
              </div>

              <div className="">
                <div className="mt-10 lg:mt-[80px]">
                  <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3">
                    <div>
                      <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5  h-[400px] relative">
                        <div className="md:w-[90%]">
                          <p className="mt-0 lg:text-[13px] uppercase">
                            PRIMER BENEFICIO
                          </p>
                          <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                            Escalabilidad
                          </h5>
                          <p className="lg:text-[15px]">
                            La nube permite aumentar o reducir recursos de manera
                            rápida y sencilla según las necesidades del usuario.
                            Esto permite un uso más eficiente de los recursos y
                            una adaptación ágil a cambios en la demanda.
                          </p>
                          <img
                            src={Card1Image}
                            className="absolute bottom-[-140px] right-[-100px] lg:bottom-[-150px] lg:right-[-100px] w-[450px] z-20"
                          />
                        </div>
                      </div>
                      <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[500px] relative mt-5">
                        <div>
                          <p className="mt-0 lg:text-[13px] uppercase">
                            CUARTO BENEFICIO
                          </p>
                          <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                            Flexibilidad
                          </h5>
                        </div>
                        <div className="md:w-[90%]">
                          <p className="lg:text-[15px]">
                            Los usuarios pueden elegir entre una variedad de
                            servicios y configuraciones según sus necesidades
                            específicas. Esto incluye opciones de
                            almacenamiento, capacidad de cómputo, herramientas
                            de desarrollo y más.
                          </p>
                        </div>
                        <div className="md:flex items-end justify-center">
                          <img src={Card2Image} className=" md:w-[300px]" />
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-[-60px]">
                      <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[450px] lg:h-[500px] relative">
                        <div className="md:w-[90%]">
                          <p className="mt-0 lg:text-[13px] uppercase">
                            SEGUNDO BENEFICIO
                          </p>
                          <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                            Costos reducidos
                          </h5>
                          <p className="lg:text-[15px]">
                            Al utilizar la nube, las empresas pueden evitar gastos
                            iniciales significativos en infraestructura y
                            hardware. Además, muchas soluciones de nube funcionan
                            con modelos de pago por uso, lo que significa que solo
                            se paga por los recursos que realmente se consumen.
                          </p>
                        </div>
                        <img
                          src={Card3Image}
                          className="absolute bottom-[-30px] right-[0px] lg:bottom-[-60px] lg:right-[20px] w-[200px] lg:w-[300px] z-20"
                        />
                      </div>
                      <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 relative mt-5">
                        <div>
                          <p className="mt-0 lg:text-[13px] uppercase">
                            QUINTO BENEFICIO
                          </p>
                          <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                            Seguridad
                          </h5>
                        </div>
                        <div className="md:w-[90%]">
                          <p className="lg:text-[15px]">
                            Implementación de medidas de seguridad robustas para
                            proteger los datos y sistemas de sus clientes.
                            Muchos de ellos tienen expertos en seguridad y
                            tecnologías avanzadas para evitar intrusiones y
                            ataques cibernéticos.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="relative gap-4 grid md:col-span-2 md:grid-cols-2 lg:col-span-1 lg:block">
                      <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[500px] relative z-[5]">
                        <div className="md:w-[90%]">
                          <p className="lg:text-[13px] uppercase">
                            TERCER BENEFICIO
                          </p>
                          <h5 className="mt-0 lg:text-[30px] my-2 font-bold lg:font-light">
                            Acceso global
                          </h5>
                          <p className="lg:text-[15px] mt-3">
                            Los servicios en la nube están disponibles en
                            cualquier lugar con acceso a internet. Esto facilita
                            el trabajo remoto y la colaboración entre equipos
                            ubicados en diferentes lugares geográficos.
                          </p>
                        </div>
                        <img
                          src={Card4Image}
                          className="w-[200px] md:w-[250px] mx-auto mt-10 absolute"
                        />
                      </div>
                      <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 relative mb-4 lg:mb-0 md:mt-[20px]">
                        <div className="md:w-[90%]">
                          <p className="mt-0 lg:text-[13px] uppercase">
                            SEXTO BENEFICIO
                          </p>
                          <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                            Recuperación y copias de seguridad
                          </h5>
                          <p className="lg:text-[15px] mt-3">
                            Soluciones de seguridad automatizadas y recuperación
                            de desastres, lo que garantiza que los datos estén
                            protegidos y se puedan recuperar en caso de fallo
                            del sistema.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Cloud
