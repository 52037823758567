import React, { useState, useContext, useEffect } from 'react'
import styles from './MeetForLaterPopup.module.css'
import { FaRegCopy } from 'react-icons/fa6'
import { IoMdClose } from 'react-icons/io'
import { ChatContext } from '../ChatContext'
import { useSelector } from 'react-redux'
import { createMeet } from '../../../actions/meet'
import { useDispatch } from 'react-redux'
import { setShowMeetOptionsPopup } from '../../../slices/meetSlices'
import { generateRandomId } from '../../Calendar/utils'
import { apiUrl } from '../../../../../../apiBackend'

const MeetForLaterPopup = ({ setShowToast }) => {
	const [created, setCreated] = useState(false)
	const { setPopUpToastMessage } = useContext(ChatContext)
	const dispatch = useDispatch()
	const { userMeets } = useSelector((state) => state.meet)
	const { user } = useSelector((state) => state.iam)
	const today = new Date()
	const defaultTitle = `Meet ${today.getDate()}/${today.getMonth() + 1}`
	const [title, setTitle] = useState(defaultTitle)
	const [showTooltip, setShowTooltip] = useState(false)
	const [id, setId] = useState(generateRandomId())

	const handleCreateMeet = () => {
		const numberOfMeet = userMeets.filter(
			(meet) => meet.type && meet.type === 'fastMeet'
		).length
		user &&
			user?.id &&
			dispatch(
				createMeet({
					meetName: `${title} (${numberOfMeet + 1})`,
					participantsIds: [user.id],
					creatorId: user.id,
					joinId: id,
					type: 'fastMeet',
				})
			)
		setCreated(true)
		setPopUpToastMessage('Meet creado con exito!')
		setShowToast(true)
	}

	const handleCopyLink = (e) => {
		e.preventDefault()
		e.stopPropagation()
		setPopUpToastMessage('Link copiado con exito!')
		setShowToast && setShowToast(true)
		navigator.clipboard.writeText(`${apiUrl}/es/v1-1/app/meet/${id}`)
		setShowTooltip(false)
	}

	return (
		<div
			className={styles.popupBackground}
			onClick={(e) => {
				e.stopPropagation()
				dispatch(setShowMeetOptionsPopup(false))
			}}
		>
			<div
				className={styles.popupContainer}
				onClick={(e) => e.stopPropagation()}
			>
				<div className={styles.body}>
					<div
						onClick={() => dispatch(setShowMeetOptionsPopup(false))}
						className={styles.closeButton}
					>
						<IoMdClose color={`var(--text-color)`} />
					</div>

					<h3>Esta sera la información para unirse</h3>
					<p>
						Envía este vínculo a las personas con quienes quieras reunirte. No
						olvides guardarlo para poder usarlo más tarde si es necesario.
					</p>

					<div className={styles.linkContainer}>
						<input
							className={styles.linkInput}
							type='text'
							value={`${apiUrl}/es/v1-1/app/meet/${id}`}
							readOnly
						/>
						<div
							onMouseEnter={() => setShowTooltip(true)}
							onMouseLeave={() => setShowTooltip(false)}
							className={styles.copyButtonContainer}
						>
							<button
								onClick={(e) => {
									e.stopPropagation()
									handleCopyLink(e)
								}}
								className={styles.copyButton}
							>
								<FaRegCopy />
							</button>
							{showTooltip && (
								<div className={styles.tooltip}>Copiar vínculo</div>
							)}
						</div>
					</div>
					{!created && (
						<div className={styles.buttonsContainer}>
							<div
								onClick={() => dispatch(setShowMeetOptionsPopup(false))}
								className={styles.cancelButton}
							>
								Cancelar
							</div>
							<div
								onClick={() => handleCreateMeet()}
								className={styles.createButton}
							>
								Crear
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default MeetForLaterPopup
