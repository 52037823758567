const iam = {
    login: {
        t1: `Anmelden`,
        t2: `Und zugreifen Sie auf Ihren persönlichen Bereich`,
        t3: `ODER`,
        t4: `Merken Sie mich`,
        t5: `Passwort vergessen?`,
        t6: `ANMELDEN`,
        t7: `Sie haben kein Konto?`,
        t8: `Registrieren`
    },
    table: {
        t1: `Wir haben keine Daten in Ihrer vektorisierten Datenbank gefunden`,
        t2: `Erstellen`,
        t3: `Schnellstart-Dokumentation`,
    },
    header: {
        t1: `Zurückkehren`,
        t2: `Identitäts- und Zugriffsmanagement (IAM)`,
        t3: `Benutzer`,
        t4: `Anwendungen`,
        t5: `Richtlinien`,
        t6: `API-Schlüssel`,
        t7: `Protokolle`,
    },
    users: {
        t1: `Es wird eine Liste der Benutzer dieser Organisation angezeigt. Sie können weitere Informationen über jeden Benutzer anzeigen.`,
        t2: `Was sind Benutzer`,
        t3: `Benutzer hinzufügen`,
        t4: `Benutzer einladen`,
        t5: `Geben Sie die E-Mail-Adresse jedes einzuladenden Benutzers ein, getrennt durch Kommas.`,
        t6: `Wertvolle Schlüssel-Tags eingeben`,
        t7: `Die wertvollen Schlüssel-Tags helfen Ihnen, Ihre Benutzer zu organisieren. Sie können bis zu 10 Tags pro Benutzer zuweisen.`,
        t8: `Hinzufügen zu einer bestehenden Gruppe (optional)`,
        t9: `Einladen`,
        t10: `Benutzerinformationen`,
        t11: `Status`,
        t12: `Aktiviert`,
        t13: `Typ`,
        t14: `Eingeladen`,
        t15: `Beigetreten`,
        t16: `Letzter Zugriff`,
        t17: `Benutzer löschen`,
        t18: `Wenn Sie einen Benutzer aus dieser Organisation löschen, werden seine API-Schlüssel automatisch gelöscht und jede direkt damit verbundene Richtlinie wird Waisen.`,
        t19: `Benutzer löschen`,
    },
    apps: {
        t1: `Es wird eine Liste der Anwendungen in dieser Organisation angezeigt. Sie können weitere Informationen über jede Anwendung anzeigen.`,
        t2: `Was sind Anwendungen?`,
        t3: `App erstellen`,
        t4: `Anwendungen`,
        t5: `Eine Anwendung erstellen`,
        t6: `Geben Sie einen Namen und eine optionale Beschreibung ein`,
        t7: `Name`,
        t8: `Der Name Ihrer Anwendung darf nur alphanumerische Zeichen, Punkte und Bindestriche enthalten.`,
        t9: `Beschreibung`,
        t10: `Wertvolle Schlüssel-Tags eingeben (optional)`,
        t11: `Die wertvollen Schlüssel-Tags helfen Ihnen, Ihre Anwendungen zu organisieren. Sie können bis zu 10 Tags pro Anwendung zuweisen.`,
        t12: `Wertvolle Schlüssel-Tags eingeben (optional)`,
        t13: `Anwendung erstellen`,
    },
    polices: {
        t1: `Es wird eine Liste der Richtlinien dieser Organisation angezeigt. Sie können weitere Informationen über jede Richtlinie anzeigen.`,
        t2: `Was sind Richtlinien?`,
        t3: `Richtlinie erstellen`,
        t4: `Richtlinien`,
        t5: `Regeln`,
        t6: `Eine Regel besteht aus einem Geltungsbereich (z.B. Projekt- oder Organisationsniveau) und einem oder mehreren Berechtigungssätzen (z.B. "Alle Instanzen auflisten").`,
        t7: `Erfahren Sie mehr über die Regeln.`,
        t8: `Regel #1`,
        t9: `Tun`,
        t10: `Organisation`,
        t11: `Die Berechtigungssätze für IAM und Abrechnung können nur auf Organisationsniveau ausgewählt werden.`,
        t12: `Zugriff auf Ressourcen`,
        t13: `Gewährt Zugriff auf Ressourcen (Instanzen, Objekt-Speicher, Datenbanken usw.) in Ihren Projekten.`,
        t14: `Zugriff auf Organisationsfunktionen`,
        t15: `Gewährt Zugriff auf IAM, Abrechnung, Support und Missbrauchstickets sowie Projektmanagement, alles auf Organisationsniveau.`,
        t16: `Überprüfen`,
        t17: `Berechtigungssätze`,
        t18: `Organisationsmanager`,
        t19: `Regel hinzufügen`,
    },
    apis: {
        t1: `Es wird eine Liste der API-Schlüssel dieser Organisation angezeigt.`,
        t2: `Wie erstelle ich API-Schlüssel`,
        t3: `API-Schlüssel generieren`,
        t4: `APIs`,
        t5: `Eine API-Schlüssel generieren`,
        t6: `Kopieren und speichern Sie Ihren geheimen Schlüssel. Er wird nur einmal angezeigt.`,
        t7: `Verwalten Sie Ihre Vektoren über den IAM-Zugriff, indem Sie Ihre API-Schlüssel hinzufügen, um sie zu verwenden`,
        t8: `Zugriffsschlüssel-ID`,
        t9: `Geheimer Schlüssel`,
        t10: `Schluss generieren`,
        t11: `Eine API-Schlüssel generieren`,
        t12: `API-Schlüsselträger auswählen`,
        t13: `Ich selbst (IAM-Benutzer)`,
        t14: `Eine Anwendung`,
        t15: `Beschreibung`,
        t16: `Ablauf`,
        t17: `Wird dieser API-Schlüssel für den Objekt-Speicher verwendet?`,
        t18: `Ich selbst (IAM-Benutzer)`,
        t19: `Ich selbst (IAM-Benutzer)`,
        t20: `API-Schlüssel generieren`,
        t21: `Schluss`,
    },
    logs: {
        t1: `Es wird eine Liste Ihrer IAM-Ressourcen-Protokolle angezeigt. IAM-Ressourcen können Benutzer, Anwendungen, Gruppen, API-Schlüssel und IAM-Richtlinien sein. Sie können weitere Informationen über jeden erhalten.`,
        t2: `Wie verstehe ich meine Protokolle?`,
        t3: `Nach exakter IAM-Ressourcen-ID filtern`,
        t4: `Datum`,
        t5: `IAM-Datum`,
        t6: `Kategorie`,
        t7: `Kategorie`,
        t8: `Aktion`,
        t9: `Aktion`,
        t10: `Hinzufügen`,
        t11: `Zurücksetzen`,
        t12: `Filtern`,
        t13: `Protokolle`,
        t14: `Protokoll-Informationen`,
        t15: `Status`,
        t16: `Aktiviert`,
        t17: `Typ`,
        t18: `Eingeladen`,
        t19: `Beigetreten`,
        t20: `Letzter Zugriff`,
        t21: `Protokoll löschen`,
        t22: `Wenn Sie ein Protokoll aus dieser Organisation löschen, werden seine API-Schlüssel automatisch gelöscht und jede direkt damit verbundene Richtlinie wird Waisen.`,
        t23: `Protokoll löschen`,
    },
}

export default iam