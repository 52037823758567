import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import * as THREE from "three";
import { createNoise3D } from "simplex-noise";
import "./3dAssistant.css";

const Assistant3d = () => {
  const { t } = useTranslation();

  const canvasRef = useRef(null);
  const speedRef = useRef(null);
  const spikesRef = useRef(null);
  const processingRef = useRef(null);
  const materialRef = useRef();
  const [color, setColor] = useState("#ffffff");

  useEffect(() => {
    const canvas = canvasRef.current;
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      context: canvas.getContext("webgl2"),
      antialias: true,
      alpha: true,
    });
    renderer.setSize(canvas.clientWidth, canvas.clientHeight);
    renderer.setPixelRatio(window.devicePixelRatio || 1);
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      45,
      canvas.clientWidth / canvas.clientHeight,
      0.1,
      1000
    );
    camera.position.z = 5;
    const geometry = new THREE.SphereGeometry(0.8, 128, 128);
    const material = new THREE.MeshPhongMaterial({
      color: color, //0xe4ecfa
      shininess: 99,
    });
    materialRef.current = material;
    const lightTop = new THREE.DirectionalLight(0xffffff, 0.69);
    lightTop.position.set(0, 500, 200);
    lightTop.castShadow = true;
    scene.add(lightTop);

    const lightBottom = new THREE.DirectionalLight(0xffffff, 0.24); // 0xffffff
    lightBottom.position.set(0, -500, 400);
    lightBottom.castShadow = true;
    scene.add(lightBottom);
    const ambientLight = new THREE.AmbientLight(0xffffff); // 0x798296
    scene.add(ambientLight);

    const sphere = new THREE.Mesh(geometry, material);
    scene.add(sphere);

    const simplex = createNoise3D();

    const animate = () => {
      const time =
          performance.now() *
          0.00001 *
          speedRef.current?.value *
          Math.pow(processingRef?.current?.value, 3),
        spikes = spikesRef?.current?.value * processingRef?.current?.value;

      let positions = sphere?.geometry?.attributes?.position?.array;
      for (let i = 0; i < positions.length; i += 3) {
        let p = new THREE.Vector3(
          positions[i],
          positions[i + 1],
          positions[i + 2]
        );
        p.normalize().multiplyScalar(
          1 + 0.3 * simplex(p.x * spikes, p.y * spikes, p.z * spikes + time)
        );
        positions[i] = p.x;
        positions[i + 1] = p.y;
        positions[i + 2] = p.z;
      }
      sphere.rotation.y = 0.1;
      sphere.geometry.computeVertexNormals();
      sphere.geometry.attributes.position.needsUpdate = true;
      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    };
    requestAnimationFrame(animate);
  }, []);

  const handleRangeChange = (e, i) => {
    const value = e.target.value;
    const max = e.target.max || 100;
    const percentage = (value / max) * 100;
    const rangeInput = document.getElementById(`inputRange-${i}`);
    rangeInput.style.background = `linear-gradient(to right,  rgb(0, 123, 255) 0%, rgb(242, 4, 255) ${percentage}%, #d3d3d3 ${percentage}%, #d3d3d3 100%)`;
  };

  const handleColorChange = (e) => {
    setColor(e.target.value);
    if (materialRef.current) {
      materialRef.current.color.set(e.target.value);
    }
  };

  return (
    <div className="mainassistant">
      <div id="blob">
        <canvas ref={canvasRef} width="600" height="600"></canvas>
      </div>
      <div className="controls">
        <div className="">
          <label></label>
          <input
            type="color"
            name="color"
            value={color}
            onChange={handleColorChange}
          />
        </div>
        <div>
          <label>{t("web.tassistant.01")}</label>
          <input
            className="inputSlider"
            style={{
              background: `linear-gradient(to right, rgb(0, 123, 255) 3%, rgb(202, 202, 202) 15%);`,
            }}
            id="inputRange-1"
            type="range"
            min="10"
            max="120"
            defaultValue="36"
            step="1"
            name="speed"
            ref={speedRef}
            onChange={(e) => handleRangeChange(e, 1)}
          />
        </div>
        <div className="">
          <label>{t("web.tassistant.02")}</label>
          <input
            type="range"
            id="inputRange-2"
            min=".05"
            max="2"
            defaultValue="0.6"
            step=".05"
            name="spikes"
            ref={spikesRef}
            onChange={(e) => handleRangeChange(e, 2)}
          />
        </div>
        <div>
          <label>{t("web.tassistant.03")}</label>
          <input
            type="range"
            id="inputRange-3"
            min=".3"
            max="2.4"
            defaultValue="0.9"
            step=".01"
            name="processing"
            ref={processingRef}
            onChange={(e) => handleRangeChange(e, 3)}
          />
        </div>
      </div>
    </div>
  );
};

export default Assistant3d;
