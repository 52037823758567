
import React from 'react'


const Icon = () => {
    return (
        <svg width="147" height="147" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M105.691 117.32H40.3088C36.8246 117.32 34 114.496 34 111.011V35.3086C34 31.8244 36.8246 29 40.3088 29H82.75L112 58.2488V111.011C112 114.496 109.175 117.32 105.691 117.32Z" fill="url(#paint0_linear_633_2859)"/>
        <g opacity="0.51" filter="url(#filter0_f_633_2859)">
        <rect x="47.7648" y="71.7266" width="51.0441" height="24.0873" fill="var(--color-primary-3)" />
        </g>
        <g opacity="0.12">
        <path fillRule="evenodd" clipRule="evenodd" d="M56.6544 64.8449C55.3874 64.8449 54.3603 65.872 54.3603 67.1389V99.2553C54.3603 100.522 55.3874 101.549 56.6544 101.549H88.7721C90.0391 101.549 91.0662 100.522 91.0662 99.2553V67.1389C91.0662 65.872 90.0391 64.8449 88.7721 64.8449H56.6544ZM49.7721 67.1389C49.7721 63.338 52.8534 60.2568 56.6544 60.2568H88.7721C92.5731 60.2568 95.6545 63.338 95.6545 67.1389V99.2553C95.6545 103.056 92.5731 106.137 88.7721 106.137H56.6544C52.8534 106.137 49.7721 103.056 49.7721 99.2553V67.1389Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M80.2676 76.9869C81.1635 76.0911 82.616 76.0911 83.5119 76.9869L94.9825 88.4571C95.8784 89.3529 95.8784 90.8054 94.9825 91.7013C94.0866 92.5972 92.6341 92.5972 91.7382 91.7013L81.8897 81.8533L58.2766 105.465C57.3807 106.361 55.9282 106.361 55.0323 105.465C54.1364 104.57 54.1364 103.117 55.0323 102.221L80.2676 76.9869Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M64.6839 74.021C64.0504 74.021 63.5368 74.5345 63.5368 75.168C63.5368 75.8015 64.0504 76.315 64.6839 76.315C65.3174 76.315 65.8309 75.8015 65.8309 75.168C65.8309 74.5345 65.3174 74.021 64.6839 74.021ZM58.9486 75.168C58.9486 72.0006 61.5163 69.4329 64.6839 69.4329C67.8514 69.4329 70.4192 72.0006 70.4192 75.168C70.4192 78.3354 67.8514 80.9031 64.6839 80.9031C61.5163 80.9031 58.9486 78.3354 58.9486 75.168Z" fill="white"/>
        </g>
        <mask id="mask0_633_2859" maskUnits="userSpaceOnUse" x="34" y="29" width="78" height="89">
        <path d="M105.691 117.32H40.3088C36.8246 117.32 34 114.496 34 111.011V35.3086C34 31.8244 36.8246 29 40.3088 29H82.75L112 58.2488V111.011C112 114.496 109.175 117.32 105.691 117.32Z" fill="url(#paint1_linear_633_2859)"/>
        </mask>
        <g mask="url(#mask0_633_2859)">
        <ellipse opacity="0.05" cx="29.8419" cy="23.1219" rx="64.0919" ry="61.5086" fill="url(#paint2_linear_633_2859)"/>
        <ellipse opacity="0.05" cx="29.842" cy="23.122" rx="40.2904" ry="38.5683" fill="url(#paint3_linear_633_2859)"/>
        </g>
        <path d="M48.2642 90V75.4545H54.0028C55.1013 75.4545 56.0388 75.651 56.8153 76.044C57.5966 76.4323 58.1908 76.9839 58.598 77.6989C59.0099 78.4091 59.2159 79.2448 59.2159 80.206C59.2159 81.1719 59.0076 82.0028 58.5909 82.6989C58.1742 83.3902 57.5705 83.9205 56.7798 84.2898C55.9938 84.6591 55.0421 84.8438 53.9247 84.8438H50.0824V82.3722H53.4276C54.0147 82.3722 54.5024 82.2917 54.8906 82.1307C55.2789 81.9697 55.5677 81.7282 55.7571 81.4062C55.9512 81.0843 56.0483 80.6842 56.0483 80.206C56.0483 79.723 55.9512 79.3158 55.7571 78.9844C55.5677 78.6529 55.2765 78.402 54.8835 78.2315C54.4953 78.0563 54.0052 77.9688 53.4134 77.9688H51.3395V90H48.2642ZM56.1193 83.3807L59.7344 90H56.3395L52.8026 83.3807H56.1193ZM65.2963 90H62.0009L67.0222 75.4545H70.9852L75.9994 90H72.704L69.0605 78.7784H68.9469L65.2963 90ZM65.0903 84.2827H72.8744V86.6832H65.0903V84.2827ZM80.9594 90L76.7975 75.4545H80.1569L82.5646 85.5611H82.6853L85.3415 75.4545H88.218L90.8671 85.5824H90.995L93.4026 75.4545H96.762L92.6001 90H89.6029L86.833 80.4901H86.7194L83.9566 90H80.9594Z" fill="white"/>
        <g filter="url(#filter1_d_633_2859)">
        <path d="M112 58.2488H104.75C92.5997 58.2488 82.75 48.3991 82.75 36.2488V29L112 58.2488Z" fill="url(#paint4_linear_633_2859)"/>
        </g>
        <defs>
        <filter id="filter0_f_633_2859" x="8.76477" y="32.7266" width="129.044" height="102.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="19.5" result="effect1_foregroundBlur_633_2859"/>
        </filter>
        <filter id="filter1_d_633_2859" x="59.75" y="5" width="79.25" height="79.249" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="2" dy="1"/>
        <feGaussianBlur stdDeviation="12.5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_633_2859"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_633_2859" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_633_2859" x1="73" y1="29" x2="73" y2="117.32" gradientUnits="userSpaceOnUse">
        <stop stopColor="var(--color-primary-3)"/>
        <stop offset="1" stopColor="var(--color-primary-3)"/>
        </linearGradient>
        <linearGradient id="paint1_linear_633_2859" x1="73" y1="29" x2="73" y2="117.32" gradientUnits="userSpaceOnUse">
        <stop stopColor="var(--color-primary-3)"/>
        <stop offset="1" stopColor="var(--color-primary-3)"/>
        </linearGradient>
        <linearGradient id="paint2_linear_633_2859" x1="34.4954" y1="25.2355" x2="53.9308" y2="76.8191" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint3_linear_633_2859" x1="32.7673" y1="24.4474" x2="44.9308" y2="56.8128" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint4_linear_633_2859" x1="89.2022" y1="19.8239" x2="97.3744" y2="58.249" gradientUnits="userSpaceOnUse">
        <stop stopColor="var(--color-primary-3)"/>
        <stop offset="1" stopColor="var(--color-primary-3)"/>
        </linearGradient>
        </defs>
        </svg>
        
    )
}

export default Icon
