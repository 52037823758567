


import apiBackend from '@src/apiBackend'

import { createAsyncThunk } from '@reduxjs/toolkit'

export const updatePrototype = createAsyncThunk(
	'iam/updatePrototype',
	async ({ prototype }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.put(
				'/iam/prototype',
				{
					prototype: prototype,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			} else if (error.response.status == 501) {
				throw 'Permision denied'
			}
		}
	}
)
export const addPrototype = createAsyncThunk(
	'iam/addPrototype',
	async ({ prompt }, { dispatch }) => {
		try {
			const tokenGPT = localStorage.getItem('token-gpt')
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/prototype',
				{
					token: tokenGPT,
					prompt,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 401) {
				throw 'Token incorrect gpt'
			} else if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			} else if (error.response.status == 501) {
				throw 'Permision denied'
			}
		}
	}
)

export const fetchsPrototype = createAsyncThunk(
	'iam/fetchsPrototype',
	async ({ prompt }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.get('/iam/prototype', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					prompt,
				},
			})


			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			}
		}
	}
)

export const deletePrototype = createAsyncThunk(
	'iam/deletePrototype',
	async ({ id }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.delete('/iam/prototype', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				data: {
					id,
				},
			})

			return response.data
		} catch (error) {
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			} else if (error.response.status == 501) {
				throw 'Permision denied'
			}
		}
	}
)
