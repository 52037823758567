import React from 'react'

import SectionImage from './assets/section-image.png'
import VectorImage from './assets/curve.png'

import Card1Image from './assets/img-1.png'
import Card2Image from './assets/img-2.png'
import Card3Image from './assets/img-3.png'
import Card4Image from './assets/img-4.png'
import Card5Image from './assets/img-5.png'
import Card6Image from './assets/img-6.png'

const DocsAi = () => {
  return (
    <div
      className="relative w-full sm:h-full lg:pb-[100px] pb-[50px]"
      id="docsai"
    >
      <div className="container mx-auto">
        <section className="home-section-1 ">
          <div className="flex ">
            <div className="relative  z-[0]">
              <div className="mb-5 flex items-center ml-[-8px] absolute left-0 top-[10px] w-[300px]">
                <div className="bg-[#939AFF] z-[-1]  w-[40px] h-[40px] p-3 flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="54"
                    height="54"
                    viewBox="0 0 54 54"
                    fill="none"
                  >
                    <rect
                      x="-0.00012207"
                      width="54"
                      height="54"
                      fill="#939AFF"
                    />
                    <path
                      d="M31.0899 18.2117H29.8782V7H14.7282C13.2199 7 11.9999 8.22 11.9999 9.72667V44.2733C11.9999 45.78 13.2199 47 14.7265 47H38.3632C39.8699 47 41.0899 45.78 41.0899 44.2733V18.2117H31.0899ZM30.1815 35.6367H18.3615V33.3633H30.1782V35.6367H30.1815ZM34.7265 30.1817H18.3632V27.9083H34.7265V30.1817ZM34.7265 24.7267H18.3632V22.455H34.7265V24.7267ZM31.0899 17H41.0899L31.0899 7V17Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="ml-4">
                  <h4 className="text-[#939AFF] font-extrabold uppercase text-[20px]">
                    DOCS AI
                  </h4>
                </div>
              </div>
              <div className="docs-left-border"></div>
            </div>
            <div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="col-span-2 relative mt-[100px]">
                  <div className="w-full">
                    <div className="lg:pl-10 ">
                      <div className="flex items-center">
                        <div>
                          <img
                            src={VectorImage}
                            className="lg:w-[350px] z-20 lg:ml-[-25px] w-[100px]"
                          />
                        </div>
                        <p className="text-[24px] text-[#554D56] lg:text-[48px] leading-tight drop-shadow-xl text-left pl-3 lg:pl-0">
                          <span className="text-[#939AFF]">
                            Descubre tu idea fácilmente.
                          </span>
                          Documenta de forma automatizada tus proyectos, permite
                          interactuar entre texto y código para elaborar
                          funcionalidades a través de AI capaz de autogenerar
                          aplicaciones desde cero.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-end relative">
                  <div className="w-full lg:absolute lg:left-[0px] lg:top-[-100px] ">
                    <img src={SectionImage} className="z-20" />
                  </div>
                </div>
              </div>

              <div className="lg:mt-[100px]">
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3">
                  <div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mb-8 lg:mb-0">
                      <p className="mt-0 lg:text-[13px] uppercase">
                        PRIMER BENEFICIO
                      </p>
                      <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                        Claridad en la visión del proyecto
                      </h5>
                      <div className="lg:w-[90%]">
                        <p className="lg:text-[15px] mt-5">
                          La documentación de diseño transforma las ideas en
                          elementos visuales, lo que ayuda a todos los miembros
                          del equipo a comprender y visualizar mejor la visión
                          del proyecto.
                        </p>
                        <img
                          src={Card1Image}
                          className="lg:absolute bottom-[0px] left-[0px]  w-[150px] lg:w-[50%] z-20"
                        />
                      </div>
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mt-5 mb-8 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          CUARTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Facilita la capacitación de nuevosmiembros del equipo
                        </h5>
                      </div>
                      <div className="lg:w-[90%]">
                        <p className="lg:text-[15px]">
                          La documentación de diseño es una herramienta valiosa
                          para capacitar a nuevos miembros del equipo, ya que
                          proporciona una referencia visual y coherente sobre el
                          enfoque y los estándares de diseño del proyecto.
                        </p>
                      </div>
                      <img
                        src={Card4Image}
                        className="lg:absolute bottom-[-80px] left-[20%] w-[200px] lg:w-[60%] z-20"
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mb-8 lg:mb-0">
                      <p className="mt-0 lg:text-[13px] uppercase">
                        SEGUNDO BENEFICIO
                      </p>
                      <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                        Consistencia visual
                      </h5>
                      <div className="lg:w-[90%]">
                        <p className="lg:text-[15px]">
                          Al definir un conjunto coherente de elementos de
                          diseño, la documentación garantiza que todos los
                          aspectos del proyecto sigan un estilo y estética
                          visual consistentes.
                        </p>
                      </div>
                      <img
                        src={Card2Image}
                        className="lg:absolute bottom-[0px] left-[20%] z-20 w-[200px] lg:w-[50%]"
                      />
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[200px] relative mt-5 mb-8 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          QUINTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Mejora la gestión de cambios
                        </h5>
                      </div>
                      <div className="lg:w-[80%]">
                        <p className="lg:text-[15px]">
                          Si es necesario realizar cambios en el diseño, la
                          documentación proporciona una base clara para realizar
                          actualizaciones de manera coherente y controlada.
                        </p>
                      </div>
                      <img
                        src={Card5Image}
                        className="z-20 lg:absolute bottom-[-180px] right-[-10px] w-[170px] lg:w-[50%]"
                      />
                    </div>
                  </div>
                  <div className="relative md:col-span-2 lg:col-span-1 md:grid grid-cols-2 lg:block gap-4">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 relative h-[auto] lg:h-[450px] mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          TERCER BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Eficiencia en el proceso de diseño
                        </h5>
                        <p className="lg:text-[15px] mt-3">
                          Al tener un marco visual predefinido, los diseñadores
                          pueden agilizar su proceso creativo y evitar la
                          necesidad de reinventar elementos de diseño en cada
                          iteración.
                        </p>
                      </div>
                      <img
                        src={Card3Image}
                        className="lg:absolute bottom-[-70px] right-[-50px] z-20 w-[200px] lg:w-[75%]"
                      />
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative  mt-5 mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          SEXTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Documentación para futuras referencias
                        </h5>
                        <p className="lg:text-[15px] mt-3">
                          La documentación de diseño sirve como una referencia
                          para el futuro, lo que facilita la comprensión del
                          diseño original y permite mantener la consistencia en
                          el proyecto a lo largo del tiempo.
                        </p>
                      </div>
                      <img
                        src={Card6Image}
                        className="z-20 lg:absolute bottom-[-50px] left-[20%] w-[200px] lg:w-[50%]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default DocsAi
