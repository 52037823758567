import React, { useRef, useEffect } from "react"
import styles from "./D3Cluster.module.css"

import Plotly from "plotly.js-dist"

function D3Cluster() {
  useEffect(() => {

    var cluster1 = {
      x: [
        39.1, 39.5, 40.3, 36.7, 39.3, 38.9, 39.2, 34.1, 42.0, 37.8, 37.8, 41.1,
        38.6, 34.6, 36.6, 38.7, 42.5, 34.4, 37.8, 37.7, 35.9, 38.2, 38.8, 35.3,
        40.6, 40.5, 37.9, 40.5, 39.5, 37.2, 39.5, 40.9, 36.4, 39.2, 38.8, 42.2,
        37.6, 39.8, 36.5, 40.8, 36.0, 37.0, 39.6, 41.1, 37.5, 36.0, 39.6, 40.1,
        35.0, 42.0, 34.5, 41.4, 39.0, 40.6, 36.5, 37.6, 35.7, 41.3, 37.6, 41.1,
        36.4, 41.6, 35.5, 41.1, 35.9, 41.8, 33.5, 39.7, 39.6, 35.5, 42.8, 40.9,
        37.2, 36.2, 42.1, 34.6, 42.9, 36.7, 35.1, 37.3, 41.3, 36.3, 36.9, 38.3,
        38.9, 35.7, 34.0, 39.6, 36.2, 38.1, 40.3, 33.1, 35.0, 41.0, 37.7, 37.8,
        37.9, 39.7, 38.6, 38.2, 38.1, 43.2, 38.1, 39.7, 42.2, 39.6, 42.7, 38.6,
        37.3, 35.7, 41.1, 36.2, 37.7, 40.2, 41.4, 35.2, 40.6, 38.8, 41.5, 39.0,
        38.5, 43.1, 36.8, 37.5, 38.1, 41.1, 35.6, 40.2, 37.0, 39.7, 40.2, 40.6,
        32.1, 40.7, 37.3, 39.0, 39.2, 36.6, 36.0, 37.8, 36.0, 42.4, 43.2, 40.9,
        42.5, 42.5,
      ],
      y: [
        181.0, 186.0, 195.0, 193.0, 190.0, 181.0, 195.0, 193.0, 190.0, 186.0,
        180.0, 182.0, 191.0, 198.0, 185.0, 195.0, 197.0, 184.0, 174.0, 180.0,
        189.0, 185.0, 180.0, 187.0, 183.0, 187.0, 172.0, 180.0, 178.0, 178.0,
        188.0, 184.0, 195.0, 196.0, 190.0, 180.0, 181.0, 184.0, 182.0, 195.0,
        186.0, 185.0, 190.0, 182.0, 179.0, 190.0, 186.0, 188.0, 190.0, 200.0,
        187.0, 191.0, 186.0, 193.0, 181.0, 194.0, 185.0, 195.0, 185.0, 192.0,
        184.0, 192.0, 195.0, 188.0, 190.0, 198.0, 190.0, 190.0, 196.0, 190.0,
        195.0, 191.0, 184.0, 187.0, 195.0, 189.0, 196.0, 187.0, 193.0, 191.0,
        194.0, 190.0, 189.0, 189.0, 190.0, 202.0, 185.0, 186.0, 187.0, 190.0,
        196.0, 178.0, 192.0, 203.0, 183.0, 190.0, 193.0, 184.0, 199.0, 190.0,
        181.0, 197.0, 198.0, 193.0, 197.0, 191.0, 196.0, 188.0, 199.0, 189.0,
        189.0, 187.0, 198.0, 176.0, 202.0, 186.0, 199.0, 191.0, 195.0, 191.0,
        190.0, 197.0, 193.0, 199.0, 187.0, 190.0, 191.0, 200.0, 185.0, 193.0,
        193.0, 187.0, 188.0, 190.0, 192.0, 185.0, 190.0, 184.0, 195.0, 193.0,
        187.0, 181.0, 187.0, 187.0, 187.0, 187.0,
      ],
      z: [
        3750.0, 3800.0, 3250.0, 3450.0, 3650.0, 3625.0, 4675.0, 3475.0, 4250.0,
        3300.0, 3700.0, 3200.0, 3800.0, 4400.0, 3700.0, 3450.0, 4500.0, 3325.0,
        3400.0, 3600.0, 3800.0, 3950.0, 3800.0, 3800.0, 3550.0, 3200.0, 3150.0,
        3950.0, 3250.0, 3900.0, 3300.0, 3900.0, 3325.0, 4150.0, 3950.0, 3550.0,
        3300.0, 4650.0, 3150.0, 3900.0, 3100.0, 3000.0, 4600.0, 3425.0, 2975.0,
        3450.0, 3500.0, 4300.0, 3450.0, 4050.0, 2900.0, 3700.0, 3550.0, 3800.0,
        2850.0, 3750.0, 3150.0, 4400.0, 3600.0, 4050.0, 2850.0, 3950.0, 3350.0,
        4100.0, 3050.0, 4450.0, 3600.0, 3900.0, 3550.0, 3700.0, 4250.0, 3700.0,
        3900.0, 3550.0, 4000.0, 3200.0, 4700.0, 3800.0, 4200.0, 3350.0, 3550.0,
        3800.0, 3500.0, 3950.0, 3600.0, 3550.0, 3400.0, 4450.0, 3300.0, 3700.0,
        4350.0, 2900.0, 3725.0, 4725.0, 3075.0, 4250.0, 2925.0, 3550.0, 3750.0,
        3900.0, 3175.0, 4775.0, 3825.0, 3200.0, 4275.0, 3900.0, 4075.0, 2900.0,
        3775.0, 3350.0, 3325.0, 3150.0, 3500.0, 3450.0, 3875.0, 3050.0, 4000.0,
        3275.0, 4300.0, 3050.0, 3325.0, 3500.0, 3500.0, 4475.0, 3425.0, 3900.0,
        3175.0, 3975.0, 3400.0, 4250.0, 3400.0, 3475.0, 3050.0, 3725.0, 3000.0,
        3650.0, 4250.0, 3475.0, 3450.0, 3750.0, 3700.0, 3600.0, 2900.0, 3200.0,
        3350.0, 3350.0,
      ],
      mode: "markers",
      marker: {
        size: 9,
        color: "#00aeff",
        line: {
          color: "#00aeff",
          width: 0.5,
        },
        opacity: 0.8,
      },
      type: "scatter3d",
    }
    var cluster2 = {
      x: [
        46.0, 44.1, 42.3, 45.8, 41.1, 40.8, 43.2, 45.6, 44.1, 41.5, 46.5, 50.0,
        51.3, 45.4, 52.7, 45.2, 46.1, 51.3, 46.0, 51.3, 46.6, 51.7, 47.0, 52.0,
        45.9, 50.5, 50.3, 58.0, 46.4, 49.2, 48.5, 50.6, 46.7, 52.0, 50.5, 49.5,
        46.4, 52.8, 54.2, 51.0, 49.7, 47.5, 47.6, 52.0, 46.9, 53.5, 49.0, 46.2,
        50.9, 45.5, 50.9, 50.8, 50.1, 49.0, 51.5, 49.8, 48.1, 51.4, 45.7, 50.7,
        52.2, 45.2, 49.3, 50.2, 45.6, 51.9, 46.8, 45.7, 55.8, 43.5, 49.6, 50.8,
        50.2,
      ],
      y: [
        194.0, 196.0, 191.0, 197.0, 205.0, 208.0, 192.0, 191.0, 210.0, 201.0,
        192.0, 196.0, 193.0, 188.0, 197.0, 198.0, 178.0, 197.0, 195.0, 198.0,
        193.0, 194.0, 185.0, 201.0, 190.0, 201.0, 197.0, 181.0, 190.0, 195.0,
        191.0, 193.0, 195.0, 197.0, 200.0, 200.0, 191.0, 205.0, 201.0, 203.0,
        195.0, 199.0, 195.0, 210.0, 192.0, 205.0, 210.0, 187.0, 196.0, 196.0,
        196.0, 201.0, 190.0, 212.0, 187.0, 198.0, 199.0, 201.0, 193.0, 203.0,
        197.0, 191.0, 203.0, 202.0, 194.0, 206.0, 189.0, 195.0, 207.0, 202.0,
        193.0, 210.0, 198.0,
      ],
      z: [
        4200.0, 4400.0, 4150.0, 4150.0, 4300.0, 4300.0, 4100.0, 4600.0, 4000.0,
        4000.0, 3500.0, 3900.0, 3650.0, 3525.0, 3725.0, 3950.0, 3250.0, 3750.0,
        4150.0, 3700.0, 3800.0, 3775.0, 3700.0, 4050.0, 3575.0, 4050.0, 3300.0,
        3700.0, 3450.0, 4400.0, 3400.0, 3800.0, 3300.0, 4150.0, 3400.0, 3800.0,
        3700.0, 4550.0, 4300.0, 4100.0, 3600.0, 3900.0, 3850.0, 4800.0, 2700.0,
        4500.0, 3950.0, 3650.0, 3550.0, 3500.0, 3675.0, 4450.0, 3400.0, 4300.0,
        3250.0, 3675.0, 3325.0, 3950.0, 3600.0, 4050.0, 3450.0, 3250.0, 4050.0,
        3800.0, 3525.0, 3950.0, 3650.0, 3650.0, 4000.0, 3400.0, 3775.0, 4100.0,
        3775.0,
      ],
      mode: "markers",
      marker: {
        size: 9,
        color: "#26e37d",
        line: {
          color: "#26e37d",
          width: 0.5,
        },
        opacity: 0.8,
      },
      type: "scatter3d",
    }
    var cluster0 = {
      x: [
        46.1, 50.0, 48.7, 50.0, 47.6, 46.5, 45.4, 46.7, 43.3, 46.8, 40.9, 49.0,
        45.5, 48.4, 45.8, 49.3, 42.0, 49.2, 46.2, 48.7, 50.2, 45.1, 46.5, 46.3,
        42.9, 46.1, 44.5, 47.8, 48.2, 50.0, 47.3, 42.8, 45.1, 59.6, 49.1, 48.4,
        42.6, 44.4, 44.0, 48.7, 42.7, 49.6, 45.3, 49.6, 50.5, 43.6, 45.5, 50.5,
        44.9, 45.2, 46.6, 48.5, 45.1, 50.1, 46.5, 45.0, 43.8, 45.5, 43.2, 50.4,
        45.3, 46.2, 45.7, 54.3, 45.8, 49.8, 46.2, 49.5, 43.5, 50.7, 47.7, 46.4,
        48.2, 46.5, 46.4, 48.6, 47.5, 51.1, 45.2, 45.2, 49.1, 52.5, 47.4, 50.0,
        44.9, 50.8, 43.4, 51.3, 47.5, 52.1, 47.5, 52.2, 45.5, 49.5, 44.5, 50.8,
        49.4, 46.9, 48.4, 51.1, 48.5, 55.9, 47.2, 49.1, 47.3, 46.8, 41.7, 53.4,
        43.3, 48.1, 50.5, 49.8, 43.5, 51.5, 46.2, 55.1, 44.5, 48.8, 47.2, 46.8,
        50.4, 45.2, 49.9,
      ],
      y: [
        211.0, 230.0, 210.0, 218.0, 215.0, 210.0, 211.0, 219.0, 209.0, 215.0,
        214.0, 216.0, 214.0, 213.0, 210.0, 217.0, 210.0, 221.0, 209.0, 222.0,
        218.0, 215.0, 213.0, 215.0, 215.0, 215.0, 216.0, 215.0, 210.0, 220.0,
        222.0, 209.0, 207.0, 230.0, 220.0, 220.0, 213.0, 219.0, 208.0, 208.0,
        208.0, 225.0, 210.0, 216.0, 222.0, 217.0, 210.0, 225.0, 213.0, 215.0,
        210.0, 220.0, 210.0, 225.0, 217.0, 220.0, 208.0, 220.0, 208.0, 224.0,
        208.0, 221.0, 214.0, 231.0, 219.0, 230.0, 214.0, 229.0, 220.0, 223.0,
        216.0, 221.0, 221.0, 217.0, 216.0, 230.0, 209.0, 220.0, 215.0, 223.0,
        212.0, 221.0, 212.0, 224.0, 212.0, 228.0, 218.0, 218.0, 212.0, 230.0,
        218.0, 228.0, 212.0, 224.0, 214.0, 226.0, 216.0, 222.0, 203.0, 225.0,
        219.0, 228.0, 215.0, 228.0, 216.0, 215.0, 210.0, 219.0, 208.0, 209.0,
        216.0, 229.0, 213.0, 230.0, 217.0, 230.0, 217.0, 222.0, 214.0, 215.0,
        222.0, 212.0, 213.0,
      ],
      z: [
        4500.0, 5700.0, 4450.0, 5700.0, 5400.0, 4550.0, 4800.0, 5200.0, 4400.0,
        5150.0, 4650.0, 5550.0, 4650.0, 5850.0, 4200.0, 5850.0, 4150.0, 6300.0,
        4800.0, 5350.0, 5700.0, 5000.0, 4400.0, 5050.0, 5000.0, 5100.0, 4100.0,
        5650.0, 4600.0, 5550.0, 5250.0, 4700.0, 5050.0, 6050.0, 5150.0, 5400.0,
        4950.0, 5250.0, 4350.0, 5350.0, 3950.0, 5700.0, 4300.0, 4750.0, 5550.0,
        4900.0, 4200.0, 5400.0, 5100.0, 5300.0, 4850.0, 5300.0, 4400.0, 5000.0,
        4900.0, 5050.0, 4300.0, 5000.0, 4450.0, 5550.0, 4200.0, 5300.0, 4400.0,
        5650.0, 4700.0, 5700.0, 4650.0, 5800.0, 4700.0, 5550.0, 4750.0, 5000.0,
        5100.0, 5200.0, 4700.0, 5800.0, 4600.0, 6000.0, 4750.0, 5950.0, 4625.0,
        5450.0, 4725.0, 5350.0, 4750.0, 5600.0, 4600.0, 5300.0, 4875.0, 5550.0,
        4950.0, 5400.0, 4750.0, 5650.0, 4850.0, 5200.0, 4925.0, 4875.0, 4625.0,
        5250.0, 4850.0, 5600.0, 4975.0, 5500.0, 4725.0, 5500.0, 4700.0, 5500.0,
        4575.0, 5500.0, 5000.0, 5950.0, 4650.0, 5500.0, 4375.0, 5850.0, 4875.0,
        6000.0, 4925.0, 4850.0, 5750.0, 5200.0, 5400.0,
      ],
      mode: "markers",
      marker: {
        size: 9,
        color: "#b500ff",
        line: {
          color: "#b500ff",
          width: 0.5,
        },
        opacity: 0.8,
      },
      type: "scatter3d",
    }

    var data = [cluster0, cluster1, cluster2]
    var layout = {
       width: 600,
      height: 800,
      margin: {
        l: 0,
        r: 0,
        b: 0,
        t: 0,
      },
      paper_bgcolor: 'rgba(0, 0, 0, 0)',
      plot_bgcolor: 'rgba(0, 0, 0, 0)',
      scene: {
        bgcolor: 'rgba(0, 0, 0, 0)',  
      }
    }
    Plotly.newPlot("myDiv", data, layout)

    return () => {
      //- Plotly.purge("myDiv")
    }
  }, [])

  return (
  <div>
    <style>{`
     .modebar-container{display:none}
    `}</style>
    <div id="myDiv" className={styles.myDiv} />
    </div>
  )
}

export default D3Cluster
