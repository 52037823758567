const home = {
    banner: {
        banner: 'Editar foto de portada',
        edit: 'Editar perfil',
        addUser: 'Agregar usuario',
        title: 'usuarios compartidos',
        viewTeam: 'Administrar equipo',
        team: {
            title: `Personas que quizás conozas`,
            button: `Ver todas`,
            addContact: `Agregar a contactos`
        },
    },
    t1: `Organización`,
    t2: `Banca`,
    t3: `Identificación y acceso de manager (IAM)`,
    t4: `Acceso (IAM)`,
    t5: `Trabaja y entiende a tus datos como vectores`,
    t6: `Entra en la nueva dimensión del dato. Elimina la latencia para trabajar un x2000 
        veces más eficiente, perfecto para segmentar, analizar y entender grandes volúmenes de 
        datos simultáneos.`,
    t60: `Añade datos a tu vector para que trabajen por ti`,
    t61: `Ir a un vector`,
    t7: `Crear nuevo`,
    t8: `Añadir nuevo`,
    t9: `Nuevo`,
    t10: `Crea tus Addons para crear componentes vectoriales en los
        cuales representar`,
    t11: `No existe ningun addon quieres crearlo`,
    t12: `New Addon`,
    t13: `Premium Addon`,
    t14: `Consumo de datos`,
    t15: `Características`,
    t16: `Ver más`,
    t17: `Notas`,
    t18: `Añadir nueva`,
    t19: `Datos seguros`,
    t20: " RGPD - ISO 27001",
    t21: `puedes trabajar con vectores personalizados.`,
    steps: {
        addCampaign: `Crear campaña`,
        addAdvice: `Crear anuncios`,
        t1: `Revision de cumplimiento`,
        t2: `Impresiones`,
        t3: `Conversiones`,
        title: `Todos los diagnosticos de la campaña`,
        s1: `Estado de la cuenta`,
        s2: `Estado de facturación`,
        s3: `Políticas de la campaña`,
        s4: `Seguimiento de conversiones`,
        s5: `Presupuesto de la campaña`,
        s6: `Estrategia de puja`,
        s7: `Estado de la campaña`,
        s8: `Eficacia del anuncio`,
        text1: `Esta campaña no tiene anuncios`,
        text2: `Añade anuncios a los grupos de anuncios para que se publiquen`,
        text3: `Solucionarlo`,
    },
    t22: {
        t0: `En`,
        t1: `usamos`,
    },
    t23: {
        t0: `Soon available`,
        t1: `Pronto estará disponible te podemos enviar una información para el
        pro`,
    },
    t24: {
        t0: `No hay conexión a Internet`,
        t1: `Comprueba tu conexión Wi-Fi o de datos móviles, o recibe una
        notificación cuando vuelva la conexión`,
        t2: `Avisarme`,
        t3: `Volver a intentar`,
    },
    t25: {
        t0: `Not found!`,
        t1: `🚀 Not found`,
        t2: `View changes`,
    }
  }


export default home