const drive = {
    t1: `Dokumentation suchen`,
    t2: `Neu erstellen`,
    t3: `Neuer Ordner`,
    t4: `Neuer lanceDB`,
    t5: `Datei hochladen`,
    t6: `Ordner hochladen`,
    t7: `Verwenden`,
    t8: `Meine Dokumente`,
    t9: `Meine Dokumente`,
    t10: `Bald`,
    t11: `Prioritäten`,
    t12: `Geteilt`,
    t13: `Aktuelle`,
    t14: `Hervorgehoben`,
    t15: `Papierkorb`,
    t16: `Papierkorb`,
    t17: `Einen Moment...`,
    t18: `Abbrechen`,
    t19: `Erstellen`,

    x1: `Name`,
    x2: `Zuletzt bearbeitet`,
    x3: `Alle auswählen`,
    x4: `Alle abwählen`,
    x5: `Aktuelle Dateien`,
    x6: `LanceDB`,
    x7: `DB anzeigen`,
    x8: `Drive anzeigen`,
    x9: `Projekt`,
    x10: `Name`,
    x11: `Name`,
    x12: `Größe`,
    x13: `Zuletzt bearbeitet`,
    x14: `Ausgewählte Elemente`,
    x15: `Mit anderen geteilt`,
}

export default drive