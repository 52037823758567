import apiBackend from '@src/apiBackend.js'
import { createAsyncThunk } from '@reduxjs/toolkit'


export const fetchsDomain = createAsyncThunk(
	'domain/fetchsDomain',
	async ({options}, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')

			const response = await apiBackend.get('/domain', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: options,
			})

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Ya existe el police'
			}
		}
	}
)



export const connectDomainVector = createAsyncThunk(
	'domain/connectDomainVector',
	async ({ id, domains }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/domain/connect',
				{
					id,
					domains,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Error al verificar dominio'
			}
		}
	}
)


export const denyDomain = createAsyncThunk(
	'domain/denyDomain',
	async (domain, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/domain/deny',
				{
					domain,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Error deny domain'
			}
		}
	}
)



export const verifyDomain = createAsyncThunk(
	'domain/verifyDomain',
	async ({ domain, txt }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/domain/verify',
				{
					domain,
					txt,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			if (!response.data.isverify) {
				return response.data
			}

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Error al verificar dominio'
			}
		}
	}
)



export const addDomain = createAsyncThunk(
	'domain/addDomain',
	async (domain, { dispatch }) => {
		try {

			console.log('domain', domain)
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/domain',
				{
					domain,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Error al verificar dominio'
			}
		}
	}
)
