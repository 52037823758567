const bank = {
    t1: `Total inkomst`,
    t2: `Totalt resultat`,
    t3: `Hantera dina åtkomst`,
    t4: `Sök en faktura`,
    t5: `Sök en datum`,
    t6: `Min kreditkort`,
    t7: `Kortsaldo`,
    t8: `Status`,
    t9: `Aktiv`,
    t10: `Administrera`,
    t11: `Överföra`,
    t12: `Dela och få alla dina kontakter att ansluta för att få din belöning`,
    t13: `Säkerhet`,
    t14: `Aktivt kort`,
    t15: `Säker åtkomst`,
    t16: `Ändra`,
    t17: `Information`,
    t18: `Plats`,
    t19: `Adress`,
    t20: `Plånbok`,

    cardlist: {
        "t0": "Kort",
        "t1": "Lägg till",
        "t2": "Lägg till ditt betalkort på ett säkert sätt",
        "t3": "När du har lagt till ditt kort kan du hantera dina betalningar och aktiva prenumerationer.",
        "t4": "Lägg till kort",
        "t5": "Ange detaljer om ditt kreditkort för att göra säkra betalningar.",
        "t6": "Visa mer",
        "t7": "Namn",
        "t8": "Kortnummer",
        "t9": "CVC",
        "t10": "Utgång",
        "t11": "Godkänn kort",
        "t12": "Avbryt",
        "t13": "Begär ett nytt kort",
        "t14": "Du kommer att få ett nytt kort",
        "t15": "Skickat från Aythen",
        "t16": "Spanien",
        "t17": "10903",
        "t18": "Santa Perpetua de Mogoda",
        "t19": "Detta är adressen där du kommer att få kortet",
        "t20": "Detta kommer att kosta 10* Detta kommer att kosta Detta kommer att kosta Detta kommer att kosta",
        "t21": "Begär kort"
    }
}

export default bank