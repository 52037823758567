const support = {
  t1: `Aiuto di Aythen Lite`,
  t2: `Aiuti e guide`,
  t33: `Ticket aperti`,
  t3: `Accordo di Livello di Servizio (SLA) stabilisce la disponibilità della piattaforma di software`,
  t4: `Contattaci`,
  t5: `Crea un ticket di supporto`,
  t6: `Ottieni aiuto direttamente dal nostro team.`,
  t7: `Gestisci incidenti`,
  t8: `Verifica la tua licenza e i tuoi ticket aperti e chiusi.`,
  t9: `Vuoi sapere di più?`,
  t10: `Conosci Aythen PRO?`,
  t11: `Entra qui per capirlo e iscrivetevi alla nostra lista d'attesa`,
  t12: `Monitoraggio e Report`,
  t13: `Invia le tue raccomandazioni per sapere con cosa vorresti lavorare i tuoi dati.`,
  topics: {
    1: {
      title: "Cookie e Privacy",
      data: {
        1: {
          title: `I miei dati sono sicuri?`,
          text: `Lavoriamo con la certificazione ISO 27001. È uno standard riconosciuto a livello internazionale per la gestione della sicurezza delle informazioni. Questa certificazione implica che è stato implementato un sistema di gestione della sicurezza delle informazioni (SGSI) che soddisfa i controlli e i processi necessari per proteggere i dati in modo efficace.`,
        },
        2: {
          title: `Esperienza dell'utente`,
          text: `Utilizzando un provider di servizi cloud con certificazioni come ISO 27001 e che opera nell'Unione Europea, diamo maggiore fiducia nella sicurezza e nella protezione dei dati personali che gestiamo. Ciò aiuta a soddisfare i requisiti di sicurezza del GDPR e a mitigare i rischi associati all'elaborazione di dati personali.`,
        },
      },
    },
    2: {
      title: `Basi di Dati Vectorizzate`,
      data: {
        1: {
          title: `Cos'è?`,
          text: `Rappresentano un'innovazione significativa nel campo dell'archiviazione e dell'elaborazione dei dati. Vengono utilizzate in una varietà di applicazioni, incluyendo la scienza dei dati, l'apprendimento automatico e l'intelligenza artificiale. <br /><br />
                        In contrasto con le basi di dati tradizionali, che archiviano informazioni in forma di tabelle con righe e colonne, le basi di dati vectorizzate utilizzano strutture di dati vettoriali per archiviare e manipolare informazioni in modo più efficiente. Organizzando così i dati in vettori multidimensionali. Questi vettori possono contenere dati in diversi formati di file, come numeri, testo, immagini, codice... Ogni elemento del vettore rappresenta un'unità di informazione, e le operazioni vengono eseguite in modo efficiente creando flussi tra insiemi di informazioni invece di righe e colonne individuali.`,
        },
        2: {
          title: `A cosa servono?`,
          text: `Questa struttura consente un accesso rapido a grandi volumi di dati e facilita operazioni matematiche e logiche complesse in modo simultaneo. 
                        È possibile eliminare la latenza dei dati senza la necessità di elaborare tutta l'informazione, solo quella di interesse, che può essere segmentata e organizzata in cluster tra dimensioni e agenti. 
                        <br /><br />
                        - Ogni dimensione rappresenta una coordinata nello spazio multidimensionale dove i dati sono organizzati. Ciò significa che un insieme di dati vectorizzati può avere molteplici dimensioni, il che consente una rappresentazione più completa e dettagliata dell'informazione.
                        <br /><br />
                        - Gli agenti sono entità che eseguono operazioni sui dati vectorizzati. Questi agenti possono essere programmi, processi o query che interagiscono con la base di dati per recuperare, elaborare o manipolare informazioni. Possono accedere a diverse dimensioni dei dati e eseguire operazioni complesse in base ai requisiti per rappresentare graficamente o rispondere alla domanda effettuata. `,
        },
      },
    },
    3: {
      title: `Serverless Back-office`,
      data: {
        1: {
          title: `Come mi beneficia?`,
          text: `In un ambiente serverless, non è necessario amministrare server fisici o virtuali. L'infrastruttura è gestita in modo che l'applicazione possa scalare automaticamente in base alla domanda. 
                        <br /><br />
                        Ciò significa che non c'è bisogno di prevedere picchi di traffico né di regolare manualmente la capacità del server. La piattaforma scalerà automaticamente le risorse necessarie per gestire il carico di lavoro.`,
        },
        2: {
          title: `Flessibilità Operativa`,
          text: `Pertanto, si pagano solo le risorse utilizzate, il che può risparmiare in confronto ai server tradizionali dove si paga per capacità riservata, indipendentemente dall'utilizzo.`,
        },
      },
    },
    4: {
      title: `Servizi on-demand`,
      data: {
        1: {
          title: `Posso adattarlo alla mia azienda?`,
          text: `Puoi creare le tue esigenze in base alla tua struttura aziendale.`,
        },
        2: {
          title: `Logo dell'azienda`,
          text: `Fondamentale poter aggiungere il tuo logo dell'azienda nel registro per mantenere la coerenza del marchio in tutte le interazioni con clienti, fornitori e altri attori.`,
        },
        3: {
          title: `Utenti e ruoli`,
          text: `Puoi aggiungere e gestire diversi tipi di utenti e ruoli nel IAM, se sono lavoratori interni, o tuoi clienti esterni, fornitori e altri collaboratori nelle tabelle dinamiche. Così ogni tipo di utente può avere livelli di accesso e permessi diversi per garantire la sicurezza e la privacy dei dati.`,
        },
        4: {
          title: `Colori aziendali`,
          text: `Sarebbe importante personalizzare l'aspetto del CRM con i colori aziendali dell'azienda per rafforzare l'identità visiva del marchio. Ciò includerebbe la possibilità di scegliere i colori predominanti nell'interfaccia del CRM, come sfondi, pulsanti e barre di navigazione.`,
        },
        5: {
          title: `Collegamento di conti bancari`,
          text: `Per una gestione finanziaria efficiente, puoi collegare le carte serverless Aythen ai IAM (accessi e ruoli) in modo che ogni membro dell'azienda possa seguire le transazioni, i pagamenti e altre operazioni finanziarie direttamente dalla piattaforma, semplificando il processo di gestione.`,
        },
      },
    },
    5: {
      title: `Interazioni su misura`,
      data: {
        1: {
          title: `Analisi Profonda`,
          text: `La rappresentazione vettoriale consente un'analisi più profonda dei dati.`,
        },
        2: {
          title: `Intuizioni Strategiche`,
          text: `Offriamo intuizioni preziose per la presa di decisioni strategiche mediante dimensioni vettoriali.`,
        },
      },
    },
    6: {
      title: `Strumenti Essenziali`,
      data: {
        1: {
          title: `Documenti Propri`,
          text: `Puoi caricare nuovi documenti di testo, formato e collaborazione in tempo reale. Accetta qualsiasi varietà di formati di file, incluyendo documenti di testo, fogli di calcolo, presentazioni, moduli, disegni e altro.`,
        },
        2: {
          title: `Archiviazione`,
          text: `I documenti possono occupare spazio nella tua account. Puoi quindi congelarli per avere accesso a loro quando vuoi o eliminarli se non sono di interesse.`,
        },
        2: {
          title: `Condividi e Collabora`,
          text: `Puoi condividere i tuoi documenti con altri utenti e lavorare insieme in tempo reale. I collaboratori possono vedere i cambiamenti nel documento mentre vengono effettuati e aggiungere commenti per discutere idee.`,
        },
        3: {
          title: `Fattura Elettronica`,
          text: `Risparmia tempo e risorse. Automatizza il processo di emissione, invio e ricezione delle fatture, riducendo il carico amministrativo e i costi associati alla carta. Riduce errori umani e migliora la precisione nel registro e nel follow-up delle transazioni finanziarie. Tutto il necessario per soddisfare la normativa di carattere obbligatorio per qualsiasi autonomo e azienda per la regolamentazione fiscale e legale.`,
        },
        3: {
          title: `Contratti`,
          text: `Centralizzazione dell'informazione relativa ai clienti e agli accordi commerciali, facilitando la gestione e il follow-up dei contratti lungo il loro ciclo di vita.`,
        },
        4: {
          title: `Dashboard delle tue grafiche preferite`,
          text: `Creando i tuoi dashboard su misura in base alle tue interazioni effettuate, ti consente di visualizzare dati chiave e metriche importanti in modo chiaro e conciso, facilitando l'identificazione di tendenze, pattern e aree di opportunità per prendere decisioni informate. `,
        },
        5: {
          title: `Tabelle Dinamiche`,
          text: `Le tabelle dinamiche offrono un modo flessibile di riassumere i tuoi dati in base ai tuoi progetti. Elenca le schede delle persone coinvolte, lo stato, le date, i dati da diverse prospettive e livelli di dettaglio. Puoi filtrare, raggruppare e riassumere dati in modo interattivo per ottenere informazioni dettagliate e prendere decisioni informate.`,
        },
      },
    },
  },
}

export default support