const drive = {
    t1: `Cerca documentazione`,
    t2: `Crea nuovo`,
    t3: `Nuova cartella`,
    t4: `Nuovo lanceDB`,
    t5: `Carica file`,
    t6: `Carica cartella`,
    t7: `Usa`,
    t8: `I miei documenti`,
    t9: `I miei documenti`,
    t10: `Prossimamente`,
    t11: `Prioritari`,
    t12: `Condivisi`,
    t13: `Recenti`,
    t14: `Evidenziati`,
    t15: `Cestino`,
    t16: `Cestino`,
    t17: `Un momento...`,
    t18: `Annulla`,
    t19: `Crea`,

    x1: `Nome`,
    x2: `Ultima modifica`,
    x3: `Seleziona tutti`,
    x4: `Rimuovi tutti`,
    x5: `File recenti`,
    x6: `LanceDB`,
    x7: `Visualizza DB`,
    x8: `Visualizza drive`,
    x9: `Progetto`,
    x10: `Nome`,
    x11: `Nome`,
    x12: `Dimensione`,
    x13: `Ultima modifica`,
    x14: `Elementi selezionati`,
    x15: `Condiviso con`,
}

export default drive