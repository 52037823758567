const settings = {
    t1: "Startseite",
    t2: "Aythen DB",
    t3: "Kanban",
    t4: "Meine Dokumente",
    t5: "Verträge",
    t6: "Rechnungsstellung",
    info: {
        t0: `Tag hinzufügen`,
        t1: `Schlüssel`,
        t2: `Wert`,
        info: {
          t0: `exkl. Steuern mit Rabatt`,
          t1: `Aktualisiert vor 27 Minuten`
        }
    },
    invoice: {
        send: {
            "t0": "Rechnung",
            "t1": "Rechnung kopieren",
            "t2": "Organisation ändern, wenn nicht korrekt",
            "t3": "Einige Daten fehlen noch",
            "t4": "Steuernummer",
            "t5": "Steuernummer (offizielle Dokumente)",
            "t6": "Wählen Sie die Zahlungsart",
            "t7": "Mehr Info",
            "t8": "Zahlung",
            "t9": "Wie viel Geld möchten Sie erhalten",
            "t10": "Kontakte",
            "t11": "Wählen Sie einen Kontakt",
            "t12": "Organisation",
            "t13": "Wir senden Ihnen den Prozess an Ihre Konto",
            "t14": "Unverändert",
            "t15": "Ich möchte das Geld so schnell wie möglich erhalten",
            "t16": "1",
            "t17": "Erinnerung an den Versand planen",
            "t18": "2",
            "t19": "Morgen erinnern, wenn nicht bezahlt",
            "t20": "3",
            "t21": "Tägliche Erinnerung für 14 Tage",
            "t22": "Bankmodus",
            "t23": "Schnelleres Factoring für Ihre Kunden",
            "t24": "1",
            "t25": "Sofortige Zahlung und sichere Zahlung",
            "t26": "2",
            "t27": "Die Bank zahlt vor",
            "t28": "3",
            "t29": "Bessere Zahlungsbedingungen",
            "t30": "Geplanter Modus",
            "t31": "Sagen Sie uns einen Tag für die Zahlung",
            "t32": "1",
            "t33": "Planen Sie den Versand des Tages",
            "t34": "2",
            "t35": "Erinnerung während 14 Tage",
            "t36": "3",
            "t37": "Bessere Zahlungsbedingungen",
            "t38": "Rechnung senden",
            "t39": "Zahlung erfolgreich",
            "t40": "Überweisung durchführen",
            "t41": "Sie erhalten",
            "t42": "2.000$",
            "t43": "Aktuelle Karte",
            "t44": "**** 4543",
            "t45": "Gewählter Prozess",
            "t46": "Sofortige Zahlung",
            "t47": "Rechnung zahlen",
            "t48": "Abbrechen"
        }
    },
    status: {
        "t0": "Aythen",
        "t1": "Status",
        "t2": "Kontakt-Support",
        "t3": "Echtzeit-Status",
        "t4": "Historie",
        "t5": "ABONNIEREN",
        "t6": "Aythen API",
        "t7": "99,999% SLA-Aktivitätszeit",
        "t8": "15. Jan",
        "t9": "100% Uptime",
        "t10": "1. Jan",
        "t11": "Heute",
        "t12": "Legende:",
        "t13": "Erfolg",
        "t14": "Ausstehend",
        "t15": "Fehler",
        "t16": "Systemstatus",
        "t17": "Geplante Wartung für Bancontact",
        "t18": "Aythen",
        "t19": "© 2024 Aythen, Inc",
        "t20": "Besuchen Sie unsere Website",
        "t21": "Nutzungsbedingungen und Datenschutz",
        "t22": "E-Mail",
        "t23": "SMS",
        "t24": "Erhalten Sie E-Mail-Benachrichtigungen, wenn Stripe einen Vorfall erstellt, aktualisiert oder löst.",
        "t25": "E-Mail-Adresse",
        "t26": "Abonnieren per E-Mail",
        "t27": "Um bestehende E-Mail-Einstellungen zu aktualisieren, abonnieren Sie erneut.",
        "t28": "Für weitere Abonnement-Informationen besuchen Sie unsere Anweisungen im Support.",
        "t29": "Diese Website wird durch reCAPTCHA geschützt und die Google-Datenschutzerklärung und Nutzungsbedingungen gelten.",
        "t30": "Erhalten Sie SMS-Benachrichtigungen, wenn Stripe einen Vorfall erstellt oder löst.",
        "t31": "Ländercode",
        "t32": "Telefonnummer",
        "t33": "Abonnieren per SMS",
        "t34": "Um bestehende Einstellungen zu aktualisieren, abonnieren Sie erneut.",
        "t35": "Für weitere Abonnement-Informationen besuchen Sie unsere Anweisungen im Support.",
        "t36": "Nachrichten- und Datenraten können anfallen. Durch das Abonnieren stimmen Sie den Atlassian-Nutzungsbedingungen und der Atlassian-Datenschutzerklärung zu. Diese Website wird durch reCAPTCHA geschützt und die Google-Datenschutzerklärung und Nutzungsbedingungen gelten."
    },
    billing: {
        t1: `Füllen Sie die Daten aus…`,
        t2: `Währung`,
        t3: `Bearbeiten`,
        t4: `Hier sehen Sie Ihre Registrierungs- und Rechnungsdaten. Sie können diese ändern oder aktualisieren, um Ihren Zugriff auf die verschiedenen Funktionen von Aythen Lite zu überprüfen.`,
        t5: `Daten ändern`,
        t6: `Datenverbrauch`,
        t7: `Kontakt-E-Mail`,
        t8: `Zur Zusammenfassung: Jeden Monat wird Ihre elektronische Rechnung automatisch an Ihre Registrierungs-E-Mail gesendet.`,
        t9: `Rechnungs-E-Mail`,
        t10: `Speichern`,
        t12: `GPT-Token`,
        t13: `Sie können ein GPT-Token erstellen, um Ihre API-Anfragen eindeutig zu identifizieren und zu authentifizieren.`,
        t14: `Token`,
        t15: `Speichern`,
        t16: `Verbrauchslimits`,
        t17: `In Ihrer Lizenz können Sie mehr Interaktionen oder GB-Speicher benötigen. Für diese zusätzlichen Verwendungen können Sie Warnungen nach einem Gesamtbetrag konfigurieren.`,
        t177: `Mehr erfahren`,
        t18: `Warnung ab `,
        t19: `Monatliche Kosten`,
        t20: `Warnung speichern`,
        t21: `Steuernummer/VAT`,
        t222: `VAT`,
        t23: `Speichern`,
        t24: `Zahlungsmethoden`,
        t25: `Wählen Sie zwischen den folgenden Zahlungsmethoden für Ihr Konto.`,
        t26: `Wie möchten Sie Ihre Lizenz verwalten:`,
        t266: `Kreditkarte`,
        t267: `SEPA-Lastschrift`,
        t268: `Tabelle fehlt`,
        t27: `Benachrichtigungstypen`,
        t28: `Wählen und konfigurieren Sie Ihre Benachrichtigungseinstellungen. Wann sollen Ihnen Benachrichtigungen gesendet werden?`,
        t29: `Benachrichtigungstypen`,
        t30: `E-Mail-Benachrichtigungen`,
        t31: `Tägliche Erinnerungen`,
        t32: `Spezielle Ereignisbenachrichtigungen:`,
        t33: `Private Nachrichtenbenachrichtigungen:`,
        t34: `Erhaltene Rechnungen`,
        t340: `Aythen Lite Premium`,
        t341: `Auf Premium upgraden`,
        t342: `Lorem ipsum dolor et epsum holend.`,
        t343: `Lorem ipsum dolor et epsum holend et erem super tupper dolem herem
    superssupostom hellom super start.`,
        t344: `Lorem ipsum dolor et teresum`,
        t345: `Lorem ipsum dolor et teresum`,
        t346: `Kostenlos`,
        t347: `Für immer`,
        t350: `Hinzufügen`,
        t35: `Hier finden Sie Ihre Historie zwischen empfangenen und gesendeten Rechnungen.`,
        t36: `Gesendete Rechnungen`,
        t360: `Erhaltene Rechnungen`,
        t361: `von`,
        t37: `Monat`,
        t38: `Jahr`,
        t39: `Nummer`,
        t40: `Zahlungsmethode`,
        t41: `Status`,
        t42: `Gesamt (inkl. MwSt.)`,
        t43: `Herunterladen`,
        t44: `Konto löschen`,
        t45: `Sind Sie sicher, dass Sie Ihr Konto löschen möchten? Beachten Sie, dass diese Aktion nicht rückgängig gemacht werden kann und Ihr Konto in 48 Stunden deaktiviert wird, wenn Sie bestätigen.`,
        t46: `Löschen`,

        x1: `Kontonamen bearbeiten`,
        x2: `Dies ist ein Firmenkonto`,
        x3: `Firmenname`,
        x4: `Straße`,
        x5: `Straße 2`,
        x6: `Postleitzahl`,
        x7: `Stadt`,
        x8: `Land`,
        x9: `Region`,
        x10: `Änderungen bestätigen`,
    },
    contracts: {
        t1: `Organisation`,
        t2: `Konto-Verträge`,
        t3: `Name`,
        t4: `Status`,
        t5: `Endet am`,
        t6: `Herunterladen`,
        t7: `Organisieren Sie hier Ihre Geschäftsvereinbarungen.`,
        t8: `Welche Vorteile bietet es?`,
        t0: 'Vertrag hinzufügen',
        popup: {
            t0: `Eröffnet am`,
            t1: `Status`,
            t2: `Mit Benachrichtigung senden`,
            t3: `Benachrichtigung löschen`,
            t4: `Einmal senden`,
            t5: `Prioritätsversand`,
            t6: `Als unterschrieben markieren`,
            t7: `Zum Unterschreiben`,
            t8: `Um zu löschen, müssen Sie Löschen hinzufügen`,
            t9: `Unterschreiben`,
            t10: `Abbrechen`,
            t11: `Löschen`
        }
    }
}

export default settings