const support = {
  t1: `Aide d'Aythen Lite`,
  t2: `Aides et guides`,
  t33: `Tickets ouverts`,
  t3: `Accord de Niveau de Service (SLA) établit la disponibilité de la plateforme de logiciel`,
  t4: `Contactez-nous`,
  t5: `Créez un ticket de support`,
  t6: `Obtenez de l'aide directement de notre équipe.`,
  t7: `Gérez les incidents`,
  t8: `Vérifiez votre licence ainsi que vos tickets ouverts et fermés.`,
  t9: `Voulez-vous en savoir plus?`,
  t10: `Connaîsez-vous Aythen PRO?`,
  t11: `Entrez ici pour comprendre et vous inscrire sur notre liste d'attente`,
  t12: `Surveillance et Rapport`,
  t13: `Envoyez-nous vos recommandations pour savoir avec quoi vous aimeriez travailler vos données.`,
  topics: {
    1: {
      title: "Cookies et Confidentialité",
      data: {
        1: {
          title: `Sont-ils sécurisés mes données?`,
          text: `Nous travaillons avec la certification ISO 27001. C'est un standard reconnu internationalement pour la gestion de la sécurité de l'information. Cette certification implique que nous avons mis en place un système de gestion de sécurité de l'information (SGSI) qui répond aux contrôles et processus nécessaires pour protéger les données de manière efficace.`,
        },
        2: {
          title: `Expérience Utilisateur`,
          text: `En utilisant un fournisseur de services en nuage avec des certifications comme ISO 27001 et qui opère dans l'Union européenne, nous offrons une confiance accrue dans la sécurité et la protection des données personnelles que nous gérons. Cela aide à répondre aux exigences de sécurité du RGPD et à atténuer les risques associés au traitement des données personnelles.`,
        },
      },
    },
    2: {
      title: `Bases de Données Vectorisées`,
      data: {
        1: {
          title: `Qu'est-ce que cela?`,
          text: `Elles représentent une innovation significative dans le domaine du stockage et du traitement des données. Elles sont utilisées dans une variété d'applications, notamment la science des données, l'apprentissage automatique et l'intelligence artificielle. <br /><br />
                      Contrairement aux bases de données traditionnelles, qui stockent l'information sous forme de tables avec des lignes et des colonnes, les bases de données vectorisées utilisent des structures de données vectorielles pour stocker et manipuler l'information de manière plus efficace. Organisant ainsi les données en vecteurs multidimensionnels. Chaque élément du vecteur représente une unité d'information, et les opérations sont réalisées de manière efficace en créant des flux entre des ensembles d'informations plutôt que des lignes et des colonnes individuelles.`,
        },
        2: {
          title: `À quoi servent-elles?`,
          text: `Cette structure permet un accès rapide à de vastes volumes de données et facilite les opérations mathématiques et logiques complexes de manière simultanée. C'est-à-dire, elle élimine la latence des données sans avoir besoin de traiter toute l'information, uniquement celle qui est d'intérêt, qui peut être segmentée et organisée en clusters entre les dimensions et les agents. 
                      <br /><br />
                      - Chaque dimension représente une coordonnée dans un espace multidimensionnel où les données sont organisées. Cela signifie qu'un ensemble de données vectorisées peut avoir plusieurs dimensions, ce qui permet une représentation plus complète et détaillée de l'information.
                      <br /><br />
                      - Les agents sont des entités qui réalisent des opérations sur les données vectorisées. Ils peuvent être des programmes, des processus ou des requêtes qui interagissent avec la base de données pour récupérer, traiter ou manipuler l'information. Ils peuvent accéder à différentes dimensions des données et réaliser des opérations complexes en fonction des exigences pour représenter graphiquement ou répondre à la question posée. `,
        },
      },
    },
    3: {
      title: `Serveur sans Serveur`,
      data: {
        1: {
          title: `En quoi cela m'avantage-t-il?`,
          text: `Dans un environnement sans serveur, il n'est pas nécessaire d'administrer des serveurs physiques ou virtuels. L'infrastructure est gérée pour que l'application puisse s'adapter automatiquement en fonction de la demande. 
                      <br /><br />
                      Cela signifie qu'il n'y a pas besoin de prévoir des pics de trafic ni d'ajuster manuellement la capacité du serveur. La plateforme adaptera automatiquement les ressources nécessaires pour gérer la charge de travail.`,
        },
        2: {
          title: `Flexibilité Opérationnelle`,
          text: `Par conséquent, vous ne payez que les ressources utilisées, ce qui peut économiser par rapport aux serveurs traditionnels où vous payez pour la capacité réservée, indépendamment de si elle est utilisée ou non.`,
        },
      },
    },
    4: {
      title: `Services à la Demande`,
      data: {
        1: {
          title: `Puis-je l'adapter à mon entreprise?`,
          text: `Vous pouvez créer vos besoins en fonction de votre propre structure d'entreprise.`,
        },
        2: {
          title: `Logo de l'entreprise`,
          text: `Il est essentiel de pouvoir ajouter votre logo d'entreprise au registre pour maintenir la cohérence de marque dans toutes les interactions avec les clients, les fournisseurs et les autres acteurs.`,
        },
        3: {
          title: `Utilisateurs et Rôles`,
          text: `Vous pouvez ajouter et gérer différents types d'utilisateurs et de rôles dans le IAM, si ce sont des travailleurs internes, ou vos clients externes, fournisseurs et autres collaborateurs dans nos tableaux dynamiques. Ainsi, chaque type d'utilisateur peut avoir des niveaux d'accès et des autorisations différents pour garantir la sécurité et la confidentialité des données.`,
        },
        4: {
          title: `Couleurs Corporatives`,
          text: `Il serait important de personnaliser l'apparence du CRM avec les couleurs corporatives de l'entreprise pour renforcer l'identité visuelle de la marque. Cela inclurait la possibilité de choisir les couleurs prédominantes dans l'interface du CRM, comme les arrière-plans, les boutons et les barres de navigation.`,
        },
        5: {
          title: `Liaison des Comptes Bancaires`,
          text: `Pour une gestion financière efficace, vous pouvez lier les cartes serverless Aythen aux IAM (accès et rôles) pour que chaque membre de l'entreprise puisse suivre les transactions, les paiements et les autres opérations financières directement depuis la plateforme, simplifiant ainsi le processus de gestion.`,
        },
      },
    },
    5: {
      title: `Interactions sur Mesure`,
      data: {
        1: {
          title: `Analyse Profonde`,
          text: `La représentation vectorielle permet une analyse plus approfondie des données.`,
        },
        2: {
          title: `Aperçus Stratégiques`,
          text: `Nous offrons des aperçus précieux pour la prise de décisions stratégiques via des dimensions vectorielles.`,
        },
      },
    },
    6: {
      title: `Outils Essentiels`,
      data: {
        1: {
          title: `Documents Propres`,
          text: `Vous pouvez télécharger de nouveaux documents de texte, de format et de collaboration en temps réel. Il prend en charge tout format de fichier, notamment des documents de texte, des feuilles de calcul, des présentations, des formulaires, des dessins et plus.`,
        },
        2: {
          title: `Stockage`,
          text: `Les documents peuvent occuper de l'espace dans votre compte. Vous pouvez donc les geler pour y accéder lorsque vous le souhaitez ou les supprimer si elles ne sont plus d'intérêt.`,
        },
        2: {
          title: `Partager et Collaborer`,
          text: `Vous pouvez partager vos documents avec d'autres utilisateurs et travailler ensemble en temps réel. Les collaborateurs peuvent voir les modifications apportées au document au fur et à mesure qu'elles sont réalisées et ajouter des commentaires pour discuter des idées.`,
        },
        3: {
          title: `Facture Électronique`,
          text: `Économisez du temps et des ressources. Automatisez le processus d'émission, d'envoi et de réception des factures, ce qui réduit la charge administrative et les coûts associés au papier. Réduisez les erreurs humaines et améliorez la précision dans l'enregistrement et le suivi des transactions financières. Tout ce qui est nécessaire pour répondre aux exigences légales et fiscales pour tout travailleur indépendant et entreprise.`,
        },
        3: {
          title: `Contrats`,
          text: `Centralisation de l'information relative aux clients et aux accords commerciaux, ce qui facilite la gestion et le suivi des contrats tout au long de leur cycle de vie.`,
        },
        4: {
          title: `Tableaux de Bord de vos Graphiques Favoris`,
          text: `Créez vos tableaux de bord à mesure en fonction de vos interactions pour visualiser les données clés et les métriques importantes de manière claire et concise, ce qui facilite l'identification des tendances, des modèles et des domaines d'opportunité pour prendre des décisions éclairées. `,
        },
        5: {
          title: `Tableaux Dynamiques`,
          text: `Les tableaux dynamiques offrent une manière flexible de résumer vos données en fonction de vos projets. Énumérez les fiches des personnes impliquées, le statut, les dates, les données depuis différentes perspectives et niveaux de détail. Vous pouvez filtrer, grouper et résumer les données de manière interactive pour obtenir des informations détaillées et prendre des décisions éclairées.`,
        },
      },
    },
  },
}

export default support