import { createSlice } from "@reduxjs/toolkit"

import {
  fetchUserStripeCards,
  deleteUserMethodCard
} from "../actions/stripe"

const stripeSlice = createSlice({
  name: "stripe",
  initialState: {
   cards: []
  },
  reducers: {
    setUser: (state, action) => {
    },
   
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchUserStripeCards.fulfilled, (state, action) => {
      state.cards = action.payload
      })
      .addCase(deleteUserMethodCard.fulfilled, (state, action) => {
        state.cards = state.cards.filter((card) => card.id !== action.payload)
      })
  },
})

export const {
} = stripeSlice.actions

export default stripeSlice.reducer
