const settings = {
    t1: "Inicio",
    t2: "Ajustes",
    t3: "Documentos",
    t4: "Contratos",
    t5: "Calendario",
    info: {
        t0: `Añadir tag`,
        t1: `Key`,
        t2: `Value`,
        t3: `Tags`,
        t4: `Las etiquetas de valor clave le ayudan a organizar a sus usuarios.`,
        info: {
          t0: `excl. taxes with discount`,
          t1: `Updated 27 minutes ago`
        }
    },
    invoice: {
        send: {
            "t0": "Invoice",
            "t1": "Escanear factura",
            "t2": "Cambia la organización si no corresponde",
            "t3": "Falta algun dato por rellenar",
            "t4": "Numero de identificación fisical",
            "t5": "DNI (documentación oficials)",
            "t6": "Crea y comparte facturas con tus clientes más rápido",
            "t7": "Más info",
            "t8": "Cuánto quieres solicitar",
            "t9": "Qué cantidad quieres recibir de dinero",
            "t10": "Contactos",
            "t11": "Añadir un receptor de la factura",
            "t12": "Porveedor",
            "t13": "Le enviaremos un proceso a la cuenta",
            "t14": "Solicitar un pago instantaneo",
            "t15": "Quiero solicitar un pago con normalidad",
            "t16": "1",
            "t17": "Enviar ahora un pago instantaneo",
            "t18": "2",
            "t19": "Enviar y recordar mañana",
            "t20": "3",
            "t21": "Enviar y recordar durante 5 días",
            "t22": "Programar un pago urgente",
            "t23": "Los pagos van a estar activos",
            "t24": "1",
            "t25": "Programar pago para día",
            "t26": "2",
            "t27": "Programar pago durante",
            "t28": "3",
            "t29": "Factoring recibe al instante",
            "t38": "Enviar factura",
            "t39": "Pago exitoso",
            "t40": "Realizar transferencia",
            "t41": "Vas a recibir",
            "t42": "2.000$",
            "t43": "Tarjeta Actual",
            "t44": "**** 4543",
            "t45": "Proceso elegido",
            "t46": "Pago instantaneo",
            "t477": "Siguiente Paso",
            "t47": "Enviar Factura",
            "t48": "Cancelar",
            "t488": "Atrás"
        }
    },
    status: {
        "t0": "Aythen",
        "t1": "status",
        "t2": "Soporte de Contacto",
        "t3": "Estado en vivo",
        "t4": "Historial",
        "t5": "SUSCRÍBETE",
        "t6": "Aythen API",
        "t7": "99,999% SLA tiempo de actividad",
        "t8": "Jan 15",
        "t9": "100% uptime",
        "t10": "Jan 1",
        "t11": "Today",
        "t12": "Legenda:",
        "t13": "Éxito",
        "t14": "Pendiente",
        "t15": "Error",
        "t16": "Estado del sistema",
        "t17": "Scheduled maintance for Bancontact",
        "t18": "Aythen",
        "t19": "© 2024 Aythen, Inc",
        "t20": "Visita nuestra web",
        "t21": "Términos y privacidad",
        "t22": "Email",
        "t23": "Text Message",
        "t24": "Get email notifications whenever Stripe creates, updates, or resolves an incident.",
        "t25": "Email address",
        "t26": "Subscribe via email",
        "t27": "To update existing email preferences, re-subscribe.",
        "t28": "For more subscription information, visit our instructions in Support.",
        "t29": "This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.",
        "t30": "Get text message notifications whenever Stripe creates or resolves an incident.",
        "t31": "Country code",
        "t32": "Phone number",
        "t33": "Subscribe via text message",
        "t34": "To update existing preferences, re-subscribe.",
        "t35": "For more subscription information, visit our instructions in Support.",
        "t36": "Message and data rates may apply. By subscribing you agree to the Atlassian Terms of Service, and the Atlassian Privacy Policy. This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply."
    },
    billing: {
        t1: `Rellena los datos…`,
        t2: `Currency`,
        t3: `Editar`,
        t4: `Aquí aparecen tus datos de registro y de facturación. Podrás cambiar o
modificarlos para verificar tu acceso a las diferentes funcionalidades que Aythen Lite te ofrece.`,
        t5: `Modificar datos`,
        t6: `Consumo de datos`,
        t7: `Email de contacto`,
        t8: `A modo de recopilación, cada mes tendrás tu factura electrónica preparada de forma
automática a tu email de registro.`,
        t9: `Billing email`,
        t10: `Guardar`,
        h12: `Token de Stripe`,
        h13: `Puede crear un token de Stripe para identificar y autenticar de forma única sus solicitudes de API.`,
        h133: `Ir a Stripe`,
        h14: `Token`,
        h15: `Guardar`,
        w12: `Obtener Whatsapp para Aythen`,
        w133: `Ir a Whatsapp`,
        w14: `Token`,
        w15: `Guardar`,
        t12: `Token de GPT`,
        t13: `Puede crear un token GPT para identificar y autenticar de forma única sus solicitudes de API.`,
        t133: `Ir a Open AI`,
        t14: `Token`,
        t15: `Guardar`,
        t16: `Límites de consumo`,
        t17: `En tu licencia puedes necesitar más interacciones o GBs de almacenamiento. 
Para esos usos adicionales puedes configurar alertas según un total.`,
        t177: `Saber más`,
        t18: `Añadir alerta a partir de `,
        t19: `Costes Mensuales`,
        t20: `Guardar Alerta`,
        t21: `Identificación NIF/VAT`,
        t22: `Ingresa tu número de identificación fiscal para poder desgravarte el IVA de 
tus facturas. `,
        t222: `VAT`,
        t23: `Guardar`,
        t24: `Métodos de pago`,
        t25: `Escoge entre los siguientes métodos de pago para tu cuenta.`,
        t26: `Como quieres gestionar tu licencia:`,
        t266: `Credit card`,
        t267: `SEPA Direct Debt`,
        t27: `Tipos de notificaciones`,
        t28: `Seleccione y configure sus preferencias de notificación. ¿Cuándo se le enviarán las notificaciones?`,
        t29: `Tipos de notificaciones`,
        t30: `Email Notificaciones`,
        t31: `Daily Reminders`,
        t32: `Special Event Notifications:`,
        t33: `Private Message Notifications:`,
        tt34: `Facturas recibidas`,
        t34: `Facturas enviadas`,
        t340: `Aythen Lite Premium`,
        t341: `Plan a Business`,
        t342: `Aumenta tu espacio privado.`,
        t343: `Con el plan Business vas a poder usar el sistema para lo que necesites
        y tendrás almacenamiento disponible para tu proyecto.`,
        t344: `Disfruta de tus facturas`,
        t345: `Actualizaciones semanales`,
        t346: `Premium`,
        t347: `Suscríbete Ahora`,
        t348: `Ver todos los planes`,
        t350: `Añadir`,
        t35: `Envia facturas a tus clientes.`,
        t36: `Facturas enviadas`,
        t361: `de`,
        t37: `Month`,
        t38: `Year`,
        t39: `Number`,
        t40: `Payment method`,
        t41: `Status`,
        t42: `Total (Vat incl.)`,
        t43: `Download`,
        t44: `Eliminar cuenta`,
        t45: `¿Estás seguro de que deseas eliminar tu cuenta? Ten en cuenta que esta acción no se puede deshacer y tu cuenta será desactivada en 48 horas si confirmas.`,
        t46: `Eliminar`,

        x2: `Editar cuenta`,
        x3: `Company name`,
        x4: `Street Address`,
        x5: `Street Address 2`,
        x6: `Postal Code`,
        x7: `City`,
        x8: `Country`,
        x9: `Region`,
        x10: `Confirm changes`,
    },
    contracts: {
        t2: `Account Docs`,
        t3: `Name`,
        t4: `Status`,
        t5: `End At`,
        t6: `Download`,
        t7: `Organiza aquí tus acuerdos empresariales.`,
        t8: `¿Qué beneficios tiene?`,
        t9: 'Añadir',
        popup: {
            t0: `Abierto en`,
            t1: `Estado`,
            t2: `Enviar con aviso`,
            t3: `Eliminar aviso`,
            t4: `Enviar una vez`,
            t7: `Para firmar`,
            t8: `Para eliminar debes añadir Eliminar`,
            t9: `Guardar`,
            t10: `Firmar`,
            t11: `Eliminar`,
        }
    }
}

export default settings
