import apiBackend from '@src/apiBackend.js'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

export const createMeet = createAsyncThunk(
	'meet/createMeet',
	async ({
		meetName,
		participantsIds,
		creatorId,
		joinId,
		type,
		usersData,
		description,
		meetHour,
		meetDate,
		reminder,
		location,
		attachments,
		syncWithCalendar,
		syncedWith,
		isPinned,
	}) => {
	
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/meet/createMeet',
				{
					meetName,
					participantsIds,
					creatorId,
					joinId,
					type,
					usersData,
					description,
					meetHour,
					meetDate,
					reminder,
					location,
					attachments,
					syncWithCalendar,
					syncedWith,
					isPinned,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error create meet:', error)
		}
	}
)

export const updateMeet = createAsyncThunk(
	'meet/updateMeet',
	async ({ meetId, toUpdate }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/meet/updateMeet',
				{
					meetId,
					toUpdate,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
		}
	}
)

export const getMeetData = createAsyncThunk(
	'meet/getMeetData',
	async ({ meetId }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.get(`/meet/getMeetData/${meetId}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})

			return res.data
		} catch (error) {
			console.error('Error fetching meet data:', error)
			throw error
		}
	}
)

export const sendWhatsappInvitation = createAsyncThunk(
	'meet/sendWhatsappInvitation',
	async ({ sessionId, number, message, isGroup }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/meet/sendWhatsappInvitation',
				{ sessionId, number, message, isGroup },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.error('Error sending whatsapp invitation:', error)
		}
	}
)

export const deleteMeet = createAsyncThunk(
	'meet/deleteMeet',
	async ({ meetId, userId }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/meet/deleteMeet',
				{
					meetId,
					userId,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.error('Error on delete meet:', error)
		}
	}
)

export const getUserMeets = createAsyncThunk(
	'meet/getUserMeets',
	async ({ userId }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				'/meet/getUserMeets',
				{
					userId,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error user meets:', error)
		}
	}
)

export const createGptChat = createAsyncThunk(
	'meet/createGptChat',
	async ({ meetId, chatTitle, messages, createdAt }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				'/meet/createGptChat',
				{ meetId, chatTitle, messages, createdAt },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error user meets:', error)
		}
	}
)

export const sendMessageToGptChat = createAsyncThunk(
	'meet/sendMessageToGptChat',
	async ({ userId, userName, meetId, gptChatId, message }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				'/meet/sendMessageToGptChat',
				{
					userId,
					userName,
					meetId,
					gptChatId,
					message,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error user meets:', error)
		}
	}
)

export const addUsersToExistentMeet = createAsyncThunk(
	'meet/addUsersToExistentMeet ',
	async ({ meetId, participantsIds }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				'/meet/addUsersToExistentMeet',
				{ meetId, participantsIds },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error users meet:', error)
		}
	}
)

export const leaveMeet = createAsyncThunk(
	'meet/leaveMeet ',
	async ({ meetId, leaverId }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				'/meet/leaveMeet',
				{ meetId, leaverId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error leave meet:', error)
		}
	}
)

export const getMeetMessages = createAsyncThunk(
	'meet/getMeetMessages',
	async ({ meetId }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				'/meet/getMeetMessages',
				{
					meetId,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error meet message:', error)
		}
	}
)

export const sendMessage = createAsyncThunk(
	'meet/sendMessage ',
	async ({
		meetId,
		type,
		senderId,
		senderName,
		senderPicture,
		message,
		selectedTool,
		responseTo,
		hasFiles,
		files,
	}) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				'/meet/sendMessage',
				{
					meetId,
					type,
					senderId,
					senderName,
					senderPicture,
					message,
					hasFiles,
					files,
					selectedTool,
					responseTo,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error send message:', error)
		}
	}
)

export const deleteMessage = createAsyncThunk(
	'meet/deleteMessage',
	async ({ meetId, messageId }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.delete(
				`/meet/deleteMessage/${meetId}/${messageId}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			throw error
		}
	}
)

export const likeMessage = createAsyncThunk(
	'meet/likeMessage',
	async ({ meetId, messageId, userId }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				`/meet/likeMessage`,
				{ meetId, messageId, userId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			return res.data
		} catch (error) {
			console.log('Error liking message:', error)
		}
	}
)

export const dislikeMessage = createAsyncThunk(
	'meet/dislikeMessage',
	async ({ meetId, messageId, userId }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				`/meet/dislikeMessage`,
				{ meetId, messageId, userId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			return res.data
		} catch (error) {
			console.log('Error disliking message:', error)
		}
	}
)

export const setMessagesToReaded = createAsyncThunk(
	'meet/setMessagesToReaded ',
	async ({ meetId, readerId }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				'/meet/setMessagesToReaded',
				{ meetId, readerId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error messages to readed:', error)
		}
	}
)

export const partialUpdateMeetBot = createAction('/meet/partialUpdate')
