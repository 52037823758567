const web = {
    t1: {
        '01': `Modellera dina`,
        '02': `data`,
        '03': `avancerat,`,
        '04': `förutsäg din`,
        '05': `matris`,
        '06': `till vilken möjlig verklighet som helst och analysera`,
        '07': `stora datamängder`,
        '08': `med vektoriserade databaser`,
    },
    t2: `BÖRJA NU`,
    t3: `På ditt nya operativsystem`,
    t4: {
        '01': `Offline`, 
        '02': `Online`, 
        '03': `Publik`, 
        '04': `Privat`, 
    },
    tassistant: {
        '01': `Hastighet`,
        '02': `Textur`,
        '03': `Bearbetning`,
    },
    t5: `En värld av möjligheter`,
    t6: {
        '01': `Tack vare de`,
        '02': `vektorisrade databaserna,`,
        '03': `går du från`,
        '04': `statiska data`,
        '05': `till`,
        '06': `dynamiska data.`,
        '07': `Att arbeta med massor av data samtidigt är svårt, därför är`,
        '08': `noll fördröjning i vissa dimensioner`,
        '09': `nödvändigt för snabb och mångsidig åtkomst.`,
    },
    t7: `Gå in nu`,
    t8: `SPARA TID . SPARA TID . SPARA TID`,
    t9: `Dra dina egna slutsatser`,
    t10: `Information är makt`,
    t11: {
        '01': `Nu kan du förstå dina data!`,
        '02': `Genom segmentering, med konceptet`,
        '03': `K-Means,`,
        '04': `har du ett verktyg för att analysera vilka data som helst för att mäta.`,
    },
    t12: `Börja nu`,
    t13: `Plats tillgänglig i molnet. Allt skalbart på privata och säkra instanser.`,
    t14: `gånger snabbare än att arbeta med traditionella databaser.`,
    t15: `Möjliga representationer för att importera dina mätetal och KPI:er.`,
    t16: `Analysera data över dimensioner`,
    t17: `Varje dimension har agenter.`,
    t18: {
        '01': `I varje dimension kan du skapa`,
        '02': `flöden av agenter`,
        '03': `för att skapa nätverk av interaktioner.`
    },
    t19: {
        '01': `I varje agent kommer du att välja`,
        '02': `strategi`,
        '03': `för att relatera till`,
        '04': `varje data.`,
        '05': `varje data.`,
    },
    t20: `Börja din GRATIS provperiod`,
    t21: `Dimensioner`,
    t22: `Agenter`,
    t23: `Idéer för hantering av data`,
    t24: `Dina data det första steget`,
    t25: {
        '01': `De är din nyckel för att bygga`,
        '02': `fantastiska applikationer. De är inte bara information,`,
        '03': `de är råmaterial`,
        '04': `för skapandet av`,
        '05': `innovativa idéer och deras analys.`,
        '06': `Hur arbetar vi med data?`,
    },
    t26: `Ditt arbetsutrymme`,
    t27: `Lagra allt innehåll du behöver i din privata eller offentliga molntjänst enligt dina behov.`,
    t28: `Värmekarta`,
    t29: `Representation som visar distributionen och koncentrationen av vissa värden inom en dataset.`,
    t30: `Dynamiska frågor`,
    t31: `Skapande av interaktiva frågeformulär, där frågor kan anpassas till befintliga data.`,
    t32: `Anpassade diagram`,
    t33: `Möjliggör val av diagramtyp, data att visualisera och anpassning av aspekter som färger och etiketter.`,
    t34: `Videokonferenser`,
    t35: `Kommunikation i realtid via video och ljud, underlättar samarbete och interaktion.`,
    t36: `Kontrakt`,
    t37: `Skapande, hantering och elektronisk fakturering av kontrakt, förenkling av juridiska och administrativa processer.`,
    t38: `Roller och behörigheter`,
    t39: `Tilldela olika roller och nivåer av åtkomst till användare inom ditt eget team för att skapa din organisation.`,
    t40: `Team synkronisering`,
    t41: `Realtids samarbete om arbete mellan flera användare, vilket säkerställer att gjorda ändringar automatiskt säkerställs.`,
    t42: `Alla dina kontakter`,
    t43: `Centralisera och organisera alla kontakter. Hantera och kom åt kontaktinformation enkelt.`,
    t44: `Lär dig att göra det, det är väldigt enkelt`,
    t45: `Ändra dimensioner`,
    t46: {
        '01': `Gör dina data intelligenta, anslutbara, konsekventa, säkra, effektiva och tillgängliga. Stärk dina analyser genom`,
        '02': `vår tillgång.`
    },
    t47: {
        '01': `Börja med `,
        '02': `handledningen`,
        '03': `för att förstå de olika delarna av plattformen.`,
    },
    t48: `Registrera dig NU`,
    t49: `Uppskatta din tid och automatisera dina data`,
    t50: {
        '01': `Och börja byta dimensioner i vårt molnbaserade operativsystem. Analysera och förstå mönster för att`,
        '02': `arbeta med dina data.`,
    },


    footer: {
        t00: "HEM",
        t01: "Ditt team",
        t02: "Uppdateringar",
        t03: "Digitalt kit",
        t04: "Konsultera",
        t05: "Studera",
    
        t10: "LÄNKAR",
        t11: "Team",
        t12: "Frilansare",
        t13: "Villkor",
        t14: "Funktioner",
        t15: "Kalkylator",
        t16: "Planer",
    
        t20: "AI MODUL",
        t21: "Röst",
        t22: "Bild & Video",
        t23: "Innehållsskapande",
        t24: "Medieskapande",
    
        t30: "STÖD AYTHEN",
        t31: "Användaravtal",
        t32: "Integritetspolicy",
        t33: "Datainsamling och användning",
        t34: "Innehållsriktlinjer",
    
        t40: "FÖR ATT DESIGNA",
        t41: "Redigerare",
        t42: "Appar",
        t43: "Moln",
        t44: "Tillägg",
        t45: "RPA",
    
        t50: "FÖR ATT HITTA",
        t51: "Bibliotek",
        t52: "Förinställningar",
        t53: "Devops",
        t54: "IDE",
        t55: "Sandbox",
    
        t60: "FÖR ATT BYGGA",
        t61: "UX/AI",
        t62: "Översätt",
        t63: "AI-dokument",
        t64: "AI-stöd",
        t65: "Samarbeten",
    
        t70: "FÖR ATT SKYDDA",
        t71: "Blockchain",
        t72: "Data",
        t73: "Pivot",
        t74: "Akademi",
        t75: "Frilansare",
    }
    
}

export default web
