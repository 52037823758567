const settings = {
    t1: "Thuis",
    t2: "Aythen DB",
    t3: "Kanban",
    t4: "Mijn documenten",
    t5: "Contracten",
    t6: "Facturatie",
    info: {
        t0: `Tag toevoegen`,
        t1: `Sleutel`,
        t2: `Waarde`,
        info: {
          t0: `excl. btw met korting`,
          t1: `Bijgewerkt 27 minuten geleden`
        }
    },
    invoice: {
        send: {
            "t0": "Factuur",
            "t1": "Factuur kopiëren",
            "t2": "Wijzig de organisatie als deze niet overeenkomt",
            "t3": "Er ontbreken nog enkele gegevens om in te vullen",
            "t4": "Fiscaal identificatienummer",
            "t5": "DNI (officiële documenten)",
            "t6": "Kies de betaalmethode die u wilt gebruiken",
            "t7": "Meer info",
            "t8": "Betaling",
            "t9": "Hoeveel geld wilt u ontvangen",
            "t10": "Contacten",
            "t11": "Selecteer een contact",
            "t12": "Organisatie",
            "t13": "Wij zullen een proces naar uw account sturen",
            "t14": "Onaangetast",
            "t15": "Ik wil het geld zo snel mogelijk ontvangen",
            "t16": "1",
            "t17": "Programma een verzendmelding",
            "t18": "2",
            "t19": "Morgen herinneren als niet betaald",
            "t20": "3",
            "t21": "Herinneren elke dag gedurende 14 dagen",
            "t22": "Bankmodus",
            "t23": "Snelste factoring voor uw klanten",
            "t24": "1",
            "t25": "Onmiddellijke en veilige betaling",
            "t26": "2",
            "t27": "De bank vooruitbetaling",
            "t28": "3",
            "t29": "Beter betaalcondities",
            "t30": "Programma modus",
            "t31": "Geef ons een dag om de betaling te vragen",
            "t32": "1",
            "t33": "Programma de verzending van de dag",
            "t34": "2",
            "t35": "Waarschuwen tijdens 14 dagen",
            "t36": "3",
            "t37": "Beter betaalcondities",
            "t38": "Factuur versturen",
            "t39": "Betaling geslaagd",
            "t40": "Uitvoeren overboeking",
            "t41": "U ontvangt",
            "t42": "2.000$",
            "t43": "Huidige creditcard",
            "t44": "**** 4543",
            "t45": "Geselecteerd proces",
            "t46": "Onmiddellijke betaling",
            "t47": "Factuur betalen",
            "t48": "Annuleren"
        }
    },
    status: {
        "t0": "Aythen",
        "t1": "status",
        "t2": "Contactondersteuning",
        "t3": "Live status",
        "t4": "Geschiedenis",
        "t5": "ABONNEER",
        "t6": "Aythen API",
        "t7": "99,999% SLA tijd van activiteit",
        "t8": "15 jan",
        "t9": "100% uptime",
        "t10": "1 jan",
        "t11": "Vandaag",
        "t12": "Legenda:",
        "t13": "Succes",
        "t14": "In behandeling",
        "t15": "Fout",
        "t16": "Systeemstatus",
        "t17": "Geplande onderhoud voor Bancontact",
        "t18": "Aythen",
        "t19": "© 2024 Aythen, Inc",
        "t20": "Bezoek onze website",
        "t21": "Voorwaarden en privacy",
        "t22": "E-mail",
        "t23": "Tekstbericht",
        "t24": "Ontvang e-mailmeldingen wanneer Stripe een incident maakt, bijwerkt of oplost.",
        "t25": "E-mailadres",
        "t26": "Abonneer via e-mail",
        "t27": "Om bestaande e-mailvoorkeuren bij te werken, abonneer opnieuw.",
        "t28": "Voor meer abonneerinformatie, bezoek onze instructies in Ondersteuning.",
        "t29": "Deze site wordt beschermd door reCAPTCHA en de Google Privacy Policy en Terms of Service zijn van toepassing.",
        "t30": "Ontvang tekstberichtmeldingen wanneer Stripe een incident maakt of oplost.",
        "t31": "Landcode",
        "t32": "Telefoonnummer",
        "t33": "Abonneer via tekstbericht",
        "t34": "Om bestaande voorkeuren bij te werken, abonneer opnieuw.",
        "t35": "Voor meer abonneerinformatie, bezoek onze instructies in Ondersteuning.",
        "t36": "Bericht- en datatarieven kunnen van toepassing zijn. Door te abonneren, gaat u akkoord met de Atlassian Terms of Service en de Atlassian Privacy Policy. Deze site wordt beschermd door reCAPTCHA en de Google Privacy Policy en Terms of Service zijn van toepassing."
    },
    billing: {
        t1: `Vul de gegevens in…`,
        t2: `Valuta`,
        t3: `Bewerken`,
        t4: `Hier worden uw registratie- en facturatiegegevens weergegeven. U kunt deze wijzigen of
bewerken om toegang te krijgen tot de verschillende functionaliteiten die Aythen Lite biedt.`,
        t5: `Gegevens bewerken`,
        t6: `Gegevensverbruik`,
        t7: `Contact e-mail`,
        t8: `Als een soort verzameling, ontvangt u elke maand automatisch uw elektronische factuur op uw registratie-e-mail.`,
        t9: `Facturatie e-mail`,
        t10: `Opslaan`,
        t12: `GPT-token`,
        t13: `U kunt een GPT-token maken om uw API-aanvragen uniek te identificeren en te authenticeren.`,
        t14: `Token`,
        t15: `Opslaan`,
        t16: `Verbruikslimieten`,
        t17: `In uw licentie kunt u meer interacties of GB's opslagruimte nodig hebben. 
Voor deze extra gebruiken kunt u waarschuwingen configureren op basis van een totaal.`,
        t177: `Meer weten`,
        t18: `Waarschuwing toevoegen vanaf `,
        t19: `Maandelijkse kosten`,
        t20: `Waarschuwing opslaan`,
        t21: `Fiscale identificatie/VAT`,
        t222: `BTW`,
        t23: `Opslaan`,
        t24: `Betaalmethoden`,
        t25: `Kies uit de volgende betaalmethoden voor uw account.`,
        t26: `Hoe wilt u uw licentie beheren:`,
        t266: `Creditcard`,
        t267: `SEPA Direct Debit`,
        t268: `ontbrekende tabel`,
        t27: `Soorten notificaties`,
        t28: `Selecteer en configureer uw notificatievoorkeuren. Wanneer worden de notificaties verzonden?`,
        t29: `Soorten notificaties`,
        t30: `E-mailnotificaties`,
        t31: `Dagelijkse herinneringen`,
        t32: `Speciale evenementnotificaties:`,
        t33: `Privéberichtenotificaties:`,
        t34: `Ontvangen facturen`,
        t340: `Aythen Lite Premium`,
        t341: `Upgraden naar Premium`,
        t342: `Lorem ipsum dolor et epsum holend.`,
        t343: `Lorem ipsum dolor et epsum holend et erem super tupper dolem herem
    superssupostom hellom super start.`,
        t344: `Lorem ipsum dolor et teresum`,
        t345: `Lorem ipsum dolor et teresum`,
        t346: `Gratis`,
        t347: `Altijd`,
        t350: `Toevoegen`,
        t35: `Hier vindt u uw historie tussen ontvangen en verzonden.`,
        t36: `Verzonden facturen`,
        t360: `Ontvangen facturen`,
        t361: `van`,
        t37: `Maand`,
        t38: `Jaar`,
        t39: `Nummer`,
        t40: `Betaalmethode`,
        t41: `Status`,
        t42: `Totaal (BTW inbegrepen)`,
        t43: `Downloaden`,
        t44: `Account verwijderen`,
        t45: `Weet u zeker dat u uw account wilt verwijderen? Houd er rekening mee dat deze actie niet ongedaan kan worden gemaakt en uw account zal worden uitgeschakeld binnen 48 uur als u bevestigt.`,
        t46: `Verwijderen`,

        x1: `Accountnaam bewerken`,
        x2: `Dit is een zakelijk account`,
        x3: `Bedrijfsnaam`,
        x4: `Straatadres`,
        x5: `Straatadres 2`,
        x6: `Postcode`,
        x7: `Stad`,
        x8: `Land`,
        x9: `Regio`,
        x10: `Wijzigingen bevestigen`,
    },
    contracts: {
        t1: `Organisatie`,
        t2: `Accountcontracten`,
        t3: `Naam`,
        t4: `Status`,
        t5: `Eindig op`,
        t6: `Downloaden`,
        t7: `Organiseer hier uw zakelijke overeenkomsten.`,
        t8: `Wat zijn de voordelen?`,
        t0: 'Contract toevoegen',
        popup: {
            t0: `Geopend op`,
            t1: `Status`,
            t2: `Verzenden met melding`,
            t3: `Melding verwijderen`,
            t4: `Eenmalig verzenden`,
            t5: `Prioriteit verzenden`,
            t6: `Markeer ondertekend`,
            t7: `Om te ondertekenen`,
            t8: `Om te verwijderen moet u Verwijderen toevoegen`,
            t9: `Ondertekenen`,
            t10: `Annuleren`,
            t11: `Verwijderen`
        }
    }
}

export default settings