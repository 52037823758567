import { createSlice } from '@reduxjs/toolkit';

import {
  deleteNavsERP,
  addNavsERP,
  fetchNavsERP,

  addDashboardsERP,
  deleteDashboardsERP,
  fetchDashboardsERP,

  fetchRepoOwner,
  fetchRepoAll,
  deleteRepo,
  addRepo,

  addDocumentsERP,
  deleteDocumentsERP,
  fetchDocumentsERP,

  addEndpointsERP,
  deleteEndpointsERP,
  fetchEndpointsERP,

  addElement,

  addERP,
  deleteERP,
} from '../actions/erp'



const initialEndpoint = {
  type: 'post',
  title: 'lorem ipsum',
  description: 'lorem ipsum',
  body: {},
  headers: {},
  auth: {},
  params: {},
  testing: [],
}

const initialDocumentation = {
  type: 'post',
  title: 'lorem ipsum',
  description: 'lorem ipsum',
  endpoint: {},
  history: [],
  date: new Date,
  ownerBy: null,
  updatedBy: null
}


const initialDashboard = [{
  title: 'lorem',
  description: 'lorem ipsum',
  icon: '',
  date: new Date(),
  components: [{
    endpoint: '',
    visibility: true,
    data: {
      type: 'string',
      content: 'text'
    }
  }]
}]

const initialLog = {
  id: null,
  username: 'info@aythen.com'
}

const initialHistory = {

  title: 'title',
  date: new Date(),
  content: '',
  log: {},
  endpoint: initialEndpoint
}


const initialServer = {
  title: 'lorem',
  description: 'lorem',
  origin: '*',
  port: '400',
  date: new Date()
}

const erpSlice = createSlice({
  name: 'erp',
  initialState: {
    id: null,
    server: initialServer,
    endpoint: initialEndpoint,

    navs: [],

    dashboard: null,
    dashboards: [],
    totalDashboards: 0,


    prevIsLoad: false,
    prevEndpointsRepos: [],
    prevDocumentsRepos: [],
    prevElementsRepos: [],
    prevInfoRepos: {
      documents: 0,
      endpoints: 0,
      elements: 0,
    },

    reposOwner: [],
    reposOwnerInfo: 0,

    reposAll: [],
    reposAllInfo: 0,

    endpoints: [],
    totalEndpoints: 0,

    documents: [],
    totalDocuments: 0,

    history: [],

    prevComponents: [],
    prevBoards: []
  },
  reducers: {
    setERP: (state, action) => {
    },

    setPrevIsLoad: (state, action) => {

      state.prevIsLoad = action.payload
    },
    setChunkRepo: (state, action) => {
      if (action.payload.type == 'info') {
        state.prevInfoRepos = action.payload.data
      } else if (action.payload.type == 'endpoints') {
        state.prevEndpointsRepos.push(action.payload.data)
      } else if (action.payload.type == 'documents') {
        state.prevDocumentsRepos.push(action.payload.data)
      } else if (action.payload.type == 'elements') {
        state.prevElementsRepos.push(action.payload.data)
      }
    },

    setChunkBoard: (state, action) => {
      state.prevComponents.push(action.payload)
    },


    updateDocument: (state, action) => {
      const indexToUpdate = state.documents.findIndex((doc) => doc._id == action.payload.id)

      if (indexToUpdate > -1) {
        state.documents[indexToUpdate].content = action.payload.content
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteNavsERP.fulfilled, (state, action) => {
        const indexToDelete = state.navs.findIndex(
          (nav) => nav._id === action.payload,
        )

        if (indexToDelete !== -1) {
          state.navs = [
            ...state.navs.slice(0, indexToDelete),
            ...state.navs.slice(indexToDelete + 1),
          ]
        }
      })
      .addCase(addNavsERP.fulfilled, (state, action) => {
        const indexToAdd = state.navs.findIndex(
          (nav) => nav._id === action.payload._id,
        )

        if (indexToAdd > -1) {
          state.navs[indexToAdd] = action.payload
        } else {
          state.navs.push(action.payload)
        }
      })
      .addCase(fetchNavsERP.fulfilled, (state, action) => {
        state.navs = action.payload.navs
      })
      // ----------
      .addCase(deleteDashboardsERP.fulfilled, (state, action) => {
        const indexToDelete = state.dashboards.findIndex(
          (dash) => dash._id === action.payload,
        )
        
        if (indexToDelete !== -1) {
          state.dashboards = [
            ...state.dashboards.slice(0, indexToDelete),
            ...state.dashboards.slice(indexToDelete + 1),
          ]
        }

        const indexToNav = state.navs.findIndex(
          (nav) => nav._id === action.payload,
        )

        if(indexToNav !== -1){
          state.navs = [
            ...state.navs.slice(0, indexToNav),
            ...state.navs.slice(indexToNav + 1),
          ] 
        }
      })
      .addCase(addDashboardsERP.fulfilled, (state, action) => {
        const indexToUpdated = state.dashboards.findIndex(board => board._id == action.payload._id)
        
        if(indexToUpdated > -1){
          state.dashboards[indexToUpdated] = action.payload
        }else{
          state.dashboards.push(action.payload)
          state.totalDashboards++
        }
      })
      .addCase(fetchDashboardsERP.fulfilled, (state, action) => {
        if(action.payload.dashboards){
          state.dashboards = action.payload.dashboards
          state.totalDashboards = action.payload.total
        }
      })
      .addCase(addDocumentsERP.fulfilled, (state, action) => {
        const indexToAdd = state.documents.findIndex(
          (dash) => dash._id === action.payload._id,
        )

        if (indexToAdd > -1) {
          state.documents[indexToAdd] = action.payload
        } else {
          state.documents.unshift(action.payload)
          state.totalDocuments++
        }

      })
      .addCase(deleteDocumentsERP.fulfilled, (state, action) => {
        for (let i = 0; i < action.payload.length; i++) {
          const id = action.payload[i]

          const indexToDelete = state.documents.findIndex(
            (dash) => dash._id === id,
          )

          if (indexToDelete !== -1) {
            state.documents = [
              ...state.documents.slice(0, indexToDelete),
              ...state.documents.slice(indexToDelete + 1),
            ]
          }
        }

      })
      .addCase(fetchDocumentsERP.fulfilled, (state, action) => {
        state.documents = action.payload.documents
        state.totalDocuments = action.payload.total
      })
      // ----------
      .addCase(addEndpointsERP.fulfilled, (state, action) => {
        state.endpoints.unshift(action.payload)
        state.totalEndpoints++
      })
      .addCase(deleteEndpointsERP.fulfilled, (state, action) => {
        for (let i = 0; i < action.payload.length; i++) {
          const id = action.payload[i]

          const indexToDelete = state.endpoints.findIndex(
            (dash) => dash._id === id,
          )

          if (indexToDelete !== -1) {
            state.endpoints = [
              ...state.endpoints.slice(0, indexToDelete),
              ...state.endpoints.slice(indexToDelete + 1),
            ]
          }

          // ------------------------------
          const indexToNav = state.navs.findIndex(
            (nav) => nav._id === id,
          )
  
          if(indexToNav !== -1){
            state.navs = [
              ...state.navs.slice(0, indexToNav),
              ...state.navs.slice(indexToNav + 1),
            ] 
          }
        }



      })
      .addCase(fetchEndpointsERP.fulfilled, (state, action) => {
        state.endpoints = action.payload.endpoints
        state.totalEndpoints = action.payload.total
      })
      // ----------
      .addCase(addERP.fulfilled, (state, action) => {        
        if(action.payload.endpoints) {
          state.endpoints.push(action.payload.endpoints)
          ++state.totalEndpoints
        }

        if(action.payload.documents) {
          state.dashboards.push(action.payload.documents)
          ++state.totalDashboards
        }

        if(action.payload.dashboards) {
          state.dashboards.push(action.payload.dashboards)
          ++state.totalDocuments
        }
      })
      .addCase(deleteERP.fulfilled, (state, action) => {
      })
      .addCase(fetchRepoAll.fulfilled, (state, action) => {
        state.reposAll = action.payload.repos
        state.reposAllInfo = action.payload.total
      })
      .addCase(fetchRepoOwner.fulfilled, (state, action) => {
        state.reposOwner = action.payload.repos
        state.reposOwnerInfo = action.payload.total
      })
      .addCase(deleteRepo.fulfilled, (state, action) => {
        const id = action.payload
        const indexToDelete = state.reposOwner.findIndex(
          (repo) => repo._id === id,
        )

        if (indexToDelete !== -1) {
          state.reposOwner = [
            ...state.reposOwner.slice(0, indexToDelete),
            ...state.reposOwner.slice(indexToDelete + 1),
          ]
        }
      })
      .addCase(addRepo.fulfilled, (state, action) => {
        const indexToUpdate = state.reposOwner.findIndex(
          (repo) => repo._id === action.payload._id,
        )

        if (indexToUpdate !== -1) {
          state.reposOwner[indexToUpdate] = action.payload
        }
      })

      .addCase(addElement.fulfilled, (state, action) => {

        const indexToUpdate = state.reposOwner.findIndex(
          (repo) => repo._id === action.payload.id,
        )

        if (indexToUpdate !== -1) {
          state.reposOwner[indexToUpdate].elements.push(action.payload.element)
        }
      })
  },
});

export const {
  setERP,

  setChunkRepo,
  setChunkBoard,

  setPrevIsLoad,

  updateDocument
} = erpSlice.actions;

export default erpSlice.reducer;












