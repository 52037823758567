// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container_Cgmah {
    width: 100%;

    .ring1_P5wIJ{

    }
    .ring2_MUZYx{

    }
    .mg-front_x9Jsq{

    }
    .mg-back_UO4_h{

    }

    main {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    section {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 50vh;
        padding: 60px 0px;
    }

    .s2_yp88I {
        background: linear-gradient(to top, #16181e, #1d0042);
    }

    svg {
        position: relative;
        margin-top: -9vw;
    }

    h1 {
        font-size: clamp(3vw, 8.5vw, 99px);
        text-align: center;
    }

    s1 h1 {
        font-weight: 800;
    }

    .pink_yhYi5 {
        color: rgb(239, 208, 255);
        display: block;
    }

    .radial_y5fxg {
        -webkit-text-fill-color: transparent;
        background-image: radial-gradient(circle at -20% 0, #2b0aff, #e93abf 31%, #f658a8 48%, #febf72);
        -webkit-background-clip: text;
        background-clip: text;
        display: block;
    }

}`, "",{"version":3,"sources":["webpack://./src/views/web/components/Cloud/Footer.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;;IAEX;;IAEA;IACA;;IAEA;IACA;;IAEA;IACA;;IAEA;;IAEA;QACI,aAAa;QACb,uBAAuB;QACvB,sBAAsB;IAC1B;;IAEA;QACI,aAAa;QACb,mBAAmB;QACnB,uBAAuB;QACvB,WAAW;QACX,gBAAgB;QAChB,iBAAiB;IACrB;;IAEA;QACI,qDAAqD;IACzD;;IAEA;QACI,kBAAkB;QAClB,gBAAgB;IACpB;;IAEA;QACI,kCAAkC;QAClC,kBAAkB;IACtB;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,yBAAyB;QACzB,cAAc;IAClB;;IAEA;QACI,oCAAoC;QACpC,+FAA+F;QAC/F,6BAA6B;QAC7B,qBAAqB;QACrB,cAAc;IAClB;;AAEJ","sourcesContent":[".container {\n    width: 100%;\n\n    .ring1{\n\n    }\n    .ring2{\n\n    }\n    .mg-front{\n\n    }\n    .mg-back{\n\n    }\n\n    main {\n        display: flex;\n        justify-content: center;\n        flex-direction: column;\n    }\n\n    section {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        width: 100%;\n        min-height: 50vh;\n        padding: 60px 0px;\n    }\n\n    .s2 {\n        background: linear-gradient(to top, #16181e, #1d0042);\n    }\n\n    svg {\n        position: relative;\n        margin-top: -9vw;\n    }\n\n    h1 {\n        font-size: clamp(3vw, 8.5vw, 99px);\n        text-align: center;\n    }\n\n    s1 h1 {\n        font-weight: 800;\n    }\n\n    .pink {\n        color: rgb(239, 208, 255);\n        display: block;\n    }\n\n    .radial {\n        -webkit-text-fill-color: transparent;\n        background-image: radial-gradient(circle at -20% 0, #2b0aff, #e93abf 31%, #f658a8 48%, #febf72);\n        -webkit-background-clip: text;\n        background-clip: text;\n        display: block;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container_Cgmah`,
	"ring1": `ring1_P5wIJ`,
	"ring2": `ring2_MUZYx`,
	"mg-front": `mg-front_x9Jsq`,
	"mg-back": `mg-back_UO4_h`,
	"s2": `s2_yp88I`,
	"pink": `pink_yhYi5`,
	"radial": `radial_y5fxg`
};
export default ___CSS_LOADER_EXPORT___;
