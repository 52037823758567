const VERSION = process.env.REACT_APP_VERSION;

const Icon1 = require(`@app/assets/steps/1.svg`).default;
const Icon2 = require(`@app/assets/steps/2.svg`).default;
const Icon3 = require(`@app/assets/steps/3.svg`).default;
const Icon4 = require(`@app/assets/steps/4.svg`).default;
const Icon5 = require(`@app/assets/steps/5.svg`).default;
const Icon6 = require(`@app/assets/steps/6.svg`).default;
const Icon7 = require(`@app/assets/steps/7.svg`).default;
const Icon8 = require(`@app/assets/steps/8.svg`).default;
const Icon9 = require(`@app/assets/steps/9.svg`).default;
const Icon10 = require(`@app/assets/steps/10.svg`).default;
const Icon11 = require(`@app/assets/steps/11.svg`).default;
const Icon12 = require(`@app/assets/steps/12.svg`).default;
const Icon13 = require(`@app/assets/steps/13.svg`).default;
const Icon14 = require(`@app/assets/steps/14.svg`).default;
const Icon15 = require(`@app/assets/steps/15.svg`).default;
const Icon16 = require(`@app/assets/steps/16.svg`).default;
const Icon17 = require(`@app/assets/steps/17.svg`).default;
const Icon18 = require(`@app/assets/steps/18.svg`).default;
const Icon19 = require(`@app/assets/steps/19.svg`).default;
const Icon20 = require(`@app/assets/steps/20.svg`).default;
const Icon21 = require(`@app/assets/steps/21.svg`).default;
const Icon22 = require(`@app/assets/steps/22.svg`).default;
const Icon23 = require(`@app/assets/steps/23.svg`).default;
const Icon24 = require(`@app/assets/steps/24.svg`).default;
const Icon25 = require(`@app/assets/steps/25.svg`).default;
const Icon26 = require(`@app/assets/steps/26.svg`).default;
const Icon28 = require(`@app/assets/steps/28.svg`).default;
const Icon29 = require(`@app/assets/steps/29.svg`).default;
const Icon30 = require(`@app/assets/steps/30.svg`).default;
const Icon31 = require(`@app/assets/steps/31.svg`).default;
const Icon32 = require(`@app/assets/steps/32.svg`).default;
const Icon33 = require(`@app/assets/steps/33.svg`).default;

const renderHelp = ({title, text, icon}) => {
    return (`<div class="steps-intro">
<img src=${icon} />
<div class="info">
<b>
${title} 
</b>
<p>
${text} 
</p>
</div>
<div class="buttons">
    <button onclick="introJs().nextStep()">
        Anterior
    </button>
    <button class="next" onclick="introJs().previousStep()">
        Siguiente
    </button >
</div>
</div>`)
}




export const home = [{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="0-1"]`,
    intro: renderHelp({
        icon: Icon1,
        title: `Your first access`,
        text: `Here you can manage your account data as well as access your license, visual preferences, and log out of your session.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="0-2"]`,
    intro: renderHelp({
        icon: Icon2,
        title: `Your first data`,
        text: `Let's start with something simple! In "Edit account" you will fill in and save your personal data as well as change the theme.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="0-3"]`,
    intro: renderHelp({
        icon: Icon3,
        title: `Your management`,
        text: `You have everything you need to advance your project from any perspective. Your start, your data, documents, tasks, invoices, and contracts.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-0"]`,
    intro: renderHelp({
        icon: Icon4,
        title: `Your start > Homepage`,
        text: `You will find a general overview. First, of your profile, organizations you manage, access to your balance, your accesses, and your domains.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-1"]`,
    intro: renderHelp({
        icon: Icon5,
        title: `Your summary > Design`,
        text: `From your projects and domains, you'll need designs. In the Add-ons you can create whatever you want. This way you will observe the resource consumption involved.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-2"]`,
    intro: renderHelp({
        icon: Icon6,
        title: `Your summary > Data`,
        text: `From your projects and designs, you'll have data. Access your vectorized databases and save your most interesting graphs.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-3"]`,
    intro: renderHelp({
        icon: Icon7,
        title: `Your summary > Status`,
        text: `Refresh your memory with the different features available to you, the status of your connection, and access to support.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="2-0"]`,
    intro: renderHelp({
        icon: Icon8,
        title: `Aythen DB > your analyses`,
        text: `Create and sync all the data to create dimensions and strategies. This way you will be able to make informed decisions thanks to the interactions.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="2-0"]`,
    intro: renderHelp({
        icon: Icon9,
        title: `Your documentation`,
        text: `Aythen supports any file format. Drag and drop your desired files. Share them and categorize their relevance.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="3-0"]`,
    intro: renderHelp({
        icon: Icon10,
        title: `Kan Ban > Your tasks`,
        text: `You'll need to organize your tasks and your team. Add tickets, task lists, priorities, statuses, and check the overall progress status.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="4-0"]`,
    intro: renderHelp({
        icon: Icon11,
        title: `Electronic invoicing (OCR)`,
        text: `Fill in your business details and manage your balance, schedule payment reminders, and configure your license limits.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icon: Icon12,
        title: `Electronic signature (Audit trail)`,
        text: `Manage all your agreements through the compilation of your contracts. Sign, download, or delete those sensitive documents.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icon: Icon13,
        title: `Your first project`,
        text: `With all the platform overview. Create a new add-on (public or private). Select settings and link your domain. Save and open it:`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icon: Icon14,
        title: `Your first project`,
        text: `With all the platform overview. Create a new add-on (public or private). Select settings and link your domain. Save and open it:`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-1"]`,
    intro: renderHelp({
        icon: Icon15,
        title: `Your creative space`,
        text: `Here you'll be able to build your design flows. Create and connect to create correlated screens. Want to know how to start?`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-2"]`,
    intro: renderHelp({
        icon: Icon16,
        title: `Create and save`,
        text: `Your entire flow is based on smart nodes. They will connect your screens and synchronize your data. Create the first one and save your session.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-3"]`,
    intro: renderHelp({
        icon: Icon17,
        title: `Organize your view`,
        text: `Are you a grid person? Dot person? Like to have everything organized? Want to know where each node is at all times? These 3 buttons will help you.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-4"]`,
    intro: renderHelp({
        icon: Icon18,
        title: `Control your view`,
        text: `Zoom in to refine details or to gain perspective on your entire creation. This will consume certain resources depending on its usage.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-5"]`,
    intro: renderHelp({
        icon: Icon19,
        title: `Control your creation`,
        text: `Export or import your designs and analyze where users are most active through heatmaps. This way you'll be able to correlate with criteria.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-6"]`,
    intro: renderHelp({
        icon: Icon20,
        title: `Add and sync`,
        text: `Add content to your design, upload it to GitHub automatically, or share it with third parties. Everything you need to create endlessly.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-7"]`,
    intro: renderHelp({
        icon: Icon21,
        title: `Do you have your first flow?`,
        text: `It's possible not everything is to the consumer's liking. Therefore, you can double-click or "Alt" + click on the node you need to refine.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-8"]`,
    intro: renderHelp({
        icon: Icon22,
        title: `Your individual creative space`,
        text: `Here you'll be able to focus on your design construction. Create with code and connect new or existing data to create correlated screens.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-9"]`,
    intro: renderHelp({
        icon: Icon23,
        title: `Access your code`,
        text: `Modify and save in real-time. With a mirror effect, you'll be able to edit and see the design to make any modifications to your liking.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-10"]`,
    intro: renderHelp({
        icon: Icon24,
        title: `Sync your data`,
        text: `You'll find different methods to save and relate your content to your databases. If you have doubts, check our commands.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-11"]`,
    intro: renderHelp({
        icon: Icon25,
        title: `Quick accesses`,
        text: `Go back with all the nodes, zoom in this individual space, or click on the right to check its view from the browser.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-12"]`,
    intro: renderHelp({
        icon: Icon26,
        title: `Work efficiently`,
        text: `Make all your modifications at once with the double internal screen to have quick access to all available features.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-0"]`,
    intro: renderHelp({
        icon: Icon28,
        title: `Your first analysis`,
        text: `Here you'll be able to build your data flows. Both external and those synchronized with the design. Create interactions and make informed decisions.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-1"]`,
    intro: renderHelp({
        icon: Icon29,
        title: `Locate your data`,
        text: `Search, select your nodes with tabs, save your version, download, and organize your flow through different functionalities.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-2"]`,
    intro: renderHelp({
        icon: Icon30,
        title: `Interact with your data`,
        text: `To make informed decisions you'll need to ask questions, create charts, or matrix relationships through Python / JavaScript.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-3"]`,
    intro: renderHelp({
        icon: Icon31,
        title: `Manage your node`,
        text: `Each smart node is configured through different ways. Its flexibility of any data and format is for any purpose.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-4"]`,
    intro: renderHelp({
        icon: Icon32,
        title: `Pay attention to all the details`,
        text: `You'll be able to see and modify the name. This way you'll always be able to search for it to find your data. To its right, you'll have when it was uploaded and its size.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-5"]`,
    intro: renderHelp({
        icon: Icon33,
        title: `Configure your data`,
        text: `Back up with versions, incorporate third-party data, generate a question, or expand the node to see all the data.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-6"]`,
    intro: renderHelp({
        icon: Icon1,
        title: `Preview your data`,
        text: `In the node window, you'll be able to see all the data you have in your space to quickly understand what it is and what it can relate to.`
    })
}]

export default home
