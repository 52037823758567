const vector = {
    t1: `Создайте новый вектор для вашего проекта`,
    t2: `Импортируйте, сохраните, работайте, анализируйте и делайте выводы из любой структуры данных. 
Храните и манипулируйте информацией с полной свободой через многомерные пространства. `,
    t3: `Новый вектор`,
    t4: `Векторы`,
    t5: `Вектор`,
    t6: `Размерность`,
    t7: `Размер`,
    t9: `Создано`,
    t10: `Матрица`,
    t11: `Векторные базы данных позволяют создавать уникальные experiencia, 
    которые стимулируются векторным поиском.  
Ускоряют разработку приложений искусственного интеллекта.`,
    t12: `Новая Матрица`,
    t13: `Документация быстрого старта`,
    node: {
        data: {
            title: `Добавьте данные в ваш отчет`,
            text: `Однажды загруженные, ваши данные появятся в`,
            label: `Поля`,
            excel: `Импортируйте данные из Excel`,
            sql: `Импортируйте данные из SQL`,
            blank: `Вставьте пустые данные`,
            export: `Экспортируйте примерные данные`,
            info: `Получите данные из другого источника`,
        }
    },
    dashboard: {
        t1: `Помните, что всегда сможете изменять и собирать графики, которые вы хотите подчеркнуть 
как избранные, начиная с информации, которую вы решили проанализировать в векторах.`,
        t2: `Узнайте больше`,
    }
}

export default vector