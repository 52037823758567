import React, { useState, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux"

import i18n from '@src/i18n';

import styles from "./index.module.css"


const VERSION = process.env.REACT_APP_VERSION;


const flagDE = (await import(`@app/pages/shared/assets/flags/de.png`)).default;
const flagEN = (await import(`@app/pages/shared/assets/flags/en.png`)).default;
const flagES = (await import(`@app/pages/shared/assets/flags/es.png`)).default;
const flagFR = (await import(`@app/pages/shared/assets/flags/fr.png`)).default;
const flagIT = (await import(`@app/pages/shared/assets/flags/it.png`)).default;
const flagND = (await import(`@app/pages/shared/assets/flags/nd.png`)).default;
const flagPL = (await import(`@app/pages/shared/assets/flags/pl.png`)).default;
const flagPT = (await import(`@app/pages/shared/assets/flags/pt.png`)).default;
const flagRU = (await import(`@app/pages/shared/assets/flags/ru.png`)).default;
const flagSZ = (await import(`@app/pages/shared/assets/flags/sz.png`)).default;



import { newsletter } from "../../../app/v1-1/actions/iam"

const Form = ({ isShowModal, setIsShowModal }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [progress, setProgress] = useState(0)
  const [step, setStep] = useState(0)
  const [title, setTitle] = useState("")

  const [state, setState] = useState({
    flag: "",
    firstName: "",
    secondName: "",
    email: "",
    phone: "",
    why: "",
    team: "",
    student: "",
    work: "",
    size: "",
    building: "",
    hiring: "",
  })


  const onButtonPrev = () => {
    if (step == 0) return setIsShowModal(false)
    setStep(step - 1)
  }

  const onButtonNext = () => {
    if (step == 0) {
      if (!state.flag) return false
    } else if (step == 1) {
      if (
        state.firstName.length <= 3 ||
        state.secondName.length <= 3 ||
        state.email.length <= 3
      )
        return false
    } else if (step == 2) {
      if (!state.why) return false
    } else if (step == 3) {
      if (!state.team) return false
    } else if (step == 4) {
      if (!state.student) return false
    } else if (step == 5) {
      if (!state.work) return false
    } else if (step == 6) {
      if (!state.size) return false
    } else if (step == 7) {
      if (!state.building) return false
    } else if (step == 8) {
      if (!state.hiring) return false

      dispatch(
        newsletter(state),
      )

      setIsShowModal(false)
      setStep(0)
      return false
    }

    setStep(step + 1)
  }

  useEffect(() => {
    const totalSteps = 8
    const progressPercentage = (step / totalSteps) * 100
    setProgress(`${progressPercentage}%`)
  }, [step])

 const overlayRef = useRef(null)
 const modalRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!(overlayRef.current && !overlayRef.current.contains(event.target))) {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          setIsShowModal(false)
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isShowModal]); 

  return (
    <div ref={overlayRef} className={styles.overlay} >
      <div ref={modalRef} className={styles.modal}>
        <div className={styles.header}>
          <div className={styles.title}>
            <b>{t("form.title.text0")}: {step + 1} {t("form.title.text1")} 9</b>
            <span>{title}</span>
          </div>
          <div className={styles.bar}>
            <div style={{ width: progress }} />
          </div>
        </div>
        <div className={styles.content}>
          {step == 0 ? (
            <FormStep0 state={state} setState={setState} setStep={setStep} setTitle={setTitle}/>
          ) : step == 1 ? (
            <FormStep1 state={state} setState={setState} setStep={setStep} setTitle={setTitle} />
          ) : step == 2 ? (
            <FormStep2 state={state} setState={setState} setStep={setStep} setTitle={setTitle}/>
          ) : step == 3 ? (
            <FormStep3 state={state} setState={setState} setStep={setStep} setTitle={setTitle}/>
          ) : step == 4 ? (
            <FormStep4 state={state} setState={setState} setStep={setStep} setTitle={setTitle}/>
          ) : step == 5 ? (
            <FormStep5 state={state} setState={setState} setStep={setStep} setTitle={setTitle}/>
          ) : step == 6 ? (
            <FormStep6 state={state} setState={setState} setStep={setStep} setTitle={setTitle}/>
          ) : step == 7 ? (
            <FormStep7 state={state} setState={setState} setStep={setStep} setTitle={setTitle}/>
          ) : (
            step == 8 && <FormStep8 state={state} setState={setState} setStep={setStep} setTitle={setTitle}/>
          )}
        </div>
        <div className={styles.footer}>
          <div className={styles.checkbox}>
            <input type="checkbox" />
            <span>{t("form.footer.text")}</span>
          </div>
          <div className={styles.buttons}>
            <button className={styles.previous} onClick={() => onButtonPrev()}>
            {t("form.footer.button0")}
            </button>
            <button className={styles.next} onClick={() => onButtonNext()}>
            {t("form.footer.button1")}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Form

const FormStep0 = ({ state, setState, setStep, setTitle }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()


  const flags = [
    {
      value: "en",
      text: t("form.flags.en"),
      flag: flagEN,
    },
    {
      value: "es",
      text: t("form.flags.es"),
      flag: flagES,
    },
    {
      value: "fr",
      text: t("form.flags.fr"),
      flag: flagFR,
    },
    {
      value: "de",
      text: t("form.flags.de"),
      flag: flagDE,
    },
    {
      value: "it",
      text: t("form.flags.it"),
      flag: flagIT,
    },
    {
      value: "ru",
      text: t("form.flags.ru"),
      flag: flagRU,
    },
    {
      value: "pt",
      text: t("form.flags.pt"),
      flag: flagPT,
    },
    {
      value: "nl",
      text: t("form.flags.nl"),
      flag: flagND,
    },
    {
      value: "sv",
      text: t("form.flags.sv"),
      flag: flagSZ,
    },
  ]


  const selectFlag = (value) => {
    i18n.changeLanguage(value);

    navigate(`/${value}`)
    setState((prev) => ({ ...prev, flag: value }))
  }


  useEffect(() => {
    setTitle(t("form.title.step0"))
  }, [])

  return (
    <div className={styles.form}>
      <h3 className={styles.title}>{t("form.step0.t0")}</h3>
      <p className={styles.text}>{t("form.step0.t1")}</p>
      <ul className={styles.flags}>
        {flags.map((flag, index) => (
          <li
            key={index}
            className={`${state.flag == flag.value ? styles.active : ""}`}
            onClick={() => selectFlag(flag.value)}>
            <img src={flag.flag} />
            <span>
            {flag.text}
            </span>
          </li>
        ))}
      </ul>
      <span className={styles.small}>{t("form.step0.t2")}</span>
    </div>
  )
}

const FormStep1 = ({ state, setState, setStep, setTitle }) => {
  const { t } = useTranslation()


  useEffect(() => {
    setTitle(t("form.title.step1"))
  }, [])


  return (
    <div className={styles.form}>
      <h3 className={styles.title}>{t("form.step1.t0")}</h3>
      <p className={styles.text}>{t("form.step1.t1")}</p>
      <div className={styles.input}>
        <label>{t("form.step1.t2")}*</label>
        <input
          spellCheck={false}
          value={state.firstName}
          onChange={(e) => setState((prev) => ({ ...prev, firstName: e.target.value }))}
        />
      </div>
      <div className={styles.input}>
        <label>{t("form.step1.t3")}*</label>
        <input
          spellCheck={false}
          value={state.secondName}
          onChange={(e) => setState((prev) => ({ ...prev, secondName: e.target.value }))}
        />
      </div>
      <div className={styles.input}>
        <label>{t("form.step1.t5")}*</label>
        <input
          spellCheck={false}
          value={state.email}
          onChange={(e) => setState((prev) => ({ ...prev, email: e.target.value }))}
        />
      </div>
      <div className={styles.input}>
        <label>{t("form.step1.t4")}</label>
        <input
          spellCheck={false}
          value={state.phone}
          onChange={(e) => setState((prev) => ({ ...prev, phone: e.target.value }))}
        />
      </div>
    </div>
  )
}

const FormStep2 = ({ state, setState, setStep, setTitle  }) => {
  const { t } = useTranslation()

  useEffect(() => {
    setTitle(t("form.title.step2"))
  }, [])

  return (
    <div className={styles.form}>
      <h3 className={styles.title}>{t("form.step2.t0")}</h3>
      <p className={styles.text}>{t("form.step2.t1")}</p>
      <ul className={styles.list}>
        <li
          className={`${state.why == "company" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, why: "company" }))}>
          {/* prettier-ignore */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 4h1.5L9 16m0 0h8m-8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-8.5-3h9.25L19 7H7.312" /> </svg>
          {t("form.step2.t2")}
        </li>
        <li
          className={`${state.why == "myself" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, why: "myself" }))}>
          {/* prettier-ignore */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0 0a8.949 8.949 0 0 0 4.951-1.488A3.987 3.987 0 0 0 13 16h-2a3.987 3.987 0 0 0-3.951 3.512A8.948 8.948 0 0 0 12 21Zm3-11a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" /> </svg>
          {t("form.step2.t3")}
        </li>
        <li
          className={`${state.why == "clients" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, why: "clients" }))}>
          {/* prettier-ignore */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7H5a2 2 0 0 0-2 2v4m5-6h8M8 7V5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m0 0h3a2 2 0 0 1 2 2v4m0 0v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-6m18 0s-4 2-9 2-9-2-9-2m9-2h.01" /> </svg>
          {t("form.step2.t4")}
        </li>
      </ul>
    </div>
  )
}

const FormStep3 = ({ state, setState, setStep, setTitle }) => {
  const { t } = useTranslation()

  useEffect(() => {
    setTitle(t("form.title.step3"))
  }, [])
  return (
    <div className={styles.form}>
      <h3 className={styles.title}>{t("form.step3.t0")}</h3>
      <ul className={`${styles.list} ${styles.list4}`}>
        <li
          className={`${state.team == "startup" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, team: "startup" }))}>
          {/* prettier-ignore */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" strokeWidth="2" d="M11.083 5.104c.35-.8 1.485-.8 1.834 0l1.752 4.022a1 1 0 0 0 .84.597l4.463.342c.9.069 1.255 1.2.556 1.771l-3.33 2.723a1 1 0 0 0-.337 1.016l1.03 4.119c.214.858-.71 1.552-1.474 1.106l-3.913-2.281a1 1 0 0 0-1.008 0L7.583 20.8c-.764.446-1.688-.248-1.474-1.106l1.03-4.119A1 1 0 0 0 6.8 14.56l-3.33-2.723c-.698-.571-.342-1.702.557-1.771l4.462-.342a1 1 0 0 0 .84-.597l1.753-4.022Z" /> </svg>
          {t("form.step3.t1")}
        </li>
        <li
          className={`${state.team == "business" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, team: "business" }))}>
          {/* prettier-ignore */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 10V6a3 3 0 0 1 3-3v0a3 3 0 0 1 3 3v4m3-2 .917 11.923A1 1 0 0 1 17.92 21H6.08a1 1 0 0 1-.997-1.077L6 8h12Z" /> </svg>
          {t("form.step3.t2")}
        </li>
        <li
          className={`${state.team == "company" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, team: "company" }))}>
          {/* prettier-ignore */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h6l2 4m-8-4v8m0-8V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v9h2m8 0H9m4 0h2m4 0h2v-4m0 0h-5m3.5 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm-10 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" /> </svg>
          {t("form.step3.t3")}
        </li>
        <li
          className={`${state.team == "enterprise" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, team: "enterprise" }))}>
          {/* prettier-ignore */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M3 21h18M4 18h16M6 10v8m4-8v8m4-8v8m4-8v8M4 9.5v-.955a1 1 0 0 1 .458-.84l7-4.52a1 1 0 0 1 1.084 0l7 4.52a1 1 0 0 1 .458.84V9.5a.5.5 0 0 1-.5.5h-15a.5.5 0 0 1-.5-.5Z" /> </svg>
          {t("form.step3.t4")}
        </li>
      </ul>
    </div>
  )
}

const FormStep4 = ({ state, setState, setStep, setTitle }) => {
  const { t } = useTranslation()

  useEffect(() => {
    setTitle(t("form.title.step4"))
  }, [])
  return (
    <div className={styles.form}>
      <h3 className={styles.title}>{t("form.step4.t0")}</h3>
      <ul className={`${styles.list} ${styles.list2}`}>
        <li
          className={`${state.student == "yes" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, student: "yes" }))}>
          {/* prettier-ignore */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" /> </svg>
          {t("form.step4.t1")}
        </li>
        <li
          className={`${state.student == "no" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, student: "no" }))}>
          {/* prettier-ignore */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" /> </svg>
          {t("form.step4.t2")}
        </li>
      </ul>
    </div>
  )
}

const FormStep5 = ({ state, setState, setStep, setTitle }) => {
  const { t } = useTranslation()

  useEffect(() => {
    setTitle(t("form.title.step5"))
  }, [])

  return (
    <div className={styles.form}>
      <h3 className={styles.title}>{t("form.step5.t0")}</h3>
      <ul className={`${styles.list} ${styles.list2}`}>
        <li
          className={`${state.work == "freelancer" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, work: "freelancer" }))}>
          {/* prettier-ignore */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M16 19h4a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-2m-2.236-4a3 3 0 1 0 0-4M3 18v-1a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" /> </svg>
          {t("form.step5.t1")}
        </li>
        <li
          className={`${state.work == "agency" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, work: "agency" }))}>
          {/* prettier-ignore */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M4.5 17H4a1 1 0 0 1-1-1 3 3 0 0 1 3-3h1m0-3.05A2.5 2.5 0 1 1 9 5.5M19.5 17h.5a1 1 0 0 0 1-1 3 3 0 0 0-3-3h-1m0-3.05a2.5 2.5 0 1 0-2-4.45m.5 13.5h-7a1 1 0 0 1-1-1 3 3 0 0 1 3-3h3a3 3 0 0 1 3 3 1 1 0 0 1-1 1Zm-1-9.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" /> </svg>
          {t("form.step5.t2")}
        </li>
      </ul>
    </div>
  )
}

const FormStep6 = ({ state, setState, setStep, setTitle }) => {
  const { t } = useTranslation()

  useEffect(() => {
    setTitle(t("form.title.step6"))
  }, [])

  return (
    <div className={styles.form}>
      <h3 className={styles.title}>{t("form.step6.t0")}</h3>
      <ul className={`${styles.list} ${styles.list4}`}>
        <li
          className={`${state.size == "1-5" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, size: "1-5" }))}>
          1-5
        </li>
        <li
          className={`${state.size == "6-10" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, size: "6-10" }))}>
          6-10
        </li>
        <li
          className={`${state.size == "11-50" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, size: "11-50" }))}>
          11-50
        </li>
        <li
          className={`${state.size == "51-200" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, size: "51-200" }))}>
          51-200
        </li>
        <li
          className={`${state.size == "201-500" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, size: "201-500" }))}>
          201-500
        </li>
        <li
          className={`${state.size == "501-2500" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, size: "501-2500" }))}>
          501-2,500
        </li>
        <li
          className={`${state.size == "2501-10000" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, size: "2501-10000" }))}>
          2,501-10,000
        </li>
        <li
          className={`${state.size == "+10001" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, size: "+10001" }))}>
          10,001+
        </li>
      </ul>
    </div>
  )
}

const FormStep7 = ({ state, setState, setStep, setTitle }) => {
  const { t } = useTranslation()

  useEffect(() => {
    setTitle(t("form.title.step7"))
  }, [])

  return (
    <div className={styles.form}>
      <h3 className={styles.title}>{t("form.step7.t0")}</h3>
      <ul className={styles.list}>
        <li
          className={`${state.building == "business" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, building: "business" }))}>
          {/* prettier-ignore */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.079 6.839a3 3 0 0 0-4.255.1M13 20h1.083A3.916 3.916 0 0 0 18 16.083V9A6 6 0 1 0 6 9v7m7 4v-1a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1Zm-7-4v-6H5a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h1Zm12-6h1a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-1v-6Z" /> </svg>
          {t("form.step7.t1")}
        </li>
        <li
          className={`${state.building == "ecommerce" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, building: "ecommerce" }))}>
          {/* prettier-ignore */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M6 14h2m3 0h5M3 7v10a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1Z" /> </svg>
          {t("form.step7.t2")}
        </li>
        <li
          className={`${state.building == "portfolio" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, building: "portfolio" }))}>
          {/* prettier-ignore */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" strokeLinejoin="round" strokeWidth="2" d="M10 12v1h4v-1m4 7H6a1 1 0 0 1-1-1V9h14v9a1 1 0 0 1-1 1ZM4 5h16a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z" /> </svg>
          {t("form.step7.t3")}
        </li>
        <li
          className={`${state.building == "blog" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, building: "blog" }))}>
          {/* prettier-ignore */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 17h6l3 3v-3h2V9h-2M4 4h11v8H9l-3 3v-3H4V4Z" /> </svg>
          {t("form.step7.t4")}
        </li>
        <li
          className={`${state.building == "other" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, building: "other" }))}>
          {/* prettier-ignore */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6.025A7.5 7.5 0 1 0 17.975 14H10V6.025Z"/> <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13.5 3c-.169 0-.334.014-.5.025V11h7.975c.011-.166.025-.331.025-.5A7.5 7.5 0 0 0 13.5 3Z"/> </svg>
          {t("form.step7.t5")}
        </li>
        <li
          className={`${state.building == "other" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, building: "other" }))}>
          {/* prettier-ignore */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.529 9.988a2.502 2.502 0 1 1 5 .191A2.441 2.441 0 0 1 12 12.582V14m-.01 3.008H12M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" /> </svg>
          {t("form.step7.t6")}
        </li>
      </ul>
    </div>
  )
}

const FormStep8 = ({ state, setState, setStep, setTitle }) => {
  const { t } = useTranslation()

  useEffect(() => {
    setTitle(t("form.title.step8"))
  }, [])


  return (
    <div className={styles.form}>
      <h3 className={styles.title}>{t("form.step8.t0")}</h3>
      <ul className={styles.list}>
        <li
          className={`${state.hiring == "yes" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, hiring: "yes" }))}>
          {/* prettier-ignore */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" /> </svg>
          {t("form.step8.t1")}
        </li>
        <li
          className={`${state.hiring == "no" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, hiring: "no" }))}>
          {/* prettier-ignore */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" /> </svg>
          {t("form.step8.t2")}
        </li>
        <li
          className={`${state.hiring == "not yet" ? styles.active : ""}`}
          onClick={() => setState((prev) => ({ ...prev, hiring: "not yet" }))}>
          {/* prettier-ignore */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.529 9.988a2.502 2.502 0 1 1 5 .191A2.441 2.441 0 0 1 12 12.582V14m-.01 3.008H12M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" /> </svg>
          {t("form.step8.t3")}
        </li>
      </ul>
    </div>
  )
}
