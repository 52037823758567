import apiBackend from '@src/apiBackend'
import { createAsyncThunk } from '@reduxjs/toolkit';


export const fetchsDashboard =
  createAsyncThunk('dashboard/fetchsDashboard',
    async (item, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')

        const res = await apiBackend.get(
          `/dashboard`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

        return res.data
      } catch (error) {
        console.log('Error', error)
        if (error.response.status == 400) {
          throw 'Ya existe el usuario'
        }
      }
    }
  );


export const fetchDashboard =
  createAsyncThunk('dashboard/fetchDashboard',
    async (dashboardId, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')

        const res = await apiBackend.get(
          `/dashboard/${dashboardId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

        return res.data
      } catch (error) {
        console.log('Error', error)
        if (error.response.status == 400) {
          throw 'Ya existe el usuario'
        }
      }
    }
  );



export const updateDashboard =
  createAsyncThunk('dashboard/updateDashboard',
    async (dashboard, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const res = await apiBackend.post('/dashboard', {
          dashboard
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        })

        return res.data;
      } catch (error) {
        console.log('Error', error)
        throw error;
      }
    }
  );

export const deleteDashboard =
  createAsyncThunk('dashboard/deleteDashboard',
    async (dashboardId, { dispatch }) => {
      try {

        return user;
      } catch (error) {
        throw error;
      }
    }
  );



export const fetchDashboardData =
  createAsyncThunk('dashboard/fetchDashboardData',
    async (vector, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const res = await apiBackend.post('/dashboard/data', {
          vector: vector
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        })

        return res.data;
      } catch (error) {
        throw error;
      }
    }
  );
