
import React from 'react'


const Icon = () => {
    return (
        <svg width="147" height="147" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M105.691 116.75H40.3088C36.8246 116.75 34 113.944 34 110.482V35.2679C34 31.8062 36.8246 29 40.3088 29H82.75L112 58.0601V110.482C112 113.944 109.175 116.75 105.691 116.75Z" fill="url(#paint0_linear_557_3345)"/>
<g filter="url(#filter0_f_557_3345)">
<rect x="47.7647" y="71.4512" width="51.0441" height="23.9319" fill="var(--color-primary-3)" fillOpacity="0.39"/>
</g>
<path opacity="0.12" d="M100.784 68.5136C100.341 68.2594 99.8382 68.1256 99.3264 68.1256C98.8147 68.1256 98.3119 68.2594 97.8687 68.5136L87.5772 73.5536C87.4941 71.3046 86.5361 69.1754 84.9048 67.6141C83.2734 66.0527 81.096 65.1811 78.8309 65.1826H52.5919C50.2722 65.1826 48.0476 66.0981 46.4073 67.7277C44.7671 69.3573 43.8456 71.5676 43.8456 73.8722V91.2513C43.8456 93.5559 44.7671 95.7661 46.4073 97.3957C48.0476 99.0253 50.2722 99.9408 52.5919 99.9408H78.8309C81.096 99.9423 83.2734 99.0707 84.9048 97.5094C86.5361 95.948 87.4941 93.8188 87.5772 91.5699L97.9562 96.7257C98.3536 96.9281 98.7924 97.0371 99.239 97.0443C99.7848 97.046 100.32 96.8955 100.784 96.6098C101.204 96.3489 101.551 95.986 101.79 95.5552C102.03 95.1244 102.155 94.64 102.154 94.1478V70.9757C102.155 70.4834 102.03 69.999 101.79 69.5683C101.551 69.1375 101.204 68.7745 100.784 68.5136V68.5136ZM81.7463 91.2513C81.7463 92.0195 81.4392 92.7562 80.8924 93.2994C80.3456 93.8426 79.6041 94.1478 78.8309 94.1478H52.5919C51.8187 94.1478 51.0771 93.8426 50.5304 93.2994C49.9836 92.7562 49.6765 92.0195 49.6765 91.2513V73.8722C49.6765 73.104 49.9836 72.3672 50.5304 71.824C51.0771 71.2808 51.8187 70.9757 52.5919 70.9757H78.8309C79.6041 70.9757 80.3456 71.2808 80.8924 71.824C81.4392 72.3672 81.7463 73.104 81.7463 73.8722V91.2513ZM96.3235 89.4554L87.5772 85.1107V80.0128L96.3235 75.668V89.4554Z" fill="white"/>
<path d="M66.2493 79.4678C66.1499 79.1221 66.0102 78.8167 65.8303 78.5516C65.6504 78.2817 65.4302 78.0544 65.1698 77.8698C64.9141 77.6804 64.6205 77.536 64.2891 77.4365C63.9624 77.3371 63.6002 77.2874 63.2025 77.2874C62.4591 77.2874 61.8057 77.472 61.2422 77.8414C60.6835 78.2107 60.2479 78.7481 59.9354 79.4536C59.6229 80.1543 59.4667 81.0113 59.4667 82.0246C59.4667 83.0378 59.6205 83.8996 59.9283 84.6098C60.2361 85.32 60.6717 85.8622 61.2351 86.2362C61.7986 86.6056 62.4638 86.7902 63.2309 86.7902C63.9269 86.7902 64.5211 86.6671 65.0135 86.4209C65.5107 86.17 65.8895 85.8172 66.1499 85.3627C66.415 84.9081 66.5476 84.3707 66.5476 83.7504L67.1726 83.8428H63.4226V81.5274H69.5093V83.3598C69.5093 84.6382 69.2394 85.7367 68.6996 86.6553C68.1598 87.5691 67.4165 88.2746 66.4695 88.7718C65.5225 89.2642 64.4383 89.5104 63.2167 89.5104C61.853 89.5104 60.6551 89.2097 59.6229 88.6084C58.5907 88.0023 57.7858 87.143 57.2081 86.0303C56.6352 84.9128 56.3488 83.5871 56.3488 82.053C56.3488 80.874 56.5192 79.8229 56.8601 78.8996C57.2058 77.9716 57.6887 77.1856 58.309 76.5416C58.9293 75.8977 59.6513 75.4076 60.4752 75.0715C61.299 74.7353 62.1916 74.5672 63.1527 74.5672C63.9766 74.5672 64.7436 74.6879 65.4539 74.9294C66.1641 75.1662 66.7938 75.5023 67.3431 75.9379C67.8971 76.3735 68.3492 76.892 68.6996 77.4933C69.05 78.0899 69.2749 78.7481 69.3743 79.4678H66.2493ZM76.2946 74.7661V89.3115H73.2193V74.7661H76.2946ZM80.2248 89.3115V74.7661H89.8554V77.3016H83.3V80.7675H89.2162V83.303H83.3V89.3115H80.2248Z" fill="white"/>
<mask id="mask0_557_3345"  maskUnits="userSpaceOnUse" x="34" y="29" width="78" height="88">
<path d="M105.691 116.75H40.3088C36.8246 116.75 34 113.944 34 110.482V35.2679C34 31.8062 36.8246 29 40.3088 29H82.75L112 58.0601V110.482C112 113.944 109.175 116.75 105.691 116.75Z" fill="url(#paint1_linear_557_3345)"/>
</mask>
<g mask="url(#mask0_557_3345)">
<ellipse opacity="0.03" cx="29.8419" cy="25.4389" rx="64.0919" ry="61.1117" fill="url(#paint2_linear_557_3345)"/>
<ellipse opacity="0.03" cx="29.8419" cy="25.4386" rx="40.2904" ry="38.3195" fill="url(#paint3_linear_557_3345)"/>
</g>
<g filter="url(#filter1_d_557_3345)">
<path d="M112 58.0601H104.75C92.5997 58.0601 82.75 48.2104 82.75 36.0601V29L112 58.0601Z" fill="url(#paint4_linear_557_3345)"/>
</g>
<defs>
<filter id="filter0_f_557_3345" x="8.76471" y="32.4512" width="129.044" height="101.932" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="19.5" result="effect1_foregroundBlur_557_3345"/>
</filter>
<filter id="filter1_d_557_3345" x="59.75" y="5" width="79.25" height="79.0605" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="1"/>
<feGaussianBlur stdDeviation="12.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_557_3345"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_557_3345" result="shape"/>
</filter>
<linearGradient id="paint0_linear_557_3345" x1="73" y1="29" x2="73" y2="116.75" gradientUnits="userSpaceOnUse">
<stop stopColor="var(--color-primary-3)"/>
<stop offset="1" stopColor="var(--color-primary-3)"/>
</linearGradient>
<linearGradient id="paint1_linear_557_3345" x1="73" y1="29" x2="73" y2="116.75" gradientUnits="userSpaceOnUse">
<stop stopColor="var(--color-primary-3)"/>
<stop offset="1" stopColor="var(--color-primary-3)"/>
</linearGradient>
<linearGradient id="paint2_linear_557_3345" x1="34.4954" y1="27.5389" x2="53.7115" y2="78.8718" gradientUnits="userSpaceOnUse">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="white"/>
</linearGradient>
<linearGradient id="paint3_linear_557_3345" x1="32.7673" y1="26.7554" x2="44.7934" y2="58.9633" gradientUnits="userSpaceOnUse">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="white"/>
</linearGradient>
<linearGradient id="paint4_linear_557_3345" x1="97.375" y1="29" x2="97.375" y2="58.0601" gradientUnits="userSpaceOnUse">
<stop stopColor="var(--color-primary-3)"/>
<stop offset="1" stopColor="var(--color-primary-3)"/>
</linearGradient>
</defs>
</svg>

    )

}

export default Icon