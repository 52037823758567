import React from 'react'


const Icon = () => {
    return (
        <svg width="200" height="240" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166 216" fill="var(--color-primary-0)" transform="scale(0.8)">
            <g opacity="0.3">
                <path opacity="0.3" d="M140.38 49.24C134.913 49.2347 129.671 47.0606 125.805 43.1947C121.939 39.3288 119.765 34.0871 119.76 28.62V1.85821e-06H27.63C24.0953 -0.00131167 20.5949 0.693768 17.3289 2.04553C14.0628 3.3973 11.0952 5.37928 8.59534 7.87824C6.09545 10.3772 4.11238 13.3442 2.7594 16.6097C1.40642 19.8752 0.709961 23.3753 0.709961 26.91V189.13C0.720561 196.262 3.56176 203.098 8.60938 208.136C13.657 213.174 20.4982 216.003 27.63 216H138.23C141.764 216 145.263 215.304 148.528 213.952C151.793 212.599 154.759 210.617 157.258 208.118C159.757 205.619 161.739 202.653 163.092 199.388C164.444 196.123 165.14 192.624 165.14 189.09V49.24H140.38Z" fill="currentFill" />
            </g>
            <path d="M165.14 49.24H140.38C134.913 49.2347 129.671 47.0606 125.805 43.1947C121.939 39.3288 119.765 34.0871 119.76 28.62V0L165.14 49.24Z" fill="currentFill"  />
            <g clipPath="url(#clip0_102_5)">
                <path d="M58.9552 75.1746L47.1334 87.8285H67.1929C68.9918 87.8515 70.2287 88.4009 70.8736 88.7541C72.7745 89.796 73.7107 91.428 74.5679 92.926C75.3218 94.2413 75.7504 95.4281 75.998 96.2495C71.0015 107.087 66.0037 117.925 61.0072 128.763H74.9952L83.0206 111.29L94.8343 136.589C95.1268 137.208 95.9637 138.767 97.7992 139.881C99.7546 141.066 101.658 141.028 102.334 140.984C107.414 140.984 112.493 140.984 117.573 140.984L125.83 128.326H109.803C108.497 128.342 107.528 128.04 106.942 127.8C104.422 126.767 103.201 124.513 102.622 123.441C102.204 122.67 101.945 121.983 101.787 121.496C91.7441 99.5892 84.1759 83.5821 82.2097 79.5698C81.8042 78.7417 81.0844 77.5644 79.8271 76.6104C77.4404 74.7998 74.6469 74.9541 71.8125 75.0542C68.9074 75.157 64.6538 75.2504 58.9552 75.1746Z" fill="currentFill" />
            </g>
        </svg>

    )
}

export default Icon

