import React, { useState } from "react";
import { useTranslation } from "react-i18next"

import { useLocation, useNavigate, useParams } from "react-router-dom";


import NavProps from "./NavProps";
import menuImg from "@home/assets/images/menu-img/menu-img-2.png";


import styles from '../index.module.css'

const Nav = () => {
  const { t } = useTranslation()

  const { lng } = useParams()

  const navigate = useNavigate();
  const location = useLocation();

  const [sectionStates, setSectionStates] = useState({
    Tools: true,
    Pages: true,
  });

  const toggleSection = (subTitle) => {
    setSectionStates((prevState) => ({
      ...prevState,
      [subTitle]: !prevState[subTitle],
    }));
  };



  const isActive = (href) => {
    if (location.pathname && location.pathname.split('/')) {

      let path = location.pathname.split('/')
      if (path.length >= 2) {
        if (path[2] === href) {
          return true
        }
      } else if (path[0] == "hola") {
        return true
      }

    }
    return false
  }

  return (
    <>
      <ul className={`${styles.mainmenu}`} >
        {Array.isArray(t('home1.header.nav', { returnObjects: true })) &&
          t('home1.header.nav', { returnObjects: true }).map((data, index) => (
            <li
              className={`${data.dropdown
                ? `${styles["has-dropdown"]} ${styles["has-menu-child-item"]} ${styles["position-relative"]}`
                : ""
                } ${data.megamenu ? `${styles["with-megamenu"]} ${styles["has-menu-child-item"]}` : ""}`}
              key={index}
            >
              {data.button === "#" ? (
                <a
                  href="#"
                  className={` ${!sectionStates[data.text] ? styles.open : ""}`}
                  onClick={() => {
                    toggleSection(data.text)
                  }}
                >
                  {data.text}
                  {data.isIcon ? (
                    <svg fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m8 10 4 4 4-4" />
                    </svg>

                  ) : (
                    ""
                  )}
                </a>
              ) : (
                <button
                  href={data.button}
                  className={isActive(data.link) ? styles.active : ""}
                  onClick={() => {
                    navigate(`/${lng}/${data.link}`)
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                >
                  {data.text}
                  {data.isIcon ? (
                    <svg fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m8 10 4 4 4-4" />
                    </svg>

                  ) : (
                    ""
                  )}
                </button>
              )}

              {data.isMenu &&
                !data.inner &&
                !data.dashboard &&
                !data.upcoming ? (
                <ul
                  className={`${styles.submenu} ${!sectionStates[data.text] ? styles["d-block"] : ""
                    }`}
                >
                  {data.subItem &&
                    data.subItem.map((innerData, innerIndex) => (
                      <li key={innerIndex}>
                        <button
                          className={`${isActive(innerData.button) ? styles["active"] : ""
                            } ${innerData.isDisable ? styles["disabled"] : ""}`}
                          href={!innerData.isDisable ? innerData.button : "#"}
                        >
                          <span>{innerData.title}</span>
                          {innerData.badge ? (
                            <div className={`${styles['rainbow-badge-card']} ${styles['badge-sm']} ${styles['ml--5']}`} >
                              {innerData.badge}
                            </div>
                          ) : (
                            ""
                          )}
                        </button>
                      </li>
                    ))}
                </ul>
              ) : data.isMenu ? (
                <div
                  className={`${styles["rainbow-megamenu"]} ${!sectionStates[data.text] ? `${styles["d-block"]} ${styles["active"]}` : ""
                    }`}
                >
                  <div className={`${styles.wrapper}`} >
                    <div className={`${styles.row} ${styles['row--0']}`} >
                      <NavProps list={data.inner} />
                      <NavProps list={data.dashboard} />
                      <NavProps list={data.upcoming} />
                      <div className={`${styles["col-lg-3"]} ${styles["single-mega-item"]}`} >
                        <div className={`${styles["header-menu-img"]}`} >
                          <img
                            src={menuImg}
                            width={326}
                            height={458}
                            alt="Menu Split Image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </li>
          ))}
      </ul>
    </>
  );
};

export default Nav;
