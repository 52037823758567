import { createSlice } from '@reduxjs/toolkit';

import {
  fetchsTicket,
  fetchTicket,
  addTicket,
  updateTicket,
  deleteTicket,

  addVectorTicket,
  vectorTicket,

  storeStatus,
  fetchsStatus,
  updateStatus,
  deleteStatus,
} from '../actions/ticket'

export const initialMessage = {
  id: '',
  message: '',
  updatedAt: '',
  createdAt: ''
}

const ticketSlice = createSlice({
  name: 'ticket',
  initialState: {
    total: null,
    tickets: [],
    messages: [],

    status: []
  },
  reducers: {
    setTicket: (state, action) => {
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchsTicket.fulfilled, (state, action) => {
        state.tickets = action.payload.tickets
        state.total = action.payload.total
      })

      .addCase(fetchTicket.fulfilled, (state, action) => {
        state.messages = action.payload;
      })
      .addCase(fetchTicket.rejected, (state, action) => {
        state.error = action.error.message;
      })


      .addCase(addTicket.fulfilled, (state, action) => {
        state.tickets.push(action.payload);
      })
      .addCase(addTicket.rejected, (state, action) => {
        state.error = action.error.message;
      })


      .addCase(updateTicket.fulfilled, (state, action) => {
        state.components = action.payload;
      })
      .addCase(updateTicket.rejected, (state, action) => {
        state.error = action.error.message;
      })

      .addCase(deleteTicket.fulfilled, (state, action) => {
        state.tickets = state.tickets.filter(ticket => ticket.id !== action.payload);
      })

      .addCase(vectorTicket.fulfilled, (state, action) => {
        state.messages = action.payload
      })


      .addCase(storeStatus.fulfilled, (state, action) => {
      })
      .addCase(fetchsStatus.fulfilled, (state, action) => {
      })
      .addCase(updateStatus.fulfilled, (state, action) => {
      })
      .addCase(deleteStatus.fulfilled, (state, action) => {
      })


  },
});

export const {
  setTicket,
} = ticketSlice.actions;

export default ticketSlice.reducer;
