const form = {
    editor: {
        step0: {
            text0: `Будет ли ваш проект монетизирован?`,
            t0: `Да`,
            t1: `Нет`,
        },
        step1: {
            title: `Описание вашего проекта`,
            text: `Опишите проект, чтобы подготовить первые шаги`,
            t0: `Имя`,
            t1: `Описание`,
        },
        step2: {
            title: `Сколько пользователей вы ожидаете иметь?`,
            text: `В зависимости от количества пользователей мы предлагаем более критические меры`,
            t0: `0-1.000`,
            t1: `1.000-10k`,
            t2: `10k-100k`,
            t3: `100k-250k`,
            t4: `250k-500k`,
            t5: `+500.001`
        },
        step3: {
            title: `Выберите тип и стиль`,
            text0: `Тип служит для выбора одной из наших категорий`,
            text1: `Стиль определяет, как это будет выглядеть`,
            t0: `Привет, мир`,
            t1: `Минималистичный`,
            t2: `Современный`,
            t3: `Классический`,
            x0: `Сложный`,
            x1: `Простой`,
        },
        step4: {
            title: `Желаете ли вы использовать расширенный или базовый режим редактора?`,
            t0: `Расширенный`,
            t1: `Базовый`,
        },
    }, 
    flags: {
        en: `Английский`,
        es: `Испанский`,
        fr: `Французский`,
        de: `Немецкий`,
        it: `Итальянский`,
        ru: `Русский`,
        pt: `Португальский`,
        nl: `Голландский`,
        sv: `Шведский`,
    },
    title: {
        text0: 'Шаг',
        text1: 'из',
        step0: 'Язык',
        step1: 'Профиль',
        step2: 'Цель',
        step3: 'Контекст',
        step4: 'Бонус',
        step5: 'Компания',
        step6: 'Размер',
        step7: 'Тип программного обеспечения',
        step8: 'Найм',
    },
    footer: {
        text: 'Подписывайтесь на наши электронные письма',
        button0: 'Назад',
        button1: 'Далее',
    },
    step0: {
        t0: `Выберите язык`,
        t1: `При выборе сайт изменится`,
        t2: `Мы в настоящее время интегрируем больше языков`,
    },
    step1: {
        t0: `Как вас зовут?`,
        t1: `Приятно познакомиться`,
        t2: `Имя`,
        t3: `Фамилия`,
        t4: `Телефон`,
        t5: `Электронная почта`,
    },
    step2: {
        t0: `Для кого вам это нужно?`,
        t1: `Выберите вариант вашей цели`,
        t2: `Моя компания`,
        t3: `Для себя`,
        t4: `Клиенты`,
    },
    step3: {
        t0: `Как вы себя описываете? `,
        t1: `Стартап`,
        t2: `Малый бизнес`,
        t3: `Среднее предприятие`,
        t4: `Крупная компания`,
    },
    step4:{
        t0: `Вы студент?`,
        t1: `Да`,
        t2: `Нет`,
    },
    step5:{
        t0: `Что лучше всего вас описывает?`,
        t1: `Фрилансер`,
        t2: `Агентство`,
    },
    step6:{
        t0: `Каков размер вашей рабочей команды?`,
    },
    step7:{
        t0: `Какой тип сайта вы сегодня пытаетесь создать?`,
        t1: `Бизнес сайт`,
        t2: `Интернет-магазин`,
        t3: `Портфолио`,
        t4: `Блог`,
        t5: `Аналитика`,
        t6: `Другой`,
    },
    step8:{
        t0: `Вы заинтересованы в использовании Aythen?`,
        t1: `Да`,
        t2: `Нет`,
        t3: `Я еще не уверен`,
    },
}

export default form
