import React, { useRef, useEffect } from 'react'
import { FiLink, FiPlus, FiCalendar } from 'react-icons/fi'
import styles from './MeetOptionsPopup.module.css'
import { useDispatch } from 'react-redux'
import { setModal } from '../../../slices/iamSlice'
import { setShowMeetOptionsPopup } from '../../../slices/meetSlices'
import { useNavigate } from 'react-router-dom'
import CreateMeetingModal from './CreateMeetingModal'

const MeetOptionsPopup = ({ onClose, goToMeet, fromHome, setShowLater }) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const popupRef = useRef(null)
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (popupRef.current && !popupRef.current.contains(event.target)) {
				onClose() 
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [onClose])

	return (
		<div ref={popupRef} className={styles.meetOptionsPopup}>
			<ul className={styles.popupOptionsList}>
				<li onClick={goToMeet} className={styles.popupOption}>
					<FiPlus className={styles.popupIcon} />
					<span>Ir a Aythen Meet</span>
				</li>
				{!fromHome && (
					<li
						onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
							fromHome
								? setShowLater(true)
								: dispatch(setShowMeetOptionsPopup(true))
							onClose()
						}}
						className={styles.popupOption}
					>
						<FiLink className={styles.popupIcon} />
						<span>Crear reunion para mas tarde</span>
					</li>
				)}
				<li
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						fromHome
							? navigate('/es/v1-1/app/calendar')
							: dispatch(
									setModal({
										component: <CreateMeetingModal />,
										option: { close: false },
									})
								)
						onClose()
					}}
					className={styles.popupOption}
				>
					<FiCalendar className={styles.popupIcon} />
					<span>Agendar en Aythen Calendar</span>
				</li>
			</ul>
		</div>
	)
}

export default MeetOptionsPopup
