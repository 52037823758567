import React from 'react'

const Icon = () => {
	return (
		<svg
			width='147'
			height='147'
			viewBox='0 0 147 147'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M40.9013 40.4309C40.9013 38.7554 42.2595 37.3971 43.9351 37.3971H57.0495C58.725 37.3971 60.0833 38.7554 60.0833 40.4309C60.0833 42.1065 58.725 43.4648 57.0495 43.4648H43.9351C42.2595 43.4648 40.9013 42.1065 40.9013 40.4309Z'
				fill='url(#paint0_linear_1168_3872)'
				fillOpacity='0.9'
				stroke='url(#paint1_linear_1168_3872)'
				strokeWidth='0.489516'
			/>
			<path
				d='M27.6431 41.2006C27.6431 37.4664 27.6431 35.5992 28.3813 34.178C29.0034 32.9804 29.9799 32.0039 31.1776 31.3818C32.5988 30.6436 34.4659 30.6436 38.2001 30.6436H64.1406C65.7291 30.6436 66.5234 30.6436 67.2724 30.8219C67.9093 30.9736 68.5198 31.2198 69.0837 31.5523C69.747 31.9433 70.3191 32.4943 71.4634 33.5962L71.8808 33.9981C73.0251 35.1 73.5972 35.651 74.2605 36.042C74.8245 36.3745 75.435 36.6207 76.0718 36.7724C76.8209 36.9507 77.6152 36.9507 79.2037 36.9507H105.387C109.121 36.9507 110.988 36.9507 112.409 37.689C113.607 38.3111 114.583 39.2876 115.206 40.4852C115.944 41.9064 115.944 43.7736 115.944 47.5078V107.359C115.944 109.226 115.944 110.159 115.575 110.87C115.264 111.469 114.775 111.957 114.177 112.268C113.466 112.637 112.532 112.637 110.665 112.637H37.1444C33.7836 112.637 32.1032 112.637 30.8241 111.973C29.7462 111.413 28.8674 110.534 28.3075 109.456C27.6431 108.177 27.6431 106.497 27.6431 103.136V41.2006Z'
				fill='url(#paint2_linear_1168_3872)'
			/>
			<g filter='url(#filter0_b_1168_3872)'>
				<path
					d='M50.9512 59.3352C51.8773 56.1498 52.3404 54.5571 53.5649 53.6379C54.7894 52.7188 56.4497 52.7188 59.7704 52.7188H117.244C122.473 52.7188 125.088 52.7188 126.466 54.4167C127.843 56.1147 127.305 58.6731 126.228 63.79L117.478 105.348C116.743 108.84 116.376 110.586 115.112 111.611C113.848 112.637 112.063 112.637 108.495 112.637H33.9504C37.2128 109.089 37.2128 105.541 40.4751 94.8981C42.7962 87.3256 48.0898 69.1772 50.9512 59.3352Z'
					fill='var(--color-primary-4)'
					fillOpacity='0.6'
				/>
				<path
					d='M51.245 59.4206C51.7101 57.8209 52.052 56.6467 52.428 55.7557C52.8009 54.8722 53.1954 54.2978 53.7485 53.8826C54.3016 53.4674 54.9636 53.2489 55.9171 53.1375C56.8784 53.0252 58.1028 53.0247 59.7704 53.0247H117.244C119.868 53.0247 121.803 53.0254 123.256 53.2338C124.7 53.4411 125.609 53.8463 126.228 54.6095C126.847 55.3727 127.057 56.3453 126.962 57.8014C126.867 59.2658 126.469 61.1592 125.928 63.727L117.179 105.285C116.81 107.038 116.538 108.326 116.204 109.306C115.872 110.277 115.49 110.911 114.919 111.374C114.348 111.837 113.649 112.081 112.63 112.205C111.603 112.331 110.287 112.331 108.495 112.331H34.6252C36.0061 110.674 36.76 108.95 37.5053 106.518C37.8448 105.41 38.1843 104.15 38.5801 102.68C38.662 102.376 38.7463 102.063 38.8334 101.741C39.3428 99.8576 39.9535 97.6439 40.7676 94.9878C43.0895 87.4126 48.3839 69.2616 51.245 59.4206Z'
					stroke='url(#paint3_linear_1168_3872)'
					strokeWidth='0.611895'
				/>
			</g>
			<path
				d='M40.9013 40.4309C40.9013 38.7554 42.2595 37.3971 43.9351 37.3971H57.0495C58.725 37.3971 60.0833 38.7554 60.0833 40.4309C60.0833 42.1065 58.725 43.4648 57.0495 43.4648H43.9351C42.2595 43.4648 40.9013 42.1065 40.9013 40.4309Z'
				fill='url(#paint4_linear_1168_3872)'
				fillOpacity='0.9'
				stroke='url(#paint5_linear_1168_3872)'
				strokeWidth='0.489516'
			/>
			<path
				d='M89.0268 77.4307L90.4942 74.5528L88.7969 74.5629C87.8966 74.5682 86.8786 74.8766 85.9668 75.4201C85.055 75.9636 84.3241 76.6977 83.9349 77.461L78.0651 88.9726C77.6759 89.7358 77.6603 90.4657 78.0217 91.0016C78.3831 91.5375 79.092 91.8356 79.9923 91.8303L81.6896 91.8202L83.157 88.9423C83.5462 88.179 84.2771 87.4449 85.1889 86.9014C86.1008 86.3579 87.1188 86.0495 88.0191 86.0442L98.203 85.9836C99.1033 85.9782 99.8121 86.2763 100.174 86.8122C100.535 87.3481 100.519 88.078 100.13 88.8412L95.7278 97.4749C95.3387 98.2382 94.6078 98.9723 93.6959 99.5158C92.7841 100.059 91.7661 100.368 90.8658 100.373L80.6819 100.434C79.7816 100.439 79.0727 100.141 78.7113 99.605C78.3499 99.0691 78.3655 98.3392 78.7547 97.5759L80.2221 94.6981L78.5248 94.7082C76.7242 94.7189 75.3065 94.1227 74.5837 93.0509C73.8609 91.979 73.8921 90.5193 74.6705 88.9928L76.8716 84.6759L70.0824 84.7163L69.3487 86.1553C68.9595 86.9185 68.2286 87.6527 67.3167 88.1962C66.4049 88.7397 65.3869 89.048 64.4866 89.0534L57.6973 89.0938C56.797 89.0991 56.0882 88.8011 55.7268 88.2652C55.3653 87.7292 55.3809 86.9994 55.7701 86.2361L58.705 80.4803C59.0942 79.717 59.8251 78.9829 60.7369 78.4394C61.6487 77.8959 62.6668 77.5876 63.5671 77.5822L70.3564 77.5418C71.2567 77.5364 71.9655 77.8345 72.3269 78.3704C72.6883 78.9063 72.6727 79.6362 72.2835 80.3995L71.5498 81.8384L78.3391 81.798L80.5402 77.4812C81.3186 75.9547 82.7804 74.4864 84.604 73.3994C86.4277 72.3124 88.4638 71.6957 90.2644 71.685L91.9617 71.6749L93.4291 68.797C93.8183 68.0337 94.5492 67.2996 95.461 66.7561C96.3728 66.2126 97.3909 65.9042 98.2912 65.8989L108.475 65.8383C109.375 65.8329 110.084 66.131 110.446 66.6669C110.807 67.2028 110.791 67.9327 110.402 68.696L106 77.3297C105.611 78.0929 104.88 78.8271 103.968 79.3706C103.056 79.9141 102.038 80.2224 101.138 80.2278L90.954 80.2884C90.0537 80.2937 89.3448 79.9957 88.9834 79.4597C88.622 78.9238 88.6376 78.1939 89.0268 77.4307Z'
				fill='url(#paint6_linear_1168_3872)'
			/>
			<defs>
				<filter
					id='filter0_b_1168_3872'
					x='20.8361'
					y='39.6044'
					width='119.568'
					height='86.1467'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feGaussianBlur in='BackgroundImageFix' stdDeviation='6.55719' />
					<feComposite
						in2='SourceAlpha'
						operator='in'
						result='effect1_backgroundBlur_1168_3872'
					/>
					<feBlend
						mode='normal'
						in='SourceGraphic'
						in2='effect1_backgroundBlur_1168_3872'
						result='shape'
					/>
				</filter>
				<linearGradient
					id='paint0_linear_1168_3872'
					x1='39.1614'
					y1='36.7142'
					x2='66.0848'
					y2='44.6688'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='white' />
					<stop offset='1' stopColor='white' stopOpacity='0' />
				</linearGradient>
				<linearGradient
					id='paint1_linear_1168_3872'
					x1='37.3257'
					y1='38.5499'
					x2='50.4923'
					y2='43.7095'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='white' />
					<stop offset='1' stopColor='white' stopOpacity='0' />
				</linearGradient>
				<linearGradient
					id='paint2_linear_1168_3872'
					x1='53.1144'
					y1='48.5796'
					x2='119.741'
					y2='73.2544'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='var(--color-primary-0)' />
					<stop offset='1' stopColor='var(--color-primary-4)' />
				</linearGradient>
				<linearGradient
					id='paint3_linear_1168_3872'
					x1='29.22'
					y1='56.9986'
					x2='62.6625'
					y2='137.645'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='white' />
					<stop offset='0.765625' stopColor='white' stopOpacity='0' />
				</linearGradient>
				<linearGradient
					id='paint4_linear_1168_3872'
					x1='39.1614'
					y1='36.7142'
					x2='66.0848'
					y2='44.6688'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='white' />
					<stop offset='1' stopColor='white' stopOpacity='0' />
				</linearGradient>
				<linearGradient
					id='paint5_linear_1168_3872'
					x1='37.3257'
					y1='38.5499'
					x2='50.4923'
					y2='43.7095'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='white' />
					<stop offset='1' stopColor='white' stopOpacity='0' />
				</linearGradient>
				<linearGradient
					id='paint6_linear_1168_3872'
					x1='88.956'
					y1='65.9544'
					x2='89.1609'
					y2='100.383'
					gradientUnits='userSpaceOnUse'
				>
					<stop offset='0.03' stopColor='var(--color-primary-3)' />
					<stop offset='1' stopColor='white' stopOpacity='0.66' />
				</linearGradient>
			</defs>
		</svg>
	)
}

export default Icon
