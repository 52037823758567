import React, { Fragment, useRef, useState, useEffect } from 'react'


const Video = ({ video = false }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [videoOpen, setVideoOpen] = useState(video)
  const cancelButtonRef = useRef(null)

  useEffect(() => {
    if (video) {
      setModalOpen(true)
      setVideoOpen(video)
    } else {
      setModalOpen(false)
      setVideoOpen(false)
    }
  }, [video])

  return (
    <div className="bg-light-50 rounded-[14px] bg-white">

      {videoOpen === 'home' && (
        <video autoPlay className="w-full" controls>
          <source src="https://mystorage.s3.fr-par.scw.cloud/aythen-home.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      {videoOpen === 'reseller' && (
        <video autoPlay className="w-full" controls>
          <source src="https://mystorage.s3.fr-par.scw.cloud/aythen-reseller.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  )
}

export default Video
