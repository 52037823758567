import { useLocation } from 'react-router-dom'

import avatar from '@home/assets/images/team/team-01sm.jpg'
import testIcon from '@home/assets/images/codeIcon.png'
import light from '@home/assets/images/light/switch/sun-01.svg'
import dark from '@home/assets/images/light/switch/vector.svg'

import { CreateContext } from '../context/Context'
import { useContext } from 'react'

const MenuData = null

import styles from '../index.module.css'

const LeftSidebar = () => {
	const location = useLocation()
	const { shouldCollapseLeftbar, isLightTheme, toggleTheme } =
		useContext(CreateContext)

	const isActive = (href) => location.pathname.startsWith(href)
	return (
		<>
			<div
				className={`${styles["rbt-left-panel"]} ${styles["popup-dashboardleft-section"]} ${
					shouldCollapseLeftbar ? styles.collapsed : ''
				}`}
			>
				<div className={styles['rbt-default-sidebar']}>
					<div className={styles['inner']}>
						<div className={styles['content-item-content']}>
							<div className={styles['rbt-default-sidebar-wrapper']}>
								<nav className={styles['mainmenu-nav']}>
									<ul className={`${styles['dashboard-mainmenu']} ${styles['rbt-default-sidebar-list']}`}>
										{MenuData &&
											MenuData.smallNavItem
												.slice(0, 7)
												.map((data, index) => (
													<li key={index}>
														<button
															style={{
																flexDirection: 'row',
																display: 'flex',
																alignItems: 'center',
																marginBottom: 25,
															}}
															className={
																isActive(data.link)
																	? styles.active
																	: '' || data.isDisable
																		? styles.disabled
																		: ''
															}
															href={data.link}
														>
															<img
																src={testIcon}
																width={35}
																height={35}
																alt='AI Generator'
															/>
															<span>{data.text}</span>
															{data.badge !== '' ? (
																<div className={`${styles['rainbow-badge-card']} ${styles['badge-sm']} ${styles['ml--10']}`}>
																	{data.badge}
																</div>
															) : (
																''
															)}
														</button>
													</li>
												))}
									</ul>
									<div className={styles['rbt-sm-separator']}></div>
									<div className={styles['mainmenu-nav']}>
										<ul className={`${styles['dashboard-mainmenu']} ${styles['rbt-default-sidebar-list']}`}>
											<li className={styles['has-submenu']}>
												<a
													className={`${styles['collapse-btn']} ${styles.collapsed}`}
													data-bs-toggle='collapse'
													href='#collapseExampleMenu'
													role='button'
													aria-expanded='false'
													aria-controls='collapseExampleMenu'
												>
													<i className={styles['feather-plus-circle']}></i>
													<span>Setting</span>
												</a>
												<div className={styles['collapse']} id='collapseExampleMenu'>
													<ul className={`${styles['submenu']} ${styles['rbt-default-sidebar-list']}`}>
														{SmallNavItem &&
															SmallNavItem.smallNavItem
																.slice(7, 14)
																.map((data, index) => (
																	<li key={index}>
																		<a className={`${styles['collapse-btn']} ${styles.collapsed}`}>
																			<i className={`${styles[`feather-${data.icon}`]}`}></i>
																			<span>{data.text}</span>
																		</a>
																	</li>
																))}
													</ul>
												</div>
											</li>
											<li>
												<a
													className={`${styles['collapse-btn']} ${styles.collapsed}`}
													data-bs-toggle='collapse'
													href='#collapseExampleMenu'
													role='button'
													aria-expanded='false'
													aria-controls='collapseExampleMenu'
												>
													<i className='feather-award'></i>
													<span>Help & FAQ</span>
												</a>
											</li>
										</ul>

										<div className={styles['rbt-sm-separator']}></div>
										<ul className={`${styles['dashboard-mainmenu']} ${styles['rbt-default-sidebar-list']}`}>
											<li>
												<a
													className={`${styles['collapse-btn']} ${styles.collapsed}`}
													data-bs-toggle='collapse'
													href='#collapseExampleMenu'
													role='button'
													aria-expanded='false'
													aria-controls='collapseExampleMenu'
												>
													<i className='feather-bell'></i>
													<span>Release notes</span>
												</a>
											</li>
											<li>
												<a
													className={`${styles['collapse-btn']} ${styles.collapsed}`}
													data-bs-toggle='collapse'
													href='#collapseExampleMenu'
													role='button'
													aria-expanded='false'
													aria-controls='collapseExampleMenu'
												>
													<i className={styles['feather-briefcase']}></i>
													<span>Terms & Policy</span>
												</a>
											</li>
										</ul>
									</div>
								</nav>
							</div>
						</div>
					</div>

					<div className={styles['subscription-box']}>
						<div className={styles.inner}>
							<button href='/profile-details' className={styles['autor-info']}>
								<div className={`${styles['author-img']} ${styles.active}`}>
									<img
										className='w-100'
										width={49}
										height={48}
										src={avatar}
										alt='Author'
									/>
								</div>
								<div className={styles['author-desc']}>
									<h6>Aythen</h6>
									<p>info@aythen.com</p>
								</div>
								<div className={styles['author-badge']}>Free</div>
							</button>
							<div style={{ width: '100%' }} className={styles['btn-part']}>
								<button
									style={{ width: '100%' }}
									href='/pricing'
									className={`${styles['btn-default']} ${styles['btn-border']}`}
								>
									Upgrade To Pro
								</button>
							</div>
						</div>
					</div>
					<p className={`${styles['subscription-copyright']} ${styles['copyright-text']} ${styles['text-center']} ${styles.b3}  ${styles['small-text']}`}>
						© 2024
						<button
							className='ps-2'
							href='https://themeforest.net/user/rainbow-themes/portfolio'
						>
							Aythen
						</button>
						.
					</p>
				</div>
			</div>
		</>
	)
}

export default LeftSidebar
