const web = {
    t1: {
        '01': `Modele seus`,
        '02': `dados`,
        '03': `de forma avançada,`,
        '04': `preveja sua`,
        '05': `matriz`,
        '06': `para qualquer realidade possível e analise os`,
        '07': `big data`,
        '08': `com bancos de dados vetorizados`,
    },
    t2: `COMECE AGORA`,
    t3: `No seu novo sistema operacional`,
    t4: {
        '01': `Offline`, 
        '02': `Online`, 
        '03': `Público`, 
        '04': `Privado`, 
    },
    tassistant: {
        '01': `Velocidade`,
        '02': `Textura`,
        '03': `Processamento`,
    },
    t5: `Um mundo de possibilidades`,
    t6: {
        '01': `Graças aos`,
        '02': `bancos de dados vetorizados,`,
        '03': `você passa de`,
        '04': `dados estáticos`,
        '05': `para`,
        '06': `dados dinâmicos.`,
        '07': `Trabalhar com muitos dados simultaneamente é difícil, por isso`,
        '08': `zero latência em certas dimensões`,
        '09': `é necessário para acesso rápido e versátil.`,
    },
    t7: `Entre agora`,
    t8: `ECONOMIZE TEMPO . ECONOMIZE TEMPO . ECONOMIZE TEMPO`,
    t9: `Tire suas próprias conclusões`,
    t10: `Informação é poder`,
    t11: {
        '01': `Agora você pode entender seus dados!`,
        '02': `Através da segmentação, com o conceito de`,
        '03': `K-Means,`,
        '04': `você tem uma ferramenta para analisar qualquer dado para medir.`,
    },
    t12: `Comece agora`,
    t13: `Espaço disponível na nuvem. Tudo escalável em instâncias privadas e seguras.`,
    t14: `vezes mais rápido do que trabalhar com bancos de dados tradicionais.`,
    t15: `Representações possíveis para importar suas métricas e KPIs.`,
    t16: `Analise dados em todas as dimensões`,
    t17: `Cada dimensão tem agentes.`,
    t18: {
        '01': `Em cada dimensão, você pode criar`,
        '02': `fluxos de agentes`,
        '03': `para criar redes de interações.`
    },
    t19: {
        '01': `Em cada agente, você escolherá a`,
        '02': `estratégia`,
        '03': `para relacionar`,
        '04': `cada dado.`,
        '05': `cada dado.`,
    },
    t20: `Inicie seu teste GRATUITO`,
    t21: `Dimensões`,
    t22: `Agentes`,
    t23: `Ideias para gerenciamento de dados`,
    t24: `Seus dados, o primeiro passo`,
    t25: {
        '01': `Eles são sua chave para construir`,
        '02': `aplicativos incríveis. Eles não são apenas informações,`,
        '03': `eles são a matéria-prima`,
        '04': `para a criação de`,
        '05': `ideias inovadoras e sua análise.`,
        '06': `Como trabalhamos com dados?`,
    },
    t26: `Seu espaço de trabalho`,
    t27: `Armazene todo o conteúdo que você precisa em sua nuvem privada ou pública, de acordo com suas necessidades.`,
    t28: `Mapa de calor`,
    t29: `Representação que mostra a distribuição e concentração de determinados valores dentro de um conjunto de dados.`,
    t30: `Perguntas dinâmicas`,
    t31: `Criação de questionários interativos, onde as perguntas podem se adaptar aos dados pré-existentes.`,
    t32: `Gráficos personalizados`,
    t33: `Permitindo a seleção do tipo de gráfico, dados a serem visualizados e personalização de aspectos como cores e rótulos.`,
    t34: `Chamadas de vídeo`,
    t35: `Comunicação em tempo real via vídeo e áudio, facilitando a colaboração e interação.`,
    t36: `Contratos`,
    t37: `Criação, gerenciamento e faturamento eletrônico de contratos, agilizando processos jurídicos e administrativos.`,
    t38: `Funções e permissões`,
    t39: `Atribua diferentes funções e níveis de acesso aos usuários dentro da sua própria equipe para criar sua organização.`,
    t40: `Sincronização de equipe`,
    t41: `Colaboração em tempo real do trabalho entre vários usuários, garantindo que as alterações feitas sejam automaticamente protegidas.`,
    t42: `Todos os seus contatos`,
    t43: `Centralize e organize todos os contatos. Gerencie e acesse facilmente as informações de contato.`,
    t44: `Aprenda a fazer, é muito fácil`,
    t45: `Mudar dimensões`,
    t46: {
        '01': `Torne seus dados inteligentes, conectáveis, consistentes, seguros, eficientes e acessíveis. Potencialize suas análises através de`,
        '02': `nosso acesso.`
    },
    t47: {
        '01': `Comece com `,
        '02': `o tutorial`,
        '03': `para entender as diferentes partes da plataforma.`,
    },
    t48: `Inscreva-se AGORA`,
    t49: `Valorize seu tempo e automatize seus dados`,
    t50: {
        '01': `E comece a alternar dimensões em nosso sistema operacional em nuvem. Analise e entenda padrões para`,
        '02': `trabalhar seus dados.`,
    },


    footer: {
        t00: "INÍCIO",
        t01: "Sua equipe",
        t02: "Atualizações",
        t03: "Kit Digital",
        t04: "Consultoria",
        t05: "Estudar",
    
        t10: "LINKS",
        t11: "Equipe",
        t12: "Freelancers",
        t13: "Termos",
        t14: "Recursos",
        t15: "Calculadora",
        t16: "Planos",
    
        t20: "MÓDULO DE IA",
        t21: "Voz",
        t22: "Imagem e Vídeo",
        t23: "Criação de Conteúdo",
        t24: "Criação de Mídia",
    
        t30: "SUPORTE AYTHEN",
        t31: "Acordo do Usuário",
        t32: "Política de Privacidade",
        t33: "Coleta e Uso de Dados",
        t34: "Diretrizes de Conteúdo",
    
        t40: "PARA PROJETAR",
        t41: "Editor",
        t42: "Aplicativos",
        t43: "Nuvem",
        t44: "Plugins",
        t45: "RPA",
    
        t50: "PARA ENCONTRAR",
        t51: "Biblioteca",
        t52: "Predefinições",
        t53: "Devops",
        t54: "IDE",
        t55: "Sandbox",
    
        t60: "PARA CONSTRUIR",
        t61: "UX/AI",
        t62: "Traduzir",
        t63: "Documentos de IA",
        t64: "Suporte de IA",
        t65: "Colaborações",
    
        t70: "PARA PROTEGER",
        t71: "Blockchain",
        t72: "Dados",
        t73: "Pivô",
        t74: "Academia",
        t75: "Freelancers",
    }
    
}

export default web
