import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import FormPreview from "./preview"

import { fetchForm } from "../../views/app/v1-1/actions/form"

const initialQuestions = [
  {
    type: "checkbox",
    text: "¿Cuáles de estas posiciones has jugado?",
    options: ["Portero", "Defensa", "Mediocampista", "Delantero"],
    id: "1718630809399ghjpm5gwq",
  },
  {
    type: "radio",
    text: "¿Cuál es tu posición favorita?",
    options: ["Portero", "Defensa", "Mediocampista", "Delantero"],
    id: "1718630809399i2ljdouns",
  },
  {
    type: "short-answer",
    text: "¿Cuál es tu equipo de futbol favorito?",
    id: "1718630809399cda6eqavm",
  },
  {
    type: "long-answer",
    text: "¿Por qué decidiste convertirte en jugador de futbol?",
    id: "1718630809399uez563ymq",
  },
  {
    type: "dropdown",
    text: "Selecciona tu posición preferida",
    options: ["Portero", "Defensa", "Mediocampista", "Delantero"],
    id: "1718630809399hm328zzpm",
  },
  {
    type: "file-upload",
    text: "Sube una foto tuya jugando al futbol",
    id: "1718630809399iid4h2kr1",
  },
  {
    type: "linear-scale",
    text: "Del 1 al 10, ¿cómo calificarías tu habilidad en el futbol?",
    id: "17186308093998dwc1p8x3",
  },
  {
    type: "date",
    text: "¿Cuál fue tu primer partido de futbol?",
    id: "1718630809399rwjano7ux",
  },
  {
    type: "time",
    text: "¿A qué hora prefieres entrenar?",
    id: "1718630809399fn5manorj",
  },
  {
    type: "multiple-choice",
    text: "¿Cuál es tu equipo nacional favorito?",
    options: ["Brasil", "Argentina", "España", "Alemania"],
    id: "1718630809399dlojxu4cs",
  },
  {
    type: "multiple-choice-grid",
    text: "Selecciona las áreas en las que te gustaría mejorar",
    rows: ["Defensa", "Ataque", "Pases", "Regates"],
    columns: ["Poco", "Regular", "Mucho"],
    id: "17186308093995jocaye6z",
  },
  {
    type: "checkbox-grid",
    text: "Selecciona los aspectos que consideras importantes en un entrenador",
    rows: ["Motivación", "Táctica", "Comunicación", "Experiencia"],
    columns: ["Importante", "Poco importante"],
    id: "17186308093998402bqkxt",
  },
]

const container = {
  maxWidth: "600px",
  margin: "20px auto",
}

const Iframe = () => {
  const { id } = useParams()

  const dispatch = useDispatch()


  const [formId, setFormId] = useState(null)
  const [questions, setQuestions] = useState([])
  const [responses, setResponses] = useState({})


  const fetchData = async () => {
    if(id){
      const resp = await dispatch(fetchForm(id))
      if(resp.payload){
        setFormId(resp.payload._id)
        setQuestions(resp.payload.questions)
      }
    }else{
    }
  }
  useEffect(() => {
   fetchData()
  }, [id])

 

  return (
    <div style={container}>
      <FormPreview
        formId={formId}
        responses={responses}
        setResponses={setResponses}
        questions={questions}
      />
    </div>
  )
}

export default Iframe
