const home = {
    banner: {
        banner: 'Redigera profilbild',
        edit: 'Redigera profil',
        addUser: 'Lägg till användare',
        title: 'delade användare',
        viewTeam: 'Hantera team',
        team: {
            title: `Personer du kanske känner`,
            button: `Visa alla`,
            addContact: `Lägg till i kontakter`
        },
    },
    t1: `Organisation`,
    t2: `Bank`,
    t3: `Identifikation och åtkomst för manager (IAM)`,
    t4: `Åtkomst (IAM)`,
    t5: `Arbeta och förstå dina data som vektorer`,
    t6: `Gå in i den nya dimensionen av data. Ta bort latency för att arbeta x2000 
        gånger mer effektivt, perfekt för att segmentera, analysera och förstå stora volymer av 
        data samtidigt.`,
    t60: `Lägg till data i din vektor så att de arbetar för dig`,
    t61: `Gå till en vektor`,
    t7: `Skapa ny`,
    t8: `Lägg till ny`,
    t9: `Ny`,
    t10: `Skapa dina Addons för att skapa vektoriella komponenter i vilka representera`,
    t11: `Det finns inget addon, vill du skapa det`,
    t12: `Ny Addon`,
    t13: `Premium Addon`,
    t14: `Dataförbrukning`,
    t15: `Egenskaper`,
    t16: `Visa mer`,
    t17: `Noteringar`,
    t18: `Lägg till ny`,
    t19: `Säkra data`,
    t20: " RGPD - ISO 27001",
    t21: `du kan arbeta med anpassade vektorer.`,
    steps: {
        addCampaign: `Skapa kampanj`,
        addAdvice: `Skapa annonser`,
        t1: `Granskning av policy`,
        t2: `Impressioner`,
        t3: `Konverteringar`,
        title: `Alla diagnostiska resultat av kampanjen`,
        s1: `Kontostatus`,
        s2: `Faktureringsstatus`,
        s3: `Kampanjpolicys`,
        s4: `Spårning av konverteringar`,
        s5: `Kampanjbudget`,
        s6: `Budstrategi`,
        s7: `Kampanjstatus`,
        s8: `Annonsens effektivitet`,
        text1: `Den här kampanjen har inga annonser`,
        text2: `Lägg till annonser i annonsgrupper för att de ska publiceras`,
        text3: `Lös det`,
    },
    t22: {
        t0: `I`,
        t1: `vi använder`,
    },
    t23: {
        t0: `Snart tillgängligt`,
        t1: `Snart kommer det att vara tillgängligt, vi kan skicka en information till dig för
        pro`,
    },
    t24: {
        t0: `Ingen internetanslutning`,
        t1: `Kontrollera din Wi-Fi- eller mobildataanslutning, eller få en
        notifikation när anslutningen återställs`,
        t2: `Meddela mig`,
        t3: `Försök igen`,
    },
    t25: {
        t0: `Hittades inte`,
        t1: `🚀 Hittades inte`,
        t2: `Visa ändringar`,
    }
}

export default home;