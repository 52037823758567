const bank = {
    t1: `Gesamt Einnahmen`,
    t2: `Gesamt Ergebnis`,
    t3: `Verwalte deine Zugänge`,
    t4: `Suche eine Rechnung`,
    t5: `Suche ein Datum`,
    t6: `Meine Karte`,
    t7: `Karten Saldo`,
    t8: `Status`,
    t9: `Aktiv`,
    t10: `Verwalten`,
    t11: `Überweisen`,
    t12: `Teile und hole dir alle deine Kontakte, um deine Belohnung zu erhalten`,
    t13: `Sicherheit`,
    t14: `Aktive Karte`,
    t15: `Sicherer Zugang`,
    t16: `Ändern`,
    t17: `Informationen`,
    t18: `Ort`,
    t19: `Adresse`,
    t20: `Geldbörse`,

    cardlist: {
        "t0": "Karten",
        "t1": "Hinzufügen",
        "t2": "Füge deine Zahlungskarte sicher hinzu",
        "t3": "Sobald du deine Karte hinzugefügt hast, kannst du deine Zahlungen und aktiven Abonnements verwalten.",
        "t4": "Karte hinzufügen",
        "t5": "Gib die Details deiner Kreditkarte ein, um sicher zu zahlen.",
        "t6": "Mehr sehen",
        "t7": "Name",
        "t8": "Kartennummer",
        "t9": "CVC",
        "t10": "Ablauf",
        "t11": "Karte akzeptieren",
        "t12": "Abbrechen",
        "t13": "Eine neue Karte anfordern",
        "t14": "Du erhältst eine neue Karte",
        "t15": "Gesendet von Aythen",
        "t16": "Deutschland",
        "t17": "10903",
        "t18": "Santa Perpetua de Mogoda",
        "t19": "Das ist die Adresse, an die sie gesendet wird",
        "t20": "Das wird 10* kosten. Das wird 10* kosten. Das wird 10* kosten. Das wird 10* kosten.",
        "t21": "Karte anfordern"
    }
}

export default bank