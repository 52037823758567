
const pricing = {
    "t0": "Analise e explore suas métricas",
    "t1": "Sem surpresas, assim são nossos preços",
    "t2": "Experimente 7 dias grátis",
    "t3": "MAIS POPULAR",
    "t4": "149€",
    "t5": "/mês",
    "t6": "Negócios",
    "t7": "Empresas que necessitam de análise contínua segundo diferentes atores.",
    "t8": "5 usuários incluídos",
    "t9": "10 Add-ons visuais",
    "t10": "Dados vetoriais",
    "t11": "50 gráficos de dados",
    "t12": "100 perguntas ao dado",
    "t13": "200 cliques de HeatMap",
    "t14": "100GB de dados",
    "t15": "Suporte prioritário",
    "t16": "Videochamadas",
    "t17": "Motor de busca",
    "t18": "Escolha plano",
    "t19": "49€",
    "t20": "/mês",
    "t21": "Básico",
    "t22": "Direcionado a ideias emergentes",
    "t23": "que desejam analisar seus KPIs para definir estratégias.",
    "t24": "1 usuário incluído",
    "t25": "5 add-ons visuais",
    "t26": "Dados vetoriais",
    "t27": "10 gráficos de dados",
    "t28": "20 perguntas ao dado",
    "t29": "50 cliques de HeatMap",
    "t30": "5GB de dados",
    "t31": "Suporte por email",
    "t32": "Videochamadas",
    "t33": "Motor de busca",
    "t34": "Escolha plano",
    "t35": "349€",
    "t36": "/mês",
    "t37": "Start Up",
    "t38": "Para equipes de Start Ups dispostas a criar seus próprios análises a medida sem limites.",
    "t39": "15 usuários incluídos",
    "t40": "Add-ons ilimitados",
    "t41": "Dados vetoriais",
    "t42": "Gráficos ilimitados",
    "t43": "Perguntas ilimitadas",
    "t44": "500 cliques de HeatMap",
    "t45": "500GB de dados",
    "t46": "Suporte urgente",
    "t47": "Videochamadas",
    "t48": "Motor de busca",
    "t49": "Escolha plano",
    "t50": "GRÁTIS",
    "t51": "*7 dias",
    "t52": "Exploração",
    "t53": "Perfeito para começar com análises pontuais, sem poder criar a longo prazo.",
    "t54": "1 usuário incluído",
    "t55": "1 Add-on visual",
    "t56": "Dados vetoriais",
    "t57": "1 gráfico de dados",
    "t58": "5 perguntas ao dado",
    "t59": "Sem HeatMap",
    "t60": "500MB de dados",
    "t61": "Sem suporte",
    "t62": "Videochamadas",
    "t63": "Motor de busca",
    "t64": "Escolha plano",
    "t65": "Se você precisa de mais usuários e mais opções, contate nosso time de",
    "t66": "Vendas",
    "t67": "Compare entre os planos de pagamento",
    "t68": "E descubra qual se encaixa em suas necessidades",
    "t69": "Básico",
    "t70": "Comece",
    "t71": "Negócios",
    "t72": "Entenda",
    "t73": "Startup",
    "t74": "Analise",
    "t75": "CAPACIDADES E ACESSOS",
    "t76": "Usuários",
    "t77": "1",
    "t78": "5",
    "t79": "15",
    "t80": "Add-ons",
    "t81": "Ilimitados",
    "t82": "5",
    "t83": "10",
    "t84": "Dados vetoriais",
    "t85": "GB de dados",
    "t86": "Até 5 GBs",
    "t87": "Até 100 GBs",
    "t88": "Até 500 GBs",
    "t89": "Representação personalizada",
    "t90": "Número de Gráficos",
    "t91": "10",
    "t92": "50",
    "t93": "Ilimitados",
    "t94": "Número de perguntas",
    "t95": "20",
    "t96": "100",
    "t97": "Ilimitados",
    "t98": "Cliques com Mapa de Calor",
    "t99": "50",
    "t100": "200",
    "t101": "500",
    "t102": "Videochamadas e busca",
    "t103": "PERSONALIZAÇÃO E CÓDIGO",
    "t104": "Logo personalizado",
    "t105": "em breve",
    "t106": "Temas predeterminados",
    "t107": "Temas personalizados",
    "t108": "em breve",
    "t109": "Compartilhar com subdomínio",
    "t110": "Compartilhar com domínio",
    "t111": "em breve",
    "t112": "Acesso IAM e banco",
    "t113": "Utilização de Add-ons",
    "t114": "em breve",
    "t115": "Edição de prompts",
    "t116": "Acesso ao código fonte",
    "t117": "em breve",
    "t118": "Edição em tempo real",
    "t119": "em breve",
    "t120": "RELATÓRIOS",
    "t121": "Exportar PDF",
    "t122": "Envio por email",
    "t123": "Compartilhar por link",
    "t124": "SUPPORT",
    "t125": "Suporte por email",
    "t126": "Suporte prioritário",
    "t127": "Ajuda one-to-one",
    "t128": "E se eu precisar de mais para analisar?",
    "t129": "Você pode mudar de plano. Mas se isso escala demais suas necessidades, você pode escolher com base em mais gráficos, mais perguntas, mais add-ons para suas representações de interface, mais cliques, mais GBs de dados... Seus extras serão adicionados à sua cota existente. Você poderá cancelar a qualquer momento. E se você precisa de um plano personalizado, contate as vendas.",
    "t130": "Gráficos",
    "t131": "Pacote 10",
    "t132": "15€",
    "t133": "Perguntas",
    "t134": "Pacote 20",
    "t135": "10€",
    "t136": "Add-on",
    "t137": "Extra",
    "t138": "10€",
    "t139": "Mapa de calor",
    "t140": "Clique",
    "t141": "0.5€",
    "t142": "GBs de Cloud",
    "t143": "GB",
    "t144": "1€"
  }
  
  export default pricing
