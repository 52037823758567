const home = {
    banner: {
        banner: 'Modifica foto di copertina',
        edit: 'Modifica profilo',
        addUser: 'Aggiungi utente',
        title: 'utenti condivisi',
        viewTeam: 'Gestisci team',
        team: {
            title: `Persone che potresti conoscere`,
            button: `Vedi tutti`,
            addContact: `Aggiungi ai contatti`
        },
    },
    t1: `Organizzazione`,
    t2: `Banca`,
    t3: `Identificazione e accesso del manager (IAM)`,
    t4: `Accesso (IAM)`,
    t5: `Lavora e comprendi i tuoi dati come vettori`,
    t6: `Entra nella nuova dimensione del dato. Elimina la latenza per lavorare un x2000 
        volte più efficiente, perfetto per segmentare, analizzare e comprendere grandi volumi di 
        dati simultanei.`,
    t60: `Aggiungi dati al tuo vettore perché lavorino per te`,
    t61: `Vai a un vettore`,
    t7: `Crea nuovo`,
    t8: `Aggiungi nuovo`,
    t9: `Nuovo`,
    t10: `Crea i tuoi Addons per creare componenti vettoriali nei quali rappresentare`,
    t11: `Non esiste nessun addon vuoi crearlo`,
    t12: `New Addon`,
    t13: `Premium Addon`,
    t14: `Consumo di dati`,
    t15: `Caratteristiche`,
    t16: `Vedi più`,
    t17: `Note`,
    t18: `Aggiungi nuova`,
    t19: `Dati sicuri`,
    t20: " RGPD - ISO 27001",
    t21: `puoi lavorare con vettori personalizzati.`,
    steps: {
        addCampaign: `Crea campagna`,
        addAdvice: `Crea annunci`,
        t1: `Revisione del rispetto delle politiche`,
        t2: `Impressioni`,
        t3: `Conversioni`,
        title: `Tutti i diagnostic della campagna`,
        s1: `Stato dell'account`,
        s2: `Stato della fatturazione`,
        s3: `Politiche della campagna`,
        s4: `Tracciamento delle conversioni`,
        s5: `Budget della campagna`,
        s6: `Strategia di offerta`,
        s7: `Stato della campagna`,
        s8: `Efficacia dell'annuncio`,
        text1: `Questa campagna non ha annunci`,
        text2: `Aggiungi annunci ai gruppi di annunci perché vengano pubblicati`,
        text3: `Risolvi`,
    },
    t22: {
        t0: `In`,
        t1: `usiamo`,
    },
    t23: {
        t0: `Presto disponibile`,
        t1: `Presto sarà disponibile ti possiamo inviare una informazione per il
        pro`,
    },
    t24: {
        t0: `Nessuna connessione a Internet`,
        t1: `Controlla la tua connessione Wi-Fi o dati mobili, o ricevi una
        notifica quando torna la connessione`,
        t2: `Avvisami`,
        t3: `Riprova`,
    },
    t25: {
        t0: `Non trovato`,
        t1: `🚀 Non trovato`,
        t2: `Vedi modifiche`,
    }
}

export default home;