import React, { useRef, useEffect } from 'react'

const GhostVideo = ({ stream }) => {
	const videoRef = useRef(null)

	useEffect(() => {
		if (videoRef.current && stream) {
			videoRef.current.srcObject = stream
			videoRef.current.play()
		}
	}, [stream])

	return (
		<div style={{ display: 'none' }}>
			<video ref={videoRef} playsInline autoPlay muted />
		</div>
	)
}

export default GhostVideo
