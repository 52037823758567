import React from "react";

import styles from '../../index.module.css'

const Categories = ({ category }) => {
  return (
    <>
      <div className={`${styles.inner}`} >
        <ul className={`${styles["category-list"]}`} >
          {category.map((data, index) => (
            <li key={index}>
              <a href="#">
                <span className={styles["left-content"]} >{data.name}</span>
                <span className={`${styles['count-text']}`} >{data.num}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Categories;
