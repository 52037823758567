const VERSION = process.env.REACT_APP_VERSION;

const Icon1 = require(`@app/assets/steps/1.svg`).default;
const Icon2 = require(`@app/assets/steps/2.svg`).default;
const Icon3 = require(`@app/assets/steps/3.svg`).default;
const Icon4 = require(`@app/assets/steps/4.svg`).default;
const Icon5 = require(`@app/assets/steps/5.svg`).default;
const Icon6 = require(`@app/assets/steps/6.svg`).default;
const Icon7 = require(`@app/assets/steps/7.svg`).default;
const Icon8 = require(`@app/assets/steps/8.svg`).default;
const Icon9 = require(`@app/assets/steps/9.svg`).default;
const Icon10 = require(`@app/assets/steps/10.svg`).default;
const Icon11 = require(`@app/assets/steps/11.svg`).default;
const Icon12 = require(`@app/assets/steps/12.svg`).default;
const Icon13 = require(`@app/assets/steps/13.svg`).default;
const Icon14 = require(`@app/assets/steps/14.svg`).default;
const Icon15 = require(`@app/assets/steps/15.svg`).default;
const Icon16 = require(`@app/assets/steps/16.svg`).default;
const Icon17 = require(`@app/assets/steps/17.svg`).default;
const Icon18 = require(`@app/assets/steps/18.svg`).default;
const Icon19 = require(`@app/assets/steps/19.svg`).default;
const Icon20 = require(`@app/assets/steps/20.svg`).default;
const Icon21 = require(`@app/assets/steps/21.svg`).default;
const Icon22 = require(`@app/assets/steps/22.svg`).default;
const Icon23 = require(`@app/assets/steps/23.svg`).default;
const Icon24 = require(`@app/assets/steps/24.svg`).default;
const Icon25 = require(`@app/assets/steps/25.svg`).default;
const Icon26 = require(`@app/assets/steps/26.svg`).default;
const Icon28 = require(`@app/assets/steps/28.svg`).default;
const Icon29 = require(`@app/assets/steps/29.svg`).default;
const Icon30 = require(`@app/assets/steps/30.svg`).default;
const Icon31 = require(`@app/assets/steps/31.svg`).default;
const Icon32 = require(`@app/assets/steps/32.svg`).default;
const Icon33 = require(`@app/assets/steps/33.svg`).default;

const renderHelp = ({title, text, icon}) => {
    return (`<div class="steps-intro">
<img src=${icon} />
<div class="info">
<b>
${title} 
</b>
<p>
${text} 
</p>
</div>
<div class="buttons">
    <button onclick="introJs().nextStep()">
        Anterior
    </button>
    <button class="next" onclick="introJs().previousStep()">
        Siguiente
    </button >
</div>
</div>`)
}

export const home = [{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="0-1"]`,
    intro: renderHelp({
        icon: Icon1,
        title: `Il tuo primo accesso`,
        text: `Qui puoi gestire i dati del tuo account, accedere alla tua licenza, alle tue preferenze visive e uscire dalla sessione.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="0-2"]`,
    intro: renderHelp({
        icon: Icon2,
        title: `I tuoi primi dati`,
        text: `Cominciamo con qualcosa di semplice! In "Modifica account" potrai compilare e salvare i tuoi dati personali e cambiare il tema.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="0-3"]`,
    intro: renderHelp({
        icon: Icon3,
        title: `La tua amministrazione`,
        text: `Hai tutto il necessario per far progredire il tuo progetto da qualsiasi punto di vista. Il tuo inizio, i tuoi dati, documenti, attività, fatture e contratti.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-0"]`,
    intro: renderHelp({
        icon: Icon4,
        title: `Il tuo inizio > Copertina`,
        text: `Troverai un'immagine generale. Prima, del tuo profilo, delle organizzazioni che gestisci, accesso al tuo bilancio, ai tuoi accessi e ai tuoi domini.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-1"]`,
    intro: renderHelp({
        icon: Icon5,
        title: `Il tuo riepilogo > Design`,
        text: `Per i tuoi progetti e domini, avrai bisogno di design. Nei Add-on puoi creare ciò che vuoi. Così potrai osservare il consumo di risorse coinvolto.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-2"]`,
    intro: renderHelp({
        icon: Icon6,
        title: `Il tuo riepilogo > Dati`,
        text: `Per i tuoi progetti e design, avrai dati. Accedi ai tuoi database vettoriali e salva i grafici più interessanti.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-3"]`,
    intro: renderHelp({
        icon: Icon7,
        title: `Il tuo riepilogo > Stato`,
        text: `Aggiorna la tua memoria con le diverse funzionalità a portata di mano, lo stato della tua connessione e l'accesso al supporto.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="2-0"]`,
    intro: renderHelp({
        icon: Icon8,
        title: `Aythen DB > le tue analisi`,
        text: `Crea e sincronizza tutti i dati per creare dimensioni e strategie. Così sarai in grado di prendere decisioni informate grazie alle interazioni.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="2-0"]`,
    intro: renderHelp({
        icon: Icon9,
        title: `La tua documentazione`,
        text: `Aythen supporta qualsiasi formato di file. Trascina e rilascia i file desiderati. Condividili e categorizza la loro rilevanza.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="3-0"]`,
    intro: renderHelp({
        icon: Icon10,
        title: `Kan Ban > I tuoi compiti`,
        text: `Dovrai organizzare i tuoi compiti e il tuo team. Aggiungi ticket, elenchi di attività, priorità, stati e controlla lo stato generale degli avanzamenti.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="4-0"]`,
    intro: renderHelp({
        icon: Icon11,
        title: `Fatturazione elettronica (OCR)`,
        text: `Compila i tuoi dati aziendali e gestisci il tuo bilancio, programma promemoria di pagamento e configura i limiti della licenza.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icon: Icon12,
        title: `Firma elettronica (Audit trail)`,
        text: `Gestisci tutti i tuoi accordi grazie alla raccolta dei tuoi contratti. Firma, scarica o elimina quei documenti più sensibili.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icon: Icon13,
        title: `Il tuo primo progetto`,
        text: `Con tutto il ripasso della piattaforma. Crea un nuovo add-on (pubblico o privato). Seleziona le impostazioni e collega il tuo dominio. Salva e aprilo:`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icon: Icon14,
        title: `Il tuo primo progetto`,
        text: `Con tutto il ripasso della piattaforma. Crea un nuovo add-on (pubblico o privato). Seleziona le impostazioni e collega il tuo dominio. Salva e aprilo:`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-1"]`,
    intro: renderHelp({
        icon: Icon15,
        title: `Il tuo spazio creativo`,
        text: `Qui potrai costruire i tuoi flussi di design. Crea e connetti per creare schermate correlate. Vuoi sapere come iniziare?`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-2"]`,
    intro: renderHelp({
        icon: Icon16,
        title: `Crea e salva`,
        text: `Tutto il tuo flusso si basa su nodi intelligenti. Connetteranno le tue schermate e sincronizzeranno i tuoi dati. Crea il primo e salva la tua sessione.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-3"]`,
    intro: renderHelp({
        icon: Icon17,
        title: `Organizza la tua vista`,
        text: `Preferisci griglie? Punti? Tutto ordinato? Vuoi sapere sempre dove si trova ogni nodo? Questi 3 pulsanti ti aiuteranno.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-4"]`,
    intro: renderHelp({
        icon: Icon18,
        title: `Controlla la tua vista`,
        text: `Ingrandisci per perfezionare i dettagli o per ottenere una visione d'insieme della tua creazione. Questo influenzerà le risorse utilizzate.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-5"]`,
    intro: renderHelp({
        icon: Icon19,
        title: `Controlla la tua creazione`,
        text: `Esporta o importa i tuoi design e analizza dove gli utenti sono più attivi grazie alle mappe di calore. Così potrai correlare con criterio.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-6"]`,
    intro: renderHelp({
        icon: Icon20,
        title: `Aggiungi e sincronizza`,
        text: `Aggiungi contenuti al tuo design, caricandoli automaticamente su GitHub o condividendoli con terzi. Tutto ciò di cui hai bisogno per creare senza limiti.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-7"]`,
    intro: renderHelp({
        icon: Icon21,
        title: `Hai il tuo primo flusso?`,
        text: `Potrebbe non essere tutto perfetto per il consumatore. Perciò, puoi fare doppio clic o "Alt" + clic sul nodo che hai bisogno di perfezionare.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-8"]`,
    intro: renderHelp({
        icon: Icon22,
        title: `Il tuo spazio creativo individuale`,
        text: `Qui potrai concentrarti sulla costruzione del tuo design. Crea con codice e collega nuovi dati o dati esistenti per creare schermate correlate.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-9"]`,
    intro: renderHelp({
        icon: Icon23,
        title: `Accedi al tuo codice`,
        text: `Modifica e salva in tempo reale. Con un effetto specchio, potrai modificare e visualizzare il design per apportare qualsiasi modifica a tuo piacimento.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-10"]`,
    intro: renderHelp({
        icon: Icon24,
        title: `Sincronizza i tuoi dati`,
        text: `Troverai diversi metodi per salvare e collegare i tuoi contenuti ai tuoi database. Se hai dubbi, controlla i nostri comandi.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-11"]`,
    intro: renderHelp({
        icon: Icon25,
        title: `Accessi rapidi`,
        text: `Torna indietro con tutti i nodi, fai zoom in questo spazio individuale o fai clic a destra per controllare la vista dal browser.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-12"]`,
    intro: renderHelp({
        icon: Icon26,
        title: `Lavora in modo efficiente`,
        text: `Crea tutte le tue modifiche contemporaneamente con la doppia schermata interna per accedere rapidamente a tutte le funzionalità disponibili.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-0"]`,
    intro: renderHelp({
        icon: Icon28,
        title: `La tua prima analisi`,
        text: `Qui potrai costruire i tuoi flussi di dati. Sia esterni che sincronizzati con il design. Crea interazioni e prendi decisioni informate.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-1"]`,
    intro: renderHelp({
        icon: Icon29,
        title: `Trova i tuoi dati`,
        text: `Cerca, seleziona i tuoi nodi con le schede, salva la tua versione, scarica e ordina il tuo flusso attraverso le diverse funzionalità.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-2"]`,
    intro: renderHelp({
        icon: Icon30,
        title: `Interagisci con i tuoi dati`,
        text: `Per prendere decisioni informate, dovrai fare domande, creare grafici o relazioni di matrici tramite Python / Javascript.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-3"]`,
    intro: renderHelp({
        icon: Icon31,
        title: `Gestisci il tuo nodo`,
        text: `Ogni nodo intelligente può essere configurato in modi diversi. La sua flessibilità per qualsiasi dato e formato è per qualsiasi scopo.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-4"]`,
    intro: renderHelp({
        icon: Icon32,
        title: `Attenzione ai dettagli`,
        text: `Potrai vedere e modificare il nome. Così potrai sempre cercarlo per trovare i tuoi dati. A destra avrai quando è stato caricato e quanto spazio occupa.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-5"]`,
    intro: renderHelp({
        icon: Icon33,
        title: `Configura i tuoi dati`,
        text: `Fai backup con le versioni, incorpora dati da terze parti, genera una domanda o espandi il nodo per vedere tutti i dati.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-6"]`,
    intro: renderHelp({
        icon: Icon1,
        title: `Anteprima dei tuoi dati`,
        text: `Nella finestra del nodo potrai vedere tutti i dati che hai nel tuo spazio per comprendere rapidamente cosa è e con cosa può essere correlato.`
    })
}]


export default home


