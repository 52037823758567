import { createSlice } from '@reduxjs/toolkit'
import {
	deleteAllNotifications,
	deleteNotification,
	getUserNotifications,
	readAllNotifications,
	readNotification,
} from '../actions/notifications'

const notificationsSlices = createSlice({
	name: 'notifications',
	initialState: {
		userNotifications: [],
		pendingNotifications: 0,
	},
	reducers: {
		setUserNotifications: (state, action) => {
			state.userNotifications = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getUserNotifications.pending, (state) => {
				state.loading = true
			})
			.addCase(getUserNotifications.fulfilled, (state, action) => {
				state.loading = false
				state.userNotifications = action.payload
				state.pendingNotifications = action.payload.filter(
					(not) => !not.readed
				).length
			})
			.addCase(getUserNotifications.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(deleteNotification.pending, (state) => {
				state.loading = true
			})
			.addCase(deleteNotification.fulfilled, (state, action) => {
				state.loading = false
				state.userNotifications = action.payload
				state.pendingNotifications = action.payload.filter(
					(not) => !not.readed
				).length
			})
			.addCase(deleteNotification.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(deleteAllNotifications.pending, (state) => {
				state.loading = true
			})
			.addCase(deleteAllNotifications.fulfilled, (state, action) => {
				state.loading = false
				state.userNotifications = action.payload
				state.pendingNotifications = action.payload.filter(
					(not) => !not.readed
				).length
			})
			.addCase(deleteAllNotifications.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(readNotification.pending, (state) => {
				state.loading = true
			})
			.addCase(readNotification.fulfilled, (state, action) => {
				state.loading = false
				state.userNotifications = action.payload
				state.pendingNotifications = action.payload.filter(
					(not) => !not.readed
				).length
			})
			.addCase(readNotification.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(readAllNotifications.pending, (state) => {
				state.loading = true
			})
			.addCase(readAllNotifications.fulfilled, (state, action) => {
				state.loading = false
				state.userNotifications = action.payload
				state.pendingNotifications = action.payload.filter(
					(not) => !not.readed
				).length
			})
			.addCase(readAllNotifications.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
	},
})

export const { setUserNotifications } = notificationsSlices.actions

export default notificationsSlices.reducer
