const vector = {
    t1: `Crea un nuovo vettore per il tuo progetto`,
    t2: `Importa, salva, lavora, analizza e trae conclusioni da qualsiasi struttura di dati. 
Memorizza e manipola le informazioni con totale libertà attraverso spazi multidimensionali. `,
    t3: `Nuovo vettore`,
    t4: `Vettori`,
    t5: `Vettore`,
    t6: `Dimensione`,
    t7: `Dimensioni`,
    t9: `Creato il`,
    t10: `Matrice`,
    t11: `Le basi di dati vettoriali consentono di creare esperienze uniche guidate dalla ricerca vettoriale.  
Accelerano lo sviluppo di applicazioni di intelligenza artificiale.`,
    t12: `Nuova Matrice`,
    t13: `Documentazione di avvio rapido`,
    node: {
        data: {
            title: `Aggiungi dati al tuo rapporto`,
            text: `Una volta caricati, i tuoi dati appariranno nel`,
            label: `Campi`,
            excel: `Importa dati da Excel`,
            sql: `Importa dati da SQL`,
            blank: `Incolla dati in bianco`,
            export: `Esporta dati di esempio`,
            info: `Ottieni dati da un'altra fonte`,
        }
    },
    dashboard: {
        t1: `Ricorda, potrai sempre modificare e raccogliere i grafici che desideri evidenziare 
come preferiti a partire dalle informazioni che avrai deciso di analizzare nei vettori.`,
        t2: `Saperne di più`,
    }
}

export default vector