import { createSlice } from "@reduxjs/toolkit"

import {
  addVector,
  deleteVector,
  duplyVector,
  fetchsVector,
  fetchVector,
  addVectorMatrixVersion,
  fetchsMatrixVersion,
  deleteMatrixVersion,

  addI18Addon,
  fetchsI18Addon,

  codeAddon,
  codeFetch,
  codeDelete,
  codeInsertAddon
} from "../actions/vector"

const vectorSlice = createSlice({
  name: "vector",
  initialState: {
    vector: null,
    vectors: [],

    versionMatrix: {},

    // --------------------------------
    status: 'saved',
    i18: null,
    codes: null,

    addon: {},
    addons: [],
    templates: [],
    connections: [],
    components: [],

    infoResume: {
      template: 10,
      component: 10,
      management: 14,
      token: 20
    },
    codes: {
      type: 'web',
      total: 0,
      items: [],
      item: {}
    }
  },
  reducers: {

    setVector: (state, action) => {
      state.vector = action.payload
    },
    setData: (state, action) => {
      state.data = action.payload
    },
    setMatrix: (state, action) => {
      state.matrix = action.payload
    },
    setDimension: (state, action) => {
      state.dimension = action.payload
    },

    // ---------------------------
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setAddon: (state, action) => {
      state.addon = action.payload
    },
    setTemplates: (state, action) => {
      state.templates = action.payload
    },
    setElement: (state, action) => {
      state.element = action.payload
    },
    setConnections: (state, action) => {
      state.connections = action.payload
    },
    setCode: (state, action) => {
      state.code = action.payload
    },
    setCodeType: (state, action) => {
      state.codes.type = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchsVector.fulfilled, (state, action) => {
        state.vectors = action.payload
      })
      .addCase(fetchVector.fulfilled, (state, action) => {
        state.vector = action.payload
      })

      .addCase(addVector.fulfilled, (state, action) => {
        state.vector = {
          ...action.payload,
          nodes: JSON.parse(action.payload.nodes),
          edges: JSON.parse(action.payload.edges),
        }
      })

      .addCase(deleteVector.fulfilled, (state, action) => {
        const { id } = action.payload

        state.vectors = state.vectors.filter((vector) => vector.id !== id)

        state.vector = null
      })
      .addCase(duplyVector.fulfilled, (state, action) => {
        state.vectors.push(action.payload)
      })

      .addCase(deleteMatrixVersion.fulfilled, (state, action) => {
        const deletedId = action.payload
        state.versionMatrix = state.versionMatrix.filter(
          (item) => item.id !== deletedId,
        )
      })
      .addCase(addVectorMatrixVersion.fulfilled, (state, action) => {
        if (action.payload) {
          state.versionMatrix.push(action.payload)
        }
      })
      .addCase(fetchsMatrixVersion.fulfilled, (state, action) => {
        state.versionMatrix = action.payload
      })


      // ---------------------------------------------------

      .addCase(fetchsI18Addon.fulfilled, (state, action) => {
        if (action.payload?.id) {
          if (!action.payload.text.store) {
            state.i18[action.payload.id] = action.payload.text
          }
        }
      })
      .addCase(addI18Addon.fulfilled, (state, action) => {
        if (action.payload?.id) {

          state.i18[action.payload.id] = action.payload.text
        }
      })

      .addCase(codeAddon.fulfilled, (state, action) => {
        state.codes.items.push(action.payload);
      })
      .addCase(codeFetch.fulfilled, (state, action) => {
        state.codes.items = action.payload;
      })
      .addCase(codeDelete.fulfilled, (state, action) => {
        state.codes.items = state.codes.items.filter(item => item.id !== action.payload.id);
      })
      .addCase(codeInsertAddon.fulfilled, (state, action) => {
      })


  },
})

export const {
  setVector,
  setData,
  setMatrix,
  setDimension,

  // ---------------------
  setStatus,
  setAddon,
  setTemplates,
  setElement,
  setConnections,
  setCode,
  setCodeType
} =
  vectorSlice.actions

export default vectorSlice.reducer
