const VERSION = process.env.REACT_APP_VERSION;

const Icon1 = require(`@app/assets/steps/1.svg`).default;
const Icon2 = require(`@app/assets/steps/2.svg`).default;
const Icon3 = require(`@app/assets/steps/3.svg`).default;
const Icon4 = require(`@app/assets/steps/4.svg`).default;
const Icon5 = require(`@app/assets/steps/5.svg`).default;
const Icon6 = require(`@app/assets/steps/6.svg`).default;
const Icon7 = require(`@app/assets/steps/7.svg`).default;
const Icon8 = require(`@app/assets/steps/8.svg`).default;
const Icon9 = require(`@app/assets/steps/9.svg`).default;
const Icon10 = require(`@app/assets/steps/10.svg`).default;
const Icon11 = require(`@app/assets/steps/11.svg`).default;
const Icon12 = require(`@app/assets/steps/12.svg`).default;
const Icon13 = require(`@app/assets/steps/13.svg`).default;
const Icon14 = require(`@app/assets/steps/14.svg`).default;
const Icon15 = require(`@app/assets/steps/15.svg`).default;
const Icon16 = require(`@app/assets/steps/16.svg`).default;
const Icon17 = require(`@app/assets/steps/17.svg`).default;
const Icon18 = require(`@app/assets/steps/18.svg`).default;
const Icon19 = require(`@app/assets/steps/19.svg`).default;
const Icon20 = require(`@app/assets/steps/20.svg`).default;
const Icon21 = require(`@app/assets/steps/21.svg`).default;
const Icon22 = require(`@app/assets/steps/22.svg`).default;
const Icon23 = require(`@app/assets/steps/23.svg`).default;
const Icon24 = require(`@app/assets/steps/24.svg`).default;
const Icon25 = require(`@app/assets/steps/25.svg`).default;
const Icon26 = require(`@app/assets/steps/26.svg`).default;
const Icon28 = require(`@app/assets/steps/28.svg`).default;
const Icon29 = require(`@app/assets/steps/29.svg`).default;
const Icon30 = require(`@app/assets/steps/30.svg`).default;
const Icon31 = require(`@app/assets/steps/31.svg`).default;
const Icon32 = require(`@app/assets/steps/32.svg`).default;
const Icon33 = require(`@app/assets/steps/33.svg`).default;

const renderHelp = ({ title, text, icon }) => {
    return (`<div class="steps-intro">
<img src=${icon} />
<div class="info">
<b>
${title} 
</b>
<p>
${text} 
</p>
</div>
<div class="buttons">
    <button onclick="introJs().nextStep()">
        Anterior
    </button>
    <button class="next" onclick="introJs().previousStep()">
        Siguiente
    </button >
</div>
</div>`)
}





export const home = [{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="0-1"]`,
    intro: renderHelp({
        icon: Icon1,
        title: `Ваш первый доступ`,
        text: `Здесь вы сможете управлять данными своей учетной записи, получить доступ к лицензии, изменить свои визуальные предпочтения и выйти из сеанса.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="0-2"]`,
    intro: renderHelp({
        icon: Icon2,
        title: `Ваши первые данные`,
        text: `Давайте начнем с чего-то простого! В разделе "Редактировать учетную запись" вы заполните и сохраните свои личные данные, а также измените тему.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="0-3"]`,
    intro: renderHelp({
        icon: Icon3,
        title: `Ваше управление`,
        text: `Здесь у вас есть все необходимое, чтобы ваш проект двигался вперед с любой точки зрения. Ваш старт, ваши данные, документы, задачи, счета и контракты.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-0"]`,
    intro: renderHelp({
        icon: Icon4,
        title: `Ваш старт > Обзор`,
        text: `Здесь вы найдете общую картину. Сначала ваш профиль, организации, которыми вы управляете, доступ к вашему балансу, вашим доступам и доменам.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-1"]`,
    intro: renderHelp({
        icon: Icon5,
        title: `Ваше резюме > Дизайн `,
        text: `Ваши проекты и домены требуют дизайна. В дополнениях вы можете создавать что угодно. Так вы сможете наблюдать за потреблением ресурсов.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-2"]`,
    intro: renderHelp({
        icon: Icon6,
        title: `Ваше резюме > Данные`,
        text: `Ваши проекты и дизайны содержат данные. Получите доступ к ваши векторизованным базам данных и сохраните наиболее интересные графики.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-3"]`,
    intro: renderHelp({
        icon: Icon7,
        title: `Ваше резюме > Статус`,
        text: `Обновите свою память о различных функциях, доступных вам, состоянии вашего подключения и доступе к поддержке.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="2-0"]`,
    intro: renderHelp({
        icon: Icon8,
        title: `База данных Aythen > ваши анализы`,
        text: `Создавайте и синхронизируйте все данные для создания измерений и стратегий. Так вы сможете принимать обоснованные решения благодаря взаимодействиям.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="2-0"]`,
    intro: renderHelp({
        icon: Icon9,
        title: `Ваша документация`,
        text: `Aythen поддерживает любой формат файла. Перетащите и отпустите нужные файлы. Делитесь ими и категоризируйте их по значимости.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="3-0"]`,
    intro: renderHelp({
        icon: Icon10,
        title: `Kan Ban > Ваши задачи`,
        text: `Вам нужно организовать свои задачи и команду. Добавляйте билеты, списки задач, приоритеты, состояния и отслеживайте общий прогресс.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="4-0"]`,
    intro: renderHelp({
        icon: Icon11,
        title: `Электронная фактура (OCR)`,
        text: `Заполните свои бизнес-данные и управляйте своим балансом, настройте напоминания о платежах и настройте свои лицензионные ограничения.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icon: Icon12,
        title: `Электронная подпись (Audit trail)`,
        text: `Управляйте всеми своими соглашениями с помощью сбора ваших контрактов. Подписывайте, загружайте или удаляйте самые чувствительные документы.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icon: Icon13,
        title: `Ваш первый проект`,
        text: `После обзора платформы создайте новое дополнение (публичное или частное). Выберите настройки и свяжите свой домен. Сохраните и откройте его:`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icon: Icon14,
        title: `Ваш первый проект`,
        text: `После обзора платформы создайте новое дополнение (публичное или частное). Выберите настройки и свяжите свой домен. Сохраните и откройте его:`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-1"]`,
    intro: renderHelp({
        icon: Icon15,
        title: `Ваше творческое пространство`,
        text: `Здесь вы сможете создавать свои потоки дизайна. Создавайте и соединяйте, чтобы создавать последовательные экраны. Хотите узнать, с чего начать?`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-2"]`,
    intro: renderHelp({
        icon: Icon16,
        title: `Создавайте и сохраняйте`,
        text: `Весь ваш процесс основан на умных узлах. Они будут соединять ваши экраны и синхронизировать ваши данные. Создайте первый и сохраните вашу сессию.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-3"]`,
    intro: renderHelp({
        icon: Icon17,
        title: `Организуйте свой вид`,
        text: `Вы предпочитаете сетки? Точки? У вас все аккуратно упорядочено? Хотите знать, где находится каждый узел в любое время? Эти 3 кнопки вам помогут.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-4"]`,
    intro: renderHelp({
        icon: Icon18,
        title: `Контролируйте свой вид`,
        text: `Масштабируйте для доработки деталей или для получения общего представления о вашем творении. Это потребует разных ресурсов в зависимости от использования.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-5"]`,
    intro: renderHelp({
        icon: Icon19,
        title: `Контролируйте свое творение`,
        text: `Экспортируйте или импортируйте свои дизайны и анализируйте, где пользователи наиболее активны, благодаря картам тепла. Так вы сможете делать обоснованные выводы.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-6"]`,
    intro: renderHelp({
        icon: Icon20,
        title: `Добавляйте и синхронизируйте`,
        text: `Добавьте контент к вашему дизайну, автоматически загрузите его на GitHub или поделитесь им с другими. Все, что вам нужно для бесконечного творчества.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-7"]`,
    intro: renderHelp({
        icon: Icon21,
        title: `У вас есть ваш первый поток?`,
        text: `Возможно, что не все устраивает потребителя. Поэтому вы можете дважды щелкнуть или нажать "Alt" + щелкнуть по нужному узлу для настройки.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-8"]`,
    intro: renderHelp({
        icon: Icon22,
        title: `Ваше индивидуальное творческое пространство`,
        text: `Здесь вы сможете сфокусироваться на создании дизайна. Создавайте с помощью кода и соединяйте новые или существующие данные, чтобы создавать последовательные экраны. `
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-9"]`,
    intro: renderHelp({
        icon: Icon23,
        title: `Доступ к вашему коду`,
        text: `Редактируйте и сохраняйте в реальном времени. С зеркальным эффектом вы сможете редактировать и видеть дизайн для внесения любых изменений по своему усмотрению.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-10"]`,
    intro: renderHelp({
        icon: Icon24,
        title: `Синхронизация ваших данных`,
        text: `Вы найдете различные методы сохранения и связывания вашего контента с вашими базами данных. Если у вас возникнут вопросы, проверьте наши команды.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-11"]`,
    intro: renderHelp({
        icon: Icon25,
        title: `Быстрые доступы`,
        text: `Вернитесь назад со всеми узлами, увеличьте масштаб в этом индивидуальном пространстве или щелкните справа для просмотра его из браузера.`
    })
}, {
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-12"]`,
    intro: renderHelp({
        icon: Icon26,
        title: `Работайте эффективно`,
        text: `Создавайте все ваши модификации одновременно с помощью внутреннего двойного экрана, чтобы иметь быстрый доступ ко всем доступным функциям.`
    })
}, {
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-0"]`,
    intro: renderHelp({
        icon: Icon28,
        title: `Ваш первый анализ`,
        text: `Здесь вы сможете создавать свои потоки данных.Как внешние, так и синхронизированные с дизайном.Создавайте взаимодействия и принимайте обоснованные решения.`
    })
}, {
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-1"]`,
    intro: renderHelp({
        icon: Icon29,
        title: `Найдите ваши данные`,
        text: `Ищите, выбирайте ваши узлы с помощью вкладок, сохраняйте вашу версию, загружайте и упорядочивайте свой поток с помощью различных функций.`
    })
}, {
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-2"]`,
    intro: renderHelp({
        icon: Icon30,
        title: `Взаимодействуйте с вашими данными`,
        text: `Для принятия обоснованных решений вам понадобится задавать вопросы, строить графики или отношения матрицы с помощью Python / JavaScript.`
    })
}, {
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-3"]`,
    intro: renderHelp({
        icon: Icon31,
        title: `Управляйте вашим узлом`,
        text: `Каждый умный узел настраивается по- разному.Его гибкость в любых данных и форматах подходит для любой цели.`
    })
}, {
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-4"]`,
    intro: renderHelp({
        icon: Icon32,
        title: `Обратите внимание на все детали`,
        text: `Вы сможете просматривать и изменять имя.Так вы всегда сможете найти его для поиска ваших данных.Справа вы увидите, когда он был загружен, и сколько он занимает.`
    })
}, {
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-5"]`,
    intro: renderHelp({
        icon: Icon33,
        title: `Настройте ваши данные`,
        text: `Создавайте резервные копии с версиями, добавляйте данные от третьих лиц, создавайте вопросы или раскрывайте узел, чтобы увидеть все данные.`

    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-6"]`,
    intro: renderHelp({
        icon: Icon1,
        title: `Предварительный просмотр ваших данных`,
        text:
            `В окне узла вы сможете видеть все данные, которые у вас есть, чтобы быстро понять, что это и с чем это может быть связано.`
    })
}]


export default home