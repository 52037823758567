import apiBackend from '@src/apiBackend'
import { createAsyncThunk } from '@reduxjs/toolkit';


import {
  setStatus
} from '../slices/utilizeSlice'



export const generateUtilize =
  createAsyncThunk('utilize/generate',
    async ({ lng, image, text }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const token_gpt = localStorage.getItem('token-gpt')
        const resp = await apiBackend.post(`/utilize/${lng}/generate`, {
          image,
          text,
          token: token_gpt
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );


export const addUtilize =
  createAsyncThunk('utilize/add',
    async ({ id, lng }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(`/utilize/${lng}`, {
          id
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );



export const updateUtilize =
  createAsyncThunk('utilize/update',
    async ({ lng, id, order, ref, data }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.put(`/utilize/${lng}`, {
          id,
          order,
          ref,
          data
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );





export const deleteUtilize =
  createAsyncThunk('utilize/delete',
    async ({ id }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.delete(`/utilize/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );




export const fetchUtilize =
  createAsyncThunk('utilize/fetchUtilize',
    async ({ ref, lng }) => {
      try {
        const token = localStorage.getItem('token')

        const res = await apiBackend.get(`/utilize/${lng}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params: {
            ref: ref
          }
        }
        )


        return res.data
      } catch (error) {
        console.log('Error note:', error)
      }
    })




// ----------------------------------------------------
export const addMenu =
  createAsyncThunk('utilize/add/menu',
    async ({ id }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(`/utilize/menu/${lng}`, {
          id
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );



export const updateMenu =
  createAsyncThunk('utilize/update/menu',
    async ({ id, lng, data }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.put(`/utilize/${lng}/menu`, {
          id,
          data
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );





export const deleteMenu =
  createAsyncThunk('utilize/delete/menu',
    async ({ id }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.delete('/utilize/menu', {
          id: id
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );




export const fetchMenu =
  createAsyncThunk('utilize/fetchUtilize/menu',
    async ({ lng }) => {
      try {
        const token = localStorage.getItem('token')

        const res = await apiBackend.get(
          `/utilize/${lng}/menu`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        )


        return res.data
      } catch (error) {
        console.log('Error note:', error)
      }
    })





    export const pdfUtilize = 
    createAsyncThunk('utilize/pdfUtilize', 
      async ({ lng }) => {
      try {
        const token = localStorage.getItem('token');
    
        const res = await apiBackend.post(`/utilize/${lng}/pdf`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          responseType: 'blob', 
        });

    
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = 'document-aythen.pdf'; 
        document.body.appendChild(a); 
        a.click(); 
        a.remove(); 
    
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.log('Error note:', error);
      }
    });



export const downloadUtilize =
  createAsyncThunk('utilize/downloadUtilize',
    async ({ lng }) => {
      try {
        const token = localStorage.getItem('token')

        const res = await apiBackend.get(
          `/utilize/${lng}/download`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        )


        return res.data
      } catch (error) {
        console.log('Error note:', error)
      }
    })




export const uploadUtilize = createAsyncThunk(
  'utilize/uploadUtilize',
  async ({ files, lng }) => {
    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();

      files.forEach((file) => {
        formData.append('files[]', file);
      });
      
      const res = await apiBackend.post(`/utilize/${lng}/upload`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      return res.data;
    } catch (error) {
      throw error; 
    }
  }
);


export const deleteAllUtilize = createAsyncThunk(
  'utilize/deleteAllUtilize',
  async ({ lng }) => {
    try {
      const token = localStorage.getItem('token');

      const res = await apiBackend.post(`/utilize/${lng}/delete`, {
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      return res.data;
    } catch (error) {
      console.log('Error utilize:', error);
      throw error; // Lanza el error para manejarlo en el slice de Redux
    }
  }
);




