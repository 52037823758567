const drive = {
    t1: `Buscar documentação`,
    t2: `Criar novo`,
    t3: `Nova pasta`,
    t4: `Novo lanceDB`,
    t5: `Enviar arquivo`,
    t6: `Enviar pasta`,
    t7: `Usar`,
    t8: `Meus documentos`,
    t9: `Meus documentos`,
    t10: `Em breve`,
    t11: `Prioritários`,
    t12: `Compartilhados`,
    t13: `Recentes`,
    t14: `Destacados`,
    t15: `Lixeira`,
    t16: `Lixeira`,
    t17: `Um momento...`,
    t18: `Cancelar`,
    t19: `Criar`,

    x1: `Nome`,
    x2: `Última modificação`,
    x3: `Selecionar todos`,
    x4: `Remover todos`,
    x5: `Arquivos recentes`,
    x6: `LanceDB`,
    x7: `Ver DB`,
    x8: `Ver drive`,
    x9: `Projeto`,
    x10: `Nome`,
    x11: `Nome`,
    x12: `Tamanho`,
    x13: `Última modificação`,
    x14: `Elementos selecionados`,
    x15: `Compartilhado com`,
}

export default drive