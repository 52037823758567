import React from "react";
import { PositionProvider } from "./PositionContext";
import Map from "./Map";
import Joystick from "./Joystick";

const App = () => {
  return (
    <PositionProvider>
      <Map />
      <Joystick />
    </PositionProvider>
  );
};

export default App;
