// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeBarContainer_yQis1 {
	width: 100%;
	height: 15px;
	border-radius: 20px;
	display: flex;
	cursor: pointer;
	-webkit-user-select: none;
	        user-select: none;
	align-items: center;
	justify-content: flex-start;
	border: 1px solid var(--color-primary-2);
	position: relative;
}

.passedBar_mlqPm {
	height: 100%;
	background-color: var(--color-primary-6);
	transition: width 0.3s ease;
	position: relative;
}

.remainingBar_ULO4z {
	height: 100%;
	background-color: var(--color-primary-4);
	transition: width 0.3s ease;
	position: relative;
}

.tooltip_OkRV5 {
	visibility: hidden;
	background-color: #333;
	color: var(--background);
	text-align: center;
	padding: 5px;
	border-radius: 5px;
	position: absolute;
	top: -30px;
	left: 100%;
	transform: translateX(-100%);
	z-index: 1;
	font-size: 12px;
	white-space: nowrap;
}

.passedBar_mlqPm:hover .tooltip_OkRV5,
.remainingBar_ULO4z:hover .tooltip_OkRV5 {
	visibility: visible;
}
`, "",{"version":3,"sources":["webpack://./src/views/app/v1-1/pages/Calendar/components/ProgressBar.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,YAAY;CACZ,mBAAmB;CACnB,aAAa;CACb,eAAe;CACf,yBAAiB;SAAjB,iBAAiB;CACjB,mBAAmB;CACnB,2BAA2B;CAC3B,wCAAwC;CACxC,kBAAkB;AACnB;;AAEA;CACC,YAAY;CACZ,wCAAwC;CACxC,2BAA2B;CAC3B,kBAAkB;AACnB;;AAEA;CACC,YAAY;CACZ,wCAAwC;CACxC,2BAA2B;CAC3B,kBAAkB;AACnB;;AAEA;CACC,kBAAkB;CAClB,sBAAsB;CACtB,wBAAwB;CACxB,kBAAkB;CAClB,YAAY;CACZ,kBAAkB;CAClB,kBAAkB;CAClB,UAAU;CACV,UAAU;CACV,4BAA4B;CAC5B,UAAU;CACV,eAAe;CACf,mBAAmB;AACpB;;AAEA;;CAEC,mBAAmB;AACpB","sourcesContent":[".timeBarContainer {\n\twidth: 100%;\n\theight: 15px;\n\tborder-radius: 20px;\n\tdisplay: flex;\n\tcursor: pointer;\n\tuser-select: none;\n\talign-items: center;\n\tjustify-content: flex-start;\n\tborder: 1px solid var(--color-primary-2);\n\tposition: relative;\n}\n\n.passedBar {\n\theight: 100%;\n\tbackground-color: var(--color-primary-6);\n\ttransition: width 0.3s ease;\n\tposition: relative;\n}\n\n.remainingBar {\n\theight: 100%;\n\tbackground-color: var(--color-primary-4);\n\ttransition: width 0.3s ease;\n\tposition: relative;\n}\n\n.tooltip {\n\tvisibility: hidden;\n\tbackground-color: #333;\n\tcolor: var(--background);\n\ttext-align: center;\n\tpadding: 5px;\n\tborder-radius: 5px;\n\tposition: absolute;\n\ttop: -30px;\n\tleft: 100%;\n\ttransform: translateX(-100%);\n\tz-index: 1;\n\tfont-size: 12px;\n\twhite-space: nowrap;\n}\n\n.passedBar:hover .tooltip,\n.remainingBar:hover .tooltip {\n\tvisibility: visible;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timeBarContainer": `timeBarContainer_yQis1`,
	"passedBar": `passedBar_mlqPm`,
	"remainingBar": `remainingBar_ULO4z`,
	"tooltip": `tooltip_OkRV5`
};
export default ___CSS_LOADER_EXPORT___;
