import React from 'react'

import DragNavImage from './assets/drag-nav.png'

import Card1Image from './assets/img-1.png'
import Card2Image from './assets/img-2.png'
import Card3Image from './assets/img-3.png'
import Card4Image from './assets/img-4.png'
import Card6Image from './assets/img-6.png'

const BlockChain = () => {
  return (
    <div className="relative w-full sm:h-full lg:pb-[180px]" id="blockchain">
      <div className="container mx-auto">
        <section className="home-section-1 ">
          <div className="flex">
            <div className="relative lg:pt-[10px] z-[0]">
              <div className="mb-5 flex items-center ml-[-8px] absolute left-0 top-[10px] w-[300px]">
                <div className="bg-[#32D1C8] z-[-1]  w-[40px] h-[40px] flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="55"
                    fill="none"
                  >
                    <path
                      d="M26.9993 23L23.9993 24.75V28.25L26.9993 30L29.9993 28.25V24.75L26.9993 23ZM28.5163 25.04L26.9863 25.932L25.4693 25.047L26.9993 24.155L28.5163 25.04ZM24.9893 25.922L26.4733 26.788V28.538L24.9893 27.673V25.922ZM27.4633 28.575V26.808L29.0093 25.906V27.673L27.4633 28.575ZM17.9993 15L14.9993 16.75V20.25L17.9993 22L20.9993 20.25V16.75L17.9993 15ZM19.5163 17.04L17.9863 17.932L16.4693 17.047L17.9993 16.155L19.5163 17.04ZM15.9893 17.921L17.4733 18.787V20.537L15.9893 19.674V17.921ZM18.4633 20.574V18.808L20.0093 17.906V19.673L18.4633 20.574ZM17.9993 32L14.9993 33.75V37.25L17.9993 39L20.9993 37.25V33.75L17.9993 32ZM19.5163 34.04L17.9863 34.932L16.4693 34.047L17.9993 33.155L19.5163 34.04ZM15.9893 34.922L17.4733 35.788V37.538L15.9893 36.674V34.922ZM18.4633 37.575V35.808L20.0093 34.906V36.673L18.4633 37.575ZM35.9993 15L32.9993 16.75V20.25L35.9993 22L38.9993 20.25V16.75L35.9993 15ZM37.5163 17.04L35.9863 17.932L34.4693 17.047L35.9993 16.155L37.5163 17.04ZM33.9893 17.922L35.4733 18.788V20.538L33.9893 19.673V17.922ZM36.4633 20.575V18.808L38.0093 17.906V19.673L36.4633 20.575ZM35.9993 32L32.9993 33.75V37.25L35.9993 39L38.9993 37.25V33.75L35.9993 32ZM37.5163 34.04L35.9863 34.932L34.4693 34.047L35.9993 33.155L37.5163 34.04ZM33.9893 34.922L35.4733 35.788V37.538L33.9893 36.673V34.922ZM36.4633 37.575V35.808L38.0093 34.906V36.673L36.4633 37.575ZM23.9993 18H29.9993V19H23.9993V18ZM23.9993 35H29.9993V36H23.9993V35ZM18.4993 24V30H17.4993V24H18.4993ZM22.2923 23.828L20.1713 21.707L20.8783 21L22.9993 23.12L22.2923 23.828ZM30.9993 23.293L33.1203 21.172L33.8273 21.879L31.7063 24L30.9993 23.293ZM23.1273 29.879L21.0063 32L20.2993 31.293L22.4203 29.172L23.1273 29.879ZM31.8783 29.129L33.9993 31.25L33.2923 31.957L31.1713 29.836L31.8783 29.129ZM36.4993 24V30H35.4993V24H36.4993Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="ml-4">
                  <h4 className="text-[#32D1C8] font-extrabold uppercase text-[20px]">
                    BLOCKCHAIN
                  </h4>
                </div>
              </div>
              <div className="blockchain-left-border mt-[70px]"></div>
            </div>
            <div>
              <div className="w-full flex md:mt-[100px]">
                <div className="lg:pl-10 lg:pr-20">
                  <p className="text-[20px] text-[#554D56] lg:text-[48px] leading-tight drop-shadow-xl text-left pl-7 lg:pl-0">
                    <span className="text-[#32D1C8]">
                      Datos descentralizados y seguros.
                    </span>
                    Crea contratos inteligentes para realizar cualquier
                    transacción segura entre cualquier dato, desarrolla nuevas
                    funcionalidades de transacción de valores en tu proyecto.
                  </p>
                </div>
                <div>
                  <img
                    src={DragNavImage}
                    className="lg:w-[500px] w-[70px]"
                  />
                </div>
              </div>

              <div className="lg:mt-[50px]">
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3">
                  <div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mb-8 lg:mb-0">
                      <p className="mt-0 lg:text-[13px] uppercase">
                        PRIMER BENEFICIO
                      </p>
                      <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                        Transparencia y Auditoría
                      </h5>
                      <div className="lg:w-[90%]">
                        <div className="lg:flex items-start">
                          <p className="lg:text-[15px] mt-5 lg:w-[60%]">
                            Blockchain registra todas las transacciones y eventos
                            de manera inmutable y transparente. Esto permite a
                            todas las partes interesadas acceder a un registro
                            exacto y verificable de todas las actividades y
                            cambios realizados en el proyecto, lo que facilita la
                            auditoría y la rendición de cuentas.
                          </p>
                          <div className=" relative lg:absolute lg:right-[-100px] lg:top-[100px] z-[10]">
                            <img
                              src={Card1Image}
                              className="w-[100%] z-[10]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mt-5 mb-8 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          Cuarto Beneficio
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Reducción de Fraudes y Errores
                        </h5>
                      </div>
                      <div className="lg:w-[90%]">
                        <p className="lg:text-[15px]">
                          La inmutabilidad y la transparencia de los registros en blockchain disminuyen el riesgo de fraudes y errores. Las partes involucradas pueden verificar de manera independiente la autenticidad y la validez de los datos.
                        </p>
                      </div>
                      <img
                        src={Card4Image}
                        className="lg:absolute bottom-[0px] right-[0%] w-[200px] lg:w-[50%] z-20"
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mb-8 lg:mb-0">
                      <p className="mt-0 lg:text-[13px] uppercase">
                        SEGUNDO BENEFICIO
                      </p>
                      <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                        Seguridad Mejorada
                      </h5>
                      <div className="lg:w-[90%]">
                        <p className="lg:text-[15px]">
                          La estructura de datos en cadena de bloques, junto con la criptografía avanzada, ofrece un alto nivel de seguridad. Los datos almacenados en blockchain están protegidos contra manipulación y acceso no autorizado, lo que garantiza la confiabilidad de la información.
                        </p>
                      </div>
                      <img
                        src={Card2Image}
                        className="lg:absolute lg:bottom-[-40px] lg:right-[-50px] z-20 w-[200px] lg:w-[50%]"
                      />
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] relative mt-5 mb-8 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          Quinto Beneficio
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Reducción de la Burocracia
                        </h5>
                      </div>
                      <div className="lg:w-[80%]">
                        <p className="lg:text-[15px]">
                          La confiabilidad y la inmutabilidad de los registros en blockchain pueden reducir la necesidad de documentación y papeleo redundante. Esto agiliza la aprobación de procesos y la formalización de contratos.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="relative md:col-span-2 lg:col-span-1 md:grid grid-cols-2 lg:block gap-4">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 relative h-[auto] lg:h-[450px] mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          Tercer beneficio
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Contratos Inteligentes
                        </h5>
                        <p className="lg:text-[15px] mt-3">
                          Los contratos inteligentes son programas informáticos autoejecutables que se activan automáticamente cuando se cumplen ciertas condiciones predefinidas. Estos contratos eliminan la necesidad de intermediarios y automatizan la ejecución de acuerdos, lo que agiliza los procesos y reduce la posibilidad de errores humanos.
                        </p>
                      </div>
                      <img
                        src={Card3Image}
                        className="lg:absolute lg:bottom-[-120px] right-[0px] z-20 w-[200px] lg:w-[70%]"
                      />
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative  mt-5 mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          SEXTO beneficio
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Registro de Historial Completo
                        </h5>
                        <p className="lg:text-[15px] mt-3 lg:w-[50%]">
                          El blockchain mantiene un historial completo y verificable de todas las etapas y cambios del proyecto. Esto es valioso para la revisión de proyectos anteriores, la mejora de procesos y la toma de decisiones informadas.
                        </p>
                      </div>
                      <img
                        src={Card6Image}
                        className="z-20 lg:absolute bottom-[0px] right-[-50px] w-[200px] lg:w-[70%]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default BlockChain
