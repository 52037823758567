const bank = {
    t1: `Totaal Inkomsten`,
    t2: `Totaal Resultaat`,
    t3: `Beheer uw toegangen`,
    t4: `Zoek een factuur`,
    t5: `Zoek een datum`,
    t6: `Mijn Kaart`,
    t7: `Kaartsaldo`,
    t8: `Status`,
    t9: `Actief`,
    t10: `Beheren`,
    t11: `Overmaken`,
    t12: `Deel en krijg al uw contacten aangesloten om uw beloning te ontvangen`,
    t13: `Beveiliging`,
    t14: `Actieve kaart`,
    t15: `Veilig toegang`,
    t16: `Wijzigen`,
    t17: `Informatie`,
    t18: `Locatie`,
    t19: `Adres`,
    t20: `Portemonnee`,

    cardlist: {
        "t0": "Kaarten",
        "t1": "Toevoegen",
        "t2": "Voeg uw betaalkaart veilig toe",
        "t3": "Zodra u uw kaart heeft toegevoegd, kunt u uw betalingen en actieve abonnementen beheren.",
        "t4": "Kaart toevoegen",
        "t5": "Voer de details van uw creditcard in om veilig te betalen.",
        "t6": "Meer zien",
        "t7": "Naam",
        "t8": "Kaartnummer",
        "t9": "CVC",
        "t10": "Verval",
        "t11": "Kaart accepteren",
        "t12": "Annuleren",
        "t13": "Vraag een nieuwe kaart aan",
        "t14": "U ontvangt een nieuwe kaart",
        "t15": "Verzonden vanaf Aythen",
        "t16": "Spanje",
        "t17": "10903",
        "t18": "Santa Perpetua de Mogoda",
        "t19": "Dit is het adres waar u de kaart naar toe gestuurd krijgt",
        "t20": "Dit zal een kosten van 10* Dit zal een kosten van Dit zal een kosten van Dit zal een kosten van",
        "t21": "Kaart aanvragen"
    }
}

export default bank