import { createSlice } from '@reduxjs/toolkit';



import {
  fetchMenu,
    deleteMenu,
    updateMenu,
    addMenu,
    fetchUtilize,
    deleteUtilize,
    updateUtilize,
    addUtilize,
    generateUtilize,
} from '../actions/utilize'



const utilizeSlice = createSlice({
  name: 'utilize',
  initialState: {
    menu: null,
    utilize: null,

    status: '',
  },
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteMenu.fulfilled, (state, action) => {

      })
      .addCase(updateMenu.fulfilled, (state, action) => {

      })
      .addCase(addMenu.fulfilled, (state, action) => {

      })
      .addCase(deleteUtilize.fulfilled, (state, action) => {

      })
      .addCase(updateUtilize.fulfilled, (state, action) => {

      })
      .addCase(addUtilize.fulfilled, (state, action) => {

      })
      .addCase(generateUtilize.fulfilled, (state, action) => {

      })
  },
});

export const {
    setStatus
} = utilizeSlice.actions;

export default utilizeSlice.reducer;












