import React, { useEffect, useRef } from 'react'
import styles from './OutlinedInput.module.css'

const OutlinedInput = ({
	icon,
	placeholder,
	state,
	setState,
	handler,
	setFocused,
	ref,
	px,
	focusedOnRender,
}) => {
	const inputRef = useRef()

	useEffect(() => {
		if (focusedOnRender) {
			inputRef.current.focus()
		}
	}, [])

	return (
		<div className={styles.inputContainer}>
			{icon && (
				<div style={{ marginRight: px && '1px' }} className={styles.icon}>
					{icon}
				</div>
			)}
			<input
				onFocus={() => setFocused && setFocused(true)}
				onBlur={() => setFocused && setFocused(false)}
				className={styles.input}
				placeholder={placeholder}
				ref={focusedOnRender ? inputRef : ref}
				value={state}
				onChange={(e) => setState(e.target.value)}
			/>
		</div>
	)
}

export default OutlinedInput
