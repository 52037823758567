 const web = {
    t1: {
        '01': `Entiende los`,
        '02': `datos`,
        '03': `de tu`,
        '04': `negocio`,
        '05': ` y automatiza tu`,
        '06': `big data`,
        '07': `para que funcione a cualquier`,
        '08': `realidad`,
        '09': `digital`,
    },
    t2: `INICIAR SESION`,
    t3: `Accede a la wishlist`,
    t4: {
        '01': `Offline`, 
        '02': `Online`, 
        '03': `Público`, 
        '04': `Privado`, 
    },
    tassistant: {
        '01': `Velocidad`,
        '02': `Textura`,
        '03': `Procesamiento`,
    },
    t5: `Todo un mundo de posibilidades`,
    t6: {
        '01': `Gracias a las`,
        '02': `bases de datos vectorizadas`,
        '03': `pasas de `,
        '04': `datos estáticos`,
        '05': `a`,
        '06': `datos dinámicos.`,
        '07': `Trabajar con muchos datos de forma `,
        '08': `simultánea`,
        '09': `es difícil, por eso es necesaria una`,
        '10': `latencia nula en unas dimensiones`,
        '11': `para un acceso rápido y versátil.`,
    },
    t7: `Entra ahora`,
    t8: `AHORRA TIEMPO . AHORRA TIEMPO . AHORRA TIEMPO`,
    t9: `Genera tus propias conclusiones`,
    t10: `La información es poder`,
    t11: {
        '01': `¡Ahora puedes entender tus datos!`,
        '02': `A partir de la segmentación, con el concepto`,
        '03': `K-Means`,
        '04': `tienes una herramienta para analizar a medida cualquier dato.`,
    },
    t12: `Empieza ahora`,
    t13: `De espacio disponible en la nube. Todo escalable en instancias
    privadas y seguras.`,
    t14: `veces más rápido que trabajando con bases de datos
    tradicionales.`,
    t15: `Representaciones posibles para importar tus métricas y tus KPIs.`,
    t16: `Analiza datos entre dimensiones`,
    t17: `Cada dimensión tiene agentes.`,
    t18: {
        '01': `En cada dimensión puedes crear`,
        '02': `flujos de agentes`,
        '03': `para crear redes de interacciones.`
    },
    t19: {
        '01': `En cada agente escogerás la`,
        '02': `estrategia`,
        '03': `para relacionar`,
        '04': `cada dato.`,
        '05': `cada dato.`,
    },
    t20: `Empieza tu prueba GRATUITA`,
    t21: `Dimensiones`,
    t22: `Agentes`,
    t23: `Datos que \ngestionan ideas`,
    t24: `Tus datos el primer paso`,
    t25: {
        '01': `Son tu llave en mano para construir`,
        '02': `aplicaciones`,
        '03': `asombrosas. No solo son información`,
        '04': `son la materia prima`,
        '05': `para la creación de`,
        '06': `innovadoras ideas y sus análisis.`,
        '07': `¿Cómo trabajamos los datos?`,
    },
    t26: `Ayhten Calendar`,
    t27: `Organiza y planifica todas tus tareas en un calendario inteligente`,
    t28: `Aythen DB`,
    t29: `Almacena datos en la nube para generar flujos de información de manera colaborativa. `,
    t30: `Aythen OCR`,
    t31: `Reconoce tus facturas electronicas con AI para agilizar la contabilidad de tu negocio`,
    t32: `Aythen Trail`,
    t33: `Firma y traza cualquier documento o acuerdo para crear lazos de confianza`,
    t34: `Aythen View`,
    t35: `Visualiza tus datos en tablas inteligentes y modifica cualquier estado de información`,
    t36: `Aythen Node`,
    t37: `Tu espacio tridimensional donde ordenar tus flujos a medida tanto para frontend y backend`,
    t38: `Aythen Vision`,
    t39: `Mapea el trafico de tus usuarios a través de eventos y analisis de calores `,
    t40: `Aythen Matrix`,
    t41: `Edita y programa a medida el código fuente de tu aplicación y lanza tu proyecto rápidamente.`,
    t42: `Ayhten BI`,
    t43: `IAM, Roles, Apps, Dashboards, Gráficas y preguntas sincronizadas en tu sistema`,
    t44: `Aprende a hacerlo, es muy fácil`,
    t45: `Cambia de dimensiones`,
    t46: {
        '01': `Haz tus datos inteligentes, conectables, consistentes, seguros,
        eficientes y accesibles. Potencia tus análisis a través de`,
        '02': `nuestro acceso.`
    },
    t47: {
        '01': `Empieza con `,
        '02': `el tutorial`,
        '03': `para entender las
        diferentes partes de la plataforma.`,
    },
    t48: `Regístrate AHORA`,
    t49: `Valora tu tiempo y automatiza tus datos`,
    t50: {
        '01': `Y empieza a cambiar de dimensiones en nuestro sistema
        operativo en la nube. Analiza y entiende patrones para`,
        '02': `trabajar tus datos.`,
    },
    footer: {
        t00: "INICIO",
        t01: "Tu equipo",
        t02: "Updates",
        t03: "Kit Digital",
        t04: "Assesorate",
        t05: "Estudiar",

        t10: "ENLACES",
        t11: "Equipo",
        t12: "Autónomos",
        t13: "Términos",
        t14: "Características",
        t15: "Calculadora",
        t16: "Planes",

        t20: "AI MODULE",
        t21: "Voice",
        t22: "Image & Video",
        t23: "Creación de contenido",
        t24: "Creación de medios",

        t30: "SUPPPORT AYTHEN",
        t31: "Acuerdo del Usuario",
        t32: "Política de Privacidad",
        t33: "Recopilación y Uso de Datos",
        t34: "Directrices de Contenido",

        t40: "PARA DISEÑAR",
        t41: "Editor",
        t42: "Apps",
        t43: "Cloud",
        t44: "Plugins",
        t45: "RPA",

        t50: "PARA ENCONTRAR",
        t51: "Library",
        t52: "Pressets",
        t53: "Devops",
        t54: "IDE",
        t55: "Sandbox",

        t60: "PARA CONSTRUIR",
        t61: "UX/AY",
        t62: "Translate",
        t63: "Docs AI",
        t64: "Support AI",
        t65: "Collabs",

        t70: "PARA PROTEGER",
        t71: "Blockchain",
        t72: "Data",
        t73: "Pivot",
        t74: "Academy",
        t75: "Freelancers",
    }
}

export default web