import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styles from './CreateMeetingModal.module.css'
import { HiOutlineUserGroup, HiOutlineLocationMarker } from 'react-icons/hi'
import {
	MdOutlineCheckBox,
	MdOutlineCheckBoxOutlineBlank,
} from 'react-icons/md'
import OutlinedInput from './OutlinedInput'
import { IoTimeOutline } from 'react-icons/io5'
import CustomDatePicker from './CustomDatePicker'
import { IoMdClose } from 'react-icons/io'
import megaphone from '../assets/meetingCard/megaphone.svg'
import { createMeet } from '../../../actions/meet'
import { createEvent } from '../../../actions/calendar'
import { CalendarContext } from '../../../components/CalendarContext'
import { ChatContext } from '../ChatContext'
import { generateRandomId } from '../../Calendar/utils'
import { setModal, setNotification } from '../../../slices/iamSlice'
import { capitalizeFirstLetter } from '../../Calendar/utils'
import { sendFriendRequest } from '../../../actions/friends'
import { FaUserFriends } from 'react-icons/fa'
import { sendNotification } from '../../../actions/notifications'
import CustomDescriptionInput from '../../Calendar/components/CustomDescriptionInput'

const CreateMeetingModal = () => {
	const { handleSendInvitationEmail } = useContext(CalendarContext)
	const { setIsCreateMeetModalOpen: setShowMeetModal } = useContext(ChatContext)
	const { generateInvitationId, getDateData, getReminderHour } =
		useContext(CalendarContext)
	const { user } = useSelector((state) => state.iam)
	const { userFriends, receivedFriendRequests, sentFriendRequests } =
		useSelector((state) => state.friends)
	const { userCalendars } = useSelector((state) => state.calendar)
	const [calendarToMergeWith, setCalendarToMergeWith] = useState(() => {
		const defaultCalendar = userCalendars.filter(
			(cal) => cal.id === `calendar_${user.id}_default`
		)[0]
		return defaultCalendar
			? defaultCalendar.calendarName
			: userCalendars[0]?.calendarName
	})
	const [syncWithCalendar, setSyncWithCalendar] = useState(false)

	function getHour() {
		const currentTime = new Date()
		let currentHour = currentTime.getHours()
		const currentMinute = currentTime.getMinutes()

		if (currentMinute >= 30) {
			currentHour++
		}

		currentHour = currentHour % 12
		if (currentHour === 0) {
			currentHour = 12
		}

		return currentHour < 10 ? `0${currentHour}` : `${currentHour}`
	}
	function getMinutes() {
		const currentTime = new Date()
		let currentMinute = currentTime.getMinutes()

		if (currentMinute <= 30) {
			currentMinute = '30'
		} else {
			currentMinute = '00'
		}
		return currentMinute
	}
	const { user: userData } = useSelector((state) => state.iam)
	const { allUsers } = useSelector((state) => state.meet)
	const [isInputFocused, setIsInputFocused] = useState(false)
	const [searchValue, setSearchValue] = useState('')
	const [hour, setHour] = useState(getHour())
	const [minutes, setMinutes] = useState(getMinutes())
	const tags = ['Evento']
	const [title, setTitle] = useState('')
	const [type, setType] = useState()
	const [location, setLocation] = useState()
	const [description, setDescription] = useState('')
	const [attachment, setAttachment] = useState()
	const [reminder, setReminder] = useState(false)
	const [hourType, setHourType] = useState(
		new Date().getHours() > 12 ? 'PM' : 'AM'
	)
	const [filteredAllUsers, setFilteredAllUsers] = useState()
	const [groupMembersNames, setGroupMembersNames] = useState([])
	const [groupMembers, setGroupMembers] = useState([])
	const [selectedDate, setSelectedDate] = useState(new Date())



	const dispatch = useDispatch()

	useEffect(() => {
		const allUsersFiltered = allUsers.filter((user) => user.id !== userData.id)
		if (searchValue === '') {
			setFilteredAllUsers(allUsersFiltered)
			return
		}
		if (searchValue) {
			setFilteredAllUsers(
				allUsersFiltered.filter((chat) =>
					chat.user
						.split('@')[0]
						.toLowerCase()
						.includes(searchValue.toLowerCase())
				)
			)
		}
	}, [searchValue])

	const addMemberToGroup = (id, name) => {
		const newArr = [...groupMembers, id]
		const uniqueObjects = {}
		const actualArray = [...groupMembersNames, { name, id }]

		for (const obj of actualArray) {
			uniqueObjects[obj.id] = obj
		}
		setGroupMembers([...new Set(newArr)])
		setGroupMembersNames(Object.values(uniqueObjects))
	}

	const outlinedInputRef = useRef(null)
	const usersCardsContainerRef = useRef(null)

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				outlinedInputRef.current &&
				!outlinedInputRef.current.contains(event.target)
			) {
				if (
					usersCardsContainerRef.current &&
					!usersCardsContainerRef.current.contains(event.target)
				) {
					setIsInputFocused(false)
				}
			}
		}
		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	const transformDate = (dateString) => {
		const months = {
			enero: '01',
			febrero: '02',
			marzo: '03',
			abril: '04',
			mayo: '05',
			junio: '06',
			julio: '07',
			agosto: '08',
			septiembre: '09',
			octubre: '10',
			noviembre: '11',
			diciembre: '12',
		}

		const parts = dateString.split(' ')

		const day = parts[1].replace(',', '')
		const month = months[parts[3]]
		const year = parts[5]

		return `${day}/${month}/${year}`
	}

	const getDateFullData = (dateString) => {
		const date = new Date(dateString)

		const monthsInSpanish = [
			'Ene',
			'Feb',
			'Mar',
			'Abr',
			'May',
			'Jun',
			'Jul',
			'Ago',
			'Sep',
			'Oct',
			'Nov',
			'Dic',
		]

		const daysInSpanish = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb']

		const month = monthsInSpanish[date.getMonth()]
		const dayNumber = date.getDate()
		const day = daysInSpanish[date.getDay()]
		const year = date.getFullYear()

		const completeDate = `${day} ${month} ${dayNumber}, ${year}`

		return {
			month,
			dayNumber,
			day,
			completeDate,
		}
	}

	const formatTimeRange = (startTime, minutesToAdd) => {
		const [time, period] = startTime.split(' ')
		let [hours, minutes] = time.split(':').map(Number)

		if (period.toLowerCase() === 'pm' && hours !== 12) {
			hours += 12
		} else if (period.toLowerCase() === 'am' && hours === 12) {
			hours = 0
		}

		const startDate = new Date()
		startDate.setHours(hours)
		startDate.setMinutes(minutes)

		const endDate = new Date(startDate.getTime() + minutesToAdd * 60000)
		let endHours = endDate.getHours()
		let endMinutes = endDate.getMinutes()

		let endPeriod = endHours >= 12 ? 'pm' : 'am'

		if (endHours > 12) {
			endHours -= 12
		} else if (endHours === 0) {
			endHours = 12
		}

		let startHours = hours
		let startPeriod = period.toLowerCase()
		if (startHours === 0) {
			startHours = 12
		} else if (startHours > 12) {
			startHours -= 12
		}

		const formattedStartMinutes = String(minutes).padStart(2, '0')
		const formattedEndMinutes = String(endMinutes).padStart(2, '0')

		const formattedTimeRange = `${startHours}:${formattedStartMinutes}${startPeriod} - ${endHours}:${formattedEndMinutes}${endPeriod}`

		return formattedTimeRange
	}

	const timeRange = formatTimeRange('12:00 AM', 30)

	const handleCreateEvent = (meetId, meetDate, meetHour) => {
		const selectedCalendar = userCalendars.filter(
			(cal) => cal.calendarName === calendarToMergeWith
		)[0]
		const eventsDuration = selectedCalendar.eventsDuration || 30
		const remindBefore = selectedCalendar.calendarEventsReminder || 10
		const joinId = generateInvitationId()
		const dateData = getDateFullData(selectedDate)
		const reminderHour = getReminderHour(
			meetHour.split(' ').join('').toLowerCase(),
			remindBefore
		)
		const usersToInvite = allUsers
			.filter((user) => groupMembers.includes(user.id))
			.map((u) => {
				return { user: u.user, id: u.id }
			})
		const eventData = {
			type: 'meet',
			date: transformDate(meetDate),
			title: title,
			description: description,
			meetId,
			hour: formatTimeRange(meetHour, eventsDuration),
			organizer: userData.user,
			timeZone: selectedCalendar.timezone || '(GMT+01:00) España - Madrid',
			participants:
				groupMembers.length > 0
					? [userData.id, ...groupMembers]
					: [userData.id],
			invitedUsers:
				groupMembers.length > 0
					? [userData.id, ...groupMembers]
					: [userData.id],
			joinId,
			reminder: reminder ? reminderHour : null,
		}
		dispatch(
			createEvent({
				userId: userData.id,
				calendarId: selectedCalendar.id,
				eventData,
			})
		)
	}


	const handleCreateMeet = async () => {
		try {
			const usersToInvite = allUsers
				.filter((user) => groupMembers.includes(user.id))
				.map((u) => {
					return { user: u.user, id: u.id }
				})
			const meetHour = `${hour || '00'}:${minutes || '00'} ${hourType}`
			const meetDate = new Intl.DateTimeFormat('es-ES', {
				weekday: 'long',
				year: 'numeric',
				month: 'long',
				day: 'numeric',
			}).format(selectedDate)
			const reminderHour = getReminderHour(
				meetHour.split(' ').join('').toLowerCase(),
				30
			)
			const joinId = generateRandomId()

			const res = await dispatch(
				createMeet({
					meetName: title,
					description,
					meetHour,
					meetDate,
					type: 'meet',
					reminder,
					location,
					attachments: [],
					usersData: usersToInvite,
					syncWithCalendar: syncWithCalendar,
					syncedWith: syncWithCalendar ? calendarToMergeWith : '',
					participantsIds:
						groupMembers.length > 0
							? [userData.id, ...groupMembers]
							: [userData.id],
					creatorId: userData.id,
					joinId,
				})
			)


			await dispatch(
				sendNotification({
					userIds: groupMembers,
					notification: {
						type: 'meetInvititation',
						message: `${user.user.split('@')[0]} te ha invitado a unirte al meet ${title}`,
					},
				})
			)

			const meetId = res.payload.id
			const dateData = getDateFullData(selectedDate)

			const usersToSendInvite = allUsers.filter((user) =>
				groupMembers.includes(user.id)
			)

			usersToSendInvite.forEach((invitedUser) => {
				const dataToEmailWith = {
					month: dateData.month,
					dayNumber: dateData.dayNumber,
					day: dateData.day,
					senderName: user.user.split('@')[0],
					reminderHour,
					receiverName: invitedUser.user.split('@')[0],
					timezone: '(GMT+01:00) España - Madrid',
					receiverEmail: invitedUser.user,
					eventName: title,
					location: 'Aythen meet',
					completeDate: dateData.completeDate,
					joinId: joinId,
					shareId: '',
					senderEmail: user.user,
					description: `${description}`,
					participants: usersToSendInvite.map(
						(user) => user.user.split('@')[0]
					),
					hour: meetHour,
				}
				handleSendInvitationEmail(dataToEmailWith)
			})

			if (syncWithCalendar && calendarToMergeWith) {
				handleCreateEvent(meetId, meetDate, meetHour)
			}

			dispatch(
				setNotification({
					status: 300,
					text: 'Se ha creado el meet exitosamente!',
				})
			)
		} catch (error) {
			console.error('Error creating meet:', error)

			dispatch(
				setNotification({
					status: 300,
					text: 'Hubo un error en la creación del meet.',
				})
			)
		} finally {
			setShowMeetModal(false)
			dispatch(setModal())
		}
	}

	const handleOverlayClick = (event) => {
		if (event.target === event.currentTarget) {
			setShowMeetModal(false)
		}
	}

	return (
		<div className={styles.modalContainerWrapper}>
			<div className={styles.modalContainer}>
				<OutlinedInput
					focusedOnRender={true}
					placeholder={'Agregar título'}
					state={title}
					setState={setTitle}
				/>
				<div className={styles.dateTimeContainer}>
					<div className={styles.topBarIcon}>
						<IoTimeOutline size={25} color='#787878' />
					</div>
					<div className={styles.dateTimeDiv}>
						{capitalizeFirstLetter(
							new Intl.DateTimeFormat('es-ES', {
								weekday: 'long',
								year: 'numeric',
								month: 'long',
								day: 'numeric',
							}).format(selectedDate)
						)}
					</div>
					<div
						style={{ marginLeft: 'auto' }}
						className={styles.dateTimeDiv}
					>{`${hour || '00'}:${minutes || '00'} ${hourType}`}</div>
				</div>
				<div className={styles.addContainer}>
					<div className={styles.reminderContainer}>
						<img
							src={megaphone}
							width={21.9}
							height={22.9}
							objectFit='cover'
							objectPosition='center'
						/>
						<div className={styles.reminderRight}>
							{!reminder ? (
								<div
									style={{ marginTop: '3px', cursor: 'pointer' }}
									onClick={() => setReminder(true)}
								>
									<MdOutlineCheckBoxOutlineBlank size={23} color={'#D9D9D9'} />
								</div>
							) : (
								<div
									style={{ marginTop: '3px', cursor: 'pointer' }}
									onClick={() => setReminder(false)}
								>
									<MdOutlineCheckBox
										size={23}
										color={`var(--color-primary-2)`}
									/>
								</div>
							)}
							<div
								className={
									reminder ? styles.reminderTextOn : styles.reminderTextOff
								}
							>
								Enviar recordatorio
							</div>
						</div>
					</div>
				</div>
				<div className={styles.membersCardsContainer}>
					{groupMembersNames.map((item, index) => (
						<div className={styles.memberCard} key={index}>
							{item.name}
							<IoMdClose
								onClick={() => {
									setGroupMembers(
										groupMembers.filter((member) => member !== item.id)
									)
									setGroupMembersNames(
										groupMembersNames.filter((member) => member.id !== item.id)
									)
								}}
								style={{ cursor: 'pointer' }}
								size={15}
								color='#6d6d6d'
							/>
						</div>
					))}
				</div>
				<div className={styles.userSelectWrapper}>
					<div className={styles.inputContainer}>
						<div className={styles.icon}>
							<HiOutlineUserGroup size={24} color={`var(--text-color)`} />
						</div>
						<input
							onFocus={() => setIsInputFocused(true)}
							className={styles.inputCreate}
							placeholder={'Añade invitados'}
							ref={outlinedInputRef}
							value={searchValue}
							onChange={(e) => setSearchValue(e.target.value)}
						/>
						<div
							className={
								searchValue.includes('@') &&
								searchValue.includes('.com') &&
								!groupMembersNames.some((member) => member.name === searchValue)
									? styles.addButton
									: styles.disabledAddButton
							}
							onClick={() => {
								if (
									searchValue.includes('@') &&
									searchValue.includes('.com') &&
									!groupMembersNames.some(
										(member) => member.name === searchValue
									)
								) {
									addMemberToGroup(generateRandomId(), searchValue)
									setSearchValue('')
								} else {
									console.error('Please enter a valid email address.')
								}
							}}
						>
							Añadir
						</div>
					</div>
					{isInputFocused && (
						<div ref={usersCardsContainerRef} className={styles.usersContainer}>
							{searchValue.length > 0 && filteredAllUsers.length === 0 && (
								<div className={styles.notFound}>
									No se encontraron usuarios relacionados con tu busqueda!
								</div>
							)}
							{filteredAllUsers?.map((data, index) => (
								<div
									key={index}
									onClick={() => {
										if (data.user) {
											addMemberToGroup(data.id, data.user)
											setIsInputFocused(false)
										}
									}}
									className={styles.userCardContainer}
								>
									<div className={styles.defaultProfile}>
										{data?.user && data?.user[0]}
									</div>
									<div className={styles.userInfoContainer}>{data.user}</div>
									{userFriends?.includes(data.id) && (
										<FaUserFriends size={15} color={`var(--color-primary-3)`} />
									)}
									{!userFriends.includes(data.id) &&
										!sentFriendRequests.some(
											(inv) => inv.receiverId === data.id
										) && (
											<div
												onClick={(e) => {
													e.stopPropagation()
													dispatch(
														sendFriendRequest({
															userId: user.id,
															receiverId: data.id,
														})
													)
												}}
												className={styles.addFriend}
											>
												Añadir amigo
											</div>
										)}
									{!userFriends.includes(data.id) &&
										sentFriendRequests.some(
											(inv) => inv.receiverId === data.id
										) && (
											<div className={styles.addedFriend}>
												Invitacion enviada
											</div>
										)}
								</div>
							))}
						</div>
					)}
				</div>
				<OutlinedInput
					icon={
						<HiOutlineLocationMarker
							style={{ marginRight: '1px' }}
							size={24}
							color={`var(--text-color)`}
						/>
					}
					placeholder={'Añade ubicación'}
					state={location}
					setState={setLocation}
					px={true}
				/>
				<CustomDescriptionInput
					fromMeet={true}
					attachment={attachment}
					setAttachment={setAttachment}
					description={description}
					setDescription={setDescription}
				/>
				<div
					onClick={() => setSyncWithCalendar((prev) => !prev)}
					className={syncWithCalendar ? styles.synced : styles.meetContainer}
				>
					<svg
						width='27'
						height='27'
						viewBox='0 0 222 133'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
						style={{
							zIndex: 30,
							fill: syncWithCalendar
								? 'var(--text-color)'
								: 'var(--color-primary-2)',
						}}
					>
						<path
							d='M20.0248 0.222384C16.9731 2.92022 13.6417 6.22923 10.3175 10.2326C5.75094 15.7259 2.41954 21.0529 0 25.5951C17.9286 25.2117 31.77 25.313 38.887 25.6168C40.5436 25.6891 43.3555 25.8627 46.3091 27.4864C50.1455 29.5912 52.0309 32.8857 53.7602 35.9091C55.2788 38.5671 56.1471 40.9612 56.6448 42.6175C46.567 64.4967 36.4892 86.3724 26.4115 108.252H54.6212L70.806 72.9808L94.6308 124.048C95.223 125.299 96.9086 128.446 100.611 130.695C104.229 132.894 107.873 132.908 109.758 132.919C117.358 132.966 128.9 133.071 143.356 132.926C146.585 129.831 150.229 125.878 153.811 120.974C157.383 116.085 160.035 111.43 162 107.427C144.932 107.467 131.821 107.387 124.824 107.366C123.756 107.362 121.565 107.333 119.051 106.302C113.969 104.219 111.506 99.6663 110.336 97.5037C109.493 95.945 108.974 94.5599 108.654 93.5762C88.4003 49.3548 73.1383 17.0423 69.1748 8.94153C68.6189 7.8096 67.2675 5.07198 64.3684 2.96723C60.503 0.160904 56.1071 0.0668788 53.6658 0.0487967C45.4263 -0.0126821 33.9716 -0.0669284 20.0248 0.222384Z'
							fill='currentFill'
						/>
					</svg>
					<div className={styles.text}>Sincronizar con Aythen Calendar</div>
				</div>
			</div>
			<div className={styles.datePickerWrapper}>
				<CustomDatePicker
					selectedDate={selectedDate}
					setSelectedDate={setSelectedDate}
				/>
				<div className={styles.hourContainer}>
					<div className={styles.hourContainerWrapper}>
						<div className={styles.hourInputsContainer}>
							<input
								placeholder={hour}
								min={1}
								max={12}
								value={hour}
								onChange={(e) => {
									const value = parseInt(e.target.value)
									if (!isNaN(value) && value >= 1 && value <= 12) {
										setHour(value < 10 ? `0${value}` : value.toString())
									}
								}}
								type='number'
								className={styles.fromHourInput}
							/>
							<div className={styles.doubleDot}>:</div>
							<input
								placeholder={minutes}
								min={0}
								max={59}
								value={minutes}
								onChange={(e) => {
									const value = parseInt(e.target.value)
									if (!isNaN(value) && value >= 0 && value <= 59) {
										setMinutes(value < 10 ? `0${value}` : value.toString())
									}
								}}
								type='number'
								className={styles.toHourInput}
							/>
						</div>

						<div className={styles.selectTypeContainer}>
							<div
								onClick={() => setHourType('AM')}
								className={
									hourType === 'AM' ? styles.hourCardSelected : styles.hourCard
								}
							>
								AM
							</div>
							<div
								onClick={() => setHourType('PM')}
								className={
									hourType === 'PM' ? styles.hourCardSelected : styles.hourCard
								}
							>
								PM
							</div>
						</div>
					</div>
					<div className={styles.buttonsWrapper}>
						<div
							onClick={() => dispatch(setModal(null))}
							className={styles.buttonsContainer}
						>
							<div className={styles.grayButton}>Cancelar</div>
						</div>
						<div
							onClick={() => title.length > 0 && handleCreateMeet()}
							className={styles.buttonsContainer}
						>
							<div className={styles.blueButton}>Agendar</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CreateMeetingModal
