const iam = {
    login: {
        t1: `Se connecter`,
        t2: `et accédez à votre espace personnel`,
        t3: `OU`,
        t4: `Se souvenir de moi`,
        t5: `Mot de passe oublié?`,
        t6: `CONNEXION`,
        t7: `Vous n'avez pas de compte?`,
        t8: `S'inscrire`
    },
    table: {
        t1: `Nous n'avons pas trouvé de données dans votre base vectorisée`,
        t2: `Créer`,
        t3: `Documentation de démarrage rapide`,
    },
    header: {
        t1: `Retourner en arrière`,
        t2: `Gestion de l'identité et de l'accès (IAM)`,
        t3: `Utilisateurs`,
        t4: `Applications`,
        t5: `Politiques`,
        t6: `Clés API`,
        t7: `Journaux`,
    },
    users: {
        t1: `Ci-dessous est affichée une liste des utilisateurs de cette organisation. Vous pouvez voir plus d'informations sur chaque utilisateur.`,
        t2: `Qu'est-ce qu'un utilisateur?`,
        t3: `Ajouter un utilisateur`,
        t4: `Inviter des utilisateurs`,
        t5: `Entrez l'adresse e-mail de chaque utilisateur à inviter, séparée par des virgules.`,
        t6: `Entrez des étiquettes de valeur clé`,
        t7: `Les étiquettes de valeur clé vous aident à organiser vos utilisateurs. Vous pouvez attribuer jusqu'à 10 étiquettes par utilisateur.`,
        t8: `Ajouter à un groupe existant (facultatif)`,
        t9: `Inviter`,
        t10: `Informations de l'utilisateur`,
        t11: `État`,
        t12: `Activé`,
        t13: `Type`,
        t14: `Invité`,
        t15: `S'est joint`,
        t16: `Dernier accès`,
        t17: `Supprimer l'utilisateur`,
        t18: `En supprimant un utilisateur de cette organisation, ses clés API et toutes les politiques directement associées à elles seront automatiquement supprimées.`,
        t19: `Supprimer l'utilisateur`,
    },
    apps: {
        t1: `Ci-dessous est affichée une liste des applications de cette organisation. Vous pouvez voir plus d'informations sur chaque application.`,
        t2: `Qu'est-ce qu'une application?`,
        t3: `Créer une application`,
        t4: `Applications`,
        t5: `Créer une application`,
        t6: `Entrez un nom et une description facultative`,
        t7: `Nom`,
        t8: `Le nom de votre application ne peut contenir que des caractères alphanumériques, des points et des tirets.`,
        t9: `Description`,
        t10: `Entrez des étiquettes de valeur clé (facultatif)`,
        t11: `Les étiquettes de valeur clé vous aident à organiser vos applications. Vous pouvez attribuer jusqu'à 10 étiquettes par application.`,
        t12: `Entrez des étiquettes de valeur clé (facultatif)`,
        t13: `Créer l'application`,
    },
    polices: {
        t1: `Ci-dessous est affichée une liste des politiques de cette organisation. Vous pouvez voir plus d'informations sur chaque politique.`,
        t2: `Qu'est-ce qu'une politique?`,
        t3: `Créer une politique`,
        t4: `Politiques`,
        t5: `Règles`,
        t6: `Une règle consiste en un champ d'application (par exemple, niveau de projet ou niveau d'organisation) et un ou plusieurs ensembles de permissions (par exemple, "lister toutes les instances").`,
        t7: `Obtenez plus d'informations sur les règles.`,
        t8: `Règle #1`,
        t9: `Faire`,
        t10: `Organisation`,
        t11: `Les ensembles de permissions IAM et de facturation ne peuvent être sélectionnés qu'au niveau de l'organisation.`,
        t12: `Accès aux ressources`,
        t13: `Donne accès aux ressources (Instances, Stockage d'objets, Bases de données, etc.) dans vos projets.`,
        t14: `Accès aux fonctions de l'organisation`,
        t15: `Fournissez accès à IAM, facturation, support et tickets d'abus et gestion de projets, tout géré au niveau de l'organisation.`,
        t16: `Valider`,
        t17: `Ensembles de permissions`,
        t18: `Gestionnaire d'organisation`,
        t19: `Ajouter une règle`,
    },
    apis: {
        t1: `Ci-dessous est affichée une liste des clés API de cette organisation.`,
        t2: `Comment créer des clés API`,
        t3: `Générer une clé API`,
        t4: `APIs`,
        t5: `Générer une clé API`,
        t6: `Copiez et stockez votre clé secrète. Elle ne sera affichée qu'une seule fois.`,
        t7: `Gérez vos vecteurs via l'accès IAM en ajoutant vos clés API pour les utiliser`,
        t8: `ID de clé d'accès`,
        t9: `Clé secrète`,
        t10: `Fermer Générer une clé`,
        t11: `Générer une clé API`,
        t12: `Sélectionner le porteur de clé API`,
        t13: `Moi-même (utilisateur IAM)`,
        t14: `Une application`,
        t15: `Description`,
        t16: `Expiration`,
        t17: `Cette clé API sera-t-elle utilisée pour le stockage d'objets?`,
        t18: `Moi-même (utilisateur IAM)`,
        t19: `Moi-même (utilisateur IAM)`,
        t20: `Générer une clé API`,
        t21: `Fermer`,
    },
    logs: {
        t1: `Ci-dessous est affichée la liste de vos journaux de ressources IAM. Les ressources IAM peuvent être des utilisateurs, des applications, des groupes, des clés API et des politiques IAM. Vous pouvez obtenir plus de détails sur chacun d'eux.`,
        t2: `Comment comprendre mes journaux?`,
        t3: `Filtrer par ID de ressource IAM exact`,
        t4: `Date`,
        t5: `Date IAM`,
        t6: `Catégorie`,
        t7: `Catégorie`,
        t8: `Action`,
        t9: `Action`,
        t10: `Ajouter`,
        t11: `Réinitialiser`,
        t12: `Filtrer`,
        t13: `Journaux`,
        t14: `Informations de journal`,
        t15: `État`,
        t16: `Activé`,
        t17: `Type`,
        t18: `Invité`,
        t19: `S'est joint`,
        t20: `Dernier accès`,
        t21: `Supprimer le journal`,
        t22: `En supprimant un journal de cette organisation, ses clés API et toutes les politiques directement associées à elles seront automatiquement supprimées.`,
        t23: `Supprimer le journal`,
    },
}

export default iam