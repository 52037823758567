
import React from 'react'


const Icon = () => {
    return (
        <svg width="147" height="147" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M105.691 117.324H40.3088C36.8246 117.324 34 114.499 34 111.015V35.3088C34 31.8246 36.8246 29 40.3088 29H82.75L112 58.25V111.015C112 114.499 109.175 117.324 105.691 117.324Z" fill="url(#paint0_linear_556_2962)"/>
        <path opacity="0.12" fillRule="evenodd" clipRule="evenodd" d="M54.9338 60.102V55.8027C54.9338 52.489 57.6201 49.8027 60.9338 49.8027H85.0662C88.3799 49.8027 91.0662 52.489 91.0662 55.8027V60.102V61.8027V70.4012V72.102V80.7005V82.4012V90.9997V92.7005V96.9997C91.0662 100.313 88.3799 103 85.0662 103H60.9338C57.6201 103 54.9338 100.313 54.9338 96.9997V92.7005V90.9997V82.4012V80.7005V72.102V70.4012V61.8027V60.102Z" fill="white"/>
        <path d="M54.2642 92V77.4545H60.0028C61.1061 77.4545 62.0459 77.6652 62.8224 78.0866C63.599 78.5033 64.1908 79.0833 64.598 79.8267C65.0099 80.5653 65.2159 81.4176 65.2159 82.3835C65.2159 83.3494 65.0076 84.2017 64.5909 84.9403C64.1742 85.679 63.5705 86.2543 62.7798 86.6662C61.9938 87.0781 61.0421 87.2841 59.9247 87.2841H56.267V84.8196H59.4276C60.0194 84.8196 60.5071 84.7178 60.8906 84.5142C61.2789 84.3059 61.5677 84.0194 61.7571 83.6548C61.9512 83.2855 62.0483 82.8617 62.0483 82.3835C62.0483 81.9006 61.9512 81.4792 61.7571 81.1193C61.5677 80.7547 61.2789 80.473 60.8906 80.2741C60.5024 80.0705 60.0099 79.9688 59.4134 79.9688H57.3395V92H54.2642ZM73.7697 92H68.6134V77.4545H73.8123C75.2754 77.4545 76.5348 77.7457 77.5907 78.3281C78.6466 78.9058 79.4586 79.7367 80.0268 80.821C80.5997 81.9053 80.8862 83.2027 80.8862 84.7131C80.8862 86.2282 80.5997 87.5303 80.0268 88.6193C79.4586 89.7083 78.6418 90.544 77.5765 91.1264C76.5159 91.7088 75.2469 92 73.7697 92ZM71.6887 89.3651H73.6418C74.5509 89.3651 75.3156 89.2041 75.9359 88.8821C76.5609 88.5554 77.0296 88.0511 77.3421 87.3693C77.6594 86.6828 77.818 85.7973 77.818 84.7131C77.818 83.6383 77.6594 82.7599 77.3421 82.0781C77.0296 81.3963 76.5632 80.8944 75.943 80.5724C75.3227 80.2505 74.558 80.0895 73.6489 80.0895H71.6887V89.3651ZM84.5642 92V77.4545H94.1949V79.9901H87.6395V83.456H93.5557V85.9915H87.6395V92H84.5642Z" fill="white"/>
        <mask id="mask0_556_2962"  maskUnits="userSpaceOnUse" x="34" y="29" width="78" height="89">
        <path d="M105.691 117.324H40.3088C36.8246 117.324 34 114.499 34 111.015V35.3088C34 31.8246 36.8246 29 40.3088 29H82.75L112 58.25V111.015C112 114.499 109.175 117.324 105.691 117.324Z" fill="url(#paint1_linear_556_2962)"/>
        </mask>
        <g mask="url(#mask0_556_2962)">
        <ellipse opacity="0.08" cx="26.114" cy="27.136" rx="64.0919" ry="61.511" fill="url(#paint2_linear_556_2962)"/>
        <ellipse opacity="0.08" cx="26.1139" cy="27.1363" rx="40.2904" ry="38.5699" fill="url(#paint3_linear_556_2962)"/>
        </g>
        <g filter="url(#filter0_d_556_2962)">
        <path d="M112 58.25H104.75C92.5997 58.25 82.75 48.4003 82.75 36.25V29L112 58.25Z" fill="url(#paint4_linear_556_2962)"/>
        </g>
        <defs>
        <filter id="filter0_d_556_2962" x="59.75" y="5" width="79.25" height="79.25" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="2" dy="1"/>
        <feGaussianBlur stdDeviation="12.5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_556_2962"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_556_2962" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_556_2962" x1="73" y1="29" x2="73" y2="117.324" gradientUnits="userSpaceOnUse">
        <stop stopColor="var(--color-primary-3)"/>
        <stop offset="1" stopColor="var(--color-primary-3)"/>
        </linearGradient>
        <linearGradient id="paint1_linear_556_2962" x1="73" y1="29" x2="73" y2="117.324" gradientUnits="userSpaceOnUse">
        <stop stopColor="var(--color-primary-3)"/>
        <stop offset="1" stopColor="var(--color-primary-3)"/>
        </linearGradient>
        <linearGradient id="paint2_linear_556_2962" x1="30.7675" y1="29.2498" x2="50.2042" y2="80.8349" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint3_linear_556_2962" x1="29.0393" y1="28.4617" x2="41.2036" y2="60.828" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint4_linear_556_2962" x1="97.375" y1="29" x2="97.375" y2="58.25" gradientUnits="userSpaceOnUse">
        <stop stopColor="var(--color-primary-3)"/>
        <stop offset="1" stopColor="var(--color-primary-3)"/>
        </linearGradient>
        </defs>
        </svg>
        
    )
}

export default Icon
