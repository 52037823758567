import { createSlice } from '@reduxjs/toolkit';

import {
  fetchsForm,
  fetchForm,
  addForm,
  updateForm,
  deleteForm,
} from '../actions/form'

export const initialMessage = {
  id: '',
  message: '',
  updatedAt: '',
  createdAt: ''
}

const formSlice = createSlice({
  name: 'form',
  initialState: {
    total: null,
    forms: [],
    form: {},
  },
  reducers: {
    setForm: (state, action) => {
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchsForm.fulfilled, (state, action) => {
        state.forms = action.payload.forms
        state.total = action.payload.total
      })

      .addCase(fetchForm.fulfilled, (state, action) => {
        state.form = action.payload;
      })

      .addCase(addForm.fulfilled, (state, action) => {
        state.forms.push(action.payload);
      })

      .addCase(updateForm.fulfilled, (state, action) => {
        state.forms = action.payload;
      })

      .addCase(deleteForm.fulfilled, (state, action) => {
        state.forms = state.forms.filter(form => form.id !== action.payload);
      })


  },
});

export const {
  setForm,
} = formSlice.actions;

export default formSlice.reducer;
