const pricing = {
  "t0": "Analyseer en verken uw metrics",
  "t1": "Geen verrassingen, zo zijn onze prijzen",
  "t2": "Probeer 7 dagen gratis",
  "t3": "MEEST POPULAIR",
  "t4": "149€",
  "t5": "/maand",
  "t6": "Business",
  "t7": "Bedrijven die continue analyse nodig hebben volgens verschillende actoren.",
  "t8": "5 gebruikers inbegrepen",
  "t9": "10 visuele Add-ons",
  "t10": "Vectorgegevens",
  "t11": "50 datagrafieken",
  "t12": "100 vragen aan de data",
  "t13": "200 klikken op HeatMap",
  "t14": "100GB aan data",
  "t15": "Prioriteitsondersteuning",
  "t16": "Videobellen",
  "t17": "Zoekmachine",
  "t18": "Kies plan",
  "t19": "49€",
  "t20": "/maand",
  "t21": "Basic",
  "t22": "Gericht op opkomende ideeën",
  "t23": "die hun KPI's willen analyseren om strategieën te definiëren.",
  "t24": "1 gebruiker inbegrepen",
  "t25": "5 visuele Add-ons",
  "t26": "Vectorgegevens",
  "t27": "10 datagrafieken",
  "t28": "20 vragen aan de data",
  "t29": "50 klikken op HeatMap",
  "t30": "5GB aan data",
  "t31": "Ondersteuning via e-mail",
  "t32": "Videobellen",
  "t33": "Zoekmachine",
  "t34": "Kies plan",
  "t35": "349€",
  "t36": "/maand",
  "t37": "Start Up",
  "t38": "Voor Start Up-teams die hun eigen analyse willen maken zonder beperkingen.",
  "t39": "15 gebruikers inbegrepen",
  "t40": "Onbeperkte Add-ons",
  "t41": "Vectorgegevens",
  "t42": "Onbeperkte grafieken",
  "t43": "Onbeperkte vragen",
  "t44": "500 klikken op HeatMap",
  "t45": "500GB aan data",
  "t46": "Dringende ondersteuning",
  "t47": "Videobellen",
  "t48": "Zoekmachine",
  "t49": "Kies plan",
  "t50": "GRATIS",
  "t51": "*7 dagen",
  "t52": "Verkenning",
  "t53": "Perfect voor het starten met puntuele analyses, zonder langdurige creatie mogelijkheid.",
  "t54": "1 gebruiker inbegrepen",
  "t55": "1 visueel Add-on",
  "t56": "Vectorgegevens",
  "t57": "1 datagrafiek",
  "t58": "5 vragen aan de data",
  "t59": "Geen HeatMap",
  "t60": "500MB aan data",
  "t61": "Geen ondersteuning",
  "t62": "Videobellen",
  "t63": "Zoekmachine",
  "t64": "Kies plan",
  "t65": "Als u meer gebruikers en opties nodig heeft, neem contact op met ons verkoopsteam",
  "t66": "Verkoop",
  "t67": "Vergelijk tussen de betaalplannen",
  "t68": "En ontdek welke bij uw behoeften past",
  "t69": "Basic",
  "t70": "Begin",
  "t71": "Business",
  "t72": "Begrijp",
  "t73": "Startup",
  "t74": "Analyseer",
  "t75": "CAPACITEITEN EN TOEGANGEN",
  "t76": "Gebruikers",
  "t77": "1",
  "t78": "5",
  "t79": "15",
  "t80": "Add-ons",
  "t81": "Onbeperkt",
  "t82": "5",
  "t83": "10",
  "t84": "Vectorgegevens",
  "t85": "GB aan data",
  "t86": "Tot 5 GB",
  "t87": "Tot 100 GB",
  "t88": "Tot 500 GB",
  "t89": "Persoonlijke weergave",
  "t90": "Aantal grafieken",
  "t91": "10",
  "t92": "50",
  "t93": "Onbeperkt",
  "t94": "Aantal vragen",
  "t95": "20",
  "t96": "100",
  "t97": "Onbeperkt",
  "t98": "Klikken met Hittekaart",
  "t99": "50",
  "t100": "200",
  "t101": "500",
  "t102": "Videobellen en zoekmachine",
  "t103": "PERSOONLIJKISERING EN CODE",
  "t104": "Persoonlijk logo",
  "t105": "binnenkort",
  "t106": "Vooraf gedefinieerde thema's",
  "t107": "Persoonlijke thema's",
  "t108": "binnenkort",
  "t109": "Delen met subdomein",
  "t110": "Delen met domein",
  "t111": "binnenkort",
  "t112": "Toegang tot IAM en bank",
  "t113": "Gebruik van Add-ons",
  "t114": "binnenkort",
  "t115": "Bewerking van prompts",
  "t116": "Toegang tot broncode",
  "t117": "binnenkort",
  "t118": "Bewerking in real-time",
  "t119": "binnenkort",
  "t120": "RAPPORTEN",
  "t121": "Exporteer naar PDF",
  "t122": "Verstuur via e-mail",
  "t123": "Delen via link",
  "t124": "ONDERSTEUNING",
  "t125": "Ondersteuning via e-mail",
  "t126": "Prioriteitsondersteuning",
  "t127": "One-to-one hulp",
  "t128": "En wat als ik meer nodig heb voor analyse?",
  "t129": "U kunt van plan wisselen. Maar als dat uw behoeften te veel schaalt, kunt u kiezen op basis van meer grafieken, meer vragen, meer Add-ons voor uw interface-weergaven, meer klikken, meer GB aan data... Uw extra's worden toegevoegd aan uw bestaande quota. U kunt op elk moment annuleren. En als u een persoonlijk plan nodig heeft, neem contact op met verkoop.",
  "t130": "Grafieken",
  "t131": "Pakket 10",
  "t132": "15€",
  "t133": "Vragen",
  "t134": "Pakket 20",
  "t135": "10€",
  "t136": "Add-on",
  "t137": "Extra",
  "t138": "10€",
  "t139": "Hittekaart",
  "t140": "Klik",
  "t141": "0.5€",
  "t142": "GB's Cloud",
  "t143": "GB",
  "t144": "1€"
}

export default pricing