const home = {
    banner: {
        banner: 'Bewerk profielfoto',
        edit: 'Bewerk profiel',
        addUser: 'Voeg gebruiker toe',
        title: 'gedeelde gebruikers',
        viewTeam: 'Beheer team',
        team: {
            title: `Mensen die je mogelijk kent`,
            button: `Bekijk alle`,
            addContact: `Voeg toe aan contacten`
        },
    },
    t1: `Organisatie`,
    t2: `Bank`,
    t3: `Identificatie en toegang voor manager (IAM)`,
    t4: `Toegang (IAM)`,
    t5: `Werk en begrijp je data als vectoren`,
    t6: `Ga naar de nieuwe dimensie van de data. Verwijder latency om x2000 keer efficiënter te werken, perfect voor het segmenteren, analyseren en begrijpen van grote hoeveelheden simultane data.`,
    t60: `Voeg data toe aan je vector zodat ze voor je werken`,
    t61: `Ga naar een vector`,
    t7: `Maak nieuw`,
    t8: `Voeg nieuw toe`,
    t9: `Nieuw`,
    t10: `Maak je eigen Addons om vectoriële componenten te maken waarin je kunt weergeven`,
    t11: `Er is geen addon, wil je het maken`,
    t12: `Nieuw Addon`,
    t13: `Premium Addon`,
    t14: `Gebruik van data`,
    t15: `Kenmerken`,
    t16: `Bekijk meer`,
    t17: `Notities`,
    t18: `Voeg nieuw toe`,
    t19: `Veilige data`,
    t20: " RGPD - ISO 27001",
    t21: `je kunt werken met aangepaste vectoren.`,
    steps: {
        addCampaign: `Maak campagne`,
        addAdvice: `Maak advertenties`,
        t1: `Herziening van beleid`,
        t2: `Impressies`,
        t3: `Conversies`,
        title: `Alle diagnostiek van de campagne`,
        s1: `Status van het account`,
        s2: `Status van de facturering`,
        s3: `Beleid van de campagne`,
        s4: `Volgen van conversies`,
        s5: `Budget van de campagne`,
        s6: `Biedstrategie`,
        s7: `Status van de campagne`,
        s8: `Effectiviteit van de advertentie`,
        text1: `Deze campagne heeft geen advertenties`,
        text2: `Voeg advertenties toe aan de advertentiegroepen om ze te publiceren`,
        text3: `Oplossen`,
    },
    t22: {
        t0: `In`,
        t1: `gebruiken we`,
    },
    t23: {
        t0: `Binnenkort beschikbaar`,
        t1: `Binnenkort zal het beschikbaar zijn, we kunnen je een bericht sturen voor het
        pro`,
    },
    t24: {
        t0: `Geen internetverbinding`,
        t1: `Controleer je Wi-Fi- of mobiele dataverbinding, of ontvang een
        notificatie wanneer de verbinding terugkeert`,
        t2: `Waarschuw me`,
        t3: `Probeer opnieuw`,
    },
    t25: {
        t0: `Niet gevonden`,
        t1: `🚀 Niet gevonden`,
        t2: `Bekijk wijzigingen`,
    }
}

export default home;