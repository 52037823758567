const VERSION = process.env.REACT_APP_VERSION;

const Icon1 = require(`@app/assets/steps/1.svg`).default;
const Icon2 = require(`@app/assets/steps/2.svg`).default;
const Icon3 = require(`@app/assets/steps/3.svg`).default;
const Icon4 = require(`@app/assets/steps/4.svg`).default;
const Icon5 = require(`@app/assets/steps/5.svg`).default;
const Icon6 = require(`@app/assets/steps/6.svg`).default;
const Icon7 = require(`@app/assets/steps/7.svg`).default;
const Icon8 = require(`@app/assets/steps/8.svg`).default;
const Icon9 = require(`@app/assets/steps/9.svg`).default;
const Icon10 = require(`@app/assets/steps/10.svg`).default;
const Icon11 = require(`@app/assets/steps/11.svg`).default;
const Icon12 = require(`@app/assets/steps/12.svg`).default;
const Icon13 = require(`@app/assets/steps/13.svg`).default;
const Icon14 = require(`@app/assets/steps/14.svg`).default;
const Icon15 = require(`@app/assets/steps/15.svg`).default;
const Icon16 = require(`@app/assets/steps/16.svg`).default;
const Icon17 = require(`@app/assets/steps/17.svg`).default;
const Icon18 = require(`@app/assets/steps/18.svg`).default;
const Icon19 = require(`@app/assets/steps/19.svg`).default;
const Icon20 = require(`@app/assets/steps/20.svg`).default;
const Icon21 = require(`@app/assets/steps/21.svg`).default;
const Icon22 = require(`@app/assets/steps/22.svg`).default;
const Icon23 = require(`@app/assets/steps/23.svg`).default;
const Icon24 = require(`@app/assets/steps/24.svg`).default;
const Icon25 = require(`@app/assets/steps/25.svg`).default;
const Icon26 = require(`@app/assets/steps/26.svg`).default;
const Icon28 = require(`@app/assets/steps/28.svg`).default;
const Icon29 = require(`@app/assets/steps/29.svg`).default;
const Icon30 = require(`@app/assets/steps/30.svg`).default;
const Icon31 = require(`@app/assets/steps/31.svg`).default;
const Icon32 = require(`@app/assets/steps/32.svg`).default;
const Icon33 = require(`@app/assets/steps/33.svg`).default;

const renderHelp = ({title, text, icon}) => {
    return (`<div class="steps-intro">
<img src=${icon} />
<div class="info">
<b>
${title} 
</b>
<p>
${text} 
</p>
</div>
<div class="buttons">
    <button onclick="introJs().nextStep()">
        Anterior
    </button>
    <button class="next" onclick="introJs().previousStep()">
        Siguiente
    </button >
</div>
</div>`)
}



export const home = [{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="0-1"]`,
    intro: renderHelp({
        icon: Icon1,
        title: `Votre premier accès`,
        text: `Ici, vous pouvez gérer les données de votre compte ainsi que accéder à votre licence, vos préférences visuelles et vous déconnecter de votre session.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="0-2"]`,
    intro: renderHelp({
        icon: Icon2,
        title: `Vos premières données`,
        text: `Commençons par quelque chose de simple ! Dans "Modifier le compte", vous remplirez et sauvegarderez vos données personnelles ainsi que changerez de thème.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="0-3"]`,
    intro: renderHelp({
        icon: Icon3,
        title: `Votre gestion`,
        text: `Vous avez tout ce dont vous avez besoin pour faire avancer votre projet sous tous ses aspects. Votre démarrage, vos données, documents, tâches, factures et contrats.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-0"]`,
    intro: renderHelp({
        icon: Icon4,
        title: `Votre démarrage > Page d'accueil`,
        text: `Vous trouverez un aperçu général. Tout d'abord, de votre profil, des organisations que vous gérez, de l'accès à votre solde, de vos accès et de vos domaines.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-1"]`,
    intro: renderHelp({
        icon: Icon5,
        title: `Votre résumé > Design`,
        text: `De vos projets et domaines, vous aurez besoin de designs. Dans les Add-ons, vous pouvez créer ce que vous voulez. Ainsi, vous observerez la consommation de ressources impliquée.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-2"]`,
    intro: renderHelp({
        icon: Icon6,
        title: `Votre résumé > Données`,
        text: `De vos projets et designs, vous aurez des données. Accédez à vos bases de données vectorisées et enregistrez vos graphiques les plus intéressants.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-3"]`,
    intro: renderHelp({
        icon: Icon7,
        title: `Votre résumé > Statut`,
        text: `Rafraîchissez votre mémoire avec les différentes fonctionnalités disponibles, l'état de votre connexion et l'accès au support.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="2-0"]`,
    intro: renderHelp({
        icon: Icon8,
        title: `Aythen DB > vos analyses`,
        text: `Créez et synchronisez toutes les données pour créer des dimensions et des stratégies. Ainsi, vous pourrez prendre des décisions éclairées grâce aux interactions.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="2-0"]`,
    intro: renderHelp({
        icon: Icon9,
        title: `Votre documentation`,
        text: `Aythen prend en charge tout format de fichier. Faites glisser et déposez vos fichiers souhaités. Partagez-les et catégorisez leur pertinence.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="3-0"]`,
    intro: renderHelp({
        icon: Icon10,
        title: `Kan Ban > Vos tâches`,
        text: `Vous devrez organiser vos tâches et votre équipe. Ajoutez des tickets, des listes de tâches, des priorités, des états et vérifiez l'état général de l'avancement.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="4-0"]`,
    intro: renderHelp({
        icon: Icon11,
        title: `Facturation électronique (OCR)`,
        text: `Remplissez vos coordonnées d'entreprise et gérez votre solde, programmez des rappels de paiement et configurez vos limites de licence.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icon: Icon12,
        title: `Signature électronique (Audit trail)`,
        text: `Gérez tous vos accords grâce à la compilation de vos contrats. Signez, téléchargez ou supprimez ces documents sensibles.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icon: Icon13,
        title: `Votre premier projet`,
        text: `Avec une vue d'ensemble de la plateforme. Créez un nouvel add-on (public ou privé). Sélectionnez les paramètres et liez votre domaine. Enregistrez et ouvrez-le :`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icon: Icon14,
        title: `Votre premier projet`,
        text: `Avec une vue d'ensemble de la plateforme. Créez un nouvel add-on (public ou privé). Sélectionnez les paramètres et liez votre domaine. Enregistrez et ouvrez-le :`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-1"]`,
    intro: renderHelp({
        icon: Icon15,
        title: `Votre espace créatif`,
        text: `Ici, vous pourrez construire vos flux de conception. Créez et connectez pour créer des écrans corrélés. Vous voulez savoir comment commencer ?`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-2"]`,
    intro: renderHelp({
        icon: Icon16,
        title: `Créer et sauvegarder`,
        text: `Tout votre flux repose sur des nœuds intelligents. Ils connecteront vos écrans et synchroniseront vos données. Créez le premier et enregistrez votre session.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-3"]`,
    intro: renderHelp({
        icon: Icon17,
        title: `Organisez votre vue`,
        text: `Êtes-vous plutôt en grille ? En points ? Aimez-vous tout bien rangé ? Voulez-vous savoir où se trouve chaque nœud à tout moment ? Ces 3 boutons vous aideront.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-4"]`,
    intro: renderHelp({
        icon: Icon18,
        title: `Contrôlez votre vue`,
        text: `Faites un zoom pour peaufiner les détails ou pour avoir une vue d'ensemble de votre création. Cela consommera des ressources différentes en fonction de son utilisation.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-5"]`,
    intro: renderHelp({
        icon: Icon19,
        title: `Contrôlez votre création`,
        text: `Exportez ou importez vos conceptions et analysez où les utilisateurs sont les plus actifs grâce aux cartes de chaleur. Vous pourrez ainsi établir des corrélations avec des critères.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-6"]`,
    intro: renderHelp({
        icon: Icon20,
        title: `Ajoutez et synchronisez`,
        text: `Ajoutez du contenu à votre conception, téléchargez-le automatiquement sur GitHub ou partagez-le avec des tiers. Tout ce dont vous avez besoin pour créer sans fin.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-7"]`,
    intro: renderHelp({
        icon: Icon21,
        title: `Avez-vous votre premier flux ?`,
        text: `Il est possible que tout ne soit pas au goût du consommateur. Vous pouvez donc double-cliquer ou faire "Alt" + clic sur le nœud que vous devez affiner.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-8"]`,
    intro: renderHelp({
        icon: Icon22,
        title: `Votre espace créatif individuel`,
        text: `Ici, vous pourrez vous concentrer sur la construction de votre design. Créez avec du code et connectez de nouvelles données ou des données existantes pour créer des écrans corrélés.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-9"]`,
    intro: renderHelp({
        icon: Icon23,
        title: `Accédez à votre code`,
        text: `Modifiez et enregistrez en temps réel. Avec un effet miroir, vous pourrez modifier et voir le design pour apporter toutes les modifications à votre goût.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-10"]`,
    intro: renderHelp({
        icon: Icon24,
        title: `Synchronisez vos données`,
        text: `Vous trouverez différents moyens de sauvegarder et de relier votre contenu à vos bases de données. Si vous avez des doutes, consultez nos commandes.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-11"]`,
    intro: renderHelp({
        icon: Icon25,
        title: `Accès rapides`,
        text: `Revenez en arrière avec tous les nœuds, zoomez dans cet espace individuel ou cliquez à droite pour vérifier sa vue depuis le navigateur.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-12"]`,
    intro: renderHelp({
        icon: Icon26,
        title: `Travaillez efficacement`,
        text: `Effectuez toutes vos modifications en une seule fois avec le double écran interne pour avoir un accès rapide à toutes les fonctionnalités disponibles.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-0"]`,
    intro: renderHelp({
        icon: Icon28,
        title: `Votre première analyse`,
        text: `Ici, vous pourrez construire vos flux de données. À la fois externes et synchronisés avec la conception. Créez des interactions et prenez des décisions éclairées.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-1"]`,
    intro: renderHelp({
        icon: Icon29,
        title: `Localisez vos données`,
        text: `Recherchez, sélectionnez vos nœuds avec des onglets, enregistrez votre version, téléchargez et organisez votre flux à travers différentes fonctionnalités.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-2"]`,
    intro: renderHelp({
        icon: Icon30,
        title: `Interagissez avec vos données`,
        text: `Pour prendre des décisions éclairées, vous devrez poser des questions, créer des graphiques ou des relations de matrices à travers Python / JavaScript.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-3"]`,
    intro: renderHelp({
        icon: Icon31,
        title: `Gérez votre nœud`,
        text: `Chaque nœud intelligent est configuré de différentes manières. Sa flexibilité de tout type de données et de format est adaptée à tous les besoins.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-4"]`,
    intro: renderHelp({
        icon: Icon32,
        title: `Faites attention à tous les détails`,
        text: `Vous pourrez voir et modifier le nom. Vous pourrez ainsi toujours le rechercher pour retrouver vos données. À sa droite, vous aurez quand il a été téléchargé et sa taille.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-5"]`,
    intro: renderHelp({
        icon: Icon33,
        title: `Configurez vos données`,
        text: `Effectuez des sauvegardes avec les versions, intégrez des données tierces, générez une question ou développez le nœud pour voir toutes les données.`
        })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-6"]`,
    intro: renderHelp({
        icon: Icon1,
        title: `Prévisualisez vos données`,
        text: `Dans la fenêtre du nœud, vous pourrez voir toutes les données que vous avez dans votre espace pour comprendre rapidement ce que c'est et avec quoi il peut être lié.`
        })
}]


export default home
