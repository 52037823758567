const VERSION = process.env.REACT_APP_VERSION;

const Icon1 = require(`@app/assets/steps/1.svg`).default;
const Icon2 = require(`@app/assets/steps/2.svg`).default;
const Icon3 = require(`@app/assets/steps/3.svg`).default;
const Icon4 = require(`@app/assets/steps/4.svg`).default;
const Icon5 = require(`@app/assets/steps/5.svg`).default;
const Icon6 = require(`@app/assets/steps/6.svg`).default;
const Icon7 = require(`@app/assets/steps/7.svg`).default;
const Icon8 = require(`@app/assets/steps/8.svg`).default;
const Icon9 = require(`@app/assets/steps/9.svg`).default;
const Icon10 = require(`@app/assets/steps/10.svg`).default;
const Icon11 = require(`@app/assets/steps/11.svg`).default;
const Icon12 = require(`@app/assets/steps/12.svg`).default;
const Icon13 = require(`@app/assets/steps/13.svg`).default;
const Icon14 = require(`@app/assets/steps/14.svg`).default;
const Icon15 = require(`@app/assets/steps/15.svg`).default;
const Icon16 = require(`@app/assets/steps/16.svg`).default;
const Icon17 = require(`@app/assets/steps/17.svg`).default;
const Icon18 = require(`@app/assets/steps/18.svg`).default;
const Icon19 = require(`@app/assets/steps/19.svg`).default;
const Icon20 = require(`@app/assets/steps/20.svg`).default;
const Icon21 = require(`@app/assets/steps/21.svg`).default;
const Icon22 = require(`@app/assets/steps/22.svg`).default;
const Icon23 = require(`@app/assets/steps/23.svg`).default;
const Icon24 = require(`@app/assets/steps/24.svg`).default;
const Icon25 = require(`@app/assets/steps/25.svg`).default;
const Icon26 = require(`@app/assets/steps/26.svg`).default;
const Icon28 = require(`@app/assets/steps/28.svg`).default;
const Icon29 = require(`@app/assets/steps/29.svg`).default;
const Icon30 = require(`@app/assets/steps/30.svg`).default;
const Icon31 = require(`@app/assets/steps/31.svg`).default;
const Icon32 = require(`@app/assets/steps/32.svg`).default;
const Icon33 = require(`@app/assets/steps/33.svg`).default;

const renderHelp = ({title, text, icon}) => {
    return (`<div class="steps-intro">
<img src=${icon} />
<div class="info">
<b>
${title} 
</b>
<p>
${text} 
</p>
</div>
<div class="buttons">
    <button onclick="introJs().nextStep()">
        Anterior
    </button>
    <button class="next" onclick="introJs().previousStep()">
        Siguiente
    </button >
</div>
</div>`)
}




export const home = [{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="0-1"]`,
    intro: renderHelp({
        icon: Icon1,
        title: `Dein erster Zugriff`,
        text: `Hier kannst du deine Kontodaten verwalten sowie auf deine Lizenz, deine visuelle Präferenz zugreifen und dich abmelden.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="0-2"]`,
    intro: renderHelp({
        icon: Icon2,
        title: `Deine ersten Daten`,
        text: `Lass uns mit etwas Einfachem beginnen! Unter "Konto bearbeiten" kannst du deine persönlichen Daten ausfüllen und speichern sowie das Thema ändern.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="0-3"]`,
    intro: renderHelp({
        icon: Icon3,
        title: `Deine Verwaltung`,
        text: `Du hast alles, was du brauchst, damit dein Projekt aus jeder Perspektive vorankommt. Dein Start, deine Daten, Dokumente, Aufgaben, Rechnungen und Verträge.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-0"]`,
    intro: renderHelp({
        icon: Icon4,
        title: `Dein Start > Startseite`,
        text: `Hier findest du eine allgemeine Übersicht. Zuerst dein Profil, die von dir verwalteten Organisationen, Zugriff auf dein Guthaben, deine Zugriffe und deine Domains.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-1"]`,
    intro: renderHelp({
        icon: Icon5,
        title: `Deine Übersicht > Design`,
        text: `Für deine Projekte und Domains brauchst du Designs. In den Add-ons kannst du alles erstellen, was du möchtest. So kannst du den Ressourcenverbrauch beobachten.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-2"]`,
    intro: renderHelp({
        icon: Icon6,
        title: `Deine Übersicht > Daten`,
        text: `Von deinen Projekten und Designs hast du Daten. Greife auf deine vektorisierten Datenbanken zu und speichere deine interessantesten Grafiken.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="1-3"]`,
    intro: renderHelp({
        icon: Icon7,
        title: `Deine Übersicht > Status`,
        text: `Erinnere dich an die verschiedenen Funktionen, die dir zur Verfügung stehen, den Status deiner Verbindung und den Zugang zum Support.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="2-0"]`,
    intro: renderHelp({
        icon: Icon8,
        title: `Aythen DB > deine Analysen`,
        text: `Erstelle und synchronisiere alle Daten, um Dimensionen und Strategien zu erstellen. So kannst du dank der Interaktionen fundierte Entscheidungen treffen.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="2-0"]`,
    intro: renderHelp({
        icon: Icon9,
        title: `Deine Dokumentation`,
        text: `Aythen unterstützt jedes Dateiformat. Ziehe deine gewünschten Dateien per Drag-and-Drop. Teile sie und kategorisiere ihre Relevanz.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="3-0"]`,
    intro: renderHelp({
        icon: Icon10,
        title: `Kan Ban > Deine Aufgaben`,
        text: `Du musst deine Aufgaben und dein Team organisieren. Füge Tickets, Aufgabenlisten, Prioritäten, Status hinzu und überprüfe den allgemeinen Fortschritt.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="4-0"]`,
    intro: renderHelp({
        icon: Icon11,
        title: `Elektronische Rechnungsstellung (OCR)`,
        text: `Fülle deine Firmendaten aus und verwalte dein Guthaben, plane Zahlungserinnerungen und konfiguriere deine Lizenzlimits.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icon: Icon12,
        title: `Elektronische Signatur (Audit Trail)`,
        text: `Verwalte alle deine Vereinbarungen durch die Erfassung deiner Verträge. Signiere, lade herunter oder lösche sensible Dokumente.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icon: Icon13,
        title: `Dein erstes Projekt`,
        text: `Mit der gesamten Durchsicht der Plattform. Erstelle ein neues Add-on (öffentlich oder privat). Wähle Einstellungen und verknüpfe deine Domain. Speichere und öffne es:`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-0"]`,
    intro: renderHelp({
        icon: Icon14,
        title: `Dein erstes Projekt`,
        text: `Mit der gesamten Durchsicht der Plattform. Erstelle ein neues Add-on (öffentlich oder privat). Wähle Einstellungen und verknüpfe deine Domain. Speichere und öffne es:`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-1"]`,
    intro: renderHelp({
        icon: Icon15,
        title: `Dein kreativer Raum`,
        text: `Hier kannst du deine Designflüsse erstellen. Erstelle und verbinde, um aufeinanderfolgende Bildschirme zu erstellen. Möchtest du wissen, wie du anfängst?`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-2"]`,
    intro: renderHelp({
        icon: Icon16,
        title: `Erstelle und speichere`,
        text: `Dein gesamter Fluss basiert auf intelligenten Knoten. Sie verbinden sowohl deine Bildschirme als auch synchronisieren deine Daten. Erstelle den ersten und speichere deine Sitzung.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-3"]`,
    intro: renderHelp({
        icon: Icon17,
        title: `Organisiere deine Ansicht`,
        text: `Bist du ein Raster-Fan? Ein Punkt-Fan? Möchtest du alles geordnet haben? Möchtest du jederzeit wissen, wo sich jeder Knoten befindet? Diese 3 Schaltflächen helfen dir dabei.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-4"]`,
    intro: renderHelp({
        icon: Icon18,
        title: `Steuere deine Ansicht`,
        text: `Zoome, um Details zu verfeinern oder um einen Überblick über deine gesamte Kreation zu bekommen. Diese wird je nach Nutzung unterschiedliche Ressourcen verbrauchen.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-5"]`,
    intro: renderHelp({
        icon: Icon19,
        title: `Steuere deine Kreation`,
        text: `Exportiere oder importiere deine Designs und analysiere, wo die Benutzer am aktivsten sind, dank Heatmaps. So kannst du mit Bedacht korrelieren.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-6"]`,
    intro: renderHelp({
        icon: Icon20,
        title: `Hinzufügen und synchronisieren`,
        text: `Füge Inhalte zu deinem Design hinzu, lade sie automatisch auf GitHub hoch oder teile sie mit Dritten. Alles, was du zum endlosen Erstellen benötigst.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-7"]`,
    intro: renderHelp({
        icon: Icon21,
        title: `Hast du deinen ersten Fluss?`,
        text: `Es ist möglich, dass nicht alles nach deinem Geschmack ist. Deshalb kannst du mit einem Doppelklick oder "Alt" + Klick den Knoten, den du bearbeiten möchtest, öffnen.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-8"]`,
    intro: renderHelp({
        icon: Icon22,
        title: `Dein individueller kreativer Raum`,
        text: `Hier kannst du dich auf deine Designkonstruktion konzentrieren. Erstelle mit Code und verbinde neue oder vorhandene Daten, um aufeinanderfolgende Bildschirme zu erstellen.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-9"]`,
    intro: renderHelp({
        icon: Icon23,
        title: `Zugriff auf deinen Code`,
        text: `Ändere und speichere in Echtzeit. Mit einem Spiegelungseffekt kannst du bearbeiten und das Design sehen, um jede gewünschte Modifikation zu erstellen.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-10"]`,
    intro: renderHelp({
        icon: Icon24,
        title: `Synchronisiere deine Daten`,
        text: `Du findest verschiedene Methoden, um deinen Inhalt zu speichern und ihn mit deinen Datenbanken zu verknüpfen. Wenn du Zweifel hast, überprüfe unsere Befehle.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-11"]`,
    intro: renderHelp({
        icon: Icon25,
        title: `Schnellzugriffe`,
        text: `Gehe mit allen Knoten zurück, zoome in diesem individuellen Bereich oder klicke rechts, um die Ansicht im Browser zu überprüfen.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="5-12"]`,
    intro: renderHelp({
        icon: Icon26,
        title: `Effizient arbeiten`,
        text: `Erstelle alle deine Änderungen gleichzeitig mit dem internen Doppelschirm, um schnellen Zugriff auf alle verfügbaren Funktionen zu haben.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-0"]`,
    intro: renderHelp({
        icon: Icon28,
        title: `Deine erste Analyse`,
        text: `Hier kannst du deine Datenflüsse erstellen. Sowohl externe als auch deine synchronisierten mit dem Design. Erstelle Interaktionen und triff fundierte Entscheidungen.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-1"]`,
    intro: renderHelp({
        icon: Icon29,
        title: `Lokalisieren deine Daten`,
        text: `Suche, wähle deine Knoten mit den Tabs aus, speichere deine Version, lade herunter und ordne deinen Fluss durch die verschiedenen Funktionen.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-2"]`,
    intro: renderHelp({
        icon: Icon30,
        title: `Interagiere mit deinen Daten`,
        text: `Um fundierte Entscheidungen zu treffen, musst du Fragen stellen, Grafiken oder Matrizenbeziehungen über Python / Javascript erstellen.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-3"]`,
    intro: renderHelp({
        icon: Icon31,
        title: `Verwalte deinen Knoten`,
        text: `Jeder intelligente Knoten wird auf verschiedene Weise konfiguriert. Seine Flexibilität für beliebige Daten und Formate ist für jeden Zweck geeignet.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-4"]`,
    intro: renderHelp({
        icon: Icon32,
        title: `Achte auf alle Details`,
        text: `Du kannst den Namen sehen und ändern. So kannst du ihn immer suchen, um deine Daten zu finden. Rechts siehst du, wann er hochgeladen wurde und wie groß er ist.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-5"]`,
    intro: renderHelp({
        icon: Icon33,
        title: `Konfiguriere deine Daten`,
        text: `Erstelle Sicherungskopien mit den Versionen, integriere Daten von Drittanbietern, stelle eine Frage oder erweitere den Knoten, um alle Daten zu sehen.`
    })
},
{
    position: 'bottom',
    hintPosition: 'top-right',
    element: `[data-step="6-6"]`,
    intro: renderHelp({
        icon: Icon1,
        title: `Vorschau deiner Daten`,
        text: `Im Knotenfenster kannst du alle Daten sehen, die du in deinen Raum eingefügt hast, um schnell zu verstehen, was es ist und womit es verknüpft werden kann.`
    })
}]

export default home
