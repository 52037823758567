import apiBackend from '@src/apiBackend'
import { createAsyncThunk } from '@reduxjs/toolkit';


import {
  setNote
} from '../slices/noteSlice'

export const addNote =
  createAsyncThunk('note/addNote',
    async (data, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          '/note', {
          note: data
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        );
        

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );




export const fetchsNote =
  createAsyncThunk('note/fetchsNote',
    async ({ id, name, title = false }) => {
      try {
        const token = localStorage.getItem('token')

        const res = await apiBackend.get(
          `/note`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        )


        return res.data.note
      } catch (error) {
        console.log('Error note:', error)
      }
    })



export const fetchNote =
  createAsyncThunk('note/fetchNote',
    async (id, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const res = await apiBackend.get(
          `/note/${id}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        )
        
        return res.data
      } catch (error) {
        console.log('Error note:', error)
      }
    })




export const deleteNote =
  createAsyncThunk('note/deleteNote',
    async ({ id }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')

        const res = await apiBackend.delete(
          `/note`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            id
          },
        })


        return res.data
      } catch (error) {
        console.log('Error note:', error)
      }
    })











