import { createSlice } from '@reduxjs/toolkit';

import {
  fetchsSession,
  fetchSession,
  addSession,
  updateSession,
  deleteSession
} from '../actions/session'



const sessionSlice = createSlice({
  name: 'sessions',
  initialState: {
    status: null,
    loading: false,

    session: {}, 
    map: null
  },
  reducers: {
    setSession: (state, action) => {
      state.session = action.payload
    },
    setMap: (state, action) => {
      state.map = action.payload
    },
    verifyToken: (state, action) => {
    },
    saveToken: (state, action) => {
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchsSession.fulfilled, (state, action) => {
        state.session[action.payload.type] = action.payload.session
      })

      .addCase(fetchSession.fulfilled, (state, action) => {
      })
      .addCase(addSession.fulfilled, (state, action) => {
        state.session[action.payload.type] = action.payload.session
      })
      .addCase(updateSession.fulfilled, (state, action) => {
        state.session = action.payload;
      })
      .addCase(deleteSession.fulfilled, (state, action) => {
        state.session = state.session.filter(session => session.id !== action.payload);
      })
  },
});

export const {
  setSession,
  setMap,

  verifyToken,
  saveToken,

  setCode,
} = sessionSlice.actions;



export default sessionSlice.reducer;










