const iam = {
    login: {
        t1: `Iniciar Sessão`,
        t2: `E acesse seu espaço pessoal`,
        t3: `OU`,
        t4: `Lembre-me`,
        t5: `Esqueceu a senha?`,
        t6: `ENTRAR`,
        t7: `Não tem uma conta?`,
        t8: `Inscrever-se`
    },
    table: {
        t1: `Não encontramos dados em sua base vetorial`,
        t2: `Criar`,
        t3: `Documentação de Início Rápido`,
    },
    header: {
        t1: `Voltar atrás`,
        t2: `Gerenciamento de Identidade e Acesso (IAM)`,
        t3: `Usuários`,
        t4: `Aplicativos`,
        t5: `Políticas`,
        t6: `Chaves API`,
        t7: `Logs`,
    },
    users: {
        t1: `A seguir é exibida uma lista de usuários desta organização. 
            Você pode ver mais informações sobre cada usuário.`,
        t2: `O que são usuários`,
        t3: `Adicionar usuário`,
        t4: `Convidar usuário(s)`,
        t5: `Insira o endereço de e-mail de cada usuário a convidar, separado por vírgulas.`,
        t6: `Insira etiquetas de valor chave`,
        t7: `As etiquetas de valor chave ajudam a organizar seus usuários. Você pode atribuir até 10 etiquetas por usuário.`,
        t8: `Adicionar a um grupo existente (opcional)`,
        t9: `Convidar`,
        t10: `Informação do usuário`,
        t11: `Estado`,
        t12: `Ativado`,
        t13: `Tipo`,
        t14: `Convidado`,
        t15: `Se juntou`,
        t16: `Último acesso`,
        t17: `Excluir usuário`,
        t18: `Ao excluir um usuário desta organização, suas chaves API e qualquer política diretamente associada a elas ficarão órfãs.`,
        t19: `Excluir usuário`,
    },
    apps: {
        t1: `A seguir é exibida uma lista de aplicativos nesta Organização. Você pode ver mais informações sobre cada aplicativo.`,
        t2: `O que são aplicativos?`,
        t3: `Criar App`,
        t4: `Aplicativos`,
        t5: `Criar um aplicativo`,
        t6: `Insira um nome e uma descrição opcional`,
        t7: `Nome`,
        t8: `O nome do seu aplicativo só pode conter caracteres alfanuméricos, pontos e hifens.`,
        t9: `Descrição`,
        t10: `Insira etiquetas de valor chave (opcional)`,
        t11: `As etiquetas de valor chave ajudam a organizar seus aplicativos. Você pode atribuir até 10 etiquetas por aplicativo.`,
        t12: `Insira etiquetas de valor chave (opcional)`,
        t13: `Criar aplicativo`,
    },
    polices: {
        t1: `Esta é uma lista de políticas desta Organização. Você pode ver mais informações sobre cada política.`,
        t2: `O que são políticas?`,
        t3: `Criar política`,
        t4: `Políticas`,
        t5: `Regras`,
        t6: `Uma regra consiste em um escopo (por exemplo, nível de projeto ou nível de organização) e um ou mais conjuntos de permissões (por exemplo, "enumerar todas as instâncias").`,
        t7: `Obtenha mais informações sobre as regras.`,
        t8: `Regra #1`,
        t9: `Fazer`,
        t10: `Organização`,
        t11: `Os conjuntos de permissões de IAM e faturamento só podem ser selecionados no nível de organização.`,
        t12: `Acesso a recursos`,
        t13: `Dá acesso a recursos (Instâncias, Armazenamento de objetos, Bases de dados, etc.) em seus Projetos.`,
        t14: `Acesso às funções da organização`,
        t15: `Ofereça acesso a IAM, faturamento, suporte e tickets de abuso e gerenciamento de projetos, tudo gerenciado no nível de organização.`,
        t16: `Validar`,
        t17: `Conjuntos de permissões`,
        t18: `Gerente de organização`,
        t19: `Adicionar regra`,
    },
    apis: {
        t1: `A seguir é exibida uma lista de chaves API desta organização.`,
        t2: `Como criar chaves API`,
        t3: `Gerar chave API`,
        t4: `APIs`,
        t5: `Gerar uma chave API`,
        t6: `Copie e armazene sua chave secreta. Só será exibida uma vez.`,
        t7: `Gerencie seus vetores através do acesso IAM adicionando suas
            Chaves API para usá-las`,
        t8: `ID de chave de acesso`,
        t9: `Chave secreta`,
        t10: `Fechar Gerar chave`,
        t11: `Gerar uma chave API`,
        t12: `Selecionar portador de chave API`,
        t13: `Eu mesmo (usuário de IAM)`,
        t14: `Um aplicativo`,
        t15: `Descrição`,
        t16: `Vencimento`,
        t17: `Será esta chave API utilizada para armazenamento de objetos?`,
        t18: `Eu mesmo (usuário de IAM)`,
        t19: `Eu mesmo (usuário de IAM)`,
        t20: `Gerar chave API`,
        t21: `Fechar`,
    },
    logs: {
        t1: `A seguir é exibida a lista de seus registros de recursos de IAM. Os recursos de IAM podem ser usuários, aplicativos, grupos, chaves API e políticas de IAM. Você pode obter mais detalhes sobre cada um deles.`,
        t2: `Como entender meus registros?`,
        t3: `Filtrar por ID de recurso de IAM exato`,
        t4: `Data`,
        t5: `Data de iam`,
        t6: `Categoria`,
        t7: `categoria`,
        t8: `Ação`,
        t9: `Ação`,
        t10: `Adicionar`,
        t11: `Reiniciar`,
        t12: `Filtrar`,
        t13: `Logs`,
        t14: `Logs informações`,
        t15: `Estado`,
        t16: `Ativado`,
        t17: `Tipo`,
        t18: `Convidado`,
        t19: `Se juntou`,
        t20: `Último acesso`,
        t21: `Excluir registro`,
        t22: `Ao excluir um registro desta organização, suas chaves API e qualquer política diretamente associada a elas ficarão órfãs.`,
        t23: `Excluir registro`,
    },
}

export default iam