import React from "react"

const Icon = () => {
  return (
<svg width="147" height="147" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27.5419 41.1519C27.5419 37.4176 27.5419 35.5505 28.2801 34.1293C28.9022 32.9317 29.8787 31.9552 31.0764 31.333C32.4976 30.5948 34.3647 30.5948 38.0989 30.5948H65.6548C67.2433 30.5948 68.0376 30.5948 68.7866 30.7732C69.4235 30.9248 70.034 31.171 70.5979 31.5035C71.2612 31.8946 71.8333 32.4455 72.9776 33.5474L73.6546 34.1994C74.7989 35.3012 75.371 35.8522 76.0343 36.2433C76.5983 36.5758 77.2088 36.8219 77.8456 36.9736C78.5947 37.152 79.389 37.152 80.9775 37.152H108.785C112.52 37.152 114.387 37.152 115.808 37.8902C117.006 38.5123 117.982 39.4888 118.604 40.6865C119.343 42.1077 119.343 43.9748 119.343 47.7091V110.56C119.343 112.427 119.343 113.36 118.973 114.071C118.662 114.67 118.174 115.158 117.575 115.469C116.865 115.838 115.931 115.838 114.064 115.838H37.0432C33.6824 115.838 32.002 115.838 30.7229 115.174C29.6451 114.614 28.7662 113.735 28.2063 112.657C27.5419 111.378 27.5419 109.698 27.5419 106.337V41.1519Z" fill="url(#paint0_linear_26_1051)"/>
<g filter="url(#filter0_b_26_1058)">
<path d="M51.8484 60.1678C52.7756 56.9781 53.2392 55.3833 54.4637 54.4641C55.6883 53.5449 57.3486 53.5449 60.6693 53.5449H121.143C126.372 53.5449 128.987 53.5449 130.365 55.2428C131.742 56.9408 131.204 59.4992 130.126 64.6161L120.877 108.549C120.142 112.041 119.775 113.787 118.511 114.812C117.246 115.838 115.462 115.838 111.894 115.838H34.0994C37.491 112.15 37.491 108.461 40.8827 97.3961C43.316 89.4573 48.8918 70.3382 51.8484 60.1678Z" fill="var(--color-primary-4)" fillOpacity="0.6"/>
<path d="M52.1422 60.2532C52.6078 58.6514 52.9501 57.4755 53.3265 56.5834C53.6997 55.6988 54.0943 55.124 54.6474 54.7088C55.2005 54.2936 55.8625 54.0751 56.816 53.9636C57.7774 53.8513 59.0017 53.8508 60.6693 53.8508H121.143C123.767 53.8508 125.702 53.8516 127.155 54.06C128.599 54.2672 129.508 54.6724 130.127 55.4356C130.746 56.1988 130.956 57.1714 130.861 58.6275C130.765 60.0919 130.368 61.9853 129.827 64.5531L120.578 108.486C120.209 110.24 119.937 111.527 119.603 112.507C119.271 113.478 118.889 114.112 118.318 114.575C117.747 115.038 117.048 115.282 116.029 115.406C115.002 115.532 113.686 115.532 111.894 115.532H34.775C36.2197 113.805 37.006 112.01 37.7835 109.473C38.1364 108.322 38.4893 107.011 38.9008 105.484C38.9859 105.168 39.0736 104.842 39.1642 104.507C39.6938 102.549 40.3287 100.247 41.1752 97.4857C43.6093 89.5443 49.1858 70.4227 52.1422 60.2532Z" stroke="url(#paint1_linear_26_1051)" strokeWidth="0.611895"/>
</g>
<path d="M40.9011 40.4305C40.9011 38.7549 42.2594 37.3966 43.935 37.3966H57.0494C58.7249 37.3966 60.0832 38.7549 60.0832 40.4305C60.0832 42.106 58.7249 43.4643 57.0494 43.4643H43.935C42.2594 43.4643 40.9011 42.106 40.9011 40.4305Z" fill="url(#paint2_linear_26_1051)" fillOpacity="0.9" stroke="url(#paint3_linear_26_1051)" strokeWidth="0.489516"/>
<defs>
<filter id="filter0_b_26_1058" x="20.985" y="40.4305" width="123.318" height="88.5221" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="6.55719"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_26_1058"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_26_1058" result="shape"/>
</filter>
<linearGradient id="paint0_linear_26_1051" x1="54.0228" y1="49.2418" x2="123.29" y2="74.8946" gradientUnits="userSpaceOnUse">
<stop stopColor="var(--color-primary-0)"/>
<stop offset="1" stopColor="var(--color-primary-4)"/>
</linearGradient>
<linearGradient id="paint1_linear_26_1051" x1="29.1815" y1="57.9944" x2="63.9495" y2="141.837" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="0.765625" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear_26_1051" x1="39.1613" y1="36.7137" x2="66.0847" y2="44.6683" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint3_linear_26_1051" x1="37.3256" y1="38.5494" x2="50.4922" y2="43.709" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
</defs>
</svg>


  )
}


export default Icon
