const drive = {
    t1: `Rechercher la documentation`,
    t2: `Créer un nouveau`,
    t3: `Nouveau dossier`,
    t4: `Nouvelle base de données`,
    t5: `Télécharger un fichier`,
    t6: `Télécharger un dossier`,
    t7: `Utiliser`,
    t8: `Mes documents`,
    t9: `Mes documents`,
    t10: `Bientôt`,
    t11: `Prioritaires`,
    t12: `Partagés`,
    t13: `Récents`,
    t14: `Mis en avant`,
    t15: `Corbeille`,
    t16: `Corbeille`,
    t17: `Un moment...`,
    t18: `Annuler`,
    t19: `Créer`,

    x1: `Nom`,
    x2: `Dernière modification`,
    x3: `Sélectionner tous`,
    x4: `Désélectionner tous`,
    x5: `Fichiers récents`,
    x6: `Base de données`,
    x7: `Voir la base de données`,
    x8: `Voir le drive`,
    x9: `Projet`,
    x10: `Nom`,
    x11: `Nom`,
    x12: `Taille`,
    x13: `Dernière modification`,
    x14: `Éléments sélectionnés`,
    x15: `Partagé avec`,
}

export default drive