
import React from 'react'


const Icon = () => {
    return (
<svg width="147" height="147" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M106.717 118.32H41.3348C37.8505 118.32 35.026 115.496 35.026 112.011V36.3086C35.026 32.8244 37.8505 30 41.3348 30H83.776L113.026 59.2488V112.011C113.026 115.496 110.201 118.32 106.717 118.32Z" fill="url(#paint0_linear_557_3243)"/>
<g opacity="0.2" filter="url(#filter0_f_557_3243)">
<rect x="48.7907" y="72.7256" width="51.0441" height="24.0873" fill="var(--color-primary-3)" />
</g>
<path d="M59.5418 90.5791H56.2463L61.2677 76.0336H65.2307L70.2449 90.5791H66.9495L63.306 79.3575H63.1924L59.5418 90.5791ZM59.3358 84.8618H67.1199V87.2623H59.3358V84.8618ZM74.2071 76.0336L77.7227 87.0848H77.8576L81.3804 76.0336H84.7895L79.7753 90.5791H75.8122L70.7909 76.0336H74.2071ZM91.0155 76.0336V90.5791H87.9402V76.0336H91.0155Z" fill="white"/>
<mask id="mask0_557_3243"  maskUnits="userSpaceOnUse" x="35" y="30" width="79" height="89">
<path d="M106.717 118.32H41.3348C37.8505 118.32 35.026 115.496 35.026 112.011V36.3086C35.026 32.8244 37.8505 30 41.3348 30H83.776L113.026 59.2488V112.011C113.026 115.496 110.201 118.32 106.717 118.32Z" fill="url(#paint1_linear_557_3243)"/>
</mask>
<g mask="url(#mask0_557_3243)">
<ellipse opacity="0.05" cx="30.8679" cy="26.4158" rx="64.0919" ry="61.5086" fill="url(#paint2_linear_557_3243)"/>
<ellipse opacity="0.05" cx="30.8679" cy="26.415" rx="40.2904" ry="38.5683" fill="url(#paint3_linear_557_3243)"/>
</g>
<g filter="url(#filter1_d_557_3243)">
<path d="M113.026 59.2488H105.776C93.6257 59.2488 83.776 49.3991 83.776 37.2488V30L113.026 59.2488Z" fill="url(#paint4_linear_557_3243)"/>
</g>
<path opacity="0.1" fillRule="evenodd" clipRule="evenodd" d="M54 61.4927V88.5073C54 91.5408 56.4592 94 59.4927 94H86.5073C89.5408 94 92 91.5408 92 88.5073V61.4927C92 58.4592 89.5408 56 86.5073 56H59.4927C56.4592 56 54 58.4592 54 61.4927ZM57.4545 61.4927C57.4545 60.3671 58.3671 59.4545 59.4927 59.4545H62.6364V64.6364H57.4545V61.4927ZM57.4545 68.0909H62.6364V73.2727H57.4545V68.0909ZM88.5455 64.6364V61.4927C88.5455 60.3671 87.6329 59.4545 86.5073 59.4545H83.3636V64.6364H88.5455ZM83.3636 68.0909H88.5455V73.2727H83.3636V68.0909ZM79.9091 59.4545H66.0909V73.2727H79.9091V59.4545ZM88.5455 76.7273V81.9091H83.3636V76.7273H88.5455ZM88.5455 85.3636H83.3636V90.5455H86.5073C87.6329 90.5455 88.5455 89.6329 88.5455 88.5073V85.3636ZM79.9091 76.7273V90.5455H66.0909V76.7273H79.9091ZM57.4545 85.3636V88.5073C57.4545 89.6329 58.3671 90.5455 59.4927 90.5455H62.6364V85.3636H57.4545ZM62.6364 81.9091H57.4545V76.7273H62.6364V81.9091Z" fill="white"/>
<defs>
<filter id="filter0_f_557_3243" x="9.79068" y="33.7256" width="129.044" height="102.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="19.5" result="effect1_foregroundBlur_557_3243"/>
</filter>
<filter id="filter1_d_557_3243" x="60.776" y="6" width="79.25" height="79.249" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="1"/>
<feGaussianBlur stdDeviation="12.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_557_3243"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_557_3243" result="shape"/>
</filter>
<linearGradient id="paint0_linear_557_3243" x1="74.026" y1="30" x2="74.026" y2="118.32" gradientUnits="userSpaceOnUse">
<stop stopColor="var(--color-primary-3)"/>
<stop offset="0.942892" stopColor="var(--color-primary-3)"/>
</linearGradient>
<linearGradient id="paint1_linear_557_3243" x1="74.026" y1="30" x2="74.026" y2="118.32" gradientUnits="userSpaceOnUse">
<stop stopColor="var(--color-primary-3)"/>
<stop offset="0.942892" stopColor="var(--color-primary-3)"/>
</linearGradient>
<linearGradient id="paint2_linear_557_3243" x1="35.5214" y1="28.5295" x2="54.9567" y2="80.1131" gradientUnits="userSpaceOnUse">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="white"/>
</linearGradient>
<linearGradient id="paint3_linear_557_3243" x1="33.7933" y1="27.7404" x2="45.9567" y2="60.1057" gradientUnits="userSpaceOnUse">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="white"/>
</linearGradient>
<linearGradient id="paint4_linear_557_3243" x1="98.401" y1="30" x2="98.401" y2="59.2488" gradientUnits="userSpaceOnUse">
<stop stopColor="var(--color-primary-3)"/>
<stop offset="1" stopColor="var(--color-primary-3)"/>
</linearGradient>
</defs>
</svg>

    )
}

export default Icon