import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";


import sal from "sal.js";

import styles from '../index.module.css'


const Compare = ({ subTitle, title, postion, titleType }) => {
  const { t } = useTranslation()


  useEffect(() => {
    sal();
  }, []);


  const arrayData = t('home1.pricing.compare', { returnObjects: true });


  return (
    <div className={`${styles["rainbow-pricing-detailed-area"]} ${styles["mt--60"]}`}>

      <div className={styles.row}>
        <div className={styles["col-lg-12"]}>
          <div
            className={`${styles["section-title"]} ${styles[`text-${postion}`]} ${styles["mb--30"]}`}
            data-sal="slide-up"
            data-sal-duration="400"
            data-sal-delay="150"
          >
            {subTitle ? (
              <h4 className={styles.subtitle}>
                <span className={styles["theme-gradient"]}>{subTitle}</span>
              </h4>
            ) : (
              ""
            )}
            {titleType ? (
              <h2 className={`${styles.title} ${styles["w-600"]} ${styles["mb--0"]}`}>{title}</h2>
            ) : (
              <h3 className={`${styles.title} ${styles["w-600"]} ${styles["mb--0"]}`}>{title}</h3>
            )}
          </div>
        </div>
      </div>
      <div className={`${styles.row} ${styles["row--15"]}`}>
        <div className={`${styles["col-lg-12"]} ${styles["mt--30"]}`}>
          <div className={`${styles["rainbow-compare-table"]} ${styles["style-1"]}`}>

            <table className={styles["table-responsive"]}>
              <thead>
                <tr>
                  <th></th>
                  <th className={styles["style-prymary"]}>Free</th>
                  <th className={styles["style-secondary"]}>Creator</th>
                  <th className={styles["style-secondary"]}>Business</th>
                  <th className={`${styles["style-secondary"]} ${styles["sm-radius-top-right"]}`}>
                    Enterprise
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(arrayData).map((key, i) => (
                  <>
                    {arrayData[key].map((_, j) => (
                      <tr className={`${j == 0 ? styles["heading-row"] : ''}`}>
                        {Array(5).fill().map((_, k) => {
                          let item = arrayData[key][j][k]

                          if (typeof item == 'boolean') {
                            if (item) {
                              item = (
                                <span className={`${styles.icon}`}>
                                  <svg fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 11.917 9.724 16.5 19 7.5" />
                                  </svg>
                                </span>)
                            } else {
                              item = (
                                <span className={`${styles.icon} ${styles["bg-dark"]}`}>
                                  <svg fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18 17.94 6M18 18 6.06 6" />
                                  </svg>
                                </span>)
                            }
                          }
                          return (
                            <td key={`${key}-${j}-${i}`}>
                              {j == 0 ? (
                                <h6>{arrayData[key][j][k]}</h6>
                              ) : (
                                item
                              )}
                            </td>
                          )
                        })
                        }
                      </tr>
                    ))}
                  </>
                ))}




              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Compare;
