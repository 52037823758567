import React from 'react'

import SectionImage from './assets/section-image.png'
import DragNavImage from './assets/drag-nav.png'
import VectorImage from './assets/Vector.png'

import Card1Image from './assets/img-1.png'
import Card2Image from './assets/img-2.png'
import Card3Image from './assets/img-3.png'
import Card4Image from './assets/img-4.png'

const Plugins = () => {
  return (
    <div className="relative w-full sm:h-full pb-[80px]" id="uiux">
      <div className="container mx-auto">
        <section className="home-section-1 ">
          <div className="flex">
            <div className="relative lg:pt-[10px] z-[0]">
              <div className="mb-5 flex items-center ml-[-8px] absolute left-0 top-[10px] w-[300px]">
                <div className="bg-[#FFA28B] z-[-1]  w-[40px] h-[40px] p-3 flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                  >
                    <path
                      d="M7.24927 11V13.5C7.24927 14.3337 7.74927 16 9.74927 16C11.7493 16 12.2493 14.3337 12.2493 13.5V11M15.9993 11L17.8743 13.5M17.8743 13.5L19.7493 16M17.8743 13.5L19.7493 11M17.8743 13.5L15.9993 16"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.4993 26C20.4028 26 25.9993 20.4036 25.9993 13.5C25.9993 6.59644 20.4028 1 13.4993 1C6.59571 1 0.999268 6.59644 0.999268 13.5C0.999268 20.4036 6.59571 26 13.4993 26Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="ml-4">
                  <h4 className="text-[#FFA28B] font-extrabold uppercase text-[25px]">
                    UX/AY
                  </h4>
                </div>
              </div>
              <div className="ux-left-border mt-[70px]"></div>
            </div>
            <div>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-[100px]">
                <div className="col-span-2 relative">
                  <div className="w-full">
                    <div className="lg:pl-10 ">
                      <div className="flex items-center">
                        <div>
                          <img
                            src={VectorImage}
                            className="lg:w-[400px] z-20 ml-[8px] lg:ml-[-33px] w-[200px]"
                          />
                        </div>
                        <p className="text-[24px] text-[#554D56] lg:text-[48px] leading-tight drop-shadow-xl text-left pl-[30px] lg:pl-0">
                          <span className="text-[#FFA28B]">
                            Wireframes, preview + publish, configuración app.
                          </span>
                          Crea bocetos rápidos y listos para compilar donde
                          describir los flujos de datos, realizar una
                          previsualización rápida en 3D o incluso lanzar tu
                          proyecto en unos minutos.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-end relative">
                  <div className="w-full absolute lg:left-[0px] lg:top-[-200px] ">
                    <img src={SectionImage} className="z-20" />
                  </div>
                  <div className="w-full initial absolute lg:left-[0px] lg:top-[350px] top-[-280px] right-[0] z-20 flex justify-end lg:block">
                    <img
                      src={DragNavImage}
                      className="lg:w-[100px] z-20 w-[45px]"
                    />
                  </div>
                </div>
              </div>

              <div className="lg:mt-[0px]">
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3">
                  <div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto]  lg:h-[430px] relative mb-8 lg:mb-0">
                      <p className="mt-0 lg:text-[13px] uppercase">
                        PRIMER BENEFICIO
                      </p>
                      <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                        Planificación y estructura
                      </h5>
                      <div className="lg:w-[90%]">
                        <p className="lg:text-[15px] mt-5">
                          Los wireframes permiten establecer una estructura
                          clara y organizada del diseño antes de sumergirse en
                          los detalles visuales. Ayudan a definir la disposición
                          de los elementos en la interfaz y la navegación entre
                          las diferentes secciones.
                        </p>
                        <img
                          src={Card1Image}
                          className="lg:absolute bottom-[-50px] right-[-30px] w-[200px] lg:w-[60%] z-20"
                        />
                      </div>
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[400px] relative mt-5 mb-8 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          CUARTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Foco en la usabilidad
                        </h5>
                      </div>
                      <div className="lg:w-[40%]">
                        <p className="lg:text-[15px]">
                          Los wireframes se centran en la disposición de los
                          elementos y la navegación, lo que permite asegurarse
                          de que el diseño esté centrado en la usabilidad y la
                          experiencia del usuario desde las primeras etapas del
                          proyecto.
                        </p>
                      </div>
                      <img
                        src={Card3Image}
                        className="lg:absolute bottom-[0px] right-[-40px]  w-[200px] lg:w-[60%] z-20"
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[430px] relative mt-5 mb-8 lg:mb-0">
                      <p className="mt-0 lg:text-[13px] uppercase">
                        SEGUNDO BENEFICIO
                      </p>
                      <h5 className="lg:text-[30px]  my-2 font-bold lg:font-light">
                        Comunicación y colaboración
                      </h5>
                      <div className="lg:w-[90%]">
                        <p className="lg:text-[15px]">
                          Los wireframes proporcionan una representación visual
                          que facilita la comunicación entre los miembros del
                          equipo y los interesados del proyecto. Al ser
                          esquemáticos y no estar enfocados en detalles
                          visuales, son más fáciles de entender y pueden
                          involucrar a todas las partes interesadas en la toma
                          de decisiones tempranas.
                        </p>
                      </div>
                      <img
                        src={Card2Image}
                        className="lg:absolute bottom-[0px] right-[50px] z-20  w-[200px] lg:w-[50%]"
                      />
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[350px] relative mt-5 mb-8 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          QUINTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Ahorro de tiempo y recursos
                        </h5>
                      </div>
                      <div className="lg:w-[80%]">
                        <p className="lg:text-[15px]">
                          Al establecer la estructura y el flujo de un diseño a
                          través de wireframes, se reducen las posibilidades de
                          cambios drásticos y costosos más adelante en el
                          proceso de desarrollo.
                        </p>
                      </div>
                      <img
                        src={Card4Image}
                        className="z-20 lg:absolute bottom-[-100px] right-[-30px]  w-[200px] lg:w-[60%]"
                      />
                    </div>
                  </div>
                  <div className="relative md:col-span-2 lg:col-span-1 md:grid grid-cols-2 lg:block gap-4">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 relative h-[auto] lg:h-[250px]  lg:mt-[180px] mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          TERCER BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Iteración rápida
                        </h5>
                        <p className="lg:text-[15px] mt-3">
                          Al trabajar con wireframes, los diseñadores pueden
                          experimentar y probar diferentes ideas de diseño sin
                          dedicar tiempo excesivo a la creación de elementos
                          visuales detallados. Esto facilita la iteración rápida
                          y la evolución del diseño.
                        </p>
                      </div>
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[250px] relative  mt-5 mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          SEXTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Evaluación temprana de conceptos
                        </h5>
                        <p className="lg:text-[15px] mt-3">
                          Los wireframes permiten a los diseñadores y
                          stakeholders evaluar la viabilidad y eficacia de un
                          diseño en una etapa temprana del proceso, lo que ayuda
                          a identificar posibles problemas y mejoras antes de
                          avanzar hacia el desarrollo completo.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Plugins
