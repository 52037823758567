import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Sal from 'sal.js'

import PricingData from '@home/assets/constants/pricing.json'

import SplitImg from '@home/assets/images/split/split-2.png'
import SplitLogo from '@home/assets/images/split/split-2-logo.png'
import DarkSplitImg from '@home/assets/images/light/split/split-2.png'
import DarkSplitLogo from '@home/assets/images/light/split/split-2-logo.png'

import bannerImg from '@home/assets/images/desktop/001.png'
import bannerWhiteImg from '@home/assets/images/desktop/001.png'

import shapeOne from '@home/assets/images/bg/icon-shape/icon-shape-one.png'
import shapeTwo from '@home/assets/images/bg/icon-shape/icon-shape-two.png'
import shapeThree from '@home/assets/images/bg/icon-shape/icon-shape-three.png'
import shapeFour from '@home/assets/images/bg/icon-shape/icon-shape-four.png'
import bgShape from '@home/assets/images/bg/split-bg-shape.png'
import bgShapeOne from '@home/assets/images/bg/bg-shape-four.png'
import bgShapeTwo from '@home/assets/images/bg/bg-shape-five.png'
import bgShapeThree from '@home/assets/images/bg/bg-shape-two.png'

import BrandList from '@home/Brands/BrandList'
import TabStyleOne from '@home/TabStyles/TabStyle-One'
import ServiceStyleOne from '@home/Services/ServiceStyle-One'
import AdvanceTab from '@home/TabStyles/AdvanceTab'
import CtaOne from '@home/CallToActions/Cta-One'
import Pricing from '@home/Pricing/Pricing'
import ServiceTwo from '@home/Services/Service-Two'
import Testimonial from '@home/Testimonials/Testimonial'
import BrandTwo from '@home/Brands/Brand-Two'
import CtaTwo from '@home/CallToActions/Cta-Two'
import { useAppContext } from '@home/context/Context'

import Intro from '@home/Home/Intro'
import IntroSecond from '@home/Home/IntroSecond'
import IntroThird from '@home/Home/IntroThird'

import styles from '../index.module.css'

import Form from '../Form'
import MeetOptionsPopup from '../../../app/v1-1/pages/Meet/components/MeetOptionsPopup'
import MeetForLaterPopup from '../../../app/v1-1/pages/Meet/components/MeetForLaterPopup'

const Home = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const [visibleIndex, setVisibleIndex] = useState(0)
	const { isLightTheme } = useAppContext()

	useEffect(() => {
		Sal()

		const intervalId = setInterval(() => {
			setVisibleIndex((prevIndex) => (prevIndex + 1) % 4)
		}, 2000)

		return () => {
			clearInterval(intervalId)
		}
	}, [])

	// ------------------
	const [isShowModal, setIsShowModal] = useState(false)

	const handleOpen = () => {
		setIsShowModal(true)
	}

	useEffect(() => {
		const form = localStorage.getItem('form')
		if (!form) {
			localStorage.setItem('form', true)
			setIsShowModal(true)
		}
	}, [])

	const [showMeetPopup, setShowMeetPopup] = useState(false)

	const [showLater, setShowLater] = useState(false)

	return (
		<>
			<div
				className={`${styles['slider-area']} ${styles['slider-style-1']} ${styles['variation-default']} ${styles['slider-bg-image']} ${styles['bg-banner1']} ${styles['slider-bg-shape']}`}
				data-black-overlay='1'
			>
				{isShowModal && (
					<Form isShowModal={isShowModal} setIsShowModal={setIsShowModal} />
				)}

				<IntroSecond />
				<Intro />
				<IntroThird />
				<div className={`${styles.container}`}>
					<div className={`${styles.row} ${styles['justify-content-center']}`}>
						<div className={`${styles['col-lg-12']}`}>
							<div
								className={`${styles.inner} ${styles['text-center']} ${styles['mt--140']}`}
							>
								<h1 className={`${styles.title} ${styles['display-one']}`}>
									{t('home1.web.component1.text1')}
									<br />{' '}
									<span className={styles['header-caption']}>
										<span
											className={`${styles['cd-headline']} ${styles['rotate-1']}`}
										>
											<span className={`${styles['cd-words-wrapper']}`}>
												<b
													className={
														visibleIndex === 0
															? `${styles['is-visible']} ${styles['theme-gradient']}`
															: `${styles['is-hidden']} ${styles['theme-gradient']}`
													}
												>
													{t('home1.web.component1.label1')}
												</b>
												<b
													className={
														visibleIndex === 1
															? `${styles['is-visible']} ${styles['theme-gradient']}`
															: `${styles['is-hidden']} ${styles['theme-gradient']}`
													}
												>
													{t('home1.web.component1.label2')}
												</b>
												<b
													className={
														visibleIndex === 2
															? `${styles['is-visible']} ${styles['theme-gradient']}`
															: `${styles['is-hidden']} ${styles['theme-gradient']}`
													}
												>
													{t('home1.web.component1.label3')}
												</b>
												<b
													className={
														visibleIndex === 3
															? `${styles['is-visible']} ${styles['theme-gradient']}`
															: `${styles['is-hidden']} ${styles['theme-gradient']}`
													}
												>
													{t('home1.web.component1.label4')}
												</b>
											</span>
										</span>
									</span>{' '}
									{t('home1.web.component1.text2')}
								</h1>
								<p className={`${styles.description}`}>
									{t('home1.web.component1.text3')}
								</p>
								<div className={`${styles['form-group']}`}>
									<textarea
										name='text'
										id='slider-text-area'
										cols='30'
										rows='2'
										placeholder={t('home1.web.component1.holder1')}
									></textarea>
									<button
										className={`${styles['btn-default']}`}
										style={{ position: 'relative' }}
										onClick={() => {
											setShowMeetPopup(true)
										}}
									>
										{t('home1.web.component1.button1')}
										{showMeetPopup && (
											<MeetOptionsPopup
												setShowLater={setShowLater}
												onClose={() => setShowMeetPopup(false)}
												goToMeet={() => navigate('/es/v1-1/app/meet/home')}
												fromHome={true}
											/>
										)}
										{showLater && <MeetForLaterPopup />}
									</button>
								</div>
								<div className={`${styles['inner-shape']}`}>
									<img
										src={shapeOne}
										width={100}
										height={95}
										alt='Icon Shape'
										className={`${styles.iconshape} ${styles['iconshape-one']}`}
									/>
									<img
										src={shapeTwo}
										width={60}
										height={57}
										alt='Icon Shape'
										className={`${styles.iconshape} ${styles['iconshape-two']}`}
									/>
									<img
										src={shapeThree}
										width={42}
										height={31}
										alt='Icon Shape'
										className={`${styles.iconshape} ${styles['iconshape-three']}`}
									/>
									<img
										src={shapeFour}
										width={100}
										height={95}
										alt='Icon Shape'
										className={`${styles.iconshape} ${styles['iconshape-four']}`}
									/>
								</div>
							</div>
						</div>
						<div
							className={`${styles['col-lg-11']} ${styles['col-xl-11']} ${styles['justify-content-center']}`}
						>
							<div className={`${styles['slider-frame']}`}>
								<img
									className={`${styles['slider-image-effect']}`}
									src={isLightTheme ? bannerImg : bannerWhiteImg}
									width={1055}
									height={898}
									alt='Banner imgs'
								/>
							</div>
						</div>
					</div>
				</div>

				<div className={`${styles['bg-shape']}`}>
					<img
						className={`${styles['bg-shape-one']}`}
						width={640}
						height={949}
						src={bgShapeOne}
						alt='Bg Shape'
					/>
					<img
						className={styles['bg-shape-two']}
						src={bgShapeTwo}
						width={626}
						height={1004}
						alt='Bg Shape'
					/>
				</div>
			</div>

			<div
				className={`${styles['rainbow-brand-area']} ${styles['rainbow-section-gap']}`}
			>
				<div className={styles.container}>
					<div className={`${styles.row}`}>
						<div className={styles['col-lg-12']}>
							<div
								className={`${styles['section-title']} ${styles['rating-title']} ${styles['text-center']} ${styles['sal-animate']}`}
								data-sal='slide-up'
								data-sal-duration='700'
								data-sal-delay='100'
							>
								<p
									className={`${styles.b1} ${styles['mb--0']} ${styles['small-title']}`}
								>
									{t('home1.web.component2.text1')}
								</p>
							</div>
						</div>
					</div>
					<div className={`${styles.row}`}>
						<div className={`${styles['col-lg-12']} ${styles['mt--10']}`}>
							<BrandList />
						</div>
					</div>
				</div>
			</div>

			<div
				className={`${styles['rainbow-service-area']} ${styles['rainbow-section-gap']}`}
			>
				<div className={`${styles.container}`}>
					<div className={`${styles.row}`}>
						<div className={styles['col-lg-12']}>
							<div
								className={`${styles['section-title']} ${styles['text-center']} ${styles['pb--60']}`}
								data-sal='slide-up'
								data-sal-duration='700'
								data-sal-delay='100'
							>
								<h4 className={styles.subtitle}>
									<span className={`${styles['theme-gradient']}`}>
										{t('home1.web.component2.text2')}
									</span>
								</h4>
								<h2 className={`${styles.title} ${styles['mb--0']}`}>
									{t('home1.web.component2.text3')}
								</h2>
							</div>
						</div>
					</div>
					<TabStyleOne />
				</div>
			</div>

			<div
				className={`${styles['mt--60']} ${styles['rainbow-service-area']} ${styles['rainbow-section-gap']} ${styles['rainbow-section-gapBottom-big']}`}
			>
				<div className={styles.container}>
					<div className={`${styles.row}`}>
						<div className={`${styles['col-lg-12']}`}>
							<div
								className={`${styles['section-title']} ${styles['text-left']}`}
								data-sal='slide-up'
								data-sal-duration='400'
								data-sal-delay='150'
							>
								<h4 className={styles.subtitle}>
									<span className={`${styles['theme-gradient']}`}>
										{t('home1.web.component3.text1')}
									</span>
								</h4>
								<h2 className={`${styles.title} ${styles['mb--60']}`}>
									{t('home1.web.component3.text2')}
								</h2>
							</div>
						</div>
					</div>
				</div>
				<ServiceStyleOne />
			</div>

			<div
				className={`${styles['rainbow-advance-tab-area']} ${styles['aiwave-bg-gradient']} ${styles['rainbow-section-gap-big']}`}
			>
				<div className={`${styles.container}`}>
					<div className={`${styles['html-tabs']}`} data-tabs='true'>
						<AdvanceTab />
					</div>
				</div>
				<div className={`${styles['bg-shape']}`}>
					<img src={bgShape} width={630} height={879} alt='Bg Shape' />
				</div>
			</div>

			<div
				className={`${styles['rainbow-collobration-area']} ${styles['rainbow-section-gap-big']}`}
			>
				<div className={`${styles.container}`}>
					<div className={`${styles.row}`}>
						<div className={`${styles['col-lg-12']}`}>
							<div
								className={`${styles['section-title']} ${styles['text-center']}`}
								data-sal='slide-up'
								data-sal-duration='700'
								data-sal-delay='100'
							>
								<h4 className={`${styles.subtitle}`}>
									<span className={`${styles['theme-gradient']}`}>
										{t('home1.web.component4.text1')}
									</span>
								</h4>
								<h2 className={`${styles.title} ${styles['mb--20']}`}>
									{t('home1.web.component4.text2')}
								</h2>
								<button
									className={`${styles['btn-default']} ${styles['btn-large']} ${styles['color-blacked']}`}
									onClick={() => navigate('/es/login')}
								>
									{t('home1.web.component4.button1')}
									<svg fill='none' viewBox='0 0 24 24'>
										<path
											stroke='currentColor'
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M19 12H5m14 0-4 4m4-4-4-4'
										/>
									</svg>
								</button>
							</div>
						</div>
					</div>
					<div className={`${styles.row}`}>
						<div className={`${styles['col-lg-12']} ${styles['mt--60']}`}>
							<div className={`${styles['collabration-image-section']}`}>
								<img
									src={!isLightTheme ? SplitImg : DarkSplitImg}
									width={1305}
									height={712}
									alt='collabration-image'
								/>
								<div className={`${styles['logo-section']}`}>
									<div className={`${styles['center-logo']}`}>
								
										<svg
											style={{ zIndex: 30 }}
											width='133'
											height='133'
											viewBox='0 0 222 133'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											{' '}
											<path
												d='M20.0248 0.222384C16.9731 2.92022 13.6417 6.22923 10.3175 10.2326C5.75094 15.7259 2.41954 21.0529 0 25.5951C17.9286 25.2117 31.77 25.313 38.887 25.6168C40.5436 25.6891 43.3555 25.8627 46.3091 27.4864C50.1455 29.5912 52.0309 32.8857 53.7602 35.9091C55.2788 38.5671 56.1471 40.9612 56.6448 42.6175C46.567 64.4967 36.4892 86.3724 26.4115 108.252H54.6212L70.806 72.9808L94.6308 124.048C95.223 125.299 96.9086 128.446 100.611 130.695C104.229 132.894 107.873 132.908 109.758 132.919C117.358 132.966 128.9 133.071 143.356 132.926C146.585 129.831 150.229 125.878 153.811 120.974C157.383 116.085 160.035 111.43 162 107.427C144.932 107.467 131.821 107.387 124.824 107.366C123.756 107.362 121.565 107.333 119.051 106.302C113.969 104.219 111.506 99.6663 110.336 97.5037C109.493 95.945 108.974 94.5599 108.654 93.5762C88.4003 49.3548 73.1383 17.0423 69.1748 8.94153C68.6189 7.8096 67.2675 5.07198 64.3684 2.96723C60.503 0.160904 56.1071 0.0668788 53.6658 0.0487967C45.4263 -0.0126821 33.9716 -0.0669284 20.0248 0.222384Z'
												fill='white'
											/>{' '}
										</svg>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>



			<div
				className={`${styles['rainbow-cta-area']} ${styles['rainbow-section-gap']} ${styles['rainbow-section-gapBottom-big']}`}
			>
				<div className={`${styles.container}`}>
					<CtaTwo />
				</div>
			</div>

		

			<div
				className={`${styles['aiwave-service-area']} ${styles['rainbow-section-gap']}`}
			>
				<div className={`${styles.container}`}>
					<div
						className={`${styles.row} ${styles['row--15']} ${styles['service-wrapper']}`}
					>
						<ServiceTwo />
					</div>
				</div>
			</div>

			<div
				className={`${styles['rainbow-testimonial-area']} ${styles['rainbow-section-gap']}`}
			>
				<div className={styles.container}>
					<div className={`${styles.row}`}>
						<div className={`${styles['col-lg-12']}`}>
							<div
								className={`${styles['section-title']} ${styles['text-left']}`}
								data-sal='slide-up'
								data-sal-duration='400'
								data-sal-delay='150'
							>
								<h4 className={styles.subtitle}>
									<span className={`${styles['theme-gradient']}`}>
										{t('home1.web.component6.text1')}
									</span>
								</h4>
								<h2 className={`${styles.title} ${styles['mb--60']}`}>
									{t('home1.web.component6.text2')}
								</h2>
							</div>
						</div>
					</div>
				</div>
				<Testimonial />
			</div>

			<div
				className={`${styles['rainbow-brand-area']} ${styles['rainbow-section-gap']}`}
			>
				<div className={`${styles.container}`}>
					<div className={styles.row}>
						<div className={`${styles['col-lg-12']}`}>
							<div
								className={`${styles['section-title']} ${styles['rating-title']} ${styles['text-center']} ${styles['sal-animate']}`}
								data-sal='slide-up'
								data-sal-duration='700'
								data-sal-delay='100'
							>
								<div className={styles.rating}>
									<a href='#rating'>
										<svg fill='currentColor' viewBox='0 0 24 24'>
											<path d='M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z' />
										</svg>
									</a>
									<a href='#rating'>
										<svg fill='currentColor' viewBox='0 0 24 24'>
											<path d='M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z' />
										</svg>
									</a>
									<a href='#rating'>
										<svg fill='currentColor' viewBox='0 0 24 24'>
											<path d='M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z' />
										</svg>
									</a>
									<a href='#rating'>
										<svg fill='currentColor' viewBox='0 0 24 24'>
											<path d='M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z' />
										</svg>
									</a>
									<a href='#rating'>
										<svg fill='currentColor' viewBox='0 0 24 24'>
											<path d='M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z' />
										</svg>
									</a>
								</div>
								<p className={`${styles.subtitle} ${styles['mb--0']}`}>
									{t('home1.web.component6.text3')}
								</p>
							</div>
						</div>
					</div>
					<BrandTwo />
					<div className={styles['bg-shape-left']}>
						<img src={bgShapeThree} width={688} height={1055} alt='Bg shape' />
					</div>
				</div>
			</div>

			<div
				className={`${styles['aiwave-pricing-area']} ${styles['wrapper']} ${styles['rainbow-section-gap-big']}`}
			>
				<div className={`${styles.container}`}>
					<Pricing
						start={0}
						end={3}
						isBadge={true}
						gap={`${styles['mt_dec--40']}`}
					/>
				</div>
			</div>


		</>
	)
}

export default Home
