
const pricing = {
    "t0": "Analysera och utforska dina mått",
    "t1": "Inga överraskningar, så är våra priser",
    "t2": "Prova 7 dagar gratis",
    "t3": "MEST POPULÄR",
    "t4": "149€",
    "t5": "/månad",
    "t6": "Företag",
    "t7": "Företag som behöver kontinuerlig analys enligt olika aktörer.",
    "t8": "5 användare inkluderade",
    "t9": "10 visuella Add-ons",
    "t10": "Vektoriserade data",
    "t11": "50 datagrafiker",
    "t12": "100 frågor till datan",
    "t13": "200 klick på HeatMap",
    "t14": "100GB data",
    "t15": "Prioriterad support",
    "t16": "Videokonferenser",
    "t17": "Sökmotor",
    "t18": "Välj plan",
    "t19": "49€",
    "t20": "/månad",
    "t21": "Basic",
    "t22": "Riktad mot nya idéer",
    "t23": "som vill analysera sina KPI för att definiera strategier.",
    "t24": "1 användare inkluderad",
    "t25": "5 visuella Add-ons",
    "t26": "Vektoriserade data",
    "t27": "10 datagrafiker",
    "t28": "20 frågor till datan",
    "t29": "50 klick på HeatMap",
    "t30": "5GB data",
    "t31": "Support via e-post",
    "t32": "Videokonferenser",
    "t33": "Sökmotor",
    "t34": "Välj plan",
    "t35": "349€",
    "t36": "/månad",
    "t37": "Start Up",
    "t38": "För Start Up-team redo att skapa sina egna analyser utan begränsningar.",
    "t39": "15 användare inkluderade",
    "t40": "Obegränsade Add-ons",
    "t41": "Vektoriserade data",
    "t42": "Obegränsade grafiker",
    "t43": "Obegränsade frågor",
    "t44": "500 klick på HeatMap",
    "t45": "500GB data",
    "t46": "Brådskande support",
    "t47": "Videokonferenser",
    "t48": "Sökmotor",
    "t49": "Välj plan",
    "t50": "GRATIS",
    "t51": "*7 dagar",
    "t52": "Utforskning",
    "t53": "Perfekt för att börja med punktuella analyser, utan att kunna skapa på lång sikt.",
    "t54": "1 användare inkluderad",
    "t55": "1 visuellt Add-on",
    "t56": "Vektoriserade data",
    "t57": "1 datagrafik",
    "t58": "5 frågor till datan",
    "t59": "Ingen HeatMap",
    "t60": "500MB data",
    "t61": "Ingen support",
    "t62": "Videokonferenser",
    "t63": "Sökmotor",
    "t64": "Välj plan",
    "t65": "Om du behöver fler användare och fler alternativ, kontakta vårt säljteam",
    "t66": "Försäljning",
    "t67": "Jämför mellan betalplanerna",
    "t68": "Och upptäck vilken som passar dina behov",
    "t69": "Basic",
    "t70": "Börja",
    "t71": "Företag",
    "t72": "Förstå",
    "t73": "Start Up",
    "t74": "Analysera",
    "t75": "KAPACITET OCH ÅTKOMST",
    "t76": "Användare",
    "t77": "1",
    "t78": "5",
    "t79": "15",
    "t80": "Add-ons",
    "t81": "Obegränsade",
    "t82": "5",
    "t83": "10",
    "t84": "Vektoriserade data",
    "t85": "GB data",
    "t86": "Upp till 5 GB",
    "t87": "Upp till 100 GB",
    "t88": "Upp till 500 GB",
    "t89": "Personlig representation",
    "t90": "Antal grafiker",
    "t91": "10",
    "t92": "50",
    "t93": "Obegränsade",
    "t94": "Antal frågor",
    "t95": "20",
    "t96": "100",
    "t97": "Obegränsade",
    "t98": "Klick med Värme karta",
    "t99": "50",
    "t100": "200",
    "t101": "500",
    "t102": "Videokonferenser och sökning",
    "t103": "PERSONALISERING OCH KOD",
    "t104": "Personligt logo",
    "t105": "snart",
    "t106": "Fördefinierade teman",
    "t107": "Personliga teman",
    "t108": "snart",
    "t109": "Dela med subdomän",
    "t110": "Dela med domän",
    "t111": "snart",
    "t112": "Åtkomst till IAM och bank",
    "t113": "Användning av Add-ons",
    "t114": "snart",
    "t115": "Redigering av prompts",
    "t116": "Åtkomst till källkod",
    "t117": "snart",
    "t118": "Redigering i realtid",
    "t119": "snart",
    "t120": "RAPPORTER",
    "t121": "Exportera till PDF",
    "t122": "Skicka via e-post",
    "t123": "Dela via länk",
    "t124": "SUPPORT",
    "t125": "Support via e-post",
    "t126": "Prioriterad support",
    "t127": "Hjälp one-to-one",
    "t128": "Och vad om jag behöver mer för att analysera?",
    "t129": "Du kan byta plan. Men om det skalar för mycket dina behov kan du välja utifrån fler grafiker, fler frågor, fler add-ons för dina gränssnitt, fler klick, fler GB data... Dina extra funktioner kommer att läggas till din befintliga kvot. Du kan avbryta när som helst. Och om du behöver en personlig plan, kontakta med försäljning.",
    "t130": "Grafiker",
    "t131": "Paket 10",
    "t132": "15€",
    "t133": "Frågor",
    "t134": "Paket 20",
    "t135": "10€",
    "t136": "Add-on",
    "t137": "Extra",
    "t138": "10€",
    "t139": "Värme karta",
    "t140": "Klick",
    "t141": "0.5€",
    "t142": "GB moln",
    "t143": "GB",
    "t144": "1€"
  }
  
  export default pricing