const web = {
    t1: {
        '01': `Modélisez vos`,
        '02': `données`,
        '03': `de manière avancée,`,
        '04': `prédisez votre`,
        '05': `matrice`,
        '06': `à toute réalité possible et analysez le`,
        '07': `big data`,
        '08': `avec des bases de données vectorisées`,
    },
    t2: `COMMENCEZ MAINTENANT`,
    t3: `Sur votre nouveau système d'exploitation`,
    t4: {
        '01': `Hors ligne`, 
        '02': `En ligne`, 
        '03': `Public`, 
        '04': `Privé`, 
    },
    tassistant: {
        '01': `Vitesse`,
        '02': `Texture`,
        '03': `Traitement`,
    },
    t5: `Un monde de possibilités`,
    t6: {
        '01': `Grâce aux`,
        '02': `bases de données vectorisées,`,
        '03': `vous passez de`,
        '04': `données statiques`,
        '05': `à`,
        '06': `données dynamiques.`,
        '07': `Travailler avec beaucoup de données simultanément est difficile, c'est pourquoi`,
        '08': `une latence nulle dans certaines dimensions`,
        '09': `est nécessaire pour un accès rapide et polyvalent.`,
    },
    t7: `Entrez maintenant`,
    t8: `GAGNEZ DU TEMPS . GAGNEZ DU TEMPS . GAGNEZ DU TEMPS`,
    t9: `Tirez vos propres conclusions`,
    t10: `L'information, c'est le pouvoir`,
    t11: {
        '01': `Maintenant, vous pouvez comprendre vos données !`,
        '02': `Grâce à la segmentation, avec le concept de`,
        '03': `K-Means,`,
        '04': `vous disposez d'un outil pour analyser n'importe quelle donnée à mesure.`,
    },
    t12: `Commencez maintenant`,
    t13: `Espace disponible dans le cloud. Tout est évolutif sur des instances privées et sécurisées.`,
    t14: `fois plus rapide que de travailler avec des bases de données traditionnelles.`,
    t15: `Représentations possibles pour importer vos indicateurs et vos KPI.`,
    t16: `Analyser des données entre dimensions`,
    t17: `Chaque dimension a des agents.`,
    t18: {
        '01': `Dans chaque dimension, vous pouvez créer`,
        '02': `des flux d'agents`,
        '03': `pour créer des réseaux d'interactions.`
    },
    t19: {
        '01': `Dans chaque agent, vous choisirez la`,
        '02': `stratégie`,
        '03': `pour relier`,
        '04': `chaque donnée.`,
        '05': `chaque donnée.`,
    },
    t20: `Commencez votre essai GRATUIT`,
    t21: `Dimensions`,
    t22: `Agents`,
    t23: `Données gérant des idées`,
    t24: `Vos données, le premier pas`,
    t25: {
        '01': `Elles sont votre clé en main pour construire`,
        '02': `des applications incroyables. Elles ne sont pas seulement des informations,`,
        '03': `elles sont la matière première`,
        '04': `pour la création d'idées innovantes et leur analyse.`,
        '05': `Comment travaillons-nous avec les données ?`,
    },
    t26: `Votre espace de travail`,
    t27: `Stockez tout le contenu dont vous avez besoin dans votre Cloud privé ou public selon vos besoins.`,
    t28: `Carte de chaleur`,
    t29: `Représentation montrant la distribution et la concentration de certaines valeurs au sein d'un ensemble de données.`,
    t30: `Questions dynamiques`,
    t31: `Création de questionnaires interactifs, où les questions peuvent s'adapter aux données préexistantes.`,
    t32: `Graphiques sur mesure`,
    t33: `Permettant de sélectionner le type de graphique, les données à visualiser et de personnaliser des aspects tels que les couleurs et les étiquettes.`,
    t34: `Appels vidéo`,
    t35: `Communication en temps réel via vidéo et audio, facilitant la collaboration et l'interaction.`,
    t36: `Contrats`,
    t37: `Création, gestion et facturation électronique de contrats, simplifiant les processus juridiques et administratifs.`,
    t38: `Rôles et autorisations`,
    t39: `Attribuez différents rôles et niveaux d'accès aux utilisateurs au sein de votre propre équipe pour créer votre organisation.`,
    t40: `Synchronisation en équipe`,
    t41: `Collaboration en temps réel entre plusieurs utilisateurs, assurant que les changements effectués sont automatiquement sécurisés.`,
    t42: `Tous vos contacts`,
    t43: `Centralisez et organisez tous vos contacts. Gérez et accédez facilement aux informations de vos contacts.`,
    t44: `Apprenez à le faire, c'est très facile`,
    t45: `Changer de dimensions`,
    t46: {
        '01': `Rendez vos données intelligentes, connectables, cohérentes, sécurisées, efficaces et accessibles. Renforcez vos analyses grâce à`,
        '02': `notre accès.`
    },
    t47: {
        '01': `Commencez par `,
        '02': `le tutoriel`,
        '03': `pour comprendre les différentes parties de la plateforme.`,
    },
    t48: `Inscrivez-vous MAINTENANT`,
    t49: `Appréciez votre temps et automatisez vos données`,
    t50: {
        '01': `Et commencez à changer de dimensions dans notre système d'exploitation cloud. Analysez et comprenez les modèles pour`,
        '02': `travailler vos données.`,
    },

    footer: {
        t00: "ACCUEIL",
        t01: "Votre équipe",
        t02: "Mises à jour",
        t03: "Kit numérique",
        t04: "Consultation",
        t05: "Études",
    
        t10: "LIENS",
        t11: "Équipe",
        t12: "Travailleurs indépendants",
        t13: "Conditions",
        t14: "Fonctionnalités",
        t15: "Calculatrice",
        t16: "Plans",
    
        t20: "MODULE IA",
        t21: "Voix",
        t22: "Image & Vidéo",
        t23: "Création de contenu",
        t24: "Création de médias",
    
        t30: "SUPPORT AYTHEN",
        t31: "Accord utilisateur",
        t32: "Politique de confidentialité",
        t33: "Collecte et utilisation des données",
        t34: "Directives de contenu",
    
        t40: "POUR CONCEVOIR",
        t41: "Éditeur",
        t42: "Applications",
        t43: "Cloud",
        t44: "Plugins",
        t45: "RPA",
    
        t50: "POUR TROUVER",
        t51: "Bibliothèque",
        t52: "Préréglages",
        t53: "Devops",
        t54: "IDE",
        t55: "Sandbox",
    
        t60: "POUR CONSTRUIRE",
        t61: "UX/AI",
        t62: "Traduire",
        t63: "Docs IA",
        t64: "Support IA",
        t65: "Collaborations",
    
        t70: "POUR PROTÉGER",
        t71: "Blockchain",
        t72: "Données",
        t73: "Pivot",
        t74: "Académie",
        t75: "Travailleurs indépendants",
    }
    
}

export default web
