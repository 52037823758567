import React, { useEffect, useState } from "react";
import "venobox/dist/venobox.min.css";

import darkBg from "@home/assets/images/light/service/bg-testimonial.png";
import darkBgHover from "@home/assets/images/light/service/bg-testimonial-hover.png";

import { useAppContext } from "@home/context/Context";

import TestimonialData from "@home/assets/constants/testimonial.json";


import styles from '../index.module.css'



const TestimonialTwo = () => {
  const { isLightTheme } = useAppContext();


  const [showToggle, setShowToggle] = useState(false);
  useEffect(() => {
    import("venobox/dist/venobox.min.js").then((venobox) => {
      new venobox.default({
        selector: ".popup-video",
        maxWidth: window.innerWidth >= 992 ? "70%" : "100%",
      });
    });
  }, []);
  return (
    <>
      <div className={`${styles.row}`} >
        <div className={`${styles["col-lg-12"]}`} >
          <div
            className={`${styles["section-title"]} ${styles["text-center"]}`}
            data-sal="slide-up"
            data-sal-duration="400"
            data-sal-delay="150"
          >
            <h4 className={styles.subtitle} >
              <span className={`${styles["theme-gradient"]}`} >customer saying</span>
            </h4>
            <h2 className={`${styles.title} ${styles["w-600"]} ${styles["mb--20"]}`} >
              The opinions of the community
            </h2>
          </div>
        </div>
      </div>

      <div
        className={`${styles["testimonial-wrapper"]} ${styles["has-show-more"]} `}
      >
        <div className={`${styles['has-show-more-inner-content']} ${styles['large-height']} ${showToggle ? styles.active : ""}`} >
          <div className={`${styles.row} ${styles["row--15"]}`} >
            {TestimonialData &&
              TestimonialData.testimonial.map((data, index) => (
                <div
                  className={`${styles["col-lg-4"]} ${styles["col-md-6"]} ${styles["col-12"]} ${styles["mt--30"]} ${styles["sal-animate"]}`}
                  data-sal="slide-up"
                  data-sal-duration="700"
                  key={index}
                >
                  <div
                    className={`${styles["rainbow-box-card"]} ${data.isBorder ? styles.active : ""
                      } ${styles["card-style-default"]} ${styles["testimonial-style-defalt"]} ${styles["has-bg-shaped"]}`}
                  >
                    <div className={`${styles.inner}`} >
                      <div className={`${styles.rating}`} >
                        <a href="#rating">
                          <svg fill="currentColor" viewBox="0 0 24 24">
                            <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                          </svg>

                        </a>
                        <a href="#rating">

                          <svg fill="currentColor" viewBox="0 0 24 24">
                            <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                          </svg>
                        </a>
                        <a href="#rating">
                          <svg fill="currentColor" viewBox="0 0 24 24">
                            <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                          </svg>

                        </a>
                        <a href="#rating">
                          <svg fill="currentColor" viewBox="0 0 24 24">
                            <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                          </svg>

                        </a>
                        <a href="#rating">
                          <svg fill="currentColor" viewBox="0 0 24 24">
                            <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                          </svg>

                        </a>
                      </div>
                      <div className={styles.content} >
                        <p className={styles.description} >{data.desc}</p>
                        <div className={`${styles["bottom-content"]}`} >
                          <div className={`${styles["meta-info-section"]}`} >
                            <p className={`${styles["title-text"]}`} >{data.name}</p>
                            <p className={`${styles.desc}`} >{data.post}</p>
                 
                          </div>
                          <div className={`${styles["meta-img-section"]}`} >
                            <a className={`${styles.img}`} href="#">
                              <img
                                src={data.userImg}
                                width={43}
                                height={43}
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles["bg-shape"]}`} >
                      <img
                        className={`${styles.bg} ${styles["shape-dark"]}`}
                        src={!isLightTheme ? data.img : darkBg}
                        width={415}
                        height={287}
                        alt=""
                      />
                      <img
                        className={`${styles["bg-hover"]} ${styles["shape-dark"]}`}
                        src={!isLightTheme ? data.imgHover : darkBgHover}
                        width={415}
                        height={287}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div
          className={`${styles["rbt-show-more-btn"]} ${showToggle ? styles.active : ""}`}
          onClick={() => setShowToggle(!showToggle)}
        >
          Show {!showToggle ? "More" : "Less"}
        </div>
      </div>
    </>
  );
};

export default TestimonialTwo;
