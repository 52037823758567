import React from 'react'


const IconLogo = () => {
    return (
        <svg width="30" height="24" viewBox="0 0 30 24" fill="var(--color-primary-0)" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentFill" d="M4.37844 0.0633246L0 4.66472H7.42944C8.09569 4.67309 8.55379 4.87287 8.79267 5.0013C9.49671 5.3802 9.84343 5.97364 10.1609 6.51837C10.4401 6.99667 10.5989 7.42822 10.6906 7.72691C8.84004 11.6679 6.98898 15.609 5.13842 19.55H10.3192L13.2916 13.1963L17.667 22.3957C17.7753 22.621 18.0853 23.1879 18.7651 23.5929C19.4893 24.024 20.1944 24.0102 20.4448 23.9939C22.3261 23.9939 24.2074 23.9939 26.0887 23.9939L29.1468 19.3911H23.2111C22.7273 19.397 22.3685 19.2872 22.1513 19.1996C21.2179 18.8242 20.7659 18.0044 20.5512 17.6147C20.3965 17.3342 20.3007 17.0847 20.2422 16.9076C16.5225 8.94135 13.7194 3.12059 12.9912 1.66159C12.841 1.36044 12.5744 0.93233 12.1088 0.585417C11.2248 -0.0729803 10.1902 -0.0168837 9.1404 0.0195299C8.06444 0.0569276 6.48905 0.0908808 4.37844 0.0633246Z" />
        </svg>
    )
}


export default IconLogo
