import React from "react";
import { useNavigate } from "react-router-dom";
import styles from '../index.module.css'

const FooterProps = ({ list }) => {
  const navigate = useNavigate()
  return (
    <>
      {list.map((item, itemIndex) => (
        <div className={styles["widget-menu-bottom"]} key={itemIndex}>
          <h4 className={styles["title"]}>{item.title}</h4>
          <div className={styles["inner"]}>
            <ul className={`${styles["footer-link"]} ${styles["link-hover"]}`}>
              {item.innerItem.map((inner, i) => (
                <li key={i}>
                  <button onClick={() => {
                    navigate(`/es/${inner.link}`)
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}>
                    {inner.text}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};

export default FooterProps;
