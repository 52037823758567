const vector = {
    t1: `Maak een nieuwe vector voor je project`,
    t2: `Importeer, sla op, werk, analyseer en trek conclusies uit elke gegevensstructuur. 
Sla en manipuleer informatie met totale vrijheid via multidimensionale ruimten. `,
    t3: `Nieuwe vector`,
    t4: `Vectoren`,
    t5: `Vector`,
    t6: `Dimensie`,
    t7: `Grootte`,
    t9: `Aangemaakt op`,
    t10: `Matrix`,
    t11: `Vectoriële databases laten unieke ervaringen toe die worden aangedreven door vectorzoekopdrachten.  
Ze versnellen de ontwikkeling van AI-toepassingen.`,
    t12: `Nieuwe Matrix`,
    t13: `Snelstartdocumentatie`,
    node: {
        data: {
            title: `Voeg gegevens toe aan je rapport`,
            text: `Eenmaal geladen, zullen je gegevens verschijnen in het`,
            label: `Velden`,
            excel: `Importeer gegevens vanuit Excel`,
            sql: `Importeer gegevens vanuit SQL`,
            blank: `Plak blanco gegevens`,
            export: `Exporteer voorbeeldgegevens`,
            info: `Haal gegevens uit een andere bron`,
        }
    },
    dashboard: {
        t1: `Houd er rekening mee dat je altijd grafieken kunt bewerken en verzamelen die je wilt laten opvallen 
als favorieten vanaf de informatie die je hebt besloten te analyseren in de vectoren.`,
        t2: `Meer informatie`,
    }
}

export default vector