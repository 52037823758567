import React from "react";

import Context from "@home/context/Context";
import SignIn from "@home/SignIn/SignIn";

const SigninPage = () => {
  return (
    <>
      <Context>
        <SignIn />
      </Context>
    </>
  );
};

export default SigninPage;
