import React from "react";
import "./3dScan.css";

const Scan3D = () => {
  const triggers = Array.from({ length: 400 }, (_, i) => (
    <div key={i} className="trigger" />
  ));
  const vrLayers = Array.from({ length: 20 }, (_, i) => (
    <div key={i} className="vr_layer">
      <div className="vr_layer_item" />
    </div>
  ));

  return (
    <div className="maiinscan">
      <div className="containerscan">
        {triggers}
        <div className="monitor">
          <div className="camera o-x">
            <div className="camera o-y">
              <div className="camera o-z">
                <div className="vr">{vrLayers}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scan3D;
