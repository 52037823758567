import React from "react";
import Context from "@home/context/Context";

import HeaderTop from "@home/Header/HeaderTop/HeaderTop";
import Header from "@home/Header/Header";
import PopupMobileMenu from "@home/Header/PopUpMobileMenu";
import Footer from "@home/Footers/Footer";
import Copyright from "@home/Footers/Copyright";
import Breadcrumb from "@home/Common/Breadcrumb";
import BackToTop from "../backToTop";
import SingleBlog from "@home/BlogDetails/BlogDetails";

import styles from '../index.module.css'

const BlogDetailsPage = () => {
  return (
    <>
      <main className={styles["page-wrapper"]}>
        <Context>
          <HeaderTop />
          <Header
            headerTransparent="header-transparent"
            headerSticky="header-sticky"
            btnClass={styles["rainbow-gradient-btn"]}
          />
          <PopupMobileMenu />
          <Breadcrumb title="Blog Details" text="Blog Details" />

          <SingleBlog />

          <BackToTop />
          <Footer />
          <Copyright />
        </Context>
      </main>
    </>
  );
};

export default BlogDetailsPage;
