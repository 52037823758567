import React from 'react'
import './style/style.css'
import EditorSection from './editorSection'
import AppsSection from './appsSection'
import CloudSection from './cloudSection'
import PluginsSection from './pluginsSection'
import RpaSection from './rpaSection'
import BibliOtekaSection from './bibliOtekaSection'
import PressetsSection from './pressetsSection'
import DevlopsSection from './devopsSection'
import IdeSection from './ideSection'
import SandBoxSection from './sanboxSection'
import UxAySection from './uxAySection'
import TranslateSection from './translateSection'
import DocsAiSection from './docsAiSection'
import SupportAiSection from './supportAiSection'
import CollabsSection from './collabsSection'
import BlockChain from './blockChain'
import DataSection from './dataSection'
import PivotSection from './pivotSection'
import AcademySection from './academySection'
import FreelancerSection from './freelancerSection'

const Home = () => {
  return (
    <>
      <div className="doted-bg dark:bg-dark-500 ">
        <div className="mx-[20px]">
          <EditorSection />
          <AppsSection />
          <CloudSection />
          <PluginsSection />
          <RpaSection />
          <BibliOtekaSection />
          <PressetsSection />
          <DevlopsSection />
          <IdeSection />
          <SandBoxSection />
          <UxAySection />
          <TranslateSection />
          <DocsAiSection />
          <SupportAiSection />
          <CollabsSection />
          <BlockChain />
          <DataSection />
          <PivotSection />
          <AcademySection />
          <FreelancerSection />
        </div>
      </div>
    </>
  )
}

export default Home
