import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";

import styles from './UtilizeBody.module.css'

import { useParams } from 'react-router-dom'

import {
  generateUtilize,
  updateUtilize,
  deleteUtilize
} from '@app/actions/utilize'

import ReactEditor from './ReactEditor'


const UtilizeBody = ({ refs, utilize, setUtilize, isAdmin, loadContent, setLoadContent }) => {

  const dispatch = useDispatch();
  const containerRef = useRef(null);

  const { lng } = useParams()



  
  const handleDrop = (event, index) => {
    event.preventDefault();
  
    const file = event.dataTransfer.files[0];
  
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
  
        img.onload = async () => {
          const MAX_SIZE = 700;
          let width = img.width;
          let height = img.height;
  
          if (width > MAX_SIZE || height > MAX_SIZE) {
            const scaleFactor = MAX_SIZE / Math.max(width, height);
            width *= scaleFactor;
            height *= scaleFactor;
          }
  
          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
  
          const base64 = canvas.toDataURL(file.type);

          const selection = window.getSelection();
          const text = selection.toString() || undefined;
          console.log('text', text)
          const userConfirmation = window.confirm("¿Quieres leer esta imagen con AI?");
  
          let imgTag = `<img class="content-image" src="${base64}" />`;
  
          if (userConfirmation) {
            const resp = await dispatch(generateUtilize({
              lng: lng,
              text: text,
              image: base64
            }));
  
            if (resp && resp.payload) {
              let value = imgTag + resp.payload.content;
  
              insertContentAtCursor(index, value);
            }
          } else {
            insertContentAtCursor(index, imgTag);
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };
  
  const insertContentAtCursor = (index, value) => {
    const editableElement = document.querySelector(`[data-index="${index}"]`);
  
    if (editableElement && document.getSelection) {
      const selection = document.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        if (range.commonAncestorContainer.parentNode === editableElement || editableElement.contains(range.commonAncestorContainer)) {
          range.deleteContents();
          const frag = document.createDocumentFragment();
          const tempDiv = document.createElement('div');
          tempDiv.innerHTML = value;
          while (tempDiv.firstChild) {
            frag.appendChild(tempDiv.firstChild);
          }
          range.insertNode(frag);
        } else {
          editableElement.innerHTML += value;
        }
      } else {
        editableElement.innerHTML += value;
      }
  
      let updateUtilize = [...utilize];
      updateUtilize[index].data = editableElement.innerHTML;
  
      setUtilize(updateUtilize);
      setLoadContent(new Date().toISOString());
      setChangeUtilize((prev) => ({
        ...prev,
        [index]: true
      }));
    }
  };


  


  const handleDragOver = (event) => {
    event.preventDefault();
  };



  const newData = (index) => ({
    // id: Date.now(),
    data: `Utilize Item ${index + 1}`,
    ref: refs,
  });

  const handleAddUtilize = (index) => {
    if (utilize.length === 0) {
      const firstItem = { order: 0, ...newData(index) };
      setUtilize([firstItem]);
      return;
    }

    const newUtilize = [...utilize];
    const newOrder = newUtilize[index].order + 1;

    const newItem = { order: newOrder, ...newData(index) };

    newUtilize.splice(index + 1, 0, newItem);

    const updatedUtilize = newUtilize.map((item, i) => ({
      ...item,
      order: i + 1,
    }));

    setUtilize(updatedUtilize);
  };

  const handleDeleteUtilize = async (index) => {
    const data  = utilize.filter((_, i) => i == index)[0]

    const updatedUtilize = utilize
      .filter((_, i) => i !== index)
      .map((item, i) => ({
        ...item,
        order: i + 1,
      }));

    setUtilize(updatedUtilize);

      console.log('delete uu', data)
    const resp = await dispatch(deleteUtilize({
      id: data._id
    }))

  };

  const handlePrevUtilize = (index) => {
    console.log('index', index)
    if (index <= 0) return;

    const newUtilize = [...utilize];

    [newUtilize[index - 1], newUtilize[index]] = [newUtilize[index], newUtilize[index - 1]];

    const updatedUtilize = newUtilize.map((item, i) => ({
      ...item,
      order: i + 1,
    }));

    console.log('eeee', updatedUtilize)
    setUtilize(updatedUtilize);

    setLoadContent(new Date().toISOString())
  };

  const handleNextUtilize = (index) => {
    if (index >= utilize.length - 1) return;

    const newUtilize = [...utilize];

    [newUtilize[index + 1], newUtilize[index]] = [newUtilize[index], newUtilize[index + 1]];

    const updatedUtilize = newUtilize.map((item, i) => ({
      ...item,
      order: i + 1,
    }));

    setUtilize(updatedUtilize);
    setLoadContent(new Date().toISOString())

  };



  const handleChangeUtilize = (e, index) => {
    setChangeUtilize((prev) => ({
      ...prev,
      [index]: true
    }));
  
    const value = e.target.innerHTML;
  
    const updatedUtilize = [...utilize];
    updatedUtilize[index] = {
      ...updatedUtilize[index],
      data: value,
    };
  
    setUtilize(updatedUtilize);
  };
  

  

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      insertLineBreak();
    } else if (e.key === 'Backspace' || e.key === 'Delete') {
      e.preventDefault();
      handleDeleteOrBackspace(e);
    }
  };
  
  const insertLineBreak = () => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
  
    const br = document.createElement("br");
    range.deleteContents(); 
    range.insertNode(br); 
  
    const newRange = document.createRange();
    newRange.setStartAfter(br);
    newRange.collapse(true);
    selection.removeAllRanges();
    selection.addRange(newRange);
  };
  
  const handleDeleteOrBackspace = (e) => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
  
    if (!range.collapsed) return; 
  
    const startContainer = range.startContainer;
    const startOffset = range.startOffset;
  
    if (startContainer.nodeType === Node.TEXT_NODE) {
      if (startOffset > 0) {
        startContainer.textContent =
          startContainer.textContent.slice(0, startOffset - 1) +
          startContainer.textContent.slice(startOffset);
        
        const newRange = document.createRange();
        newRange.setStart(startContainer, startOffset - 1);
        newRange.collapse(true);
        selection.removeAllRanges();
        selection.addRange(newRange);
      } else {
        const previousNode = startContainer.previousSibling;
        if (previousNode && previousNode.nodeType === Node.ELEMENT_NODE) {
          previousNode.remove(); 
  
          const newRange = document.createRange();
          newRange.setStart(startContainer, 0);
          newRange.collapse(true);
          selection.removeAllRanges();
          selection.addRange(newRange);
        }
      }
    } else if (startContainer.nodeType === Node.ELEMENT_NODE && startOffset === 0) {
      const parent = startContainer.parentNode;
      if (parent) {
        parent.removeChild(startContainer);
  
        const newRange = document.createRange();
        if (parent.firstChild) {
          newRange.setStart(parent.firstChild, 0);
        } else {
          newRange.setStart(parent, 0);
        }
        newRange.collapse(true);
        selection.removeAllRanges();
        selection.addRange(newRange);
      }
    }
  };



  const [changeUtilize, setChangeUtilize] = useState(false);



  const handleSaveUtilize = (index) => {
    setChangeUtilize((prev) => ({
      ...prev,
      [index]: false
    }));


    dispatch(updateUtilize({
      lng: lng,
      id: utilize[index]._id,
      order: utilize[index].order,
      ref: utilize[index].ref,
      data: utilize[index].data,
    }))
  };

  const handleOpenUtilize = (ref) => {
    openUtilize(ref);
  };


 // -------------------------------------


  return (
    <>
      {utilize && (
        <>
          {(isAdmin && utilize.length == 0) && (
            <div>
              <button onClick={() => handleAddUtilize(0)}>
                Nuevo añadir
              </button>
            </div>
          )}
          {utilize.map((item, index) => (
            <div
              key={index}
              ref={containerRef}
              onDrop={(e) => handleDrop(e, index)}
              onDragOver={(e) => handleDragOver(e)}
              className={`${styles["single-inner-box"]}`}
            >
              {!isAdmin ? (  
                <div className={styles.content} dangerouslySetInnerHTML={{__html: item.data}}></div>
              ):(
              <ReactEditor
                index={index}
                handleChange={(e) => handleChangeUtilize(e, index)}
                handleKey={handleKeyDown}
                content={item.data}
                loadContent={loadContent}
              >
                {changeUtilize[index] && (
                  <div className={styles['btn-group']}>
                    <button className={styles['btn']} onClick={() => handleSaveUtilize(index)}>
                      <svg fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 11v5m0 0 2-2m-2 2-2-2M3 6v1a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1Zm2 2v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8H5Z" />
                      </svg>
                    </button>
                  </div>
                )}
                <div className={styles['btn-group']}>
                  <button className={styles['btn']} onClick={() => handleAddUtilize(index)}>
                    <svg fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14m-7 7V5" />
                    </svg>

                  </button>
                  <button className={styles['btn']} onClick={() => handleDeleteUtilize(index)}>
                    <svg fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18 17.94 6M18 18 6.06 6" />
                    </svg>

                  </button>
                  <button className={styles['btn']} onClick={() => handlePrevUtilize(index)}>

                    <svg fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m16 14-4-4-4 4" />
                    </svg>
                  </button>
                  <button className={styles['btn']} onClick={() => handleNextUtilize(index)}>
                    <svg fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m8 10 4 4 4-4" />
                    </svg>

                  </button>
                </div>
              </ReactEditor>
              )}
            </div>
          ))}
        </>
      )}
    </>
  )




};

export default UtilizeBody;
