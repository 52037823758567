
const pricing = {
    "t0": "Анализируйте и исследуйте свои метрики",
    "t1": "Без сюрпризов, таковы наши цены",
    "t2": "Попробуйте 7 дней бесплатно",
    "t3": "САМЫЙ ПОПУЛЯРНЫЙ",
    "t4": "149€",
    "t5": "/месяц",
    "t6": "Бизнес",
    "t7": "Компании, которые нуждаются в постоянном анализе по разным актёрам.",
    "t8": "5 пользователей включены",
    "t9": "10 визуальных Add-ons",
    "t10": "Векторизованные данные",
    "t11": "50 графиков данных",
    "t12": "100 вопросов к данным",
    "t13": "200 кликов на HeatMap",
    "t14": "100GB данных",
    "t15": "Приоритетная поддержка",
    "t16": "Видеозвонки",
    "t17": "Мотор поиска",
    "t18": "Выберите план",
    "t19": "49€",
    "t20": "/месяц",
    "t21": "Базовый",
    "t22": "Направлен на эмерджентные идеи",
    "t23": "которые хотят анализировать свои KPI для определения стратегий.",
    "t24": "1 пользователь включен",
    "t25": "5 визуальных Add-ons",
    "t26": "Векторизованные данные",
    "t27": "10 графиков данных",
    "t28": "20 вопросов к данным",
    "t29": "50 кликов на HeatMap",
    "t30": "5GB данных",
    "t31": "Поддержка по электронной почте",
    "t32": "Видеозвонки",
    "t33": "Мотор поиска",
    "t34": "Выберите план",
    "t35": "349€",
    "t36": "/месяc",
    "t37": "Стартап",
    "t38": "Для команд Стартапов, готовых создавать свои анализы на заказ без ограничений.",
    "t39": "15 пользователей включены",
    "t40": "Неограниченные Add-ons",
    "t41": "Векторизованные данные",
    "t42": "Неограниченные графики",
    "t43": "Неограниченные вопросы",
    "t44": "500 кликов на HeatMap",
    "t45": "500GB данных",
    "t46": "Срочная поддержка",
    "t47": "Видеозвонки",
    "t48": "Мотор поиска",
    "t49": "Выберите план",
    "t50": "БЕСПЛАТНО",
    "t51": "*7 дней",
    "t52": "Исследование",
    "t53": "Идеально для начала с точечными анализами, без возможности создания на длительный срок.",
    "t54": "1 пользователь включен",
    "t55": "1 визуальный Add-on",
    "t56": "Векторизованные данные",
    "t57": "1 график данных",
    "t58": "5 вопросов к данным",
    "t59": "Без HeatMap",
    "t60": "500MB данных",
    "t61": "Без поддержки",
    "t62": "Видеозвонки",
    "t63": "Мотор поиска",
    "t64": "Выберите план",
    "t65": "Если вам нужно больше пользователей и опций, свяжитесь с нашей командой",
    "t66": "Продажи",
    "t67": "Сравните между планами оплаты",
    "t68": "И узнайте, какой из них подходит вашим потребностям",
    "t69": "Базовый",
    "t70": "Начните",
    "t71": "Бизнес",
    "t72": "Понимайте",
    "t73": "Стартап",
    "t74": "Анализируйте",
    "t75": "ВОЗМОЖНОСТИ И ДОСТУП",
    "t76": "Пользователи",
    "t77": "1",
    "t78": "5",
    "t79": "15",
    "t80": "Add-ons",
    "t81": "Неограниченные",
    "t82": "5",
    "t83": "10",
    "t84": "Векторизованные данные",
    "t85": "GB данных",
    "t86": "До 5 GB",
    "t87": "До 100 GB",
    "t88": "До 500 GB",
    "t89": "Персонализированная презентация",
    "t90": "Количество графиков",
    "t91": "10",
    "t92": "50",
    "t93": "Неограниченные",
    "t94": "Количество вопросов",
    "t95": "20",
    "t96": "100",
    "t97": "Неограниченные",
    "t98": "Клики с картой тепла",
    "t99": "50",
    "t100": "200",
    "t101": "500",
    "t102": "Видеозвонки и поиск",
    "t103": "ПЕРСОНАЛИЗАЦИЯ И КОД",
    "t104": "Персонализированный логотип",
    "t105": "скоро",
    "t106": "Предопределенные темы",
    "t107": "Персонализированные темы",
    "t108": "скоро",
    "t109": "Поделиться с субдоменом",
    "t110": "Поделиться с доменом",
    "t111": "скоро",
    "t112": "Доступ к IAM и банку",
    "t113": "Использование Add-ons",
    "t114": "скоро",
    "t115": "Редактирование промптов",
    "t116": "Доступ к исходному коду",
    "t117": "скоро",
    "t118": "Редактирование в реальном времени",
    "t119": "скоро",
    "t120": "ОТЧЕТЫ",
    "t121": "Экспорт в PDF",
    "t122": "Отправка по электронной почте",
    "t123": "Поделиться по ссылке",
    "t124": "ПОДДЕРЖКА",
    "t125": "Поддержка по электронной почте",
    "t126": "Приоритетная поддержка",
    "t127": "Помощь one-to-one",
    "t128": "А если вам нужно больше для анализа?",
    "t129": "Вы можете изменить план. Но если это слишком масштабирует ваши потребности, вы можете выбрать на основе более графиков, более вопросов, более Add-ons для ваших интерфейсных представлений, более кликов, более GB данных... Ваши дополнительные функции будут добавлены к вашему существующему квоту. Вы сможете отменить в любой момент. И если вам нужно персонализированный план, свяжитесь с продажами.",
    "t130": "Графики",
    "t131": "Пакет 10",
    "t132": "15€",
    "t133": "Вопросы",
    "t134": "Пакет 20",
    "t135": "10€",
    "t136": "Add-on",
    "t137": "Дополнительный",
    "t138": "10€",
    "t139": "Карта тепла",
    "t140": "Клик",
    "t141": "0.5€",
    "t142": "GB облака",
    "t143": "GB",
    "t144": "1€"
  }
  
  export default pricing
