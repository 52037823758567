const settings = {
    t1: "Início",
    t2: "Aythen DB",
    t3: "Kanban",
    t4: "Meus documentos",
    t5: "Contratos",
    t6: "Faturamento",
    info: {
        t0: `Adicionar tag`,
        t1: `Chave`,
        t2: `Valor`,
        info: {
          t0: `excl. impostos com desconto`,
          t1: `Atualizado há 27 minutos`
        }
    },
    invoice: {
        send: {
            "t0": "Fatura",
            "t1": "Copiar fatura",
            "t2": "Alterar a organização se não corresponder",
            "t3": "Falta algum dado por preencher",
            "t4": "Número de identificação fiscal",
            "t5": "DNI (documentação oficial)",
            "t6": "Escolha o modo de pagamento que deseja efetuar",
            "t7": "Mais info",
            "t8": "Pagamento",
            "t9": "Quanto você deseja receber de dinheiro",
            "t10": "Contatos",
            "t11": "Selecione um contato",
            "t12": "Organização",
            "t13": "Nós enviaremos um processo para a conta",
            "t14": "Modo intacto",
            "t15": "Quero receber o dinheiro o mais rápido possível",
            "t16": "1",
            "t17": "Programar aviso de envio",
            "t18": "2",
            "t19": "Lembre-se amanhã se não for pago",
            "t20": "3",
            "t21": "Lembre-se todos os dias durante 14 dias",
            "t22": "Modo banco",
            "t23": "Factoring mais rápido para seus clientes",
            "t24": "1",
            "t25": "Pagamento imediato e pagamento seguro",
            "t26": "2",
            "t27": "O banco antecipa o dinheiro",
            "t28": "3",
            "t29": "Melhores condições de pagamento",
            "t30": "Modo programado",
            "t31": "Diga-nos um dia para pedir o cobro",
            "t32": "1",
            "t33": "Programar o envio do dia",
            "t34": "2",
            "t35": "Avisar durante 14 dias",
            "t36": "3",
            "t37": "Melhores condições de pagamento",
            "t38": "Enviar fatura",
            "t39": "Pagamento bem-sucedido",
            "t40": "Realizar transferência",
            "t41": "Você vai receber",
            "t42": "2.000$",
            "t43": "Cartão Atual",
            "t44": "**** 4543",
            "t45": "Processo escolhido",
            "t46": "Pagamento instantâneo",
            "t47": "Pagar Fatura",
            "t48": "Cancelar"
        }
    },
    status: {
        "t0": "Aythen",
        "t1": "status",
        "t2": "Suporte de Contato",
        "t3": "Estado em tempo real",
        "t4": "Histórico",
        "t5": "ASSINE",
        "t6": "Aythen API",
        "t7": "99,999% SLA tempo de atividade",
        "t8": "Jan 15",
        "t9": "100% uptime",
        "t10": "Jan 1",
        "t11": "Hoje",
        "t12": "Legenda:",
        "t13": "Sucesso",
        "t14": "Pendente",
        "t15": "Erro",
        "t16": "Estado do sistema",
        "t17": "Manutenção programada para Bancontact",
        "t18": "Aythen",
        "t19": "© 2024 Aythen, Inc",
        "t20": "Visite nosso site",
        "t21": "Termos e privacidade",
        "t22": "Email",
        "t23": "Mensagem de texto",
        "t24": "Receba notificações por email sempre que o Stripe criar, atualizar ou resolver um incidente.",
        "t25": "Endereço de email",
        "t26": "Assine via email",
        "t27": "Para atualizar as preferências de email existentes, re-assine.",
        "t28": "Para mais informações de assinatura, visite nossas instruções no Suporte.",
        "t29": "Este site é protegido pelo reCAPTCHA e pela Política de Privacidade e Termos de Serviço do Google.",
        "t30": "Receba notificações por mensagem de texto sempre que o Stripe criar ou resolver um incidente.",
        "t31": "Código de país",
        "t32": "Número de telefone",
        "t33": "Assine via mensagem de texto",
        "t34": "Para atualizar as preferências existentes, re-assine.",
        "t35": "Para mais informações de assinatura, visite nossas instruções no Suporte.",
        "t36": "Taxas de mensagem e dados podem ser aplicadas. Ao assinar, você concorda com os Termos de Serviço da Atlassian e com a Política de Privacidade da Atlassian. Este site é protegido pelo reCAPTCHA e pela Política de Privacidade e Termos de Serviço do Google."
    },
    billing: {
        t1: `Preencha os dados…`,
        t2: `Moeda`,
        t3: `Editar`,
        t4: `Aqui aparecem seus dados de registro e de faturamento. Você poderá alterar ou
modificar para verificar seu acesso às diferentes funcionalidades que o Aythen Lite oferece.`,
        t5: `Modificar dados`,
        t6: `Consumo de dados`,
        t7: `Email de contato`,
        t8: `Como forma de recopilação, todos os meses você terá sua fatura eletrônica preparada automaticamente em seu email de registro.`,
        t9: `Email de faturamento`,
        t10: `Salvar`,
        t12: `Token de GPT`,
        t13: `Você pode criar um token de GPT para identificar e autenticar suas solicitações de API de forma única.`,
        t14: `Token`,
        t15: `Salvar`,
        t16: `Limites de consumo`,
        t17: `Em sua licença, você pode precisar de mais interações ou GBs de armazenamento. 
Para esses usos adicionais, você pode configurar alertas de acordo com um total.`,
        t177: `Saiba mais`,
        t18: `Adicionar alerta a partir de `,
        t19: `Custos Mensais`,
        t20: `Salvar Alerta`,
        t21: `Identificação NIF/VAT`,
        t22: `Insira seu número de identificação fiscal para poder deduzir o IVA de 
suas faturas. `,
        t222: `VAT`,
        t23: `Salvar`,
        t24: `Métodos de pagamento`,
        t25: `Escolha entre os seguintes métodos de pagamento para sua conta.`,
        t26: `Como você deseja gerenciar sua licença:`,
        t266: `Cartão de crédito`,
        t267: `SEPA Direct Debt`,
        t268: `falta tabela`,
        t27: `Tipos de notificações`,
        t28: `Selecione e configure suas preferências de notificação. Quando você receberá as notificações?`,
        t29: `Tipos de notificações`,
        t30: `Notificações por email`,
        t31: `Lembrete diário`,
        t32: `Notificações de eventos especiais:`,
        t33: `Notificações de mensagens privadas:`,
        t34: `Faturas recebidas`,
        t340: `Aythen Lite Premium`,
        t341: `Upgrade para Premium`,
        t342: `Lorem ipsum dolor et epsum holend.`,
        t343: `Lorem ipsum dolor et epsum holend et erem super tupper dolem herem
    superssupostom hellom super start.`,
        t344: `Lorem ipsum dolor et teresum`,
        t345: `Lorem ipsum dolor et teresum`,
        t346: `Grátis`,
        t347: `Para sempre`,
        t350: `Adicionar`,
        t35: `Aqui você encontrará seu histórico entre recebidas e enviadas.`,
        t36: `Faturas enviadas`,
        t360: `Faturas recebidas`,
        t361: `de`,
        t37: `Mês`,
        t38: `Ano`,
        t39: `Número`,
        t40: `Método de pagamento`,
        t41: `Status`,
        t42: `Total (IVA incl.)`,
        t43: `Baixar`,
        t44: `Excluir conta`,
        t45: `Você tem certeza de que deseja excluir sua conta? Tenha em mente que essa ação não pode ser desfeita e sua conta será desativada em 48 horas se confirmar.`,
        t46: `Excluir`,

        x1: `Editar o nome da conta`,
        x2: `Esta é uma conta corporativa`,
        x3: `Nome da empresa`,
        x4: `Endereço da rua`,
        x5: `Endereço da rua 2`,
        x6: `Código postal`,
        x7: `Cidade`,
        x8: `País`,
        x9: `Região`,
        x10: `Confirmar alterações`,
    },
    contracts: {
        t1: `Organização`,
        t2: `Contratos de Conta`,
        t3: `Nome`,
        t4: `Status`,
        t5: `Fim em`,
        t6: `Baixar`,
        t7: `Organize aqui seus acordos empresariais.`,
        t8: `Quais são os benefícios?`,
        t0: 'Adicionar contrato',
        popup: {
            t0: `Aberto em`,
            t1: `Estado`,
            t2: `Enviar com aviso`,
            t3: `Eliminar aviso`,
            t4: `Enviar uma vez`,
            t5: `Envio prioritário`,
            t6: `Marcar assinado`,
            t7: `Para assinar`,
            t8: `Para eliminar, você deve adicionar Eliminar`,
            t9: `Assinar`,
            t10: `Cancelar`,
            t11: `Eliminar`
        }
    }
}

export default settings