import React from 'react'

const Test = () => {
    return (
        <div>
            <style>{`.group-2085662826,
.group-2085662826 * {
  box-sizing: border-box;
}
.group-2085662826 {
  height: 1170px;
  position: relative;
}
.rectangle-376714361 {
  background: #ffffff;
  border-radius: 19px;
  width: 1513px;
  height: 1170px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.paragraph-container {
  display: flex;
  flex-direction: column;
  gap: 37px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 572px;
  top: 114px;
}
.tu-escritorio-cloud {
  color: var(--colors-alias-black-and-white-black-800, #0d0e14);
  text-align: center;
  font-family: "Manrope-Bold", sans-serif;
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
}
.explora-esta-pantalla-y-entiende-el-porqu-y-de-qu-manera-la-utilizamos {
  color: var(--colors-alias-black-and-white-black-400, #4d4f5c);
  text-align: center;
  font-family: "DmSans-Regular", sans-serif;
  font-size: 18px;
  line-height: 25.2px;
  font-weight: 400;
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calendar {
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-end;
}
.container {
  background: var(--colors-alias-black-and-white-white, #ffffff);
  border-radius: 12px;
  border-style: solid;
  border-color: var(--colors-alias-black-and-white-black-100, #d3d3d6);
  border-width: 0.5px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24.13px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 538px;
  height: 317px;
  position: relative;
  overflow: hidden;
}
.horizontal-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 494px;
  height: 61.4px;
  position: relative;
}
.descripci-n-general {
  color: var(--colors-alias-black-and-white-black-800, #0d0e14);
  text-align: left;
  font-family: "Manrope-Bold", sans-serif;
  font-size: 30px;
  line-height: 22.4px;
  font-weight: 700;
  position: relative;
  align-self: stretch;
  height: 22.4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.es-una-de-las-primeras-interfaces-que-el-usuario-encuentra-al-ingresar-a-la-plataforma-al-seleccionar-su-cono-el-usuario-ingresa-a-su-espacio-nico-dentro-de-la-cuenta-este-sistema-permite-que-m-ltiples-personas-no-usen-la-misma-cuenta-para-no-interferir-en-las-preferencias-de-los-dem-s {
  color: #000000;
  text-align: left;
  font-family: "Inter-Regular", sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  position: relative;
  width: 482px;
  height: 222px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.text-input {
  background: #000000;
  border-radius: 50%;
  opacity: 0.1;
  flex-shrink: 0;
  width: 451.73px;
  height: 322.78px;
  position: absolute;
  left: 185px;
  top: -118.5px;
  filter: blur(105.24px);
}
.button-container {
  background: var(--colors-alias-black-and-white-white, #ffffff);
  border-radius: 16px;
  border-style: solid;
  border-color: var(--colors-alias-black-and-white-black-100, #d3d3d6);
  border-width: 1px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 19px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 538px;
  height: 317px;
  position: relative;
  overflow: hidden;
}
.container2 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.prop-sito-de-uso {
  color: var(--colors-alias-black-and-white-black-800, #0d0e14);
  text-align: left;
  font-family: "Manrope-Bold", sans-serif;
  font-size: 30px;
  line-height: 22.4px;
  font-weight: 700;
  position: relative;
  align-self: stretch;
  height: 22.4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.text-input2 {
  background: #000000;
  border-radius: 50%;
  opacity: 0.1;
  flex-shrink: 0;
  width: 451.73px;
  height: 322.78px;
  position: absolute;
  left: 255.45px;
  top: -174.15px;
  filter: blur(105.24px);
}
.ofrecer-a-los-usuarios-la-posibilidad-de-elegir-entre-diferentes-perfiles-antes-de-iniciar-la-experiencia-en-la-plataforma-cada-perfil-est-vinculado-a-las-preferencias-personales-de-visualizaci-n-historial-y-datos-relacionados-lo-que-permite-que-cada-persona-acceda-a-su-contenido-personalizado {
  color: #000000;
  text-align: left;
  font-family: "Inter-Regular", sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  position: relative;
  width: 490px;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.vertical-container {
  background: var(--colors-alias-black-and-white-white, #ffffff);
  border-radius: 16px;
  border-style: solid;
  border-color: var(--colors-alias-black-and-white-black-100, #d3d3d6);
  border-width: 1px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 27px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 348px;
  height: 380px;
  position: relative;
  overflow: hidden;
}
.text-input-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.elementos-visuales {
  color: var(--colors-alias-black-and-white-black-800, #0d0e14);
  text-align: left;
  font-family: "Manrope-Bold", sans-serif;
  font-size: 30px;
  line-height: 22.4px;
  font-weight: 700;
  position: relative;
  align-self: stretch;
  height: 22.4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.button-container2 {
  display: flex;
  flex-direction: column;
  gap: 22px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 304px;
  height: 218px;
  position: relative;
}
.button-container3 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.button {
  background: var(--colors-alias-black-and-white-black-25, #f4f4f5);
  border-radius: 80px;
  border-style: solid;
  border-color: var(--colors-alias-black-and-white-black-300, #7a7b85);
  border-width: 0.5px;
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.t-tulos {
  color: var(--colors-alias-black-and-white-black-800, #0d0e14);
  text-align: left;
  font-family: "Manrope-Regular", sans-serif;
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 400;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.cuenta {
  color: var(--colors-alias-black-and-white-black-800, #0d0e14);
  text-align: left;
  font-family: "Manrope-Regular", sans-serif;
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 400;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.button-container4 {
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 285px;
  position: relative;
}
.a-adir-cuena {
  color: var(--colors-alias-black-and-white-black-800, #0d0e14);
  text-align: left;
  font-family: "Manrope-Regular", sans-serif;
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 400;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.text-input3 {
  background: #000000;
  border-radius: 50%;
  opacity: 0.1;
  flex-shrink: 0;
  width: 451.73px;
  height: 322.78px;
  position: absolute;
  left: 118px;
  top: -199.5px;
  filter: blur(105.24px);
}
.beneficios-de-uso {
  color: var(--colors-alias-black-and-white-black-800, #0d0e14);
  text-align: left;
  font-family: "Manrope-Bold", sans-serif;
  font-size: 30px;
  line-height: 22.4px;
  font-weight: 700;
  position: relative;
  align-self: stretch;
  height: 22.4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.seguridad-mejorada-a-ade-una-capa-adicional-de-protecci-n-asegurando-que-solo-usuarios-de-ese-perfil-tengan-acceso-al-escritorio-prevenci-n-de-accesos-el-uso-de-perfiles-nicos-garantizan-un-control-sobre-la-informaci-n-de-otros-usuarios {
  color: #000000;
  text-align: left;
  font-family: "-", sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  position: relative;
  align-self: stretch;
  height: 255px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.seguridad-mejorada-a-ade-una-capa-adicional-de-protecci-n-asegurando-que-solo-usuarios-de-ese-perfil-tengan-acceso-al-escritorio-prevenci-n-de-accesos-el-uso-de-perfiles-nicos-garantizan-un-control-sobre-la-informaci-n-de-otros-usuarios-span {
  color: #000000;
  font-family: "Manrope-Bold", sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
}
.seguridad-mejorada-a-ade-una-capa-adicional-de-protecci-n-asegurando-que-solo-usuarios-de-ese-perfil-tengan-acceso-al-escritorio-prevenci-n-de-accesos-el-uso-de-perfiles-nicos-garantizan-un-control-sobre-la-informaci-n-de-otros-usuarios-span2 {
  color: #000000;
  font-family: "Manrope-Regular", sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
}
.seguridad-mejorada-a-ade-una-capa-adicional-de-protecci-n-asegurando-que-solo-usuarios-de-ese-perfil-tengan-acceso-al-escritorio-prevenci-n-de-accesos-el-uso-de-perfiles-nicos-garantizan-un-control-sobre-la-informaci-n-de-otros-usuarios-span3 {
  color: #000000;
  font-family: "Manrope-Bold", sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
}
.seguridad-mejorada-a-ade-una-capa-adicional-de-protecci-n-asegurando-que-solo-usuarios-de-ese-perfil-tengan-acceso-al-escritorio-prevenci-n-de-accesos-el-uso-de-perfiles-nicos-garantizan-un-control-sobre-la-informaci-n-de-otros-usuarios-span4 {
  color: #000000;
  font-family: "Manrope-Regular", sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
}
.text-input4 {
  background: #000000;
  border-radius: 50%;
  opacity: 0.1;
  flex-shrink: 0;
  width: 451.73px;
  height: 322.78px;
  position: absolute;
  left: 154px;
  top: -137.5px;
  filter: blur(105.24px);
}
.paragraph-container2 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 308px;
  position: relative;
}
.acciones-disponibles {
  color: var(--colors-alias-black-and-white-black-800, #0d0e14);
  text-align: left;
  font-family: "Manrope-Bold", sans-serif;
  font-size: 30px;
  line-height: 22.4px;
  font-weight: 700;
  position: relative;
  width: 325px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.button-container5 {
  display: flex;
  flex-direction: column;
  gap: 22px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.button-container6 {
  display: flex;
  flex-direction: row;
  gap: 11px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 269px;
  position: relative;
}
.entrar-en-cuenta {
  color: var(--colors-alias-black-and-white-black-800, #0d0e14);
  text-align: left;
  font-family: "Manrope-Regular", sans-serif;
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 400;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.crear-cuenta {
  color: var(--colors-alias-black-and-white-black-800, #0d0e14);
  text-align: left;
  font-family: "Manrope-Regular", sans-serif;
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 400;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.text-input5 {
  background: #000000;
  border-radius: 50%;
  opacity: 0.1;
  flex-shrink: 0;
  width: 451.73px;
  height: 322.78px;
  position: absolute;
  left: 106px;
  top: -242.9px;
  filter: blur(105.24px);
}
.vector {
  width: 3.11%;
  height: 3.42%;
  position: absolute;
  right: 48.45%;
  left: 48.45%;
  bottom: 3.33%;
  top: 93.25%;
  overflow: visible;
}`}</style>
            <div class="group-2085662813">
                <div class="group-2085662797">
                    <div class="rectangle-376714361"></div>
                    <div class="paragraph-container">
                        <div class="tu-escritorio-personalizaci-n">
                            Tu escritorio &gt; Personalización
                        </div>
                        <div
                            class="explora-esta-pantalla-y-entiende-el-porqu-y-de-qu-manera-la-utilizamos"
                        >
                            Explora esta pantalla y entiende el porqué y
                            <br />
                            de qué manera la utilizamos
                        </div>
                    </div>
                    <div class="calendar">
                        <div class="container">
                            <div class="horizontal-container">
                                <div class="descripci-n-general">Descripción general</div>
                                <div
                                    class="es-una-de-las-primeras-interfaces-que-el-usuario-encuentra-al-ingresar-a-la-plataforma-al-seleccionar-su-cono-el-usuario-ingresa-a-su-espacio-nico-dentro-de-la-cuenta-este-sistema-permite-que-m-ltiples-personas-no-usen-la-misma-cuenta-para-no-interferir-en-las-preferencias-de-los-dem-s"
                                >
                                    Es una de las primeras interfaces que el usuario encuentra al
                                    ingresar a la plataforma.
                                    <br />
                                    Al seleccionar su ícono, el usuario ingresa a su espacio único
                                    dentro de la cuenta.
                                    <br />
                                    <br />
                                    Este sistema permite que múltiples personas no usen la misma cuenta
                                    para no interferir en las preferencias de los demás.º
                                </div>
                            </div>
                            <div class="text-input"></div>
                        </div>
                        <div class="button-container">
                            <div class="container2">
                                <div class="prop-sito-de-uso">Propósito de uso</div>
                                <div class="text-input2"></div>
                            </div>
                            <div
                                class="ofrecer-a-los-usuarios-la-posibilidad-de-elegir-entre-diferentes-perfiles-antes-de-iniciar-la-experiencia-en-la-plataforma-cada-perfil-est-vinculado-a-las-preferencias-personales-de-visualizaci-n-historial-y-datos-relacionados-lo-que-permite-que-cada-persona-acceda-a-su-contenido-personalizado"
                            >
                                Ofrecer a los usuarios la posibilidad de elegir entre diferentes
                                perfiles antes de iniciar la experiencia en la plataforma.
                                <br />
                                <br />
                                Cada perfil está vinculado a las preferencias personales de
                                visualización, historial y datos relacionados, lo que permite que cada
                                persona acceda a su contenido personalizado.
                            </div>
                        </div>
                        <div class="vertical-container">
                            <div class="text-input-container">
                                <div class="elementos-visuales">Elementos visuales</div>
                            </div>
                            <div class="button-container2">
                                <div class="button-container3">
                                    <div class="button">
                                        <div class="t-tulos">Títulos</div>
                                    </div>
                                    <div class="button">
                                        <div class="cuenta">Cuenta</div>
                                    </div>
                                </div>
                                <div class="button-container4">
                                    <div class="button">
                                        <div class="a-adir-cuena">Añadir cuena</div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-input3"></div>
                        </div>
                        <div class="vertical-container">
                            <div class="text-input-container">
                                <div class="beneficios-de-uso">Beneficios de uso</div>
                            </div>
                            <div
                                class="seguridad-mejorada-a-ade-una-capa-adicional-de-protecci-n-asegurando-que-solo-usuarios-de-ese-perfil-tengan-acceso-al-escritorio-prevenci-n-de-accesos-el-uso-de-perfiles-nicos-garantizan-un-control-sobre-la-informaci-n-de-otros-usuarios"
                            >
                                <span>
                                    <span
                                        class="seguridad-mejorada-a-ade-una-capa-adicional-de-protecci-n-asegurando-que-solo-usuarios-de-ese-perfil-tengan-acceso-al-escritorio-prevenci-n-de-accesos-el-uso-de-perfiles-nicos-garantizan-un-control-sobre-la-informaci-n-de-otros-usuarios-span"
                                    >
                                        <br />
                                        Seguridad mejorada:
                                    </span>
                                    <span
                                        class="seguridad-mejorada-a-ade-una-capa-adicional-de-protecci-n-asegurando-que-solo-usuarios-de-ese-perfil-tengan-acceso-al-escritorio-prevenci-n-de-accesos-el-uso-de-perfiles-nicos-garantizan-un-control-sobre-la-informaci-n-de-otros-usuarios-span2"
                                    >
                                        Añade una capa adicional de protección, asegurando que solo
                                        usuarios de ese perfil tengan acceso al escritorio.
                                        <br />
                                        <br />
                                    </span>
                                    <span
                                        class="seguridad-mejorada-a-ade-una-capa-adicional-de-protecci-n-asegurando-que-solo-usuarios-de-ese-perfil-tengan-acceso-al-escritorio-prevenci-n-de-accesos-el-uso-de-perfiles-nicos-garantizan-un-control-sobre-la-informaci-n-de-otros-usuarios-span3"
                                    >
                                        Prevención de accesos:
                                    </span>
                                    <span
                                        class="seguridad-mejorada-a-ade-una-capa-adicional-de-protecci-n-asegurando-que-solo-usuarios-de-ese-perfil-tengan-acceso-al-escritorio-prevenci-n-de-accesos-el-uso-de-perfiles-nicos-garantizan-un-control-sobre-la-informaci-n-de-otros-usuarios-span4"
                                    >
                                        El uso de perfiles únicos garantizan un control sobre la
                                        información de otros usuarios.
                                    </span>
                                </span>
                            </div>
                            <div class="text-input4"></div>
                        </div>
                        <div class="vertical-container">
                            <div class="paragraph-container2">
                                <div class="acciones-disponibles">Acciones disponibles</div>
                            </div>
                            <div class="button-container5">
                                <div class="button-container6">
                                    <div class="button">
                                        <div class="entrar-en-cuenta">Entrar en cuenta</div>
                                    </div>
                                    <div class="button">
                                        <div class="crear-cuenta">Crear cuenta</div>
                                    </div>
                                </div>
                                <div class="text-input5"></div>
                            </div>
                        </div>
                    </div>
                    <img class="vector" src="vector0.svg" />
                </div>
            </div>
        </div>
    )
}

export default Test