import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { ReactComponent as IconBanderaTests } from './assets/banderaTests.svg'
import { ReactComponent as IconBombilla } from './assets/bombilla.svg'
import { ReactComponent as IconCablePorRevisar } from './assets/cablePorRevisar.svg'
import { ReactComponent as IconCheckIdentificado } from './assets/checkIdentificado.svg'
import { ReactComponent as IconCorazon } from './assets/corazon.svg'
import { ReactComponent as IconEstrellaValoracion } from './assets/estrellaValoracion.svg'
import { ReactComponent as IconGoogle } from './assets/google.svg'
import { ReactComponent as IconLambda } from './assets/lambda.svg'
import { ReactComponent as IconSumar } from './assets/iconoSumar.svg'
import { ReactComponent as IconManoIdentificacion } from './assets/manoIdentificacion.svg'
import { ReactComponent as IconStar } from './assets/Star.svg'
import { ReactComponent as IconTrustpilot } from './assets/trustpilot.svg'

import { icons } from '../../app/v1-1/pages/Drive/assetsAux'

import styles from './index.module.css'



const HowToUse = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [paymentType, setPaymentType] = useState('saldo')
	const [paymentMethod, setPaymentMethod] = useState('card')

	const [tag, setTag] = useState(0)
	const [type, setType] = useState('PDF')
	const [text, setText] =
		useState(`Transforma tus archivos en los siguientes formatos
                        de manera que puedas ahorrar tiempo y esfuerzos en gestionar
                        tus documentaciones.`)
	const [progress, setProgress] = useState(0)

	const [formattedTag, setFormattedTag] = useState('01')

	const [labels, setLabels] = useState([])

	useEffect(() => {
		setFormattedTag(String(tag + 1).padStart(2, '0'))
	}, [tag])

	useEffect(() => {
		if (tag === 2) {
			let timer = 0
			const interval = setInterval(() => {
				timer++
				setProgress(timer * 10)
				if (timer === 11) {
					clearInterval(interval)
					setTag(3)
					setProgress(0)
				}
			}, 1000)

			return () => clearInterval(interval)
		}
	}, [tag])

	useEffect(() => {
		if (type === 'PDF') {
			setText(`Transforma tus archivos en los siguientes formatos
                        de manera que puedas ahorrar tiempo y esfuerzos en gestionar
                        tus documentaciones.`)
		}
	}, [type])

	const handleReturn = () => {
		if (tag === 0) {
			navigate('/es')
		} else {
			setTag(tag - 1)
		}
	}

	// ------------------------------------------------------------
	const [searchParams, setSearchParams] = useSearchParams()

	useEffect(() => {
		const keyParam = searchParams.get('key')
		if (keyParam) {
			const urlLabels = keyParam
				.split(',')
				.map((label) => ({ title: label.trim().toLowerCase() }))
				.filter((label) => label.title !== '')
			setLabels(urlLabels)
		}
	}, [])

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault()
			const input = e.target.value.trim()
			const newLabels = input
				.split(',')
				.map((label) => label.trim().toLowerCase())
				.filter((label) => label !== '')

			if (newLabels.length > 0) {
				const updatedLabels = [...labels]
				newLabels.forEach((newLabel) => {
					const labelExists = updatedLabels.some(
						(label) => label.title.toLowerCase() === newLabel
					)
					if (!labelExists) {
						updatedLabels.unshift({ title: newLabel })
					} else {
						console.log(`La etiqueta "${newLabel}" ya existe`)
					}
				})

				setLabels(updatedLabels)
				const keyParam = updatedLabels.map((label) => label.title).join(',')
				setSearchParams({ key: keyParam })
				e.target.value = ''
			}
		}
	}



	const [files, setFiles] = useState([])

	const handleDoc = () => {
		const input = document.createElement('input')
		input.type = 'file'
		input.accept = '.pdf,.jpg,.jpeg,.png' 
		input.multiple = true

		input.onchange = (e) => {
			const files = Array.from(e.target.files)
			files.forEach((file) => {
				const reader = new FileReader()
				reader.onload = (event) => {
					const buffer = event.target.result

					setFiles([...files, file])
				}
				reader.readAsArrayBuffer(file)
			})
		}

		input.click()
	}

	return (
		<div className={styles.howtouse}>
			<header
				className={styles.header}
				style={{ display: tag < 3 ? 'none' : 'flex' }}
			>
				{tag == 3 ? (
					<div>
						<div className={styles.icon}>
							<IconBombilla />
						</div>
						<b>1.&nbsp;Reflexión</b>
						<div className={styles.bar}></div>
						<span>¿Tengo un documento?</span>
					</div>
				) : tag == 4 ? (
					<div>
						<div className={styles.icon}>
							<IconManoIdentificacion />
						</div>
						<b>2.&nbsp;Identificación</b>
						<div className={styles.bar}></div>
						<span>Tus datos de interés</span>
					</div>
				) : tag == 5 ? (
					<div>
						<div className={styles.icon}>
							<IconBanderaTests />
						</div>
						<b>3.&nbsp;Tests</b>
						<div className={styles.bar}></div>
						<span style={{ minWidth: '330px' }}>
							Revision de varios documentos
						</span>
					</div>
				) : (
					tag == 6 && (
						<div>
							<div className={styles.icon}>
								<IconEstrellaValoracion />
							</div>
							<b>4.&nbsp;Valoración</b>
							<div className={styles.bar}></div>
							<span>Escribe una reseña.</span>
						</div>
					)
				)}
			</header>

			<container className={styles.container}>
				{tag == 0 ? (
					<div onClick={() => setTag(1)} className={styles.cardIntro}>
						<HowImage />
						<div className={styles.flexDirection}>
							<b className={styles.title1}>Aythen</b>
							<b className={styles.subtitle1}>Crea tu futuro</b>
						</div>
						<div
							className={`${styles.flexDirection} ${styles.textCenter}`}
							style={{ zIndex: 99, marginTop: '4%' }}
						>
							<b className={styles.title}>
								Sistema <br />
								operativo
							</b>
							<b className={styles.subtitle}>Cloud Ia</b>
							<span className={styles.text}>
								Empieza por la gestión documental de tu empresa y ahorra tiempo
								digitalizando
							</span>
							<button>
								Empieza aqui
								<svg fill='none' viewBox='0 0 24 24'>
									<path
										stroke='currentColor'
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth='2'
										d='M19 12H5m14 0-4 4m4-4-4-4'
									/>
								</svg>
							</button>
						</div>
						<div>
							<IconLambda />
						</div>
					</div>
				) : tag == 1 ? (
					<div onClick={() => setTag(2)} className={styles.cardSecond}>
						<HowImage />
						<b className={styles.title}>Descubre nuesta</b>
						<b className={styles.subtitle}>Carpeta inteligente</b>

						<div className={styles.icon}>{icons['ay']}</div>

						<span className={styles.text}>
							Gracias a ellas vas a poder reconcer y almacenar tus datos de
							forma totalmente segura gracias a nuestros servidores europeos.
						</span>
						<button onClick={() => setTag(2)}>
							Siguientes pasos
							<svg fill='none' viewBox='0 0 24 24'>
								<path
									stroke='currentColor'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeWidth='2'
									d='M19 12H5m14 0-4 4m4-4-4-4'
								/>
							</svg>
						</button>
					</div>
				) : tag == 2 ? (
					<div onClick={() => setTag(3)} className={styles.cardInfo}>
						<div className={styles.title}>
							<b>
								Tienes 4 pasos, piensa en un documento y empieza tu camino..
							</b>
							<IconLambda />
						</div>
						<ul className={styles.list}>
							<li>
								<div>
									<div className={styles.icon}>
										<IconBombilla />
									</div>
									1.&nbsp;Reflexión
								</div>
								<div className={styles.bar}></div>
								<div className={styles.content}>
									<b>¿Tengo documentos físicos? ¿Cuáles son?</b>
									<span>Ejemplo: factura</span>
								</div>
							</li>
							<li>
								<div>
									<div className={styles.icon}>
										<IconManoIdentificacion />
									</div>
									2.&nbsp;Identificación
								</div>
								<div className={styles.bar}></div>
								<div className={styles.content}>
									<b>¿Qué datos de interés, subrallarías de ellos?</b>
									<span>Ejemplos: Nif Cliente</span>
								</div>
							</li>
							<li>
								<div>
									<div className={styles.icon}>
										<IconBanderaTests />
									</div>
									3.&nbsp;Tests
								</div>
								<div className={styles.bar}></div>
								<div className={styles.content}>
									<b>¿Qué necesitas? Un excel autorellenado? Elige:</b>
									<span>Ejemplo: CSV, XML, TXT...</span>
								</div>
							</li>
							<li>
								<div>
									<div className={styles.icon}>
										<IconEstrellaValoracion />
									</div>
									4.&nbsp;Valoración
								</div>
								<div className={styles.bar}></div>
								<div className={styles.content}>
									<b>No te olvides, si ha sido útil, escribe una reseña.</b>
									<span>Vista previa + DESCARGA</span>
								</div>
							</li>
						</ul>
						<div className={styles.progress}>
							<div
								className={styles.barProgress}
								style={{ width: `${progress}%` }}
							/>
						</div>
					</div>
				) : tag == 3 ? (
					<div
						className={styles.cardTest}
						style={{
							marginTop: '10vh',
						}}
					>
						<ComponentAythen />
						<div className={styles.legend}>
							<div>
								<IconCablePorRevisar />
								<span>Revisado</span>
							</div>
							<div>
								<IconCheckIdentificado />
								<span>Por revisar</span>
							</div>
						</div>

						<div className={styles.message}>
							<div>
								<textarea
									placeholder='Escribe las variables para detectar'
									onKeyDown={(e) => handleKeyDown(e)}
								/>
								<button>
									<svg fill='none' viewBox='0 0 24 24'>
										<path
											stroke='currentColor'
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='m7 16 4-4-4-4m6 8 4-4-4-4'
										/>
									</svg>
								</button>
							</div>
							<span>
								Si lo necesitas, puedes ver
								<a
									href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'
									target='_blank'
								>
									este vídeo
								</a>
								para ayudarte
							</span>
						</div>
						<div className={styles.buttons}>
							<button onClick={() => handleDoc()}>
								<svg fill='none' viewBox='0 0 24 24'>
									<path
										stroke='currentColor'
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth='2'
										d='M5 12h14m-7 7V5'
									/>
								</svg>
								Añadir documentos
							</button>
							<button className={styles.button} onClick={() => setTag(4)}>
								<svg fill='currentColor' viewBox='0 0 24 24'>
									<path
										fillRule='evenodd'
										d='M8.6 5.2A1 1 0 0 0 7 6v12a1 1 0 0 0 1.6.8l8-6a1 1 0 0 0 0-1.6l-8-6Z'
										clipRule='evenodd'
									/>
								</svg>
								Guardar
							</button>
						</div>
						{files.length > 0 && (
							<div className={styles.box}>
								{false && <HowRender />}

								<div className={styles.flexDirection} style={{ width: '100%' }}>
									<HowInput labels={labels} setLabels={setLabels} />
									<HowTest />
								</div>
							</div>
						)}
					</div>
				) : tag == 4 ? (
					<div className={styles.cardFile}>
						<span className={styles.text}>{text}</span>
						<button onClick={() => setTag(5)}>
							Siguiente paso
							<svg fill='none' viewBox='0 0 24 24'>
								<path
									stroke='currentColor'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeWidth='2'
									d='M19 12H5m14 0-4 4m4-4-4-4'
								/>
							</svg>
						</button>
						<ul className={styles.list}>
							<li
								onClick={() => setType('PDF')}
								className={`${type == 'PDF' ? styles.active : ''}`}
							>
								{icons['pdf']}
							</li>
							<li
								onClick={() => setType('TXT')}
								className={`${type == 'TXT' ? styles.active : ''}`}
							>
								{icons['txt']}
							</li>
							<li
								onClick={() => setType('CSV')}
								className={`${type == 'CSV' ? styles.active : ''}`}
							>
								{icons['csv']}
							</li>
							<li
								onClick={() => setType('XML')}
								className={`${type == 'XML' ? styles.active : ''}`}
							>
								{icons['xml']}
							</li>
							<li
								onClick={() => setType('JSON')}
								className={`${type == 'JSON' ? styles.active : ''}`}
							>
								{icons['json']}
							</li>
						</ul>
						<span className={styles.text}>
							Descubre de +50 funcionalidades para automatizar tu empresa
						</span>
						<ul className={styles.subList}>
							<li>✅ Whatsapp</li>
							<li>🗓️ Calendar</li>
							<li>🤖 Bot</li>
							<li>🚀 Xml</li>
							<li>📉 Excel</li>
							<li>👨🏻‍💻 Kanban</li>
							<li>📩 Email</li>
							<li>🗂️ Carpeta</li>
						</ul>
					</div>
				) : tag == 5 ? (
					<div className={styles.cardPayment}>
						<span className={styles.text}>
							Vas a necesitar subir el saldo de tu cuenta si quieres descargar
							el archivo. <br />
							Recarga con saldo o subscríbete a nuestra mensualidad.
						</span>
						<div className={styles.form}>
							<div className={styles.first}>
								<div className={styles.flexDirection}>
									<b>Pagar con</b>
									<div className={styles.radio}>
										<label>
											<input
												type='radio'
												name='paymentType'
												value='saldo'
												checked={paymentType === 'saldo'}
												onChange={(e) => setPaymentType(e.target.value)}
											/>
											Saldo
										</label>

										<label>
											<input
												type='radio'
												name='paymentType'
												value='mensual'
												checked={paymentType === 'mensual'}
												onChange={(e) => setPaymentType(e.target.value)}
											/>
											Mensual
										</label>
										<label>
											<input
												type='radio'
												name='paymentType'
												value='anual'
												checked={paymentType === 'anual'}
												onChange={(e) => setPaymentType(e.target.value)}
											/>
											Anual
										</label>
									</div>
								</div>
								<div className={styles.flexDirection}>
									<b>Pagar con</b>
									<div className={styles.radio}>
										<label>
											<input
												type='radio'
												name='paymentMethod'
												value='card'
												checked={paymentMethod === 'card'}
												onChange={(e) => setPaymentMethod(e.target.value)}
											/>
											Tarjeta
										</label>
										<label>
											<input
												type='radio'
												name='paymentMethod'
												value='bank'
												checked={paymentMethod === 'bank'}
												onChange={(e) => setPaymentMethod(e.target.value)}
											/>
											Banco
										</label>
										<label>
											<input
												type='radio'
												name='paymentMethod'
												value='transfer'
												checked={paymentMethod === 'transfer'}
												onChange={(e) => setPaymentMethod(e.target.value)}
											/>
											Transferencia
										</label>
									</div>
								</div>
							</div>
							<div className={styles.second}>
								<div>
									<label>Número de tarjeta</label>
									<input type='text' />
								</div>
								<div>
									<label>Fecha expiración</label>
									<input type='text' />
								</div>
								<div>
									<label>CVV</label>
									<input type='text' />
								</div>
							</div>
							<label className={styles.save}>
								<input type='checkbox' />
								Guardar detalles de la tarjeta
							</label>
						</div>
						<button onClick={() => setTag(6)}>Pagar USD 59.25</button>
					</div>
				) : (
					tag == 6 && (
						<div className={styles.cardOpinion}>
							<span className={styles.text}>
								Escríbenos un email, una reseña en Google, un postal, o incluso
								puedes donar para ayudar a crear más funcionalidades.
							</span>
							<div className={styles.link}>
								<div>
									<svg fill='none' viewBox='0 0 24 24'>
										<path
											stroke='currentColor'
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961'
										/>
									</svg>
								</div>
								<p>
									Este
									<a href='https://aythen.com/contact' target='_blank'>
										link
									</a>
									estará disponible durante la suscripción activa, una vez
									terminada tendrás 7 días para renovar.
								</p>
							</div>
							<div className={styles.flex}>
								<div className={styles.boxGoogle}>
									<div className={styles.star}>
										{Array.from({ length: 5 }).map((_, index) => (
											<div key={index}>
												<svg
													class='w-6 h-6 text-gray-800 dark:text-white'
													aria-hidden='true'
													xmlns='http://www.w3.org/2000/svg'
													width='24'
													height='24'
													fill='currentColor'
													viewBox='0 0 24 24'
												>
													<path d='M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z' />
												</svg>
											</div>
										))}
									</div>
									<div>
										<IconGoogle />
									</div>
								</div>
								<div className={styles.boxTrustpilot}>
									<div className={styles.logo}>
										<IconTrustpilot />
									</div>
									<b>Excelnt</b>
									<div className={styles.star}>
										{Array.from({ length: 5 }).map((_, index) => (
											<div key={index}>
												<svg
													class='w-6 h-6 text-gray-800 dark:text-white'
													aria-hidden='true'
													xmlns='http://www.w3.org/2000/svg'
													width='24'
													height='24'
													fill='currentColor'
													viewBox='0 0 24 24'
												>
													<path d='M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z' />
												</svg>
											</div>
										))}
									</div>

									<b>
										Basado en
										<a
											href='https://trustpilot.com/review/aythen.com'
											target='_blank'
										>
											456 reviews
										</a>
									</b>
								</div>
							</div>
						</div>
					)
				)}
			</container>

			<footer className={styles.footer}>
				<div>
					<IconLambda />
					Aythen
				</div>
				<button onClick={() => handleReturn()}>
					{tag == 0 ? 'Volver a la web' : 'Ir atrás'}
				</button>
				<b>{formattedTag}</b>
			</footer>
		</div>
	)
}

export default HowToUse

const ComponentAythen = () => {
	return (
		<div className={styles.componentAythen}>
			<div className={`${styles.letter} ${styles.active}`}>
				<b>A</b>
				<span>A/El Origen</span>
			</div>
			<div className={styles.symbol}>
				<svg fill='none' viewBox='0 0 24 24'>
					<path
						stroke='currentColor'
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth='2'
						d='M5 12h14m-7 7V5'
					/>
				</svg>
			</div>
			<div className={styles.symbol}>
				<b>Y</b>
				<span>Y/La conexión</span>
			</div>
			<div className={styles.symbol}>
				<b style={{ marginTop: '-18px' }}>=</b>
			</div>
			<div className={styles.symbol}>
				<b>
					<IconLambda />
				</b>
				<span>Aythen</span>
			</div>
		</div>
	)
}

// ---------------------------------------

const HowImage = () => {
	return (
		<div>
			<div className={styles.image}>
				<div className={styles.image1}>
					<IconSumar />
				</div>
				<div className={styles.image2}>
					<IconBombilla />
				</div>
				<div className={styles.image3}>
					<IconStar />
				</div>
				<div className={styles.image4}>
					<IconCorazon />
				</div>
				<div className={styles.image5}>
					<IconSumar />
				</div>
			</div>
		</div>
	)
}

const HowRender = () => {
	return (
		<div className={styles.howRender}>factura subida sacar el sign firm</div>
	)
}

const HowTest = () => {
	return (
		<div className={styles.howTest}>
			<div className={styles.box}>
				<div className={styles.icon}>
					<IconCheckIdentificado />
				</div>
				<div className={styles.file}>{icons['pdf']}</div>
				<span>Factura #1</span>

				<div className={styles.text}>
					Conecta tu/s archivos para empezar el proceso de forma externa
				</div>
			</div>
		</div>
	)
}

const HowInput = ({ labels, setLabels }) => {
	const handleDelete = (label) => {
		setLabels(labels.filter((item) => item !== label))
	}

	return (
		<div className={styles.howInput}>
			{labels.map((label, index) => (
				<label key={index} onClick={(e) => e.preventDefault()}>
					{label.title}
					<button onClick={() => handleDelete(label)}>
						<svg fill='none' viewBox='0 0 24 24'>
							<path
								stroke='currentColor'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='1'
								d='M6 18 17.94 6M18 18 6.06 6'
							/>
						</svg>
					</button>
				</label>
			))}

			<div className={styles.text}>
				Sube tu/s archivo/s para empezar el proceso de forma local
			</div>
		</div>
	)
}

const HowFiles = () => {
	return (
		<div className={styles.howFiles}>
			<div>icono png jpg</div>
		</div>
	)
}
