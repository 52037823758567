import React, { useState } from "react"
import {useDispatch} from 'react-redux'
import styles from "./index.module.css"


import {
  addFormResponses,
} from "../../views/app/v1-1/actions/form"


const FormPreview = ({
  formId,
  responses,
  setResponses,
  questions,
  setPreviewMode,
}) => {
  const [email, setEmail] = useState("")

  const dispatch = useDispatch()

  const handleChange = (questionId, value) => {
    setResponses({
      ...responses,
      [questionId]: value,
    })
  }




  const handleSubmit = async () => {  
    const formattedResponses = questions.reduce((acc, question, index) => {
      const answerKey = String.fromCharCode(65 + index); 
      const response = responses[question.id];
      
      if (response === undefined) {
        console.warn(`Response for question ID ${question.id} is undefined`);
      }
  
      acc[answerKey] = response || "";
      return acc;
    }, {});
  
    const result = {
      id: Math.random().toString(36).substr(2, 9), 
      email,
      answers: formattedResponses,
    };

    const resp = await dispatch(addFormResponses({
      projectId: formId,
      data: {
        A: {
          type: "string",
          content: "probando1111abc",
        },
      },
    }))

  };
 

  const renderQuestion = (question) => {
    switch (question.type) {
      case "checkbox":
        return (
          <div key={question.id} className={styles.questionCheckbox}>
            <h3>{question.text}</h3>
            {question.options.map((option, index) => (
              <div key={index}>
                <label>
                  <input
                    type="checkbox"
                    value={option}
                    onChange={(e) =>
                      handleChange(question.id, {
                        ...responses[question.id],
                        [option]: e.target.checked,
                      })
                    }
                  />
                  {option}
                </label>
              </div>
            ))}
          </div>
        )
      case "radio":
        return (
          <div key={question.id} className={styles.questionRadio}>
            <h3>{question.text}</h3>
            {question.options.map((option, index) => (
              <div key={index}>
                <label>
                  <input
                    type="radio"
                    name={`question-${question.id}`}
                    value={option}
                    onChange={(e) => handleChange(question.id, e.target.value)}
                  />
                  {option}
                </label>
              </div>
            ))}
          </div>
        )
      case "short-answer":
        return (
          <div key={question.id} className={styles.questionShortAnswer}>
            <h3>{question.text}</h3>
            <input
              type="text"
              onChange={(e) => handleChange(question.id, e.target.value)}
            />
          </div>
        )
      case "long-answer":
        return (
          <div key={question.id} className={styles.questionLongAnswer}>
            <h3>{question.text}</h3>
            <textarea
              onChange={(e) => handleChange(question.id, e.target.value)}
            />
          </div>
        )
      case "dropdown":
        return (
          <div key={question.id} className={styles.questionDropdown}>
            <h3>{question.text}</h3>
            <select onChange={(e) => handleChange(question.id, e.target.value)}>
              {question.options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        )
      case "file-upload":
        return (
          <div key={question.id} className={styles.questionFileUpload}>
            <h3>{question.text}</h3>
            <input
              type="file"
              onChange={(e) => handleChange(question.id, e.target.files)}
            />
          </div>
        )
      case "linear-scale":
        return (
          <div key={question.id} className={styles.questionLinearScale}>
            <h3>{question.text}</h3>
            <input
              type="range"
              min={1}
              max={5}
              onChange={(e) => handleChange(question.id, e.target.value)}
            />
          </div>
        )
      case "date":
        return (
          <div key={question.id} className={styles.questionDate}>
            <h3>{question.text}</h3>
            <input
              type="date"
              onChange={(e) => handleChange(question.id, e.target.value)}
            />
          </div>
        )
      case "time":
        return (
          <div key={question.id} className={styles.questionTime}>
            <h3>{question.text}</h3>
            <input
              type="time"
              onChange={(e) => handleChange(question.id, e.target.value)}
            />
          </div>
        )
      case "multiple-choice":
        return (
          <div key={question.id} className={styles.questionMultipleChoice}>
            <h3>{question.text}</h3>
            {question.options.map((option, index) => (
              <div key={index}>
                <label>
                  <input
                    type="radio"
                    name={`question-${question.id}`}
                    value={option}
                    onChange={(e) => handleChange(question.id, e.target.value)}
                  />
                  {option}
                </label>
              </div>
            ))}
          </div>
        )
      case "multiple-choice-grid":
        return (
          <div key={question.id} className={styles.questionMultipleChoiceGrid}>
            <h3>{question.text}</h3>
            {question.rows.map((row, rowIndex) => (
              <div key={rowIndex}>
                <strong>{row}</strong>
                {question.columns.map((col, colIndex) => (
                  <div key={colIndex}>
                    <label>
                      <input
                        type="radio"
                        name={`question-${question.id}-${rowIndex}`}
                        value={col}
                        onChange={(e) =>
                          handleChange(question.id, {
                            ...responses[question.id],
                            [row]: e.target.value,
                          })
                        }
                      />
                      {col}
                    </label>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )
      case "checkbox-grid":
        return (
          <div key={question.id} className={styles.questionCheckboxGrid}>
            <h3>{question.text}</h3>
            {question.rows.map((row, rowIndex) => (
              <div key={rowIndex}>
                <strong>{row}</strong>
                {question.columns.map((col, colIndex) => (
                  <div key={colIndex}>
                    <label>
                      <input
                        type="checkbox"
                        value={col}
                        onChange={(e) =>
                          handleChange(question.id, {
                            ...responses[question.id],
                            [row]: {
                              ...responses[question.id][row],
                              [col]: e.target.checked,
                            },
                          })
                        }
                      />
                      {col}
                    </label>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className={styles.preview}>
      <div className={styles.header}>
        <h2>Preview Form</h2>
        <div className={styles.input}>
          <label>
            Email:
            </label>
            <input
              type="email"
              placeholder={'Insert your email'}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
        </div>
        <div className={styles.email}>
          <span>info@aythen.com</span>
          <button>Switch account</button>
          <svg fill="none" viewBox="0 0 24 24">
            <path
              stroke="currentColor"
              d="M15 17h3a3 3 0 0 0 0-6h-.025a5.56 5.56 0 0 0 .025-.5A5.5 5.5 0 0 0 7.207 9.021C7.137 9.017 7.071 9 7 9a4 4 0 1 0 0 8h2.167M12 19v-9m0 0-2 2m2-2 2 2"
            />
          </svg>
        </div>
        <div className={styles.footer}>* Indicates required question</div>
      </div>
      {questions.length > 0 ? (
        <div className={styles.listPreview}>
          {questions.map((question) => renderQuestion(question))}
          <button onClick={handleSubmit} className={styles.addPreview}>
            Sign now here
          </button>
        </div>
      ) : (
        <div className={styles.nonePreview}>
          <div>
            <b>Todavia no hay preguntas</b>
            <p>
              Si estás interesado rellena tu email y le enviaremos un mensaje
              cuadno todo este listo
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default FormPreview
