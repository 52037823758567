import { createSlice } from '@reduxjs/toolkit';

import {
  fetchsDashboard,
  fetchDashboard,
  updateDashboard,
  deleteDashboard
} from '../actions/dashboard'



export const initialComponent = {
  id: '',
  isavailable: '',
  type: 'LineScatter',
  position: '',
  title: '',
  description: '',
  columnSize: 1,
  style: '',
  vector: '',
  filter: '',
}




const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    status: 'pending',
    loading: false,

    status: null,

    nodes: [],
    edges: [],

    dashboards: [], 
    
    dashboard: null, 
    board: {}, 

    component: {},
  },
  reducers: {
    setDashboard: (state, action) => {
      const id = action.payload
      state.dashboard = id
    },
    setBoard: (state, action) => {
      state.board = action.payload

    },
    setComponent: (state, action) => {
      state.component = action.payload;
    },
    addComponent: (state, action) => {
      const id = action.payload.dashboard
      if(!state.board[id]) state.board[id] = { id: id, components: []}

      state.board[id].components.push(action.payload.component);
      state.status = 'save'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchsDashboard.pending, (state) => {
        state.status = 'pending';
        state.loading = true;
      })
      .addCase(fetchsDashboard.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.loading = false;
        state.dashboards = action.payload;
      })
      .addCase(fetchsDashboard.rejected, (state, action) => {
        state.status = 'rejected';
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchDashboard.fulfilled, (state, action) => {
        if(action.payload !== 400){
          if(!state.board[action.payload.id]) state.board = {}
  
          state.board[action.payload.id] = {
            ...action.payload,
            data: JSON.parse(action.payload.data),   
            components: JSON.parse(action.payload.components),
            shared: JSON.parse(action.payload.shared), 
          }
        }
      })

      .addCase(updateDashboard.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(updateDashboard.fulfilled, (state, action) => {
        state.status = 'save';
        if(!state.board[action.payload.id]) state.board = {}
        state.board[action.payload.id] = {
          ...action.payload,
          components: JSON.parse(action.payload.components),
          data: JSON.parse(action.payload.data),
          shared: JSON.parse(action.payload.shared),
        }
      })
      .addCase(updateDashboard.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message;
      })

      .addCase(deleteDashboard.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(deleteDashboard.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.dashboards = action.payload;
      })
      .addCase(deleteDashboard.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message;
      })


  },
});

export const {
  setDashboard,
  addComponent,
  setComponent,
  setBoard,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;