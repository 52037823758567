import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch } from 'react-redux'

import styles from "./payment.module.css"

import CardMastercard from "./assets/card/mastercard.png"
import CardVisa from "./assets/card/visa.png"


import {
  fetchInvoice,
} from "../views/app/v1-1/actions/iam"

import {
  addUserStripePayment
} from "../views/app/v1-1/actions/stripe"

const Checkout = () => {
  const { id } = useParams()

  const dispatch = useDispatch()
  const navigate = useNavigate()


  const fetchData = async () => {
    const resp = await dispatch(fetchInvoice({
      id: id
    }))

    if (!resp.payload._id) {
      navigate('/')
    } else {
      let invoice = resp.payload
      setState({
        company: invoice.companyName,
        type: "live",
        file: invoice.file,

        value: "149.00",

        email: "",
        name: "",
        pan: "",
        expiredAt: "",
        cvc: "",

        currency: invoice.currency.currency,

        security: false,
      })


      if(invoice.status == 200){
        setIsPaid(true)
      }

      setContent(resp.payload.lineItems)
    }
  }

  useEffect(() => {
    fetchData()
  }, [id])


  const [isAlert, setIsAlert] = useState(false)
  const [isPromo, setIsPromo] = useState(false)
  const [value, setValue] = useState(0)

  const [state, setState] = useState({
    company: "Jokan.s",
    type: "live",

    value: "149.00",

    email: "",
    name: "",
    pan: "",
    expiredAt: "",
    cvc: "",

    currency: '$',

    security: false,
  })

  const [content, setContent] = useState([])


  useEffect(() => {
    let sum = 0
    content.map(i => sum += parseFloat(i.value))

    setValue(sum.toFixed(2))
  }, [content])

  const handleChange = (e) => {
    const { value, name } = e.target

    setState((prev) => ({
      ...prev,
      [name]: value,
    }))
  }


  const [isPaid, setIsPaid] = useState(false)

  const handlePay = async () => {
    const resp = await dispatch(addUserStripePayment({
      id: id,
      email: "eng.carlos.valle@gmail.com",
      value: 1000,
      number: "4242 4242 4242 4242",
      exp_month: "12",
      exp_year: "25",
      cvc: "123",
    }))

    if(resp && resp.payload){
      if(resp.payload.status == 200){
        setIsPaid(true)
      }
    }
  }


  const handleDownloadInvoice = () => {
    if (state.file) {
      const link = document.createElement('a');
      link.href = `data:application/pdf;base64,${state.file}`;
      link.download = 'invoice.pdf';
      link.click();
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.checkout}>
        <div className={styles.left}>
          <div>
            <div className={styles.header}>
              <div className={styles.icon}>
                {/* prettier-ignore */}
                <svg fill="none" viewBox="0 0 24 24" > <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M6 12c.263 0 .524-.06.767-.175a2 2 0 0 0 .65-.491c.186-.21.333-.46.433-.734.1-.274.15-.568.15-.864a2.4 2.4 0 0 0 .586 1.591c.375.422.884.659 1.414.659.53 0 1.04-.237 1.414-.659A2.4 2.4 0 0 0 12 9.736a2.4 2.4 0 0 0 .586 1.591c.375.422.884.659 1.414.659.53 0 1.04-.237 1.414-.659A2.4 2.4 0 0 0 16 9.736c0 .295.052.588.152.861s.248.521.434.73a2 2 0 0 0 .649.488 1.809 1.809 0 0 0 1.53 0 2.03 2.03 0 0 0 .65-.488c.185-.209.332-.457.433-.73.1-.273.152-.566.152-.861 0-.974-1.108-3.85-1.618-5.121A.983.983 0 0 0 17.466 4H6.456a.986.986 0 0 0-.93.645C5.045 5.962 4 8.905 4 9.736c.023.59.241 1.148.611 1.567.37.418.865.667 1.389.697Zm0 0c.328 0 .651-.091.94-.266A2.1 2.1 0 0 0 7.66 11h.681a2.1 2.1 0 0 0 .718.734c.29.175.613.266.942.266.328 0 .651-.091.94-.266.29-.174.537-.427.719-.734h.681a2.1 2.1 0 0 0 .719.734c.289.175.612.266.94.266.329 0 .652-.091.942-.266.29-.174.536-.427.718-.734h.681c.183.307.43.56.719.734.29.174.613.266.941.266a1.819 1.819 0 0 0 1.06-.351M6 12a1.766 1.766 0 0 1-1.163-.476M5 12v7a1 1 0 0 0 1 1h2v-5h3v5h7a1 1 0 0 0 1-1v-7m-5 3v2h2v-2h-2Z" /> </svg>
              </div>
              <span>{state.company}</span>
              <label>{state.type} mode</label>
            </div>
            <div className={styles.resume}>
              <span>Pay {state.company}</span>
              <b>{state.currency}{value}</b>
            </div>
            <div className={styles.content}>
              <ul>
                {content.map((item, index) => (
                  <li key={index}>
                    <div>
                      <span>{item.item}</span>
                      {content.length - 1 == index && (
                        <label onClick={() => setIsPromo(true)}>
                          Add promotion code
                        </label>
                      )}
                    </div>
                    <b>{state.currency}{item.value}</b>
                  </li>
                ))}
                <li>
                  <span>
                  Total adeudado
                  </span>
                  <b>{state.currency}{value}</b>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.f0}>
              <span>Powered by</span>
              <b>Aythen</b>
            </div>
            <div className={styles.f1}>
              <a onClick={() => navigate('/')}>
                Terminos
              </a>
              <a onClick={() => navigate('/')}>
                Privacidad
              </a>
            </div>
          </div>
        </div>
        {!isPaid ? (
          <div className={styles.right}>
            {false && (
              <>
                <div className={styles.buttons}>
                  <button className={styles.google}>
                    {/* prettier-ignore */}
                    <svg fill="currentColor" viewBox="0 0 24 24"> <path fillRule="evenodd" d="M12.037 21.998a10.313 10.313 0 0 1-7.168-3.049 9.888 9.888 0 0 1-2.868-7.118 9.947 9.947 0 0 1 3.064-6.949A10.37 10.37 0 0 1 12.212 2h.176a9.935 9.935 0 0 1 6.614 2.564L16.457 6.88a6.187 6.187 0 0 0-4.131-1.566 6.9 6.9 0 0 0-4.794 1.913 6.618 6.618 0 0 0-2.045 4.657 6.608 6.608 0 0 0 1.882 4.723 6.891 6.891 0 0 0 4.725 2.07h.143c1.41.072 2.8-.354 3.917-1.2a5.77 5.77 0 0 0 2.172-3.41l.043-.117H12.22v-3.41h9.678c.075.617.109 1.238.1 1.859-.099 5.741-4.017 9.6-9.746 9.6l-.215-.002Z" clipRule="evenodd" /> </svg>
                    Pay
                  </button>
                  <button onClick={() => handlePay()}>
                    Pay with
                    {/* prettier-ignore */}
                    <svg fill="currentColor" viewBox="0 0 24 24" > <path fillRule="evenodd" d="M12 2a1 1 0 0 1 .932.638l7 18a1 1 0 0 1-1.326 1.281L13 19.517V13a1 1 0 1 0-2 0v6.517l-5.606 2.402a1 1 0 0 1-1.326-1.281l7-18A1 1 0 0 1 12 2Z" clipRule="evenodd" /> </svg>
                  </button>
                </div>
                <div className={styles.line}>
                  <span>Or pay with card</span>
                </div>
              </>
            )}
            <div className={styles.input}>
              <label>Email</label>
              <input
                type="text"
                spellCheck="false"
                placeholder=""
                name={"email"}
                value={state.email}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className={styles.card}>
              <div className={styles.info}>
                <label>Información de la tarjeta</label>
                <div>
                  <input
                    type="text"
                    spellCheck="false"
                    placeholder="1234 1234 1234 1234 "
                    name={"pan"}
                    value={state.pan}
                    onChange={(e) => handleChange(e)}
                  />
                  <img src={CardMastercard} />
                  <img src={CardVisa} />
                </div>
              </div>
              <div className={styles.grid2}>
                <div className={styles.expired}>
                  <label>MM/YY</label>
                  <div>
                    <input
                      type="text"
                      spellCheck="false"
                      placeholder="MM / YY"
                      name={"expiredAt"}
                      value={state.expiredAt}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className={styles.cvc}>
                  <label>CVC</label>
                  <div>
                    <input
                      type="text"
                      spellCheck="false"
                      placeholder="123"
                      name={"cvc"}
                      value={state.cvc}
                      onChange={(e) => handleChange(e)}
                    />
                    {/* prettier-ignore */}
                    <svg fill="currentColor" viewBox="0 0 24 24" > <path fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clipRule="evenodd" /> </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.input}>
              <label>Titular de la tarjeta</label>
              <input
                type="text"
                spellCheck="false"
                placeholder="Nombre completo en la tarjeta"
                name={"name"}
                value={state.name}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className={styles.alert}>
              <div
                className={`${styles.checkbox} ${state.security ? styles.active : ""}`}
                onClick={() =>
                  handleChange({
                    target: { name: "security", value: !state.security },
                  })
                }
              >
                {/* prettier-ignore */}
                <svg fill="none" viewBox="0 0 24 24" > <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 11.917 9.724 16.5 19 7.5" /> </svg>
              </div>
              <div className={styles.text}>
                <b>
                Guarde mi información de forma segura con un solo clic
                </b>
                <p>
                  Pague más rápido en {state.company} y en todos los lugares donde se acepte Link.
                  </p>
              </div>
            </div>
            {isPromo && (
              <div className={styles.promo}>
                <label>Promotional opiton code</label>
                <div className={styles.input}>
                  <input type="text" spellCheck="false" placeholder="" />
                </div>
              </div>
            )}
            <div className={styles.pay}>
              <button onClick={() => handlePay()}>
                Pagar Ahora
              </button>
            </div>
          </div>
        ) : (
          <div className={styles.right}>
            <div className={styles.success}>
              <div className={styles.top}>
                <b>
                Factura de Vivien
                </b>
                <span>
                Facturado a Pearson Spected Corporation
                </span>
                <span>
                  Factura No.BF2F0720-0003
                </span>
              </div>
              <div className={styles.bottom}>
                <svg fill="currentColor" viewBox="0 0 24 24">
                  <path fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clipRule="evenodd" />
                </svg>
                <label>
                  Pagado
                </label>
                <b>
                  SGD 50.00 pagad 28 Jul 2020
                </b>
                <button onClick={() => handleDownloadInvoice()}>
                  <svg fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19V5m0 14-4-4m4 4 4-4" />
                  </svg>
                  Descargar como PDF..
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Checkout
