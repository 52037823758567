import React from "react";
import { useTranslation } from "react-i18next";

import Context from "@home/context/Context";

import HeaderTop from "@home/Header/HeaderTop/HeaderTop";
import Header from "@home/Header/Header";
import PopupMobileMenu from "@home/Header/PopUpMobileMenu";
import Footer from "@home/Footers/Footer";
import Copyright from "@home/Footers/Copyright";
import Breadcrumb from "@home/Common/Breadcrumb";
import Roadmap from "@home/RoadMap/Roadmap";
import BackToTop from "../backToTop";

import styles from '../index.module.css'

const RoadmapPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <main className={styles["page-wrapper"]} >
        <Context>
          <HeaderTop />
          <Header
            headerTransparent="header-transparent"
            headerSticky="header-sticky"
            btnClass="rainbow-gradient-btn"
          />
          <PopupMobileMenu />
          <Breadcrumb
            title={t("home1.roadmap.beadcrumb.text1")}
            text={t("home1.roadmap.beadcrumb.text2")}
          />

          <Roadmap />

          <BackToTop />
          <Footer />
          <Copyright />
        </Context>
      </main>
    </>
  );
};

export default RoadmapPage;
