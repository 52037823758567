import { createSlice } from '@reduxjs/toolkit'
import {
	acceptFriendRequest,
	cancelFriendRequest,
	getUserFriends,
	sendFriendRequest,
} from '../actions/friends'

const friendsSlices = createSlice({
	name: 'friends',
	initialState: {
		userFriends: [],
		receivedFriendRequests: [],
		sentFriendRequests: [],
	},
	reducers: {
		setUserFriends: (state, action) => {
			state.userFriends = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getUserFriends.pending, (state) => {
				state.loading = true
			})
			.addCase(getUserFriends.fulfilled, (state, action) => {
				state.loading = false
				state.userFriends = action.payload.friends
				state.receivedFriendRequests = action.payload.friendRequests
				state.sentFriendRequests = action.payload.sentRequests
			})
			.addCase(getUserFriends.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(sendFriendRequest.pending, (state) => {
				state.loading = true
			})
			.addCase(sendFriendRequest.fulfilled, (state, action) => {
				state.loading = false
				if (action.payload) {
					state.userFriends = action?.payload?.friends
					state.receivedFriendRequests = action.payload.friendRequests
					state.sentFriendRequests = action.payload.sentRequests
				}
			})
			.addCase(sendFriendRequest.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(acceptFriendRequest.pending, (state) => {
				state.loading = true
			})
			.addCase(acceptFriendRequest.fulfilled, (state, action) => {
				state.loading = false
				state.userFriends = action.payload.friends
				state.receivedFriendRequests = action.payload.friendRequests
				state.sentFriendRequests = action.payload.sentRequests
			})
			.addCase(acceptFriendRequest.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(cancelFriendRequest.pending, (state) => {
				state.loading = true
			})
			.addCase(cancelFriendRequest.fulfilled, (state, action) => {
				state.loading = false
				state.userFriends = action.payload.friends
				state.receivedFriendRequests = action.payload.friendRequests
				state.sentFriendRequests = action.payload.sentRequests
			})
			.addCase(cancelFriendRequest.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
	},
})

export const { setUserFriends } = friendsSlices.actions

export default friendsSlices.reducer
