import { createSlice } from '@reduxjs/toolkit';



import {
    fetchsNote,
    fetchNote,
    
    addNote,
    deleteNote,
} from '../actions/note'



const noteSlice = createSlice({
  name: 'note',
  initialState: {
    note: null,
    message: null,

    notes: [],
  },
  reducers: {
    setNote: (state, action) => {
      const noteIndex = state.notes.findIndex(note => note.id === action.payload);
      if (noteIndex !== -1) {
         state.note = state.notes[noteIndex];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNote.fulfilled, (state, action) => {
        state.note = action.payload;
        state.notes.push(action.payload)
      })
      .addCase(fetchsNote.fulfilled, (state, action) => {
        state.notes = action.payload;

        if(action.payload.length > 0){
          state.note = action.payload[0]
        }
      })
      .addCase(fetchNote.fulfilled, (state, action) => {
        state.note = action.payload
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        state.notes = state.notes.filter(note => note.id !== action.payload);

        if(action.payload.length > 0){
          state.note = action.payload[0]
        }
      })
  },
});

export const {
    setNote
} = noteSlice.actions;

export default noteSlice.reducer;












