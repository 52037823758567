const settings = {
    t1: "Casa",
    t2: "Aythen DB",
    t3: "Kanban",
    t4: "I miei documenti",
    t5: "Contratti",
    t6: "Fatturazione",
    info: {
        t0: `Aggiungi tag`,
        t1: `Chiave`,
        t2: `Valore`,
        info: {
          t0: `escl. tasse con sconto`,
          t1: `Aggiornato 27 minuti fa`
        }
    },
    invoice: {
        send: {
            "t0": "Fattura",
            "t1": "Copia fattura",
            "t2": "Cambia l'organizzazione se non corrisponde",
            "t3": "Mancano alcuni dati da compilare",
            "t4": "Numero di identificazione fiscale",
            "t5": "DNI (documentazione ufficiale)",
            "t6": "Scegli il modo di pagamento che desideri effettuare",
            "t7": "Più info",
            "t8": "Pagamento",
            "t9": "Quanto denaro vuoi ricevere",
            "t10": "Contatti",
            "t11": "Seleziona un contatto",
            "t12": "Organizzazione",
            "t13": "Ti invieremo un processo alla tua email",
            "t14": "Modo intatto",
            "t15": "Voglio ricevere il denaro il più presto possibile",
            "t16": "1",
            "t17": "Programma avviso di invio",
            "t18": "2",
            "t19": "Ricorda domani se non si paga",
            "t20": "3",
            "t21": "Ricorda ogni giorno per 14 giorni",
            "t22": "Modo banca",
            "t23": "Factoring più rapido per i tuoi clienti",
            "t24": "1",
            "t25": "Pagamento immediato e pagamento sicuro",
            "t26": "2",
            "t27": "La banca anticipa il denaro",
            "t28": "3",
            "t29": "Migliori condizioni di pagamento",
            "t30": "Modo programmato",
            "t31": "Dici un giorno per chiedere il pagamento",
            "t32": "1",
            "t33": "Programma l'invio del giorno",
            "t34": "2",
            "t35": "Avvisare durante 14 giorni",
            "t36": "3",
            "t37": "Migliori condizioni di pagamento",
            "t38": "Invia fattura",
            "t39": "Pagamento riuscito",
            "t40": "Esegui trasferimento",
            "t41": "Stai per ricevere",
            "t42": "2.000$",
            "t43": "Carta di credito attuale",
            "t44": "**** 4543",
            "t45": "Processo scelto",
            "t46": "Pagamento istantaneo",
            "t47": "Paga fattura",
            "t48": "Annulla"
        }
    },
    status: {
        "t0": "Aythen",
        "t1": "stato",
        "t2": "Supporto di contatto",
        "t3": "Stato in diretta",
        "t4": "Cronologia",
        "t5": "ISCRIVITI",
        "t6": "Aythen API",
        "t7": "99,999% SLA tempo di attività",
        "t8": "15 gen",
        "t9": "100% uptime",
        "t10": "1 gen",
        "t11": "Oggi",
        "t12": "Legenda:",
        "t13": "Successo",
        "t14": "In attesa",
        "t15": "Errore",
        "t16": "Stato del sistema",
        "t17": "Manutenzione programmata per Bancontact",
        "t18": "Aythen",
        "t19": "© 2024 Aythen, Inc",
        "t20": "Visita il nostro sito web",
        "t21": "Termini e privacy",
        "t22": "Email",
        "t23": "Messaggio di testo",
        "t24": "Ricevi notifiche via email ogni volta che Stripe crea, aggiorna o risolve un incidente.",
        "t25": "Indirizzo email",
        "t26": "Iscrivi via email",
        "t27": "Per aggiornare le preferenze email esistenti, iscrivi nuovamente.",
        "t28": "Per ulteriori informazioni sull'iscrizione, visita le istruzioni nel Supporto.",
        "t29": "Questo sito è protetto da reCAPTCHA e si applicano le Condizioni di servizio e la Politica sulla privacy di Google.",
        "t30": "Ricevi notifiche via messaggio di testo ogni volta che Stripe crea o risolve un incidente.",
        "t31": "Codice paese",
        "t32": "Numero di telefono",
        "t33": "Iscrivi via messaggio di testo",
        "t34": "Per aggiornare le preferenze esistenti, iscrivi nuovamente.",
        "t35": "Per ulteriori informazioni sull'iscrizione, visita le istruzioni nel Supporto.",
        "t36": "Tariffe e dati possono applicarsi. Iscrivendoti, accetti i Termini di servizio di Atlassian e la Politica sulla privacy di Atlassian. Questo sito è protetto da reCAPTCHA e si applicano le Condizioni di servizio e la Politica sulla privacy di Google."
    },
    billing: {
        t1: `Compila i dati…`,
        t2: `Valuta`,
        t3: `Modifica`,
        t4: `Qui appaiono i tuoi dati di registrazione e di fatturazione. Potrai cambiare o
modificare per verificare il tuo accesso alle diverse funzionalità che Aythen Lite ti offre.`,
        t5: `Modifica dati`,
        t6: `Consumo di dati`,
        t7: `Email di contatto`,
        t8: `A modo di raccolta, ogni mese avrai la tua fattura elettronica preparata automaticamente alla tua email di registrazione.`,
        t9: `Email di fatturazione`,
        t10: `Salva`,
        t12: `Token di GPT`,
        t13: `Puoi creare un token di GPT per identificare e autenticare unicamente le tue richieste API.`,
        t14: `Token`,
        t15: `Salva`,
        t16: `Limiti di consumo`,
        t17: `Nella tua licenza potresti aver bisogno di più interazioni o GB di archiviazione. 
Per questi usi aggiuntivi puoi configurare avvisi in base a un totale.`,
        t177: `Saperne di più`,
        t18: `Aggiungi avviso a partire da `,
        t19: `Costi mensili`,
        t20: `Salva avviso`,
        t21: `Identificazione NIF/VAT`,
        t222: `IVA`,
        t23: `Salva`,
        t24: `Metodi di pagamento`,
        t25: `Scegli tra i seguenti metodi di pagamento per la tua account.`,
        t26: `Come vuoi gestire la tua licenza:`,
        t266: `Carta di credito`,
        t267: `SEPA Direct Debit`,
        t268: `mancano dati`,
        t27: `Tipi di notifiche`,
        t28: `Seleziona e configura le tue preferenze di notifica. Quando ti verranno inviate le notifiche?`,
        t29: `Tipi di notifiche`,
        t30: `Notifiche via email`,
        t31: `Promemoria giornalieri`,
        t32: `Notifiche di eventi speciali:`,
        t33: `Notifiche di messaggi privati:`,
        t34: `Fatture ricevute`,
        t340: `Aythen Lite Premium`,
        t341: `Aggiorna a Premium`,
        t342: `Lorem ipsum dolor et epsum holend.`,
        t343: `Lorem ipsum dolor et epsum holend et erem super tupper dolem herem
    superssupostom hellom super start.`,
        t344: `Lorem ipsum dolor et teresum`,
        t345: `Lorem ipsum dolor et teresum`,
        t346: `Gratis`,
        t347: `Per sempre`,
        t350: `Aggiungi`,
        t35: `Qui troverai la tua cronologia tra ricevute e inviate.`,
        t36: `Fatture inviate`,
        t360: `Fatture ricevute`,
        t361: `di`,
        t37: `Mese`,
        t38: `Anno`,
        t39: `Numero`,
        t40: `Metodo di pagamento`,
        t41: `Stato`,
        t42: `Totale (IVA inclusa)`,
        t43: `Scarica`,
        t44: `Elimina account`,
        t45: `Sei sicuro di voler eliminare il tuo account? Tieni presente che questa azione non può essere annullata e il tuo account sarà disattivato entro 48 ore se confermi.`,
        t46: `Elimina`,

        x1: `Modifica il nome dell'account`,
        x2: `Questo è un account aziendale`,
        x3: `Nome azienda`,
        x4: `Indirizzo stradale`,
        x5: `Indirizzo stradale 2`,
        x6: `Codice postale`,
        x7: `Città`,
        x8: `Paese`,
        x9: `Regione`,
        x10: `Conferma le modifiche`,
    },
    contracts: {
        t1: `Organizzazione`,
        t2: `Contratti dell'account`,
        t3: `Nome`,
        t4: `Stato`,
        t5: `Fine il`,
        t6: `Scarica`,
        t7: `Organizza qui i tuoi accordi aziendali.`,
        t8: `Quali sono i benefici?`,
        t0: 'Aggiungi contratto',
        popup: {
            t0: `Aperto il`,
            t1: `Stato`,
            t2: `Invia con avviso`,
            t3: `Elimina avviso`,
            t4: `Invia una volta`,
            t5: `Invio prioritario`,
            t6: `Marca firmato`,
            t7: `Per firmare`,
            t8: `Per eliminare devi aggiungere Elimina`,
            t9: `Firma`,
            t10: `Annulla`,
            t11: `Elimina`
        }
    }
}

export default settings