import React, { useCallback } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  MiniMap,
  Controls,
  Background
} from "reactflow";


import CustomNode from "./CustomReactflow";

const nodeTypes = {
  custom: CustomNode,
};

const initNodes = [
  {
    id: "1",
    type: "custom",
    data: { name: "A", color: "blue", emoji: "😎" },
    position: { x: 0, y: 50 },
  },
  {
    id: "2",
    type: "custom",
    data: { name: "B", color: "purple", emoji: "🤓" },
    position: { x: -200, y: 200 },
  },
  {
    id: "3",
    type: "custom",
    data: { name: "C", color: "purple", emoji: "🤩" },
    position: { x: 200, y: 200 },
  },
  {
    id: "4",
    type: "custom",
    data: { name: "D", color: "pink", emoji: "😜" },
    position: { x: 25, y: 400 },
  },
];

const initEdges = [
  {
    id: "e1-2",
    source: "1",
    target: "2",
  },
  {
    id: "e1-3",
    source: "1",
    target: "3",
  },
  {
    id: "e1-4",
    source: "3",
    target: "4",
  },
];

const Flow = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initEdges);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      nodeTypes={nodeTypes}
      fitView
      className=" bg-slate-100"
      style={{ background: '#1a1a1a' }}
      >
      <MiniMap />
      <Controls />
    </ReactFlow>
  );
};

export default Flow;
