import React, { useEffect, useState } from 'react'

const SignTest = () => {

    useEffect(() => {
        import('./sign.js')
            .then(module => {
                const signElement = document.getElementById('sign-container');
                if (signElement) {
                    signElement.innerHTML = module.text;
                }
            })
            .catch(error => console.error('Error loading HTML:', error));
    }, []);

    return (
        <div id="sign-container"></div>
    )
}

export default SignTest
