import React, { useState } from 'react'
import styles from './CustomDatePicker.module.css'
import {
	getNumberOfDaysInMonth,
	getSortedDays,
	range,
} from '../utils/functions'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { monthNames } from '../../../components/constants/datepicker'

const CustomDatePicker = ({ selectedDate, setSelectedDate }) => {
	const [currentMonth, setCurrentMonth] = useState(new Date().getMonth())
	const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
	const [currentDay, setCurrentDay] = useState(
		new Date(
			new Date().getFullYear(),
			new Date().getMonth(),
			new Date().getDate()
		).getTime()
	)

	const nextMonth = () => {
		if (currentMonth < 11) {
			setCurrentMonth((prev) => prev + 1)
		} else {
			setCurrentMonth(0)
			setCurrentYear((prev) => prev + 1)
		}
	}
	const prevMonth = () => {
		if (currentMonth > 0) {
			setCurrentMonth((prev) => prev - 1)
		} else {
			setCurrentMonth(11)
			setCurrentYear((prev) => prev - 1)
		}
	}

	const handleSelection = (event) => {
		if (event.target.id === 'day') {
			setSelectedDate(
				new Date(
					currentYear,
					currentMonth,
					event.target.getAttribute('data-day')
				)
			)
		}
	}

	return (
		<div className={styles.pickerWrapper}>
			<div className={styles.pickerHeader}>
				<div onClick={prevMonth} className={styles.chevronIconContainer}>
					<FaChevronLeft color={`var(--text-color)`} />
				</div>
				<p>{`${monthNames[currentMonth]} ${currentYear}`}</p>
				<div onClick={nextMonth} className={styles.chevronIconContainer}>
					<FaChevronRight color={`var(--text-color)`} />
				</div>
			</div>
			<div className={styles.pickerBody}>
				<div style={{ marginBottom: '5px' }} className={styles.sevenColGrid}>
					{getSortedDays(currentYear, currentMonth).map((day, index) => (
						<p key={index}>{day}</p>
					))}
				</div>
				<div onClick={handleSelection} className={styles.sevenColGrid}>
					{range(1, getNumberOfDaysInMonth(currentYear, currentMonth) + 1).map(
						(day, index) => (
							<div key={index + day} className={styles.dayCardContainer}>
								<p
									id='day'
									data-day={day}
									className={
										selectedDate?.getTime() ===
										new Date(currentYear, currentMonth, day).getTime()
											? styles.dayCardSelected
											: currentDay ===
												  new Date(currentYear, currentMonth, day).getTime()
												? styles.actualDay
												: styles.dayCard
									}
								>
									{day}
								</p>
							</div>
						)
					)}
				</div>
			</div>
		</div>
	)
}

export default CustomDatePicker
