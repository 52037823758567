import React from "react";

import styles from '../../index.module.css'

const Archives = ({ blogarc }) => {
  return (
    <>
      <div className={`${styles.inner}`} >
        <ul>
          {blogarc.map((data) => (
            <li key={data.id}>
              <button href="#">
                <span className={`${styles.cate}`} >{data.date}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Archives;
