import apiBackend from '@src/apiBackend.js'
import { createAsyncThunk } from '@reduxjs/toolkit'


export const fetchsContact = createAsyncThunk(
	'contact/fetchsContact',
	async ({options}, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')

			const response = await apiBackend.get('/domain', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: options,
			})

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Ya existe el police'
			}
		}
	}
)





export const denyContact = createAsyncThunk(
	'contact/denyContact',
	async (domain, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/contact/deny',
				{
					domain,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Error deny domain'
			}
		}
	}
)



export const verifyContact = createAsyncThunk(
	'contact/verifyContact',
	async ({ domain, txt }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/contact/verify',
				{
					domain,
					txt,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			if (!response.data.isverify) {
				return response.data
			}

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Error al verificar dominio'
			}
		}
	}
)



export const addContact = createAsyncThunk(
	'contact/addContact',
	async (contact, { dispatch }) => {
		try {

			console.log('contact', contact)
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/contact',
				{
					contact,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Error al agregar contacto'
			}
		}
	}
)
