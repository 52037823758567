import React, { useState, useEffect, useRef } from "react";
import Sidebar from "./Sidebar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"



import home from '@src/locales/es/home1'

import UtilizeBody from "./UtilizeBody";

import styles from '../index.module.css'


import {
  generateUtilize,
  addUtilize,
  updateUtilize,
  deleteUtilize,
  fetchUtilize,
  addMenu,
  updateMenu,
  deleteMenu,
  fetchMenu
} from '@app/actions/utilize'

import { verify } from '@app/actions/iam'

const Utilize = () => {
  const { t } = useTranslation()
  const { lng, tagUtilize } = useParams()

  const navigate = useNavigate()



  const dispatch = useDispatch()

  const [refs, setRefs] = useState(null)
  const [menu, setMenu] = useState([])
  const [utilize, setUtilize] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isAdmin2, setIsAdmin2] = useState(false)


  useEffect(() => {
    const fetchItem = async () => {
      const resp = await dispatch(fetchMenu({
        lng: lng
      }))

      if (resp && resp.payload) {
        setMenu(resp.payload.menu)
        if (resp.payload.menu.length > 0) {


          if (tagUtilize) {
            handleOpenMenu(tagUtilize)
          } else {
            handleOpenMenu(resp.payload.menu[0].ref)
          }
        }
      }
    }

    fetchItem()
  }, [])



  const token = localStorage.getItem('token')

  useEffect(() => {
    const fetchAuth = async () => {
      const resp = await dispatch(verify({}))
      const authEmail = ['info@aythen.com', 'carles.19944@gmail.com']
      if (resp && resp.payload && resp.payload.user && authEmail.includes(resp.payload.user?.user)) {
        setIsAdmin(true)
        setIsAdmin2(true)
      }
    }


    if (token) {
      fetchAuth()
    }
  }, [token])


  const [loadContent, setLoadContent] = useState(null);



  const handleOpenMenu = async (ref) => {
    setRefs(ref)
    navigate(`/es/utilize/${ref}`)

    console.log('eee', ref)

    document.body.style.overflow = 'hidden'
    document.body.scrollTop = 220; // Para navegadores antiguos
    document.documentElement.scrollTop = 220; // Para navegadores modernos
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(function(){
      document.body.style.overflow = 'auto'
    }, 500)

    const resp = await dispatch(fetchUtilize({
      lng: lng,
      ref: ref
    }))


    if (resp && resp.payload) {
      setUtilize(resp.payload.contents)
    }

    setLoadContent(new Date().toISOString())
  }


  const handleAddMenu = () => {
    let updateMenu = [...menu, {
      id: uuidv4(),
      title: 'hello world',
      ref: 'hello'
    }]

    setMenu(updateMenu)
  }






  const [isAtTop, setIsAtTop] = useState(false);
  
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      setIsAtTop(scrollTop === 0);
    };
  
    const handleWheel = (event) => {
      if (isAtTop && event.deltaY < 0) {
        handleMenu('up')
      }
  
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
  
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        handleMenu('down')
      }
    };
  useEffect(() => {

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('wheel', handleWheel,  { passive: false });

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('wheel', handleWheel);
    };
  }, [isAtTop, refs]);


  const isBlocked = useRef(false);
  const timeoutRef = useRef(null);


  const handleMenu = (type) => {
    if (isAdmin || isBlocked.current) return;

    let ref = null;
    const index = menu.findIndex((x) => x.ref === refs);
    if (index === -1) return;

    if (type === 'up') {
      if (index === 0) return; 
      ref = menu[index - 1].ref; 
    } else if (type === 'down') {
      if (index === menu.length - 1) return; 
      ref = menu[index + 1].ref; 
    }

    isBlocked.current = true;
    timeoutRef.current = setTimeout(() => {
      isBlocked.current = false; 
      if (ref) {
        handleOpenMenu(ref); 
      }
    }, 400);
  };




  return (
    <>
      <div className={`${styles['rbt-utilize-area']} ${styles['rainbow-section-gap-big']}`} >
        <div className={styles["container-lg"]} >
          <div className={`${styles["utilize-inner"]}`} >
            <div className={`${styles.row} ${styles['g-5']}`} >
              <div className={`${styles["col-md-3"]}`}  >
                <div className={`${styles["rbt-default-sidebar"]} ${styles["sticky-top"]} ${styles["rbt-shadow-box"]}`} >
                  <Sidebar
                    sidebar={home.utilize.sidebar}
                    menu={menu}
                    setMenu={setMenu}
                    setUtilize={setUtilize}
                    addMenu={handleAddMenu}
                    openMenu={handleOpenMenu}
                    isAdmin={isAdmin}
                    setIsAdmin={setIsAdmin}
                    isAdmin2={isAdmin2}
                  />
                </div>
              </div>
              <div className={`${styles["col-md-9"]} ${styles["inner-content"]} ${styles["pl--0"]}`} >
                <UtilizeBody
                  refs={refs}
                  utilize={utilize}
                  setUtilize={setUtilize}
                  isAdmin={isAdmin}
                  isAdmin2={isAdmin2}
                  setIsAdmin2={setIsAdmin2}
                  loadContent={loadContent}
                  setLoadContent={setLoadContent}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Utilize;
