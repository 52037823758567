const bank = {
    t1: `Revenu Total`,
    t2: `Résultat Total`,
    t3: `Gérez vos accès`,
    t4: `Rechercher une facture`,
    t5: `Rechercher une date`,
    t6: `Ma Carte`,
    t7: `Solde de la carte`,
    t8: `État`,
    t9: `Actif`,
    t10: `Administrer`,
    t11: `Transférer`,
    t12: `Partagez et faites adhérer tous vos contacts pour obtenir votre récompense`,
    t13: `Sécurité`,
    t14: `Carte active`,
    t15: `Accès sécurisé`,
    t16: `Changer`,
    t17: `Informations`,
    t18: `Localité`,
    t19: `Adresse`,
    t20: `Portefeuille`,

    cardlist: {
        "t0": "Cartes",
        "t1": "Ajouter",
        "t2": "Ajoutez votre carte de paiement de manière sécurisée",
        "t3": "Une fois que vous avez ajouté votre carte, vous pourrez gérer vos paiements et vos abonnements actifs.",
        "t4": "Ajouter une carte",
        "t5": "Entrez les détails de votre carte de crédit pour effectuer des paiements de manière sécurisée.",
        "t6": "Voir plus",
        "t7": "Nom",
        "t8": "Numéro de carte",
        "t9": "CVC",
        "t10": "EXP",
        "t11": "Accepter la carte",
        "t12": "Annuler",
        "t13": "Demander une nouvelle carte",
        "t14": "Vous recevrez une nouvelle carte",
        "t15": "Envoyé depuis Aythen",
        "t16": "Espagne",
        "t17": "10903",
        "t18": "Santa Perpetua de Mogoda",
        "t19": "C'est l'adresse où vous recevrez",
        "t20": "Cela aura un coût de 10* Cela aura un coût de Cela aura un coût de Cela aura un coût de",
        "t21": "Demander une carte"
    }
}

export default bank