const support = {
    t1: `Hilfe von Aythen Lite`,
    t2: `Hilfen und Anleitungen`,
    t33: `Offene Tickets`,
    t3: `Service-Level-Agreement (SLA) zur Verfügbarkeit der Software-Plattform`,
    t4: `Kontaktieren Sie uns`,
    t5: `Erstellen Sie ein Support-Ticket`,
    t6: `Erhalten Sie direkte Hilfe von unserem Team.`,
    t7: `Inzidenzen verwalten`,
    t8: `Überprüfen Sie Ihre Lizenz sowie Ihre offenen und geschlossenen Tickets.`,
    t9: `Möchten Sie mehr wissen?`,
    t10: `Kennen Sie Aythen PRO?`,
    t11: `Klicken Sie hier, um mehr zu erfahren und sich auf unsere Warteliste anzumelden`,
    t12: `Überwachung und Bericht`,
    t13: `Senden Sie uns Ihre Empfehlungen, um zu erfahren, wie Sie Ihre Daten besser bearbeiten möchten.`,
    topics: {
      1: {
        title: "Cookies und Datenschutz",
        data: {
          1: {
            title: `Sind meine Daten sicher?`,
            text: `Wir arbeiten mit der Zertifizierung ISO 27001. Dies ist ein international anerkannter Standard für das Management der Informationssicherheit. Diese Zertifizierung bedeutet, dass ein Informationssicherheits-Managementsystem (ISMS) implementiert wurde, das die erforderlichen Kontrollen und Prozesse umfasst, um Daten effektiv zu schützen.`,
          },
          2: {
            title: `Benutzererfahrung`,
            text: `Indem wir einen cloudbasierten Dienstleister mit Zertifizierungen wie ISO 27001 verwenden, der in der Europäischen Union tätig ist, bieten wir eine höhere Sicherheit und Datenschutz für personenbezogene Daten. Dies hilft bei der Einhaltung der Sicherheitsanforderungen der DSGVO und minimiert die Risiken, die mit der Verarbeitung personenbezogener Daten verbunden sind.`,
          },
        },
      },
      2: {
        title: `Vektorbasierte Datenbanken`,
        data: {
          1: {
            title: `Was sind sie?`,
            text: `Sie stellen eine bedeutende Innovation im Bereich der Datenverwaltung und -verarbeitung dar. Sie werden in verschiedenen Anwendungen eingesetzt, einschließlich der Datenwissenschaft, des maschinellen Lernens und der künstlichen Intelligenz. <br /><br />
                        Im Gegensatz zu traditionellen Datenbanken, die Informationen in Form von Tabellen mit Zeilen und Spalten speichern, verwenden vektorbasierte Datenbanken vektorielle Datenstrukturen, um Informationen effizienter zu speichern und zu verarbeiten. 
                        Sie organisieren Daten in mehrdimensionalen Vektoren. Jedes Element des Vektors repräsentiert eine Einheit von Informationen, und Operationen werden effizient durchgeführt, indem Flüsse zwischen Informationsmengen erstellt werden, anstatt einzelne Zeilen und Spalten zu verarbeiten.`,
          },
          2: {
            title: `Wofür sind sie gut?`,
            text: `Diese Struktur ermöglicht einen schnellen Zugriff auf große Datenmengen und erleichtert komplexe mathematische und logische Operationen gleichzeitig. 
                        Das bedeutet, dass die Latenz des Datenzugriffs eliminiert wird, ohne dass alle Informationen verarbeitet werden müssen, nur die von Interesse, die segmentiert und in Clustern nach Dimensionen und Akteuren organisiert werden kann.
                        <br /><br />
                        - Jede Dimension repräsentiert eine Koordinate in einem mehrdimensionalen Raum, in dem die Daten organisiert sind. Dies bedeutet, dass ein vektorbasiertes Datenbanksystem mehrere Dimensionen haben kann, was eine vollständigere und detailliertere Darstellung der Informationen ermöglicht.
                        <br /><br />
                        - Die Akteure sind Entitäten, die Operationen auf den vektorbasierten Daten durchführen. Diese Akteure können Programme, Prozesse oder Abfragen sein, die mit der Datenbank interagieren, um Informationen abzurufen, zu verarbeiten oder zu manipulieren. Sie können auf verschiedene Dimensionen der Daten zugreifen und komplexe Operationen durchführen, je nach Anforderungen, um grafisch darzustellen oder auf Fragen zu antworten.`,
          },
        },
      },
      3: {
        title: `Serverlose Back-Office-Infrastruktur`,
        data: {
          1: {
            title: `Wie profitiere ich davon?`,
            text: `In einer serverlosen Umgebung ist es nicht erforderlich, physische oder virtuelle Server zu verwalten. Die Infrastruktur wird so verwaltet, dass die Anwendung automatisch skaliert, wenn die Nachfrage steigt. 
                        <br /><br />
                        Dies bedeutet, dass keine Kapazitätsplanung für Spitzenzeiten erforderlich ist und die Plattform automatisch die erforderlichen Ressourcen skaliert, um die Arbeitslast zu bewältigen.`,
          },
          2: {
            title: `Operative Flexibilität`,
            text: `Daher werden nur die tatsächlich genutzten Ressourcen bezahlt, was im Vergleich zu traditionellen Servern, bei denen die Kapazität unabhängig von der tatsächlichen Nutzung bezahlt wird, Kosten einsparen kann.`,
          },
        },
      },
      4: {
        title: `On-Demand-Dienstleistungen`,
        data: {
          1: {
            title: `Kann ich es an meine Unternehmen anpassen?`,
            text: `Sie können Ihre Bedürfnisse basierend auf Ihrer eigenen Unternehmensstruktur erstellen.`,
          },
          2: {
            title: `Unternehmenslogo`,
            text: `Es ist wichtig, Ihr Unternehmenslogo im Registrierungsprozess hinzuzufügen, um die Markenkonstanz in allen Interaktionen mit Kunden, Lieferanten und anderen Akteuren aufrechtzuerhalten.`,
          },
          3: {
            title: `Benutzer und Rollen`,
            text: `Sie können verschiedene Benutzertypen und Rollen im IAM hinzufügen und verwalten, ob es sich um interne Mitarbeiter oder externe Kunden, Lieferanten und andere Kooperationspartner handelt. Jeder Benutzertyp kann unterschiedliche Zugriffsebenen und Berechtigungen haben, um die Sicherheit und den Datenschutz der Daten zu gewährleisten.`,
          },
          4: {
            title: `Unternehmensfarben`,
            text: `Es wäre wichtig, das CRM mit den Unternehmensfarben anzupassen, um die visuelle Identität der Marke zu stärken. 
                        Dies würde die Möglichkeit einschließen, die dominierenden Farben in der CRM-Oberfläche auszuwählen, einschließlich Hintergründen, Schaltflächen und Navigationsleisten.`,
          },
          5: {
            title: `Verknüpfung von Bankkonten`,
            text: `Für eine effiziente Finanzverwaltung können Sie serverlose Aythen-Karten mit IAM-Zugängen und -Rollen verknüpfen, damit jedes Unternehmensteam Transaktionen, Zahlungen und andere Finanzoperationen direkt von der Plattform aus verfolgen kann, was den Verwaltungsprozess vereinfacht.`,
          },
        },
      },
      5: {
        title: `Maßgeschneiderte Interaktionen`,
        data: {
          1: {
            title: `Tiefgehende Analyse`,
            text: `Die vektorbasierte Darstellung ermöglicht eine tiefgehende Analyse der Daten.`,
          },
          2: {
            title: `Strategische Erkenntnisse`,
            text: `Wir bieten wertvolle Erkenntnisse für strategische Entscheidungen durch vektorbasierte Dimensionen.`,
          },
        },
      },
      6: {
        title: `Wesentliche Tools`,
        data: {
          1: {
            title: `Eigene Dokumente`,
            text: `Sie können neue Textdokumente, Tabellenkalkulationen, Präsentationen, Formulare, Zeichnungen und mehr in Echtzeit hochladen und bearbeiten. Es unterstützt alle Arten von Dateiformaten, einschließlich Textdokumenten, Tabellenkalkulationen, Präsentationen, Formularen, Zeichnungen und mehr.`,
          },
          2: {
            title: `Speicherung`,
            text: `Dokumente können Speicherplatz in Ihrem Konto belegen. Sie können sie also einfrieren, um darauf zuzugreifen, wenn Sie es benötigen, oder löschen, wenn sie nicht mehr von Interesse sind.`,
          },
          2: {
            title: `Teilen und Zusammenarbeiten`,
            text: `Sie können Ihre Dokumente mit anderen Benutzern teilen und in Echtzeit zusammenarbeiten. Die Mitarbeiter können Änderungen am Dokument in Echtzeit sehen und Kommentare hinzufügen, um Ideen zu diskutieren.`,
          },
          3: {
            title: `Elektronische Rechnung`,
            text: `Sparen Sie Zeit und Ressourcen. Automatisieren Sie den Prozess der Rechnungserstellung, -versendung und -annahme, was die administrative Last und die damit verbundenen Kosten reduziert. Reduzieren Sie Fehler und verbessern Sie die Genauigkeit bei der Registrierung und Verfolgung von Finanztransaktionen. Alles, was erforderlich ist, um den gesetzlichen Anforderungen für Selbstständige und Unternehmen zu entsprechen.`,
          },
          3: {
            title: `Verträge`,
            text: `Zentralisieren Sie Informationen zu Kunden und Geschäftsvereinbarungen, was die Verwaltung und Verfolgung von Verträgen über ihren Lebenszyklus hinweg erleichtert.`,
          },
          4: {
            title: `Dashboards Ihrer Lieblingsgrafiken`,
            text: `Erstellen Sie Ihre Dashboards nach Ihren Interaktionen, um wichtige Daten und Metriken klar und übersichtlich darzustellen, was die Identifizierung von Trends, Mustern und Chancen erleichtert, um informierte Entscheidungen treffen zu können. `,
          },
          5: {
            title: `Dynamische Tabellen`,
            text: `Dynamische Tabellen bieten eine flexible Möglichkeit, Ihre Daten nach Ihren Projekten zusammenzufassen. Listen Sie die Karten der beteiligten Personen auf, den Status, Daten von verschiedenen Perspektiven und Detailstufen. Sie können Daten interaktiv filtern, gruppieren und zusammenfassen, um detaillierte Informationen zu erhalten und informierte Entscheidungen zu treffen.`,
          },
        },
      },
    },
  }

export default support