const form = {
    editor: {
        step0: {
            text0: `Zal jouw project worden gemonetiseerd?`,
            t0: `Ja`,
            t1: `Nee`,
        },
        step1: {
            title: `Beschrijving van jouw project`,
            text: `Beschrijf het project om de eerste stappen voor te bereiden`,
            t0: `Naam`,
            t1: `Beschrijving`,
        },
        step2: {
            title: `Hoeveel gebruikers verwacht je te hebben?`,
            text: `Afhankelijk van het aantal gebruikers, bieden we kritischer maatregelen aan`,
            t0: `0-1.000`,
            t1: `1.000-10k`,
            t2: `10k-100k`,
            t3: `100k-250k`,
            t4: `250k-500k`,
            t5: `+500.001`
        },
        step3: {
            title: `Selecteer het type en de stijl`,
            text0: `Het type dient om een van onze categorieën te kiezen`,
            text1: `De stijl bepaalt hoe het eruit zal zien`,
            t0: `Hallo wereld`,
            t1: `Minimalistisch`,
            t2: `Modern`,
            t3: `Klassiek`,
            x0: `Complex`,
            x1: `Eenvoudig`,
        },
        step4: {
            title: `Wil je de geavanceerde of basis editor modus?`,
            t0: `Geavanceerd`,
            t1: `Basis`,
        },
    }, 
    flags: {
        en: `Engels`,
        es: `Spaans`,
        fr: `Frans`,
        de: `Duits`,
        it: `Italiaans`,
        ru: `Russisch`,
        pt: `Portugees`,
        nl: `Nederlands`,
        sv: `Zweeds`,
    },
    title: {
        text0: 'Stap',
        text1: 'van',
        step0: 'Taal',
        step1: 'Profiel',
        step2: 'Doel',
        step3: 'Context',
        step4: 'Bonus',
        step5: 'Bedrijf',
        step6: 'Grootte',
        step7: 'Type software',
        step8: 'Aanneming',
    },
    footer: {
        text: 'Abonneer je op onze e-mails',
        button0: 'Vorige',
        button1: 'Volgende',
    },
    step0: {
        t0: `Kies de taal`,
        t1: `Door te kiezen, zal de website veranderen`,
        t2: `We integreren momenteel meer talen`,
    },
    step1: {
        t0: `Wat is je naam?`,
        t1: `Leuk je te ontmoeten`,
        t2: `Voornaam`,
        t3: `Achternaam`,
        t4: `Telefoon`,
        t5: `E-mail`,
    },
    step2: {
        t0: `Voor wie heb je het nodig?`,
        t1: `Selecteer de optie van jouw doel`,
        t2: `Mijn bedrijf`,
        t3: `Voor mezelf`,
        t4: `Klanten`,
    },
    step3: {
        t0: `Hoe zou je jezelf omschrijven? `,
        t1: `Startup`,
        t2: `Kleine bedrijf`,
        t3: `Middelgroot bedrijf`,
        t4: `Groot bedrijf`,
    },
    step4:{
        t0: `Ben je student?`,
        t1: `Ja`,
        t2: `Nee`,
    },
    step5:{
        t0: `Wat beschrijft jou het beste?`,
        t1: `Freelancer`,
        t2: `Bureau`,
    },
    step6:{
        t0: `Wat is de grootte van jouw werkploeg?`,
    },
    step7:{
        t0: `Wat voor soort site probeer je vandaag te bouwen?`,
        t1: `Bedrijfswebsite`,
        t2: `E-commerce winkel`,
        t3: `Portfolio`,
        t4: `Blog`,
        t5: `Analyse`,
        t6: `Andere`,
    },
    step8:{
        t0: `Ben je geïnteresseerd in het gebruik van Aythen?`,
        t1: `Ja`,
        t2: `Nee`,
        t3: `Ik weet het nog niet zeker`,
    },
}

export default form
