const vector = {
    t1: `Créez un nouveau vecteur pour votre projet`,
    t2: `Importez, enregistrez, travaillez, analysez et tirez des conclusions de n'importe quelle structure de données. 
Stockez et manipulez les informations avec une totale liberté à travers des espaces multidimensionnels. `,
    t3: `Nouveau vecteur`,
    t4: `Vecteurs`,
    t5: `Vecteur`,
    t6: `Dimension`,
    t7: `Taille`,
    t9: `Créé le`,
    t10: `Matrice`,
    t11: `Les bases de données vectorielles permettent de créer des expériences uniques impulsées par la recherche vectorielle.  
Elles accélèrent le développement d'applications d'IA.`,
    t12: `Nouvelle Matrice`,
    t13: `Documentation de démarrage rapide`,
    node: {
        data: {
            title: `Ajoutez des données à votre rapport`,
            text: `Une fois chargés, vos données apparaîtront dans le`,
            label: `Champs`,
            excel: `Importer des données depuis Excel`,
            sql: `Importer des données depuis SQL`,
            blank: `Coller des données en blanc`,
            export: `Exporter des données d'exemple`,
            info: `Obtenir des données d'une autre source`,
        }
    },
    dashboard: {
        t1: `N'oubliez pas, vous pourrez toujours modifier et compiler les graphiques que vous souhaitez mettre en avant 
comme favoris à partir des informations que vous avez décidé d'analyser dans les vecteurs.`,
        t2: `En savoir plus`,
    }
}

export default vector;