import React from 'react'

import DragNavImage from './assets/drag-nav.png'
import Card1Image from './assets/img-1.png'
import Card2Image from './assets/img-2.png'
import Card3Image from './assets/img-3.png'
import Card4Image from './assets/img-4.png'
import Card5Image from './assets/img-5.png'
import Card6Image from './assets/img-6.png'

const AcademySection = () => {
  return (
    <div className="relative w-full sm:h-full lg:pb-[100px]" id="academy">
      <div className="container mx-auto">
        <section className="home-section-1 ">
          <div className="flex">
            <div className="relative lg:pt-[10px] z-[0]">
              <div className="mb-5 flex items-center absolute left-0 top-[10px] w-[300px]">
                <div className="bg-[#0000E6] z-[-1]  w-[40px] h-[40px] flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M22.6343 19.185C22.8249 19.0366 22.9768 18.8443 23.0771 18.6246C23.1775 18.4048 23.2233 18.1641 23.2106 17.9229C23.1979 17.6816 23.1272 17.447 23.0044 17.239C22.8816 17.0309 22.7104 16.8556 22.5053 16.728L22.4993 7.995L20.9993 9V16.725C20.7965 16.8526 20.6272 17.0268 20.5056 17.2332C20.3839 17.4395 20.3134 17.672 20.2999 17.9111C20.2864 18.1503 20.3303 18.3892 20.4279 18.608C20.5256 18.8267 20.6742 19.0189 20.8613 19.1685L20.2943 19.9215C19.79 20.563 19.5107 21.3526 19.4993 22.1685V24.0015H20.7218C21.0154 24.0015 21.3007 23.9035 21.5325 23.7231C21.7642 23.5427 21.9292 23.2902 22.0013 23.0055L22.4978 21.0015V24.0015H23.9978V22.1865C23.9863 21.3664 23.7048 20.5729 23.1968 19.929L22.6343 19.185ZM11.9993 0L-0.000732422 6L11.9993 13.5L23.9993 6L11.9993 0Z"
                      fill="white"
                    />
                    <path
                      d="M11.9993 14.9989L4.49927 10.0039V12.5689C4.49927 13.9339 8.90927 17.9989 11.9993 17.9989C15.0893 17.9989 19.4993 13.9339 19.4993 12.5689V10.0039L11.9993 14.9989Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="ml-4">
                  <h4 className="text-[#0000E6] font-extrabold uppercase text-[20px]">
                    ACADEMY
                  </h4>
                </div>
              </div>
              <div className="academy-left-border mt-[70px]"></div>
            </div>
            <div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="col-span-2 relative">
                  <div className="w-full md:mt-[100px]">
                    <div className="lg:pl-10 lg:flex justify-between w-full">
                      <p className="text-[20px] text-[#554D56] lg:text-[48px] leading-tight drop-shadow-xl text-left pl-5 lg:pl-0 ">
                        <span className="text-[#0000E6]">
                          Accede a contenido académico.
                        </span>
                        Empieza o mejora tus capacidades digitales a través de
                        nuestras formaciones en línea y acreditaciones que
                        verificarán las habilidades como profesional.
                      </p>
                      <img
                        src={DragNavImage}
                        className=" w-[60px] lg:w-[200px] h-[auto] lg:relative absolute top-[-100px] right-0"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-[30px] ">
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3">
                  <div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mb-8 lg:mb-0 ">
                      <p className="mt-0 lg:text-[13px] uppercase">
                        PRIMER BENEFICIO
                      </p>
                      <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                        Educación Personalizada
                      </h5>
                      <div className="lg:w-[40%]">
                        <p className="lg:text-[15px] mt-5">
                          Una academia de programación permite adaptar el
                          contenido de aprendizaje según el nivel de habilidad y
                          las necesidades individuales de los usuarios. Los
                          cursos pueden ser estructurados para satisfacer los
                          requisitos específicos de cada usuario.
                        </p>
                        <img
                          src={Card1Image}
                          className="lg:absolute bottom-[0px] right-[0px] lg:w-[60%] z-20"
                        />
                      </div>
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mt-5 mb-8 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          Cuarto Beneficio
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Mejora de la Productividad
                        </h5>
                      </div>
                      <div className="lg:w-[50%]">
                        <p className="lg:text-[15px]">
                          Al conocer a fondo la plataforma, los usuarios pueden trabajar de manera más eficiente y productiva. Pueden aprovechar funciones avanzadas y atajos que aceleren su trabajo diario.
                        </p>
                      </div>
                      <img
                        src={Card4Image}
                        className="lg:absolute bottom-[-50px] right-[-50px] lg:w-[80%] z-20"
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mb-8 lg:mb-0">
                      <p className="mt-0 lg:text-[13px] uppercase">
                        SEGUNDO BENEFICIO
                      </p>
                      <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                        Dominio de la Plataforma
                      </h5>
                      <div className="lg:w-[90%]">
                        <p className="lg:text-[15px]">
                          Los usuarios pueden aprender a utilizar todas las funciones y características de la plataforma de software de manera efectiva. Esto ayuda a maximizar el rendimiento y la utilidad de la plataforma.
                        </p>
                      </div>
                      <img
                        src={Card2Image}
                        className="lg:absolute bottom-[0px] right-[20%] z-20 lg:w-[50%]"
                      />
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mt-5 mb-8 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          Quinto Beneficio
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Fomento de la Innovación
                        </h5>
                      </div>
                      <div className="lg:w-[70%]">
                        <p className="lg:text-[15px]">
                          La educación en programación puede inspirar a los usuarios a explorar soluciones creativas y personalizadas utilizando la plataforma de software. Esto fomenta la innovación y la adaptación a diferentes casos de uso.
                        </p>
                      </div>
                      <img
                        src={Card5Image}
                        className="z-20 lg:absolute bottom-[-50px] right-[-100px] lg:w-[80%]"
                      />
                    </div>
                  </div>
                  <div className="relative md:col-span-2 lg:col-span-1 md:grid grid-cols-2 lg:block gap-4">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 relative h-[auto] lg:h-[450px] mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          SEXTO beneficio
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Promoción de la comunidad
                        </h5>
                        <p className="lg:text-[15px] mt-3">
                          Los usuarios que participan en una academia de programación pueden conectarse con otros usuarios y compartir experiencias y conocimientos. Esto fomenta una comunidad de aprendizaje y colaboración.
                        </p>
                      </div>
                      <img
                        src={Card3Image}
                        className="lg:absolute bottom-[-30px] right-[0px] z-20 lg:w-[50%]"
                      />
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative  mt-5 mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          SEXTO beneficio
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Promoción de la comunidad
                        </h5>
                        <p className="lg:text-[15px] mt-3">
                          Los usuarios que participan en una academia de
                          programación pueden conectarse con otros usuarios y
                          compartir experiencias y conocimientos. Esto fomenta
                          una comunidad de aprendizaje y colaboración.
                        </p>
                      </div>
                      <img
                        src={Card6Image}
                        className="z-20 lg:absolute bottom-[0px] right-[0] lg:w-[60%]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default AcademySection
