import React, { useState, useEffect } from "react";

import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import styles from './UtilizeBody.module.css'

import {
  updateMenu,
  pdfUtilize,
  downloadUtilize,
  uploadUtilize,
  deleteAllUtilize
} from '@app/actions/utilize'

const Sidebar = ({ menu, setMenu, setUtilize, addMenu, openMenu, sidebar, isAdmin, setIsAdmin, isAdmin2 }) => {

  const { lng, tagUtilize } = useParams()
  const dispatch = useDispatch()

  const [settingsMenu, setSettingsMenu] = useState([])

  const newData = (index) => ({
    id: Date.now(),
    title: `Menu Item ${index + 1}`,
    ref: `menu-${index + 1}`,
  });


  const handleAddMenu = (index) => {
    if (menu.length === 0) {
      const firstItem = { order: 0, ...newData(index) };
      setMenu([firstItem]);
      return;
    }

    const newMenu = [...menu];
    const newOrder = newMenu[index].order + 1;

    const newItem = { order: newOrder, ...newData(index) };

    newMenu.splice(index + 1, 0, newItem);

    const updatedMenu = newMenu.map((item, i) => ({
      ...item,
      order: i + 1,
    }));

    setMenu(updatedMenu);
  };

  const handleSettingsMenu = (index) => {
    setSettingsMenu((prev) => ({
      ...prev,
      [index]: prev[index] ? !prev[index] : true
    }))
  }


  const handleDeleteMenu = async (index) => {
    const updatedMenu = menu
      .filter((_, i) => i !== index)
      .map((item, i) => ({
        ...item,
        order: i + 1,
      }));

    setMenu(updatedMenu);

  };


  const handlePrevMenu = (index) => {
    if (index <= 0) return;

    const newMenu = [...menu];

    [newMenu[index - 1], newMenu[index]] = [newMenu[index], newMenu[index - 1]];

    const updatedMenu = newMenu.map((item, i) => ({
      ...item,
      order: i + 1,
    }));

    setMenu(updatedMenu);
  };

  const handleNextMenu = (index) => {
    if (index >= menu.length - 1) return;

    const newMenu = [...menu];

    [newMenu[index + 1], newMenu[index]] = [newMenu[index], newMenu[index + 1]];

    const updatedMenu = newMenu.map((item, i) => ({
      ...item,
      order: i + 1,
    }));

    setMenu(updatedMenu);
  };


  const handleTitleChange = (index, newTitle) => {
    const updatedMenu = [...menu];

    updatedMenu[index] = {
      ...updatedMenu[index],
      title: newTitle,
    };

    setMenu(updatedMenu);
  };


  const handleRefChange = (index, newRef) => {
    const updatedMenu = [...menu];

    updatedMenu[index] = {
      ...updatedMenu[index],
      ref: newRef,
    };

    setMenu(updatedMenu);
  };


  const [loadMenu, setLoadMenu] = useState(false)
  const [changeMenu, setChangeMenu] = useState(false)

  useEffect(() => {
    if (menu.length > 0) {
      if (!loadMenu) {
        setLoadMenu(true)
      } else {
        setChangeMenu(true)
      }
    }
  }, [menu])



  const handleSaveMenu = async () => {
    setChangeMenu(false)
    const resp = await dispatch(updateMenu({
      id: menu._id,
      lng: lng,
      data: menu
    }))

  }

  const handleOpenMenu = (ref) => {
    openMenu(ref)
  }


  const handlePDF = () => {
    dispatch(pdfUtilize({lng}))
  }

  const handleDownload = () => {
    dispatch(downloadUtilize({}))
  }



  const handleView = () => {
    setIsAdmin(!isAdmin)
  }

  const handleDelete = () => {
    dispatch(deleteAllUtilize({
      lng: lng
    }))

    setMenu([])
    setUtilize([])
  }


  // -------------------------------------------------------------------------

  // Función para manejar el cambio de archivos
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const validFiles = [];
    let validationError = '';

    selectedFiles.forEach((file) => {
      if (file.type !== 'application/json') {
        validationError = 'Solo se permiten archivos .json';
      } else if (!file.name.startsWith('backup-')) {
        validationError = 'Los archivos deben comenzar con "backup-"';
      } else {
        validFiles.push(file);
      }
    });

    if (validationError) {
      setError(validationError);
    } else {
      setError('');
    }
  };

  const handleUpload = async () => {
    const newInput = document.createElement('input');
    newInput.type = 'file';
    newInput.accept = '.json';
    newInput.multiple = true;
    newInput.onchange = handleFileChange;
    newInput.style.display = 'none';

    newInput.click();

    newInput.onchange = async (event) => {
      const selectedFiles = Array.from(event.target.files);
      const validFiles = [];
      let validationError = '';

      selectedFiles.forEach((file) => {
        if (file.type !== 'application/json') {
          validationError = 'Solo se permiten archivos .json';
        } else if (!file.name.startsWith('backup-')) {
          validationError = 'Los archivos deben comenzar con "backup-"';
        } else {
          validFiles.push(file);
        }
      });

      if (validationError) {
        console.error('error', validationError)
      } else {


        try {

          const response = await dispatch(uploadUtilize({
            files: validFiles,
            lng: lng
          }))

          if (response.payload) {
            setUtilize(response.payload.utilize)
            setMenu(response.payload.menu)
          }
        } catch (err) {
          console.error(err);
          setError('Error al subir los archivos.');
        }
      }
    };
  };



  return (
    <>
      <div className={`${styles.inner}`} >
        <div className={`${styles["content-item-content"]}`} >
          <div className={`${styles['rbt-default-sidebar-wrapper']}`} >
            <nav className={`${styles["mainmenu-nav"]}`} >
              {changeMenu && (
                <button
                  className={styles['btn-new']}
                  onClick={() => handleSaveMenu()}
                >
                  Guardar
                </button>
              )}
              {isAdmin2 && (
                <div>
                  <div className={styles['btn-group']}>
                    <button className={styles['btn']} onClick={() => handlePDF()}>
                      <svg fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 17v-5h1.5a1.5 1.5 0 1 1 0 3H5m12 2v-5h2m-2 3h2M5 10V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1v6M5 19v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-1M10 3v4a1 1 0 0 1-1 1H5m6 4v5h1.375A1.627 1.627 0 0 0 14 15.375v-1.75A1.627 1.627 0 0 0 12.375 12H11Z" />
                      </svg>
                    </button>
                    <button className={styles['btn']} onClick={() => handleDownload()}>
                      <svg fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01" />
                      </svg>
                    </button>
                    <button className={styles['btn']} onClick={() => handleUpload()}>
                      <svg fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v9m-5 0H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2M8 9l4-5 4 5m1 8h.01" />
                      </svg>
                    </button>
                    <button className={styles['btn']} onClick={() => handleView()}>
                      {isAdmin ? (
                        <svg fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" strokeWidth="2" d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z" />
                          <path stroke="currentColor" strokeWidth="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        </svg>
                      ) : (
                        <svg fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        </svg>
                      )}
                    </button>
                    <button className={styles["btn"]} onClick={() => handleDelete()}>
                      <svg fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18 17.94 6M18 18 6.06 6" />
                      </svg>
                    </button>
                  </div>
                </div>
              )}
              {(isAdmin && menu.length == 0) ? (
                <div
                  className={styles['btn-new']}
                  onClick={() => handleAddMenu(0)}
                >
                  Nuevo Menu
                </div>
              ) : (
                <ul className={styles.list}>
                  {menu.map((item, index) => (
                    <li key={index} className={`${styles['list-item']} ${tagUtilize == item.ref ? styles.active : ''}`}>
                      <button onClick={() => handleOpenMenu(item.ref)}>
                        {item.title}
                      </button>
                      {isAdmin && settingsMenu[index] && (
                        <div className={styles['form-group']}>
                          <input
                            type="text"
                            spellCheck="false"
                            value={item.title}
                            onChange={(e) => handleTitleChange(index, e.target.value)}
                          />
                          <input
                            type="text"
                            spellCheck="false"
                            value={item.ref}
                            onChange={(e) => handleRefChange(index, e.target.value)}
                          />
                        </div>
                      )}
                      {isAdmin && (
                        <div className={styles["btn-group"]}>
                          <button className={styles["btn"]} onClick={() => handleAddMenu(index)}>
                            <svg fill="none" viewBox="0 0 24 24">
                              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14m-7 7V5" />
                            </svg>
                          </button>
                          <button className={styles["btn"]} onClick={() => handleSettingsMenu(index)}>
                            {settingsMenu[index] ? (
                              <svg fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeWidth="2" d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z" />
                                <path stroke="currentColor" strokeWidth="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                              </svg>
                            ) : (
                              <svg fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                              </svg>
                            )}

                          </button>
                          <button className={styles["btn"]} onClick={() => handleDeleteMenu(index)}>
                            <svg fill="none" viewBox="0 0 24 24">
                              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18 17.94 6M18 18 6.06 6" />
                            </svg>
                          </button>
                          <button className={styles["btn"]} onClick={() => handlePrevMenu(index)}>
                            <svg fill="none" viewBox="0 0 24 24">
                              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m16 14-4-4-4 4" />
                            </svg>
                          </button>
                          <button className={styles["btn"]} onClick={() => handleNextMenu(index)}>
                            <svg fill="none" viewBox="0 0 24 24">
                              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m8 10 4 4 4-4" />
                            </svg>
                          </button>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              )}

            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
