import React, { useState, useEffect, useRef } from "react"

import { useDispatch } from "react-redux"

import styles from "./sign.module.css"

import SignatureCanvas from "react-signature-canvas"

import { Document, Page, pdfjs  } from "react-pdf"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;



import { fetchContract } from "../views/app/v1-1/actions/iam"

const Sign = () => {
  const dispatch = useDispatch()

  const [isAlert, setIsAlert] = useState(false)
  const [isPromo, setIsPromo] = useState(false)
  const [value, setValue] = useState(0)

  const [state, setState] = useState({
    company: "Jokan.s",

    security: false,
  })

  const [isError, setIsError] = useState(false)
  const [url, setUrl] = useState("https://pdfobject.com/pdf/sample.pdf")
  const [email, setEmail] = useState("")

  useEffect(() => {
    const getUrlParam = (name) => {
      const searchParams = new URLSearchParams(location.search)
      return searchParams.get(name)
    }

    const fetchData = async (id) => {
      const resp = await dispatch(fetchContract({ id }))
      if(resp.payload){
        if (resp.payload.key_s3) {
        }
        if(resp.payload.users.length > 0){
          setListSignatures(resp.payload.users)
        }
      }
    }

    const idParam = getUrlParam("id")
    const emailParam = getUrlParam("email")

    if (idParam && emailParam) {
      fetchData(idParam)
      setEmail(emailParam)
    } else {
      setIsError(true)
      alert('El parámetro "key" no está presente en la URL.')
    }
  }, [location.search])

  const handleChange = (e) => {
    const { value, name } = e.target

    setState((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const [numPages, setNumPages] = useState(null)
  const [scale, setScale] = useState(0.65)


  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const zoomIn = () => {
    setScale(scale + 0.1)
  }

  const zoomOut = () => {
    setScale(scale > 0.2 ? scale - 0.1 : scale)
  }

  const resetZoom = () => {
    setScale(1.0)
  }


  const [listSignatures, setListSignatures] = useState([])
  const [signatures, setSignatures] = useState([]) 
  const signatureRefs = useRef([])

  const addSignatureField = () => {
  };


 const handleCanvasChange = (index) => {
    return () => {
      if (signatureRefs.current[index] && signatureRefs.current[index].current) {
        const signatureData = signatureRefs.current[index].current.toDataURL(); 

        setSignatures((prevSignatures) =>
          prevSignatures.map((signature, idx) =>
            idx === index ? { ...signature, data: signatureData } : signature
          )
        );
      }
    };
  };

  const handleDetectSignatures = () => {
  }



  

  return (
    <div className={styles.container}>
      <div className={styles.checkout}>
        <div className={styles.left}>
          <div>
            <div className={styles.header}>
              <div className={styles.icon}>
                {/* prettier-ignore */}
                <svg fill="none" viewBox="0 0 24 24" > <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M6 12c.263 0 .524-.06.767-.175a2 2 0 0 0 .65-.491c.186-.21.333-.46.433-.734.1-.274.15-.568.15-.864a2.4 2.4 0 0 0 .586 1.591c.375.422.884.659 1.414.659.53 0 1.04-.237 1.414-.659A2.4 2.4 0 0 0 12 9.736a2.4 2.4 0 0 0 .586 1.591c.375.422.884.659 1.414.659.53 0 1.04-.237 1.414-.659A2.4 2.4 0 0 0 16 9.736c0 .295.052.588.152.861s.248.521.434.73a2 2 0 0 0 .649.488 1.809 1.809 0 0 0 1.53 0 2.03 2.03 0 0 0 .65-.488c.185-.209.332-.457.433-.73.1-.273.152-.566.152-.861 0-.974-1.108-3.85-1.618-5.121A.983.983 0 0 0 17.466 4H6.456a.986.986 0 0 0-.93.645C5.045 5.962 4 8.905 4 9.736c.023.59.241 1.148.611 1.567.37.418.865.667 1.389.697Zm0 0c.328 0 .651-.091.94-.266A2.1 2.1 0 0 0 7.66 11h.681a2.1 2.1 0 0 0 .718.734c.29.175.613.266.942.266.328 0 .651-.091.94-.266.29-.174.537-.427.719-.734h.681a2.1 2.1 0 0 0 .719.734c.289.175.612.266.94.266.329 0 .652-.091.942-.266.29-.174.536-.427.718-.734h.681c.183.307.43.56.719.734.29.174.613.266.941.266a1.819 1.819 0 0 0 1.06-.351M6 12a1.766 1.766 0 0 1-1.163-.476M5 12v7a1 1 0 0 0 1 1h2v-5h3v5h7a1 1 0 0 0 1-1v-7m-5 3v2h2v-2h-2Z" /> </svg>
              </div>
              <span>{state.company}</span>

              <div className={styles.controls}>
                <div className={styles.zoomLevel}>
                  {(scale * 100).toFixed(0)}%
                </div>
                <button className={styles.controlsButton} onClick={zoomIn}>
                  Zoom In
                </button>
                <button className={styles.controlsButton} onClick={zoomOut}>
                  Zoom Out
                </button>
                <button className={styles.controlsButton} onClick={resetZoom}>
                  Reset Zoom
                </button>
              </div>
            </div>
            <div className={styles.pdfViewerContainer}>
              {isError && (
                <div className={styles.alert}>
                  <b>Titulo</b>
                  <p>Lorem ipsum dolor et</p>
                </div>
              )}
              <div className={styles.pdfDocument}>
                {url && (
                  <Document file={url} onLoadSuccess={onDocumentLoadSuccess}   onLoadError={console.error}>
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        scale={scale}
                      />
                    ))}
                  </Document>
                )}
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.f0}>
              <span>Powered by</span>
              <b>Aythen</b>
            </div>
            <div className={styles.f1}>
              <a>Terms</a>
              <a>Privacy</a>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.buttons}>
            <button>
              Firm with
              {/* prettier-ignore */}
              <svg fill="currentColor" viewBox="0 0 24 24" > <path fillRule="evenodd" d="M12 2a1 1 0 0 1 .932.638l7 18a1 1 0 0 1-1.326 1.281L13 19.517V13a1 1 0 1 0-2 0v6.517l-5.606 2.402a1 1 0 0 1-1.326-1.281l7-18A1 1 0 0 1 12 2Z" clipRule="evenodd" /> </svg>
            </button>
          </div>
          <div className={styles.line}>
            <span>Or sign here</span>
          </div>

          <div className={styles.listSignatures}>
            {listSignatures.map((sig, index) => (
              <div key={index} className={`${styles.signatureContainer} ${email == sig.user ? styles.active : ''}`}>
                <b>
                  {sig.user}
                </b>
                <SignatureCanvas
                  ref={signatureRefs.current[index]}
                  canvasProps={{
                    width: 400,
                    height: 100,
                    className: "sigCanvas",
                    style: { pointerEvents: !(email == sig.user) ? 'none' : 'auto' }
                  }}
                  onEnd={!(email == sig.user) ? () => {} : handleCanvasChange(index)}
                />
              </div>
            ))}
          </div>

          <div className={styles.alert}>
            <div
              className={`${styles.checkbox} ${state.security ? styles.active : ""}`}
              onClick={() =>
                handleChange({
                  target: { name: "security", value: !state.security },
                })
              }
            >
              {/* prettier-ignore */}
              <svg fill="none" viewBox="0 0 24 24" > <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 11.917 9.724 16.5 19 7.5" /> </svg>
            </div>
            <div className={styles.text}>
              <b>Securely save my information for 1-click</b>
              <p>
                Pay faster on {state.company} and everywhere Link is accepted.
              </p>
            </div>
          </div>
          {isPromo && (
            <div className={styles.promo}>
              <label>Promotional opiton code</label>
              <div className={styles.input}>
                <input type="text" spellCheck="false" placeholder="" />
              </div>
            </div>
          )}
          <div className={styles.pay}>
            <button>Sign here now</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sign
