const drive = {
    t1: `Zoek documentatie`,
    t2: `Nieuw maken`,
    t3: `Nieuwe map`,
    t4: `Nieuwe lanceDB`,
    t5: `Bestand uploaden`,
    t6: `Map uploaden`,
    t7: `Gebruiken`,
    t8: `Mijn documenten`,
    t9: `Mijn documenten`,
    t10: `Binnenkort`,
    t11: `Prioritaire`,
    t12: `Gedeeld`,
    t13: `Recente`,
    t14: `Uitgelicht`,
    t15: `Prullenbak`,
    t16: `Prullenbak`,
    t17: `Een moment...`,
    t18: `Annuleren`,
    t19: `Maken`,

    x1: `Naam`,
    x2: `Laatste wijziging`,
    x3: `Selecteer alles`,
    x4: `Deselecteer alles`,
    x5: `Recente bestanden`,
    x6: `LanceDB`,
    x7: `Bekijk DB`,
    x8: `Bekijk drive`,
    x9: `Project`,
    x10: `Naam`,
    x11: `Naam`,
    x12: `Grootte`,
    x13: `Laatste wijziging`,
    x14: `Geselecteerde items`,
    x15: `Gedeeld met`,
}

export default drive