import { useTranslation } from "react-i18next";

import logo from "@home/assets/images/logo/logo.png";
import logoDark from "@home/assets/images/light/logo/logo-dark.png";
import userImg from "@home/assets/images/team/team-02sm.jpg";
import brandImg from "@home/assets/images/brand/brand-t.png";
import google from "@home/assets/images/sign-up/google.png";
import facebook from "@home/assets/images/sign-up/facebook.png";
import DarkSwitch from "@home/Header/dark-switch";
import { useAppContext } from "@home/context/Context";

import styles from '../index.module.css'

const Signin = () => {
  const { t } = useTranslation()

  const { isLightTheme, toggleTheme } = useAppContext();
  return (
    <>
      <DarkSwitch isLight={isLightTheme} switchTheme={toggleTheme} />
      <main className={`${styles["page-wrapper"]}`} >
        <div className={`${styles["signup-area"]}`} >
          <div className={`${styles.wrapper}`} >
            <div className={`${styles.row}`} >
              <div className={`${styles["col-lg-6"]} ${styles["bg-color-blackest"]} ${styles["left-wrapper"]}`} >
                <div className={`${styles["sign-up-box"]}`} >
                  <div className={styles["signup-box-top"]} >
                    <button href="/">
                      <img
                        src={isLightTheme ? logo : logoDark}
                        width={193}
                        height={50}
                        alt="sign-up logo"
                      />
                    </button>
                  </div>
                  <div className={styles["signup-box-bottom"]} >
                    <div className={`${styles["signup-box-content"]}`} >
                      <div className={`${styles["social-btn-grp"]}`} >
                        <a className={`${styles["btn-default"]} ${styles["btn-border"]}`} href="#">
                          <span className={`${styles["icon-left"]}`} >
                            <img
                              src={google}
                              width={18}
                              height={18}
                              alt="Google Icon"
                            />
                          </span>
                          {t('home1.login.text1')}

                        </a>
                        <a className={`${styles["btn-default"]} ${styles["btn-border"]}`} href="#">
                          <span className={styles["icon-left"]} >
                            <img
                              src={facebook}
                              width={18}
                              height={18}
                              alt="Facebook Icon"
                            />
                          </span>
                          {t('home1.login.text2')}


                        </a>
                      </div>
                      <div className={`${styles["text-social-area"]}`} >
                        <hr />
                        <span>
                          {t('home1.login.text3')}

                        </span>
                        <hr />
                      </div>
                      <form>
                        <div className={`${styles["input-section"]} ${styles["mail-section"]}`} >
                          <div className={`${styles.icon}`} >
                            <i className={`${styles['fa-sharp']} ${styles['fa-regular']} ${styles['fa-envelope']}`} ></i>
                          </div>
                          <input
                            type="email"
                            placeholder={t('home1.login.text4')}
                          />
                        </div>
                        <div className={`${styles["input-section"]} ${styles["password-section"]}`} >
                          <div className={styles.icon} >
                            <i className={`${styles['fa-sharp']} ${styles['fa-regular']} ${styles['fa-lock']}`} ></i>
                          </div>
                          <input type="password" placeholder={t('home1.login.text5')} />
                        </div>
                        <div className={styles["forget-text"]} >
                          <a className={`${styles["btn-read-more"]}`} href="#">
                            <span>{t('home1.login.text6')}</span>
                          </a>
                        </div>
                        <button type="submit" className={styles["btn-default"]} >
                          {t('home1.login.text7')}
                        </button>
                      </form>
                    </div>
                    <div className={`${styles["signup-box-footer"]}`} >
                      <div className={styles["bottom-text"]} >
                        {t('home1.login.text8')}
                        {" "}
                        <a className={`${styles.btnReadMore} ${styles.ml5}`} href="/signup">
                          <span>{t('home1.login.text9')}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles["col-lg-6"]} ${styles["right-wrapper"]}`} >
                <div className={`${styles["client-feedback-area"]}`} >
                  <div className={`${styles["single-feedback"]}`} >
                    <div className={`${styles.inner}`} >
                      <div className={`${styles["meta-img-section"]}`} >
                        <a className={styles.image} href="#">
                          <img
                            src={userImg}
                            width={93}
                            height={93}
                            alt="User img"
                          />
                        </a>
                      </div>
                      <div className={styles.rating} >
                        <a href="#rating">
                          <svg fill="currentColor" viewBox="0 0 24 24">
                            <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                          </svg>

                        </a>
                        <a href="#rating">

                          <svg fill="currentColor" viewBox="0 0 24 24">
                            <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                          </svg>
                        </a>
                        <a href="#rating">

                          <svg fill="currentColor" viewBox="0 0 24 24">
                            <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                          </svg>
                        </a>
                        <a href="#rating">

                          <svg fill="currentColor" viewBox="0 0 24 24">
                            <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                          </svg>
                        </a>
                        <a href="#rating">
                          <svg fill="currentColor" viewBox="0 0 24 24">
                            <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                          </svg>

                        </a>
                      </div>
                      <div className={`${styles.content}`} >
                        <p className={styles.description} >
                          {t('home1.login.text10')}
                        </p>
                        <div className={`${styles["bottom-content"]}`} >
                          <div className={`${styles["meta-info-section"]}`} >
                            <h4 className={`${styles["title-text"]} ${styles["mb--0"]}`} >Guy Hawkins</h4>
                            <p className={`${styles.desc} ${styles["mb--20"]}`} >Nursing Assistant</p>
                            <div className={styles["desc-img"]} >
                              <img
                                src={brandImg}
                                width={83}
                                height={23}
                                alt="Brand img"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className={styles["close-button"]} href="/">
            <i className={`${styles["fa-sharp"]} ${styles["fa-regular"]} ${styles["fa-x"]}`} ></i>
          </button>
        </div>
      </main>
    </>
  );
};

export default Signin;
