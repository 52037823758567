import React, { useState } from 'react'
import styles from './calculator.module.css'

import Modal from './modal'


const Page = ({ isShow, setIsShow }) => {

    const [isExpand, setIsExpand] = useState(false)
    const [isHourly, setIsHourly] = useState(false)

    return (
        <Modal
            isShow={isShow}
            style={`${styles["app-calculator"]} 
                ${isExpand ? styles.viewExpand : ''}
                ${isHourly ? styles.viewHourly : ''}
                `
            }
        >
            <Calculator
                isExpand={isExpand}
                isHourly={isHourly}
                setIsExpand={setIsExpand}
                setIsHourly={setIsHourly}
            />
        </Modal>
    )
}


export default Page



const Calculator = ({ isExpand, isHourly, setIsExpand, setIsHourly }) => {


    return (
        <div className={styles.container}>
            <div className={`${styles.hourly} ${isHourly ? styles.active : ''}`}>
                <label>Edit</label>
                <b>
                    Today
                </b>
                <ul>
                    <li>
                        <span>log10((96x28):2)</span>
                        <span className={styles.result}>3.12893433</span>
                    </li>
                </ul>
                <b>
                    Previous 30 days
                </b>
                <ul>
                    <li>
                        <span>log10((96x28):2)</span>
                        <span className={styles.result}>3.12893433</span>
                    </li>
                </ul>
            </div>
            <div className={styles.principal}>
                <div className={`${styles.calculatorExpand} ${isExpand ? styles.active : ''}`}>
                    <CalculatorExpand />
                </div>
                <div className={styles.calculatorApp}>
                    <div className={styles.header}>
                        <button onClick={() => setIsExpand(!isExpand)}>
                            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.143 4H4.857A.857.857 0 0 0 4 4.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 10 9.143V4.857A.857.857 0 0 0 9.143 4Zm10 0h-4.286a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 20 9.143V4.857A.857.857 0 0 0 19.143 4Zm-10 10H4.857a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286a.857.857 0 0 0 .857-.857v-4.286A.857.857 0 0 0 9.143 14Zm10 0h-4.286a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286a.857.857 0 0 0 .857-.857v-4.286a.857.857 0 0 0-.857-.857Z" />
                            </svg>

                        </button>
                        <button onClick={() => setIsHourly(!isHourly)}>
                            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 13v-2a1 1 0 0 0-1-1h-.757l-.707-1.707.535-.536a1 1 0 0 0 0-1.414l-1.414-1.414a1 1 0 0 0-1.414 0l-.536.535L14 4.757V4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v.757l-1.707.707-.536-.535a1 1 0 0 0-1.414 0L4.929 6.343a1 1 0 0 0 0 1.414l.536.536L4.757 10H4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h.757l.707 1.707-.535.536a1 1 0 0 0 0 1.414l1.414 1.414a1 1 0 0 0 1.414 0l.536-.535 1.707.707V20a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-.757l1.707-.708.536.536a1 1 0 0 0 1.414 0l1.414-1.414a1 1 0 0 0 0-1.414l-.535-.536.707-1.707H20a1 1 0 0 0 1-1Z" />
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                            </svg>

                        </button>
                    </div>
                    <div className={styles.bottom}>
                        <b>
                            log10((96x28):2)
                        </b>
                        <span>
                            3.12893433
                        </span>
                    </div>
                    <CalculatorApp />
                </div>
            </div>

        </div>
    )
}

// export default Calculator


const CalculatorExpand = () => {
    return (
        <div className={styles.calculator}>
            <ul>
                <li>
                    <button>
                        (
                    </button>
                    <button>
                        )
                    </button>
                    <button>
                        mc
                    </button>
                    <button>
                        m+
                    </button>
                    <button>
                        m-
                    </button>
                    <button>
                        mr
                    </button>
                </li>
                <li>
                    <button>
                        2nd
                    </button>
                    <button>
                        x²
                    </button>
                    <button>
                        x³
                    </button>
                    <button>
                        ex
                    </button>
                    <button>
                        10x
                    </button>
                </li>
                <li>
                    <button>
                        1/x
                    </button>
                    <button>
                        vx
                    </button>
                    <button>
                        3√x
                    </button>
                    <button>
                        y√x
                    </button>
                    <button>
                        ln
                    </button>
                    <button>
                        log10
                    </button>
                </li>
                <li>
                    <button>
                        x!
                    </button>
                    <button>
                        sin
                    </button>
                    <button>
                        cos
                    </button>
                    <button>
                        tan
                    </button>
                    <button>
                        e
                    </button>
                    <button>
                        EE
                    </button>
                </li>
                <li>
                    <button>
                        <svg fill="#000000" width="800px" height="800px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
                            <path d="M176,60H80a4.0002,4.0002,0,0,0-4,4v48a4.0002,4.0002,0,0,0,4,4h96a4.0002,4.0002,0,0,0,4-4V64A4.0002,4.0002,0,0,0,176,60Zm-4,48H84V68h88Zm28-79.99219H56a12.01375,12.01375,0,0,0-12,12v176a12.01375,12.01375,0,0,0,12,12H200a12.01375,12.01375,0,0,0,12-12v-176A12.01375,12.01375,0,0,0,200,28.00781Zm4,188a4.00458,4.00458,0,0,1-4,4H56a4.00458,4.00458,0,0,1-4-4v-176a4.00458,4.00458,0,0,1,4-4H200a4.00458,4.00458,0,0,1,4,4ZM136,148a8,8,0,1,1-8-8A8.00917,8.00917,0,0,1,136,148Zm0,40a8,8,0,1,1-8-8A8.00917,8.00917,0,0,1,136,188ZM96,148a8,8,0,1,1-8-8A8.00917,8.00917,0,0,1,96,148Zm0,40a8,8,0,1,1-8-8A8.00917,8.00917,0,0,1,96,188Zm80-40a8,8,0,1,1-8-8A8.00917,8.00917,0,0,1,176,148Zm0,40a8,8,0,1,1-8-8A8.00917,8.00917,0,0,1,176,188Z" />
                        </svg>
                    </button>
                    <button>
                        sinh
                    </button>
                    <button>
                        cosh
                    </button>
                    <button>
                        tanh
                    </button>
                    <button>
                        π
                    </button>
                    <button>
                        Rad
                    </button>
                </li>
            </ul>
        </div>
    )
}

const CalculatorApp = () => {
    return (
        <div className={styles.calculator}>
            <ul>
                <li>
                    <button className={styles.second}>
                        AC
                    </button>
                    <button className={styles.second}>
                        +/-
                    </button>
                    <button className={styles.second}>
                        %
                    </button>
                    <button className={styles.action}>
                        :
                    </button>
                </li>
                <li>
                    <button className={styles.calc}>
                        7
                    </button>
                    <button className={styles.calc}>
                        8
                    </button>
                    <button className={styles.calc}>
                        9
                    </button>
                    <button className={styles.action}>
                        x
                    </button>
                </li>
                <li>
                    <button className={styles.calc}>
                        4
                    </button>
                    <button className={styles.calc}>
                        5
                    </button>
                    <button className={styles.calc}>
                        6
                    </button>
                    <button className={styles.action}>
                        -
                    </button>
                </li>
                <li>
                    <button className={styles.calc}>
                        1
                    </button>
                    <button className={styles.calc}>
                        2
                    </button>
                    <button className={styles.calc}>
                        3
                    </button>
                    <button className={styles.action}>
                        +
                    </button>
                </li>
                <li>
                    <button className={styles.calc}>
                        Rand
                    </button>
                    <button className={styles.calc}>
                        0
                    </button>
                    <button className={styles.calc} >
                        .
                    </button>
                    <button className={styles.action}>
                        =
                    </button>
                </li>
            </ul>
        </div>
    )
}