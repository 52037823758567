import React from 'react'

import SectionImage from './assets/Chart.png'
import DragNavImage from './assets/drag-nav.png'

import Card1Image from './assets/img-1.png'
import Card2Image from './assets/img-2.png'
import Card3Image from './assets/img-3.png'
import Card4Image from './assets/img-4.png'
import Card6Image from './assets/img-6.png'

const PivotSection = () => {
  return (
    <div className="relative w-full sm:h-full lg:pb-[180px]" id="pivot">
      <div className="container mx-auto">
        <section className="home-section-1 ">
          <div className="flex">
            <div className="relative lg:pt-[10px] z-[0]">
              <div className="mb-5 flex items-center ml-[-8px] absolute left-0 top-[10px] w-[300px]">
                <div className="bg-[#7EE787] z-[-1]  w-[40px] h-[40px] flex items-center justify-center">
                  <svg
                    style={{zoom: 0.8}}
                    xmlns="http://www.w3.org/2000/svg"
                    width="60"
                    height="55"
                    fill="none"
                  >
                    <path
                      d="M16.9993 18.75C16.9993 17.783 17.7823 17 18.7493 17H35.2493C36.2153 17 36.9993 17.783 36.9993 18.75V25C36.9993 25.1989 36.9202 25.3897 36.7796 25.5303C36.6389 25.671 36.4482 25.75 36.2493 25.75C36.0504 25.75 35.8596 25.671 35.7189 25.5303C35.5783 25.3897 35.4993 25.1989 35.4993 25V23.75H23.7493V35.5H24.9993C25.1982 35.5 25.3889 35.579 25.5296 35.7197C25.6702 35.8603 25.7493 36.0511 25.7493 36.25C25.7493 36.4489 25.6702 36.6397 25.5296 36.7803C25.3889 36.921 25.1982 37 24.9993 37H18.7493C18.2851 37 17.84 36.8156 17.5118 36.4874C17.1836 36.1592 16.9993 35.7141 16.9993 35.25V18.75ZM23.7493 18.5V22.25H35.4993V18.75C35.4993 18.6837 35.4729 18.6201 35.426 18.5732C35.3792 18.5263 35.3156 18.5 35.2493 18.5H23.7493ZM22.2493 35.5V23.75H18.4993V35.25C18.4993 35.388 18.6113 35.5 18.7493 35.5H22.2493ZM18.4993 22.25H22.2493V18.5H18.7493C18.683 18.5 18.6194 18.5263 18.5725 18.5732C18.5256 18.6201 18.4993 18.6837 18.4993 18.75V22.25Z"
                      fill="white"
                    />
                    <path
                      d="M36.249 27.3125C36.4479 27.3125 36.6387 27.3915 36.7794 27.5322C36.92 27.6728 36.999 27.8636 36.999 28.0625V30.6885C36.999 31.6831 36.6039 32.6369 35.9007 33.3402C35.1974 34.0434 34.2436 34.4385 33.249 34.4385H29.747L31.779 36.4705C31.9196 36.6111 31.9986 36.8017 31.9986 37.0005C31.9986 37.1993 31.9196 37.3899 31.779 37.5305C31.6385 37.6711 31.4478 37.75 31.249 37.75C31.0502 37.75 30.8596 37.6711 30.719 37.5305L27.469 34.2805C27.3994 34.2109 27.3441 34.1283 27.3064 34.0374C27.2687 33.9464 27.2493 33.8489 27.2493 33.7505C27.2493 33.6521 27.2687 33.5546 27.3064 33.4636C27.3441 33.3727 27.3994 33.2901 27.469 33.2205L30.719 29.9705C30.7886 29.9009 30.8713 29.8457 30.9622 29.808C31.0531 29.7704 31.1506 29.751 31.249 29.751C31.3475 29.751 31.4449 29.7704 31.5359 29.808C31.6268 29.8457 31.7094 29.9009 31.779 29.9705C31.8486 30.0401 31.9038 30.1227 31.9415 30.2137C31.9792 30.3046 31.9986 30.4021 31.9986 30.5005C31.9986 30.5989 31.9792 30.6964 31.9415 30.7873C31.9038 30.8783 31.8486 30.9609 31.779 31.0305L29.872 32.9385H33.249C33.8458 32.9385 34.4181 32.7014 34.84 32.2795C35.262 31.8575 35.499 31.2852 35.499 30.6885V28.0625C35.499 27.8636 35.578 27.6728 35.7187 27.5322C35.8593 27.3915 36.0501 27.3125 36.249 27.3125Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="ml-4">
                  <h4 className="text-[#7EE787] font-extrabold uppercase text-[20px]">
                    PIVOT
                  </h4>
                </div>
              </div>
              <div className="pivot-left-border mt-[70px]"></div>
            </div>
            <div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="col-span-2 relative">
                  <div className="w-full lg:flex md:mt-[100px]">
                    <div className="lg:pl-10">
                      <p className="text-[20px] text-[#554D56] lg:text-[48px] leading-tight drop-shadow-xl text-left pl-5 lg:pl-0">
                        <span className="text-[#7EE787]">
                          Relaciona y analiza tus datos.
                        </span>
                        Usa tus datos como información y permite representarlos
                        a través de diferentes modelos gráficos generando un
                        análisis de la evolución en las métricas de tu proyecto.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full relative">
                  <img
                    src={SectionImage}
                    className="lg:absolute lg:top-[-270px] right-0 "
                  />
                  <img
                    src={DragNavImage}
                    className="mx-auto me-0 lg:w-[200px] w-[100px] absolute lg:relative top-[0px] lg:top-[200px] right-0 z-10"
                  />
                </div>
              </div>

              <div className="lg:mt-[50px]">
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3">
                  <div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mb-8 lg:mb-0">
                      <p className="mt-0 lg:text-[13px] uppercase">
                        PRIMER BENEFICIO
                      </p>
                      <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                        Transformación de Datos Complejos
                      </h5>
                      <div className="lg:w-[90%]">
                        <p className=" lg:text-[15px] mt-5">
                          Un pivot permite transformar datos complejos y no
                          estructurados en formatos más manejables y
                          significativos. Puedes realizar filtrados,
                          agrupaciones y cálculos en base a criterios
                          específicos para obtener información procesable.
                        </p>
                        <img
                          src={Card1Image}
                          className="lg:absolute bottom-[0px] left-[0px] w-[200px] lg:w-[50%] z-20"
                        />
                      </div>
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mt-5 mb-8 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          Cuarto Beneficio
                        </p>
                        <h5 className="lg:text-[30px] text-gris-500 my-2 font-bold lg:font-light">
                          Comparación de Datos
                        </h5>
                      </div>
                      <div className="lg:w-[90%]">
                        <p className="lg:text-[15px]">
                          Los pivotes permiten comparar datos entre diferentes
                          períodos, segmentos o categorías. Esto facilita la
                          identificación de cambios y diferencias significativas
                          que pueden influir en la estrategia del proyecto.
                        </p>
                      </div>
                      <img
                        src={Card4Image}
                        className="lg:absolute bottom-[-100px] w-[200px] lg:w-[100%] z-20"
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mb-8 lg:mb-0 lg:mt-20">
                      <p className="mt-0 lg:text-[13px] uppercase">
                        Quinto beneficio
                      </p>
                      <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                        Ahorro de Tiempo
                      </h5>
                      <div className="lg:w-[60%]">
                        <p className="lg:text-[15px]">
                          Al automatizar la organización y el análisis de datos,
                          un pivot ahorra tiempo en comparación con el análisis
                          manual. Los informes y gráficos generados permiten una
                          evaluación rápida y eficiente de las métricas del
                          proyecto.
                        </p>
                      </div>
                      <img
                        src={Card2Image}
                        className="lg:absolute lg:bottom-[-70px]  lg:right-[-130px] z-20 w-[200px] lg:w-[80%]"
                      />
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] relative mt-5 mb-8 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          SEGUNDO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] text-gris-500 my-2 font-bold lg:font-light">
                          Análisis Visual
                        </h5>
                      </div>
                      <div className="lg:w-[80%]">
                        <p className="lg:text-[15px]">
                          Al representar los datos a través de modelos gráficos
                          como gráficos de barras, líneas, tortas y otros, se
                          facilita el análisis visual de la evolución de las
                          métricas. Los gráficos proporcionan una comprensión
                          rápida y clara de las tendencias y patrones en los
                          datos.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="relative md:col-span-2 lg:col-span-1 grid md:grid-cols-2 lg:grid-cols-1 gap-4">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 relative h-[auto] lg:h-[450px] mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          Tercer beneficio
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Detección de Tendencias
                        </h5>
                        <p className="lg:text-[15px] mt-3">
                          Utilizar un pivot permite identificar tendencias y
                          patrones emergentes en los datos a lo largo del
                          tiempo. Esto puede ayudar a anticipar cambios y tomar
                          medidas preventivas o aprovechar oportunidades.
                        </p>
                      </div>
                      <img
                        src={Card3Image}
                        className="lg:absolute bottom-[-30px] right-[0px] z-20 lg:bottom-[-100px] lg:right-[-30px] lg:w-[100%]"
                      />
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative  mt-5 mb-8 lg:mb-0">
                      <div className="">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          SEXTO beneficio
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light  lg:w-[70%]">
                          Toma de Decisiones Informadas
                        </h5>
                        <p className="lg:text-[15px] mt-3 lg:w-[55%]">
                          La representación visual de los datos en un pivot
                          proporciona información clara y accesible para la toma
                          de decisiones informadas. Los resultados analíticos
                          ayudan a definir estrategias y ajustes para el
                          proyecto.
                        </p>
                      </div>
                      <img
                        src={Card6Image}
                        className="z-20 lg:absolute lg:right-[-20px] lg:bottom-[-100px] w-[200px] lg:w-[60%]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default PivotSection
