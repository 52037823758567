import { createSlice } from '@reduxjs/toolkit'
import { addGptTokens, getUserGptTokens } from '../actions/tokens'

const tokensSlices = createSlice({
	name: 'gptTokens',
	initialState: {
		gptTokens: [],
	},
	reducers: {
		setUserGptTokens: (state, action) => {
			state.gptTokens = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getUserGptTokens.pending, (state) => {
				state.loading = true
			})
			.addCase(getUserGptTokens.fulfilled, (state, action) => {
				state.loading = false
				state.gptTokens = action.payload
			})
			.addCase(getUserGptTokens.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(addGptTokens.pending, (state) => {
				state.loading = true
			})
			.addCase(addGptTokens.fulfilled, (state, action) => {
				state.loading = false
				state.gptTokens = action.payload
			})
			.addCase(addGptTokens.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
	},
})

export const { setUserGptTokens } = tokensSlices.actions

export default tokensSlices.reducer
