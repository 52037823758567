import React from "react";
import Context from "@home/context/Context";
import { useTranslation } from "react-i18next";


import HeaderTop from "@home/Header/HeaderTop/HeaderTop";
import Header from "@home/Header/Header";
import PopupMobileMenu from "@home/Header/PopUpMobileMenu";
import Footer from "@home/Footers/Footer";
import Copyright from "@home/Footers/Copyright";
import Breadcrumb from "@home/Common/Breadcrumb";
import BackToTop from "../backToTop";
import Contact from "@home/Contact/Contact";
import CtaTwo from "@home/CallToActions/Cta-Two";

import styles from '../index.module.css'

const ContactPage = () => {

  const {t} = useTranslation()

  return (
    <>
      <main className={styles["page-wrapper"]} >
        <Context>
          <HeaderTop />
          <Header
            headerTransparent="header-transparent"
            headerSticky="header-sticky"
            btnClass="rainbow-gradient-btn"
          />
          <PopupMobileMenu />
          <Breadcrumb
            title={t('home1.contact.component2.text1')}
            text={t('home1.contact.component2.text2')}
          />

          <Contact />

          <div className={`${styles["rainbow-cta-area"]} ${styles["rainbow-section-gap"]} ${styles["rainbow-section-gapBottom-big"]}`} >
            <div className={`${styles.container}`} >
              <CtaTwo />
            </div>
          </div>

          <BackToTop />
          <Footer />
          <Copyright />
        </Context>
      </main>
    </>
  );
};

export default ContactPage;
