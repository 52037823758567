import apiBackend from '@src/apiBackend'
import {
	setSelectedChat,
	setSelectedContact,
	updateChat,
	changeActiveComponent,
	setSelectedChatUsername,
	setAllMessages,
	setNewChatSelected,
	setNewChatUsername,
	setJoinedVideoChat,
	setSocketId,
	setSelectedChatAdmin,
	setOnChatSection,
	setUserVideoRef,
	setOutsideChatSection,
	settingPeers,
	setJoinTime,
} from '../slices/chatSlice'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getAllChatsByUserId = createAsyncThunk(
	'chats/getAllChatsByUserId',
	async (userId) => {
		try {
			if (!userId) {
				return []
			}

			const { data } = await apiBackend.get(`/chat/${userId}/all-chats`)

			return data.data
		} catch (error) {
			console.log('Error r!!!!!!!!!!!!!!!!!!!', error)
		}
	}
)

export const getAllUsers = createAsyncThunk(
	'chats/getAllUsers',
	async (user = '') => {
		try {
			const token = localStorage.getItem('token')
			const { data } = await apiBackend.get('/iam/account/all-account', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					query: user,
					order: {
						type: 'ASC',
						param: 'createdat',
					},
				},
			})

			return data
		} catch (error) {
			console.log('Error error', error)
		}
	}
)

export const getAllProjectIds = createAsyncThunk(
	'chats/getAllProjectIds',
	async (chatId) => {
		try {
			const { data } = await apiBackend.get(`/chat/${chatId}/all-users`)
			return data.data
		} catch (error) {
			console.log('Error error', error)
		}
	}
)

export const getAllMessagesByChat = createAsyncThunk(
	'chats/getAllMessagesByChat',
	async (chatId) => {
		try {
			const { data } = await apiBackend.get(`/chat/${chatId}/all-messages`)

			return data.data
		} catch (error) {
			console.log('Error', error)
		}
	}
)

export const setUserSocketId = (socketId) => async (dispatch) => {
	try {
		dispatch(setSocketId(socketId))
	} catch (error) {
		console.log('Error', error)
	}
}
export const setSelectedChatAdminStatus =
	(selectedChat, userId) => async (dispatch) => {
		try {
			const { data } = await apiBackend.get(`/chat/${selectedChat}/all-users`)
			const currentUser = data.data.filter((user) => user.userToolId === userId)
			dispatch(setSelectedChatAdmin(currentUser[0].isAdmin))
		} catch (error) {
			console.log('Error', error)
		}
	}
export const selectChat = (chatId, username) => async (dispatch) => {
	try {
		dispatch(setJoinedVideoChat(null))
		dispatch(setAllMessages([]))
		dispatch(setNewChatSelected(null))
		dispatch(setNewChatUsername(null))
		dispatch(setSelectedChat(chatId))
		dispatch(setSelectedChatUsername(username))
		chatId && dispatch(getAllProjectIds(chatId))
		chatId && dispatch(getAllMessagesByChat(chatId))
	} catch (error) {
		console.log('Error', error)
	}
}
export const selectNewChat = (userId, userName) => async (dispatch) => {
	try {
		dispatch(setNewChatSelected(userId))
		dispatch(setNewChatUsername(userName))
	} catch (error) {
		console.log('Error', error)
	}
}

export const selectContact = (contactId) => async (dispatch) => {
	try {
		dispatch(setSelectedContact(contactId))
	} catch (error) {
		console.log('Error', error)
	}
}
export const joinVideoChat = () => async (dispatch) => {
	try {
		dispatch(setJoinedVideoChat(true))
	} catch (error) {
		console.log('Error', error)
	}
}
export const joinedChatSection = () => async (dispatch) => {
	try {
		dispatch(setOnChatSection())
	} catch (error) {
		console.log('Error', error)
	}
}
export const setHourJoined = (hour) => async (dispatch) => {
	try {
		dispatch(setJoinTime(hour))
	} catch (error) {
		console.log('Error', error)
	}
}
export const leavingChatSection = () => async (dispatch) => {
	try {
		dispatch(setOutsideChatSection())
	} catch (error) {
		console.log('Error', error)
	}
}
export const setPeers = (peers) => async (dispatch) => {
	try {
		dispatch(settingPeers(peers))
	} catch (error) {
		console.log('Error', error)
	}
}
export const leaveVideoChat = () => async (dispatch) => {
	try {
		dispatch(setJoinedVideoChat(null))
	} catch (error) {
		console.log('Error', error)
	}
}

export const getChatByParticipant = createAsyncThunk(
	'chats/getChatByParticipant',
	async (participants) => {
		try {
			const { data } = await apiBackend.get('/chat/users-chat', participants)
			if (data.data._id) {
				return data.data._id
			}
		} catch (error) {
			console.log('Error', error)
		}
	}
)

export const updateLastMessage = (message) => async (dispatch) => {
	try {
		dispatch(updateChat(message))
	} catch (error) {
		console.log('Error', error)
	}
}
export const setUserVideoReference = (ref) => async (dispatch) => {
	try {
		dispatch(setUserVideoRef(ref))
	} catch (error) {
		console.log('Error', error)
	}
}

export const createChat = createAsyncThunk(
	'chats/createChat',
	async (participants) => {
		try {
			const { data } = await apiBackend.post('/chat/new', participants)
			return data.data.chatId
		} catch (error) {
			console.log('Error error', error)
		}
	}
)
export const createChatAndSendMessage =
	(participants, bodyMessage, userName, userId) => async (dispatch) => {
		try {
			const response = await apiBackend.post('/chat/new', participants)
			const id = response.data.data._id
			const messageResponse = await apiBackend.post(
				`/chat/${id}/new-message`,
				bodyMessage
			)
			dispatch(getAllChatsByUserId(userId))
			dispatch(getAllMessagesByChat(id))
			dispatch(selectChat(id, userName))
		} catch (error) {
			console.log('Error creating chat:', error)
		}
	}

export const sendMessage = (chatId, bodyMessage) => async (dispatch) => {
	try {
		const { data } = await apiBackend.post(
			`/chat/${chatId}/new-message`,
			bodyMessage
		)
		dispatch(updateChat(data.data))
	} catch (error) {
		console.log('Error send message', error)
	}
}

export const setMessagesToReaded = (chatId, userId) => async (dispatch) => {
	try {
		const { data } = await apiBackend.post(`/chat/${chatId}/read-message`, {
			userId,
		})

		dispatch(getAllMessagesByChat(chatId))
	} catch (error) {
		console.log('Error data error', error)
	}
}

export const deletechat = (chatId, bodyMessage, userId) => async (dispatch) => {
	try {
		await apiBackend.patch(`/chat/${chatId}/delete-chat`, bodyMessage)
		dispatch(selectChat(null, null))
		dispatch(getAllChatsByUserId(userId))
	} catch (error) {
		console.log('Error data error', error)
	}
}

export const activeComp = (component) => (dispatch) => {
	dispatch(changeActiveComponent(component))
}

export const createGroup =
	(chatName, creatorId, participantIds, meetingStartDate) =>
	async (dispatch) => {
		try {
			const { data } = await apiBackend.post('/chat/new-group', {
				chatName,
				creatorId,
				participantIds,
				meetingStartDate,
			})
			dispatch(getAllChatsByUserId(creatorId))
			dispatch(getAllMessagesByChat(data.data._id))
			dispatch(selectChat(data.data._id, data.data.name))
			return data.data
		} catch (error) {
			console.log('Error data error', error)
		}
	}

export const createGroup2 =
	(chatName, creatorId, participantIds, image) => async (dispatch) => {
		try {
			const formData = new FormData()
			formData.append('chatName', chatName)
			formData.append('creatorId', creatorId)
			formData.append('participantIds', participantIds)
			formData.append('image', image)
			const { data } = await apiBackend.post('/chat/new-group', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			dispatch(getAllChatsByUserId(creatorId))
			dispatch(getAllMessagesByChat(data.data._id))
			dispatch(selectChat(data.data._id, data.data.name))
		} catch (error) {
			console.log('Error ', error)
		}
	}


export const getAllUserInvitations = createAsyncThunk(
	'invitations/getAllUserInvitations',
	async (userId) => {
		try {
			const { data } = await apiBackend.get(`/chat/invitations/${userId}`)
			return data.data
		} catch (error) {
			console.log('Error error', error)
		}
	}
)
export const getAllInvitations = createAsyncThunk(
	'invitations/getAllInvitations',
	async () => {
		try {
			const { data } = await apiBackend.get('/chat/invitations/all')
			return data.data
		} catch (error) {
			console.log('Error error', error)
		}
	}
)
export const deleteInvitationById = createAsyncThunk(
	'invitations/deleteInvitationById',
	async (invitationId) => {
		try {
			const { data } = await apiBackend.delete(
				`/v1/invitations/${invitationId}`
			)
			return data.data
		} catch (error) {
		}
	}
)
export const postInvitation = createAsyncThunk(
	'invitations/postInvitation',
	async (body) => {
		try {
			const { data } = await apiBackend.post('/chat/invitations', body)
			return data.data
		} catch (error) {
		}
	}
)
export const updateUserInvitationState = createAsyncThunk(
	'invitations/updateUserInvitationState',
	async (body) => {
		try {
			const { data } = await apiBackend.put(
				`/chat/invitations/${body.invitationId}/users/${body.userId}`,
				{ newState: body.newState }
			)
			return data.data
		} catch (error) {
			throw new Error(error)
		}
	}
)
