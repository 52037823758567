
import React from 'react'


const Icon = () => {
    return (
        <svg width="147" height="147" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_633_2796" maskUnits="userSpaceOnUse" x="34" y="29" width="78" height="89">
                <path d="M105.691 117.32H40.3088C36.8246 117.32 34 114.496 34 111.011V35.3086C34 31.8244 36.8246 29 40.3088 29H82.75L112 58.2488V111.011C112 114.496 109.175 117.32 105.691 117.32Z" fill="url(#paint0_linear_633_2796)" />
            </mask>
            <g mask="url(#mask0_633_2796)">
                <path d="M105.691 117.32H40.3088C36.8246 117.32 34 114.496 34 111.011V35.3086C34 31.8244 36.8246 29 40.3088 29H82.75L112 58.2488V111.011C112 114.496 109.175 117.32 105.691 117.32Z" fill="url(#paint1_linear_633_2796)" />
                <path d="M41.419 73.8556C41.4083 73.5218 41.5094 73.1941 41.7062 72.9244C41.9416 72.622 42.2245 72.3599 42.5441 72.1484C43.0282 71.8979 43.5286 71.6802 44.0419 71.4966L45.5394 70.8759L51.5298 68.354C52.3626 67.966 53.9145 67.3194 56.1855 66.4141L59.0332 65.2502L60.0575 64.8855L60.236 64.8079C60.2846 64.7976 60.3348 64.7976 60.3834 64.8079C60.4983 64.7571 60.6226 64.7306 60.7482 64.7303C60.9886 64.717 61.229 64.752 61.4556 64.8333C61.6822 64.9146 61.8903 65.0406 62.0673 65.2036C62.2175 65.3451 62.3369 65.5159 62.4184 65.7054C62.4998 65.8949 62.5414 66.0992 62.5406 66.3055V68.2764C62.5332 68.6422 62.42 68.9979 62.2147 69.3007C61.9988 69.6386 61.6768 69.8952 61.2991 70.0301L49.0623 75.035L61.2991 80.0399C61.6635 80.1904 61.9799 80.4372 62.2147 80.7538C62.428 81.0438 62.5422 81.3948 62.5406 81.7548V83.7257C62.5408 83.9342 62.4989 84.1406 62.4176 84.3326C62.3363 84.5246 62.2172 84.6982 62.0673 84.8431C61.8931 85.0112 61.6859 85.1415 61.4588 85.2256C61.2318 85.3097 60.99 85.346 60.7482 85.332C60.6235 85.3306 60.4998 85.307 60.3834 85.2621L60.0575 85.1535L59.0332 84.7888L56.2088 83.6404L51.5531 81.7082L45.5627 79.1864L44.0652 78.5656C43.5841 78.3716 43.0796 78.1544 42.5674 77.9061C42.2496 77.7579 41.9775 77.527 41.7795 77.2376C41.5816 76.9482 41.4652 76.6109 41.4423 76.261L41.419 73.8556Z" fill="var(--color-primary-3)" />
                <path d="M77.9744 87.4437C77.5861 87.4532 77.2021 87.3599 76.8614 87.1732C76.5208 86.9865 76.2355 86.7131 76.0345 86.3806C75.8678 86.1412 75.7347 85.8801 75.6388 85.6046L75.3827 84.9141L69.7958 72.5686L69.3923 71.6529C68.9267 70.6597 68.0266 68.6888 66.692 65.7402C64.959 61.9897 64.0924 59.9334 64.0924 59.5713C64.0927 59.3531 64.1425 59.1378 64.2379 58.9416C64.3334 58.7453 64.472 58.5733 64.6435 58.4384C65.0307 58.1111 65.5257 57.9396 66.0323 57.9573H67.8481C68.2307 57.9507 68.6082 58.0456 68.9422 58.2323C69.2761 58.4191 69.5545 58.691 69.7492 59.0204L70.1527 59.7963L70.4088 60.4869L71.8675 63.6994L75.9957 72.8324L76.5388 73.9963L79.0995 79.622C80.0812 81.6401 80.9465 83.7127 81.6913 85.8297C81.6899 86.0466 81.6397 86.2604 81.5443 86.4551C81.4489 86.6499 81.3107 86.8207 81.1402 86.9548C80.7534 87.2828 80.2582 87.4544 79.7514 87.4359L77.9744 87.4437Z" fill="var(--color-primary-3)" />
                <path d="M84.733 85.3479C84.4852 85.364 84.2367 85.3289 84.003 85.2448C83.7694 85.1607 83.5557 85.0294 83.375 84.859C83.0765 84.5633 82.9064 84.1618 82.9016 83.7416V81.7707C82.8981 81.4129 83.0067 81.0628 83.2121 80.7697C83.4442 80.4441 83.7685 80.1955 84.1432 80.0559L96.4189 75.0509L84.1432 70.0538C83.7705 69.8906 83.4546 69.6206 83.2354 69.2778C83.0239 68.9835 82.9073 68.6315 82.9016 68.2691V66.2671C82.899 66.0624 82.9391 65.8594 83.0193 65.671C83.0994 65.4827 83.2179 65.313 83.3673 65.173C83.732 64.8449 84.2119 64.6747 84.7019 64.6997C84.8153 64.6977 84.9273 64.7244 85.0277 64.7772C85.0945 64.7655 85.1627 64.7655 85.2295 64.7772L85.3926 64.8548C85.346 64.8548 85.6718 64.948 86.3779 65.2196L89.2646 66.3835C89.1146 66.3162 90.6665 66.9629 93.9204 68.3234C95.6947 69.0528 97.7276 69.9167 100.019 70.9151L100.601 71.1323L101.439 71.466C101.933 71.6415 102.411 71.8596 102.867 72.1178C103.193 72.2665 103.471 72.5026 103.671 72.7998C103.871 73.097 103.985 73.4437 104 73.8016V76.2071C103.993 76.537 103.885 76.8566 103.69 77.1227C103.473 77.4239 103.192 77.6731 102.867 77.8521C102.425 78.0694 101.905 78.3022 101.315 78.5505L99.7631 79.1713L86.246 84.828C85.8581 85.022 85.6253 85.1461 85.5555 85.1927L85.4079 85.3013L84.733 85.3479Z" fill="var(--color-primary-3)" />
                <g opacity="0.2" filter="url(#filter0_f_633_2796)">
                    <rect x="48" y="62" width="51" height="34" fill="var(--color-primary-3)" />
                </g>
                <g filter="url(#filter1_d_633_2796)">
                    <path d="M112 58.2488H104.75C92.5997 58.2488 82.75 48.3991 82.75 36.2488V29L112 58.2488Z" fill="url(#paint2_linear_633_2796)" />
                </g>
                <path d="M42.6994 87V72.4545H45.7746V78.456H52.0175V72.4545H55.0857V87H52.0175V80.9915H45.7746V87H42.6994ZM58.4673 74.9901V72.4545H70.4133V74.9901H65.9602V87H62.9204V74.9901H58.4673ZM73.7806 72.4545H77.5732L81.5789 82.2273H81.7494L85.755 72.4545H89.5477V87H86.5647V77.5327H86.444L82.6798 86.929H80.6485L76.8843 77.4972H76.7636V87H73.7806V72.4545ZM93.4814 87V72.4545H96.5567V84.4645H102.792V87H93.4814Z" fill="white" />
            </g>
            <defs>
                <filter id="filter0_f_633_2796" x="9" y="23" width="129" height="112" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="19.5" result="effect1_foregroundBlur_633_2796" />
                </filter>
                <filter id="filter1_d_633_2796" x="59.75" y="5" width="79.25" height="79.249" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="2" dy="1" />
                    <feGaussianBlur stdDeviation="12.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_633_2796" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_633_2796" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_633_2796" x1="73" y1="29" x2="73" y2="117.32" gradientUnits="userSpaceOnUse">
                    <stop stopColor="var(--color-primary-3)" />
                    <stop offset="1" stopColor="var(--color-primary-3)" />
                </linearGradient>
                <linearGradient id="paint1_linear_633_2796" x1="73" y1="29" x2="73" y2="117.32" gradientUnits="userSpaceOnUse">
                    <stop stopColor="var(--color-primary-3)" />
                    <stop offset="0.942892" stopColor="var(--color-primary-3)" />
                </linearGradient>
                <linearGradient id="paint2_linear_633_2796" x1="97.375" y1="29" x2="97.375" y2="58.2488" gradientUnits="userSpaceOnUse">
                    <stop stopColor="var(--color-primary-3)" />
                    <stop offset="1" stopColor="var(--color-primary-3)" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export default Icon