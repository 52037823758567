const pricing = {
  "t0": "Analizza e esplora le tue metriche",
  "t1": "Nessuna sorpresa, così sono i nostri prezzi",
  "t2": "Prova 7 giorni gratis",
  "t3": "PIÙ POPOLARE",
  "t4": "149€",
  "t5": "/mese",
  "t6": "Business",
  "t7": "Aziende che necessitano di analisi continue secondo diversi attori.",
  "t8": "5 utenti inclusi",
  "t9": "10 Add-ons visivi",
  "t10": "Dati vettorializzati",
  "t11": "50 grafici di dati",
  "t12": "100 domande al dato",
  "t13": "200 clics di HeatMap",
  "t14": "100GB di dati",
  "t15": "Supporto prioritario",
  "t16": "Videochiamate",
  "t17": "Motore di ricerca",
  "t18": "Scegli piano",
  "t19": "49€",
  "t20": "/mese",
  "t21": "Base",
  "t22": "Destinato a idee emergenti",
  "t23": "che desiderano analizzare i propri KPI per definire strategie.",
  "t24": "1 utente incluso",
  "t25": "5 add-ons visivi",
  "t26": "Dati vettorializzati",
  "t27": "10 grafici di dati",
  "t28": "20 domande al dato",
  "t29": "50 clics di HeatMap",
  "t30": "5GB di dati",
  "t31": "Supporto via email",
  "t32": "Videochiamate",
  "t33": "Motore di ricerca",
  "t34": "Scegli piano",
  "t35": "349€",
  "t36": "/mese",
  "t37": "Start Up",
  "t38": "Per team di Start Up disposti a creare i propri analisi su misura senza limiti.",
  "t39": "15 utenti inclusi",
  "t40": "Add-ons illimitati",
  "t41": "Dati vettorializzati",
  "t42": "Grafici illimitati",
  "t43": "Domande illimitate",
  "t44": "500 clics di HeatMap",
  "t45": "500GB di dati",
  "t46": "Supporto urgente",
  "t47": "Videochiamate",
  "t48": "Motore di ricerca",
  "t49": "Scegli piano",
  "t50": "GRATIS",
  "t51": "*7 giorni",
  "t52": "Esplorazione",
  "t53": "Perfetto per iniziare con analisi puntuali, senza poter creare a lungo termine.",
  "t54": "1 utente incluso",
  "t55": "1 Add-on visivo",
  "t56": "Dati vettorializzati",
  "t57": "1 grafico di dati",
  "t58": "5 domande al dato",
  "t59": "Nessun HeatMap",
  "t60": "500MB di dati",
  "t61": "Nessun supporto",
  "t62": "Videochiamate",
  "t63": "Motore di ricerca",
  "t64": "Scegli piano",
  "t65": "Se hai bisogno di più utenti e opzioni, contatta il nostro team di",
  "t66": "Vendite",
  "t67": "Confronta tra i piani di pagamento",
  "t68": "E scopri quale si adatta alle tue esigenze",
  "t69": "Base",
  "t70": "Inizia",
  "t71": "Business",
  "t72": "Comprendi",
  "t73": "Startup",
  "t74": "Analizza",
  "t75": "CAPACITÀ E ACCESSI",
  "t76": "Utenti",
  "t77": "1",
  "t78": "5",
  "t79": "15",
  "t80": "Add-ons",
  "t81": "Illimitati",
  "t82": "5",
  "t83": "10",
  "t84": "Dati vettorializzati",
  "t85": "GB di dati",
  "t86": "Fino a 5 GB",
  "t87": "Fino a 100 GB",
  "t88": "Fino a 500 GB",
  "t89": "Rappresentazione personalizzata",
  "t90": "Numero di Grafici",
  "t91": "10",
  "t92": "50",
  "t93": "Illimitati",
  "t94": "Numero di domande",
  "t95": "20",
  "t96": "100",
  "t97": "Illimitati",
  "t98": "Clics con Mappa di Calore",
  "t99": "50",
  "t100": "200",
  "t101": "500",
  "t102": "Videochiamate e ricerca",
  "t103": "PERSONALIZZAZIONE E CODICE",
  "t104": "Logo personalizzato",
  "t105": "presto",
  "t106": "Temi predefiniti",
  "t107": "Temi personalizzati",
  "t108": "presto",
  "t109": "Condividi con sottodominio",
  "t110": "Condividi con dominio",
  "t111": "presto",
  "t112": "Accesso IAM e banca",
  "t113": "Utilizzo di Add-ons",
  "t114": "presto",
  "t115": "Edizione di prompts",
  "t116": "Accesso al codice sorgente",
  "t117": "presto",
  "t118": "Edizione in tempo reale",
  "t119": "presto",
  "t120": "INFORMI",
  "t121": "Esporta PDF",
  "t122": "Invio via email",
  "t123": "Condividi per link",
  "t124": "SUPPORTO",
  "t125": "Supporto via email",
  "t126": "Supporto prioritario",
  "t127": "Aiuto one-to-one",
  "t128": "E se ho bisogno di più per analizzare?",
  "t129": "Puoi cambiare piano. Ma se ciò scala troppo le tue esigenze, puoi scegliere in base a più grafici, più domande, più add-ons per le tue rappresentazioni di interfaccia, più clics, più GB di dati... I tuoi extra si aggiungeranno alla tua quota esistente. Potrai annullare in qualsiasi momento. E se hai bisogno di un piano personalizzato, contatta le vendite.",
  "t130": "Grafici",
  "t131": "Pacchetto 10",
  "t132": "15€",
  "t133": "Domande",
  "t134": "Pacchetto 20",
  "t135": "10€",
  "t136": "Add-on",
  "t137": "Extra",
  "t138": "10€",
  "t139": "Mappa di calore",
  "t140": "Clic",
  "t141": "0.5€",
  "t142": "GB di Cloud",
  "t143": "GB",
  "t144": "1€"
}

export default pricing