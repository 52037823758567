import apiBackend from '@src/apiBackend.js'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getUserNotifications = createAsyncThunk(
	'notifications/getUserNotifications',
	async ({ userId }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.get(`/notifications/${userId}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			return res.data
		} catch (error) {
			console.error('Error getUserNotifications:', error)
		}
	}
)

export const sendNotification = createAsyncThunk(
	'notifications/sendNotification',
	async ({ userIds, notification }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/notifications/send',
				{ userIds, notification },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			return res.data
		} catch (error) {
			console.log('Error sendNotification:', error)
		}
	}
)

export const deleteNotification = createAsyncThunk(
	'notifications/deleteNotification',
	async ({ notificationId }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				`/notifications/delete/${notificationId}`,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			return res.data
		} catch (error) {
			console.log('Error deleteNotification:', error)
		}
	}
)

export const deleteAllNotifications = createAsyncThunk(
	'notifications/deleteAllNotifications',
	async ({ userId }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				`/notifications/deleteAll/${userId}`,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			return res.data
		} catch (error) {
			console.log('Error deleteAllNotifications:', error)
		}
	}
)

export const readNotification = createAsyncThunk(
	'notifications/readNotification',
	async ({ notificationId }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				`/notifications/read/${notificationId}`,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			return res.data
		} catch (error) {
			console.log('Error readNotification:', error)
		}
	}
)

export const readAllNotifications = createAsyncThunk(
	'notifications/readAllNotifications',
	async ({ userId }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				`/notifications/readAll/${userId}`,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			return res.data
		} catch (error) {
			console.log('Error readAllNotifications:', error)
		}
	}
)
