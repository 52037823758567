import React from "react";

import BlogData from "@home/assets/constants/blog.json";
import bgShape from "@home/assets/images/bg/bg-shape-two.png";

import BlogItem from "./BlogItem";
import Categories from "./BlogItems/Categories";
import BlogPost from "./BlogItems/BlogPost";
import Archives from "./BlogItems/Archives";
import BlogTags from "./BlogItems/BlogTags";
import BrandTwo from "../Brands/Brand-Two";

import styles from '../index.module.css'

const Blog = () => {
  return (
    <>
      <div className={`${styles['rainbow-blog-area']} ${styles['rainbow-section-gap']} ${styles['bg-color-1']}`}>
        <div className={styles.container}>
          <div className={`${styles.row} ${styles['row--30']}`}>
            <div className={styles["col-lg-8"]}>
              <BlogItem blog={BlogData && BlogData.blog} />
            </div>
            <div className={`${styles["col-lg-4"]} ${styles["mt_md--40"]} ${styles["mt_sm--40"]}`}>
              <aside className={styles["rainbow-sidebar"]}>
                <div className={`${styles["rbt-single-widget"]} ${styles["widget_search"]} ${styles["mt--40"]}`}>
                  <div className={styles.inner}>
                    <form className="blog-search" action="#">
                      <input type="text" placeholder="Search ..." />
                      <button className={styles["search-button"]}>
                        <i className={styles["feather-search"]}></i>
                      </button>
                    </form>
                  </div>
                </div>
                <div className={`${styles["rbt-single-widget"]} ${styles["widget_categories"]} ${styles["mt--40"]}`}>
                  <h3 className={styles.title}>Categories</h3>
                  <Categories category={BlogData && BlogData.categories} />
                </div>
                <div className={`${styles["rbt-single-widget"]} ${styles["widget_recent_entries"]} ${styles["mt--40"]}`} >
                  <h3 className={styles.title}>Post</h3>
                  <BlogPost blogpost={BlogData && BlogData.blog} />
                </div>
                <div className={`${styles["rbt-single-widget"]} ${styles["widget_archive"]} ${styles["mt--40"]}`} >
                  <h3 className={styles.title}>Archives</h3>
                  <Archives blogarc={BlogData && BlogData.blog} />
                </div>
                <div className={`${styles["rbt-single-widget"]} ${styles["widget_tag_cloud"]} ${styles["mt--40"]}`} >
                  <h3 className={styles.title}>Tags</h3>
                  <BlogTags tags={BlogData && BlogData.tags} />
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles["rainbow-brand-area"]} ${styles["rainbow-section-gap"]} ${styles["bg-color-1"]}`}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles["col-lg-12"]}>
              <div
                className={`${styles["section-title"]} ${styles["rating-title"]} ${styles["text-center"]} ${styles["sal-animate"]}`}
                data-sal="slide-up"
                data-sal-duration="700"
                data-sal-delay="100"
              >
                <div className={styles.rating}>
                  <a href="#rating">
                    <svg  fill="currentColor" viewBox="0 0 24 24">
                      <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                    </svg>
                  </a>
                  <a href="#rating">
                    <svg  fill="currentColor" viewBox="0 0 24 24">
                      <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                    </svg>
                  </a>
                  <a href="#rating">
                    <svg  fill="currentColor" viewBox="0 0 24 24">
                      <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                    </svg>
                  </a>
                  <a href="#rating">
                    <svg  fill="currentColor" viewBox="0 0 24 24">
                      <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                    </svg>
                  </a>
                  <a href="#rating">
                    <svg  fill="currentColor" viewBox="0 0 24 24">
                      <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                    </svg>
                  </a>
                </div>
                <p className={`${styles.subtitle} ${styles["mb--0"]}`}>Based on 20,000+ reviews on</p>
              </div>
            </div>
          </div>
          <BrandTwo />
        </div>

        <div className={styles["bg-shape-left"]}>
          <img src={bgShape} width={688} height={1055} alt="Bg shape" />
        </div>
      </div>
    </>
  );
};

export default Blog;
