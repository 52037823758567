const web = {
    t1: {
        '01': `Modelleer uw`,
        '02': `gegevens`,
        '03': `geavanceerd,`,
        '04': `voorspel uw`,
        '05': `matrix`,
        '06': `naar elke mogelijke realiteit en analyseer de`,
        '07': `big data`,
        '08': `met gevectoriseerde databases`,
    },
    t2: `BEGIN NU`,
    t3: `Op uw nieuwe besturingssysteem`,
    t4: {
        '01': `Offline`, 
        '02': `Online`, 
        '03': `Openbaar`, 
        '04': `Privé`, 
    },
    tassistant: {
        '01': `Snelheid`,
        '02': `Textuur`,
        '03': `Verwerking`,
    },
    t5: `Een wereld van mogelijkheden`,
    t6: {
        '01': `Dankzij de`,
        '02': `gevectoriseerde databases,`,
        '03': `gaat u van`,
        '04': `statische gegevens`,
        '05': `naar`,
        '06': `dynamische gegevens.`,
        '07': `Werken met veel gegevens tegelijk is moeilijk, daarom is`,
        '08': `nul latentie in bepaalde dimensies`,
        '09': `noodzakelijk voor snelle en veelzijdige toegang.`,
    },
    t7: `Ga nu naar binnen`,
    t8: `TIJD BESPAREN . TIJD BESPAREN . TIJD BESPAREN`,
    t9: `Trek uw eigen conclusies`,
    t10: `Informatie is macht`,
    t11: {
        '01': `Nu kunt u uw gegevens begrijpen!`,
        '02': `Via segmentatie, met het concept van`,
        '03': `K-Means,`,
        '04': `heeft u een instrument om elke gegevens te analyseren om te meten.`,
    },
    t12: `Begin nu`,
    t13: `Ruimte beschikbaar in de cloud. Alle schaalbaar op privé- en veilige instanties.`,
    t14: `keer sneller dan werken met traditionele databases.`,
    t15: `Mogelijke representaties om uw metrics en KPI's te importeren.`,
    t16: `Analyseer gegevens over dimensies heen`,
    t17: `Elke dimensie heeft agenten.`,
    t18: {
        '01': `In elke dimensie kunt u`,
        '02': `stromen van agenten`,
        '03': `creëren om netwerken van interacties te creëren.`
    },
    t19: {
        '01': `In elke agent kiest u de`,
        '02': `strategie`,
        '03': `om`,
        '04': `elk gegeven te relateren.`,
        '05': `elk gegeven.`,
    },
    t20: `Start uw GRATIS proefperiode`,
    t21: `Dimensies`,
    t22: `Agenten`,
    t23: `Ideeën voor gegevensbeheer`,
    t24: `Uw gegevens de eerste stap`,
    t25: {
        '01': `Ze zijn uw sleutel om`,
        '02': `geweldige toepassingen te bouwen. Ze zijn niet alleen informatie,`,
        '03': `ze zijn het ruwe materiaal`,
        '04': `voor de creatie van`,
        '05': `innovatieve ideeën en hun analyse.`,
        '06': `Hoe werken we met gegevens?`,
    },
    t26: `Uw werkruimte`,
    t27: `Sla alle inhoud op die u nodig heeft in uw privé- of openbare cloud, afhankelijk van uw behoeften.`,
    t28: `Warmtekaart`,
    t29: `Representatie die de verdeling en concentratie van bepaalde waarden binnen een dataset laat zien.`,
    t30: `Dynamische vragen`,
    t31: `Creatie van interactieve vragenlijsten, waarbij vragen zich kunnen aanpassen aan vooraf bestaande gegevens.`,
    t32: `Aangepaste grafieken`,
    t33: `Hiermee kunt u het type grafiek, de gegevens om te visualiseren en de aanpassing van aspecten zoals kleuren en labels selecteren.`,
    t34: `Videogesprekken`,
    t35: `Realtime communicatie via video en audio, wat samenwerking en interactie vergemakkelijkt.`,
    t36: `Contracten`,
    t37: `Creatie, beheer en elektronische facturering van contracten, waardoor juridische en administratieve processen worden gestroomlijnd.`,
    t38: `Rollen en machtigingen`,
    t39: `Wijs verschillende rollen en niveaus van toegang toe aan gebruikers binnen uw eigen team om uw organisatie te creëren.`,
    t40: `Team synchronisatie`,
    t41: `Realtime samenwerking van werk tussen meerdere gebruikers, waarbij ervoor wordt gezorgd dat wijzigingen automatisch worden beveiligd.`,
    t42: `Al uw contacten`,
    t43: `Centraliseer en organiseer alle contacten. Beheer en krijg eenvoudig toegang tot contactinformatie.`,
    t44: `Leer het te doen, het is heel gemakkelijk`,
    t45: `Dimensies wijzigen`,
    t46: {
        '01': `Maak uw gegevens intelligent, verbindbaar, consistent, veilig, efficiënt en toegankelijk. Versterk uw analyses met`,
        '02': `onze toegang.`
    },
    t47: {
        '01': `Begin met `,
        '02': `de tutorial`,
        '03': `om de verschillende onderdelen van het platform te begrijpen.`,
    },
    t48: `Meld u NU aan`,
    t49: `Waardeer uw tijd en automatiseer uw gegevens`,
    t50: {
        '01': `En begin met het wisselen van dimensies in ons cloud-besturingssysteem. Analyseer en begrijp patronen om`,
        '02': `met uw gegevens te werken.`,
    },


    footer: {
        t00: "HOME",
        t01: "Jouw team",
        t02: "Updates",
        t03: "Digitale Kit",
        t04: "Advies",
        t05: "Studeren",
    
        t10: "LINKS",
        t11: "Team",
        t12: "Freelancers",
        t13: "Voorwaarden",
        t14: "Kenmerken",
        t15: "Rekenmachine",
        t16: "Plannen",
    
        t20: "AI MODULE",
        t21: "Stem",
        t22: "Beeld & Video",
        t23: "Inhoud Creatie",
        t24: "Media Creatie",
    
        t30: "AYTHEN ONDERSTEUNING",
        t31: "Gebruikersovereenkomst",
        t32: "Privacybeleid",
        t33: "Gegevensverzameling en gebruik",
        t34: "Inhoudsrichtlijnen",
    
        t40: "OM TE ONTWERPEN",
        t41: "Editor",
        t42: "Apps",
        t43: "Cloud",
        t44: "Plugins",
        t45: "RPA",
    
        t50: "OM TE VINDEN",
        t51: "Bibliotheek",
        t52: "Presets",
        t53: "Devops",
        t54: "IDE",
        t55: "Sandbox",
    
        t60: "OM TE BOUWEN",
        t61: "UX/AI",
        t62: "Vertalen",
        t63: "AI Documenten",
        t64: "AI Ondersteuning",
        t65: "Samenwerkingen",
    
        t70: "OM TE BESCHERMEN",
        t71: "Blockchain",
        t72: "Gegevens",
        t73: "Pivot",
        t74: "Academie",
        t75: "Freelancers",
    }
    
}

export default web
