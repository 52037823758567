import React from 'react'

import DragNavImage from './assets/drag-nav.png'
import PluginImage from './assets/plugin.png'
import Card1Image from './assets/img-1.png'
import Card2Image from './assets/img-2.png'
import Card3Image from './assets/img-3.png'
import Card4Image from './assets/img-4.png'
import CurveImage from './assets/Vector.png'

const Plugins = () => {
  return (
    <div className="relative w-full sm:h-full" id="plugins">
      <div className="container mx-auto">
        <section className="home-section-1">
          <div className="flex">
            <div className="relative lg:pt-[10px] z-[0]">
              <div className="mb-5 flex items-center ml-[-8px] absolute left-0 top-[10px] w-[300px]">
                <div className="bg-[#939AFF] z-[-1]  w-[40px] h-[40px] flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                  >
                    <path
                      d="M8.67281 13.5195H1.80701C1.36091 13.5195 0.999268 13.8812 0.999268 14.3273V21.1931C0.999268 21.6392 1.36091 22.0008 1.80701 22.0008H8.67281C9.11891 22.0008 9.48055 21.6392 9.48055 21.1931V14.3273C9.48055 13.8812 9.11891 13.5195 8.67281 13.5195Z"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M21.1929 13.5195H14.3272C13.881 13.5195 13.5194 13.8812 13.5194 14.3273V21.1931C13.5194 21.6392 13.881 22.0008 14.3272 22.0008H21.1929C21.6391 22.0008 22.0007 21.6392 22.0007 21.1931V14.3273C22.0007 13.8812 21.6391 13.5195 21.1929 13.5195Z"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.941 1H8.0752C7.62909 1 7.26746 1.36164 7.26746 1.80774V8.67354C7.26746 9.11964 7.62909 9.48128 8.0752 9.48128H14.941C15.3871 9.48128 15.7487 9.11964 15.7487 8.67354V1.80774C15.7487 1.36164 15.3871 1 14.941 1Z"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="ml-4">
                  <h4 className="text-[#939AFF] font-extrabold uppercase text-[20px]">
                    PLUGINS
                  </h4>
                </div>
              </div>
              <div className="plugins-left-border mt-[70px]"></div>
            </div>
            <div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="col-span-2 relative">
                  <div className="mt-[100px]">
                    <div className="pl-5 lg:pl-10">
                      <p className="mb-20 text-[24px] text-[#554D56] lg:text-[48px] leading-tight drop-shadow-xl text-left">
                        <span className="text-[#939AFF]">
                          Plugins creados por la comunidad.
                        </span>
                        Una librería para empresas y open source para usar en un
                        solo click en tus proyectos.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="text-end relative">
                  <div className="w-full">
                    <img
                      src={PluginImage}
                      className="md:w-auto lg:left-[50px] lg:top-[-250px] z-20"
                    />
                  </div>
                  <div className="w-full initial lg:absolute top-[-200px] lg:left-[100px] lg:top-[100px] z-20 lg:block flex justify-end">
                    <img
                      src={DragNavImage}
                      className="w-[80px] lg:w-[120px] z-20"
                    />
                  </div>
                </div>
              </div>

              <div className="lg:mt-[120px]">
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3">
                  <div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5  h-[450px] relative mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          PRIMER BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Extensibilidad
                        </h5>
                        <p className="lg:text-[15px]">
                          Los plugins permiten extender las capacidades del
                          software base sin modificar su código fuente. Esto
                          facilita la adición de nuevas características y
                          funcionalidades sin tener que realizar cambios
                          importantes en la aplicación principal.
                        </p>
                        <img src={Card1Image} className="  z-20" />
                      </div>
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[500px] relative mt-5">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          CUARTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Reutilización de código
                        </h5>
                      </div>
                      <div className="lg:w-[90%]">
                        <p className="lg:text-[15px]">
                          Los plugins pueden compartir componentes y bibliotecas
                          de código entre ellos y con el software principal.
                          Esto fomenta la reutilización de código, lo que puede
                          ahorrar tiempo y esfuerzo en el desarrollo.
                        </p>
                      </div>
                      <div className="lg:flex items-end justify-center">
                        <img src={Card3Image} className="lg:w-[350px]" />
                      </div>
                    </div>
                    <div>
                      <img src={CurveImage} className="ml-[7px] w-[200px]" />
                    </div>
                  </div>
                  <div className="relative mt-[-80px] -lg:mt-[0px]">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[450px] relative mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          SEGUNDO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Personalización
                        </h5>
                        <p className="lg:text-[15px]">
                          Los usuarios pueden personalizar el software según sus
                          necesidades específicas al agregar o eliminar plugins.
                          Esto brinda una experiencia más adaptada y
                          satisfactoria para cada usuario.
                        </p>
                      </div>
                      <img
                        src={Card2Image}
                        className="absolute bottom-[0px] right-[0px] z-20"
                      />
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[400px] relative mt-5 mb-8 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          QUINTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Compatibilidad y portabilidad
                        </h5>
                      </div>
                      <div className="lg:w-[60%]">
                        <p className="lg:text-[15px]">
                          Los plugins pueden desarrollarse para ser compatibles
                          con diferentes sistemas operativos y versiones de
                          software, lo que mejora su portabilidad y facilita su
                          uso en diversas plataformas.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="relative md:col-span-2 lg:col-span-1 grid md:grid-cols-2 lg:block gap-4">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 relative mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          TERCER BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Actualizaciones independientes
                        </h5>
                        <p className="lg:text-[15px] mt-3">
                          Al separar funcionalidades en plugins, las
                          actualizaciones se pueden realizar de manera
                          independiente para cada uno de ellos. Esto permite a
                          los desarrolladores proporcionar mejoras y
                          correcciones de errores específicas sin afectar la
                          estabilidad del software general.
                        </p>
                      </div>
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 relative mt-3 mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          SEXTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Escala de negocio
                        </h5>
                        <p className="lg:text-[15px] mt-3">
                          Si el software permite la creación de plugins por
                          parte de terceros, puede dar lugar a un ecosistema de
                          desarrolladores y empresas que creen y ofrezcan
                          plugins para la aplicación base, lo que puede generar
                          oportunidades comerciales adicionales.
                        </p>
                      </div>
                    </div>
                    <div className="md:absolute left-[-300px] top-[430px] mt-[-100px] md:mt-[0px]">
                      <img src={Card4Image} className="md:w-[500px] lg:w-[700px]" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Plugins
