import { useLocation } from 'react-router-dom';


import React from "react";

import styles from '../index.module.css'


const NavProps = ({ list }) => {
  const location = useLocation();

  const isActive = (href) =>
    location.pathname.substring(0, 11) === href.substring(0, 11);

  return (
    <>
      <div className={`${styles["col-lg-3"]} ${styles["single-mega-item"]}`} >
        <ul className={`${styles["mega-menu-item"]}`} >
          {list &&
            list.map((item, i) => (
              <li key={i}>
                {item.heading ? (
                  <h3 className={styles["rbt-short-title"]} >{item.heading}</h3>
                ) : (
                  <button
                    className={`${item.isComing ? styles.disabled : ""} ${
                      isActive(item.link) ? styles.active : ""
                    }`}
                    href={item.link}
                  >
                    <span>{item.text}</span>
                    {item.badge ? (
                      <div className={`${styles["rainbow-badge-card"]} ${styles["badge-sm"]} ${styles["ml--5"]}`} >
                        {item.badge}
                      </div>
                    ) : (
                      ""
                    )}
                  </button>
                )}
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export default NavProps;
