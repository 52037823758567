// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdownContainer_dyzEs {
	position: absolute;
	top: -28px;
	right: -17px;
	width: 200px;
	background-color: var(--background) !important;
	border-radius: 4px !important;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	z-index: 1000;
}

.dropdownList_ASZgB {
	list-style-type: none;
	background: var(--background) !important;
	margin: 0 !important;
	padding: 5px 0px !important;
}

.dropdownItem_Et6M4 {
	padding: 5px 10px;
	font-size: 14px;
	color: var(--text-color);
	font-weight: 500;
	cursor: pointer;
	background: var(--background) !important;
	transition: background-color 0.2s ease;
	border-radius: 0px !important;
}

.dropdownItem_Et6M4:hover {
	background-color: var(--border-color) !important;
}
`, "",{"version":3,"sources":["webpack://./src/views/app/v1-1/pages/Calendar/components/EventOptionsDropdown.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,UAAU;CACV,YAAY;CACZ,YAAY;CACZ,8CAA8C;CAC9C,6BAA6B;CAC7B,wCAAwC;CACxC,aAAa;AACd;;AAEA;CACC,qBAAqB;CACrB,wCAAwC;CACxC,oBAAoB;CACpB,2BAA2B;AAC5B;;AAEA;CACC,iBAAiB;CACjB,eAAe;CACf,wBAAwB;CACxB,gBAAgB;CAChB,eAAe;CACf,wCAAwC;CACxC,sCAAsC;CACtC,6BAA6B;AAC9B;;AAEA;CACC,gDAAgD;AACjD","sourcesContent":[".dropdownContainer {\n\tposition: absolute;\n\ttop: -28px;\n\tright: -17px;\n\twidth: 200px;\n\tbackground-color: var(--background) !important;\n\tborder-radius: 4px !important;\n\tbox-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n\tz-index: 1000;\n}\n\n.dropdownList {\n\tlist-style-type: none;\n\tbackground: var(--background) !important;\n\tmargin: 0 !important;\n\tpadding: 5px 0px !important;\n}\n\n.dropdownItem {\n\tpadding: 5px 10px;\n\tfont-size: 14px;\n\tcolor: var(--text-color);\n\tfont-weight: 500;\n\tcursor: pointer;\n\tbackground: var(--background) !important;\n\ttransition: background-color 0.2s ease;\n\tborder-radius: 0px !important;\n}\n\n.dropdownItem:hover {\n\tbackground-color: var(--border-color) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownContainer": `dropdownContainer_dyzEs`,
	"dropdownList": `dropdownList_ASZgB`,
	"dropdownItem": `dropdownItem_Et6M4`
};
export default ___CSS_LOADER_EXPORT___;
