const pricing = {
    "t0": "Analiza y explora tus métricas",
    "t1": "Sin sorpresas, así son nuestros precios",
    "t2": "Añadir más funciones",
    "t3": "Más Popular",
    "t4": "49€",
    "t5": "/mes",
    "t6": "Business",
    "t7": "Automatiza tus procesos documentales de forma sencilla.",
    "t8": "1 usuario",
    "t9": "+ Adventure",
    "t10": "Sin tablas",
    "t11": "Sin relaciones",
    "t12": "Sin mapas de calor",
    "t13": "Sin acceso al código",
    "t14": "Sin acceso a análisis",
    "t15": "Hata 100GB de datos",
    "t16": "Facturas ilimitadas",
    "t17": "Firmas ilimitadas",
    "t1711": "Soporte prioritario",
    "t18": "Escoger plan",
    "t19": "Gratis",
    "t21": "Adventure",
    "t22": "Perfecto para empezar, sin poder crear a largo plazo.",
    "t24": "1 usuario",
    "t25": "Hasta 15 GB incluidos",
    "t26": "Hata 100 facturas/año",
    "t27": "Hata 20 firmas/año",
    "t28": "Automatiza alertas",
    "t29": "Soporte por email",
    "t30": "Aythen Calendar",
    "t34": "Escoger plan",
    "t35": "149€",
    "t36": "/mes",
    "t37": "Freelance",
    "t38": "Crea, gestiona y analiza cualquier situación digital por ti mismo.",
    "t39": "1 usuario",
    "t40": "+ Business",
    "t41": "Tablas interactivas",
    "t42": "Relaciones de datos",
    "t43": "Hasta 500 clicks",
    "t44": "Acceso al código",
    "t45": "Acceso a análisis",
    "t46": "Hasta 1TB de datos",
    "t47": "Hata 5 invitados",
    "t48": "10 Addons visuales",
    "t481": "50 gráficas",
    "t482": "100 preguntas",
    "t483": "Soporte urgente",
    "t484": "Acceso al Marketplace",
    "t49": "Escoger plan",

    "t65": "Si tienes dudas o no tienes algo claro, contacta con nuestro equipo de ",
    "t66": "Ventas",
    "t67": "Compara entre los planes de pago",
    "t68": "Y descubre cual encaja en tus necesidades",
    "t69": "Gratis",
    "t70": "Empieza",
    "t71": "Business",
    "t72": "Organiza",
    "t73": "Freelance",
    "t74": "Analiza",
    "t75": "CAPACIDADES Y ACCESOS",
    "t76": "Usuarios",
    "t77": "1",
    "t78": "1",
    "t79": "1",
    "t80": "Invitados",
    "t81": "5",
    "t84": "Datos vectorizados (Aythen DB)",
    "t85": "GB de datos",
    "t86": "Hasta 5 GBs",
    "t87": "Hasta 100 GBs",
    "t88": "Hasta 1 TB",
    "t89": "Acceso a tablas (Aythen view)",
    "t90": "Acceso a relaciones (Aythen node)",
    "t91": "10",
    "t92": "50",
    "t93": "Ilimitadas",
    "t94": "Acceso a mapas de calor (Aythen view)",
    "t95": "20",
    "t96": "100",
    "t97": "Ilimitados",
    "t98": "Acceso al código (Aythen matrix)",
    "t99": "50",
    "t100": "200",
    "t101": "500",
    "t102": "Acceso a análisis (Aythen BI)",
    "t103": "FUNCIONALIDADES INCLUÍDAS",
    "t104": "Agenda y calendario",
    "t105": "soon",
    "t106": "Kan Ban de tareas",
    "t107": "Factura electrónica",
    "t107_1": "Hasta 100",
    "t107_2": "Ilimitadas",
    "t107_3": "Ilimitadas",
    "t108": "soon",
    "t109": "Firma electrónica",
    "t109_1": "Hasta 100",
    "t109_2": "Hasta 20",
    "t109_3": "Ilimitadas",
    "t110": "Clicks de usuarios  (Aythen Vision)",
    "t111": "soon",
    "t112": "Correlaciones (Aythen Node)",
    "t113": "Utilización de Add-ons",
    "t113_1": "Hasta 10",
    "t114": "soon",
    "t115": "Gráficas dinámicas",
    "t115_1": "Hasta 50",
    "t116": "Preguntas dinámicas",
    "t116_1": "Hasta 100",
    "t117": "soon",
    "t118": "Venta en Marketplace",
    "t119": "soon",
    "t120": "INTEGRADO CON",
    "t121": "Cualquier extensión",
    "t122": "Cualquier email",
    "t123": "Cualquier código",
    "t124": "SOPORTE",
    "t125": "Soporte por email",
    "t126": "Soporte prioritario",
    "t127": "Soporte urgente",
    "t128": "¿Y si necesito más para analizar?",
    "t129": "Puedes cambiar de plan. Pero si eso escala demasiado tus necesidades puedes escoger en función de más gráficas, más preguntas, más add-ons para tus representaciones de interfaz, más clicks, más GBs de datos... Tus extras se sumarán a tu cuota existente. Podrás cancelar en cualquier momento. Y si necesitas un plan personalizado, contacta con ventas.",
    "t130": "Gráficas",
    "t131": "Pack 10",
    "t132": "15€",
    "t133": "Preguntas",
    "t134": "Pack 20",
    "t135": "10€",
    "t136": "Add-on",
    "t137": "Extra",
    "t138": "10€",
    "t139": "Mapa de calor",
    "t140": "Click",
    "t141": "0.5€",
    "t142": "GBs de Cloud",
    "t143": "GB",
    "t144": "0.5€",
    "t145": "Invitado",
    "t146": "Extra",
    "t147": "5€",
    "t148": "Firma electrónica",
    "t149": "Escáner de documentos",

  }
  
  export default pricing