import apiBackend from '@src/apiBackend'
import { createAsyncThunk } from '@reduxjs/toolkit';


import {
  setChunk,

  setIsLoad,
} from '../slices/gameSlice'


export const readChunk =
  createAsyncThunk('game/readChunk',
    async ({ id, text }, { dispatch }) => {
      try {

        dispatch(setIsLoad(false))
        const tokenGPT = localStorage.getItem("token-gpt")
        const token = localStorage.getItem("token")

        const formData = new FormData()
        formData.append("image", null)

        formData.append("tokenGPT", tokenGPT)
        formData.append("token", token)
        formData.append("id", id)
        formData.append("text", text)

        fetch('http://localhost:3001/service/v1/game/chunk', {
          method: 'POST',
          body: formData,
        })
          .then(response => {
            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            const processStream = async () => {
              while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                let chunk = decoder.decode(value, { stream: true });
                const obj = { ...chunk.split('\n').filter(Boolean).map(item => JSON.parse(item))[0] }

                dispatch(setChunk(obj.data))
              }
            };

            processStream().then(() => {
              dispatch(setIsLoad(false))
            }).catch(console.error);
          })
          .catch(error => {
            console.error('Error:', error);
          });


      } catch (error) {
        console.log('Error', error)
        throw error;
      }
    }
  );






export const readEmbedding =
  createAsyncThunk('game/readEmbedding',
    async ({ id, text }, { dispatch }) => {
      try {

        dispatch(setIsLoad(false))
        const tokenGPT = localStorage.getItem("token-gpt")
        const token = localStorage.getItem("token")

        const formData = new FormData()
        formData.append("image", null)

        formData.append("tokenGPT", tokenGPT)
        formData.append("token", token)
        formData.append("id", id)
        formData.append("text", text)

        const response = await fetch('http://localhost:3001/service/v1/game/embedding', {
          method: 'POST',
          body: formData,
        })

        const data = await response.json();


        dispatch(setChunk({
          id: id, 
          input: {
            token: data.token, 
            price: data.price
          },
          output: {
            price: 0,
            token: 0
          },
          text: JSON.stringify(data.data)
        }))

        dispatch(setIsLoad(false))
        
      } catch (error) {
        console.log('Error', error)
        throw error;
      }
    }
  );






  export const readUrl =
  createAsyncThunk('game/readUrl',
    async ({ id, text }, { dispatch }) => {
      try {

        dispatch(setIsLoad(false))
        const tokenGPT = localStorage.getItem("token-gpt")
        const token = localStorage.getItem("token")

        const formData = new FormData()
        formData.append("image", null)

        formData.append("tokenGPT", tokenGPT)
        formData.append("token", token)
        formData.append("id", id)
        formData.append("text", text)

        fetch('http://localhost:3001/service/v1/game/url', {
          method: 'POST',
          body: formData,
        })
          .then(response => {
            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            const processStream = async () => {
              while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                let chunk = decoder.decode(value, { stream: true });
                const obj = { ...chunk.split('\n').filter(Boolean).map(item => JSON.parse(item))[0] }

                let data = {
                  id,
                  type: 'website-spider',
                  ...obj.data
                }

                dispatch(setChunk(data))
              }
            };

            processStream().then(() => {
              dispatch(setIsLoad(false))
            }).catch(console.error);
          })
          .catch(error => {
            console.error('Fetch error:', error);
          });


      } catch (error) {
        console.log('Error', error)
        throw error;
      }
    }
  );



  export const readWebsite =
  createAsyncThunk('game/readWebsite',
    async ({ id, text }, { dispatch }) => {
      try {

        const tokenGPT = localStorage.getItem("token-gpt")
        const token = localStorage.getItem("token")

        const formData = new FormData()
        formData.append("image", null)

        formData.append("tokenGPT", tokenGPT)
        formData.append("token", token)
        formData.append("id", id)
        formData.append("text", text)

        fetch('http://localhost:3001/service/v1/game/website', {
          method: 'POST',
          body: formData,
        })
          .then(response => {
            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            const processStream = async () => {
              while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                let chunk = decoder.decode(value, { stream: true });
                const obj = { ...chunk.split('\n').filter(Boolean).map(item => JSON.parse(item))[0] }

                let data = {
                  id,
                  type: 'website',
                  ...obj.data
                }

                dispatch(setChunk(data))
              }
            };

            processStream().then(() => {
              dispatch(setIsLoad(false))
            }).catch(console.error);
          })
          .catch(error => {
            console.error('Error error:', error);
          });


      } catch (error) {
        console.log('Error', error)
        throw error;
      }
    }
  );



  export const readWeb =
  createAsyncThunk('game/readWeb',
    async ({ id, text }, { dispatch }) => {
      try {

        dispatch(setIsLoad(false))
        const tokenGPT = localStorage.getItem("token-gpt")
        const token = localStorage.getItem("token")

        const formData = new FormData()
        formData.append("image", null)

        formData.append("tokenGPT", tokenGPT)
        formData.append("token", token)
        formData.append("id", id)
        formData.append("text", text)

        fetch('http://localhost:3001/service/v1/game/web', {
          method: 'POST',
          body: formData,
        })
          .then(response => {
            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            const processStream = async () => {
              while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                let chunk = decoder.decode(value, { stream: true });
                const obj = { ...chunk.split('\n').filter(Boolean).map(item => JSON.parse(item))[0] }

                let data = {
                  id,
                  type: 'website-embed',
                  ...obj.data
                }

                dispatch(setChunk(data))
              }
            };

            processStream().then(() => {
              dispatch(setIsLoad(false))
            }).catch(console.error);
          })
          .catch(error => {
            console.error('Fetch error:', error);
          });


      } catch (error) {
        console.log('Error', error)
        throw error;
      }
    }
  );


  export const readContact =
  createAsyncThunk('game/readContact',
    async ({ id, text }, { dispatch }) => {
      try {

        dispatch(setIsLoad(false))
        const tokenGPT = localStorage.getItem("token-gpt")
        const token = localStorage.getItem("token")

        const formData = new FormData()
        formData.append("image", null)

        formData.append("tokenGPT", tokenGPT)
        formData.append("token", token)
        formData.append("id", id)
        formData.append("text", text)

        fetch('http://localhost:3001/service/v1/game/contact', {
          method: 'POST',
          body: formData,
        })
          .then(response => {
            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            const processStream = async () => {
              while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                let chunk = decoder.decode(value, { stream: true });
                const obj = { ...chunk.split('\n').filter(Boolean).map(item => JSON.parse(item))[0] }

                let data = {
                  id,
                  type: 'website-contact',
                  ...obj.data
                }

                dispatch(setChunk(data))
              }
            };

            processStream().then(() => {
              dispatch(setIsLoad(false))
            }).catch(console.error);
          })
          .catch(error => {
            console.error('Error:', error);
          });


      } catch (error) {
        console.log('Error', error)
        throw error;
      }
    }
  );


  export const readSemantic =
  createAsyncThunk('game/readSemantic',
    async ({ id, text }, { dispatch }) => {
      try {

        dispatch(setIsLoad(false))
        const tokenGPT = localStorage.getItem("token-gpt")
        const token = localStorage.getItem("token")

        const formData = new FormData()
        formData.append("image", null)

        formData.append("tokenGPT", tokenGPT)
        formData.append("token", token)
        formData.append("id", id)
        formData.append("text", text)

        fetch('http://localhost:3001/service/v1/game/semantic', {
          method: 'POST',
          body: formData,
        })
          .then(response => {
            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            const processStream = async () => {
              while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                let chunk = decoder.decode(value, { stream: true });
                const obj = { ...chunk.split('\n').filter(Boolean).map(item => JSON.parse(item))[0] }

                dispatch(setChunk(obj.data))
              }
            };

            processStream().then(() => {
              dispatch(setIsLoad(false))
            }).catch(console.error);
          })
          .catch(error => {
            console.error('Error error:', error);
          });


      } catch (error) {
        console.log('Error', error)
        throw error;
      }
    }
  );