const iam = {
    login: {
        t1: `Logga in`,
        t2: `och få tillgång till din personliga utrymme`,
        t3: `ELLER`,
        t4: `Kom ihåg mig`,
        t5: `Glömt lösenord?`,
        t6: `LOGGA IN`,
        t7: `Har du inte ett konto?`,
        t8: `Registrera dig`
    },
    table: {
        t1: `Vi har inte hittat några data i din vektorbaserade databas`,
        t2: `Skapa`,
        t3: `Snabbstartdokumentation`,
    },
    header: {
        t1: `Gå tillbaka`,
        t2: `Identitets- och åtkomsthantering (IAM)`,
        t3: `Användare`,
        t4: `Applikationer`,
        t5: `Policys`,
        t6: `API-nycklar`,
        t7: `Loggar`,
    },
    users: {
        t1: `Här visas en lista över användare i denna organisation. 
            Du kan se mer information om varje användare.`,
        t2: `Vad är användare`,
        t3: `Lägg till användare`,
        t4: `Bjud in användare`,
        t5: `Ange e-postadressen för varje användare du vill bjuda in, separerad med komma.`,
        t6: `Ange värdefulla etiketter`,
        t7: `Värdefulla etiketter hjälper dig att organisera dina användare. Du kan tilldela upp till 10 etiketter per användare.`,
        t8: `Lägg till i en befintlig grupp (valfritt)`,
        t9: `Bjud in`,
        t10: `Användarinformation`,
        t11: `Status`,
        t12: `Aktiverad`,
        t13: `Typ`,
        t14: `Inbjuden`,
        t15: `Gick med`,
        t16: `Senaste åtkomst`,
        t17: `Ta bort användare`,
        t18: `När du tar bort en användare från denna organisation, tas deras API-nycklar och alla direkt associerade policys bort.`,
        t19: `Ta bort användare`,
    },
    apps: {
        t1: `Här visas en lista över applikationer i denna organisation. Du kan se mer information om varje applikation.`,
        t2: `Vad är applikationer?`,
        t3: `Skapa app`,
        t4: `Applikationer`,
        t5: `Skapa en applikation`,
        t6: `Ange ett namn och en valfri beskrivning`,
        t7: `Namn`,
        t8: `Ditt applikationsnamn kan bara innehålla alfanumeriska tecken, punkter och bindestreck.`,
        t9: `Beskrivning`,
        t10: `Ange värdefulla etiketter (valfritt)`,
        t11: `Värdefulla etiketter hjälper dig att organisera dina applikationer. Du kan tilldela upp till 10 etiketter per applikation.`,
        t12: `Ange värdefulla etiketter (valfritt)`,
        t13: `Skapa applikation`,
    },
    polices: {
        t1: `Detta är en lista över policys i denna organisation. Du kan se mer information om varje policy.`,
        t2: `Vad är policys?`,
        t3: `Skapa policy`,
        t4: `Policys`,
        t5: `Regler`,
        t6: `En regel består av ett omfång (t.ex. projekt- eller organisationsnivå) och ett eller flera behörighetssätt (t.ex. "räkna upp alla instanser").`,
        t7: `Få mer information om reglerna.`,
        t8: `Regel #1`,
        t9: `Gör`,
        t10: `Organisation`,
        t11: `Behörighetssätt för IAM och fakturering kan bara väljas på organisationsnivå.`,
        t12: `Åtkomst till resurser`,
        t13: `Ger åtkomst till resurser (instanser, objektlagring, databaser, etc.) i dina projekt.`,
        t14: `Åtkomst till organisationsfunktioner`,
        t15: `Ger åtkomst till IAM, fakturering, support och missbruksbiljetter och projektledning, allt hanterat på organisationsnivå.`,
        t16: `Validera`,
        t17: `Behörighetssätt`,
        t18: `Organisationsledare`,
        t19: `Lägg till regel`,
    },
    apis: {
        t1: `Här visas en lista över API-nycklar i denna organisation.`,
        t2: `Hur skapar jag API-nycklar?`,
        t3: `Generera API-nyckel`,
        t4: `API`,
        t5: `Generera en API-nyckel`,
        t6: `Kopiera och spara din hemliga nyckel. Den visas bara en gång.`,
        t7: `Hantera dina vektorer genom IAM-åtkomst genom att lägga till dina
            API-nycklar för att använda dem`,
        t8: `Åtkomst-ID`,
        t9: `Hemlig nyckel`,
        t10: `Stäng generera nyckel`,
        t11: `Generera en API-nyckel`,
        t12: `Välj bärare av API-nyckel`,
        t13: `Jag själv (IAM-användare)`,
        t14: `En applikation`,
        t15: `Beskrivning`,
        t16: `Utgång`,
        t17: `Kommer denna API-nyckel att användas för objektlagring?`,
        t18: `Jag själv (IAM-användare)`,
        t19: `Jag själv (IAM-användare)`,
        t20: `Generera API-nyckel`,
        t21: `Stäng`,
    },
    logs: {
        t1: `Här visas en lista över dina IAM-resursloggar. IAM-resurser kan vara användare, applikationer, grupper, API-nycklar och IAM-policys. Du kan få mer information om varje.`,
        t2: `Hur förstår jag mina loggar?`,
        t3: `Filtrera på exakt IAM-resurs-ID`,
        t4: `Datum`,
        t5: `IAM-datum`,
        t6: `Kategori`,
        t7: `kategori`,
        t8: `Åtgärd`,
        t9: `Åtgärd`,
        t10: `Lägg till`,
        t11: `Återställ`,
        t12: `Filtrera`,
        t13: `Loggar`,
        t14: `Logginformation`,
        t15: `Status`,
        t16: `Aktiverad`,
        t17: `Typ`,
        t18: `Inbjuden`,
        t19: `Gick med`,
        t20: `Senaste åtkomst`,
        t21: `Ta bort logg`,
        t22: `När du tar bort en logg från denna organisation, tas deras API-nycklar och alla direkt associerade policys bort.`,
        t23: `Ta bort logg`,
    },
}

export default iam