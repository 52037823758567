import React from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "@home/context/Context";
import { useNavigate, useParams } from "react-router-dom";

import styles from '../../index.module.css'

const HeaderTop = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {lng} = useParams()

  const { toggleTop, setToggle } = useAppContext();


  const handleOpen = () => {
    navigate(`/${lng}/register`)
  }

  return (
    <>
      <div
        className={`${styles["header-top-news"]} ${styles["bg-image1"]} ${toggleTop ? "" : styles.desactive}`}
      >
        <div className={`${styles.wrapper}`} >
          <div className={styles.container} >
            <div className={`${styles.row}`} >
              <div className={`${styles["col-lg-12"]}`} >
                <div className={`${styles.inner}`} >
                  <div className={`${styles.content}`} >
                    <span className={`${styles["rainbow-badge"]} ${styles["d-none"]} ${styles["d-lg-block"]}`} >
                      
                      {t("home1.header.topbar.text1")}
                      </span>
                    <span className={styles["news-text"]} >
                      {t("home1.header.topbar.text2")}
                     
                    </span>
                  </div>
                  <div className={`${styles["right-button"]} ${styles["d-none"]} ${styles["d-lg-block"]} `} >
                    <button 
                    className={`${styles["btn-read-more"]}`} 
                    onClick={() => handleOpen()}
                    >
                      <span>
                      {t("home1.header.topbar.text3")}{" "}
                        <i className={`${styles["fa-sharp"]} ${styles["fa-regular"]} ${styles["fa-arrow-right"]}`} ></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles["icon-close"]} >
          <button
            className={`${styles["close-button"]} ${styles["bgsection-activation"]}`}
            onClick={() => setToggle(!toggleTop)}
          >
            <svg  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18 17.94 6M18 18 6.06 6" />
            </svg>

          </button>
        </div>
      </div>
    </>
  );
};

export default HeaderTop;
