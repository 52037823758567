const support = {
  t1: `Instrucciones de Aythen`,
  t2: `Ayudas y guias`,
  t22: `Aythen Lite Tools`,
  t33: `Tickets abiertos`,
  t3: `Acuerdo de Nivel de Servicio (SLA) es establecer la disponibilidad de la plataforma de software`,
  t4: `Contacta con nosotros`,
  t5: `Crea un ticket de soporte`,
  t6: `Obtén ayuda directamente de nuestro equipo.`,
  t7: `Administrar incidencias`,
  t8: `Comprueba tu licencia así como tus tickets abiertos y cerrados.`,
  t9: `¿Quieres saber más?`,
  t10: `¿Conoces Aythen PRO?`,
  t11: `Entra aquí para entenderlo y regístrate en nuestra lista de espera`,
  t12: `Monitoreo y Reporte`,
  t13: `Envíanos tus recomendaciones para saber con qué más te gustaría trabajar tus datos.`,
  topics: {
    1: {
      title: "Cookies y Privacidad",
      data: {
        1: {
          text: `Las cookies son pequeños archivos que se almacenan en tu navegador cuando visitas un sitio web, y se utilizan para recordar información sobre ti, como tus preferencias o actividades. En Europa, hay leyes muy estrictas sobre cómo se manejan las cookies y los datos personales, como el Reglamento General de Protección de Datos (RGPD). Esto garantiza que las empresas deban tener tu consentimiento antes de usar tus datos y aseguran que se manejen de forma responsable.`,
        },
        2: {
          title: `Legislación europea vs. nubes tradicionales`,
          text: `La privacidad de los datos es un tema serio y está protegida por regulaciones, las nubes tradicionales a menudo están sujetas a leyes más permisivas o prácticamente nulas por ser gestionadas por gobiernos de terceros, como por ejempo USA o China. Por eso, usar plataformas certificadas en Europa ofrece una mayor protección y tranquilidad para los usuarios y sus datos.`,
        },
        3: {
          title: `Certificaciones ISO`,
          text: `Scaleway cuenta con certificaciones clave como ISO 27001 (para la gestión de seguridad de la información) e ISO 9001 (para la gestión de calidad). Estas certificaciones demuestran que Scaleway sigue procesos estrictos para mantener los datos seguros y bien gestionados. Es como tener un sello de garantía que asegura que tus datos están en buenas manos y protegidos contra posibles amenazas.`,
        },
        4: {
          title: `Mayor tranquilidad`,
          text: `Al elegir una nube europea como Scaleway, tus datos están más seguros porque no solo siguen la normativa local, sino que también se almacenan en centros de datos europeos certificados. Esto asegura que tus datos no sean vulnerables a leyes extranjeras, como ocurre en las nubes americanas, lo que significa que tienes más control sobre tu privacidad y seguridad.`,
        },
        5: {
          title: `Compromiso ecológico`,
          text: `Además de ser seguros, los centros de datos de Scaleway son también ecológicos. Utilizan búnkeres para controlar la temperatura, así como paneles solares, refrigeración por agua y otras tecnologías que implican un uso 100% de energías renovables. Esto no solo asegura que los servidores funcionen de manera eficiente, sino que también se está comprometido con el medioambiente.`,
        },
      },
    },
    2: {
      title: `Bases Vectorizadas`,
      data: {
        1: {
          text: `Seguramente nunca hayas oído hablar de este tipo de bases de datos. Y, aunque sea un tema técnico, siempre es interesante entender y saber en qué se basan los cimientos de la estructura de datos. Por ello, te dejamos con una descripción para contextualizar qué son y para qué sirven.`,
        },
        2: {
          title: `¿Qué son las bases de datos vectorizadas?`,
          text: `Imagina un archivo PNG (una imagen estática) y un SVG (una imagen que puedes ampliar o modificar sin perder calidad). Un PNG es fijo, como las bases de datos tradicionales, donde los datos están organizados en tablas con filas y columnas, y no cambian. Por otro lado, un SVG es flexible, puedes modificarlo fácilmente, parecido a cómo funcionan las bases de datos vectorizadas. Estas bases usan vectores (unidades de información) que permiten almacenar y manipular datos de forma mucho más dinámica, ya que no están limitados a filas y columnas, sino a dimensiones que pueden representar cualquier tipo de dato: números, texto, imágenes o incluso código.`,
        },
        3: {
          title: `¿Cómo funcionan?`,
          text: `Las bases de datos vectorizadas organizan la información en un espacio multidimensional. Cada "dimensión" es como una coordenada en un mapa, lo que significa que puedes trabajar con muchos datos a la vez, de forma más rápida y eficiente. Imagina que tienes una gran cantidad de información, pero solo necesitas ciertos datos relevantes. En lugar de procesar todo el conjunto, como harías con una tabla tradicional, las bases vectorizadas segmentan los datos, organizándolos en grupos llamados clusters. Así, solo se manipula lo que es necesario, optimizando el tiempo y el rendimiento.`,
        },
        4: {
          title: `¿Para qué sirven?`,
          text: `Este tipo de bases de datos es perfecto para manejar grandes volúmenes de datos, como los que se utilizan en inteligencia artificial (IA) o machine learning (ML). Los agentes, que son programas o procesos, interactúan con estos datos en diferentes dimensiones, recuperando y procesando solo la información relevante. Esto permite realizar cálculos complejos y consultas avanzadas de manera más eficiente y sin la latencia típica de las bases de datos tradicionales.`,
        },
        5: {
          title: `¿Por qué son importantes para la IA?`,
          text: `Las bases vectorizadas son fundamentales para Big Data y el desarrollo de IA. Permiten manejar datos en formatos y volúmenes masivos de forma flexible y rápida, ideal para alimentar algoritmos de machine learning o procesamientos de lenguaje natural (NLP). Al igual que el SVG se ajusta dinámicamente a tus necesidades, las bases vectorizadas hacen lo mismo con los datos, proporcionando la estructura perfecta para que los sistemas de IA aprendan y evolucionen de forma eficiente.`,
        },
      },
    },
    3: {
      title: `Tu Cloud privado`,
      data: {
        1: {
          text: `Aunque trabajes gracias a la computación en la nube (Cloud) y te ofrezcamos Scaleway como nuestra opción preferida, tu siempre vas a tener la última palabra. Es decir, `,
        },
        2: {
          title: `Conecta tu propio cloud (self-hosted)`,
          text: `Es posible que ya tengas tu proveedor de confianza, por los motivos que sean, o que tengas un Cloud privado en forma de Rack en tu oficina, por ello puedes instalar y gestionar tus propios servidores, controlando todo el proceso. Esto significa que puedes tener el control total de tus datos y aplicaciones, sin depender de terceros. Todo lo que necesitas es un servidor privado o hardware dedicado y puedes configurar una "nube" donde solo tú o tu equipo tienen acceso.`,
        },
        3: {
          title: `Independencia y control total`,
          text: `Con Sclaeway o con tu propio servidor, siempre tendrás tus instancias dedicadas y privadas a configurar según su potencia de procesamiento. Puedes personalizar y ajustar todo según las necesidades de tu negocio o proyecto. Ya sea que trabajes en diseño gráfico, videojuegos o programación, puedes adaptar de forma independiente cada aplicación interna con instancias personalizadas para no depender de tu hardware. Es decir, puedes dividir tus actividades en diferentes servidores permitiendo realizar diferentes tareas sin sobrecargar un solo sistema, optimizando así tus actividades.`,
        },
        4: {
          title: `Escalabilidad y flexibilidad`,
          text: `El Cloud te permite escalar tus recursos fácilmente según las necesidades de tu proyecto. Si de repente necesitas más potencia de procesamiento o almacenamiento, puedes ampliarlo sin tener que invertir en más hardware físico. Esto es especialmente útil si trabajas en proyectos que pueden variar en demanda, como cuando lanzas un nuevo proyecto o necesitas hacer pruebas intensivas de software. Con el cloud, puedes ajustar los recursos en tiempo real, lo que te ahorra costos y tiempo.`,
        },
        5: {
          title: `Colaboración y acceso remoto`,
          text: `Otra gran ventaja del Cloud es la posibilidad de trabajar desde cualquier lugar y colaborar con tu equipo en tiempo real desde cualquier dispositivo. Ya no necesitas estar en la misma oficina o tener acceso físico a un servidor o a tu ordenador con Windows. Gracias a internet tendrás acceso a tu escritorio, a tu nube, a tus datos y por tanto a todo lo que necesitas. Accesible desde cualquier dispositivo con conexión a Internet, mejoramos la productividad y la colaboración.`,
        },
      },
    },
    4: {
      title: `Escritorio único`,
      data: {
        1: {
          title: `Acceso desde cualquier lugar`,
          text: `Con un escritorio Cloud único, tu espacio de trabajo está disponible desde cualquier lugar del mundo, sin importar si estás usando tu computadora, una tablet o un teléfono. Solo necesitas conexión a internet, y tendrás acceso a todos tus programas, archivos y configuraciones como si estuvieras en tu oficina o en casa. Esto es perfecto para quienes necesitan trabajar de forma remota o moverse entre diferentes dispositivos.`,
        },
        2: {
          title: `Seguridad y respaldo constante`,
          text: `Todo lo que hagas en tu escritorio Cloud está siempre respaldado de manera automática en servidores seguros. Si tu computadora se daña, se pierde o falla, no tienes que preocuparte de perder tus archivos o programas, porque todo sigue seguro en la nube. Esto te protege contra posibles fallos y asegura que siempre tengas acceso a tus datos sin interrupciones.`,
        },
        3: {
          title: `Personalización a medida`,
          text: `Un escritorio Cloud único por usuario se adapta a tus necesidades y preferencias. Todos tus programas, herramientas y configuraciones personales están siempre a tu disposición, lo que significa que no importa en qué dispositivo trabajes, siempre verás tu escritorio tal como lo dejaste. No necesitas reconfigurar nada ni instalar programas nuevos cada vez que cambies de equipo.`,
        },
        4: {
          title: `Ahorro de recursos y actualizaciones automáticas`,
          text: `En lugar de gastar dinero en comprar computadoras potentes para cada usuario, el escritorio Cloud permite que se utilicen recursos del servidor. Esto significa que puedes trabajar en proyectos exigentes sin depender de una computadora cara o potente. Además, las actualizaciones del sistema o programas se realizan de forma automática en la nube, sin que tú tengas que preocuparte por mantener todo al día.`,
        },
      },
    },
    5: {
      title: `Tarifas por consumo`,
      data: {
        1: {
          text: `Las tarifas por consumo básicamente se basan en pagar por lo que usas. Es como cuando compras pan: si llevas dos barras, pagas por esas dos; si llevas cinco, pagas por cinco. En el mundo digital y de servicios, esto funciona igual: si usas más servicios, pagas más. No es una tarifa fija, sino que va cambiando dependiendo de cuánto consumes.`,
        },
        2: {
          title: `Aythen y la tarjeta "serverless"`,
          text: `En Aythen, usamos una tarjeta especial que está vinculada a tu ID personal. Esta tarjeta te permite pagar en base al uso de los servicios, como si fuera una tarjeta de prepago así como un acceso seguro de tu identidad. En lugar de pagar todo al inicio, vas gastando saldo conforme interactúas con los servicios, o también puedes gestionar tu consumo mediante paquetes de servicios, lo que es como comprar en cantidad para obtener un mejor precio.`,
        },
        3: {
          title: `Más sobre las tarjetas`,
          text: `Además, esta tarjeta está conectada a diferentes APIs (interfaces que permiten conectar con otros sistemas), como bancos, Repsol (para la gasolina), Ticket Restaurante (para tus comidas), entre otros. Esto te da una experiencia integrada donde puedes hacer pagos o recibir beneficios en varios sitios, todo gestionado a través de Aythen.`,
        },
      },
    },
    6: {
      title: `Videotutoriales`,
      data: {
        1: {
          text: `En el futuro, en Aythen haremos hacer que la experiencia de los usuarios sea aún más intuitiva y accesible mediante el uso de videotutoriales interactivos. Estos videos complementarán los tutoriales escritos y las guías paso a paso, permitiendo a los usuarios entender de forma visual y dinámica cómo funciona cada aspecto de la plataforma.`,
        },
        2: {
          title: `Demostraciones visuales`,
          text: `En lugar de leer solo instrucciones, los usuarios podrán ver directamente cómo usar la plataforma. Verán en pantalla cómo se hacen las interacciones, cómo se navega por el sistema y cómo funcionan las herramientas clave.`,
        },
        3: {
          title: `Aprendizaje paso a paso`,
          text: `Los usuarios podrán seguir los videotutoriales mientras utilizan Aythen, deteniendo el video cuando necesiten y retomando en cualquier momento. Esto les permitirá aprender al ritmo que prefieran, sin prisas.`,
        },
        4: {
          title: `Ejemplos prácticos y casos de uso`,
          text: `Los videos mostrarán casos prácticos sobre cómo aprovechar mejor las funciones, cómo gestionar sus tarjetas, paquetes o saldo de manera eficiente, y cómo sacarle provecho a las integraciones con otras plataformas (bancos, Repsol, etc.)`,
        },
        5: {
          title: `Tutoriales personalizados según el nivel del usuario`,
          text: `Los videotutoriales estarán clasificados en diferentes niveles, desde básico hasta avanzado, para que cada usuario reciba la explicación que más le convenga, dependiendo de su experiencia o familiaridad con la plataforma.`,
        },
      },
    },
  },
  mvps: {
    1: {
      title: "Escritorio Cloud",
      data: {
        1: {
          text: `El escritorio en el sistema operativo Cloud de Aythen actúa como la interfaz principal tras iniciar sesión. En él vas a poder encontrar`,
        },
        2: {
          title: `Acceso remoto`,
          text: `Entra desde cualquier dispositivo, sea de una biblioteca o un sobremesa de los 90’ o con el portátil de viaje, solo necesitarás estar conectado a internet. Acceder a tus datos a través del “Home” de Aythen, allí puedes encontrar todas las herramientas que tengas activadas.`,
        },
        3: {
          title: `Interfaz personalizada`,
          text: `Cambia de color y personaliza el fondo de pantalla gracias a la generación de imágenes inteligente. Escribe qué lienzo quieres pintar y plasma tu idea con una sola descripción gracias a la IA.`,
        },
        4: {
          title: `Multi-ventana`,
          text: `Sea un archivo, una carpeta, un Dashboard, una App o un juego que te apetezca jugar, tendrás tu acceso directo disponible. Solo debes configurar qué quieres guardar para que aparezca como acceso directo. `,
        },
        5: {
          title: `Tu máquina virtual`,
          text: `Tanto para Apple, Microsoft o Linux tus datos y tus juegos del escritorio pueden ser compatibles con cualquier potencia. Instala el servidor que necesites y aumenta el rendimiento de tu ordenador gracias a la computación en la nube.`,
        },
      },
    },
    2: {
      title: "Carpetas Inteligentes",
      data: {
        1: {
          text: `Las carpetas inteligentes permiten organizar archivos y datos dentro del sistema operativo para trabajar los datos de cualquier documento. Escoge diferentes tipos de carpetas según tus necesidades de procesamiento y/o automatización. `,
        },
        2: {
          title: `Sincronización`,
          text: `Los archivos de tu ordenador, de tu Gmail, de tu Drive, Outlook, etc. se pueden sincronizar automáticamente, sin necesidad de migraciones, por lo que puedes etiquetar según tipo de documento para configurar las variables que contienen. `,
        },
        3: {
          title: `Estructura directorios`,
          text: `Configura de forma inteligente donde quieres guardar ese documento, en qué apartado, subcarpeta o ruta concreta, todo automatizado para ordenar de forma fácil.`,
        },
        4: {
          title: `Reconoce los datos`,
          text: `Cada tipo de documento y sector tiene sus variables, hay quien trabaja con pacientes y otros con referencias de producto. Para cada situación, puedes reconocer qué #etiqueta necesitas reconocer. `,
        },
        5: {
          title: `Conexión con terceros`,
          text: `Vincula los datos sincronizados, reconocidos y almacenados con terceros. Es decir, autorellena un Excel con la información, una alerta, un XML para conectar a tu ERP, un TXT con tu banco, etc.`,
        },
      },
    },
    3: {
      title: "OCR dinámico",
      data: {
        1: {
          text: `Aythen te ofrece, junto con el escritorio y la carpeta inteligente, la capacidad de identificar cualquier tipo de datos en cualquier documento o sitio URL.`,
        },
        2: {
          title: `Detección Automática`,
          text: `Pueden incluir tendencias numéricas, palabras clave, imágenes, relaciones entre datos y otros elementos que puedan ser relevantes para el análisis. Esto permite descubrir insights valiosos sin tener que realizar búsquedas manuales.`,
        },
        3: {
          title: `Procesado eficaz`,
          text: `Agiliza el análisis documental, reduciendo el tiempo necesario para revisar grandes volúmenes de información. Aythen optimiza la gestión de datos al extraer información relevante en cuestión de segundos, facilitando la toma de decisiones en tiempo real.`,
        },
        4: {
          title: `Múltiples Formatos`,
          text: `Puedes incorporar cualquier extensión de información, desde un Word, pasando por una fotografía, un PDF, un Excel, etc. Detecta y guarda la información de tu interés según tu gestión documental.`,
        },
        5: {
          title: `Extracción a elección`,
          text: `Configura solo 1 vez y automatiza para siempre cuáles son los datos de interés y déjalo en modo autopilot para que los datos trabajen por ti. Además, podrás modificar y cambiar todas las variables que quieras para un reconocimiento a medida de tus datos.`,
        },
      },
    },
    4: {
      title: "Calendario - Meet",
      data: {
        1: {
          text: `El calendario combinado con la videollamada, son herramientas troncales para la gestión del tiempo y la organización en cualquier proyecto, emprendimiento o simplemente vida diaria. Combinado con la videollamada, un combo .`,
        },
        2: {
          title: `Eventos`,
          text: `Los eventos ya no son solo anotaciones estáticas en el calendario, sino que se convierten en puntos de encuentro virtuales. Al crear un evento, automáticamente se puede incluir un enlace a una videollamada, lo que permite que todos los asistentes tengan acceso a la reunión sin la necesidad de intercambiar enlaces de forma separada. `,
        },
        3: {
          title: `Multi-agenda`,
          text: `Potencia la organización en múltiples áreas de la vida (trabajo, personal, estudios) sin perder de vista ninguna responsabilidad. Al combinar esta funcionalidad con videollamadas integradas, pueden gestionar múltiples calendarios con múltiples propósitos gracias formatos tanto diario, mensual, formato agenda… Todo lo necesario para  evitar confusiones .`,
        },
        4: {
          title: `Voice to Text`,
          text: `Capacidad para identificar y procesar la voz humana, transformándola en texto de manera automática. Puede diferenciar entre múltiples voces en una conversación o en un entorno con varias personas hablando.`,
        },
        5: {
          title: `Salas multifuncionales`,
          text: `Realiza salas de reuniones donde poder activar diferentes herramientas como el compartir y grabar pantalla, chats, y demás funciones necesarias para una correcta comunicación durante el desarrollo de cualquier proyecto. Haciendo así una interacción en tiempo real entre el hablar y el crear, fomentando por tanto una participación activa.`,
        },
      },
    },
    5: {
      title: "Tareas y seguimiento",
      data: {
        1: {
          title: `Gestión visual de proyectos`,
          text: `Organiza proyectos mediante tableros, listas y tarjetas, ofreciendo una representación visual intuitiva del flujo de trabajo. Los tableros representan proyectos, los tickets definen las tareas del proyecto y las listas son las sub-tareas individuales. Esto facilita la planificación y el seguimiento del progreso, permitiendo que los equipos colaboren fácilmente.`,
        },
        2: {
          title: `Colaboración en tiempo real`,
          text: `Trello permite que múltiples usuarios trabajen simultáneamente en los mismos tableros, asignando tareas, añadiendo comentarios, adjuntando archivos y mencionando a miembros del equipo para mejorar la comunicación. Todo ocurre de manera colaborativa y en tiempo real, lo que refuerza la transparencia y la coordinación entre los equipos.`,
        },
        3: {
          title: `Automatización generativa`,
          text: `Con solo proporcionar una descripción general del proyecto en formato de texto serás capaz de generar automáticamente tareas detalladas, tiempos estimados (timings) y listas de tareas específicas para cada fase de cualquier proyecto. Esto elimina la necesidad de organizar manualmente cada detalle y permite que los equipos comiencen a trabajar con una estructura mínima y organizada basada en la inteligencia artificial.`,
        },
        4: {
          title: `Flexibilidad y personalización`,
          text: `Altamente adaptable a diferentes metodologías de trabajo (Kanban, Scrum), con la capacidad de personalizar cada tablero según las necesidades del proyecto. Asignar prioridades, establecer fechas límite, agregar checklists y utilizar etiquetas, todo lo necesario para crear proyectos de trabajo óptimos de manera automatizada.`,
        },
      },
    },
    6: {
      title: "Nodos Inteligentes",
      data: {
        1: {
          text: `Imagina los nodos inteligentes como pequeños centros de información o "cajas negras" que contienen una variedad de datos y herramientas interconectadas. Son muy versátiles y pueden integrar diferentes tipos de contenidos, lo que los hace ideales para analizar y conectar información de manera más eficiente. Aquí te explico en 4 puntos cómo funcionan.`,
        },
        2: {
          title: `Contenedores de múltiples formatos`,
          text: `Estos nodos pueden almacenar todo tipo de información, desde fotos, gráficos, y tablas de Excel, hasta PDFs, códigos incrustados y preguntas. Son como cajones donde puedes guardar cualquier tipo de dato que necesites, todo en un solo lugar.`,
        },
        3: {
          title: `Interacción entre datos distintos`,
          text: `Lo interesante es que no solo almacenan información, sino que permiten relacionarla entre sí. Por ejemplo, puedes vincular una gráfica con una hoja de Excel, o conectar un código con un PDF. Los nodos inteligentes "hablan" entre ellos, cruzando información de diferentes tipos para darte una visión más completa.`,
        },
        4: {
          title: `Análisis en profundidad`,
          text: `Al funcionar como "cajas negras", estos nodos recopilan datos y te permiten analizarlos de forma más profunda. Todo lo que guardes en ellos está disponible para ser desmenuzado o relacionado con otros nodos, facilitando la extracción de insights o patrones complejos que, de otro modo, podrían pasar desapercibidos.`,
        },
        5: {
          title: `Sincronización y flexibilidad`,
          text: `Lo mejor es que estos nodos inteligentes se sincronizan automáticamente, manteniendo la información actualizada en todo momento. Puedes modificar un Excel, agregar una nueva gráfica o incluir un fragmento de código, y todos los elementos relacionados se ajustarán en consecuencia. Esto te da un enorme margen de maniobra para trabajar con datos de diferentes tipos de forma fluida.`,
        },
      },
    },
    7: {
      title: "Business Intelligence",
      data: {
        1: {
          text: `Los nodos inteligentes permiten crear un entorno de Business Intelligence en el que la información recopilada, relacionada y analizada se convierte en una poderosa herramienta para tomar decisiones informadas. Aquí te explico cómo funciona en 4 puntos clave, incorporando elementos de análisis de datos avanzados.`,
        },
        2: {
          title: `Recopilación de datos diversa e integración con múltiples fuentes`,
          text: `Los nodos inteligentes funcionan como contenedores que almacenan y organizan información de múltiples formatos y fuentes, como imágenes, gráficos, hojas de cálculo, PDFs, y código. Además, se pueden extraer datos desde diferentes sistemas y plataformas, lo que permite centralizar toda la información relevante del negocio en un solo lugar.`,
        },
        3: {
          title: `Relaciones entre la información y análisis avanzado`,
          text: `No solo guardan los datos, sino que los nodos permiten crear conexiones entre ellos. Puedes vincular un gráfico con una hoja de Excel o un código con un documento, lo que genera relaciones profundas entre diferentes tipos de información. Herramientas avanzadas de análisis, como dashboards automáticos, gráficos dinámicos e informes, aprovechan estas conexiones para ofrecer análisis automatizados, que facilitan la comprensión de la situación del negocio.`,
        },
        4: {
          title: `Predicción y tendencias`,
          text: `A partir de la información almacenada, los nodos inteligentes pueden utilizar inteligencia artificial para predecir tendencias futuras. Analizando datos históricos y actuales, la herramienta genera informes predictivos que permiten anticiparse a cambios en el mercado o en el rendimiento del negocio, proporcionando una ventaja competitiva.`,
        },
        5: {
          title: `Visualización interactiva y toma de decisiones informadas`,
          text: `Los usuarios pueden personalizar visualizaciones interactivas, como gráficos y reportes, para explorar los datos de manera intuitiva. Al tener un acceso claro y visual a la información relacionada, se facilita la toma de decisiones basada en hechos concretos, permitiendo obtener conclusiones sólidas y mejorar la estrategia del negocio.`,
        },
      },
    },
    8: {
      title: "Facturas y firmas",
      data: {
        1: {
          text: `Facilita la gestión de tu facturación y la firma digital de documentos para crear relaciones duraderas con tu círculo.`,
        },
        2: {
          title: `Envío y programación de pagos`,
          text: `Permite realizar pagos a proveedores o colaboradores de forma manual o automática, según la modalidad que se configure. Esto significa que puedes definir fechas específicas para enviar pagos sin necesidad de intervenir cada vez, asegurando que se cumplan los plazos de manera eficiente.`,
        },
        3: {
          title: `Reconocimiento y envío de facturas`,
          text: `Identifica y procesa facturas automáticamente. Cuando recibes una factura, el sistema la reconoce, la clasifica, y puedes enviarla directamente a los clientes. Esto agiliza el proceso de facturación, ahorrando tiempo y reduciendo errores al no tener que manejar las facturas de manera manual.`,
        },
        4: {
          title: `Firma digital de contratos`,
          text: `Firma documentos de forma digital. Gestiona cualquier tipo de información y conviértela en contratos  (laborales, comerciales, etc.) para facilitar que tanto tú como el cliente o colaborador firmen sin necesidad de estar presentes físicamente. Esto optimiza los tiempos de firma y aprobación entre los diferentes intereses.`,
        },
        5: {
          title: `Trazabilidad y registro`,
          text: `Con la trazabilidad necesaria garantiza el lugar, la identidad y la veracidad de la firma tanto de contratos como la gestión de las facturas en tu lista de clientes/proveedores. Esto te ayudará a centralizar y recordar las relaciones de tu negocio.`,
        },
      },
    },
    9: {
      title: "Asistentes con IA local",
      data: {
        1: {
          text: `Los asistentes con inteligencia artificial proporcionan ayuda automatizada en el uso del sistema. Son como un "ayudante personal" que trabaja dentro de la misma herramienta que usas.`
        },
        2: {
          title: `Integración con herramientas externas`,
          text: `No solo funcionan dentro de la plataforma, sino que también se podrán integrar con otras aplicaciones que ya utilizas (como correo electrónico, calendarios o servicios de terceros). Los bots podrán interactuar con estas herramientas para automatizar tareas y hacer que todo esté conectado, facilitando aún más tu trabajo.`
        },
        3: {
          title: `Privacidad y uso de datos propios`,
          text: `Lo interesante es que podrás crear estos bots usando tus propios datos, de forma completamente privada. Es como tener una "piscina de datos" privada que solo tú o tu empresa controlan. Esto asegura que los bots trabajen con información sensible sin depender de datos externos o servicios de terceros, manteniendo todo dentro de un entorno seguro.`
        },
        3: {
          title: `Retroalimentación e inteligencia artificial local`,
          text: `A medida que los bots usan esos datos privados, el sistema va aprendiendo, mejorando su inteligencia y adaptándose a las necesidades específicas de tu negocio. Esta retroalimentación es un proceso de Machine Learning (ML) que permite que los asistentes se vuelvan cada vez más eficientes, creando una IA local personalizada que evoluciona sin compartir información con otras plataformas.`
        }
      }
    }
  }
}

export default support