import React, { useEffect } from "react";

import Sal from "sal.js";

import ServiceData from "@home/assets/constants/serviceStyle.json";

import styles from '../index.module.css'

const ServiceTwo = () => {
  useEffect(() => {
    Sal();
  }, []);
  return (
    <>
      {ServiceData &&
        ServiceData.serviceTwo.map((data, index) => (
          <div
            className={`${styles["col-lg-4"]} ${styles["col-md-6"]} ${styles["col-sm-6"]} ${styles["col-12"]} ${styles["sal-animate"]}`} 
            data-sal="slide-up"
            data-sal-duration="700"
            key={index}
          >
            <div className={`${styles.service} ${styles["service__style--1"]} ${styles["aiwave-style"]} ${styles["text-center"]}`} >
              <div className={styles.icon} >
                <img
                  src={data.img}
                  width={91}
                  height={90}
                  alt="Servece img"
                />
              </div>
              <div className={styles.content} >
                <h4 className={`${styles.title} ${styles['w-600']}`} >{data.title}</h4>
                <p className={`${styles.description} ${styles.b1} ${styles["mb--0"]}`} >{data.desc}</p>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default ServiceTwo;
