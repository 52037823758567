import React from 'react'

import SectionImage from './assets/creditMachine.png'
import DragNavImage from './assets/drag-nav.png'

import Card1Image from './assets/img-1.png'
import Card2Image from './assets/img-2.png'
import Card4Image from './assets/img-4.png'
import Card5Image from './assets/img-5.png'
import Card6Image from './assets/img-6.png'

const DataSection = () => {
  return (
    <div className="relative w-full sm:h-full lg:pb-[200px]" id="data">
      <div className="container mx-auto">
        <section className="home-section-1 ">
          <div className="data-gradient"></div>
          <div className="flex">
            <div className="relative lg:pt-[10px] z-[0]">
              <div className="mb-5 flex items-center ml-[-8px] absolute left-0 top-[10px] w-[300px]">
                <div className="bg-[#F00] z-[-1]  w-[40px] h-[40px] p-2 flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 43 40"
                    fill="none"
                  >
                    <path
                      d="M28.9404 17.0588L37.2346 8.70588L35.5875 7.05883L28.9404 13.7059L25.5875 10.3529L23.9404 12.0588L28.9404 17.0588ZM-0.000732422 40V35.2941H28.2346V40H-0.000732422ZM30.5875 23.5294C27.3326 23.5294 24.5577 22.382 22.2628 20.0871C19.9679 17.7922 18.8212 15.018 18.8228 11.7647C18.8228 8.50981 19.9702 5.7349 22.2652 3.44C24.5601 1.1451 27.3342 -0.00156702 30.5875 1.6072e-06C33.8424 1.6072e-06 36.6173 1.14745 38.9122 3.44235C41.2071 5.73726 42.3538 8.51137 42.3522 11.7647C42.3522 15.0196 41.2048 17.7945 38.9099 20.0894C36.615 22.3843 33.8408 23.531 30.5875 23.5294ZM-0.000732422 21.1765V16.4706H14.8228C15.0973 17.3333 15.411 18.1569 15.764 18.9412C16.1169 19.7255 16.5483 20.4706 17.0581 21.1765H-0.000732422ZM-0.000732422 30.5882V25.8824H22.1169C23.0189 26.4314 23.9797 26.8926 24.9993 27.2659C26.0189 27.6392 27.0973 27.9035 28.2346 28.0588V30.5882H-0.000732422Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="ml-4">
                  <h4 className="text-[#F00] font-extrabold uppercase text-[20px]">
                    DATA
                  </h4>
                </div>
              </div>
              <div className="data-left-border mt-[70px]"></div>
            </div>
            <div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="relative">
                  <div className="w-full md:mt-[100px]">
                    <div className="lg:pl-10">
                      <p className="text-[20px] text-[#554D56] lg:text-[48px] leading-tight drop-shadow-xl text-left pl-7 lg:pl-0">
                        <span className="text-[#F00]">
                          Crea los datos de tu proyecto.
                        </span>
                        Define los modelos de tus datos en hojas de cálculo
                        inteligentes. Ahorra tiempo para interactuar y
                        estructurar tus datos dentro y fuera de tu proyecto de
                        manera fácil y gestionada.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="relative">
                  <div className="lg:flex lg:absolute top-[-150px] left-[-100px] ">
                    <div className="flex justify-center mt-[-100px] mt-[0px] ">
                      <img
                        src={SectionImage}
                        className="w-[200px] lg:w-[75%] "
                      />
                    </div>
                    <div className="flex justify-end items-end">
                      <img
                        src={DragNavImage}
                        className="lg:w-[100px] w-[60px]"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="lg:mt-[50px]">
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3">
                  <div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mb-8 lg:mb-0">
                      <p className="mt-0 lg:text-[13px] uppercase">PRIMER BENEFICIO</p>
                      <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                        Organización Centralizada
                      </h5>
                      <div className="lg:w-[90%]">
                        <p className="lg:text-[15px] mt-5">
                          Utilizar una hoja de cálculo o una base de datos
                          interna permite centralizar toda la información
                          relevante del proyecto en un solo lugar. Esto
                          simplifica la gestión y evita la dispersión de datos
                          en múltiples ubicaciones.
                        </p>
                        <img
                          src={Card1Image}
                          className="lg:absolute bottom-[0px] left-[0px] w-[200px] lg:w-[50%] z-20"
                        />
                      </div>
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mt-5 mb-8 lg:mb-0">
                      <div>
                        <p className="lg:text-[13px] uppercase">
                          Cuarto Beneficio
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Generación de Informes
                        </h5>
                      </div>
                      <div className="lg:w-[90%]">
                        <p className="lg:text-[15px]">
                          Las hojas de cálculo internas permiten crear informes
                          detallados y personalizados con gráficos, tablas y
                          resúmenes visuales. Esto mejora la presentación de la
                          información y facilita la toma de decisiones.
                        </p>
                      </div>
                      <img
                        src={Card4Image}
                        className="lg:absolute bottom-[-50px] right-[-20px] w-[200px] lg:w-[50%] z-20"
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mb-8 lg:mb-0">
                      <p className="mt-0 lg:text-[13px] uppercase">
                        SEGUNDO BENEFICIO
                      </p>
                      <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                        Business Intelligence
                      </h5>
                      <div className="lg:w-[90%] lg:flex items-start">
                        <p className="lg:text-[15px] lg:w-[50%]">
                          Un data o Excel interno proporciona herramientas para
                          realizar análisis y cálculos complejos en los datos
                          recopilados. Las funciones y fórmulas permiten
                          explorar patrones, tendencias y relaciones clave en
                          los datos del proyecto.
                        </p>
                        <img
                          src={Card2Image}
                          className=" z-20 w-[200px] lg:w-[100%] lg:ml-[-80px] lg:mr-[-100px]"
                        />
                      </div>
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative mt-5 mb-8 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          Quinto Beneficio
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light">
                          Historial de Versiones
                        </h5>
                      </div>
                      <div className="lg:w-[80%]">
                        <p className="lg:text-[15px]">
                          Las hojas de cálculo y bases de datos internas pueden
                          mantener un historial de versiones, lo que permite
                          rastrear cambios y revisiones en los datos a lo largo
                          del tiempo.
                        </p>
                      </div>
                      <img
                        src={Card5Image}
                        className="z-20 lg:absolute bottom-[-80px] right-[0%] w-[200px] lg:w-[70%]"
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 relative h-[auto] mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="lg:text-[13px] uppercase">Quinto Beneficio</p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Personalización
                        </h5>
                        <p className="lg:text-[15px] mt-3">
                          Los datos y las hojas de cálculo pueden ser
                          personalizados según las necesidades específicas del
                          proyecto y del equipo. Esto permite adaptar la
                          estructura de los datos y los informes para satisfacer
                          requisitos particulares.
                        </p>
                      </div>
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[auto] lg:h-[450px] relative  mt-5 mb-8 lg:mb-0">
                      <div className="lg:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">SEXTO beneficio</p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light">
                          Planificación y Programación
                        </h5>
                        <p className="lg:text-[15px] mt-3">
                          Puedes utilizar hojas de cálculo internas para
                          planificar y programar tareas y recursos en el
                          proyecto. Esto ayuda a mantener un registro organizado
                          de la asignación de recursos y los plazos.
                        </p>
                      </div>
                      <img
                        src={Card6Image}
                        className="z-20 lg:absolute bottom-[0px] left-[20%] w-[200px] lg:w-[50%]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default DataSection
