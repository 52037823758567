import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addPageToSelectedPremiumSheetData,
  setSelectedPremiumSheet,
  setSelectedPremiumSheetData,
  setSelectedPremiumSheetPage,
} from "../slices/datatableSlices";

export const createScalewayInstanceDB = createAsyncThunk(
  "/data/createScalewayInstanceDB",
  async (body) => {

  }
);

export const deleteScalewayInstanceDB = createAsyncThunk(
  "/data/deleteScalewayInstanceDB",
  async (id) => {
 
  }
);

export const getScalewayInstance = createAsyncThunk(
  "/data/getScalewayInstance",
  async (userId) => {

  }
);

export const addDatabaseToScalewayInstance = createAsyncThunk(
  "/data/addDatabaseToScalewayInstance",
  async (body, thunkAPI) => {

  }
);

export const getPremiumSheetData = createAsyncThunk(
  "/data/getPremiumSheetData",
  async (database) => {
    function convertToOldFormat(newData) {
      const oldFormatData = [];

      newData.forEach((page, index) => {
        const oldPage = {
          id: index + 1,
          additionalColumns: page[index].additionalColumns,
          renamedColumns: page[index].renamedColumns,
          labels: page[index].labels,
        };

        page.forEach((rowObj, rowIndex) => {
          Object.keys(rowObj).forEach((key) => {
            if (
              key !== "id" &&
              key !== "createdAt" &&
              key !== "updatedAt" &&
              key !== "additionalColumns" &&
              key !== "renamedColumns" &&
              key !== "labels" &&
              key !== "row" &&
              key !== "pageId"
            ) {
              if (!oldPage[key]) {
                oldPage[key] = {};
              }

              oldPage[key][(rowIndex + 1).toString()] = {
                id: rowObj.id,
                pageId: rowObj.pageId,
                row: rowObj.row,
                value: rowObj[key].value,
                styles: rowObj[key].styles,
              };
            }
          });
        });
        oldFormatData.push(oldPage);
      });
      return oldFormatData;
    }

  }
);
export const getEditorSheetData = createAsyncThunk(
  "/data/getEditorSheetData",
  async (database) => {
    function convertToOldFormat(newData) {
      const oldFormatData = [];

      newData.forEach((page, index) => {
        const oldPage = {
          id: index + 1,
          additionalColumns: page[index].additionalColumns,
          renamedColumns: page[index].renamedColumns,
          labels: page[index].labels,
        };

        page.forEach((rowObj, rowIndex) => {
          Object.keys(rowObj).forEach((key) => {
            if (
              key !== "id" &&
              key !== "createdAt" &&
              key !== "updatedAt" &&
              key !== "additionalColumns" &&
              key !== "renamedColumns" &&
              key !== "labels" &&
              key !== "row" &&
              key !== "pageId"
            ) {
              if (!oldPage[key]) {
                oldPage[key] = {};
              }

              oldPage[key][(rowIndex + 1).toString()] = {
                id: rowObj.id,
                pageId: rowObj.pageId,
                row: rowObj.row,
                value: rowObj[key].value,
                styles: rowObj[key].styles,
              };
            }
          });
        });
        oldFormatData.push(oldPage);
      });
      return oldFormatData;
    }


  }
);

export const insertColumn = createAsyncThunk(
  "/data/insertColumn",
  async (body) => {

  }
);
export const moveRow = createAsyncThunk("/data/moveRow", async (body) => {

});
export const moveColumn = createAsyncThunk("/data/moveColumn", async (body) => {

});
export const renameColumn = createAsyncThunk(
  "/data/renameColumn",
  async (body) => {

  }
);
export const changeLabels = createAsyncThunk(
  "/data/changeLabels",
  async (body) => {

  }
);
export const insertRow = createAsyncThunk("/data/insertRow", async (body) => {

});

export const deleteRow = createAsyncThunk("/data/deleteRow", async (body) => {

});
export const deleteColumn = createAsyncThunk(
  "/data/deleteColumn",
  async (body) => {

  }
);

export const deleteInstanceDatabase = createAsyncThunk(
  "/data/deleteInstanceDatabase",
  async (databaseData) => {
  }
);

export const getAllInstanceDatabases = createAsyncThunk(
  "/data/getAllInstanceDatabases",
  async (instanceId) => {

  }
);

export const addPageToPremiumDatabase = createAsyncThunk(
  "/data/addPageToPremiumDatabase",
  async (body, thunkAPI) => {

  }
);
export const updateCell = createAsyncThunk("/data/updateCell", async (body) => {

});
export const updateCellStyles = createAsyncThunk(
  "/data/updateCellStyles",
  async (body) => {

  }
);

export const applyYjsVersion = createAsyncThunk(
  "/data/applyYjsVersion",
  async (data) => {
  }
);

export const selectPremiumSheet = (sheetId) => (dispatch) =>
  dispatch(setSelectedPremiumSheet(sheetId));

export const addSinglePageToDatabase = (page) => (dispatch) =>
  dispatch(addPageToSelectedPremiumSheetData(page));

export const emptyPremiumSheetData = () => (dispatch) =>
  dispatch(setSelectedPremiumSheetData(null));

export const selectPremiumSheetPage = (page) => (dispatch) =>
  dispatch(setSelectedPremiumSheetPage(page));
