import apiBackend from '@src/apiBackend.js'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const createNewDesktop = createAsyncThunk(
	'desktop/createDesktop',
	async ({ userId }, { rejectWithValue }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/desktop/create',
				{ userId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			return res.data
		} catch (error) {
			return rejectWithValue(error.response?.data || 'Failed to create desktop')
		}
	}
)

export const getUserDesktops = createAsyncThunk(
	'desktop/getUserDesktops',
	async ({ userId }, { rejectWithValue }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.get(`/desktop/user/${userId}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			return res.data
		} catch (error) {
			return rejectWithValue(error.response?.data || 'Failed to fetch desktops')
		}
	}
)

export const updateUserDesktop = createAsyncThunk(
	'desktop/updateUserDesktop',
	async ({ userId, desktopId, toUpdate }, { rejectWithValue }) => {
		console.log('on updateUserDesktop with', toUpdate)
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.put(
				`/desktop/update`,
				{ userId, toUpdate, desktopId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			return res.data
		} catch (error) {
			return rejectWithValue(error.response?.data || 'Failed to update desktop')
		}
	}
)

export const updateDesktopSettings = createAsyncThunk(
	'desktop/updateDesktopSettings',
	async ({ userId, toUpdate }, { rejectWithValue }) => {
		console.log('on updateDesktopSettings with', toUpdate)
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.put(
				`/desktop/settings/update`,
				{ userId, toUpdate },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			return res.data
		} catch (error) {
			return rejectWithValue(
				error.response?.data || 'Failed to update desktop settings'
			)
		}
	}
)

export const deleteUserDesktop = createAsyncThunk(
	'desktop/deleteUserDesktop',
	async ({ userId, desktopId }, { rejectWithValue }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.delete(`/desktop/delete`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				data: { userId, desktopId },
			})
			return { desktopId, ...res.data }
		} catch (error) {
			return rejectWithValue(error.response?.data || 'Failed to delete desktop')
		}
	}
)
