import { createAction, createSlice } from '@reduxjs/toolkit'
import {
	createGptChat,
	createMeet,
	deleteMeet,
	deleteMessage,
	dislikeMessage,
	getMeetData,
	getMeetMessages,
	getUserMeets,
	leaveMeet,
	likeMessage,
	partialUpdateMeetBot,
	sendMessage,
	sendMessageToGptChat,
	setMessagesToReaded,
	updateMeet,
} from '../actions/meet'
import { getAllUsers } from '../actions/iam'
import { meetBot } from '../actions/bot'

export const meetSlices = createSlice({
	name: 'meets',
	initialState: {
		socketId: null,
		joinedRoom: null,
		allUsers: [],
		userMeets: [],
		userGptChats: [],
		selectedSubChatMessages: [],
		showMeetOptionsPopup: false,
		selectedMeet: null,
		selectedMeetGptChats: [],
		botLoading: false,
		selectedGptChat: null,
		joinedVideoChat: null,
		selectedMeetMessages: [],
		regenerateParams: null,
	},
	reducers: {
		setRegenerateParams(state, action) {
			state.regenerateParams = action.payload
		},
		setUserMeets(state, action) {
			state.userMeets = action.payload
		},
		setJoinedRoom(state, action) {
			state.joinedRoom = action.payload
		},
		setShowMeetOptionsPopup(state, action) {
			state.showMeetOptionsPopup = action.payload
		},
		setSelectedMeet(state, action) {
			state.selectedMeet = action.payload
			state.selectedGptChat = null
			state.selectedMeetGptChats = []
			state.selectedSubChatMessages = []
			if (action.payload.messages) {
				state.selectedMeetMessages = action.payload.messages
			}
			if (action.payload.gptChats) {
				state.selectedMeetGptChats = action.payload.gptChats
			}
		},
		setSelectedMeetMessages(state, action) {
			state.selectedMeetMessages = action.payload
		},
		setBotLoading(state, action) {
			state.botLoading = action.payload
		},
		setSelectedSubChatMessages(state, action) {
			state.selectedSubChatMessages = action.payload
		},
		setSelectedGptChat(state, action) {
			state.selectedGptChat = action.payload
			if (action.payload && action.payload.messages) {
				state.selectedSubChatMessages = action.payload.messages
			}
		},
		setSocketId(state, action) {
			state.socketId = action.payload
		},
		setJoinedVideoChat(state, action) {
			state.joinedVideoChat = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllUsers.pending, (state) => {
				state.loading = true
			})
			.addCase(getAllUsers.fulfilled, (state, action) => {
				state.loading = false
				if (action.payload) {
					state.allUsers = action.payload
				}
			})
			.addCase(getAllUsers.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(getMeetData.pending, (state) => {
				state.loading = true
			})
			.addCase(getMeetData.fulfilled, (state, action) => {
				state.loading = false
				if (state.selectedMeet && action.payload.id === state.selectedMeet.id) {
					state.selectedMeet = action.payload
				}
			})
			.addCase(getMeetData.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(updateMeet.pending, (state) => {
				state.loading = true
			})
			.addCase(updateMeet.fulfilled, (state, action) => {
				state.loading = false
				if (action.payload && state.userMeets) {
					const chatIndex = state?.userMeets?.findIndex(
						(meet) => meet.id === action.payload.id
					)

					if (chatIndex !== -1) {
						state.userMeets[chatIndex] = action.payload
					}
					if (action?.payload?.id === state?.selectedMeet?.id) {
						state.selectedMeet = action.payload
					}
				}
			})
			.addCase(updateMeet.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(createGptChat.pending, (state) => {
				state.loading = true
			})
			.addCase(createGptChat.fulfilled, (state, action) => {
				state.loading = false
				state.selectedGptChat = action.payload
				state.selectedSubChatMessages = action.payload.messages
				state.userGptChats = [...state.userGptChats, action.payload]
				state.selectedMeetGptChats = [
					...state.selectedMeetGptChats,
					action.payload,
				]

				const chatIndex = state.userMeets.findIndex(
					(meet) => meet.id === action.payload.meetId
				)

				if (chatIndex !== -1) {
					state.userMeets[chatIndex].gptChats =
						state.userMeets[chatIndex].gptChats || []
					state.userMeets[chatIndex].gptChats = [
						...state.userMeets[chatIndex].gptChats,
						action.payload,
					]
				}
			})

			.addCase(createGptChat.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(getUserMeets.pending, (state) => {
				state.loading = true
			})
			.addCase(getUserMeets.fulfilled, (state, action) => {
				state.loading = false
				state.userMeets = action.payload
		
				state.userGptChats = action.payload
					.map((meet) => meet.gptChats || [])
					.flat()
			})
			.addCase(getUserMeets.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(createMeet.pending, (state) => {
				state.loading = true
			})
			.addCase(createMeet.fulfilled, (state, action) => {
		
				state.loading = false
				state.userMeets = [...state.userMeets, action.payload]
			})
			.addCase(createMeet.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(deleteMeet.pending, (state) => {
				state.loading = true
			})
			.addCase(deleteMeet.fulfilled, (state, action) => {
				state.loading = false
				if (action.payload) {
					state.userMeets = [...state.userMeets].filter(
						(meet) => meet.id !== action.payload
					)
					state.selectedMeet = null
					state.selectedGptChat = null
					state.selectedMeetGptChats = []
					state.selectedSubChatMessages = []
					state.selectedMeetMessages = []
					state.selectedMeetGptChats = []
				}
			})
			.addCase(deleteMeet.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(leaveMeet.pending, (state) => {
				state.loading = true
			})
			.addCase(leaveMeet.fulfilled, (state, action) => {
				state.loading = false
				state.userMeets = [...state.userMeets].filter(
					(meet) => meet.id !== action.payload
				)
			})
			.addCase(leaveMeet.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(getMeetMessages.pending, (state) => {
				state.loading = true
			})
			.addCase(getMeetMessages.fulfilled, (state, action) => {
				state.loading = false
				state.selectedMeetMessages = action.payload
			})
			.addCase(getMeetMessages.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(sendMessage.pending, (state) => {
				state.loading = true
			})
			.addCase(sendMessage.fulfilled, (state, action) => {
				state.selectedMeetMessages = action.payload
				state.loading = false
			})

			.addCase(sendMessage.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(likeMessage.pending, (state) => {
				state.loading = true
			})
			.addCase(likeMessage.fulfilled, (state, action) => {
				state.selectedMeetMessages = action.payload
				state.loading = false
			})

			.addCase(likeMessage.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(dislikeMessage.pending, (state) => {
				state.loading = true
			})
			.addCase(dislikeMessage.fulfilled, (state, action) => {
				state.selectedMeetMessages = action.payload
				state.loading = false
			})

			.addCase(dislikeMessage.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(deleteMessage.pending, (state) => {
				state.loading = true
			})
			.addCase(deleteMessage.fulfilled, (state, action) => {
				state.selectedMeetMessages = action.payload
				state.loading = false
			})

			.addCase(deleteMessage.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(sendMessageToGptChat.pending, (state) => {
				state.loading = true
			})
			.addCase(sendMessageToGptChat.fulfilled, (state, action) => {
				const chatIndex = state.userGptChats.findIndex(
					(chat) => chat.id === action.payload.gptChatId
				)

				if (chatIndex !== -1) {
					state.userGptChats[chatIndex].messages = action.payload.messages
				}
				state.selectedSubChatMessages = action.payload.messages
			})
			.addCase(sendMessageToGptChat.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(setMessagesToReaded.pending, (state) => {
				state.loading = true
			})
			.addCase(setMessagesToReaded.fulfilled, (state, action) => {
				state.loading = false
			})
			.addCase(setMessagesToReaded.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(meetBot.pending, (state) => {
				state.botLoading = true
				state.loading = true
			})
			.addCase(meetBot.fulfilled, (state, action) => {
				state.loading = false

				if (typeof action.payload !== 'object') {
					state.selectedMeetMessages = action.payload // Set all messages when streaming ends
				}
			})
			.addCase(meetBot.rejected, (state, action) => {
				state.error = action.error.message
				state.loading = false
			})
			.addCase(partialUpdateMeetBot, (state, action) => {
				const {
					messageId,
					newContent,
					meetId,
					type,
					prompt,
					regenerate,
					isImage,
				} = action.payload

				let updatedMessages = [...state.selectedMeetMessages]

				let existingMessageIndex = updatedMessages.findIndex(
					(msg) => msg.id === messageId
				)

				if (existingMessageIndex !== -1) {
					if (regenerate && !isImage) {
						updatedMessages[existingMessageIndex] = {
							...updatedMessages[existingMessageIndex],
							message: newContent,
						}
					} else if (regenerate && isImage) {
						updatedMessages[existingMessageIndex] = {
							...updatedMessages[existingMessageIndex],
							message: 'Aqui tienes otra imagen!.',
							files: [{ data: newContent, type: 'png' }],
						}
					} else {
						updatedMessages[existingMessageIndex] = {
							...updatedMessages[existingMessageIndex],
							message:
								updatedMessages[existingMessageIndex].message + newContent,
						}
					}
				} else {
					const newMessage = {
						id: messageId,
						type,
						senderId: `bot-textToText-${meetId}`,
						senderName: 'Aythen BOT',
						senderPicture: '',
						message: newContent,
						createdAt: new Date().toISOString(),
						readedBy: [],
						prompt,
					}
					if (isImage) {
						newMessage.message = 'Aqui tienes tu imagen:'
						newMessage.files = [{ data: newContent, type: 'png' }]
					}
					updatedMessages.push(newMessage)
				}

				state.selectedMeetMessages = updatedMessages
			})
	},
})

export const {
	setUserMeets,
	setSelectedMeet,
	setShowMeetOptionsPopup,
	setSelectedMeetMessages,
	setSocketId,
	setJoinedVideoChat,
	setSelectedSubChatMessages,
	setBotLoading,
	setSelectedGptChat,
	setJoinedRoom,
	setRegenerateParams,
} = meetSlices.actions

export default meetSlices.reducer
