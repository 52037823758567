const vector = {
    t1: `Skapa en ny vektor för ditt projekt`,
    t2: `Importera, spara, arbeta, analysera och dra slutsatser från vilken datastruktur som helst. 
Lagra och manipulera informationen med total frihet genom multidimensionella utrymmen. `,
    t3: `Ny vektor`,
    t4: `Vektorer`,
    t5: `Vektor`,
    t6: `Dimension`,
    t7: `Storlek`,
    t9: `Skapad`,
    t10: `Matris`,
    t11: `Vektorbaserade databaser möjliggör unika upplevelser drivna av vektorbaserad sökning.  
De accelererar utvecklingen av AI-applikationer.`,
    t12: `Ny Matris`,
    t13: `Snabbstartdokumentation`,
    node: {
        data: {
            title: `Lägg till data i din rapport`,
            text: `När de är laddade, kommer dina data att visas i`,
            label: `Fält`,
            excel: `Importera data från Excel`,
            sql: `Importera data från SQL`,
            blank: `Klistra in tom data`,
            export: `Exportera exempeldata`,
            info: `Hämta data från en annan källa`,
        }
    },
    dashboard: {
        t1: `Kom ihåg att du alltid kan ändra och samla grafer som du vill framhäva 
som favoriter från den information du har bestämt att analysera i vektorerna.`,
        t2: `Läs mer`,
    }
}

export default vector