// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputContainer_GtEip {
	width: 100%;
	height: 46px;
	align-items: center;
	justify-content: flex-start;
	border-radius: 6px;
	border: 1px solid var(--border-color);
	padding: 0px 9px;
	gap: 5px;
	flex-direction: row;
	display: flex;
}
.icon_zUAzT {
	height: 100%;
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.input_t04VY {
	display: flex;
	height: 100%;
	width: 100%;
	outline: none;
	background: transparent;
	color: var(--text-color);
	border: none;
	font-size: 16px;
	align-items: center;
	justify-content: center;
	text-align: flex-start;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
`, "",{"version":3,"sources":["webpack://./src/views/app/v1-1/pages/Meet/components/OutlinedInput.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,YAAY;CACZ,mBAAmB;CACnB,2BAA2B;CAC3B,kBAAkB;CAClB,qCAAqC;CACrC,gBAAgB;CAChB,QAAQ;CACR,mBAAmB;CACnB,aAAa;AACd;AACA;CACC,YAAY;CACZ,WAAW;CACX,aAAa;CACb,mBAAmB;CACnB,uBAAuB;AACxB;AACA;CACC,aAAa;CACb,YAAY;CACZ,WAAW;CACX,aAAa;CACb,uBAAuB;CACvB,wBAAwB;CACxB,YAAY;CACZ,eAAe;CACf,mBAAmB;CACnB,uBAAuB;CACvB,sBAAsB;CACtB,kBAAkB;CAClB,gBAAgB;CAChB,mBAAmB;AACpB","sourcesContent":[".inputContainer {\n\twidth: 100%;\n\theight: 46px;\n\talign-items: center;\n\tjustify-content: flex-start;\n\tborder-radius: 6px;\n\tborder: 1px solid var(--border-color);\n\tpadding: 0px 9px;\n\tgap: 5px;\n\tflex-direction: row;\n\tdisplay: flex;\n}\n.icon {\n\theight: 100%;\n\twidth: 30px;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n}\n.input {\n\tdisplay: flex;\n\theight: 100%;\n\twidth: 100%;\n\toutline: none;\n\tbackground: transparent;\n\tcolor: var(--text-color);\n\tborder: none;\n\tfont-size: 16px;\n\talign-items: center;\n\tjustify-content: center;\n\ttext-align: flex-start;\n\tfont-style: normal;\n\tfont-weight: 400;\n\tline-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `inputContainer_GtEip`,
	"icon": `icon_zUAzT`,
	"input": `input_t04VY`
};
export default ___CSS_LOADER_EXPORT___;
