const bank = {
    t1: `Общий доход`,
    t2: `Общий результат`,
    t3: `Управляйте своими доступами`,
    t4: `Найти счет-фактуру`,
    t5: `Найти дату`,
    t6: `Моя карта`,
    t7: `Баланс карты`,
    t8: `Состояние`,
    t9: `Активна`,
    t10: `Управлять`,
    t11: `Перевести`,
    t12: `Поделитесь и получите бонус, привлекая всех своих контактов`,
    t13: `Безопасность`,
    t14: `Активная карта`,
    t15: `Безопасный доступ`,
    t16: `Изменить`,
    t17: `Информация`,
    t18: `Местоположение`,
    t19: `Адрес`,
    t20: `Кошелек`,

    cardlist: {
        "t0": "Карты",
        "t1": "Добавить",
        "t2": "Добавьте свою платежную карту безопасно",
        "t3": "После добавления карты вы сможете управлять своими платежами и активными подписками.",
        "t4": "Добавить карту",
        "t5": "Введите данные своей кредитной карты для безопасных платежей.",
        "t6": "Посмотреть больше",
        "t7": "Имя",
        "t8": "Номер карты",
        "t9": "CVC",
        "t10": "Срок действия",
        "t11": "Принять карту",
        "t12": "Отменить",
        "t13": "Запросить новую карту",
        "t14": "Вы получите новую карту",
        "t15": "Отправлено от Aythen",
        "t16": "Испания",
        "t17": "10903",
        "t18": "Санта-Перпетуа-де-Могода",
        "t19": "Это адрес, по которому вы получите карту",
        "t20": "Это будет стоить 10* Это будет стоить Это будет стоить Это будет стоить",
        "t21": "Запросить карту"
    }
}

export default bank