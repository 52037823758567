import React, { useRef, useEffect, useState } from "react";
import ReactNipple from "react-nipple";
import { usePosition } from "./PositionContext";
import styles from "./Joystick.module.css";
import collisionSound from "./collision.mp3";  // Importa el cuarto sonido

const Joystick = () => {
  const { setPosition, setDirection, direction, walls } = usePosition();
  const [speedLevel, setSpeedLevel] = useState(1); // Nivel de velocidad inicial
  const animationRef = useRef(null);
  const collisionAudioRef = useRef(null); 

  useEffect(() => {
    const updatePosition = () => {
      setPosition((prevPosition) => {
        const newTop = prevPosition.top - direction.deltaY;
        const newLeft = prevPosition.left + direction.deltaX;

        if (isCollidingWithWall(newTop, newLeft) ||
            newTop < 0 ||
            newTop > 4000 - 50 || 
            newLeft < 0 ||
            newLeft > 4000 - 50) { 
          playAudio(collisionAudioRef); 
          return prevPosition;
        }

        return { top: newTop, left: newLeft };
      });

      animationRef.current = requestAnimationFrame(updatePosition);
    };

    if (direction.deltaX !== 0 || direction.deltaY !== 0) {
      animationRef.current = requestAnimationFrame(updatePosition);
    } else {
      cancelAnimationFrame(animationRef.current);
    }

    return () => cancelAnimationFrame(animationRef.current);
  }, [direction, setPosition]);

  const handleJoystickMove = (evt, data) => {
    if (data && data.distance) {
      let speedMultiplier;
      switch (speedLevel) {
        case 2:
          speedMultiplier = data.distance < 30 ? 2 : data.distance < 60 ? 4 : 6;
          break;
        case 3:
          speedMultiplier = data.distance < 30 ? 4 : data.distance < 60 ? 6 : 8;
          break;
        default:
          speedMultiplier = data.distance < 30 ? 1 : data.distance < 60 ? 2 : 4;
          break;
      }

      const deltaX = Math.cos(data.angle.radian) * speedMultiplier;
      const deltaY = Math.sin(data.angle.radian) * speedMultiplier;

      setDirection({ deltaX, deltaY });
    }
  };

  const handleJoystickEnd = () => {
    setDirection({ deltaX: 0, deltaY: 0 });
    cancelAnimationFrame(animationRef.current);
  };

  const isCollidingWithWall = (newTop, newLeft) => {
    return walls.some((wall) => 
      newTop < wall.top + wall.height &&
      newTop + 50 > wall.top &&
      newLeft < wall.left + wall.width &&
      newLeft + 50 > wall.left
    );
  };

  const playAudio = (audioRef) => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    }
  };

  return (
    <div className={styles.joystickContainer}>
      <ReactNipple
        className={styles.joystick}
        options={{
          mode: "static",
          color: "hsl(219, 84%, 56%)",
          position: { top: "50%", left: "50%" },
          size: 150,
          threshold: 0.1,
        }}
        onMove={handleJoystickMove}
        onEnd={handleJoystickEnd}
      />
      <div className={styles.speedControls}>
        <button onClick={() => setSpeedLevel(1)}>Baja</button>
        <button onClick={() => setSpeedLevel(2)}>Media</button>
        <button onClick={() => setSpeedLevel(3)}>Alta</button>
      </div>
      <audio ref={collisionAudioRef} src={collisionSound} />
    </div>
  );
};

export default Joystick;
