import apiBackend from '@src/apiBackend'
import { createAsyncThunk } from '@reduxjs/toolkit';


import {
  setChunkBoard,

  setChunkRepo,
  setPrevIsLoad,
} from '../slices/erpSlice'



export const deleteNavsERP =
  createAsyncThunk('erp/deleteNavsERP',
    async ({ id, navId }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')

        const res = await apiBackend.delete(
          `/erp/${id}/navs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            id: navId
          },
        })

        return res.data
      } catch (error) {
        console.log('Error delete navs:', error)
      }
    })


export const addNavsERP =
  createAsyncThunk('erp/addNavsERP',
    async ({ id, nav }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          '/erp/navs', {
          id,
          nav
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );


export const fetchNavsERP =
  createAsyncThunk('erp/fetchNavsERP',
    async ({ id }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const res = await apiBackend.get(
          `/erp/${id}/navs`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        )


        return res.data
      } catch (error) {
        console.log('Error:', error)
        throw new Error("Error fetchNav erp");

      }
    })




export const deleteDashboardsERP =
  createAsyncThunk('erp/deleteDashboardsERP',
    async ({ id, dashboardId }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')

        const res = await apiBackend.delete(
          `/erp/${id}/dashboards`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            id: dashboardId
          },
        })

        return res.data
      } catch (error) {
        console.log('Error dashboard erp:', error)
      }
    })


export const addDashboardsERP =
  createAsyncThunk('erp/addDashboardsERP',
    async ({ id, dashboard }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          '/erp/dashboards', {
          id,
          dashboard
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        );

        return resp.data;
      } catch (error) {
        console.log('Error', error)
        throw error;
      }
    }
  );




export const fetchDashboardsERP =
  createAsyncThunk('erp/fetchDashboardsERP',
    async ({ id, query, offset = 0, limit = 40 }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const res = await apiBackend.get(
          `/erp/${id}/dashboards`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params: {
            query,
            offset,
            limit,
          },
        }
        )


        return res.data
      } catch (error) {
        console.log('Error:', error)
        throw new Error("Error fetchDashboards erp");
        
      }
    })





export const deleteERP =
  createAsyncThunk('erp/deleteERP',
    async ({ id }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')

        const res = await apiBackend.delete(
          `/erp`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            id
          },
        })


        return res.data
      } catch (error) {
        console.log('Error erp:', error)
      }
    })





export const addEndpointsERP =
  createAsyncThunk('erp/addEndpointsERP',
    async ({ id, endpoint }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          `/erp/endpoints`, {
          id,
          endpoint
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        );


        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );



export const deleteEndpointsERP =
  createAsyncThunk('erp/deleteEndpointsERP',
    async ({ id, ids }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')

        const res = await apiBackend.delete(
          `/erp/${id}/endpoints`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            ids: ids
          },
        })


        return res.data
      } catch (error) {
        console.log('Error endpoints:', error)
      }
    })




export const fetchEndpointsERP = createAsyncThunk(
  "iam/fetchEndpointsERP",
  async ({ id, query, offset = 0, limit = 40 }, { dispatch }) => {
    try {
      const token = localStorage.getItem("token")
      const response = await apiBackend.get(`/erp/${id}/endpoints`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          query,
          offset,
          limit,
        },
      })

      return {
        endpoints: response.data.endpoints,
        total: response.data.total,
      }
    } catch (error) {
      throw "Ya existe el usuario"
      if (error.response.status == 400) {
        throw "Ya existe el usuario"
      }
    }
  },
)





export const addDocumentsERP =
  createAsyncThunk('erp/addDocumentsERP',
    async ({ id, document }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          `/erp/documents`, {
          id,
          document
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );



export const deleteDocumentsERP =
  createAsyncThunk('erp/deleteDocumentsERP',
    async ({ id, ids }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')

        const res = await apiBackend.delete(
          `/erp/${id}/documents`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            ids: ids
          },
        })

        return res.data
      } catch (error) {
        console.log('Error documents:', error)
      }
    })




export const fetchDocumentsERP = createAsyncThunk(
  "iam/fetchDocumentsERP",
  async ({ id, query, offset = 0, limit = 40 }, { dispatch }) => {
    try {
      const token = localStorage.getItem("token")
      const response = await apiBackend.get(`/erp/${id}/documents`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          query,
          offset,
          limit,
        },
      })

      return {
        documents: response.data.documents,
        total: response.data.total,
      }
    } catch (error) {
      console.log('Error', error)
      throw "Ya existe el usuario"
      if (error.response.status == 400) {
        throw "Ya existe el usuario"
      }
    }
  },
)



export const deleteRepo =
  createAsyncThunk('erp/deleteRepo',
    async ({ id }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')

        const res = await apiBackend.delete(
          `/erp/${id}/repo`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
          },
        })

        return res.data
      } catch (error) {
        console.log('Error repo:', error)
      }
    })


export const addRepo =
  createAsyncThunk('erp/addRepo',
    async ({ repository }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          '/erp/repo', {
          repository
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );



export const addERP =
  createAsyncThunk('erp/addERP',
    async ({ id, endpoints, documents, dashboards }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          '/erp/repo/add', {
          id,
          endpoints,
          documents,
          dashboards
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        );

        return {
          endpoints,
          documents,
          dashboards
        };
      } catch (error) {
        throw error;
      }
    }
  );




export const fetchRepoAll = createAsyncThunk(
  "iam/fetchRepoAll",
  async ({ query, offset = 0, limit = 40 }, { dispatch }) => {
    try {
      const token = localStorage.getItem("token")
      const response = await apiBackend.get(`/erp/repo/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          query,
          offset,
          limit,
        },
      })


      return {
        repos: response.data.repos,
        total: response.data.total,
      }
    } catch (error) {
      throw "Ya existe el usuario"
      if (error.response.status == 400) {
        throw "Ya existe el usuario"
      }
    }
  },
)

export const fetchRepoOwner = createAsyncThunk(
  "iam/fetchRepoOwner",
  async ({ query, offset = 0, limit = 40 }, { dispatch }) => {
    try {
      const token = localStorage.getItem("token")
      const response = await apiBackend.get(`/erp/repo/owner`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          query,
          offset,
          limit,
        },
      })


      return {
        repos: response.data.repos,
        total: response.data.total,
      }
    } catch (error) {
      console.log("Error", error)
      throw "Ya existe el usuario"
      if (error.response.status == 400) {
        throw "Ya existe el usuario"
      }
    }
  },
)



export const readRepo =
  createAsyncThunk('erp/readRepo',
    async ({ file }, { dispatch }) => {
      try {
        dispatch(setPrevIsLoad(true))

        const tokenGPT = localStorage.getItem("token-gpt")
        const token = localStorage.getItem("token")

        const formData = new FormData()
        formData.append("image", file)

        formData.append("tokenGPT", tokenGPT)
        formData.append("token", token)

        fetch('http://localhost:3001/service/v1/erp/repo/read', {
          method: 'POST',
          body: formData,
        })
          .then(response => {
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let jsonChunks = [];

            const processStream = async () => {
              while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const chunk = decoder.decode(value, { stream: true });

                const data = { ...chunk.split('\n').filter(Boolean).map(item => JSON.parse(item))[0] }

                jsonChunks.push(data);
                dispatch(setChunkRepo(data))
              }
            };

            processStream().then(() => {
              dispatch(setPrevIsLoad(false))
            }).catch(console.error);
          })
          .catch(error => {
            console.error('Fetch error:', error);
          });


      } catch (error) {
        console.log('Error', error)
        throw error;
      }
    }
  );





export const readBoard =
  createAsyncThunk('erp/readBoard',
    async ({ text }, { dispatch }) => {
      try {

        const tokenGPT = localStorage.getItem("token-gpt")
        const token = localStorage.getItem("token")

        const formData = new FormData()
        formData.append("tokenGPT", tokenGPT)
        formData.append("token", token)

        formData.append("text", text)

        fetch('http://localhost:3001/service/v1/erp/read/board', {
          method: 'POST',
          body: formData,
        })
          .then(response => {
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let jsonChunks = [];

            const processStream = async () => {
              while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const chunk = decoder.decode(value, { stream: true });

                const data = { ...chunk.split('\n').filter(Boolean).map(item => JSON.parse(item))[0] }

                jsonChunks.push(data);
                dispatch(setChunkBoard(data))
              }
            };

            processStream().catch(console.error);
          })
          .catch(error => {
            console.error('Error error:', error);
          });

      } catch (error) {
        console.log('Error', error)
        throw error;
      }
    }
  );




export const addElement =
  createAsyncThunk('erp/addElement',
    async ({ id, value, endpoints, documents, elements }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const tokenGPT = localStorage.getItem('token-gpt')
        const resp = await apiBackend.post(
          '/erp/element/add', {
          id,
          token: tokenGPT,
          value,
          endpoints,
          documents,
          elements
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        );

        return {
          id,
          element: resp.data
        };
      } catch (error) {
        throw error;
      }
    }
  );





export const exportERP =
  createAsyncThunk('erp/exportERP',
    async ({ id }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const response = await apiBackend.post(
          `/erp/export`, {
          id,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          responseType: 'blob'
        }
        );


        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.xlsx'); 
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );
