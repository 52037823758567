import React from 'react'

const Privacy = () => {
    return (
    <div>
        <b>This website uses cookies</b>
        <p>
      We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. 
      We also share information about your use of our site with our social media, advertising and analytics partners 
      who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.
        </p>

        <b>Essential</b>    
        <p>
            Essentials cookies help make a website usable by enabling basic functions like page navigation and access 
            to secure areas of the website.
        </p>

        <b>Advertising</b>    
        <p>
            Advertising cookies are used display ads that are relevant and engaging for the individual user and thereby 
            more valuable for publishers and third party advertisers.
        </p>

        <b>Statistics</b>    
        <p>
            Statistic cookies help website owners to understand how visitors interact with websites by collecting 
            and reporting information anonymously.
        </p>



        <b>1. An overview of data protection</b>    
        <p>
      The following information will provide you with an easy to navigate overview of what will happen 
      with your personal data when you visit this website. The term “personal data” comprises all data 
      that can be used to personally identify you. For detailed information about the subject matter of data protection, 
      please consult our Data Protection Declaration, which we have included beneath this copy.
        </p>

        <b>Data recording on this website</b>    
        <p>
            Who is the responsible party for the recording of data on this website (i.e. the “controller”)?
            The data on this website is processed by the operator of the website, whose contact information 
            is available under section “Information Required by Law” on this website.
        </p>

        <b>How do we record your data?</b>    
        <p>
            We collect your data as a result of your sharing of your data with us. This may, for instance be 
            information you enter into our contact form.
        </p>

        <p>Other data shall be recorded by our IT systems automatically or after you consent to its recording 
        during your website visit. This data comprises primarily technical information 
        (e.g. web browser, operating system or time the site was accessed). 
        This information is recorded automatically when you access this website.
        </p>

        <b>What are the purposes we use your data for?</b>    
        <p>
            A portion of the information is generated to guarantee the error free provision of the website. 
            Other data may be used to analyze your user patterns.
        </p>

        <b>What rights do you have as far as your information is concerned?</b>    
        <p>
            You have the right to receive information about the source, recipients and purposes of your archived personal 
            data at any time without having to pay a fee for such disclosures. You also have the right to demand that 
            your data are rectified or eradicated. If you have consented to data processing, you have the option 
            to revoke this consent at any time, which shall affect all future data processing. Moreover, 
            you have the right to demand that the processing of your data be restricted under certain circumstances. 
            Furthermore, you have the right to log a complaint with the competent supervising agency.
        </p>

        <p>
            Please do not hesitate to contact us at any time under the address disclosed in section 
            “Information Required by Law” on this website if you have questions about this or any other data protection related issues.
        </p>

        <b>Analysis tools and tools provided by third parties</b>    
        <p>
            There is a possibility that your browsing patterns will be statistically analyzed when your visit this website. 
            Such analyses are performed primarily with what we refer to as analysis programs.
        </p>

        <p>
            For detailed information about these analysis programs please consult our Data Protection Declaration below.
        </p>

        <b>2. General information and mandatory information</b>    
        <p>
            Data protection
      The operators of this website and its pages take the protection of your personal data very seriously. 
      Hence, we handle your personal data as confidential information and in compliance with the statutory 
            data protection regulations and this Data Protection Declaration.
        </p>    

        <p>
            Whenever you use this website, a variety of personal information will be collected. 
            Personal data comprises data that can be used to personally identify you. 
            This Data Protection Declaration explains which data we collect as well as the purposes we use this data for. 
            It also explains how, and for which purpose the information is collected.
        </p>

        <p>
            We herewith advise you that the transmission of data via the Internet (i.e. through e-mail communications) 
            may be prone to security gaps. It is not possible to completely protect data against third-party access.
        </p>

        <b>Information about the responsible party (referred to as the “controller” in the GDPR)</b>    
        <p>
            The data processing controller on this website is:
        </p>

        <p> 
            wwwInspired.com
            Poetenweg 4
            08056 Zwickau
            Zwickau
        </p>

        <p>
            E-mail: contact@wwwinspired.com
        </p>

        <p>
            The controller is the natural person or legal entity that single-handedly or jointly with others makes decisions 
            as to the purposes of and resources for the processing of personal data (e.g. names, e-mail addresses, etc.).
        </p>

        <b>Storage duration</b>    
        <p>
            Unless a more specific storage period has been specified in this privacy policy, 
            your personal data will remain with us until the purpose for which it was collected no longer applies. 
            If you assert a justified request for deletion or revoke your consent to data processing, your data will be deleted, 
            unless we have other legally permissible reasons for storing your personal data (e.g. tax or commercial law retention periods); 
            in the latter case, the deletion will take place after these reasons cease to apply.
        </p>

        <b>Information on data transfer to the USA</b>    
        <p>
            Our website uses, in particular, tools from companies based in the USA. When these tools are active, 
            your personal information may be transferred to the US servers of these companies. 
            We must point out that the USA is not a safe third country within the meaning of EU data protection law. 
      US companies are required to release personal data to security authorities without you as the data subject being able to take legal action against this. 
      The possibility cannot therefore be excluded that US authorities (e.g. secret services) may process, evaluate and permanently store your data on 
      US servers for monitoring purposes.  
            We have no influence over these processing activities.
        </p>

        <b>Revocation of your consent to the processing of data</b>    
        <p>
            A wide range of data processing transactions are possible only subject to your express consent. 
            You can also revoke at any time any consent you have already given us. This shall be without prejudice to the lawfulness 
            of any data collection that occurred prior to your revocation.
        </p>

        <b>Right to object to the collection of data in special cases; right to object to direct advertising (Art. 21 GDPR)</b>    
        <p>
            IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF ART. 6 SECT. 1 LIT. E OR F GDPR, YOU HAVE THE RIGHT TO AT 
            ANY TIME OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA BASED ON GROUNDS ARISING FROM YOUR UNIQUE SITUATION. 
            THIS ALSO APPLIES TO ANY PROFILING BASED ON THESE PROVISIONS. TO DETERMINE THE LEGAL BASIS, ON WHICH ANY PROCESSING OF DATA IS BASED, 
      PLEASE CONSULT THIS DATA PROTECTION DECLARATION. IF YOU LOG AN OBJECTION, WE WILL NO LONGER PROCESS YOUR AFFECTED PERSONAL DATA, 
            UNLESS WE ARE IN A POSITION TO PRESENT COMPELLING PROTECTION WORTHY GROUNDS FOR THE PROCESSING OF YOUR DATA, 
            THAT OUTWEIGH YOUR INTERESTS, RIGHTS AND FREEDOMS OR IF THE PURPOSE OF THE PROCESSING IS THE CLAIMING, EXERCISING OR DEFENCE OF LEGAL ENTITLEMENTS (OBJECTION PURSUANT TO ART. 21 SECT. 1 GDPR).
        </p>

        <p>
            IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT ADVERTISING, YOU HAVE THE RIGHT TO AT ANY TIME OBJECT 
      TO THE PROCESSING OF YOUR AFFECTED PERSONAL DATA FOR THE PURPOSES OF SUCH ADVERTISING. THIS ALSO APPLIES TO PROFILING 
            TO THE EXTENT THAT IT IS AFFILIATED WITH SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA WILL SUBSEQUENTLY 
            NO LONGER BE USED FOR DIRECT ADVERTISING PURPOSES (OBJECTION PURSUANT TO ART. 21 SECT. 2 GDPR).
        </p>

        <b>Right to log a complaint with the competent supervisory agency</b>    
        <p>
            In the event of violations of the GDPR, data subjects are entitled to log a complaint with a supervisory agency, 
            in particular in the member state where they usually maintain their domicile, place of work or at the place where 
            the alleged violation occurred. The right to log a complaint is in effect regardless of any other administrative 
            or court proceedings available as legal recourses.
        </p>

        <b>Right to data portability</b>    
        <p>
            You have the right to demand that we hand over any data we automatically process on the basis of your consent 
            or in order to fulfil a contract be handed over to you or a third party in a commonly used, machine readable format. 
            If you should demand the direct transfer of the data to another controller, this will be done only if it is technically feasible.
        </p>

        <b>SSL and/or TLS encryption</b>    
        <p>
            For security reasons and to protect the transmission of confidential content, such as purchase orders or inquiries you submit 
            to us as the website operator, this website uses either an SSL or a TLS encryption program. 
            You can recognize an encrypted connection by checking whether the address line of the browser switches from 
            “http://” to “https://” and also by the appearance of the lock icon in the browser line.

            If the SSL or TLS encryption is activated, data you transmit to us cannot be read by third parties.
        </p>

        <b>Encrypted payment transactions on this website</b>    
        <p>
            If you are under an obligation to share your payment information (e.g. account number if you give us the authority 
            to debit your bank account) with us after you have entered into a fee-based contract with us, 
            this information is required to process payments.

      Payment transactions using common modes of paying (Visa/MasterCard, debit to your bank account) 
      are processed exclusively via encrypted SSL or TLS connections. You can recognize an encrypted connection 
      çby checking whether the address line of the browser switches from “http://” to “https://” and also 
            by the appearance of the lock icon in the browser line.
        </p>

        <p>
            If the communication with us is encrypted, third parties will not be able to read the payment information you share with us.
        </p>

        <b>Information about, rectification and eradication of data</b>    
        <p>
            Within the scope of the applicable statutory provisions, you have the right to at any time demand information 
            about your archived personal data, their source and recipients as well as the purpose of the processing of your data. 
            You may also have a right to have your data rectified or eradicated. If you have questions about this subject 
            matter or any other questions about personal data, please do not hesitate to contact us at any time at the address 
            provided in section “Information Required by Law.”
        </p>

        <b>Right to demand processing restrictions</b>    
        <p>
            You have the right to demand the imposition of restrictions as far as the processing of your personal data is concerned. 
            To do so, you may contact us at any time at the address provided in section “Information Required by Law.” 
            The right to demand restriction of processing applies in the following cases:
        </p>

        <p>
            In the event that you should dispute the correctness of your data archived by us, we will usually need some time 
            to verify this claim. During the time that this investigation is ongoing, you have the right to demand that 
            we restrict the processing of your personal data.
        </p>

        <p>
            If the processing of your personal data was/is conducted in an unlawful manner, you have the option 
            to demand the restriction of the processing of your data in lieu of demanding the eradication of this data.
      If we do not need your personal data any longer and you need it to exercise, defend or claim legal entitlements, 
      you have the right to demand the restriction of the processing of your personal data instead of its eradication.
      If you have raised an objection pursuant to Art. 21 Sect. 1 GDPR, your rights and our rights will have to be weighed against each other. 
      As long as it has not been determined whose interests prevail, you have the right to demand a restriction of the processing of your personal data.
      If you have restricted the processing of your personal data, these data – with the exception of their archiving – may be processed only subject 
      to your consent or to claim, exercise or defend legal entitlements or to protect the rights of other natural persons or legal entities or 
            for important public interest reasons cited by the European Union or a member state of the EU.
        </p>

        <b>Rejection of unsolicited e-mails</b>    
        <p>
            We herewith object to the use of contact information published in conjunction with the mandatory information to be provided in section 
            “Information Required by Law” to send us promotional and information material that we have not expressly requested. 
            The operators of this website and its pages reserve the express right to take legal action in the event of the unsolicited sending 
            of promotional information, for instance via SPAM messages.
        </p>

        <b>Recording of data on this website</b>    
        <p>
            Cookies
      Our websites and pages use what the industry refers to as “cookies.” Cookies are small text files that do not cause any damage to your device. 
      They are either stored temporarily for the duration of a session (session cookies) or they are permanently archived on your device (permanent cookies). 
      Session cookies are automatically deleted once you terminate your visit. Permanent cookies remain archived on your device until you actively delete them 
            or they are automatically eradicated by your web browser.
        </p>

        <p>
            In some cases, it is possible that third-party cookies are stored on your device once you enter our site (third-party cookies). 
            These cookies enable you or us to take advantage of certain services offered by the third party (e.g. cookies for the processing of payment services).
        </p>

        <p>
            Cookies have a variety of functions. Many cookies are technically essential since certain website functions would not work in the absence of the cookies (e.g. the shopping cart function or the display of videos). The purpose of other cookies may be the analysis of user patterns or the display of promotional messages.
        </p>

        <p>
            Cookies, which are required for the performance of electronic communication transactions (required cookies) or for the provision of certain functions you want to use (functional cookies, e.g. for the shopping cart function) or those that are necessary for the optimization of the website (e.g. cookies that provide measurable insights into the web audience), shall be stored on the basis of Art. 6 Sect. 1 lit. f GDPR, unless a different legal basis is cited. The operator of the website has a legitimate interest in the storage of cookies to ensure the technically error free and optimized provision of the operator’s services. If your consent to the storage of the cookies has been requested, the respective cookies are stored exclusively on the basis of the consent obtained (Art. 6 Sect. 1 lit. a GDPR); this consent may be revoked at any time.
        </p>

        <p>
            You have the option to set up your browser in such a manner that you will be notified any time cookies are placed and to permit the acceptance of cookies only in specific cases. You may also exclude the acceptance of cookies in certain cases or in general or activate the delete function for the automatic eradication of cookies when the browser closes. If cookies are deactivated, the functions of this website may be limited.
        </p>

        <p>
            In the event that third-party cookies are used or if cookies are used for analytical purposes, we will separately notify you in conjunction with this Data Protection Policy and, if applicable, ask for your consent.
        </p>

        <b>Server log files</b>    
        <p>
            The provider of this website and its pages automatically collects and stores information in so-called server log files, which your browser communicates to us automatically. The information comprises:
        </p>

        <p>
            The type and version of browser used
            The used operating system
            Referrer URL
            The hostname of the accessing computer
            The time of the server inquiry
            The IP address
            This data is not merged with other data sources.
        </p>

        <p>
            This data is recorded on the basis of Art. 6 Sect. 1 lit. f GDPR. The operator of the website has a legitimate interest in the technically error free depiction and the optimization of the operator’s website. In order to achieve this, server log files must be recorded.
        </p>

        <b>Contact form</b>    
        <p>
            If you submit inquiries to us via our contact form, the information provided in the contact form as well as any contact information provided therein will be stored by us in order to handle your inquiry and in the event that we have further questions. We will not share this information without your consent.
        </p>

        <p>
            The processing of these data is based on Art. 6 para. 1 lit. b GDPR, if your request is related to the execution of a contract or if it is necessary to carry out pre-contractual measures. In all other cases the processing is based on our legitimate interest in the effective processing of the requests addressed to us (Art. 6 Para. 1 lit. f GDPR) or on your agreement (Art. 6 Para. 1 lit. a GDPR) if this has been requested.
        </p>

        <p>
            The information you have entered into the contact form shall remain with us until you ask us to eradicate the data, revoke your consent to the archiving of data or if the purpose for which the information is being archived no longer exists (e.g. after we have concluded our response to your inquiry). This shall be without prejudice to any mandatory legal provisions – in particular retention periods.
        </p>

        <b>Request by e-mail, telephone or fax</b>    
        <p>
            If you contact us by e-mail, telephone or fax, your request, including all resulting personal data (name, request) 
            will be stored and processed by us for the purpose of processing your request. We do not pass these data on without your consent.

        </p>

        <p>
            These data are processed on the basis of Art. 6 Sect. 1 lit. b GDPR if your inquiry is related to the fulfillment 
            of a contract or is required for the performance of pre-contractual measures. In all other cases, the data 
            are processed on the basis of our legitimate interest in the effective handling of inquiries submitted to us 
            (Art. 6 Sect. 1 lit. f GDPR) or on the basis of your consent (Art. 6 Sect. 1 lit. a GDPR) if it has been obtained.
        </p>

        <p>
            The data sent by you to us via contact requests remain with us until you request us to delete, revoke your consent 
            to the storage or the purpose for the data storage lapses (e.g. after completion of your request). 
            Mandatory statutory provisions - in particular statutory retention periods - remain unaffected.
        </p>

        <b>Registration on this website</b>    
        <p>
            You have the option to register on this website to be able to use additional website functions. 
            We shall use the data you enter only for the purpose of using the respective offer or service you have registered for. 
            The required information we request at the time of registration must be entered in full. Otherwise we shall reject the registration.

        </p>

        <p>
            To notify you of any important changes to the scope of our portfolio or in the event of technical modifications, 
            we shall use the e-mail address provided during the registration process.
        </p>

        <p>
            We shall process the data entered during the registration process on the basis of your consent (Art. 6 Sect. 1 lit. a GDPR).
        </p>

        <p>
            The data recorded during the registration process shall be stored by us as long as you are registered on this website. 
            Subsequently, such data shall be deleted. This shall be without prejudice to mandatory statutory retention obligations.
        </p>

        <b>Analysis tools and advertising</b>    
        <p>
            Google Analytics
            This website uses functions of the web analysis service Google Analytics. The provider of this service is 
            Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.

        </p>

        <p>
            Google Analytics enables the website operator to analyze the behavior patterns of website visitors. 
            To that end, the website operator receives a variety of user data, such as pages accessed, time spent on the page, 
            the utilized operating system and the user’s origin. Google may consolidate these data in a profile that 
            is allocated to the respective user or the user’s device.

        </p>

        <p>
            Google Analytics uses technologies that make the recognition of the user for the purpose of analyzing 
            the user behavior patterns (e.g. cookies or device fingerprinting). The website use information recorded by Google is, 
            as a rule transferred to a Google server in the United States, where it is stored.

        </p>

        <p>
            This analysis tool is used on the basis of Art. 6 Sect. 1 lit. f GDPR. The operator of this website has 
            a legitimate interest in the analysis of user patterns to optimize both, the services offered online and 
            the operator’s advertising activities. If a corresponding agreement has been requested 
            (e.g. an agreement to the storage of cookies), the processing takes place exclusively on the basis of Art. 
            6 para. 1 lit. a GDPR; the agreement can be revoked at any time.

        </p>

        <p>
            Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. 
            Details can be found here: https://privacy.google.com/businesses/controllerterms/mccs/.

        </p>

        <p>
            IP anonymization
            On this website, we have activated the IP anonymization function. As a result, your IP address will 
            be abbreviated by Google within the member states of the European Union or in other states 
            that have ratified the Convention on the European Economic Area prior to its transmission to the United States. 
            The full IP address will be transmitted to one of Google’s servers in the United States and abbreviated 
            there only in exceptional cases. On behalf of the operator of this website, Google shall use this information 
      to analyze your use of this website to generate reports on website activities and to render other services 
      to the operator of this website that are related to the use of the website and the Internet. 
            The IP address transmitted in conjunction with Google Analytics from your browser shall not be merged 
            with other data in Google’s possession.

        </p>

        <b>Browser plug-in</b>    
        <p>
            You can prevent the recording and processing of your data by Google by downloading and installing 
            the browser plugin available under the following link: https://tools.google.com/dlpage/gaoptout?hl=en.

        </p>

        <p>
            For more information about the handling of user data by Google Analytics, please consult 
            Google’s Data Privacy Declaration at: https://support.google.com/analytics/answer/6004245?hl=en.

        </p>

        <b>Contract data processing</b>    
        <p>
            We have executed a contract data processing agreement with Google and are implementing 
            the stringent provisions of the German data protection agencies to the fullest when using Google Analytics.

        </p>

        <b>Demographic parameters provided by Google Analytics</b>    
        <p>
            This website uses the “demographic characteristics” function of Google Analytics, to be able 
            to display to the website visitor compatible ads within the Google advertising network. 
            This allows reports to be created that contain information about the age, gender and interests 
            of the website visitors. The sources of this information are interest-related advertising 
            by Google as well as visitor data obtained from third-party providers. 
            This data cannot be allocated to a specific individual. You have the option to deactivate 
      this function at any time by making pertinent settings changes for advertising in 
            your Google account or you can generally prohibit the recording of your data 
            by Google Analytics as explained in section “Objection to the recording of data”.

        </p>

        <b>Archiving period</b>    
        <p>
            Data on the user or incident level stored by Google linked to cookies, user IDs or 
            advertising IDs (e.g. DoubleClick cookies, Android advertising ID) will be anonymized 
            or deleted after 14 month. For details please click the following link: 
            https://support.google.com/analytics/answer/7667196?hl=en
        </p>

        <b>Push Notifications</b>    
        <p>
            If you activate push notifications for this website using the "Signalize" service, a 
            function of your Internet browser or mobile operating system will be used to provide 
            the notifications for you. Only anonymous or pseudonymous data is transmitted for 
            the purpose of sending messages. Depending on the configuration of the website,this may be:

            Pseudonymous User ID: a randomly generated value (example: 108bf9a85547edb1108bf9a85547edb1) stored in a tracking cookie ID
            Pseudonymous digital fingerprints, pseudonymous mobile device codes and where required pseudonymous cross-device identifiers
            This data is only processed to deliver the notifications you have subscribed to and to configure notification-related settings. 
            We ask for your consent to store this data. In this case, the legal basis for data processing is Art. 6 Para. 1 lit. a GDPR. 
            You can object to receiving notifications at any time via the settings of your browser or mobile device. 
            Information about unsubscribing for push notifications can be found here.
            In order to make the push notifications meaningful for you in terms of content, we use the preferences collected 
      on the basis of a pseudonymous user profile by means of tracking pixels and, only with your consent, 
            also by means of cookies, and we merge your notification ID with the user profile of the website solely for the purpose 
            of personalised message dispatch. The Tracking technology is also used for statistical evaluation of the notifications on our behalf. 
            This allows us to determine whether a notification was delivered and whether it was clicked. 
            The data generated in this way is processed and stored on our behalf by etracker GmbH solely in Germany and is thus subject to strict 
            German and European data protection laws and standards. etracker has been independently audited and certified in this regard and 
            is entitled to bear the data privacy seal ePrivacyseal
            The data processing for the statistical analysis of the notifications and in order to better adapt future notifications to 
      the interests of the recipients is based on our legitimate interest in personalised direct advertising in accordance with Art. 
      6 Para. 1 letter f GDPR. As the privacy of our visitors is very important to us, the data that may possibly allow a reference to an individual person, 
      such as the IP address, login or device IDs, are anonymised or pseudonymised as soon as possible. 
            A direct personal reference is thereby excluded. etracker does not use the data for any other purpose, combine it with other data or pass it on to third parties.
            You can object to the data processing described above at any time.
</p>

        <b>Newsletter</b>    
        <p>
            If you would like to subscribe to the newsletter offered on this website, we will need from you an 
            e-mail address as well as information that allow us to verify that you are the owner of the e-mail address 
            provided and consent to the receipt of the newsletter. No further data shall be collected or shall 
            be collected only on a voluntary basis. We shall use such data only for the sending of the requested 
            information and shall not share such data with any third parties.
                </p>
        <p>

            The processing of the information entered into the newsletter subscription form shall occur exclusively on 
            the basis of your consent (Art. 6 Sect. 1 lit. a GDPR). You may revoke the consent you have given to the archiving of data, 
            the e-mail address and the use of this information for the sending of the newsletter at any time, for instance 
            by clicking on the “Unsubscribe” link in the newsletter. This shall be without prejudice to the lawfulness 
            of any data processing transactions that have taken place to date.

        </p>

        <p>
            The data deposited with us for the purpose of subscribing to the newsletter will be stored by us until you unsubscribe 
            from the newsletter or the newsletter service provider and deleted from the newsletter distribution list after 
            you unsubscribe from the newsletter. Data stored for other purposes with us remain unaffected.

        </p>

        <p>
            After you unsubscribe from the newsletter distribution list, your e-mail address may be stored by us or the 
            newsletter service provider in a blacklist to prevent future mailings. The data from the blacklist is used only 
            for this purpose and not merged with other data. This serves both your interest and our interest in complying 
            with the legal requirements when sending newsletters (legitimate interest within the meaning of Art. 6 para. 1 lit. f GDPR). 
            The storage in the blacklist is indefinite. You may object to the storage if your interests outweigh our legitimate interest.

        </p>

        <b>Plug-ins and Tools</b>    
        <p>
            Google Web Fonts (local embedding)
            This website uses so-called Web Fonts provided by Google to ensure the uniform use of fonts on this site. 
            These Google fonts are locally installed so that a connection to Google’s servers will not be established 
            in conjunction with this application.

        </p>

        <p>
            For more information on Google Web Fonts, please follow this link: 
            https://developers.google.com/fonts/faq and consult Google’s Data Privacy Declaration under: 
            https://policies.google.com/privacy?hl=en.

        </p>

        <b>Font Awesome (local embedding)</b>    
        <p>
            This website uses Font Awesome to ensure the uniform use of fonts on this site. Font Awesome is locally 
            installed so that a connection to Fonticons, Inc.’s servers will not be established in conjunction with this application.

        </p>

        <p>
            For more information on Font Awesome, please and consult the Data Privacy Declaration for Font Awesome under: 
            https://fontawesome.com/privacy.

        </p>

        <b>Bootstrap & Bootstrap Icons (local embedding)</b>    
        <p>
            This website uses Bootstrap & Bootstrap icons to ensure the uniform use of fonts on this site. Bootstrap & 
            Bootstrap icons is locally installed so that a connection to Fonticons, Inc.’s servers will not be established 
            in conjunction with this application.

        </p>

        <p>
            For more information on Bootstrap icons, please and consult the Data Privacy Declaration for Bootstrap under: 
            https://getbootstrap.com.
        </p>

        <b>Social media appearances</b>    
        <p>
            Data processing through social networks
            We maintain publicly available profiles in social networks. The individual social networks we use can be found below.
        </p>

        <p>
            Social networks such as Facebook, Twitter etc. can generally analyze your user behavior comprehensively if you 
            visit their website or a website with integrated social media content (e.g. like buttons or banner ads). 
            When you visit our social media pages, numerous data protection-relevant processing operations are triggered. 
            In detail:

        </p>

        <p>
            If you are logged in to your social media account and visit our social media page, the operator of the social 
            media portal can assign this visit to your user account. Under certain circumstances, your personal data may 
            also be recorded if you are not logged in or do not have an account with the respective social media portal. 
            In this case, this data is collected, for example, via cookies stored on your device or by recording your IP address.

        </p>

        <p>
            Using the data collected in this way, the operators of the social media portals can create user profiles in 
            which their preferences and interests are stored. This way you can see interest-based advertising inside 
            and outside of your social media presence. If you have an account with the social network, interest-based 
            advertising can be displayed on any device you are logged in to or have logged in to.
        </p>

        <p>
            Please also note that we cannot retrace all processing operations on the social media portals. 
            Depending on the provider, additional processing operations may therefore be carried out by the operators of 
            the social media portals. Details can be found in the terms of use and privacy policy of the respective 
            social media portals.
        </p>

        <b>Legal basis</b>    
        <p>
            Our social media appearances should ensure the widest possible presence on the Internet. This is a legitimate 
            interest within the meaning of Art. 6 (1) lit. f GDPR. The analysis processes initiated by the social networks 
            may be based on divergent legal bases to be specified by the operators of the social networks 
            (e.g. consent within the meaning of Art. 6 (1) (a) GDPR).

        </p>

        <b>Responsibility and assertion of rights</b>    
        <p>
            If you visit one of our social media sites (e.g., Facebook), we, together with the operator of the social media platform, 
            are responsible for the data processing operations triggered during this visit. You can in principle protect your rights 
            (information, correction, deletion, limitation of processing, data portability and complaint) vis-à-vis us as well as 
            vis-à-vis the operator of the respective social media portal (e.g. Facebook).

        </p>

        <p>
            Please note that despite the shared responsibility with the social media portal operators, we do not have full 
            influence on the data processing operations of the social media portals. Our options are determined by the company 
            policy of the respective provider.
        </p>

        <b>Storage time</b>    
        <p>
            The data collected directly from us via the social media presence will be deleted from our systems as soon as the 
            purpose for their storage lapses, you ask us to delete it, you revoke your consent to the storage or the purpose 
            for the data storage lapses. Stored cookies remain on your device until you delete them. Mandatory statutory provisions 
            - in particular, retention periods - remain unaffected.

        </p>

        <p>
            We have no control over the storage duration of your data that are stored by the social network operators for 
            their own purposes. For details, please contact the social network operators directly (e.g. in their privacy policy, 
            see below).

        </p>

        <b>Individual social networks</b>    
        <p>
            Twitter
            We use the short message service Twitter. The provider is Twitter International Company, One Cumberland Place, 
            Fenian Street, Dublin 2, D02 AX07, Ireland.

        </p>

        <p>
            You can customize your Twitter privacy settings in your user account. Click on the following link and log in: 
            https://twitter.com/personalization.

        </p>

        <p>
            Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. 
            Details can be found here: https://gdpr.twitter.com/en/controller-to-controller-transfers.html.

        </p>

        <p>
            For details, see the Twitter Privacy Policy: https://twitter.com/privacy.

        </p>

        <b>Instagram</b>    
        <p>
            We have a profile on Instagram. The provider is Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA.

        </p>

        <p>
            Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. 
            Details can be found here: https://www.facebook.com/legal/EU_data_transfer_addendum, 
            https://help.instagram.com/519522125107875 and https://de-de.facebook.com/help/566994660333381.

      For details on how they handle your personal information, see the Instagram Privacy Policy: 
            https://help.instagram.com/519522125107875.

        </p>

        <b>Pinterest</b>    
        <p>
            We have a profile at Pinterest. The operator is Pinterest Europe Ltd., Palmerston House, 2nd Floor, 
            Fenian Street, Dublin 2, Ireland. Details on how they handle your personal data can be found in the privacy 
            policy of Pinterest: https://policy.pinterest.com/de/privacy-policy.

        </p>

        <b>XING</b>    
        <p>
            We have a profile on XING. The provider is New Work SE, Dammtorstraße 30, 20354 Hamburg, Germany. 
            Details on their handling of your personal data can be found in the XING Privacy Policy: 
            https://privacy.xing.com/de/datenschutzerklaerung.

      LinkedIn
      We have a LinkedIn profile. The provider is the LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton Place, 
            Dublin 2, Ireland. LinkedIn uses advertising cookies.

        </p>

        <p>
            If you want to disable LinkedIn advertising cookies, please use the following link: 
            https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out.

      Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. 
            Details can be found here: https://www.linkedin.com/legal/l/dpa und https://www.linkedin.com/legal/l/eu-sccs.

        </p>

        <p>
            For details on how they handle your personal information, please refer to LinkedIn's privacy policy: 
            https://www.linkedin.com/legal/privacy-policy.

        </p>

        <b>Tumblr</b>    
        <p>
            We have a profile on Tumblr. The provider is Tumblr, Inc., 35 East 21st St., 10th Floor, New York, NY 10010, USA. 
            Details on how they handle your personal data can be found in the Tumblr privacy policy: 
            https://www.tumblr.com/privacy/de.

        </p>

        <b>Vimeo</b>    
        <p>
            We have a profile on Vimeo. The provider is Vimeo, Inc., 555 West 18th Street, New York 10011, USA.

        </p>

        <p>
            Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission and, 
            according to Vimeo, on “legitimate business interests”. Details can be found here: https://vimeo.com/privacy.

        </p>

        <p>
            Details on how they handle your personal data can be found in the Vimeo privacy policy: https://vimeo.com/privacy.
        </p>

        <b>YouTube</b>    
        <p>
            We have a profile on YouTube. The provider is Google Ireland Limited, Gordon House, Barrow Street, 
            Dublin 4, Ireland. Details on how they handle your personal data can be found in the YouTube privacy 
            policy: https://policies.google.com/privacy?hl=en.

        </p>

        <b>TikTok</b>    
        <p>
      We have a profile on TikTok. The provider is TikTok Technology Limited, 10 Earlsfort Terrace, 
      Dublin, D02 T380, Ireland. Details on how they handle your personal data can be found in the 
      TiKTok privacy policy: https://www.tiktok.com/legal/privacy-policy?lang=en.

        </p>

        <p>
            Data transmission to third countries is based on the Standard Contractual Clauses (SCC) of 
            the European Commission. Details can be found here: https://www.tiktok.com/legal/privacy-policy?lang=en.

        </p>

        <b>Information Provided as Mandated by Article 13 GDPR</b>    
        <p>
            If this is your first interaction with us, Art. 12, 13 GDPR mandates that we make available 
            to you the following mandatory data protection related information: If you are contacting us 
            via e-mail, we will process your personal data only if we have a legitimate interest in the 
            processing of this data  (Art. 6 Sect. 1 lit. f GDPR), if you have consented to the processing 
            of your data (Art. 6 Sect. 1 lit. a GDPR), if the processing of the data is required for the development, 
            establishment, content or modification of a legal relationship between you and our company 
            (Art. 6 Sect. 1 lit. b GDPR) or if any other legal provision permits the processing of this data. 
            Your personal data will remain in our possession until you ask us to delete the data or you revoke 
            your consent to store the data or if the purpose the data stored is required for no longer exists 
            (e.g. once your request has been conclusively processed). This shall be without prejudice to any 
            compelling statutory provisions – in particular tax and commercial law based retention periods. 
        </p>

        <p>
            You have the right to at any time receive free information concerning the origins, recipients and 
            purpose of your data archived by us. You also have a right to object, to data portability and a 
            right to log a complaint with the competent supervisory agency. Moreover, you can demand the correction, 
            eradication and, under certain circumstances, the restriction of the processing of your personal data. 
            Our data protection officer can be reached at contact@wwwinspired.com.
        </p>
    </div>
  )
}

export default Privacy