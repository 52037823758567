import React from 'react'

import DragNavImage from './assets/drag-nav.png'
import Card2Image from './assets/saly-2.png'
import Card3Image from './assets/controllers.png'
import Card4Image from './assets/saly-1.png'
import Card5Image from './assets/apps-man.png'
import Card6Image from './assets/saly-3.png'

const Apps = () => {
  return (
    <div className="relative w-full sm:h-full -lg:pb-[60px]" id="apps">
      <div className="container mx-auto">
        <section className="home-section-1 ">
          <div className="apps-gradient"></div>
          <div className="flex">
            <div className="relative lg:pt-[10px] z-[0]">
              <div className="mb-10 lg:mb-5 flex items-center ml-[-8px] absolute left-0 top-[10px] w-[300px] z-[5]">
                <div className="bg-[#FFA28B] z-[-1]  w-[40px] h-[40px] p-3 flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                  >
                    <path
                      d="M12.4418 0.21934C12.5648 0.0788894 12.7316 0 12.9056 0C13.0795 0 13.2463 0.0788894 13.3693 0.21934L19.0568 6.71934C19.1797 6.85997 19.2487 7.05059 19.2487 7.24934C19.2487 7.44809 19.1797 7.63871 19.0568 7.77934L13.3693 14.2793C13.2891 14.3781 13.1871 14.4502 13.074 14.4883C12.9609 14.5263 12.8409 14.5287 12.7267 14.4953C12.6125 14.462 12.5083 14.394 12.425 14.2986C12.3418 14.2032 12.2826 14.084 12.2537 13.9533C12.2244 13.823 12.2265 13.686 12.2596 13.5568C12.2927 13.4277 12.3556 13.3111 12.4418 13.2193L17.6656 7.24934L12.4418 1.27934C12.3189 1.13871 12.2499 0.948091 12.2499 0.74934C12.2499 0.550589 12.3189 0.359965 12.4418 0.21934ZM6.8068 0.21934C6.92969 0.359965 6.99872 0.550589 6.99872 0.74934C6.99872 0.948091 6.92969 1.13871 6.8068 1.27934L1.58305 7.24934L6.8068 13.2193C6.89318 13.311 6.95633 13.4276 6.9896 13.5569C7.02286 13.6861 7.025 13.8232 6.9958 13.9537C6.9666 14.0843 6.90713 14.2034 6.82367 14.2985C6.74021 14.3937 6.63583 14.4613 6.52155 14.4943C6.40747 14.5278 6.28762 14.5254 6.1746 14.4876C6.06158 14.4498 5.95957 14.3778 5.8793 14.2793L0.1918 7.77934C0.0689063 7.63871 -0.00012207 7.44809 -0.00012207 7.24934C-0.00012207 7.05059 0.0689063 6.85997 0.1918 6.71934L5.8793 0.21934C6.00235 0.0788894 6.16914 0 6.34305 0C6.51696 0 6.68375 0.0788894 6.8068 0.21934Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="ml-4">
                  <h4 className="text-[#FFA28B] font-extrabold uppercase text-[20px]">
                    ADDONS Y VECTORES
                  </h4>
                </div>
              </div>
              <div className="apps-left-border mt-[70px]"></div>
            </div>
            <div>
              <div className="grid md:grid-cols-3 gap-4">
                <div className="md:col-span-2 relative pt-10">
                  <div className="mt-8">
                    <div className="pl-8 lg:pl-10">
                      <p className="text-[#554D56] text-[24px] lg:text-[48px] leading-tight drop-shadow-xl text-left">
                        <span className="text-[#FFA28B]">
                          Crea y comparte en el mercado cualquier aplicación
                          completa.
                        </span>
                        Descubre miles de apps donde podrás personalizar todos
                        los componentes funcionales a medida entre tu equipo o
                        terceros para tener la base perfecta de tu proyecto.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="text-end relative">
                  <div className="w-full initial absolute lg:left-[50px] bottom-[-70px] right-0 lg:right-auto lg:bottom-[-150px] z-20 flex justify-end lg:block h-[100px] lg:h-[auto]">
                    <img
                      src={DragNavImage}
                      className="w-20 z-20"
                    />
                  </div>
                </div>
              </div>

              <div className=" mt-5 lg:mt-10 ">
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3 ">
                  <div className="z-[5]">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 p-5 mb-8 lg:mb-0 ">
                      <div className="md:w-[80%] text-gris-300 dark:text-gray-400">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          PRIMER BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light ">
                          Ahorro de tiempo y recursos
                        </h5>
                        <p className="lg:text-[15px]">
                          Desarrollar una App desde cero es un proceso complejo
                          y que consume mucho tiempo. Al reutilizar una base ya
                          existente, se ahorra tiempo y recursos significativos,
                          ya que gran parte del trabajo ya se ha realizado a
                          falta de su adaptación.
                        </p>
                      </div>
                    </div>
                    <div className="order-2 card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[500px] relative mt-5 mb-8 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          CUARTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light ">
                          Facilidad de personalización
                        </h5>
                      </div>
                      <div className="md:w-[90%]">
                        <p className="lg:text-[15px]">
                          Aunque se base en una App existente, se puede
                          personalizar para satisfacer las necesidades
                          específicas del nuevo proyecto. Esto permite adaptarla
                          y ajustarla para cumplir con los requisitos únicos.
                        </p>
                      </div>
                      <img
                        src={Card4Image}
                        className="absolute bottom-[0px] right-[0px] w-full"
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 p-5 h-[450px] relative mb-8 lg:mb-0">
                      <div className="text-gris-300 dark:text-gray-400">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          SEGUNDO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light ">
                          Infraestructura probada
                        </h5>
                      </div>
                      <div className="text-gris-300 dark:text-gray-400 md:w-[45%]">
                        <p className="lg:text-[15px]">
                          Una App que ha sido utilizada, probada y testeada ya
                          ha superado pruebas y correcciones de errores. Esto
                          proporciona una infraestructura confiable para
                          construir nuevos proyectos con menores riesgos para el
                          usuario.
                        </p>
                      </div>
                      <div className="flex justify-center">
                        <img
                          src={Card2Image}
                          className=" absolute w-[150px] md:top-[100px] md:right-[-35px] md:w-[250px]"
                        />
                      </div>
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 text-gris-300 dark:text-gray-400 p-5 h-[500px] lg:h-[650px] relative mt-5 mb-4 lg:mb-0">
                      <div>
                        <p className="mt-0 lg:text-[13px] uppercase">
                          QUINTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light ">
                          Experiencia del usuario mejorada
                        </h5>
                      </div>
                      <div className="md:w-[90%]">
                        <p className="lg:text-[15px]">
                          Si la App base ha sido diseñada con una buena
                          experiencia del usuario, utilizarla como base para
                          nuevos proyectos puede proporcionar una base sólida.
                        </p>
                      </div>
                      <img
                        src={Card5Image}
                        className="absolute bottom-[0px] right-[0px] w-[90%] lg:w-full"
                      />
                    </div>
                  </div>
                  <div className="col-span-2 lg:col-span-1 gap-4 md:grid lg:block grid-cols-2 relative ">
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 p-5 h-[auto] lg:h-[450px] relative md:mt-[-160px] lg:mt-[80px] mb-4">
                      <div className="text-gris-300 dark:text-gray-400 md:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          TERCER BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 font-bold lg:font-light ">
                          Funcionalidades predefinidas
                        </h5>
                        <p className="lg:text-[15px] mt-3">
                          Se ofrecen un conjunto de características y
                          funcionalidades establecidas. Al utilizarla como base,
                          se obtienen estas funcionalidades listas para usar, lo
                          que acelera el proceso de desarrollo.
                        </p>
                      </div>
                      <img src={Card3Image} className="md:w-[70%] mx-auto mt-10" />
                    </div>
                    <div className="card bg-[#F6F6F6] dark:bg-gray-900 p-5 relative ">
                      <div className="text-gris-300 dark:text-gray-400 md:w-[90%]">
                        <p className="mt-0 lg:text-[13px] uppercase">
                          SEXTO BENEFICIO
                        </p>
                        <h5 className="lg:text-[30px] my-2 leading-none font-bold lg:font-light ">
                          Facilita la iteración y experimentación
                        </h5>
                        <p className="lg:text-[15px] mt-5">
                          Al utilizar una App existente como punto de partida,
                          se puede centrar más en la iteración y experimentación
                          de nuevas características y conceptos, en lugar de
                          dedicar tiempo a construir la infraestructura básica
                          desde cero.
                        </p>
                      </div>
                    </div>
                    <div className="md:col-span-2 -lg:absolute right-[-50px] bottom-[0px] ml-10">
                      <img
                        src={Card6Image}
                        className="mt-10 mb-10 mx-auto w-[250px] md:w-[50%] lg:w-[350px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Apps
