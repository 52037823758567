import React from 'react'



const Icon = () => {
	return (
<svg xmlns="http://www.w3.org/2000/svg" width="200" height="240" viewBox="0 0 200 240" fill="var(--color-primary-0)">
<g opacity="0.3">
<path opacity="0.3" d="M139.67 49.25C134.203 49.2447 128.961 47.0704 125.095 43.2046C121.229 39.3387 119.055 34.097 119.05 28.6299V0H26.91C19.773 0 12.9284 2.83523 7.88176 7.88184C2.83516 12.9284 0 19.7729 0 26.9099V189.13C0.0105984 196.26 2.85043 203.094 7.89589 208.132C12.9413 213.17 19.78 216 26.91 216H137.51C141.045 216.001 144.545 215.306 147.811 213.955C151.077 212.603 154.045 210.621 156.545 208.122C159.045 205.623 161.028 202.656 162.381 199.39C163.734 196.125 164.43 192.625 164.43 189.09V49.21L139.67 49.25Z" fill="currentFill"/>
</g>
<path d="M164.43 49.25H139.67C134.203 49.2447 128.961 47.0704 125.095 43.2046C121.229 39.3387 119.055 34.097 119.05 28.6299V0L164.43 49.25Z" fill="currentFill"/>
<path d="M183.94 170.61H58.9C50.0303 170.61 42.84 177.8 42.84 186.67V223.95C42.84 232.82 50.0303 240.01 58.9 240.01H183.94C192.81 240.01 200 232.82 200 223.95V186.67C200 177.8 192.81 170.61 183.94 170.61Z" fill="currentFill"/>
<path d="M80.49 213.12V220.37H73V213.12H80.49Z" fill="white"/>
<path d="M102.668 220H93.9318L103.774 190.182H114.839L124.682 220H115.946L109.423 198.393H109.19L102.668 220ZM101.037 208.236H117.46V214.293H101.037V208.236ZM123.288 190.182H132.315L138.139 202.295H138.372L144.195 190.182H153.222L142.274 210.624V220H134.237V210.624L123.288 190.182Z" fill="white"/>
<path d="M52.5714 57.2137L38 72.7435H62.7251C64.9423 72.7717 66.4669 73.446 67.2619 73.8795C69.6049 75.1583 70.7588 77.1611 71.8154 78.9996C72.7446 80.6139 73.2729 82.0703 73.5781 83.0784C67.4195 96.3795 61.2592 109.681 55.1006 122.982H72.342L82.2341 101.538L96.7954 132.586C97.156 133.346 98.1875 135.26 100.45 136.626C102.86 138.081 105.206 138.035 106.04 137.98C112.301 137.98 118.562 137.98 124.823 137.98L135 122.445H115.246C113.636 122.465 112.442 122.095 111.719 121.799C108.613 120.532 107.108 117.765 106.394 116.45C105.879 115.503 105.56 114.661 105.366 114.063C92.9865 87.1772 83.658 67.532 81.2345 62.6079C80.7347 61.5915 79.8474 60.1466 78.2977 58.9758C75.3559 56.7537 71.9127 56.943 68.4191 57.0659C64.8383 57.1921 59.5954 57.3067 52.5714 57.2137Z" fill="currentFill"/>
</svg>
	)
}

export default Icon