import React from "react";

import styles from '../../index.module.css'

const BlogTags = ({ tags }) => {
  return (
    <>
      <div className={`${styles.inner}`} >
        <div className={`${styles.tagcloud}`} >
          {tags.map((data, index) => (
            <a href="#" key={index}>
              {data.name}
            </a>
          ))}
        </div>
      </div>
    </>
  );
};

export default BlogTags;
