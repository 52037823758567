import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate, useParams } from "react-router-dom";

import { useAppContext } from "@home/context/Context";

import logo from "@home/assets/images/logo/logo.png";
import logoDark from "@home/assets/images/light/logo/logo-dark.png";
import Nav from "./Nav";
import DarkSwitch from "./dark-switch";

import styles from '../index.module.css'


const Header = ({ headerTransparent, headerSticky, btnClass }) => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const {lng} = useParams()

  const { isLightTheme, activeMobileMenu, setActiveMobileMenu, toggleTheme } =
    useAppContext();



    const [themeMode, setThemeMode] = useState(() => {
      const storedMode = localStorage.getItem('ay-theme')
      return storedMode ? storedMode : 'light'
  })

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      if (scrolled > 200) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);




  const handleOpenTest = () => {
    // navigate(`/${lng}/login`)
    navigate(`/how-to-use`)
  }


  const handleOpenLogin = () => {
    navigate(`/${lng}/login`)
  }

  
  return (
    <>
      <DarkSwitch isLight={isLightTheme} switchTheme={toggleTheme} />
      <header
        className={`${styles["rainbow-header"]} ${styles["header-default"]} ${styles['headerTransparent']} ${styles[headerSticky]} ${isSticky ? styles.sticky : ""
          }`}
      >
        <div className={`${styles.container} ${styles["position-relative"]}`} >
          <div 
          className={`${styles.row} ${styles['align-items-center']} ${styles['row--0']} ${styles["ml--5"]}`} 
          style={{width: '100%'}}
          >
            <div className={`${styles["col-lg-2"]} ${styles["col-md-6"]} ${styles["col-6"]}`} >
              <div className={styles.logo} >
                <button onClick={() => navigate(`/${lng}`)}>
            
                  <svg 
                  style={{ 
                    zIndex: 30,
                    fill: themeMode == 'dark' ? '#fff' : 'var(--color-border-2)'
                  }} 
                  width="40" height="40" viewBox="0 0 222 133" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M20.0248 0.222384C16.9731 2.92022 13.6417 6.22923 10.3175 10.2326C5.75094 15.7259 2.41954 21.0529 0 25.5951C17.9286 25.2117 31.77 25.313 38.887 25.6168C40.5436 25.6891 43.3555 25.8627 46.3091 27.4864C50.1455 29.5912 52.0309 32.8857 53.7602 35.9091C55.2788 38.5671 56.1471 40.9612 56.6448 42.6175C46.567 64.4967 36.4892 86.3724 26.4115 108.252H54.6212L70.806 72.9808L94.6308 124.048C95.223 125.299 96.9086 128.446 100.611 130.695C104.229 132.894 107.873 132.908 109.758 132.919C117.358 132.966 128.9 133.071 143.356 132.926C146.585 129.831 150.229 125.878 153.811 120.974C157.383 116.085 160.035 111.43 162 107.427C144.932 107.467 131.821 107.387 124.824 107.366C123.756 107.362 121.565 107.333 119.051 106.302C113.969 104.219 111.506 99.6663 110.336 97.5037C109.493 95.945 108.974 94.5599 108.654 93.5762C88.4003 49.3548 73.1383 17.0423 69.1748 8.94153C68.6189 7.8096 67.2675 5.07198 64.3684 2.96723C60.503 0.160904 56.1071 0.0668788 53.6658 0.0487967C45.4263 -0.0126821 33.9716 -0.0669284 20.0248 0.222384Z" fill="currentFill" 
                  /> </svg>
                </button>
              </div>
            </div>

            <div className={`${styles["col-lg-8"]} ${styles["d-none"]} ${styles["d-lg-block"]}`} >
              <nav className={`${styles["mainmenu-nav"]} ${styles["d-none"]} ${styles["d-lg-flex"]} ${styles["justify-content-center"]}`} >
                <Nav />
              </nav>
            </div>

            <div className={`${styles["col-lg-2"]} ${styles["col-md-6"]} ${styles["col-6"]} ${styles["position-static"]}`} >
              <div className={styles["header-right"]} >
                <div className={`${styles['header-btn']}`} >
                  <button 
                  className={`${styles['btn-default']}`} 
                  // çhref="/text-generator"
                  onClick={() => handleOpenTest()}
                  >
                    <span>Demo</span>
                  </button>
                  <button 
                  className={`${styles[btnClass]}`} 
                  onClick={() => handleOpenLogin()}
                  >
                    <span>{t("home1.header.component0.text1")}</span>
                  </button>
                </div>

                <div className={`${styles["mobile-menu-bar"]} ${styles["ml--5"]} ${styles["d-flex"]} ${styles["justify-content-end"]} ${styles["d-lg-none"]}`} >
                  <div className={styles.hamberger} >
                    <button
                      className={`${styles["hamberger-button"]}`}
                      onClick={() => setActiveMobileMenu(!activeMobileMenu)}
                    >
                      <svg fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M5 7h14M5 12h14M5 17h14" />
                      </svg>

                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
