const pricing = {
    "t0": "Analysieren und erkunden Sie Ihre Metriken",
    "t1": "Keine Überraschungen, so sind unsere Preise",
    "t2": "7 Tage kostenlose Testversion",
    "t3": "BELIEBTES",
    "t4": "149€",
    "t5": "/Monat",
    "t6": "Business",
    "t7": "Für Unternehmen, die kontinuierliche Analysen nach verschiedenen Akteuren benötigen.",
    "t8": "5 Benutzer inklusive",
    "t9": "10 visuelle Add-ons",
    "t10": "Vektorisierte Daten",
    "t11": "50 Daten-Graphiken",
    "t12": "100 Fragen an die Daten",
    "t13": "200 Klicks auf HeatMap",
    "t14": "100GB Daten",
    "t15": "Prioritäts-Support",
    "t16": "Videokonferenzen",
    "t17": "Suchmaschine",
    "t18": "Plan auswählen",
    "t19": "49€",
    "t20": "/Monat",
    "t21": "Basic",
    "t22": "Für aufstrebende Ideen, die ihre KPIs analysieren möchten, um Strategien zu definieren.",
    "t23": "1 Benutzer inklusive",
    "t24": "5 visuelle Add-ons",
    "t25": "Vektorisierte Daten",
    "t26": "10 Daten-Graphiken",
    "t27": "20 Fragen an die Daten",
    "t28": "50 Klicks auf HeatMap",
    "t29": "5GB Daten",
    "t30": "E-Mail-Support",
    "t31": "Videokonferenzen",
    "t32": "Suchmaschine",
    "t33": "Plan auswählen",
    "t34": "349€",
    "t35": "/Monat",
    "t36": "Start Up",
    "t37": "Für Start-Up-Teams, die ihre eigenen Analysen ohne Grenzen erstellen möchten.",
    "t38": "15 Benutzer inklusive",
    "t39": "Unbegrenzte visuelle Add-ons",
    "t40": "Vektorisierte Daten",
    "t41": "Unbegrenzte Daten-Graphiken",
    "t42": "Unbegrenzte Fragen an die Daten",
    "t43": "500 Klicks auf HeatMap",
    "t44": "500GB Daten",
    "t45": "Dringender Support",
    "t46": "Videokonferenzen",
    "t47": "Suchmaschine",
    "t48": "Plan auswählen",
    "t49": "KOSTENLOS",
    "t50": "*7 Tage",
    "t51": "Entdeckung",
    "t52": "Perfekt, um mit punktuellen Analysen zu beginnen, ohne langfristige Möglichkeiten zu haben.",
    "t53": "1 Benutzer inklusive",
    "t54": "1 visuelles Add-on",
    "t55": "Vektorisierte Daten",
    "t56": "1 Daten-Graphik",
    "t57": "5 Fragen an die Daten",
    "t58": "Kein HeatMap",
    "t59": "500MB Daten",
    "t60": "Kein Support",
    "t61": "Videokonferenzen",
    "t62": "Suchmaschine",
    "t63": "Plan auswählen",
    "t64": "Wenn Sie mehr Benutzer und mehr Optionen benötigen, kontaktieren Sie unser Vertriebsteam",
    "t65": "Vertrieb",
    "t66": "Vergleichen Sie unsere Bezahlpläne",
    "t67": "Und entdecken Sie, welcher Ihren Bedürfnissen am besten entspricht",
    "t68": "Basic",
    "t69": "Beginnen",
    "t70": "Business",
    "t71": "Verstehen",
    "t72": "Start Up",
    "t73": "Analysieren",
    "t74": "FÄHIGKEITEN UND ZUGÄNGE",
    "t75": "Benutzer",
    "t76": "1",
    "t77": "5",
    "t78": "15",
    "t79": "Add-ons",
    "t80": "Unbegrenzt",
    "t81": "5",
    "t82": "10",
    "t83": "Vektorisierte Daten",
    "t84": "GB Daten",
    "t85": "Bis zu 5 GB",
    "t86": "Bis zu 100 GB",
    "t87": "Bis zu 500 GB",
    "t88": "Personalisierte Darstellung",
    "t89": "Anzahl der Graphiken",
    "t90": "10",
    "t91": "50",
    "t92": "Unbegrenzt",
    "t93": "Anzahl der Fragen",
    "t94": "20",
    "t95": "100",
    "t96": "Unbegrenzt",
    "t97": "Klicks mit HeatMap",
    "t98": "50",
    "t99": "200",
    "t100": "500",
    "t101": "Videokonferenzen und Suche",
    "t102": "PERSONALISIERUNG UND CODE",
    "t103": "Personalisiertes Logo",
    "t104": "Bald",
    "t105": "Vordefinierte Themen",
    "t106": "Personalisierte Themen",
    "t107": "Bald",
    "t108": "Teilen mit Subdomain",
    "t109": "Teilen mit Domain",
    "t110": "Bald",
    "t111": "Zugriff auf IAM und Banking",
    "t112": "Verwendung von Add-ons",
    "t113": "Bald",
    "t114": "Bearbeiten von Prompts",
    "t115": "Zugriff auf Quellcode",
    "t116": "Bald",
    "t117": "Echtzeitbearbeitung",
    "t118": "Bald",
    "t119": "BERICHTE",
    "t120": "PDF-Export",
    "t121": "E-Mail-Versand",
    "t122": "Teilen per Link",
    "t123": "SUPPORT",
    "t124": "E-Mail-Support",
    "t125": "Prioritäts-Support",
    "t126": "One-to-One-Hilfe",
    "t127": "Was, wenn ich mehr benötige, um zu analysieren?",
    "t128": "Sie können Ihren Plan ändern. Wenn Ihre Anforderungen jedoch zu stark skalieren, können Sie nach mehr Graphiken, mehr Fragen, mehr Add-ons für Ihre Interface-Darstellungen, mehr Klicks, mehr GB Daten... Ihre Extras werden zu Ihrem bestehenden Kontingent hinzugefügt. Sie können jederzeit stornieren. Wenn Sie einen personalisierten Plan benötigen, kontaktieren Sie bitte unseren Vertrieb.",
    "t129": "Graphiken",
    "t130": "Paket 10",
    "t131": "15€",
    "t132": "Fragen",
    "t133": "Paket 20",
    "t134": "10€",
    "t135": "Add-on",
    "t136": "Extra",
    "t137": "10€",
    "t138": "HeatMap",
    "t139": "Klick",
    "t140": "0,5€",
    "t141": "GB Cloud",
    "t142": "GB",
    "t143": "1€"
  }
  
  export default pricing