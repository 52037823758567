import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Sal from "sal.js";

import RoadmapData from '@src/locales/es/home1'

import CtaTwo from "../CallToActions/Cta-Two";


import styles from '../index.module.css'

const Roadmap = () => {

  const { t } = useTranslation()
  useEffect(() => {
    Sal();
  }, []);
  return (
    <>
      <div className={`${styles["roadmap-section"]} ${styles["rainbow-section-gap-big"]} ${styles["rainbow-section-gapBottom"]}`} >
        <div className={`${styles.container}`} >
          {RoadmapData &&
            RoadmapData.roadmap.data.map((data, index) => (
              <div className={`${styles.row} ${styles.changelog_info}`} id="v120" key={index}>
                <div className={`${styles["col-lg-2"]} ${styles["changelog_date"]}`} >
                  <div className={`${styles.c_date}`} >
                    <h6>{data.heading} </h6>
                    <p>{data.date}</p>
                  </div>
                </div>
                <div className={`${styles["col-lg-2"]}`} >
                  <div className={styles.version_info} >
                    {data.isCheck ? (
                      <div className={`${styles.c_version}`} >
                        <svg fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 11.917 9.724 16.5 19 7.5" />
                        </svg>
                      </div>
                    ) : data.isLoading ? (
                      <div className={`${styles.c_version} ${styles["bg-yellow"]}`} >
                        <svg fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 17h6l3 3v-3h2V9h-2M4 4h11v8H9l-3 3v-3H4V4Z" />
                        </svg>

                      </div>
                    ) : (
                      <div className={`${styles.c_version} ${styles["bg-dark"]}`} >
                        <svg fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm0 0v6M9.5 9A2.5 2.5 0 0 1 12 6.5" />
                        </svg>

                      </div>
                    )}

                    {data.isBorader ? (
                      <div className={`${styles.line} ${styles.bottom_half} ${
                        data.isCheck ? '' : data.isLoading ? styles['bg-yellow'] : styles['bg-dark'] 
                      }`} ></div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className={`${styles["col-lg-6"]}`} >
                  <div className={`${styles.changelog_content}`} >
                    <p className={`${styles.text}`} >{data.desc}</p>
                    <p className={styles.title} >{data.title}</p>
                    <ul className={styles["content-list"]} >
                      {data.list &&
                        data.list.map((item, i) => (
                          <li key={i}>{item.desc}</li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className={`${styles["rainbow-callto-action"]} ${styles["rainbow-call-to-action"]} ${styles["style-8"]} ${styles["content-wrapper"]} ${styles["rainbow-section-gapBottom"]}`} >
        <div className={`${styles.container}`} >
          <div className={`${styles.row} ${styles["row--0"]} ${styles["align-items-center"]}`} >
            <div className={`${styles["col-lg-12"]}`} >
              <div className={`${styles.inner}`} >
                <div className={`${styles.content} ${styles["text-center"]}`} >
                  <h3
                    className={`${styles.title} ${styles["sal-animate"]}`}
                    data-sal="slide-up"
                    data-sal-duration="400"
                    data-sal-delay="200"
                  >
                    {t("home1.web.roadmap.text1")}
                  </h3>
                  <p
                    className={`${styles["sal-animate"]}`}
                    data-sal="slide-up"
                    data-sal-duration="400"
                    data-sal-delay="300"
                  >
                    {t("home1.web.roadmap.text2")}
                  </p>
                  <div
                    className={`${styles["call-to-btn"]} ${styles["text-center"]} ${styles["mt--30"]} ${styles["sal-animate"]}`}
                    data-sal="slide-up"
                    data-sal-duration="400"
                    data-sal-delay="400"
                  >
                    <button className={`${styles["btn-default"]} ${styles["btn-icon"]}`} href="contact">
                      {t("home1.web.roadmap.button1")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles["rainbow-cta-area"]} ${styles["rainbow-section-gap"]} ${styles["rainbow-section-gapBottom-big"]}`} >
        <div className={`${styles.container}`} >
          <CtaTwo />
        </div>
      </div>
    </>
  );
};

export default Roadmap;
