
import React from 'react'


const Icon = () => {
    return (
        <svg width="147" height="147" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M105.691 117.32H40.3088C36.8246 117.32 34 114.496 34 111.011V35.3086C34 31.8244 36.8246 29 40.3088 29H82.75L112 58.2488V111.011C112 114.496 109.175 117.32 105.691 117.32Z" fill="url(#paint0_linear_557_3216)"/>
<g filter="url(#filter0_f_557_3216)">
<rect x="47.4779" y="70.293" width="51.0441" height="24.0873" fill="var(--color-primary-3)" fillOpacity="0.33" />
</g>
<path opacity="0.12" d="M81.5594 97.426L77.4573 81.0188C77.35 80.5897 77.1024 80.2087 76.7538 79.9365C76.4051 79.6643 75.9755 79.5164 75.5331 79.5164H73.5497V73.5663H79.5V69.5995H73.5497V65.6327H79.5V61.666H73.5497V59.6826H69.5827V65.6327H63.6324V69.5995H69.5827V73.5663H63.6324V77.533H69.5827V79.5164H67.5993C67.1569 79.5164 66.7273 79.6643 66.3787 79.9365C66.03 80.2087 65.7824 80.5897 65.6751 81.0188L61.5731 97.426C61.2806 98.5957 61.2587 99.8165 61.5088 100.996C61.7589 102.175 62.2745 103.282 63.0165 104.232C63.7585 105.183 64.7073 105.951 65.7908 106.48C66.8744 107.009 68.0642 107.284 69.2699 107.284H73.8626C75.0682 107.284 76.258 107.009 77.3416 106.48C78.4251 105.951 79.3739 105.183 80.1159 104.232C80.8579 103.282 81.3735 102.175 81.6236 100.996C81.8738 99.8165 81.8518 98.5957 81.5594 97.426ZM71.5662 101.334C70.7816 101.334 70.0147 101.101 69.3623 100.665C68.71 100.229 68.2015 99.6096 67.9013 98.8848C67.601 98.16 67.5225 97.3624 67.6755 96.5929C67.8286 95.8234 68.2064 95.1166 68.7612 94.5619C69.316 94.0071 70.0228 93.6293 70.7923 93.4762C71.5618 93.3232 72.3594 93.4017 73.0843 93.702C73.8091 94.0022 74.4287 94.5106 74.8646 95.163C75.3005 95.8153 75.5331 96.5822 75.5331 97.3668C75.5331 98.4188 75.1152 99.4278 74.3712 100.172C73.6273 100.916 72.6183 101.334 71.5662 101.334Z" fill="white"/>
<path d="M57.0475 89.5791V87.7538L64.306 77.5692H57.0333V75.0336H68.1696V76.8589L60.904 87.0436H68.1838V89.5791H57.0475ZM75.0188 75.0336V89.5791H71.9435V75.0336H75.0188ZM78.949 89.5791V75.0336H84.6877C85.7909 75.0336 86.7307 75.2443 87.5073 75.6657C88.2838 76.0824 88.8756 76.6624 89.2828 77.4058C89.6948 78.1444 89.9007 78.9967 89.9007 79.9626C89.9007 80.9285 89.6924 81.7808 89.2757 82.5194C88.8591 83.2581 88.2554 83.8334 87.4646 84.2453C86.6787 84.6572 85.727 84.8632 84.6095 84.8632H80.9519V82.3987H84.1124C84.7042 82.3987 85.1919 82.2969 85.5754 82.0933C85.9637 81.885 86.2525 81.5985 86.4419 81.2339C86.636 80.8646 86.7331 80.4408 86.7331 79.9626C86.7331 79.4797 86.636 79.0583 86.4419 78.6984C86.2525 78.3338 85.9637 78.0521 85.5754 77.8532C85.1872 77.6497 84.6948 77.5479 84.0982 77.5479H82.0243V89.5791H78.949Z" fill="white"/>
<mask id="mask0_557_3216"  maskUnits="userSpaceOnUse" x="34" y="29" width="78" height="89">
<path d="M105.691 117.32H40.3088C36.8246 117.32 34 114.496 34 111.011V35.3086C34 31.8244 36.8246 29 40.3088 29H82.75L112 58.2488V111.011C112 114.496 109.175 117.32 105.691 117.32Z" fill="url(#paint1_linear_557_3216)"/>
</mask>
<g mask="url(#mask0_557_3216)">
<ellipse opacity="0.05" cx="32.7096" cy="25.989" rx="64.0919" ry="61.5086" fill="url(#paint2_linear_557_3216)"/>
<ellipse opacity="0.05" cx="32.7096" cy="25.9892" rx="40.2904" ry="38.5683" fill="url(#paint3_linear_557_3216)"/>
</g>
<mask id="mask1_557_3216" maskUnits="userSpaceOnUse" x="34" y="29" width="78" height="89">
<path d="M105.691 117.32H40.3088C36.8246 117.32 34 114.496 34 111.011V35.3086C34 31.8244 36.8246 29 40.3088 29H82.75L112 58.2488V111.011C112 114.496 109.175 117.32 105.691 117.32Z" fill="url(#paint4_linear_557_3216)"/>
</mask>
<g mask="url(#mask1_557_3216)">
</g>
<g filter="url(#filter1_d_557_3216)">
<path d="M112 58.2488H104.75C92.5997 58.2488 82.75 48.3991 82.75 36.2488V29L112 58.2488Z" fill="url(#paint5_linear_557_3216)"/>
</g>
<defs>
<filter id="filter0_f_557_3216" x="8.47794" y="31.293" width="129.044" height="102.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="19.5" result="effect1_foregroundBlur_557_3216"/>
</filter>
<filter id="filter1_d_557_3216" x="59.75" y="5" width="79.25" height="79.249" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="1"/>
<feGaussianBlur stdDeviation="12.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_557_3216"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_557_3216" result="shape"/>
</filter>
<linearGradient id="paint0_linear_557_3216" x1="73" y1="29" x2="73" y2="117.32" gradientUnits="userSpaceOnUse">
<stop stopColor="var(--color-primary-3)"/>
<stop offset="1" stopColor="var(--color-primary-3)"/>
</linearGradient>
<linearGradient id="paint1_linear_557_3216" x1="73" y1="29" x2="73" y2="117.32" gradientUnits="userSpaceOnUse">
<stop stopColor="var(--color-primary-3)"/>
<stop offset="1" stopColor="var(--color-primary-3)"/>
</linearGradient>
<linearGradient id="paint2_linear_557_3216" x1="37.3631" y1="28.1027" x2="56.7984" y2="79.6863" gradientUnits="userSpaceOnUse">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="white"/>
</linearGradient>
<linearGradient id="paint3_linear_557_3216" x1="35.6349" y1="27.3146" x2="47.7984" y2="59.6799" gradientUnits="userSpaceOnUse">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="white"/>
</linearGradient>
<linearGradient id="paint4_linear_557_3216" x1="73" y1="29" x2="73" y2="117.32" gradientUnits="userSpaceOnUse">
<stop stopColor="var(--color-primary-3)"/>
<stop offset="1" stopColor="var(--color-primary-3)"/>
</linearGradient>
<linearGradient id="paint5_linear_557_3216" x1="97.375" y1="29" x2="97.375" y2="58.2488" gradientUnits="userSpaceOnUse">
<stop stopColor="var(--color-primary-3)"/>
<stop offset="1" stopColor="var(--color-primary-3)"/>
</linearGradient>
</defs>
</svg>

    )
}

export default Icon