const settings = {
    t1: "Accueil",
    t2: "Aythen DB",
    t3: "Kanban",
    t4: "Mes documents",
    t5: "Contrats",
    t6: "Facturation",
    info: {
        t0: `Ajouter un tag`,
        t1: `Clé`,
        t2: `Valeur`,
        info: {
          t0: `hors taxes avec remise`,
          t1: `Mis à jour il y a 27 minutes`
        }
    },
    invoice: {
        send: {
            "t0": "Facture",
            "t1": "Copier la facture",
            "t2": "Changer l'organisation si elle ne correspond pas",
            "t3": "Il manque des informations à remplir",
            "t4": "Numéro d'identification fiscale",
            "t5": "DNI (documents officiels)",
            "t6": "Choisissez le mode de paiement à effectuer",
            "t7": "Plus d'info",
            "t8": "Paiement",
            "t9": "Quel montant voulez-vous recevoir d'argent",
            "t10": "Contacts",
            "t11": "Sélectionnez un contact",
            "t12": "Organisation",
            "t13": "Nous vous enverrons un processus à votre compte",
            "t14": "Mode intact",
            "t15": "Je veux recevoir l'argent le plus tôt possible",
            "t16": "1",
            "t17": "Planifier un avertissement d'envoi",
            "t18": "2",
            "t19": "Rappeler demain si non payé",
            "t20": "3",
            "t21": "Rappeler chaque jour pendant 14 jours",
            "t22": "Mode bancaire",
            "t23": "Factoring plus rapide pour vos clients",
            "t24": "1",
            "t25": "Paiement immédiat et paiement sécurisé",
            "t26": "2",
            "t27": "La banque avance l'argent",
            "t28": "3",
            "t29": "Meilleures conditions de paiement",
            "t30": "Mode programmé",
            "t31": "Dites-nous un jour pour demander le paiement",
            "t32": "1",
            "t33": "Programmer l'envoi du jour",
            "t34": "2",
            "t35": "Avertir pendant 14 jours",
            "t36": "3",
            "t37": "Meilleures conditions de paiement",
            "t38": "Envoyer la facture",
            "t39": "Paiement réussi",
            "t40": "Effectuer un virement",
            "t41": "Vous allez recevoir",
            "t42": "2.000$",
            "t43": "Carte actuelle",
            "t44": "**** 4543",
            "t45": "Processus choisi",
            "t46": "Paiement instantané",
            "t47": "Payer la facture",
            "t48": "Annuler"
        }
    },
    status: {
        "t0": "Aythen",
        "t1": "statut",
        "t2": "Support de contact",
        "t3": "État en direct",
        "t4": "Historique",
        "t5": "S'ABONNER",
        "t6": "Aythen API",
        "t7": "99,999% SLA temps d'activité",
        "t8": "15 janv.",
        "t9": "100% uptime",
        "t10": "1er janv.",
        "t11": "Aujourd'hui",
        "t12": "Légende:",
        "t13": "Succès",
        "t14": "En attente",
        "t15": "Erreur",
        "t16": "État du système",
        "t17": "Maintenance planifiée pour Bancontact",
        "t18": "Aythen",
        "t19": "© 2024 Aythen, Inc",
        "t20": "Visitez notre site web",
        "t21": "Termes et confidentialité",
        "t22": "Email",
        "t23": "Message texte",
        "t24": "Recevez des notifications par email chaque fois que Stripe crée, met à jour ou résout un incident.",
        "t25": "Adresse email",
        "t26": "S'abonner par email",
        "t27": "Pour mettre à jour vos préférences email, ré-abonnez.",
        "t28": "Pour plus d'informations sur l'abonnement, visitez nos instructions dans le Support.",
        "t29": "Ce site est protégé par reCAPTCHA et les politiques de confidentialité et de service de Google s'appliquent.",
        "t30": "Recevez des notifications par message texte chaque fois que Stripe crée ou résout un incident.",
        "t31": "Code pays",
        "t32": "Numéro de téléphone",
        "t33": "S'abonner par message texte",
        "t34": "Pour mettre à jour vos préférences, ré-abonnez.",
        "t35": "Pour plus d'informations sur l'abonnement, visitez nos instructions dans le Support.",
        "t36": "Les frais de message et de données peuvent s'appliquer. En vous abonnant, vous acceptez les termes de service d'Atlassian et la politique de confidentialité d'Atlassian. Ce site est protégé par reCAPTCHA et les politiques de confidentialité et de service de Google s'appliquent."
    },
    billing: {
        t1: `Remplissez les données…`,
        t2: `Devise`,
        t3: `Éditer`,
        t4: `Voici vos données d'enregistrement et de facturation. Vous pourrez les modifier pour vérifier votre accès aux différentes fonctionnalités que Aythen Lite vous offre.`,
        t5: `Modifier les données`,
        t6: `Consommation de données`,
        t7: `Email de contact`,
        t8: `À titre de récapitulation, chaque mois vous aurez votre facture électronique préparée automatiquement à votre email d'enregistrement.`,
        t9: `Email de facturation`,
        t10: `Enregistrer`,
        t12: `Jeton de GPT`,
        t13: `Vous pouvez créer un jeton de GPT pour identifier et authentifier vos requêtes API de manière unique.`,
        t14: `Jeton`,
        t15: `Enregistrer`,
        t16: `Limites de consommation`,
        t17: `Dans votre licence, vous pourriez avoir besoin de plus d'interactions ou de GB de stockage. 
Pour ces usages supplémentaires, vous pouvez configurer des alertes en fonction d'un total.`,
        t177: `En savoir plus`,
        t18: `Ajouter une alerte à partir de `,
        t19: `Coûts mensuels`,
        t20: `Enregistrer l'alerte`,
        t21: `Identification NIF/VAT`,
        t22: `Entrez votre numéro d'identification fiscale pour pouvoir déduire la TVA de vos factures. `,
        t222: `VAT`,
        t23: `Enregistrer`,
        t24: `Méthodes de paiement`,
        t25: `Choisissez entre les méthodes de paiement suivantes pour votre compte.`,
        t26: `Comment voulez-vous gérer votre licence:`,
        t266: `Carte de crédit`,
        t267: `SEPA Direct Debt`,
        t268: `table manquante`,
        t27: `Types de notifications`,
        t28: `Sélectionnez et configurez vos préférences de notification. Quand vous recevrez les notifications?`,
        t29: `Types de notifications`,
        t30: `Notifications par email`,
        t31: `Rappels quotidiens`,
        t32: `Notifications d'événements spéciaux:`,
        t33: `Notifications de message privé:`,
        t34: `Factures reçues`,
        t340: `Aythen Lite Premium`,
        t341: `Mise à jour en Premium`,
        t342: `Lorem ipsum dolor et epsum holend.`,
        t343: `Lorem ipsum dolor et epsum holend et erem super tupper dolem herem
    superssupostom hellom super start.`,
        t344: `Lorem ipsum dolor et teresum`,
        t345: `Lorem ipsum dolor et teresum`,
        t346: `Gratuit`,
        t347: `Pour toujours`,
        t350: `Ajouter`,
        t35: `Vous trouverez ici votre historique entre reçues et envoyées.`,
        t36: `Factures envoyées`,
        t360: `Factures reçues`,
        t361: `de`,
        t37: `Mois`,
        t38: `Année`,
        t39: `Numéro`,
        t40: `Méthode de paiement`,
        t41: `Statut`,
        t42: `Total (TVA incl.)`,
        t43: `Télécharger`,
        t44: `Supprimer le compte`,
        t45: `Êtes-vous sûr de vouloir supprimer votre compte? Notez que cette action ne peut pas être annulée et votre compte sera désactivée dans 48 heures si vous confirmez.`,
        t46: `Supprimer`,

        x1: `Éditer le nom du compte`,
        x2: `Ceci est un compte d'entreprise`,
        x3: `Nom de l'entreprise`,
        x4: `Adresse`,
        x5: `Adresse 2`,
        x6: `Code postal`,
        x7: `Ville`,
        x8: `Pays`,
        x9: `Région`,
        x10: `Confirmer les changements`,
    },
    contracts: {
        t1: `Organisation`,
        t2: `Contrats de compte`,
        t3: `Nom`,
        t4: `Statut`,
        t5: `Fin à`,
        t6: `Télécharger`,
        t7: `Organisez vos accords commerciaux.`,
        t8: `Quels avantages?`,
        t0: 'Ajouter un contrat',
        popup: {
            t0: `Ouvert le `,
            t1: `Statut`,
            t2: `Envoyer avec avertissement`,
            t3: `Supprimer l'avertissement`,
            t4: `Envoyer une fois`,
            t5: `Envoyer en priorité`,
            t6: `Marquer signé`,
            t7: `Pour signer`,
            t8: `Pour supprimer le `,
            t9: `Signer`,
            t10: `Annuler`,
            t11: `Supprimer`
        }
    }
}

export default settings