 const vector = {
    t1: `Crear un nuevo vector`,
    t2: `Importa, guarda, trabaja, analiza y saca conclusiones de cualquier estructura de datos.`,
    t3: `Nuevo vector`,
    t4: `Vectores`,
    t5: `Vector`,
    t6: `Dimension`,
    t7: `Size`,
    t9: `CreatedAt`,
    t10: `Power Business - Matrix - Vector`,
    t11: `Las bases de datos vectoriales permiten crear experiencias únicas impulsadas por la búsqueda vectorial.  
     Aceleran el desarrollo de aplicaciones de la IA.`,
    t12: `Nueva Matrix`,
    t13: `Quickstart Documentation`,
    f0: `Descargar aquí`,
    f1: `Conectado`,
    f2: `Nuevo`,
    node: {
        data: {
            title: `Añade datos a tu informe`,
            text: `Una vez cargados, tus datos aparecerán en el`,
            label: `Campos`,
            excel: `Importar datos desde Excel`,
            sql: `Importar datos desde SQL`,
            blank: `Pegar datos en blanco`,
            export: `Exportar datos de muestra`,
            info: `Obtener datos de otra fuente`,
        }
    },
    dashboard: {
        t1: `Recuerda, siempre podrás modificar y recopilar los gráficos que quieras resaltar 
    como favoritos a partir de la información que hayas decidido analizar en los vectores.`,
        t2: `Saber más`,
    },
    modal: {
        matrix: {
            t0: 'Matrix information', 
            t1: 'ID', 
            t2: 'Estado', 
            t3: 'Activado', 
            t4: 'Tipo', 
            t5: 'Datos', 
            t6: 'Gráficos', 
            t7: 'Matrix', 
            t8: 'Preguntas', 
            t9: 'Modificado:', 
            t10: 'Creado:', 
            t11: 'Eliminar Vector', 
            t12: 'Automáticamente elimina sus claves API, y cualquier política directamente adjunta a ellos quedará huérfana.', 
            t13: 'Eliminar', 
            t14: 'Refrescar', 
        }
    }
}

export default vector