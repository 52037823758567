import React, { useEffect, useState, useRef } from "react"

import styles from "./video.module.css"

import video from "../../assets/video.mp4"

const Video = ({ isShowVideo, setIsShowVideo }) => {
  const [isShow, setIsShow] = useState("workspace")

  const overlayRef = useRef(null)
  const modalRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!(overlayRef.current && !overlayRef.current.contains(event.target))) {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          setIsShowVideo(false)
        }
      }
    }

    document.addEventListener("click", handleClickOutside)

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [isShowVideo])

  return (
    <div
      ref={overlayRef}
      className={`${styles.overlay} ${isShow == "workspace " ? styles.workspace : ""}`}
    >
      <div ref={modalRef} className={styles.modal}>
        <video width="100%" autoPlay playsinliney controls>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  )
}

export default Video
