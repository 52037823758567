import React from "react";

import { useTranslation } from "react-i18next";

import ContactForm from "./ContactForm";


import styles from '../index.module.css'

const Contact = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={`${styles["main-content"]}`} >
        <div className={`${styles["rainbow-contact-area"]} ${styles["rainbow-section-gapTop-big"]}`} >
          <div className={`${styles.container}`} >
            <div className={`${styles.row} ${styles['mt--40']} ${styles['row--15']}`} >
              <div className={`${styles["col-lg-8"]}`} >
                <div className={`${styles["contact-details-box"]}`} >
                  <div className={`${styles["profile-details-tab"]}`} >
                    <div className={`${styles["advance-tab-button"]}`} >
                      <ul
                        className={`${styles.nav} ${styles["nav-tabs"]} ${styles["tab-button-style-2"]} ${styles["justify-content-start"]}`}
                        id="settinsTab-4"
                        role="tablist"
                      >
                        <li role="presentation">
                          <a
                            href="#"
                            className={`${styles["tab-button"]} ${styles["active"]}`}
                            id="image-genarator-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#image-genarator"
                            role="tab"
                            aria-controls="image-genarator"
                            aria-selected="true"
                          >
                            <span className={styles.title} >
                              {t('home1.contact.component2.text2')}
                            </span>
                            <span className={styles.title} >
                              {t('home1.contact.component2.text3')}

                            </span>
                          </a>
                        </li>
                        <li role="presentation">
                          <a
                            href="#"
                            className={`${styles["tab-button"]}`}
                            id="email-genarator-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#email-genarator"
                            role="tab"
                            aria-controls="email-genarator"
                            aria-selected="true"
                          >
                            <span className={styles.title} >
                              {t('home1.contact.component2.text6')}
                            </span>
                            <span className={styles.title} >
                              {t('home1.contact.component2.text7')}

                            </span>
                          </a>
                        </li>
                        <li role="presentation">
                          <a
                            href="#"
                            className={`${styles.tabButton}`}
                            id="code-genarator-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#code-genarator"
                            role="tab"
                            aria-controls="code-genarator"
                            aria-selected="true"
                          >
                            <span className={styles.title} >
                              {t('home1.contact.component2.text8')}

                            </span>
                            <span className={`${styles.title}`} >
                              {t('home1.contact.component2.text9')}

                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className={styles["tab-content"]} >
                      <div
                        className={`${styles["tab-pane"]} ${styles["fade"]} ${styles["active"]} ${styles["show"]}`}
                        id="image-genarator"
                        role="tabpanel"
                        aria-labelledby="image-genarator-tab"
                      >
                        <ContactForm />
                      </div>
                      <div
                        className={`${styles["tab-pane"]} ${styles["fade"]}`}
                        id="photo-editor"
                        role="tabpanel"
                        aria-labelledby="photo-editor-tab"
                      >
                        <ContactForm />
                      </div>
                      <div
                        className={`${styles["tab-pane"]} ${styles["fade"]}`}
                        id="email-genarator"
                        role="tabpanel"
                        aria-labelledby="email-genarator-tab"
                      >
                        <ContactForm />
                      </div>
                      <div
                        className={`${styles["tab-pane"]} ${styles["fade"]}`}
                        id="code-genarator"
                        role="tabpanel"
                        aria-labelledby="code-genarator-tab"
                      >
                        <ContactForm />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles["col-lg-4"]} ${styles["mt_md--30"]} ${styles["mt_sm--30"]}`} >
                <div className={styles["rainbow-address"]} >
                  <div className={styles.icon} >
                    <svg  fill="currentColor" viewBox="0 0 24 24">
                      <path fillRule="evenodd" d="M11.906 1.994a8.002 8.002 0 0 1 8.09 8.421 7.996 7.996 0 0 1-1.297 3.957.996.996 0 0 1-.133.204l-.108.129c-.178.243-.37.477-.573.699l-5.112 6.224a1 1 0 0 1-1.545 0L5.982 15.26l-.002-.002a18.146 18.146 0 0 1-.309-.38l-.133-.163a.999.999 0 0 1-.13-.202 7.995 7.995 0 0 1 6.498-12.518ZM15 9.997a3 3 0 1 1-5.999 0 3 3 0 0 1 5.999 0Z" clipRule="evenodd" />
                    </svg>

                  </div>
                  <div className={`${styles.inner}`} >
                    <h4 className={styles.title} >
                      {t('home1.contact.component2.text10')}

                    </h4>
                    <p className={`${styles.className} ${styles["b2"]}`} >
                      {t('home1.contact.component2.text11')}

                    </p>
                  </div>
                </div>
                <div className={`${styles["rainbow-address"]}`} >
                  <div className={styles.icon} >
                    
                    <svg  fill="currentColor" viewBox="0 0 24 24">
                      <path fillRule="evenodd" d="M11 4a1 1 0 0 0-1 1v10h10.459l.522-3H16a1 1 0 1 1 0-2h5.33l.174-1H16a1 1 0 1 1 0-2h5.852l.117-.67v-.003A1.983 1.983 0 0 0 20.06 4H11ZM9 18c0-.35.06-.687.17-1h11.66c.11.313.17.65.17 1v1a1 1 0 0 1-1 1H10a1 1 0 0 1-1-1v-1Zm-6.991-7a17.8 17.8 0 0 0 .953 6.1c.198.54 1.61.9 2.237.9h1.34c.17 0 .339-.032.495-.095a1.24 1.24 0 0 0 .41-.27c.114-.114.2-.25.254-.396a1.01 1.01 0 0 0 .055-.456l-.242-2.185a1.073 1.073 0 0 0-.395-.71 1.292 1.292 0 0 0-.819-.286H5.291c-.12-.863-.17-1.732-.145-2.602-.024-.87.024-1.74.145-2.602H6.54c.302 0 .594-.102.818-.286a1.07 1.07 0 0 0 .396-.71l.24-2.185a1.01 1.01 0 0 0-.054-.456 1.088 1.088 0 0 0-.254-.397 1.223 1.223 0 0 0-.41-.269A1.328 1.328 0 0 0 6.78 4H4.307c-.3-.001-.592.082-.838.238a1.335 1.335 0 0 0-.531.634A17.127 17.127 0 0 0 2.008 11Z" clipRule="evenodd" />
                    </svg>

                  </div>
                  <div className={`${styles.inner}`} >
                    <h4 className={styles.title} >
                      {t('home1.contact.component2.text12')}
                    </h4>
                    <p className={`${styles.className} ${styles["b2"]}`} >
                      {t('home1.contact.component2.text122')}

                    </p>
                  </div>
                </div>
                <div className={`${styles["rainbow-address"]}`} >
                  <div className={`${styles.icon}`} >
                  <svg  fill="currentColor" viewBox="0 0 24 24">
                      <path fillRule="evenodd" d="M12 2a7 7 0 0 0-7 7 3 3 0 0 0-3 3v2a3 3 0 0 0 3 3h1a1 1 0 0 0 1-1V9a5 5 0 1 1 10 0v7.083A2.919 2.919 0 0 1 14.083 19H14a2 2 0 0 0-2-2h-1a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h1a2 2 0 0 0 1.732-1h.351a4.917 4.917 0 0 0 4.83-4H19a3 3 0 0 0 3-3v-2a3 3 0 0 0-3-3 7 7 0 0 0-7-7Zm1.45 3.275a4 4 0 0 0-4.352.976 1 1 0 0 0 1.452 1.376 2.001 2.001 0 0 1 2.836-.067 1 1 0 1 0 1.386-1.442 4 4 0 0 0-1.321-.843Z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className={`${styles.inner}`} >
                    <h4 className={styles.title} >
                      {t('home1.contact.component2.text13')}

                    </h4>
                    <p className={`${styles.className} ${styles["b2"]}`} >
                      {t('home1.contact.component2.text133')}

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
