const web = {
    t1: {
        '01': `Modelliere deine`,
        '02': `Daten`,
        '03': `fortgeschritten,`,
        '04': `prognostiziere deine`,
        '05': `Matrix`,
        '06': `für jede mögliche Realität und analysiere`,
        '07': `Big Data`,
        '08': `mit vektorisierten Datenbanken`,
    },
    t2: `JETZT BEGINNEN`,
    t3: `Auf deinem neuen Betriebssystem`,
    t4: {
        '01': `Offline`, 
        '02': `Online`, 
        '03': `Öffentlich`, 
        '04': `Privat`, 
    },
    tassistant: {
        '01': `Geschwindigkeit`,
        '02': `Textur`,
        '03': `Verarbeitung`,
    },
    t5: `Eine Welt voller Möglichkeiten`,
    t6: {
        '01': `Dank der`,
        '02': `vektorisierter Datenbanken`,
        '03': `gehst du von`,
        '04': `statischen Daten`,
        '05': `zu`,
        '06': `dynamischen Daten über.`,
        '07': `Das Arbeiten mit vielen Daten gleichzeitig ist schwierig, daher ist eine`,
        '08': `Null-Latenz in bestimmten Dimensionen`,
        '09': `für einen schnellen und vielseitigen Zugriff erforderlich.`,
    },
    t7: `Jetzt anmelden`,
    t8: `ZEIT SPAREN . ZEIT SPAREN . ZEIT SPAREN`,
    t9: `Ziehe deine eigenen Schlussfolgerungen`,
    t10: `Information ist Macht`,
    t11: {
        '01': `Jetzt kannst du deine Daten verstehen!`,
        '02': `Mit der Segmentierung und dem Konzept des`,
        '03': `K-Means`,
        '04': `hast du ein Werkzeug, um Daten individuell zu analysieren.`,
    },
    t12: `Jetzt starten`,
    t13: `Speicherplatz verfügbar in der Cloud. Alles skalierbar auf privaten und sicheren Instanzen.`,
    t14: `Mal schneller als bei der Arbeit mit herkömmlichen Datenbanken.`,
    t15: `Mögliche Darstellungen zum Importieren Ihrer Metriken und KPIs.`,
    t16: `Analyse von Daten zwischen Dimensionen`,
    t17: `Jede Dimension hat Agenten.`,
    t18: {
        '01': `In jeder Dimension können Sie`,
        '02': `Agentenströme`,
        '03': `erstellen, um Interaktionsnetzwerke zu erstellen.`
    },
    t19: {
        '01': `In jedem Agenten wählen Sie die`,
        '02': `Strategie`,
        '03': `zur Verknüpfung`,
        '04': `jeder Daten.`,
        '05': `jeder Daten.`,
    },
    t20: `Beginnen Sie Ihren KOSTENLOSEN Test`,
    t21: `Dimensionen`,
    t22: `Agenten`,
    t23: `Daten, die Ideen verwalten`,
    t24: `Ihre Daten sind der erste Schritt`,
    t25: {
        '01': `Sie sind Ihr Schlüssel, um`,
        '02': `erstaunliche Anwendungen`,
        '03': `zu erstellen. Sie sind nicht nur Informationen,`,
        '04': `sie sind der Rohstoff`,
        '05': `für die Schaffung von`,
        '06': `innovativen Ideen und deren Analyse.`,
        '07': `Wie arbeiten wir mit Daten?`,
    },
    t26: `Ihr Arbeitsbereich`,
    t27: `Speichern Sie alle benötigten Inhalte in Ihrer privaten oder öffentlichen Cloud je nach Bedarf.`,
    t28: `Heatmap`,
    t29: `Eine Darstellung, die die Verteilung und Konzentration bestimmter Werte innerhalb eines Datensatzes zeigt.`,
    t30: `Dynamische Fragen`,
    t31: `Erstellung interaktiver Fragebögen, bei denen sich die Fragen an vorhandene Daten anpassen können.`,
    t32: `Maßgeschneiderte Diagramme`,
    t33: `Ermöglicht die Auswahl des Diagrammtyps, die zu visualisierenden Daten und die Anpassung von Aspekten wie Farben und Beschriftungen.`,
    t34: `Videoanrufe`,
    t35: `Echtzeitkommunikation über Video und Audio, um die Zusammenarbeit und Interaktion zu erleichtern.`,
    t36: `Verträge`,
    t37: `Erstellung, Verwaltung und elektronische Rechnungsstellung von Verträgen, um rechtliche und administrative Prozesse zu beschleunigen.`,
    t38: `Rollen und Berechtigungen`,
    t39: `Weisen Sie verschiedenen Benutzern in Ihrem Team verschiedene Rollen und Zugriffsebenen zu, um Ihre Organisation zu erstellen.`,
    t40: `Team-Synchronisation`,
    t41: `Echtzeit-Zusammenarbeit mehrerer Benutzer, um Änderungen automatisch zu sichern.`,
    t42: `Alle Ihre Kontakte`,
    t43: `Zentralisieren und organisieren Sie alle Kontakte. Verwalten und greifen Sie einfach auf Kontaktinformationen zu.`,
    t44: `Es ist einfach zu lernen, wie es geht`,
    t45: `Dimensionen wechseln`,
    t46: {
        '01': `Machen Sie Ihre Daten intelligent, verbindbar, konsistent, sicher, effizient und zugänglich. Stärken Sie Ihre Analysen durch`,
        '02': `unseren Zugriff.`
    },
    t47: {
        '01': `Beginnen Sie mit `,
        '02': `dem Tutorial`,
        '03': `um die verschiedenen Teile der Plattform zu verstehen.`,
    },
    t48: `Jetzt registrieren`,
    t49: `Schätzen Sie Ihre Zeit und automatisieren Sie Ihre Daten`,
    t50: {
        '01': `Und beginnen Sie, Dimensionen in unserem Cloud-Betriebssystem zu wechseln. Analysieren und verstehen Sie Muster, um`,
        '02': `Ihre Daten zu bearbeiten.`,
    },

    footer: {
        t00: "START",
        t01: "Dein Team",
        t02: "Updates",
        t03: "Digital Kit",
        t04: "Beratung",
        t05: "Studieren",
    
        t10: "LINKS",
        t11: "Team",
        t12: "Freiberufler",
        t13: "Bedingungen",
        t14: "Eigenschaften",
        t15: "Rechner",
        t16: "Pläne",
    
        t20: "KI-MODUL",
        t21: "Stimme",
        t22: "Bild & Video",
        t23: "Inhalterstellung",
        t24: "Medienerstellung",
    
        t30: "UNTERSTÜTZUNG AYTHEN",
        t31: "Nutzungsvereinbarung",
        t32: "Datenschutzrichtlinie",
        t33: "Datensammlung und -nutzung",
        t34: "Inhaltsrichtlinien",
    
        t40: "ZUM ENTWERFEN",
        t41: "Editor",
        t42: "Apps",
        t43: "Cloud",
        t44: "Plugins",
        t45: "RPA",
    
        t50: "ZUM FINDEN",
        t51: "Bibliothek",
        t52: "Presets",
        t53: "Devops",
        t54: "IDE",
        t55: "Sandbox",
    
        t60: "ZUM BAUEN",
        t61: "UX/AI",
        t62: "Übersetzen",
        t63: "AI-Dokumente",
        t64: "AI-Unterstützung",
        t65: "Zusammenarbeit",
    
        t70: "ZUM SCHÜTZEN",
        t71: "Blockchain",
        t72: "Daten",
        t73: "Pivot",
        t74: "Akademie",
        t75: "Freiberufler",
    }
    
}

export default web
