import React from "react";
import Context from "@home/context/Context";

import HeaderTop from "@home/Header/HeaderTop/HeaderTop";
import Header from "@home/Header/Header";
import PopupMobileMenu from "@home/Header/PopUpMobileMenu";
import Footer from "@home/Footers/Footer";
import Copyright from "@home/Footers/Copyright";
import Breadcrumb from "@home/Common/Breadcrumb";
import Utilize from "@home/Utilize/Utilize";
import BackToTop from "../backToTop";

import styles from '../index.module.css'

const UtilizePage = () => {
  return (
    <>
      <main className={`${styles['page-wrapper']}`} >
        <Context>
          <HeaderTop />
          <Header
            headerTransparent="header-transparent"
            headerSticky="header-sticky"
            btnClass="rainbow-gradient-btn"
          />
          <PopupMobileMenu />
          <Breadcrumb title="How to use" text="How to use" />

          <Utilize />

          <BackToTop />
          <Footer />
          <Copyright />
        </Context>
      </main>
    </>
  );
};

export default UtilizePage;
