const web = {
    t1: {
        '01': `Modella i tuoi`,
        '02': `dati`,
        '03': `avanzati,`,
        '04': `predici la tua`,
        '05': `matrice`,
        '06': `per qualsiasi realtà possibile e analizza il`,
        '07': `big data`,
        '08': `con database vettorializzati`,
    },
    t2: `INIZIA ORA`,
    t3: `Nel tuo nuovo sistema operativo`,
    t4: {
        '01': `Offline`, 
        '02': `Online`, 
        '03': `Pubblico`, 
        '04': `Privato`, 
    },
    tassistant: {
        '01': `Velocità`,
        '02': `Texture`,
        '03': `Elaborazione`,
    },
    t5: `Un mondo di possibilità`,
    t6: {
        '01': `Grazie ai`,
        '02': `database vettorializzati,`,
        '03': `passi da`,
        '04': `dati statici`,
        '05': `a`,
        '06': `dati dinamici.`,
        '07': `Lavorare con molti dati contemporaneamente è difficile, ecco perché`,
        '08': `una latenza zero in certe dimensioni`,
        '09': `è necessaria per un accesso rapido e versatile.`,
    },
    t7: `Entra ora`,
    t8: `RISPARMIA TEMPO . RISPARMIA TEMPO . RISPARMIA TEMPO`,
    t9: `Tragga le proprie conclusioni`,
    t10: `L'informazione è potere`,
    t11: {
        '01': `Ora puoi capire i tuoi dati!`,
        '02': `Attraverso la segmentazione, con il concetto di`,
        '03': `K-Means,`,
        '04': `hai uno strumento per analizzare qualsiasi dato su misura.`,
    },
    t12: `Inizia ora`,
    t13: `Spazio disponibile nel cloud. Tutto scalabile su istanze private e sicure.`,
    t14: `volte più veloce rispetto al lavoro con database tradizionali.`,
    t15: `Rappresentazioni possibili per importare le proprie metriche e KPI.`,
    t16: `Analizza dati tra dimensioni`,
    t17: `Ogni dimensione ha agenti.`,
    t18: {
        '01': `In ogni dimensione è possibile creare`,
        '02': `flussi di agenti`,
        '03': `per creare reti di interazioni.`
    },
    t19: {
        '01': `In ogni agente sceglierai la`,
        '02': `strategia`,
        '03': `per relazionare`,
        '04': `ogni dato.`,
        '05': `ogni dato.`,
    },
    t20: `Inizia la tua prova GRATUITA`,
    t21: `Dimensioni`,
    t22: `Agenti`,
    t23: `Dati che gestiscono idee`,
    t24: `I tuoi dati il primo passo`,
    t25: {
        '01': `Sono la tua chiave in mano per costruire`,
        '02': `applicazioni incredibili. Non sono solo informazioni,`,
        '03': `sono la materia prima`,
        '04': `per la creazione di`,
        '05': `idee innovative e la loro analisi.`,
        '06': `Come lavoriamo con i dati?`,
    },
    t26: `Il tuo spazio di lavoro`,
    t27: `Archivia tutto il contenuto di cui hai bisogno nel tuo Cloud privato o pubblico in base alle tue esigenze.`,
    t28: `Mappa di calore`,
    t29: `Rappresentazione che mostra la distribuzione e concentrazione di determinati valori all'interno di un set di dati.`,
    t30: `Domande dinamiche`,
    t31: `Creazione di questionari interattivi, dove le domande possono adattarsi ai dati preesistenti.`,
    t32: `Grafici personalizzati`,
    t33: `Consentendo la selezione del tipo di grafico, dei dati da visualizzare e personalizzando aspetti come colori ed etichette.`,
    t34: `Chiamate video`,
    t35: `Comunicazione in tempo reale tramite video e audio, facilitando la collaborazione e l'interazione.`,
    t36: `Contratti`,
    t37: `Creazione, gestione e fatturazione elettronica di contratti, semplificando i processi legali e amministrativi.`,
    t38: `Ruoli e autorizzazioni`,
    t39: `Assegnare ruoli e livelli di accesso diversi agli utenti all'interno del proprio team per creare la propria organizzazione.`,
    t40: `Sincronizzazione di squadra`,
    t41: `Collaborazione in tempo reale del lavoro tra più utenti, garantendo che le modifiche apportate siano automaticamente protette.`,
    t42: `Tutti i tuoi contatti`,
    t43: `Centralizza e organizza tutti i contatti. Gestisci e accedi facilmente alle informazioni dei tuoi contatti.`,
    t44: `Impara a farlo, è molto semplice`,
    t45: `Cambia dimensioni`,
    t46: {
        '01': `Rendi i tuoi dati intelligenti, collegabili, coerenti, sicuri, efficienti e accessibili. Potenzia le tue analisi tramite`,
        '02': `il nostro accesso.`
    },
    t47: {
        '01': `Inizia con `,
        '02': `il tutorial`,
        '03': `per capire le diverse parti della piattaforma.`,
    },
    t48: `Registrati ORA`,
    t49: `Valuta il tuo tempo e automatizza i tuoi dati`,
    t50: {
        '01': `E inizia a cambiare dimensioni nel nostro sistema operativo cloud. Analizza e comprendi i modelli per`,
        '02': `lavorare i tuoi dati.`,
    },

    footer: {
        t00: "HOME",
        t01: "Il tuo team",
        t02: "Aggiornamenti",
        t03: "Kit digitale",
        t04: "Consulenza",
        t05: "Studiare",
    
        t10: "LINK",
        t11: "Team",
        t12: "Freelancer",
        t13: "Termini",
        t14: "Funzionalità",
        t15: "Calcolatrice",
        t16: "Piani",
    
        t20: "MODULO IA",
        t21: "Voce",
        t22: "Immagine e Video",
        t23: "Creazione di contenuti",
        t24: "Creazione di media",
    
        t30: "SUPPORTO AYTHEN",
        t31: "Accordo dell'utente",
        t32: "Informativa sulla privacy",
        t33: "Raccolta e utilizzo dei dati",
        t34: "Linee guida per i contenuti",
    
        t40: "PER PROGETTARE",
        t41: "Editor",
        t42: "Applicazioni",
        t43: "Cloud",
        t44: "Plugin",
        t45: "RPA",
    
        t50: "PER TROVARE",
        t51: "Libreria",
        t52: "Preset",
        t53: "Devops",
        t54: "IDE",
        t55: "Sandbox",
    
        t60: "PER COSTRUIRE",
        t61: "UX/AI",
        t62: "Traduzione",
        t63: "Documenti IA",
        t64: "Supporto IA",
        t65: "Collaborazioni",
    
        t70: "PER PROTEGGERE",
        t71: "Blockchain",
        t72: "Dati",
        t73: "Pivot",
        t74: "Accademia",
        t75: "Freelancer",
    }
    
}

export default web
