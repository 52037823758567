
import React from 'react'


const Icon = () => {
    return (
        <svg width="147" height="147" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_557_3121)">
                <path d="M105.691 117.32H40.3088C36.8246 117.32 34 114.496 34 111.011V35.3086C34 31.8244 36.8246 29 40.3088 29H82.75L112 58.2488V111.011C112 114.496 109.175 117.32 105.691 117.32Z" fill="url(#paint0_linear_557_3121)" />
                <g opacity="0.23" filter="url(#filter0_f_557_3121)">
                    <rect x="38.3015" y="71.7256" width="69.9706" height="24.0873" fill="var(--color-primary-3)" />
                </g>
                <path opacity="0.12" fillRule="evenodd" clipRule="evenodd" d="M90.1918 81.3721C91.3053 78.9611 91.9265 76.2762 91.9265 73.4462C91.9265 62.9938 83.4528 54.5205 73 54.5205C62.5472 54.5205 54.0735 62.9938 54.0735 73.4462C54.0735 76.2762 54.6947 78.9611 55.8081 81.3721H54.9338V82.3721V93.3721V94.3721H91.0662V93.3721V82.3721V81.3721H90.1918ZM85.8548 81.3721C86.9857 79.5419 87.7366 77.4521 87.9976 75.2128H74.7664V75.2129H70.9811V58.4808C63.5963 59.4674 57.9001 65.7916 57.9001 73.4462C57.9001 76.3532 58.7217 79.0683 60.1452 81.3721H85.8548ZM74.7664 58.4492V71.4276H87.9661C87.0565 64.6194 81.6098 59.2465 74.7664 58.4492Z" fill="white" />
                <path d="M53.2353 89.5791V75.0336H58.9739C60.0771 75.0336 61.017 75.2443 61.7935 75.6657C62.57 76.0824 63.1619 76.6624 63.5691 77.4058C63.981 78.1444 64.187 78.9967 64.187 79.9626C64.187 80.9285 63.9787 81.7808 63.562 82.5194C63.1453 83.2581 62.5416 83.8334 61.7509 84.2453C60.9649 84.6572 60.0132 84.8632 58.8958 84.8632H55.2381V82.3987H58.3986C58.9905 82.3987 59.4782 82.2969 59.8617 82.0933C60.25 81.885 60.5388 81.5985 60.7282 81.2339C60.9223 80.8646 61.0194 80.4408 61.0194 79.9626C61.0194 79.4797 60.9223 79.0583 60.7282 78.6984C60.5388 78.3338 60.25 78.0521 59.8617 77.8532C59.4734 77.6497 58.981 77.5479 58.3844 77.5479H56.3106V89.5791H53.2353ZM67.5845 89.5791V75.0336H73.3231C74.4264 75.0336 75.3662 75.2443 76.1427 75.6657C76.9193 76.0824 77.5111 76.6624 77.9183 77.4058C78.3302 78.1444 78.5362 78.9967 78.5362 79.9626C78.5362 80.9285 78.3279 81.7808 77.9112 82.5194C77.4945 83.2581 76.8908 83.8334 76.1001 84.2453C75.3141 84.6572 74.3624 84.8632 73.245 84.8632H69.5873V82.3987H72.7479C73.3397 82.3987 73.8274 82.2969 74.2109 82.0933C74.5992 81.885 74.888 81.5985 75.0774 81.2339C75.2715 80.8646 75.3686 80.4408 75.3686 79.9626C75.3686 79.4797 75.2715 79.0583 75.0774 78.6984C74.888 78.3338 74.5992 78.0521 74.2109 77.8532C73.8227 77.6497 73.3302 77.5479 72.7337 77.5479H70.6598V89.5791H67.5845ZM81.3797 77.5692V75.0336H93.3258V77.5692H88.8726V89.5791H85.8329V77.5692H81.3797Z" fill="white" />
                <mask id="mask0_557_3121" maskUnits="userSpaceOnUse" x="34" y="36" width="78" height="81">
                    <path d="M105.691 117H40.3088C36.8246 117 34 114.425 34 111.249V42.2364C34 39.0602 36.8246 36.4854 40.3088 36.4854H82.75L112 63.1493V111.249C112 114.425 109.175 117 105.691 117Z" fill="url(#paint1_linear_557_3121)" />
                </mask>
                <g mask="url(#mask0_557_3121)">
                    <ellipse opacity="0.05" cx="29.8419" cy="33.2182" rx="64.0919" ry="56.0727" fill="url(#paint2_linear_557_3121)" />
                    <ellipse opacity="0.07" cx="29.8419" cy="33.2184" rx="40.2904" ry="35.1598" fill="url(#paint3_linear_557_3121)" />
                </g>
                <g filter="url(#filter1_d_557_3121)">
                    <path d="M112 58.2488H104.75C92.5997 58.2488 82.75 48.3991 82.75 36.2488V29L112 58.2488Z" fill="url(#paint4_linear_557_3121)" />
                </g>
            </g>
            <defs>
                <filter id="filter0_f_557_3121" x="-0.698517" y="32.7256" width="147.971" height="102.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="19.5" result="effect1_foregroundBlur_557_3121" />
                </filter>
                <filter id="filter1_d_557_3121" x="59.75" y="5" width="79.25" height="79.249" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="2" dy="1" />
                    <feGaussianBlur stdDeviation="12.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_557_3121" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_557_3121" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_557_3121" x1="73" y1="29" x2="73" y2="117.32" gradientUnits="userSpaceOnUse">
                    <stop stopColor="var(--color-primary-3)" />
                    <stop offset="1" stopColor="var(--color-primary-3)" />
                </linearGradient>
                <linearGradient id="paint1_linear_557_3121" x1="73" y1="36.4854" x2="73" y2="117" gradientUnits="userSpaceOnUse">
                    <stop stopColor="var(--color-primary-3)" />
                    <stop offset="1" stopColor="var(--color-primary-3)" />
                </linearGradient>
                <linearGradient id="paint2_linear_557_3121" x1="34.4954" y1="35.1451" x2="50.9938" y2="83.1787" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient id="paint3_linear_557_3121" x1="32.7673" y1="34.4266" x2="43.0917" y2="64.5618" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient id="paint4_linear_557_3121" x1="97.375" y1="29" x2="97.375" y2="58.2488" gradientUnits="userSpaceOnUse">
                    <stop stopColor="var(--color-primary-3)" />
                    <stop offset="1" stopColor="var(--color-primary-3)" />
                </linearGradient>
                <clipPath id="clip0_557_3121">
                    <rect width="146.855" height="146.855" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Icon