import { createSlice } from '@reduxjs/toolkit'

import {
	fetchsDomain,
	addDomain,
	verifyDomain,
	denyDomain,
} from '../actions/domain'

const domainSlices = createSlice({
	name: 'domain',
	initialState: {
		domains: [],
	},
	reducers: {

	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchsDomain.fulfilled, (state, action) => {
				console.log('action', action.payload)
				state.domains = action.payload
			})

			.addCase(addDomain.fulfilled, (state, action) => {
				if (action.payload) {
					console.log('action.payload', action.payload)
					const index = state.domains.findIndex(
						(domain) => domain.id == action.payload.id
					)
					console.log('index', index)
					if (index == -1) {
						state.domains = [...state.domains, action.payload]
					}else{
						state.domains[index] = action.payload
					}
					
					// const index = state.domains.findIndex(
					// 	(domain) => domain.id == action.payload.id
					// )
					// if (action.payload) {
					// }
				}
			})
			.addCase(verifyDomain.fulfilled, (state, action) => {
				const index = state.domains.findIndex(
					(domain) => domain.id == action.payload.id
				)

				if (index > -1 && action.payload.isverify) {
					state.domains[index] = action.payload
				}
			})
			.addCase(denyDomain.fulfilled, (state, action) => {
				const id = action.payload
				const indexToDelete = state.domains.findIndex(
					(domain) => domain.id === id
				)
				if (indexToDelete !== -1) {
					state.domains = [
						...state.domains.slice(0, indexToDelete),
						...state.domains.slice(indexToDelete + 1),
					]
				}
			})

		
	},
})

export const {

} = domainSlices.actions

export default domainSlices.reducer
