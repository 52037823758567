const support = {
  t1: `Ajuda do Aythen Lite`,
  t2: `Ajuda e guias`,
  t33: `Tickets abertos`,
  t3: `Acordo de Nível de Serviço (SLA) é estabelecer a disponibilidade da plataforma de software`,
  t4: `Contate-nos`,
  t5: `Crie um ticket de suporte`,
  t6: `Obtenha ajuda diretamente da nossa equipe.`,
  t7: `Gerencie incidentes`,
  t8: `Verifique sua licença e seus tickets abertos e fechados.`,
  t9: `Você gostaria de saber mais?`,
  t10: `Você conhece o Aythen PRO?`,
  t11: `Entre aqui para entender e se inscreva em nossa lista de espera`,
  t12: `Monitoramento e Relatório`,
  t13: `Envie-nos suas recomendações para saber com quais dados você gostaria de trabalhar.`,
  topics: {
    1: {
      title: "Cookies e Privacidade",
      data: {
        1: {
          title: `Meus dados estão seguros?`,
          text: `Trabalhamos com a certificação ISO 27001. É um padrão reconhecido internacionalmente para a gestão da segurança da informação. Esta certificação implica que um sistema de gestão da segurança da informação (SGSI) foi implementado que atende aos controles e processos necessários para proteger os dados de forma eficaz.`,
        },
        2: {
          title: `Experiência do Usuário`,
          text: `Ao utilizar um provedor de serviços em nuvem com certificações como ISO 27001 e que opera na União Europeia, 
                        damos mais confiança na segurança e proteção dos dados pessoais que gerenciamos. Isso ajuda a atender aos requisitos de segurança do RGPD e a mitigar os riscos associados ao processamento de dados pessoais.`,
        },
      },
    },
    2: {
      title: `Bases de Dados Vectorizadas`,
      data: {
        1: {
          title: `O que são?`,
          text: `Representam uma inovação significativa no campo do armazenamento e processamento de dados. São utilizadas em uma variedade de aplicações, 
                        incluindo a ciência de dados, o aprendizado automático e a inteligência artificial. <br /><br />
                        Em contraste com as bases de dados tradicionais, que armazenam informações em forma de tabelas com linhas e colunas, 
                        as bases de dados vectorizadas utilizam estruturas de dados vectoriais para armazenar e manipular informações de forma mais eficiente. 
                        Organizando assim os dados em vetores multidimensionais. Esses vetores podem conter dados em diversos formatos de arquivos, 
                        como números, texto, imagens, código... Cada elemento do vetor representa uma unidade de informação, e as operações são realizadas 
                        de forma eficiente criando fluxos entre conjuntos de informações em vez de linhas e colunas individuais.`,
        },
        2: {
          title: `Para que servem?`,
          text: `Essa estrutura permite um acesso rápido a grandes volumes de dados e facilita operações matemáticas e lógicas complexas de forma simultânea. 
                        É possível eliminar a latência dos dados sem a necessidade de processar toda a informação, apenas a de interesse, que pode ser segmentada e organizada em clusters 
                        entre dimensões e agentes. 
                        <br /><br />
                        - Cada dimensão representa uma coordenada em um espaço multidimensional onde os dados estão organizados. Isso significa que um conjunto de dados vectorizados 
                        pode ter múltiplas dimensões, o que permite uma representação mais completa e detalhada da informação.
                        <br /><br />
                        - Os agentes são entidades que realizam operações sobre os dados vectorizados. Esses agentes podem ser programas, processos ou consultas que interagem com 
                        a base de dados para recuperar, processar ou manipular informações. Podem acessar diferentes dimensões dos dados e realizar operações complexas em função 
                        dos requisitos para representar graficamente ou responder à pergunta realizada. `,
        },
      },
    },
    3: {
      title: `Back-office Serverless`,
      data: {
        1: {
          title: `Como eu me beneficio?`,
          text: `Em um ambiente serverless, não é necessário administrar servidores físicos ou virtuais. A infraestrutura é gerenciada para que a aplicação possa escalar automaticamente 
                        de acordo com a demanda. 
                        <br /><br />
                        Isso significa que não há necessidade de prever picos de tráfego nem de ajustar manualmente a capacidade do servidor. A plataforma escalonará automaticamente 
                        os recursos necessários para gerenciar a carga de trabalho.`,
        },
        2: {
          title: `Flexibilidade Operacional`,
          text: `Portanto, apenas os recursos utilizados são pagos, o que pode economizar em comparação com os servidores tradicionais onde se paga por capacidade 
                        reservada, independentemente de se utilizar ou não.`,
        },
      },
    },
    4: {
      title: `Serviços on-demand`,
      data: {
        1: {
          title: `Posso adaptá-lo à minha empresa?`,
          text: `Você pode criar suas necessidades com base em sua própria estrutura empresarial.`,
        },
        2: {
          title: `Logo da empresa`,
          text: `Fundamental poder adicionar seu logo da empresa no registro para manter a coerência da marca em todas as interações com clientes, 
                        fornecedores e outros atores.`,
        },
        3: {
          title: `Usuários e funções`,
          text: `Você pode adicionar e gerenciar diferentes tipos de usuários e funções no IAM, se forem trabalhadores internos, ou seus clientes externos, fornecedores e outros 
                        colaboradores em nossas tabelas dinâmicas. Assim, cada tipo de usuário pode ter níveis de acesso e permissões diferentes para garantir a segurança e a 
                        privacidade dos dados.`,
        },
        4: {
          title: `Cores corporativas`,
          text: `Seria importante personalizar a aparência do CRM com as cores corporativas da empresa para reforçar a identidade visual da marca. 
                        Isso incluiria a possibilidade de escolher as cores predominantes na interface do CRM, como fundos, botões e barras de navegação.`,
        },
        5: {
          title: `Vinculação de contas bancárias`,
          text: `Para uma gestão financeira eficiente, você pode vincular as cartões serverless Aythen aos IAM (acessos e funções) para que cada membro da empresa 
                        possa acompanhar transações, pagamentos e outras operações financeiras diretamente da plataforma, simplificando o processo de gestão.`,
        },
      },
    },
    5: {
      title: `Interações sob medida`,
      data: {
        1: {
          title: `Análise Profunda`,
          text: `A representação vectorial permite uma análise mais profunda dos dados.`,
        },
        2: {
          title: `Insights Estratégicos`,
          text: `Oferecemos insights valiosos para a tomada de decisões estratégicas mediante dimensões vectoriais.`,
        },
      },
    },
    6: {
      title: `Ferramentas Essenciais`,
      data: {
        1: {
          title: `Documentos Próprios`,
          text: `Você pode subir novos documentos de texto, formato e colaboração em tempo real. Aceita qualquer variedade de 
                        formatos de arquivo, incluindo documentos de texto, planilhas, apresentações, formulários, desenhos e mais.`,
        },
        2: {
          title: `Armazenamento`,
          text: `Os documentos podem ocupar espaço em sua conta. Você pode então congelá-los para ter acesso a eles quando 
                        quiser ou eliminá-los se não forem de interesse.`,
        },
        2: {
          title: `Compartilhe e Colabore`,
          text: `Você pode compartilhar seus documentos com outros usuários e trabalhar juntos em tempo real. Os colaboradores podem ver 
                        as alterações no documento à medida que são realizadas e adicionar comentários para discutir ideias.`,
        },
        3: {
          title: `Fatura Eletrônica`,
          text: `Economiza tempo e recursos. Automatiza o processo de emissão, envio e recebimento de faturas, o que reduz a carga 
                        administrativa e custos associados ao papel. Reduz erros humanos e melhora a precisão no registro e acompanhamento de 
                        transações financeiras. Tudo o que é necessário para atender aos requisitos legais obrigatórios para qualquer autônomo 
                        e empresa para a regulação fiscal e legal.`,
        },
        3: {
          title: `Contratos`,
          text: `Centralização da informação relacionada a clientes e acordos comerciais, o que facilita a gestão e 
                        o acompanhamento de contratos ao longo de seu ciclo de vida.`,
        },
        4: {
          title: `Dashboards de suas gráficos favoritos`,
          text: `Criando seus dashboards sob medida com base em suas interações realizadas, você pode visualizar dados-chave e métricas importantes 
                        de forma clara e concisa, o que facilita a identificação de tendências, padrões e áreas de oportunidade para tomar decisões informadas. `,
        },
        5: {
          title: `Tabelas Dinâmicas`,
          text: `As tabelas dinâmicas oferecem uma maneira flexível de resumir seus dados com base em seus projetos. Enumera as fichas de 
                        as pessoas envolvidas, o status, datas, dados desde diferentes perspectivas e níveis de detalhe. Você pode filtrar, agrupar e resumir dados de forma interativa para obter informações detalhadas e tomar decisões informadas.`,
        },
      },
    },
  },
}

export default support