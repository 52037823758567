const drive = {
    t1: `Искать документацию`,
    t2: `Создать новый`,
    t3: `Новая папка`,
    t4: `Новый lanceDB`,
    t5: `Загрузить файл`,
    t6: `Загрузить папку`,
    t7: `Использовать`,
    t8: `Мои документы`,
    t9: `Мои документы`,
    t10: `Вскоре`,
    t11: `Приоритетные`,
    t12: `Общие`,
    t13: `Недавние`,
    t14: `Выделенные`,
    t15: `Корзина`,
    t16: `Корзина`,
    t17: `Момент...`,
    t18: `Отменить`,
    t19: `Создать`,

    x1: `Имя`,
    x2: `Последнее изменение`,
    x3: `Выбрать все`,
    x4: `Убрать все`,
    x5: `Недавние файлы`,
    x6: `LanceDB`,
    x7: `Просмотреть DB`,
    x8: `Просмотреть drive`,
    x9: `Проект`,
    x10: `Имя`,
    x11: `Имя`,
    x12: `Размер`,
    x13: `Последнее изменение`,
    x14: `Выбранные элементы`,
    x15: `Общий доступ с`,
}

export default drive