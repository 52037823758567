const home = {
    banner: {
        banner: 'Editar foto de capa',
        edit: 'Editar perfil',
        addUser: 'Adicionar usuário',
        title: 'usuários compartilhados',
        viewTeam: 'Administrar equipe',
        team: {
            title: `Pessoas que você pode conhecer`,
            button: `Ver todos`,
            addContact: `Adicionar a contatos`
        },
    },
    t1: `Organização`,
    t2: `Banco`,
    t3: `Identificação e acesso do gerente (IAM)`,
    t4: `Acesso (IAM)`,
    t5: `Trabalhe e entenda seus dados como vetores`,
    t6: `Entre na nova dimensão do dado. Elimine a latência para trabalhar x2000 
        vezes mais eficiente, perfeito para segmentar, analisar e entender grandes volumes de 
        dados simultâneos.`,
    t60: `Adicione dados ao seu vetor para que eles trabalhem por você`,
    t61: `Ir a um vetor`,
    t7: `Criar novo`,
    t8: `Adicionar novo`,
    t9: `Novo`,
    t10: `Crie seus Addons para criar componentes vetoriais nos quais representar`,
    t11: `Não existe nenhum addon, você quer criar`,
    t12: `New Addon`,
    t13: `Premium Addon`,
    t14: `Consumo de dados`,
    t15: `Características`,
    t16: `Ver mais`,
    t17: `Notas`,
    t18: `Adicionar nova`,
    t19: `Dados seguros`,
    t20: " RGPD - ISO 27001",
    t21: `você pode trabalhar com vetores personalizados.`,
    steps: {
        addCampaign: `Criar campanha`,
        addAdvice: `Criar anúncios`,
        t1: `Revisão do cumprimento de políticas`,
        t2: `Impressões`,
        t3: `Conversões`,
        title: `Todos os diagnósticos da campanha`,
        s1: `Estado da conta`,
        s2: `Estado da faturamento`,
        s3: `Políticas da campanha`,
        s4: `Rastreamento de conversões`,
        s5: `Orçamento da campanha`,
        s6: `Estratégia de lance`,
        s7: `Estado da campanha`,
        s8: `Eficácia do anúncio`,
        text1: `Essa campanha não tem anúncios`,
        text2: `Adicione anúncios aos grupos de anúncios para que sejam publicados`,
        text3: `Solucionar`,
    },
    t22: {
        t0: `Em`,
        t1: `usamos`,
    },
    t23: {
        t0: `Em breve disponível`,
        t1: `Em breve estará disponível, podemos enviar uma informação para o
        pro`,
    },
    t24: {
        t0: `Não há conexão à Internet`,
        t1: `Verifique sua conexão Wi-Fi ou de dados móveis, ou receba uma
        notificação quando a conexão voltar`,
        t2: `Avisar-me`,
        t3: `Tentar novamente`,
    },
    t25: {
        t0: `Não encontrado`,
        t1: `🚀 Não encontrado`,
        t2: `Ver alterações`,
    }
}

export default home;