import React from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import styles from '../index.module.css'

const Breadcrumb = ({ title, text }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <>
      <div className={`${styles["breadcrumb-area"]} ${styles["breadcarumb-style-1"]} ${styles["pt--120"]} ${styles["pb--20"]}`}>
        <div className={styles["container"]}>
          <div className={styles["row"]}>
            <div className={styles["col-lg-12"]}>
              <div className={`${styles["breadcrumb-inner"]} ${styles["text-center"]}`}>
                <h3 className={`${styles["title"]} ${styles["h3"]}`}>{title}</h3>
                <ul className={styles["page-list"]}>
                  <li className={styles["rainbow-breadcrumb-item"]}>
                    <button onClick={() => navigate('/')}>{t('home1.breadcrumb.text1')}</button>
                  </li>
                  <li className={`${styles["rainbow-breadcrumb-item"]} ${styles["active"]}`}>{text}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Breadcrumb;
