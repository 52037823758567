import apiBackend from '@src/apiBackend'
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchsTicket =
  createAsyncThunk('ticket/fetchsTicket',
    async (type = 'open', { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.get(
          `/ticket/all/${type}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );


        return {
          tickets: resp.data.tickets,
          total: resp.data.total
        }
      } catch (error) {
        throw error;
      }
    }
  );


export const fetchTicket =
  createAsyncThunk('ticket/fetchTicket',
    async (ticketId, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.get(
          `/ticket/${ticketId}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );

export const addTicket =
  createAsyncThunk('ticket/addTicket',
    async (ticket, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          '/ticket',
          { ticket },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return 'eee';
      } catch (error) {
        throw error;
      }
    }
  );



export const addVectorTicket =
  createAsyncThunk('ticket/addVectorTicket',
    async (ticket, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          '/ticket/vector',
          { ticket },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );

  export const vectorTicket =
  createAsyncThunk('ticket/vectorTicket',
    async (ticketId, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          `/ticket/vector/${ticketId}`,
          {  },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return resp.data;
      } catch (error) {
        console.log('Error', error)
        throw error;
      }
    }
  );


export const updateTicket =
  createAsyncThunk('ticket/updateTicket',
    async (ticket, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.put(
          '/ticket',
          { ticket },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return 'user';
      } catch (error) {
        throw error;
      }
    }
  );


  export const deleteTicket =
  createAsyncThunk('ticket/deleteTicket',
    async (ticketId, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.delete(
          `/ticket`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          data: { id: ticketId }, 
        });

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );







  export const storeStatus =
  createAsyncThunk('ticket/storeStatus',
    async (status, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          `/ticket/status/all/${type}`,
          { status },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );


  export const fetchsStatus =
  createAsyncThunk('ticket/fetchsStatus',
    async (type = 'open', { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.get(
          `/ticket/status/${type}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );


  export const updateStatus =
  createAsyncThunk('ticket/updateStatus',
    async (status, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.put(
          '/ticket/status',
          { status },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return 'user';
      } catch (error) {
        throw error;
      }
    }
  );
  

  export const deleteStatus =
  createAsyncThunk('ticket/deleteStatus',
    async (statusId, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.delete(
          `/ticket`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          data: { id: statusId }, 
        });


        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );

