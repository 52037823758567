import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from '../index.module.css'

const AccordionItem = ({ parentClass }) => {
  const { t } = useTranslation();
  
  const [expandedIndex, setExpandedIndex] = useState(null);

  const arrayData = t('home1.accordion', { returnObjects: true });

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <>
      <div className={`${styles['rainbow-accordion-style']} ${styles[parentClass]} ${styles.accordion}`}>
        <div className={styles.accordion} id="accordionExamplea">
          {arrayData &&
            arrayData.map((data, index) => (
              <div className={`${styles["accordion-item"]} ${styles.card}`} key={index}>
                <h2
                  className={`${styles['accordion-header']} ${styles['card-header']} ${expandedIndex === index ? styles.active : ''}`}
                  id={`heading${index + 1}`}
                >
                  <button
                    className={`${styles['accordion-button']} ${expandedIndex !== index ? styles.collapsed : ''}`}
                    type="button"
                    onClick={() => handleToggle(index)} // Llamada a la función al hacer clic
                  >
                    {data.title}
                  </button>
                </h2>
                <div
                  id={`collapse${index + 1}`}
                  className={`${styles['accordion-collapse']} ${styles.collapse} ${expandedIndex === index ? styles.show : ''}`} 
                  aria-labelledby={`heading${index + 1}`}
                  data-bs-parent="#accordionExamplea"
                >
                  <div className={`${styles['accordion-body']} ${styles['card-body']}`}>
                    {data.desc}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default AccordionItem;
