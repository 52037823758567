import React, { useState, useEffect, useRef } from 'react'

import { useDrag } from 'react-dnd';


import styles from './modal.module.css'

const Modal = ({ children, style,  isShow }) => {
    const ref = useRef(null);
    const rightResizerRef = useRef(null);
    const bottomResizerRef = useRef(null);
    const diagonalResizerRef = useRef(null);

    const [width, setWidth] = useState(300);
    const [height, setHeight] = useState(300);

    const [positionDrag, setPositionDrag] = useState({ x: 40, y: 40 });

    const [{ isDragging }, drag] = useDrag({
        type: 'BOX',
        item: () => {
            const initialOffset = ref.current.getBoundingClientRect();
            return { initialOffset, position: positionDrag };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            if (!monitor.didDrop()) {
                const delta = monitor.getDifferenceFromInitialOffset();
                let newX = Math.round(item.initialOffset.left + delta.x);
                let newY = Math.round(item.initialOffset.top + delta.y);
                setPositionDrag({ x: newX, y: newY });
            }
        },
    });

    drag(ref);


    useEffect(() => {
        const handleMouseMoveRight = (e) => {
            if (!rightResizerRef.current) return;
            const startX = rightResizerRef.current.startX;
            const startWidth = rightResizerRef.current.startWidth;
            const newWidth = startWidth + (e.clientX - startX);
            setWidth(newWidth);
        };

        const handleMouseUpRight = () => {
            window.removeEventListener('mousemove', handleMouseMoveRight);
            window.removeEventListener('mouseup', handleMouseUpRight);
        };

        const handleMouseDownRight = (e) => {
            e.preventDefault();
            if (ref.current) {
                rightResizerRef.current = {
                    startX: e.clientX,
                    startWidth: ref.current.offsetWidth,
                };
                window.addEventListener('mousemove', handleMouseMoveRight);
                window.addEventListener('mouseup', handleMouseUpRight);
            }
        };

        const handleMouseMoveBottom = (e) => {
            if (!bottomResizerRef.current) return;
            const startY = bottomResizerRef.current.startY;
            const startHeight = bottomResizerRef.current.startHeight;
            const newHeight = startHeight + (e.clientY - startY);
            setHeight(newHeight);
        };

        const handleMouseUpBottom = () => {
            window.removeEventListener('mousemove', handleMouseMoveBottom);
            window.removeEventListener('mouseup', handleMouseUpBottom);
        };

        const handleMouseDownBottom = (e) => {
            e.preventDefault();
            if (ref.current) {
                bottomResizerRef.current = {
                    startY: e.clientY,
                    startHeight: ref.current.offsetHeight,
                };
                window.addEventListener('mousemove', handleMouseMoveBottom);
                window.addEventListener('mouseup', handleMouseUpBottom);
            }
        };

        const handleMouseMoveDiagonal = (e) => {
            if (!diagonalResizerRef.current) return;
            const startX = diagonalResizerRef.current.startX;
            const startY = diagonalResizerRef.current.startY;
            const startWidth = diagonalResizerRef.current.startWidth;
            const startHeight = diagonalResizerRef.current.startHeight;
            const newWidth = startWidth + (e.clientX - startX);
            const newHeight = startHeight + (e.clientY - startY);
            setWidth(newWidth);
            setHeight(newHeight);
        };

        const handleMouseUpDiagonal = () => {
            window.removeEventListener('mousemove', handleMouseMoveDiagonal);
            window.removeEventListener('mouseup', handleMouseUpDiagonal);
        };

        const handleMouseDownDiagonal = (e) => {
            e.preventDefault();
            if (ref.current) {
                diagonalResizerRef.current = {
                    startX: e.clientX,
                    startY: e.clientY,
                    startWidth: ref.current.offsetWidth,
                    startHeight: ref.current.offsetHeight,
                };
                window.addEventListener('mousemove', handleMouseMoveDiagonal);
                window.addEventListener('mouseup', handleMouseUpDiagonal);
            }
        };

        const rightResizer = rightResizerRef.current;
        const bottomResizer = bottomResizerRef.current;
        const diagonalResizer = diagonalResizerRef.current;

        if (rightResizer) {
            rightResizer.addEventListener('mousedown', handleMouseDownRight);
        }
        if (bottomResizer) {
            bottomResizer.addEventListener('mousedown', handleMouseDownBottom);
        }
        if (diagonalResizer) {
            diagonalResizer.addEventListener('mousedown', handleMouseDownDiagonal);
        }

        return () => {
            if (rightResizer) {
                rightResizer.removeEventListener('mousedown', handleMouseDownRight);
            }
            if (bottomResizer) {
                bottomResizer.removeEventListener('mousedown', handleMouseDownBottom);
            }
            if (diagonalResizer) {
                diagonalResizer.removeEventListener('mousedown', handleMouseDownDiagonal);
            }
        };
    }, []);


    return (
        <div
            ref={ref}
            className={`${style} ${styles.popUp}`}
            style={{
                left: `${positionDrag.x}px`,
                top: `${positionDrag.y}px`,
                display: isShow ? '' : 'none',
                width: `${width}px`,
                height: `${height}px`,
            }}
        >

            {children}


            <div className={styles.resizerRight} ref={rightResizerRef} />
            <div className={styles.resizerBottom} ref={bottomResizerRef} />
            <div className={styles.resizerDiagonal} ref={diagonalResizerRef} />
        </div>
    )
}


export default Modal