import React from 'react'


const Icon = () => {
    return (
        <svg width="147" height="147" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M105.691 117.161H40.3088C36.8246 117.161 34 114.337 34 110.853V35.1467C34 31.6624 36.8246 28.8379 40.3088 28.8379H82.75L112 58.0879V110.853C112 114.337 109.175 117.161 105.691 117.161Z" fill="url(#paint0_linear_557_3227)"/>
        <g filter="url(#filter0_f_557_3227)">
        <rect x="47.4779" y="72.7129" width="51.0441" height="24.0882" fill="var(--color-primary-3)" fillOpacity="0.17"/>
        </g>
        <path opacity="0.12" fillRule="evenodd" clipRule="evenodd" d="M54.9338 65.3002V60.6973C54.9338 58.4881 56.7247 56.6973 58.9338 56.6973H87.0662C89.2753 56.6973 91.0662 58.4881 91.0662 60.6973V65.3002V68.6973V73.9031V77.3002V82.5061V85.9031V91.109V94.5061V99.109C91.0662 101.318 89.2753 103.109 87.0662 103.109H58.9338C56.7247 103.109 54.9338 101.318 54.9338 99.109V94.5061V91.109V85.9031V82.5061V77.3002V73.9031V68.6973V65.3002Z" fill="white"/>
        <path d="M49.3352 77.409V74.8735H61.2812V77.409H56.8281V89.4189H53.7884V77.409H49.3352ZM67.5321 74.8735L70.4653 79.8309H70.579L73.5264 74.8735H76.9994L72.5605 82.1462L77.0989 89.4189H73.5619L70.579 84.4545H70.4653L67.4824 89.4189H63.9597L68.5122 82.1462L64.0449 74.8735H67.5321ZM79.7915 77.409V74.8735H91.7375V77.409H87.2844V89.4189H84.2446V77.409H79.7915Z" fill="white"/>
        <mask id="mask0_557_3227"  maskUnits="userSpaceOnUse" x="34" y="28" width="78" height="90">
        <path d="M105.691 117.161H40.3088C36.8246 117.161 34 114.337 34 110.853V35.1467C34 31.6624 36.8246 28.8379 40.3088 28.8379H82.75L112 58.0879V110.853C112 114.337 109.175 117.161 105.691 117.161Z" fill="url(#paint1_linear_557_3227)"/>
        </mask>
        <g mask="url(#mask0_557_3227)">
        <ellipse opacity="0.05" cx="32.7096" cy="25.8265" rx="64.0919" ry="61.511" fill="url(#paint2_linear_557_3227)"/>
        <ellipse opacity="0.05" cx="32.7096" cy="25.8267" rx="40.2904" ry="38.5699" fill="url(#paint3_linear_557_3227)"/>
        </g>
        <g filter="url(#filter1_d_557_3227)">
        <path d="M112 58.0879H104.75C92.5997 58.0879 82.75 48.2382 82.75 36.0879V28.8379L112 58.0879Z" fill="url(#paint4_linear_557_3227)"/>
        </g>
        <defs>
        <filter id="filter0_f_557_3227" x="8.47794" y="33.7129" width="129.044" height="102.088" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="19.5" result="effect1_foregroundBlur_557_3227"/>
        </filter>
        <filter id="filter1_d_557_3227" x="59.75" y="4.83789" width="79.25" height="79.25" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="2" dy="1"/>
        <feGaussianBlur stdDeviation="12.5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_557_3227"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_557_3227" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_557_3227" x1="73" y1="28.8379" x2="73" y2="117.161" gradientUnits="userSpaceOnUse">
        <stop stopColor="var(--color-primary-3)"/>
        <stop offset="0.0730828" stopColor="var(--color-primary-3)"/>
        <stop offset="1" stopColor="var(--color-primary-3)"/>
        </linearGradient>
        <linearGradient id="paint1_linear_557_3227" x1="73" y1="28.8379" x2="73" y2="117.161" gradientUnits="userSpaceOnUse">
        <stop stopColor="var(--color-primary-3)"/>
        <stop offset="1" stopColor="var(--color-primary-3)"/>
        </linearGradient>
        <linearGradient id="paint2_linear_557_3227" x1="37.3631" y1="27.9402" x2="56.7998" y2="79.5254" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint3_linear_557_3227" x1="35.6349" y1="27.1521" x2="47.7992" y2="59.5184" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint4_linear_557_3227" x1="97.375" y1="28.8379" x2="97.375" y2="58.0879" gradientUnits="userSpaceOnUse">
        <stop stopColor="var(--color-primary-3)"/>
        <stop offset="1" stopColor="var(--color-primary-3)"/>
        </linearGradient>
        </defs>
        </svg>
        
    )
}

export default Icon
