const bank = {
    t1: `Ingresos`,
    t2: `Gastos`,
    t3: `Gestiona tus accesos`,
    t4: `Buscar una factura`,
    t5: `Buscar una fecha`,
    t6: `Mi Tarjeta`,
    t7: `Balance de tarjeta`,
    t8: `Estado`,
    t9: `Activa`,
    t10: `Administrar`,
    t11: `Transferir`,
    t12: `Comparte y consigue afiliar a todos tus contactos para
        obtener tu recompensa`,
    t13: `Seguridad`,
    t14: `Tarjeta activa`,
    t15: `Acceso seguro`,
    t16: `Cambiar`,
    t17: `Información`,
    t18: `Localidad`,
    t19: `Dirección`,
    t20: `Wallet`,

    cardlist: {
        "t0": "Tarjetas",
        "t1": "Añadir",
        "t2": "Añade tu tarjeta de pago",
        "t3": "Una vez que hayas añadido tu tarjeta, podrás administrar tus pagos",
        "t4": "Añadir tarjeta",
        "t5": "Ingresa los detalles de tu tarjeta de crédito para realizar pagos de manera segura.",
        "t6": "Ver más",
        "t7": "Nombre",
        "t8": "Pan",
        "t9": "CVC",
        "t10": "EXP",
        "t11": "Aceptar tarjeta",
        "t12": "Cancelar",
        "t13": "Pedia una nueva tarjeta",
        "t14": "Te llegara una nueva tarjeta",
        "t15": "Enviado desde Aythen",
        "t16": "España",
        "t17": "10903",
        "t18": "Santa Perpetua de Mogoda",
        "t19": "Esta es la dirección se te enviaria",
        "t20": "Esto tendrá un coste de 10* Esto tendrá un coste de Esto tendrá un coste de Esto tendrá un coste de",
        "t21": "Solicitar tarjeta"
    }
}

export default bank