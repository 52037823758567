// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.joystickContainer_ni_HU {
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.joystick_OvjMG {
    width: 150px;
    height: 150px;
}

.speedControls_n7GlY {
    margin-top: 10px;
}

.speedControls_n7GlY button {
    background-color: hsl(219, 84%, 56%);
    border: none;
    color: white;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    font-size: 14px;
}

.speedControls_n7GlY button:hover {
    background-color: hsl(219, 84%, 66%);
}
`, "",{"version":3,"sources":["webpack://./src/test/game/Joystick.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;IACpC,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":[".joystickContainer {\n    position: fixed;\n    bottom: 10px;\n    right: 10px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.joystick {\n    width: 150px;\n    height: 150px;\n}\n\n.speedControls {\n    margin-top: 10px;\n}\n\n.speedControls button {\n    background-color: hsl(219, 84%, 56%);\n    border: none;\n    color: white;\n    padding: 10px 20px;\n    margin: 5px;\n    cursor: pointer;\n    font-size: 14px;\n}\n\n.speedControls button:hover {\n    background-color: hsl(219, 84%, 66%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"joystickContainer": `joystickContainer_ni_HU`,
	"joystick": `joystick_OvjMG`,
	"speedControls": `speedControls_n7GlY`
};
export default ___CSS_LOADER_EXPORT___;
