import React from "react";

import styles from '../../index.module.css'

const BlogPost = ({ blogpost }) => {
  return (
    <>
      <div className={`${styles.inner}`} >
        <ul>
          {blogpost.slice(0, 3).map((data) => (
            <li key={data.id}>
              <div className={`${styles["list-blog-sm"]}`} >
                <div className={`${styles.img}`} >
                  <img src={data.img} width={120} height={85} alt="Blog" />
                </div>
                <div className={`${styles.content}`} >
                  <button className={styles["d-block"]}  href={`/blog-detail/${data.id}`}>
                    {data.title}
                  </button>
                  <span className={styles.className || styles["className"]} >{data.cate}</span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default BlogPost;
