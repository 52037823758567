import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Sal from "sal.js";

import Context from "@home/context/Context";
import HeaderTop from "@home/Header/HeaderTop/HeaderTop";
import Header from "@home/Header/Header";
import PopupMobileMenu from "@home/Header/PopUpMobileMenu";
import Footer from "@home/Footers/Footer";
import Copyright from "@home/Footers/Copyright";
import Breadcrumb from "@home/Common/Breadcrumb";
import BackToTop from "../backToTop";
import Pricing from "@home/Pricing/Pricing";
import Compare from "@home/Pricing/Compare";

import TestimonialTwo from "@home/Testimonials/TestimonialTwo";
import AccordionItem from "@home/Accordion/AccordionItem";
import CtaTwo from "@home/CallToActions/Cta-Two";

import styles from '../index.module.css'

const PricingPage = () => {

  const { t } = useTranslation()

  useEffect(() => {
    Sal();
  }, []);
  return (
    <>
      <main className={styles["page-wrapper"]} >
        <Context>
          <HeaderTop />
          <Header
            headerTransparent="header-transparent"
            headerSticky="header-sticky"
            btnClass="rainbow-gradient-btn"
          />
          <PopupMobileMenu />
          <Breadcrumb 
          title={t("home1.pricing.breadcrumb.text1")} 
          text={t("home1.pricing.breadcrumb.text2")}
          />



          <div className={`${styles["rainbow-testimonial-area"]} ${styles["rainbow-section-gap"]}`} >
            <div className={`${styles.container}`} >
              <Pricing />
              <Compare />
              <TestimonialTwo />
            </div>
          </div>

          <div className={`${styles["rainbow-accordion-area"]} ${styles["rainbow-section-gap"]}`} >
            <div className={`${styles.container}`} >
              <div className={`${styles.row} ${styles["justify-content-between"]}`} >
                <div className={`${styles["col-lg-12"]} ${styles["col-xl-4"]} ${styles["col-12"]}`} >
                  <div className={`${styles["split-inner"]}`} >
                    <h2
                      className={`${styles.title} ${styles["sal-animate"]}`}
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      {t('home1.pricing.component1.text7')}
                    </h2>
                    <p
                      className={`${styles.description} ${styles["sal-animate"]}`}
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      {t('home1.pricing.component1.text8')}
                    </p>
                    <div
                      className={`${styles["contact-button"]} ${styles["mt--35"]} ${styles["sal-animate"]}`}
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="400"
                    >
                      <button
                        className={`${styles["rainbow-gradient-btn"]} ${styles["without-shape"]}`}
                        target="_blank"
                        href="/contact"
                      >
                        <span>
                          {t('home1.pricing.component1.button1')}

                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className={`${styles["col-lg-12"]} ${styles["col-xl-7"]} ${styles["col-12"]}`} >
                  <AccordionItem parentClass="rainbow-accordion-02" />
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles["rainbow-cta-area"]} ${styles["rainbow-section-gap"]} ${styles["rainbow-section-gapBottom-big"]}`} >
            <div className={styles.container} >
              <CtaTwo />
            </div>
          </div>







          <BackToTop />
          <Footer />
          <Copyright />
        </Context>
      </main>
    </>
  );
};

export default PricingPage;
