
const privacy = {
    breadcrumb: {
        text1: 'Política de Privacidad',
        text2: 'Privacidad',
    },
    text: ``
}


export default privacy