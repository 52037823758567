import io from 'socket.io-client'
import WebSocket from 'ws';


import { store } from './store'
import { setSocketId } from '../../slices/meetSlices'

// const socket = io('http://localhost:3001', {
import { apiUrl } from '@src/apiBackend'

// const socket = io(apiUrl, {
// 	transports: ['websocket'],
//     withCredentials: true,
//     forceNew: true,
//     // Añade timeout y reconexión
// 	// secure: false,
//     timeout: 10000,
//     reconnection: true,
//     reconnectionAttempts: 5,
//     reconnectionDelay: 1000,

// 	secure: false,
//     rejectUnauthorized: false
// })

// const socket = io('wss://ay-cloud.com:443/ws', {
// const socket = io('https://ay-cloud.com/ws', {
	// const socket = new WebSocket('wss://ay-cloud.com:3004', {
	const socket = io('https://ay-cloud.com:3004', {
	transports: ['websocket'],
	path: "/websocket",
	// withCredentials: true,
	// rejectUnauthorized: false,
	// secure: true
	// forceNew: true,
	// 'force new connection': true,
	// path: "/ws/",
	// path: "/ws",
})

// const socket = new WebSocket('wss://aythen.com:3004/websocket');


// const socket = io(apiUrl, {
// 	transports: ['websocket'],
// 	withCredentials: true,
// 	forceNew: true,
// })

// Añade manejo de errores
socket.on('connect_error', (error) => {
	console.log('Socket connection error:', error)
	console.log('Socket connection error details:', {
		message: error.message,
		type: error.type,
		description: error.description
	})


	const timeout = Math.min(1000 * Math.pow(2, socket.reconnectionAttempts), 10000);
    setTimeout(() => {
        console.log(`Intentando reconectar... (intento ${socket.reconnectionAttempts + 1})`);
        socket.connect();
    }, timeout);
})


socket.on('connect', () => {
	console.log('Connected to Socket.io server')

	const socketId = socket.id
	store.dispatch(setSocketId(socketId))

	if (typeof localStorage !== 'undefined') {
		localStorage.setItem('socketId', socketId)
	}
})



// ... existing imports ...

// socket.onerror = (error) => {
//     console.log('WebSocket error:', error);
// };

// socket.onclose = (event) => {
//     console.log('WebSocket connection closed:', event);
    
//     // Implementación de reconexión
//     const maxAttempts = 5;
//     let attempts = 0;
    
//     const reconnect = () => {
//         if (attempts < maxAttempts) {
//             attempts++;
//             const timeout = Math.min(1000 * Math.pow(2, attempts), 10000);
//             console.log(`Intentando reconectar... (intento ${attempts})`);
            
//             setTimeout(() => {
//                 socket = new WebSocket('wss://ay-cloud.com:3004');
//                 // Volver a configurar los event listeners
//                 setupWebSocket(socket);
//             }, timeout);
//         }
//     };
    
//     reconnect();
// };

// socket.onopen = () => {
//     console.log('WebSocket connected');
    
//     // Generar un ID único para el socket (ya que WebSocket no lo proporciona como Socket.IO)
//     const socketId = 'ws-' + Math.random().toString(36).substr(2, 9);
//     store.dispatch(setSocketId(socketId));
    
//     if (typeof localStorage !== 'undefined') {
//         localStorage.setItem('socketId', socketId);
//     }
// };

// ... rest of the file ...
export default socket



// import io from 'socket.io-client'
// import { store } from './store'
// import { setSocketId } from '../../slices/meetSlices'

// const socket = io('http://localhost:3001', {
// 	transports: ['websocket'],
// 	withCredentials: true,
// 	forceNew: true,
// })

// socket.on('connect', () => {
// 	console.log('Connected to Socket.io server')

// 	const socketId = socket.id
// 	store.dispatch(setSocketId(socketId))

// 	if (typeof localStorage !== 'undefined') {
// 		localStorage.setItem('socketId', socketId)
// 	}
// })

// export default socket
// >>>>>>> 611692139834f68381d125d4214b50763db5417c:src/views/app/v1-1/pages/Meet/socket.js
