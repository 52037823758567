import { createSlice } from '@reduxjs/toolkit';

import {
  fetchsPrototype,
  addPrototype,
  deletePrototype
} from '../actions/prototype'







const prototypeSlice = createSlice({
  name: 'prototype',
  initialState: {
    id: null,
    prototypes: []
  },
  reducers: {
    setChun: (state, action) => {

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchsPrototype.fulfilled, (state, action) => {
        state.prototypes = action.payload
      })

      .addCase(addPrototype.fulfilled, (state, action) => {
        state.prototypes.push(action.payload)
      })
      .addCase(deletePrototype.fulfilled, (state, action) => {
        const index = state.prototypes.findIndex((i) => i._id == action.payload)

        let updatedPrototypes = state.prototypes
        delete updatedPrototypes[index]

        state.prototypes = updatedPrototypes.filter(Boolean)
      })

  },
});

export const {
  setERP,

  setChunkRepo,
  setChunkBoard,

  setPrevIsLoad,

  updateDocument
} = prototypeSlice.actions;

export default prototypeSlice.reducer;












