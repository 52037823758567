// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  
  .popUp_F14PR {
    background: linear-gradient(-10deg, var(--overlay), var(--background));
    position: fixed;
    bottom: 60px;
    width: 600px;
    flex-direction: column;
    left: 10px;
    max-height: calc(100vh - 80px);
    overflow-y: scroll;
    border-radius: 10px;
    padding: 0px;
    z-index: 9999;
  
    &::-webkit-scrollbar {
      display: none;
    }
  }


.resizerRight_vS4Sa {
    width: 10px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    cursor: ew-resize;
    background-color: transparent;
    z-index: 99;
}

.resizerBottom_E9S6R {
    height: 10px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    cursor: ns-resize;
    background-color: transparent;
    z-index: 99;
}

.resizerDiagonal_xmfWG {
    height: 10px;
    width: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: nwse-resize;
    background-color: transparent;
    z-index: 999;
}`, "",{"version":3,"sources":["webpack://./src/views/app/v1-1/pages/Desktop/settings/modal.module.css"],"names":[],"mappings":";EACE;IACE,sEAAsE;IACtE,eAAe;IACf,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,UAAU;IACV,8BAA8B;IAC9B,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,aAAa;;IAEb;MACE,aAAa;IACf;EACF;;;AAGF;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,QAAQ;IACR,MAAM;IACN,iBAAiB;IACjB,6BAA6B;IAC7B,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,iBAAiB;IACjB,6BAA6B;IAC7B,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,mBAAmB;IACnB,6BAA6B;IAC7B,YAAY;AAChB","sourcesContent":["  \n  .popUp {\n    background: linear-gradient(-10deg, var(--overlay), var(--background));\n    position: fixed;\n    bottom: 60px;\n    width: 600px;\n    flex-direction: column;\n    left: 10px;\n    max-height: calc(100vh - 80px);\n    overflow-y: scroll;\n    border-radius: 10px;\n    padding: 0px;\n    z-index: 9999;\n  \n    &::-webkit-scrollbar {\n      display: none;\n    }\n  }\n\n\n.resizerRight {\n    width: 10px;\n    height: 100%;\n    position: absolute;\n    right: 0;\n    top: 0;\n    cursor: ew-resize;\n    background-color: transparent;\n    z-index: 99;\n}\n\n.resizerBottom {\n    height: 10px;\n    width: 100%;\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    cursor: ns-resize;\n    background-color: transparent;\n    z-index: 99;\n}\n\n.resizerDiagonal {\n    height: 10px;\n    width: 10px;\n    position: absolute;\n    bottom: 0;\n    right: 0;\n    cursor: nwse-resize;\n    background-color: transparent;\n    z-index: 999;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popUp": `popUp_F14PR`,
	"resizerRight": `resizerRight_vS4Sa`,
	"resizerBottom": `resizerBottom_E9S6R`,
	"resizerDiagonal": `resizerDiagonal_xmfWG`
};
export default ___CSS_LOADER_EXPORT___;
