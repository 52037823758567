import React from "react";


import NoteData from '@src/locales/es/home1'

import styles from '../index.module.css'

const Release = () => {
  return (
    <>
      <div className={`${styles["rbt-main-content"]}`} >
        <div className={`${styles['rbt-daynamic-page-content']} ${styles['center-width']}`} >
          <div className={`${styles["rbt-dashboard-content"]}`} >
            <div className={`${styles["banner-area"]}`} >
              <div className={`${styles['settings-area']}`} >
                <h3 className={styles.title} >Release Notes</h3>
              </div>
              <div className={`${styles["content-page"]} ${styles["pb--50"]}`} >
                <div className={`${styles["chat-box-list"]}`} >
                  <div className={styles.content} >
                    {NoteData &&
                      NoteData.release.map((data, index) => (
                        <div
                          className={`${styles.row} ${styles.changelog_info}`} 
                          id="v120"
                          key={index}
                        >
                          <div className={`${styles["col-lg-3"]} ${styles["changelog_date"]}`} >
                            <div className={`${styles.c_date}`} >
                              <h6>{data.date}</h6> 
                              <p>{data.text}</p>
                              <p>{data.issue}</p>
                            </div>
                          </div>
                          <div className={`${styles["col-lg-2"]}`} >
                            <div className={styles.version_info} >
                              <div className={`${styles.c_version}`} >{data.version}</div>
                              <div className={`${styles.line} ${styles.bottom_half}`} ></div>
                            </div>
                          </div>
                          <div className={`${styles["col-lg-7"]}`} >
                            <div className={`${styles.changelog_content}`} >
                              {data.content.map((innerData, innerIndex) => (
                                <p key={innerIndex}>
                                  <span
                                    className={`${
                                      innerData.status === "new"
                                        ? styles[innerData.status]
                                        : styles[innerData.status]
                                    } ${styles["text-uppercase"]}`}
                                  >
                                    {innerData.status === "new"
                                      ? "ADDED"
                                      : innerData.status}
                                  </span>
                                  {innerData.text}
                                </p>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Release;
