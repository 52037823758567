export const colors = [
	{ hex: '#d32f2f', label: 'Rojo intenso' },
	{ hex: '#f06292', label: 'Rosa claro' },
	{ hex: '#ffb74d', label: 'Naranja' },
	{ hex: '#4caf50', label: 'Verde claro' },
	{ hex: '#388e3c', label: 'Verde oscuro' },
	{ hex: '#64b5f6', label: 'Azul claro' },
	{ hex: '#1976d2', label: 'Azul intenso' },
	{ hex: '#673ab7', label: 'Púrpura claro' },
	{ hex: '#9c27b0', label: 'Morado intenso' },
	{ hex: '#616161', label: 'Gris' },
]
