const vector = {
    t1: `Erstelle einen neuen Vektor für dein Projekt`,
    t2: `Importiere, speichere, bearbeite, analysiere und ziehe Schlussfolgerungen aus jeder Datenstruktur. 
Speichere und manipuliere die Informationen mit vollständiger Freiheit durch mehrdimensionale Räume.`,
    t3: `Neuer Vektor`,
    t4: `Vektoren`,
    t5: `Vektor`,
    t6: `Dimension`,
    t7: `Größe`,
    t9: `Erstellt am`,
    t10: `Matrix`,
    t11: `Vektor-Datenbanken ermöglichen die Erstellung einzigartiger Erfahrungen, die durch vektorbasierte Suche angetrieben werden. 
Sie beschleunigen die Entwicklung von KI-Anwendungen.`,
    t12: `Neue Matrix`,
    t13: `Schnellstart-Dokumentation`,
    node: {
        data: {
            title: `Füge Daten deinem Bericht hinzu`,
            text: `Sobald geladen, werden deine Daten im`,
            label: `Felder`,
            excel: `Daten von Excel importieren`,
            sql: `Daten von SQL importieren`,
            blank: `Leere Daten einfügen`,
            export: `Beispiel-Daten exportieren`,
            info: `Daten von einer anderen Quelle abrufen`,
        }
    },
    dashboard: {
        t1: `Denke daran, dass du immer die Grafiken, die du hervorheben möchtest, als Favoriten speichern kannst, 
basierend auf den Informationen, die du in den Vektoren analysiert hast.`,
        t2: `Erfahre mehr`,
    }
}

export default vector;