import { createSlice } from '@reduxjs/toolkit';

import {

} from '../actions/game'


const gameSlice = createSlice({
  name: 'game',
  initialState: {
    id: null,

    chunks: [],
    isLoad: false,

  },
  reducers: {
    setChunk: (state, action) => {
      state.chunks.push(action.payload)
    },
    setIsLoad: (state, action) => {
      state.isLoad = action.payload
    },


  },
  extraReducers: (builder) => {
    builder
  
  },
});

export const {
  setChunk,
  setIsLoad,
} = gameSlice.actions;

export default gameSlice.reducer;












