const form = {
    editor: {
        step0: {
            text0: `Wird Ihr Projekt monetarisiert werden?`,
            t0: `Ja`,
            t1: `Nein`,
        },
        step1: {
            title: `Beschreibung Ihres Projekts`,
            text: `Beschreiben Sie das Projekt, um die ersten Schritte vorzubereiten`,
            t0: `Name`,
            t1: `Beschreibung`,
        },
        step2: {
            title: `Wie viele Benutzer erwarten Sie?`,
            text: `Je nach Benutzeranzahl bieten wir kritischere Maßnahmen an`,
            t0: `0-1.000`,
            t1: `1.000-10k`,
            t2: `10k-100k`,
            t3: `100k-250k`,
            t4: `250k-500k`,
            t5: `+500.001`
        },
        step3: {
            title: `Wählen Sie den Typ und Stil aus`,
            text0: `Der Typ dient dazu, eine unserer Kategorien auszuwählen`,
            text1: `Der Stil definiert das Aussehen`,
            t0: `Hallo Welt`,
            t1: `Minimalistisch`,
            t2: `Modern`,
            t3: `Klassisch`,
            x0: `Komplex`,
            x1: `Einfach`,
        },
        step4: {
            title: `Möchten Sie den erweiterten oder den Basis-Editor-Modus?`,
            t0: `Erweitert`,
            t1: `Basis`,
        },
    }, 
    flags: {
        en: `Englisch`,
        es: `Spanisch`,
        fr: `Französisch`,
        de: `Deutsch`,
        it: `Italienisch`,
        ru: `Russisch`,
        pt: `Portugiesisch`,
        nl: `Niederländisch`,
        sv: `Schwedisch`,
    },
    title: {
        text0: 'Schritt',
        text1: 'von',
        step0: 'Sprache',
        step1: 'Profil',
        step2: 'Ziel',
        step3: 'Kontext',
        step4: 'Bonus',
        step5: 'Firma',
        step6: 'Größe',
        step7: 'Softwaretyp',
        step8: 'Einstellung',
    },
    footer: {
        text: 'Abonnieren Sie unsere E-Mails',
        button0: 'Vorherige',
        button1: 'Nächste',
    },
    step0: {
        t0: `Wählen Sie die Sprache aus`,
        t1: `Durch die Auswahl ändert sich die Webseite`,
        t2: `Wir integrieren derzeit mehr Sprachen`,
    },
    step1: {
        t0: `Wie ist Ihr Name?`,
        t1: `Freut mich, Sie kennenzulernen`,
        t2: `Vorname`,
        t3: `Nachname`,
        t4: `Telefon`,
        t5: `E-Mail`,
    },
    step2: {
        t0: `Für wen benötigen Sie es?`,
        t1: `Wählen Sie die Option Ihres Zwecks`,
        t2: `Mein Unternehmen`,
        t3: `Für mich`,
        t4: `Kunden`,
    },
    step3: {
        t0: `Wie würden Sie sich beschreiben? `,
        t1: `Startup`,
        t2: `Kleine Firma`,
        t3: `Mittlere Firma`,
        t4: `Großunternehmen`,
    },
    step4:{
        t0: `Sind Sie Student?`,
        t1: `Ja`,
        t2: `Nein`,
    },
    step5:{
        t0: `Was beschreibt Sie am besten?`,
        t1: `Freiberufler`,
        t2: `Agentur`,
    },
    step6:{
        t0: `Wie groß ist Ihr Team?`,
    },
    step7:{
        t0: `Welche Art von Website möchten Sie heute erstellen?`,
        t1: `Unternehmenswebsite`,
        t2: `E-Commerce-Shop`,
        t3: `Portfolio`,
        t4: `Blog`,
        t5: `Analytik`,
        t6: `Andere`,
    },
    step8:{
        t0: `Interessieren Sie sich für die Verwendung von Aythen?`,
        t1: `Ja`,
        t2: `Nein`,
        t3: `Ich weiß es noch nicht`,
    },
}

export default form
