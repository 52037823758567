import React from "react";

import Context from "@home/context/Context";
import SignUp from "@home/SignUp/SignUp";

const SignupPage = () => {
  return (
    <>
      <Context>
        <SignUp />
      </Context>
    </>
  );
};

export default SignupPage;
