import React from "react";

import styles from '../index.module.css'

const BlogItem = ({ blog }) => {
  return (
    <>
      <div className="row mt_dec--30">
        <div className={styles["col-lg-12"]}>
          <div className={`${styles["row"]} ${styles["row--15"]}`}>
            {blog.slice(0, 8).map((data) => (
              <div className={`${styles["col-lg-6"]} ${styles["col-md-6"]} ${styles["col-12"]} ${styles["mt--30"]}`} key={data.id}>
                <div className={`${styles["rainbow-card"]} ${styles["undefined"]}`}>
                  <div className={styles["inner"]}>
                    <div className={styles["thumbnail"]}>
                      <button className={styles["image"]} href={`/blog-detail/${data.id}`}>
                        <img
                          src={data.img}
                          width={413}
                          height={281}
                          alt="Blog img"
                        />
                      </button>
                    </div>
                    <div className={styles.content}>
                      <ul className={styles["rainbow-meta-list"]}>
                        <li>
                          <i className={`${styles["fa-sharp"]} ${styles["fa-regular"]} ${styles["fa-calendar-days"]} ${styles["icon-left"]}`}></i>{" "}
                          {data.date}
                        </li>
                        <li className={styles["separator"]}></li>
                        <li className={styles["catagory-meta"]}>
                          <a href="#">{data.cate}</a>
                        </li>
                      </ul>
                      <h4 className={styles["title"]}>
                        <button href={`/blog-detail/${data.id}`}>
                          {data.title}
                        </button>
                      </h4>
                      <p className={styles["description"]}>{data.desc}</p>
                      <a className={`${styles["btn-read-more"]} ${styles["border-transparent"]}`} href="#">
                        <span>
                          Read More{" "}
                          <i className={`${styles["fa-sharp"]} ${styles["fa-regular"]} ${styles["fa-arrow-right"]}`}></i>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={`${styles["col-lg-12"]} ${styles["text-center"]}`}>
          <div className={`${styles["rainbow-load-more"]} ${styles["text-center"]} ${styles["mt--60"]}`}>
            <button className={`${styles["btn"]} ${styles["btn-default"]} ${styles["btn-icon"]}`}>
              <span>
                View More Post
                <span className={styles["icon"]}>
                  <i className={styles["feather-loader"]}></i>
                </span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogItem;
